import { Downgraded } from "@hookstate/core";
import { creditData, userDetails } from "../../../configs/mainStore";

export const isNotPermittedDocs = () => {
  const creditDataDowngraded: any = creditData.attach(Downgraded).get();
  let { userId }: any = userDetails.attach(Downgraded).get();
  const { disbursed, currentAssignee } = creditDataDowngraded;

  if (disbursed || disbursed === 1) {
    return true;
  }
  if (currentAssignee !== userId) {
    return true;
  }
  return false;
};

export const isNotPermittedAll = (isTabInEditString) => {
  if (isNotPermittedDocs()) {
    return true;
  }
  if (isTabInEditString) {
    return true;
  }
  return false;
};

export const isTabActionPrivilege = (disableAccordingToTab = false) => {
  const creditDataDowngraded: any = creditData.attach(Downgraded).get();
  let { userId }: any = userDetails.attach(Downgraded).get();
  const { disbursed, currentAssignee } = creditDataDowngraded;

  if (disbursed || disbursed === 1) {
    return true;
  }
  if (disableAccordingToTab) {
    return true;
  }

  if (currentAssignee !== userId) {
    return true;
  }
  return false;
};
