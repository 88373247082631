import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import appStrings from "./configs/constants/appStrings";

// Translations
import { defaultAppSettings } from "./configs/constants/defaultAppSettings";
import { enDictionary } from "./configs/languageFiles/english";
import { siDictionary } from "./configs/languageFiles/sinhala";
import { tmDictionary } from "./configs/languageFiles/tamil";

let language;
const userSettings: any = localStorage.getItem(
  appStrings?.localStorage?.appSettings
);

if (userSettings) {
  language = JSON.parse(userSettings)?.language;
} else {
  language = defaultAppSettings?.language;
  localStorage.setItem(
    appStrings?.localStorage?.appSettings,
    JSON.stringify(defaultAppSettings)
  );
}
i18n
  // detect user language
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: language,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      EN: {
        translation: enDictionary,
      },
      SI: {
        translation: siDictionary,
      },
      TM: {
        translation: tmDictionary,
      },
    },
  });

export default i18n;
