/**
 *
 * @param currentState current state object
 * @param newState incoming changes object
 * @returns updated new state with incoming changes
 */


// import { otherParticipantDataDefaultDataObject } from '../configs/constants/stateObjects';

export const compareState = (currentState: any = null, newState, exactOriginalState = null) => {
  const keySet = Object.keys(currentState);

  // if current state is an array
  if (isArray(currentState)) {
    return handleArrays(currentState, newState);
  }

  keySet?.forEach((key, index) => {
    try {
      if (isArray(currentState[key]) && newState[key]?.length === 0) return;
      if (!newState?.hasOwnProperty(key)) {
        if (currentState[key] === undefined) return;

        if (key !== "guarantors") {
          /**
           * exactOriginalState is an object that have empty arrys as a values.
           *   if db has not any record regarding "key" and ignore the default values and get this exactOriginalState.
           *   otherwise get the default values if you want.
           */
          newState[key] = exactOriginalState ? exactOriginalState[key] : currentState[key];
        } else {
          newState[key] = []
        }
      } else {
        if (isObject(currentState[key])) {
          const updatedObject = handleObjects(currentState[key], newState[key]);
          newState[key] = updatedObject;
          return;
        }

        //if key refers to a array value -> key :[]

        if (isArray(currentState[key])) {
          const updatedArray = handleArrays([...currentState[key]], newState[key], key);
          newState[key] = updatedArray;
          return;
        }
      }
    } catch { }
  });

  return newState;
};

//------------------------ Task functions -------------------------------

/**
 *
 * @param {*} newObject new object to be create
 * @param {*} dataSet incoming data values to be set
 * @returns object that created or updated
 */
const handleObjects = (newObject, dataSet) => {
  //keys to be update insert
  const updatedKeys = Object.keys(dataSet);

  updatedKeys.forEach((key) => {
    //if json object contains this selected key -> update key

    if (newObject.hasOwnProperty(key)) {
      //if key refers to a object value -> key:{}
      if (isObject(newObject[key])) {
        const updatedObject = handleObjects(newObject[key], dataSet[key]);
        newObject[key] = updatedObject;
        return;
      }

      //if key refers to a array value -> key :[]
      if (isArray(newObject[key])) {
        const updatedArray = handleArrays(newObject[key], dataSet[key], key);

        newObject[key] = updatedArray;
        return;
      }

      //if key is a pure key value pare -> key:value
      newObject[key] = dataSet[key];
    }
    //if json object not contains this selected key -> insert key
    else {
      newObject[key] = dataSet[key];
    }
  });

  return newObject;
};

/**
 *
 * @param {*} currentArray selected array to be edit
 * @param {*} dataSet new data values to be set
 * @returns array that created or updated
 */
const handleArrays = (currentArray, dataSet, targetKey = '') => {
  const originalObject = {
    ...JSON.parse(JSON.stringify(currentArray[0])),
  };

  dataSet?.forEach((item, index) => {
    //selecting target index from existing array

    const isExistingItem = currentArray[item?.['itemIndex']];

    //if target index exist -> update target index
    if (isExistingItem) {
      //if key refers to a object value -> [ {} , {}]
      if (isObject(isExistingItem)) {
        // if(index === item[itemIndex]){
        const updatedItem = handleObjects(JSON.parse(JSON.stringify(originalObject)), item);
        currentArray[index] = updatedItem;
        // }
        return;
      }

      //if key refers to a array value -> [ [] , []]
      if (isArray(isExistingItem)) {
        const updatedArray = handleArrays(isExistingItem, item);
        currentArray[index] = updatedArray;
        return;
      }

      //if key contain scalar key values -> [ a ,b ]
      currentArray[index] = item;
      return;
    }
    //if target index not exist -> inserting target index
    else {
      const updatedItem = compareState(JSON.parse(JSON.stringify(originalObject)), item);
      currentArray[item?.['itemIndex']] = updatedItem;
    }
  });

  return currentArray;
};

//----------------------- Helper functions --------------------------------

/**
 * Helper function to check a item is an object
 * @param {*} object object to be check
 * @returns true or false
 */
const isObject = (object) => Object.prototype.toString.call(object) === '[object Object]';

/**
 * Helper function to check a item is an array
 * @param {*} object object to be check
 * @returns true or false
 */
const isArray = (object) => Array.isArray(object);
