import { Downgraded } from "@hookstate/core";
import { langDictionary } from "../../../configs/languageDictionaryEn";
import { incomeDetailsValidationOnNext } from "../../../configs/validations/application-form-on-next-validation-handlers/income-details/IncomeDetailsValidationOnNext";
import AppConfig from "../../AppConfig";
import { messageMappings } from "./messageMapping";
import { formStateArrayTypeHandler } from "./structureValidation";

export const incomeDetails = (structure, state, isGuarantor = false, isJointBorrower = false, participantState, isNoObligations, formStore) => {
  let errorArrayKeys: string[] = [];
  let mainDataKeys: string[] = ["personnelIncome", "businessIncome", "cultivationIncome"];
  const { type = null }: { type: string | null } = formStore?.creditData.attach(Downgraded).get();
  const { microTypetId, SMETypeId }: { microTypetId: string; SMETypeId: string } = AppConfig.config;
  const isOtherProducts: boolean = (type == microTypetId) || (type == SMETypeId);

  if (!(state?.personnelIncome?.length || state?.businessIncome?.income?.length || state?.cultivationIncome?.income?.length)) {
    errorArrayKeys.push("incomeCategory");
  }

  let employementSetionState: any[] = [];

  if (isGuarantor || isJointBorrower) {
    employementSetionState = participantState?.employedData?.currentEmployment?.attach(Downgraded).get() || [];
    let employmentTypes: boolean[] = employementSetionState.map((employement) => Number(employement?.employmentStatus || 0) === 2).filter((item: boolean) => item === true)

    if (errorArrayKeys.length) {
      if (employmentTypes.length) return [];

      if (isJointBorrower && isOtherProducts) return [];
    }
  }

  if (errorArrayKeys.length) {
    return messageMappings(errorArrayKeys);
  }

  const getValidationResponses = ({ state }) => {
    return formStateArrayTypeHandler({ structure, state });
  }

  try {
    let stateValidationsWithStructure: string[] = mainDataKeys.reduce((validationArray: string[], key: string, index: number) => {
      let finalArry: string[] = [];

      if (state?.[key]?.income?.get().length) {
        let validationsResponse: string[] = getValidationResponses({ state: state?.[key]?.income });
        finalArry = [...finalArry, ...validationsResponse];
      }

      if (state?.[key]?.expense?.get().length) {
        let validationsResponse: string[] = getValidationResponses({ state: state?.[key]?.expense });
        finalArry = [...finalArry, ...validationsResponse];
      }

      if (Array.isArray(state?.[key]) && state?.[key].length) {
        let validationsResponse: string[] = getValidationResponses({ state: state?.[key] });
        finalArry = [...finalArry, ...validationsResponse];
      }

      finalArry = messageMappings(finalArry);

      finalArry = finalArry.length ? [`${index === 0 ? "" : "\n"} 👉 ${langDictionary?.[key]}`, ...finalArry] : [];

      return [...validationArray, ...finalArry];;
    }, [])

    let incomeState = state.attach(Downgraded).get();
    let validationResponse = incomeDetailsValidationOnNext(incomeState);
    validationResponse = validationResponse.length ? ['\n 👉 other Income Validations', ...validationResponse] : [];

    errorArrayKeys = [...stateValidationsWithStructure, ...validationResponse, ...messageMappings(errorArrayKeys)];
  } catch (error) {
    console.error(`${error} something went wrong on Income Details Error Array`);
  }

  return errorArrayKeys;
};


