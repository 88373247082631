import { useState } from '@hookstate/core'
import { Grid, Stack, Typography } from '@mui/material'
import { masterDataDetails, productDetails } from '../../../../../../configs/mainStore'
import { formatCurrency } from '../../../../../../services/currencyFormater'
import { loanRequirementOptions } from '../../../../../../utility/util'
import DetailItem from '../../DetailItem'

const CreditDetailItem = ({ formData: { creditData } }) => {
    const masterData: any = useState(masterDataDetails);
    const { productTypesMap, productSectorsMap, productSchemesMap, productApplicantsMap }: any = useState(productDetails);
    let { type, sector, scheme, applicantType, loanRequirement }: any = creditData;

    type = productTypesMap[type].get();
    sector = productSectorsMap[sector]?.get();
    scheme = productSchemesMap[scheme]?.get();
    applicantType = productApplicantsMap[creditData?.applicantType]?.get();
    const repaymentType = masterData?.REPAYMENT_TYPE?.get()?.find((t) => t.id == creditData?.repaymentType);
    const loanPurpose = masterData?.LOAN_PURPOSE?.get()?.find((t) => t.id == creditData?.loanPurpose);
    const fundSource = masterData?.FUND_SOURCE?.get()?.find((t) => t.id == creditData?.fundSource);
    const loanFrequency = masterData?.LOAN_FREQUENCY?.get()?.find((t) => t.id == creditData?.loanFrequency);

    return <Stack>
        <Stack px={2} py={1} style={{ backgroundColor: "#e6f0fa" }}>
            <Typography variant="subtitle1" color={"gray"}>Product Information</Typography>
        </Stack>
        <Grid container>
            <Grid container p={1} className="data-box" spacing={1}>
                <Grid container item spacing={1}>
                    {type?.TYPE_NAME && (
                        <Grid item xs={3}>
                            <DetailItem title="Product Type" value={type.TYPE_NAME} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                    )}
                    {sector?.TYPE_NAME && (
                        <Grid item xs={3}>
                            <DetailItem title="Sector" value={sector.TYPE_NAME} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                    )}
                    {scheme?.TYPE_NAME && (
                        <Grid item xs={3}>
                            <DetailItem title="Scheme" value={scheme.TYPE_NAME} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                    )}
                    {applicantType?.TYPE_NAME && (
                        <Grid item xs={3}>
                            <DetailItem title="Applicant Type" value={applicantType.TYPE_NAME} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>

        {creditData.type === 22 ? <></> : <>
            <Stack px={2} py={1} style={{ backgroundColor: "#e6f0fa" }}>
                <Typography variant="subtitle1" color={"gray"}>Loan Information</Typography>
            </Stack>
            <Grid container>
                <Grid container p={1} className="data-box" spacing={1}>
                    <Grid container item spacing={1}>
                        {creditData?.district && creditData?.rdbBranch && (
                            <Grid item xs={3}>
                                <DetailItem title="Nearest RDB Branch" value={`${creditData?.district} District ${creditData?.rdbBranch} Branch`} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                        )}
                        <Grid item xs={3}>
                            <DetailItem title="Loan Amount" value={creditData?.loanAmount ? `LKR ${formatCurrency(creditData?.loanAmount)}` : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={3}>
                            <DetailItem title="Purpose" value={loanPurpose?.name ? loanPurpose.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={3}>
                            <DetailItem title="Loan Cycle" value={creditData?.loanCycles ? creditData?.loanCycles : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={3}>
                            <DetailItem title="Repayment Type" value={repaymentType?.name ? repaymentType.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={3}>
                            <DetailItem title="Loan Frequency" value={loanFrequency?.name ? loanFrequency.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={3}>
                            <DetailItem title="Proposed Rate" value={creditData?.interestRate ? `${creditData.interestRate}% p.a` : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={3}>
                            <DetailItem title="Loan Tenor" value={creditData?.loanTerms ? creditData.loanTerms : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={3}>
                            <DetailItem title="Fund Source" value={fundSource?.name ? fundSource.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={3}>
                            <DetailItem title="Grace Period" value={creditData?.gracePeriod ? creditData.gracePeriod : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        {loanRequirement && <Grid item xs={3}>
                            <DetailItem title="Loan Requirement" value={loanRequirementOptions.filter(opt => loanRequirement === opt.id)?.[0]?.name ?? "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>}
                    </Grid>
                    <Grid container item></Grid>
                </Grid>
            </Grid>
        </>}

    </Stack>;
}

export default CreditDetailItem
