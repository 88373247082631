import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Stack } from "@mui/material";
import { continueButtonStyle, loadingButtonStyle } from "../../../../../../configs/constants/styleValues";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { StyledButton } from "../../../../../InputComponents/ButtonComponent/StyledButton";

const HeaderCollateral = ({ headerTitle, loading, handleFieldsAdding, requestDtlData, collateralApplicationForm, handleSave }) => {
  return (
    <>
      <Stack m={0}>
        <Stack direction={"row"} width={"100%"} justifyContent={"space-between"} style={{ backgroundColor: "#e6f0fa" }} className="align-center">
          <Stack direction={"row"} className="basic-font basic-font-color-bold font-size-16 padding-m align-left" alignItems={"center"}>
            {headerTitle}
            <ButtonComponent iconbtn onClick={handleFieldsAdding}>
              <AddCircleOutlineIcon />
            </ButtonComponent>
          </Stack>
          <Stack>
            {/* Save button */}
            {requestDtlData.get()?.length > 0 && Object.keys(collateralApplicationForm).length > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  marginTop: "5px",
                  marginRight: "10px",
                }}
              >
                <StyledButton styles={loading ? loadingButtonStyle : continueButtonStyle} title="Save" onClick={handleSave} loading={loading} disabled={loading ? true : false} />
              </div>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default HeaderCollateral;
