import AutoGeneratedTable from "./AutoGeneratedTable/AutoGeneratedTable";
import * as TableColumns from "./TableColumn/TableColumnsWrapper";
import * as TableHeaders from "./TableHeader/TableHeaderWrapper";
import * as TableRows from "./TableRow/TableRowWrapper";
import TableBodyWrapper from "./TableWrapper/TableWrapper";


const tablePreBuildComponents = { AutoGeneratedTable, TableBodyWrapper, TableColumns, TableHeaders, TableRows };

export default tablePreBuildComponents;
