import { Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { subflowReferralHistory } from "../../../../../services/creditFileApiCall";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { ErrorMessageComponent } from "../UserCreditComponents/ErrorMessageComponent";
import HistoryItemRow from "./HistoryItemRow";

const ViewReferralHistory = ({ data, currentUser }) => {
  const [isLoading, setLoading] = useState<any>(true);
  const [historyData, setHistoryData] = useState<any>([]);

  useEffect(() => {
    setLoading(true);

    (async function () {
      const params = {
        applicationId: data?.applicationId,
        id: data?.id,
        groupId: data?.groupId,
      };
      const { data: history } = await subflowReferralHistory(params);

      setHistoryData(history);
    })();
    setLoading(false);
  }, []);

  if (isLoading) {
    return (
      <CircularProgressComponent
        size={30}
        sx={{ marginTop: "20px", marginBottom: "20px", marginLeft: "auto", marginRight: "auto", zIndex: 100 }}
      />
    );
  }

  if (!historyData || historyData.length === 0) {
    return <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />;
  }

  return (
    <Grid container maxHeight={"calc(100vh - 200px)"} sx={{ overflowY: "auto" }}>
      <Stack spacing={1} m={1} className="full-width">
        {historyData?.map((item, idx) => (
          <HistoryItemRow data={item} groupId={data?.groupId} referData={data} currentUser={currentUser} />
        ))}
      </Stack>
    </Grid>
  );
};

export default ViewReferralHistory;
