import CalculateIcon from "@mui/icons-material/Calculate";
import SendIcon from "@mui/icons-material/Send";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Alert from "@mui/material/Alert";
import { Box } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";
import { getRepaymentMethods, updateApplicationTrailCalData } from "../../../services/creditFileApiCall";
import { formatCurrency } from "../../../services/currencyFormater";
import { dateFormatWithDate, shortDateFormatWithDate } from "../../../utility/fomatters";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import { addToaster } from "../GlobalToast";
import { months, repaymentDays } from "./constant";
import { MetaDataFieldRow, NumericDataInputFieldRow, SelectMethodsFieldRow } from "./MetaDataFieldRow";
import { trailCalculatorResult } from "./service/trailCalculator";

export const TrailCalculatorLayout = (params) => {
  const [loanAmount, setLoanAmount] = useState(params?.loanAmount ? parseFloat(params?.loanAmount) : 0);
  const [paymentMethod, setPaymentMethod] = useState<any>(null);
  const [numTerms, setNumTerms] = useState(params?.numTerms ? params?.numTerms : 0);
  const [applicableRate, setApplicableRate] = useState(params?.interestRate ? parseFloat(params?.interestRate) : 0);
  const [paymentStartMonth, setPaymentStartMonth] = useState(params?.trailCalParams?.paymentStartMonth ? params?.trailCalParams?.paymentStartMonth : "");

  const [paymentYear, setPaymentYear] = useState(1);
  const [paymentMonth, setPaymentMonth]: any = useState(new Date().getMonth());
  const [paymentDate, setPaymentDate]: any = useState(new Date().getDate());

  const [trailCalTableData, setTrailCalTableData] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<any>("");

  const [isLoading, setIsLoading] = useState(false);
  const [isDirect, setIsDirect] = useState(false);

  const [repaymentMethodList, setRepaymentMethodList] = useState({});
  const [repaymentMethodListDropdown, setRepaymentMethodListDropdown] = useState([]);

  const [isDateChange, setIsDateChange] = useState(true);

  const systemRate = params?.interestRate ? parseFloat(params?.systemRate) : 0;
  const tempOtherFeesAmountArr = []

  const productType = params?.product;
  const sector = params?.sector;
  const scheme = params?.scheme;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonthInNumber = currentDate.getMonth();
  const currentDayInNumber = currentDate.getDate();

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const data = await getRepaymentMethods("EN");

      if (data) {
        if (data?.repaymentMethodList) {
          let tempDropdown: any = [];
          let tempRepaymentMethod: any = [];
          data?.repaymentMethodList?.map((item) => {
            tempDropdown.push({ id: item.code, name: item.desc });
            tempRepaymentMethod[item.code] = item;
          });
          setRepaymentMethodList(tempRepaymentMethod);

          setRepaymentMethodListDropdown(tempDropdown);
          if (tempDropdown.length > 0) {
            setPaymentMethod("EB_M");
          }
        }
      }

      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (params?.trailCalResult) {
      setTrailCalTableData(params?.trailCalResult);
    }

    if (params?.trailCalParams?.paymentMethod && params?.trailCalParams?.terms && params?.trailCalParams?.LoanInterestRate && params?.trailCalParams?.repaymentDate && params?.interestRate && params?.numTerms) {
      setPaymentMethod(params?.trailCalParams?.paymentMethod);
      setNumTerms(params?.numTerms);
      setApplicableRate(params?.interestRate);
      setPaymentDate(params?.trailCalParams?.repaymentDate);
    }

    if (params?.isDirect) {
      setIsDirect(params?.isDirect);
    }
  }, [params?.trailCalResult, params?.trailCalParams, params?.isDirect]);

  useEffect(() => { }, [isDateChange]);

  const calculatePaymentTerms = async () => {
    setTrailCalTableData([]);

    if (loanAmount > 0 && numTerms > 0 && applicableRate > 0 && systemRate >= 0 && paymentYear !== null && paymentMethod !== null && paymentDate !== null) {
      const annualInterestRate = applicableRate > 0 ? applicableRate : systemRate;
      const startYear = paymentYear === 1 ? currentYear : currentYear + 1;
      const startMonthWithYear = startYear + "-" + paymentMonth;
      const { trailCalculationData, meta } = trailCalculatorResult(repaymentMethodList, paymentMethod, loanAmount, annualInterestRate, numTerms, paymentDate, startMonthWithYear);

      return { trailCalculationData, meta };
    } else {
      return null;
    }
  };

  const calculateTrailCalButtonClick = async () => {
    const { trailCalculationData }: any = await calculatePaymentTerms();

    if (trailCalculationData) {
      setErrorMessage("");
      setTrailCalTableData(trailCalculationData);
    } else {
      setErrorMessage("Invalid Parameters");
    }
  };

  const saveTrailCalculatorJson = async () => {
    setIsLoading(true);

    const trailParamsJson = {
      productId: params?.type,
      sector: params?.scheme,
      loanScheme: params?.sector,
      systemRate,
      branch: params?.branch,
      loanAmount,
      paymentMethod,
      terms: numTerms,
      applicableRate,
      repaymentDate: paymentDate,
      paymentStartMonth: paymentStartMonth,
    };

    const schemeSubmitResult = await params?.schemeSubmit();
    const { trailCalculationData, meta }: any = await calculatePaymentTerms();

    if (trailCalculationData) {
      setErrorMessage("");
      setTrailCalTableData(trailCalculationData);

      const result = await updateApplicationTrailCalData(params?.applicationId, trailParamsJson, trailCalculationData, tempOtherFeesAmountArr, meta);

      if (result?.msg) {
        params?.trailCalculatorSubmit(loanAmount, numTerms, applicableRate);

        addToaster({
          status: "success",
          title: "Updated",
          message: "Check List Item Added",
        });
      } else if (result?.error) {
        addToaster({
          status: "warning",
          title: "Not Updated",
          message: "Update error",
        });
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: "Something went Wrong!",
        });
      }
    } else {
      setErrorMessage("Invalid Parameters");
    }

    // closeGlobalModal();
    setIsLoading(false);
  };

  const changeTrailCalTableData = (date, i) => {
    let tempTrailCalTableData = trailCalTableData;

    tempTrailCalTableData[i] = {
      ...trailCalTableData[i],
      ...{ paymentDate: moment(date) },
    };

    setTrailCalTableData(tempTrailCalTableData);
    setIsDateChange(!isDateChange);
  };

  const changeMonthData = (date) => {
    setPaymentStartMonth(date);
  };

  const getRestOfMonths = () => {
    let restMonths = months?.filter((el) => {
      return el?.id >= currentMonthInNumber;
    });

    const monthsForTheCurrent = restMonths.slice(0, 6);
    if (paymentYear === 1) {
      return monthsForTheCurrent;
    } else {
      const restLength = monthsForTheCurrent.length;
      if (restLength < 6) {
        const deducedMonths = 6 - restLength;
        const monthsForTheNext = months.slice(0, deducedMonths);
        return monthsForTheNext;
      } else {
        return months.slice(0, 6);
      }
    }
  };

  const getRepaymentYear = () => {
    const monthRange = 12 - currentMonthInNumber;

    if (monthRange >= 6) {
      return [{ id: 1, name: currentYear }];
    } else {
      return [
        { id: 1, name: currentYear },
        { id: 2, name: currentYear + 1 },
      ];
    }
  };

  const getRepaymentDay = () => {
    if (paymentMonth == currentMonthInNumber) {
      let restDays = repaymentDays?.filter((el) => {
        return el?.id >= currentDayInNumber;
      });
      if (restDays.length !== 0) {
        return restDays;
      } else {
        return repaymentDays;
      }
    } else {
      return repaymentDays;
    }
  };

  const repaymentYear = (value) => {
    setPaymentYear(value);
  };

  const repaymentMonth = (value) => {
    setPaymentMonth(value);
  };

  const repaymentDate = (value) => {
    setPaymentDate(value);
  };

  return (
    <Paper elevation={0}>
      <Grid container direction="column">
        <Grid pt={2}>
          <Box>
            <Grid
              className="row_list_item"
              style={{
                backgroundColor: `#e6f0fa`,
              }}
              container
              direction="column"
              p={2}
            >
              {!isDirect && (
                <Grid container item direction="row" xs={12}>
                  <Grid container item sm={4} style={{ textAlign: "left" }}>
                    <MetaDataFieldRow label={"Product:"} value={productType ? productType : "-"} />
                    <MetaDataFieldRow label={"Date:"} value={shortDateFormatWithDate(new Date())} />
                  </Grid>
                  <Grid container item sm={4} style={{ textAlign: "left" }}>
                    <MetaDataFieldRow label={"Sector:"} value={sector ? sector : "-"} />
                    <MetaDataFieldRow label={"System Rate:"} value={`${systemRate} %`} />
                  </Grid>
                  <Grid container item sm={4} style={{ textAlign: "left" }}>
                    <MetaDataFieldRow label={"Loan Scheme:"} value={scheme ? scheme : "-"} />
                    <MetaDataFieldRow label={"Branch:"} value={params?.branch ? params?.branch?.name : "-"} />
                  </Grid>
                </Grid>
              )}
              {!isDirect && (
                <hr
                  style={{
                    borderTop: "1px solid rgba(69, 76, 102, 0.1)",
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                />
              )}
              <Grid container item direction="row" xs={12}>
                <NumericDataInputFieldRow
                  value={loanAmount}
                  maxValue={100000000}
                  minValue={0}
                  onChange={(value) => {
                    setLoanAmount(value?.floatValue);
                  }}
                  label={"Loan Amount:"}
                  xs={2}
                  isLoading={isLoading}
                />
                {repaymentMethodListDropdown && (
                  <SelectMethodsFieldRow
                    label={"Payment Method:"}
                    dropdownLanguageList={repaymentMethodListDropdown}
                    value={paymentMethod}
                    onChange={(value) => {
                      setPaymentMethod(value);
                    }}
                    isLoading={isLoading}
                    xs={isDirect ? 2 : 3}
                  />
                )}

                <NumericDataInputFieldRow
                  value={numTerms}
                  maxValue={100000}
                  minValue={0}
                  onChange={(value) => {
                    setNumTerms(value?.floatValue);
                  }}
                  label={"Term:"}
                  thousandSeparator={false}
                  isLoading={isLoading}
                  xs={1}
                />

                <NumericDataInputFieldRow
                  value={applicableRate}
                  maxValue={500}
                  minValue={0}
                  onChange={(value) => {
                    setApplicableRate(value?.floatValue);
                  }}
                  label={"Applicable Rate:"}
                  thousandSeparator={false}
                  isLoading={isLoading || params?.interestRate ? true : false}
                />

                <SelectMethodsFieldRow
                  label={"Repayment Year:"}
                  dropdownLanguageList={getRepaymentYear()}
                  value={paymentYear}
                  onChange={(value) => {
                    repaymentYear(value);
                  }}
                  xs={2}
                  isLoading={isLoading}
                />

                <SelectMethodsFieldRow
                  label={"Repayment Month:"}
                  dropdownLanguageList={getRestOfMonths()}
                  value={paymentMonth}
                  onChange={(value) => {
                    repaymentMonth(value);
                  }}
                  xs={2}
                  isLoading={isLoading}
                />

                <SelectMethodsFieldRow
                  label={"Repayment Date:"}
                  dropdownLanguageList={getRepaymentDay()}
                  value={paymentDate}
                  onChange={(value) => {
                    repaymentDate(value);
                  }}
                  xs={2}
                  isLoading={isLoading}
                />

                {isDirect && (
                  <Grid container item direction="row" xs={1}>
                    <Grid container item sm={12} p={2}>
                      <ButtonComponent
                        startIcon={<CalculateIcon style={{ marginLeft: "10px" }} />}
                        variant="contained"
                        onClick={() => {
                          calculateTrailCalButtonClick();
                        }}
                        disabled={isLoading}
                        loadingbtn={true}
                        loading={isLoading}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Box mt={2}>
              <Grid
                style={{
                  height: isDirect ? "60vh" : "320px",
                  overflowY: "auto",
                }}
                container
                direction="column"
              >
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">
                          <b>Terms</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Payment Date</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Capital Recovery</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Interest Recovery</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Termly Installment</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Capital Outstanding</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {trailCalTableData?.map((item: any, i) => {
                        return (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row" align="right">
                              {i + 1}
                            </TableCell>
                            <TableCell align="right">{dateFormatWithDate(item?.paymentDate)}</TableCell>
                            <TableCell align="right">{formatCurrency(item?.capitalRecovery, 2)}</TableCell>
                            <TableCell align="right">{formatCurrency(item?.interestRecovery, 2)}</TableCell>
                            <TableCell align="right">{formatCurrency(item?.termlyInstallment, 2)}</TableCell>
                            <TableCell align="right">{formatCurrency(item?.capitalOutstanding, 2)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {errorMessage != "" && (
                  <Box pt={1}>
                    <Alert severity="error">{errorMessage}</Alert>
                  </Box>
                )}
              </Grid>
            </Box>
            {!isDirect && (
              <Grid container item direction="row" xs={12} justifyContent="flex-end" mt={1}>
                <Grid container item direction="row" xs={4} mb={1}>
                  <Grid container item sm={12} justifyContent="flex-end">
                    <ButtonComponent
                      startIcon={<SendIcon />}
                      title={"Confirm"}
                      variant="contained"
                      onClick={() => {
                        saveTrailCalculatorJson();
                      }}
                      style={{ maxHeight: "40px", marginTop: "auto" }}
                      disabled={isLoading}
                      loadingbtn={true}
                      loading={isLoading}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
