import React from 'react';

import { Box, Grid, Stack } from '@mui/material';

import { Downgraded } from '@hookstate/core';
import { userDetails } from '../../../../../configs/mainStore';
import { ButtonComponent } from '../../../../InputComponents/ButtonComponent/ButtonComponent';
import { TextBoxComponent } from '../../../../InputComponents/TextBoxComponent/TextBoxComponent';
import { CMTextInput } from '../../../CommunicationService/CMTextInput';
import { closeGlobalModal } from '../../../GlobalModal/GlobalModal';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

import { addToaster } from '../../../GlobalToast';

import { addCreditAuditData } from '../../../../../services/creditFileApiCall';
import { closeBtn } from '../../../../../styles';

export const AddCreditAuditModal = (params) => {
  const { userId }: any = userDetails.attach(Downgraded).get();

  const [comment, setComment] = React.useState('');

  const [isLoading, setIsLoading] = React.useState<any>(false);

  const [errorMessage, setErrorMessage] = React.useState<any>({ comment: '' });

  const onSubmit = async () => {
    let isValid = true;

    let tempError = errorMessage;
    if (comment === '') {
      tempError = { ...tempError, ...{ comment: 'Required!' } };
      isValid = false;
    }

    setErrorMessage(tempError);

    if (isValid) {
      setIsLoading(true);

      const result = await addCreditAuditData(params?.applicationId, comment, userId);

      closeGlobalModal();

      if (result?.msg) {
        addToaster({
          status: 'success',
          title: 'Updated',
          message: 'Credit Audit Comment Added',
        });

        params?.callBackOnAction();
      } else if (result?.error) {
        addToaster({
          status: 'warning',
          title: 'Not Updated',
          message: 'Update Error',
        });
      } else {
        addToaster({
          status: 'error',
          title: 'Error',
          message: 'Something went Wrong!',
        });
      }

      setIsLoading(false);
    }
  };

  const onChangeField = (value) => {
    setComment(value);

    if (value === '') {
      setErrorMessage({ ...errorMessage, ...{ comment: 'Required!' } });
    } else {
      setErrorMessage({ ...errorMessage, ...{ comment: '' } });
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">{'Credit Audit'}</div>
      </Stack>

      <Grid container spacing={1} mt={1} rowSpacing={1} className="modal-text-field">
        <Grid item xs={12} md={12} className="">
          <CMTextInput
            desc={'Comment'}
            component={
              <TextBoxComponent
                onChange={(e) => {
                  onChangeField(e.target.value);
                }}
                multiline={true}
                minRows={6}
                value={comment}
                disabled={isLoading}
              />
            }
            error={errorMessage?.comment !== '' ? true : false}
          />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="CLOSE"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={() => {
            closeGlobalModal();
          }}
          disabled={isLoading}
          loadingbtn={true}
          loading={isLoading}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="SUBMIT"
          variant="contained"
          onClick={() => {
            onSubmit();
          }}
          disabled={isLoading}
          loadingbtn={true}
          loading={isLoading}
          color="info"
        />
      </Stack>
    </Box>
  );
};
