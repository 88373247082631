import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { FormDeletePropsType } from "../../../configs/interfaces";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import { Dialog } from "../DialogComponent/Dialog";
import { closeGlobalModal } from "../GlobalModal/GlobalModal";
import { InputFieldsStack } from "../InputFieldsStack/InputFieldsStack";

export const SavedFormDelete: FC<FormDeletePropsType> = ({ open, onClose, onDelete, title = "Discard Selected From ?", description = `This action will be erase all the data saved about this form !` }) => {
  return (
    <Dialog
      open={open}
      title={title}
      children={<Typography>{description}</Typography>}
      actions={
        <InputFieldsStack>
          <ButtonComponent title="Not now" onClick={onClose} />
          <ButtonComponent title="Discard" onClick={onDelete} sx={{ color: "red" }} />
        </InputFieldsStack>
      }
    />
  );
};

export const ConfirmDialogModal: FC<FormDeletePropsType> = ({ onAction, description = `This action will be erase all the data saved about this form !` }) => {
  return (
    <Stack>
      <Typography variant="body2" color={"gray"}>
        {description}
      </Typography>
      <Stack direction={"row-reverse"} mt={5} flex={1}>
        <ButtonComponent
          title="Cancel"
          onClick={() => {
            closeGlobalModal();
          }}
          sx={{ color: "red" }}
        />
        <ButtonComponent title="Confirm" onClick={onAction} />
      </Stack>
    </Stack>
  );
};
