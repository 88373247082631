import { useHookstate } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { workflowMasterData } from "../../../../../configs/mainStore";
import { getHistoryDataForApplication, getStageColors } from "../../../../../services/creditFileApiCall";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import ProcessFlow from "./SubComponents/ProcessFlow";
import UnitFlow from "./SubComponents/UnitFlow";

const Timeline = ({ applicationId, currentApplicationWfData, innerComponentData }) => {
    const { wf_stages: stages }: any = useHookstate(workflowMasterData);
    const { applicationSubmittedDate } = currentApplicationWfData;
    const [loading, setLoading]: any = useState(true);
    const [items, setItems]: any = useState([]);
    const stageMap = stages.get()?.reduce((acc, stage) => {
        acc[stage.id] = stage.name;
        return acc;
    }, {});
    const [stageColors, setStageColors]: any = React.useState([]);


    const [selectedFlow, setSelectedFlow]: any = React.useState('process');

    useEffect(() => {
        prepareDataSource();
    }, []);

    const prepareDataSource = async () => {
        const [stages = [], data] = await Promise.all([getStageColors(), getHistoryDataForApplication(applicationId)]);
        setStageColors(stages);
        calcTimeLines(data);
    }

    const calcTimeLines = (data) => {
        if (!data || !(data instanceof Array)) {
            return;
        }

        data = data.reverse();

        let startDate = new Date(applicationSubmittedDate);

        const list = data.reduce((acc, item) => {
            const data = { ...item };
            const recordedDate = new Date(data.timeStamp);
            const duration = recordedDate.getTime() - startDate.getTime();
            data.duration = duration / (3600 * 1000)
            acc[acc.length] = data;
            startDate = recordedDate;
            return acc;
        }, []);

        setItems(list);

        setLoading(false);
    }

    const formatDuration = (durationInHours) => {
        const minutes = Math.round((durationInHours * 60) % 60);
        const hours = Math.floor(durationInHours % 24);
        const days = Math.floor(durationInHours / 24);

        return `${days > 0 ? `${days}d ` : ""}${hours > 0 ? `${hours}h ` : ""}${minutes > 0 ? `${minutes}m` : ""}`.trim();
    };

    if (loading) {
        return <Stack flex={1} justifyContent={'center'} alignItems={'center'} height={"200px"}><CircularProgressComponent
            size={30}
        /></Stack>;
    }

    const handleFlowClick = (value) => {
        setSelectedFlow(value);
    }

    return <Stack spacing={2} padding={2} style={{ backgroundColor: "white" }} position={"relative"}>
        <Grid container item gap={2} mt={1} position={"sticky"} top={"0"} zIndex={"999"} style={{ backgroundColor: "white" }} p={2}>
            <ButtonComponent variant={selectedFlow === "process" ? "contained" : "outlined"} title="Process Flow" onClick={() => handleFlowClick('process')} />
            <ButtonComponent variant={selectedFlow === "unit" ? "contained" : "outlined"} title="Unit Flow" onClick={() => handleFlowClick('unit')} sx={{ backgroundColor: "white" }} />
        </Grid>

        <Grid>
            {selectedFlow === "process" && <ProcessFlow items={items} stageMap={stageMap} stageColors={stageColors} />}

            {selectedFlow === "unit" && <UnitFlow items={items} formatDuration={formatDuration} stageMap={stageMap} stageColors={stageColors} />}
        </Grid>
    </Stack>;
}

export default Timeline;