import { useHookstate } from "@hookstate/core";
import { Stack } from "@mui/material";
import { useEffect } from "react";
import { fetchCreditAdminUnit } from "../../../../../../services/cauService";
import { getApplicationData } from "../../../../../../services/creditFileApiCall";
import { fetchInspectionService } from "../../../../../../services/inspectionService";
import Content from "../../../../ManualInspectionAllocation/Content";
import Header from "../../../../ManualInspectionAllocation/Header";

const useResolveApplication = (wfdata, cb) => {
  useEffect(() => {
    if (wfdata && wfdata.applicationId) {
      getApplicationData(wfdata.applicationId).then((response) => {
        // console.log(response);
      });
    }
  }, []);
};

export const useResolveCreditAdminUsers = (cb) => {
  useEffect(() => {
    fetchCreditAdminUnit().then((response) => {
      cb(response);
    });
  }, []);
};

export const useResolveCreditAdminOfficers = (data, cb) => {
  useEffect(() => {
    fetchInspectionService(data).then((response) => {
      cb(response);
    });
  }, []);
};

const ManualInspectionAllocation = ({ dataCollection, wfData, creditData }) => {
  const { formData } = creditData;
  const getCreditData = formData?.creditData;
  const loanAmount = formData?.creditData?.loanAmount;

  const productCatalog = `${getCreditData?.type ?? "*"}-${getCreditData?.sector ?? "*"}-${getCreditData?.scheme ?? "*"
    }`;

  const loading: any = useHookstate(true);
  const inspection: any = useHookstate([]);

  let payload: any = {
    group: null,
    user: null,
  };

  useResolveCreditAdminOfficers(
    {
      applicationId: wfData.applicationId,
      catalog: productCatalog,
      loanAmount: loanAmount,
    },
    (result) => {
      loading.set(false);
      inspection.set(result);
    }
  );

  const userDidSelect = (user) => {
    payload.user = user;
    dataCollection.set({ ...dataCollection.get(), newAssignee: user.id });
  };

  const groupDidSelect = (group) => {
    payload.group = group;
  };

  const actions = {
    userDidSelect,
    groupDidSelect,
  };

  return (
    <Stack direction="column" spacing={2} justifyContent="space-between">
      <Header></Header>

      <Content data={{ inspection, selection: payload, loading }} actions={actions}>
        {" "}
      </Content>
    </Stack>
  );
};

export default ManualInspectionAllocation;
