import { useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import { masterDataDetails } from "../../../../../configs/mainStore";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import CreditFileDocumentsViewSection from "../CreditFileDocumentsViewSection";
import ApplicationHeader from "./ApplicationHeader";
import BusinessIncome from "./Income/BusinessIncome";
import CultivationIncome from "./Income/CultivationIncome";
import PersonnelIncome from "./Income/PersonnelIncome";

const IncomeDetails = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion = false, printMode = false, isGuarantor = false, isJointBurrower = false, guarantorJBSection = "", innerComponentData, tabsToEdit }) => {
  const masterData: any = useState(masterDataDetails);
  const incomeData: any = data?.formData?.incomeData;
  const personnelIncome = incomeData?.personnelIncome;
  const businessIncome = incomeData?.businessIncome;
  const cultivationIncome = incomeData?.cultivationIncome;

  const { formStructure: { primaryApplicant } } = data;
  const { label = "Income Details" } = primaryApplicant?.[innerComponentData?.id];

  let personnelIncomeProps = { isGuarantor, isJointBurrower, personnelIncome, masterData }
  return (
    <div className={`mainSectionWrapper ${isMainContactDetails ? "full-width full-height" : "full-width"}`}>
      {isMainContactDetails && (!isRawVersion || printMode) && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={label ? label : "Income Details"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)} />}
      {!isMainContactDetails && (
        <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
          {label ? label : "Income Details"}
        </Grid>
      )}
      <Grid container className={isMainContactDetails ? "inner-component-height padding-sm" : "margin-xs-t"}>
        <Grid container>
          <Grid container className={`border-radius-5 ${isMainContactDetails ? (isRawVersion ? "" : "sub-header-height") : ""}`}>
            {(!personnelIncome && !businessIncome && !cultivationIncome) ? (
              <ErrorMessageComponent headMessage={""} errorMessage={"No Income"} showImg={false} />
            ) : (
              <>
                <CreditFileDocumentsViewSection data={data} applicationId={applicationId} isGuarantor={isGuarantor} guarantorJBSection={guarantorJBSection} isJointBurrower={isJointBurrower} innerComponentData={innerComponentData} />
                <PersonnelIncome {...personnelIncomeProps} />
                <BusinessIncome businessIncome={businessIncome} masterData={masterData} isMainContactDetails={isMainContactDetails} />
                <CultivationIncome cultivationIncome={cultivationIncome} masterData={masterData} />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default IncomeDetails;
