import { useTranslation } from "react-i18next";
import { creditCardAnnualAmount, incomeExpenseFrequency, loanLeaseAnnualAmount, overDraftAnnualAmount, pawningAnnualAmount } from "../../../../../utility/util";

export class JBController {
  dataPayload: any;
  incomeData: any;
  expenseData: any;
  lifeInsData: any;
  liabilityData: any;

  constructor(data) {
    try {
      this.dataPayload = data.jbdata.get();
    } catch {
      this.dataPayload = data.jbdata || [];
    }

    try {
      this.incomeData = this.dataPayload.map((obj) => {
        try {
          return obj.incomeData && obj.incomeData.get();
        } catch {
          return obj.incomeData || [];
        }
      });
    } catch (error) {
      this.incomeData = {};
    }

    try {
      this.expenseData = this.dataPayload.map((obj) => {
        try {
          return obj.expenseData && obj.expenseData.get();
        } catch {
          return obj.expenseData || [];
        }
      });
    } catch (error) {
      this.expenseData = {};
    }

    try {
      this.lifeInsData = this.dataPayload.map((obj) => {
        try {
          return obj.lifeInsuranceData && obj.lifeInsuranceData.get();
        } catch {
          return obj.lifeInsuranceData || [];
        }
      });
    } catch (error) {
      this.lifeInsData = {};
    }

    try {
      this.liabilityData = this.dataPayload.map((obj) => {
        try {
          return obj.inquiryOfObligationsData && obj.inquiryOfObligationsData.get();
        } catch {
          return obj.inquiryOfObligationsData || [];
        }
      });
    } catch (error) {
      this.liabilityData = {};
    }
  }

  // Income And Expenses Details - INCOME
  get annualSalary(): number {
    let total = 0.0;

    try {
      if (!this.incomeData || !this.incomeData.map((obj) => obj.personnelIncome)) {
        return total;
      }

      let personnelIncome = [];
      try {
        personnelIncome = this.incomeData.map((obj) => obj.personnelIncome.get());
      } catch (error) {
        personnelIncome = this.incomeData.map((obj) => obj.personnelIncome);
      }

      personnelIncome.map((obj: any) => {
        let data = [];

        try {
          data = obj.get();
        } catch {
          data = obj;
        }

        let incomeSum = data.map((obj) => this.calculateNetAnnualIncome(obj));

        let segments = incomeSum;

        if (segments.length > 0) {
          let sum = segments.reduce((sum, value) => sum + value, 0);
          total = sum;
        }
      });
    } catch (error) { }

    return total;
  }

  calculateNetAnnualIncome = (obj: any) => {
    try {
      let netIncome = parseFloat(obj.basicSalary.toString()) + parseFloat((obj.fixedAllowance || "0").toString()) + parseFloat((obj.averageVariableAllowance || "0").toString()) - parseFloat((obj.lastThreeAverageDeduction || "0").toString());
      let annualTerms = obj.frequencyForMonth * obj.frequencyForYear;
      let netAnnualIncome = netIncome * annualTerms;
      return netAnnualIncome;
    } catch (error) {
      return 0.0;
    }
  };

  calculateAnnualSalary = (obj: any) => {
    try {
      return parseFloat(obj.basicSalary.toString()) + parseFloat((obj.fixedAllowance || "0").toString()) + parseFloat((obj.averageVariableAllowance || "0").toString()) - parseFloat((obj.lastThreeAverageDeduction || "0").toString());
    } catch (error) {
      return 0.0;
    }
  };

  get annualBusiness(): number {
    let total = 0.0;

    try {
      if (!this.incomeData || !this.incomeData.map((obj) => obj.businessIncome)) {
        return total;
      }

      let businessIncome = [];
      try {
        businessIncome = this.incomeData.map((obj) => obj.businessIncome.get());
      } catch (error) {
        businessIncome = this.incomeData.map((obj) => obj.businessIncome);
      }

      businessIncome.map((obj: any) => {
        let data = [];

        try {
          data = obj.get();
        } catch {
          data = obj;
        }

        total = total + this.extractApplicantIncomeValue("income", data, "incomeBusiness") - this.extractApplicantIncomeValue("expense", data, "expenseBusiness");
      });
    } catch (error) { }

    // JB's salary details
    return total;
  }

  private extractApplicantIncomeValue(node1, data, field) {
    try {
      // let incomes = data[node1].map((obj) => parseFloat(obj[field] || "0"));
      let incomes = data[node1].map((obj) => {
        return this.calculateNetIncomeOrExpense(obj, node1, field);
      });
      let sum =
        incomes.length === 0
          ? 0
          : incomes.reduce((total, num) => {
            return total + num;
          });
      return sum;
    } catch (error) {
      return 0.0;
    }
  }

  calculateNetIncomeOrExpense = (obj: any, node1: any, field: any) => {
    const { t: translate } = useTranslation();

    const frequencyData = incomeExpenseFrequency.map((f) => ({
      ...f,
      name: translate(f.name),
    }));

    try {
      let value = parseFloat(obj[field] || "0");
      let annualTerms: any = "";
      let annualValue: any = "";

      if (node1 == "income") {
        if (field == "incomeBusiness") {
          annualTerms = obj.incomeFrequencyForMonthBusiness * obj.incomeFrequencyForYearBusiness;
          annualValue = value * annualTerms;
        } else if (field == "seasonalIncome") {
          let frequencyObj: any = {};

          frequencyData.forEach((frequency) => {
            if (frequency.id == obj.incomeFrequencyCultivation) {
              frequencyObj = frequency;
            }
          });
          annualTerms = frequencyObj.defaultFrequencyForMonth * frequencyObj.defaultFrequencyForYear;
          annualValue = value * annualTerms;
        }
      } else if (node1 == "expense") {
        if (field == "expenseBusiness") {
          annualTerms = obj.expenseFrequencyForMonthBusiness * obj.expenseFrequencyForYearBusiness;
          annualValue = value * annualTerms;
        } else if (field == "seasonalExpense") {
          let frequencyObj: any = {};

          frequencyData.forEach((frequency) => {
            if (frequency.id == obj.expenseFrequencyCultivation) {
              frequencyObj = frequency;
            }
          });
          annualTerms = frequencyObj.defaultFrequencyForMonth * frequencyObj.defaultFrequencyForYear;
          annualValue = value * annualTerms;
        }
      }
      return annualValue;
    } catch (error) {
      return 0.0;
    }
  };

  get annualCultivation(): number {
    let total = 0.0;

    try {
      if (!this.incomeData || !this.incomeData.map((obj) => obj.cultivationIncome)) {
        return total;
      }

      let cultivationIncome = [];
      try {
        cultivationIncome = this.incomeData.map((obj) => obj.cultivationIncome.get());
      } catch (error) {
        cultivationIncome = this.incomeData.map((obj) => obj.cultivationIncome);
      }

      cultivationIncome.map((obj: any) => {
        let data = [];

        try {
          data = obj.get();
        } catch {
          data = obj;
        }

        total = total + this.extractApplicantIncomeValue("income", data, "seasonalIncome") - this.extractApplicantIncomeValue("expense", data, "seasonalExpense");
      });
    } catch (error) { }

    // JB's details
    return total;
  }

  get annualTotalIncome(): number {
    let total = 0.0;
    for (const salary of [this.annualSalary, this.annualBusiness, this.annualCultivation]) {
      total = total + parseFloat(salary.toString());
    }
    return total;
  }

  // Income And Expenses Details - EXPENSES
  get annualOther(): number {
    let total = 0.0;

    try {
      if (!this.expenseData || !this.expenseData.map((obj) => obj.expenses)) {
        return total;
      }

      let expenses: any = [];
      try {
        expenses = this.expenseData.map((obj) => obj.expenses).get();
      } catch (error) {
        expenses = this.expenseData.map((obj) => obj.expenses) || {};
      }

      expenses.map((obj: any) => {
        let data = [];

        try {
          data = obj.get();
        } catch {
          data = obj;
        }

        let expenseTotal = 0.0;
        try {
          expenseTotal = data
            .map((obj) => {
              const expAmount = parseFloat(obj["expenseAmount"] || "0.0");
              const monthly = parseFloat(obj["frequencyForAnnual"] || "1.0");
              const annually = parseFloat(obj["frequencyForMonth"] || "1.0");

              return expAmount * monthly * annually;
            })
            .reduce((sum, value) => sum + value, 0);
        } catch (error) { }

        let lifeInsuranceData: any[] = [];
        try {
          lifeInsuranceData = this.lifeInsData.get();
        } catch (error) {
          lifeInsuranceData = this.lifeInsData || [];
        }

        lifeInsuranceData.map((obj: any) => {
          let data = [];

          try {
            data = obj.get();
          } catch {
            data = obj;
          }

          try {
            let premium = 0;

            try {
              premium = data.map((obj) => parseFloat(obj["monthlyPremium"] || "0")).reduce((sum, value) => sum + value, 0);
            } catch (error) {
              premium = 0;
            }

            const premiumAnnual = premium * 12;

            total = total + expenseTotal + premiumAnnual;
          } catch (error) {
            total = 0.0;
          }
        });
      });
    } catch (error) { }

    // JB's details
    return total;
  }

  get annualLiability(): number {
    let total = 0.0;

    try {
      if (!this.liabilityData) {
        return total;
      }

      try {
        let liabilities: any = [];
        try {
          liabilities = this.liabilityData.map((obj) => obj.loanLease).get();
        } catch (error) {
          liabilities = this.liabilityData.map((obj) => obj.loanLease) || [];
        }

        const leaseTotalAmount = liabilities.map((obj: any) => {
          let data = [];

          try {
            data = obj.get();
          } catch {
            data = obj;
          }

          const leaseTotalAmounts = data.map((obj) => {
            const amountLeft = parseFloat(obj["installmentLeft"] || "0.0");
            const installment = parseFloat(obj["installmentAmount"] || "0.0");

            return loanLeaseAnnualAmount(amountLeft, installment);
          });

          return leaseTotalAmounts.length > 0 ? leaseTotalAmounts.reduce((sum, value) => sum + value, 0) : 0.0;
        });

        try {
          liabilities = this.liabilityData.map((obj) => obj.pawning).get();
        } catch (error) {
          liabilities = this.liabilityData.map((obj) => obj.pawning) || [];
        }

        const pawningTotalAmount = liabilities.map((obj: any) => {
          let data = [];

          try {
            data = obj.get();
          } catch {
            data = obj;
          }

          const pawningTotalAmounts = data.map((obj) => {
            const outstanding = parseFloat(obj["outstanding"] || "0.0");
            const interest = parseFloat(obj["annualInterest"] || "0.0");

            return pawningAnnualAmount(outstanding, interest);
          });

          return pawningTotalAmounts.length > 0 ? pawningTotalAmounts.reduce((sum, value) => sum + value, 0) : 0.0;
        });

        try {
          liabilities = this.liabilityData.map((obj) => obj.creditCard).get();
        } catch (error) {
          liabilities = this.liabilityData.map((obj) => obj.creditCard) || [];
        }

        const creditCardTotalAmount = liabilities.map((obj: any) => {
          let data = [];

          try {
            data = obj.get();
          } catch {
            data = obj;
          }

          return this.extractApplicantLiabilityValue(data, "limit", creditCardAnnualAmount);
        });

        try {
          liabilities = this.liabilityData.map((obj) => obj.overdraft).get();
        } catch (error) {
          liabilities = this.liabilityData.map((obj) => obj.overdraft) || [];
        }

        const overDraftTotalAmount = liabilities.map((obj: any) => {
          let data = [];

          try {
            data = obj.get();
          } catch {
            data = obj;
          }

          return this.extractApplicantLiabilityValue(data, "outstanding", overDraftAnnualAmount);
        });

        total = total + parseFloat(creditCardTotalAmount) + parseFloat(leaseTotalAmount) + parseFloat(overDraftTotalAmount) + parseFloat(pawningTotalAmount);
      } catch (error) {
        total = 0.0;
      }
    } catch (error) { }
    // JB's details
    return total;
  }

  private extractApplicantLiabilityValue(data, field, evaluator: any = null) {
    try {
      let liabilities = data.map((obj) => (!evaluator ? parseFloat(obj[field] || "0") : evaluator(parseFloat(obj[field] || "0"))));

      let sum =
        liabilities.length === 0
          ? 0
          : liabilities.reduce((total, num) => {
            return total + num;
          });
      return sum;
    } catch (error) {
      return 0.0;
    }
  }

  get annualTotalExpenses(): number {
    return this.annualOther + this.annualLiability;
  }
}
