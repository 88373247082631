import { useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Stack } from "@mui/material";
import React, { createRef, useEffect } from "react";
import {
  userDetails,
  workflowMasterData,
} from "../../../../../configs/mainStore";
import { uploadAuditFile } from "../../../../../services/creditFileApiCall";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../GlobalToast";

import { documentUploadData } from "../../../../../configs/stores/document-upload-store/documentUploadStore";
import { getDocumentDetailsList } from "../../../../../services/documentUploadService";
import { closeBtn } from "../../../../../styles";
import AppConfig from "../../../../../utility/AppConfig";
import AutoCompleteProxyComponent from "../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import ModalInputComponent from "../../../../InputComponents/ModalInputComponent/ModalInputComponent";
import { CMTextInput } from "../../../CommunicationService/CMTextInput";
import { FileUploadComponent } from "../../../DropzoneComponent/FileUploadComponent";

const AuditModal = (params) => {
  const { documentDetailsList }: any = useState(documentUploadData);
  const [documentId, setDocumentId] = React.useState<any>();

  React.useState<any>();

  const initialData = { title: "", description: "" };
  const showErrors = useState(false);
  const auditState: any = useState(initialData);
  const loginUser = useState(userDetails);
  const { wf_system_users }: any = useState(workflowMasterData);

  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [dropdownUserList, setDropdownUserList] = React.useState<any>([]);

  const docUploadRef: any = createRef();

  useEffect(() => {
    (async () => {
      let data;
      let documentId;

      if (Object.values(documentDetailsList.get()).length <= 0) {
        data = await getDocumentDetailsList();
      } else {
        Object.values(documentDetailsList.get()).forEach((doc: any) => {
          if (doc.documentKey === "AUDIT_FILE") {
            documentId = doc.documentId;
            setDocumentId(documentId);
          }
        });
        setIsLoading(false);
      }

      if (data) {
        documentDetailsList.set(data);
        setIsLoading(false);

        Object.values(data).forEach((doc: any) => {
          if (doc.documentKey === "AUDIT_FILE") {
            documentId = doc.documentId;
            setDocumentId(documentId);
          }
        });
      } else {
        // setErrorMessage("Something went Wrong!")
      }
    })();
  }, []);

  useEffect(() => {
    let tempUserList: any = [];
    wf_system_users?.get()?.map((item: any) => {
      if (item?.id !== loginUser?.get()?.userId) {
        tempUserList.push({ name: item?.fullName, id: item.id });
      }
    });

    // getDocumentsListData();

    // getDocumentId();

    setDropdownUserList(tempUserList);
  }, []);

  const saveAudit = async () => {
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      setIsLoading(true);

      const primaryDocUploadResult =
        await docUploadRef?.current?.uploadSingleImg();

      if (primaryDocUploadResult?.status) {
        addToaster({
          status: "success",
          title: "Updated",
          message: "New Audit Record Added",
        });

        if (
          params.callBackOnAction &&
          params.callBackOnAction instanceof Function
        ) {
          params.callBackOnAction(primaryDocUploadResult?.data?.data);
        }
        AppConfig.invokers.audit();
        closeGlobalModal();
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: primaryDocUploadResult?.message,
        });
      }

      setIsLoading(false);
    }
  };

  const closeAudit = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return auditState.title.get() === "" || auditState.description.get() === "";
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  const extractValue = (value) => {
    if (value === "null") {
      return null;
    }
    if (value === "undefined") {
      return null;
    }
    if (value === "") {
      return null;
    }
    if (!value) {
      return null;
    }
    return value;
  };

  return (
    <Box>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <div className="basic-font font-size-18 basic-font-color-bold">
          Create Audit
        </div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Audit Title"
            component={
              <TextBoxComponent
                value={auditState?.title?.get()}
                onChange={(e) => {
                  resetError();
                  auditState.title.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                minRows={1}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && auditState.risk.get() === ""}
          />
        </Grid>
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Audit Description"
            component={
              <TextBoxComponent
                value={auditState?.description.get()}
                onChange={(e) => {
                  resetError();
                  auditState.description.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                minRows={12}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && auditState.risk.get() === ""}
          />
        </Grid>
        {params?.enableDropdown === true && (
          <Grid container item xs={12}>
            <ModalInputComponent
              title="User"
              component={
                <AutoCompleteProxyComponent
                  label={"Select User"}
                  value={extractValue(auditState?.approvalFrom?.get())}
                  options={dropdownUserList}
                  onChange={(e) =>
                    auditState?.approvalFrom?.set(e ? e.id : null)
                  }
                />
              }
              isMandatory={true}
              showErrors={showErrors.get() && !auditState.user.get()}
            />
          </Grid>
        )}
        <Grid item xs={12} md={12} className="">
          <CMTextInput
            desc={"Upload Audit File"}
            component={
              <FileUploadComponent
                fileDetails={{
                  //TODO AUDIT DOC GET FROM DB
                  ref: docUploadRef,
                  isMandatory: true,
                  allowedFormats: "application/pdf",
                  maxFileSize: 4000000,
                  documentKey: "",
                }}
                uploadDataObject={{
                  title: auditState?.title?.get(),
                  description: auditState?.description.get(),
                  createdBy: loginUser?.userId?.get(),
                  applicationId: params?.applicationId,
                  workflowId: params?.currentWorkflow,
                  stageId: params?.currentWorkflowStage,
                  referralId: params?.referId,
                  approvalFrom: auditState?.approvalFrom?.get(),
                  documentId: documentId,
                }}
                isPreviewHave={false}
                fileUploadApi={uploadAuditFile}
              />
            }
          // error={errorMessage?.cribRecordKey !== '' ? true : false}
          />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeAudit}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={saveAudit}
          loadingbtn={true}
          loading={isLoading}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default AuditModal;
