import { Downgraded, useHookstate } from "@hookstate/core";
import { Box, Stack } from "@mui/material";
import React, { useMemo } from "react";
import AutoCompleteProxyComponent from "../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { InputGroup } from "../InputGroup/InputGroup";
import { CircularProgressComponent } from "../ProgressComponent/ProgressComponent";

const Content = ({ data, actions, children }) => {
  const selectedGroup = useHookstate(data.selection?.selectedGroup?.id?.get());
  const selectedUser = useHookstate(data.selection?.selectedUser?.id?.get());
  const [userList, setUserList]: any = React.useState([]);

  useMemo(() => {
    const userList: any = data.users.attach(Downgraded).get() || [];
    const formattedUserList: any[] = [];

    if (userList.length > 0) {
      userList.forEach((user) => {
        formattedUserList.push({ id: user?.id ? user?.id : "", name: user?.name ? user?.name.replaceAll("null", "") : "" });
      });
      setUserList(formattedUserList);
    }
  }, [data]);

  return (
    <Stack flex={1}>
      {/* <InputGroup title="Select Group" padding={2} marginTop={1}>
            <AutoCompleteProxyComponent
                options={data.groups}
                fullWidth={true}
                onChange={(e) => {
                    selectedGroup.set(e.id);
                    return actions.groupDidSelect(e);
                }}
                label={undefined}
                value={selectedGroup.get()}
                cls="select_basic"
            />
        </InputGroup> */}
      <InputGroup title="Select Bank Officer" padding={2} marginTop={1}>
        <Stack direction={"row"} alignItems="center">
          <AutoCompleteProxyComponent
            options={userList || []}
            fullWidth={true}
            onChange={(e) => {
              selectedUser.set(e.id);
              return actions.userDidSelect(e);
            }}
            label={undefined}
            value={selectedUser.get()}
            cls="select_basic"
          />

          {data.loading.get() && (
            <>
              <Box pl={2}></Box>
              <CircularProgressComponent size={24} />
            </>
          )}
        </Stack>
      </InputGroup>

      {children}
    </Stack>
  );
};

export default Content;
