import { Downgraded, useState } from "@hookstate/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Grid } from "@mui/material";
import React, { FC, useEffect } from "react";
import { bufferToBlobUrl } from "../../../../configs/base64Conversion";
import { documentSectionParticipantMapping } from "../../../../configs/constants/contants";
import { ERROR_CODES } from "../../../../configs/constants/errorCode";
import { creditData } from "../../../../configs/mainStore";
import {
  getCribRecordDocFile,
  getCribRecordList,
  getSectionDocuments,
  getSectionSingleDocument
} from "../../../../services/creditFileApiCall";
import AppConfig from "../../../../utility/AppConfig";
import { readStaticDocumentPath } from "../../../../utility/helpers/creditFileUtility";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { addToaster } from "../../GlobalToast";
import { CircularProgressComponent } from "../../ProgressComponent/ProgressComponent";

export interface CreditFileDocumentsViewSectionPropTypes {
  data?: any;
  applicationId: any;
  isGuarantor?: boolean;
  isJointBurrower?: boolean;
  guarantorJBSection?: any;
  securityDocumentKey?: string;
  isSecurityDocument?: boolean;
  expandedSection?: string;
  innerComponentData?: any;
}

const CreditFileDocumentsViewSection: FC<CreditFileDocumentsViewSectionPropTypes> = ({
  data,
  applicationId,
  isGuarantor,
  isJointBurrower,
  guarantorJBSection,
  securityDocumentKey,
  isSecurityDocument,
  expandedSection,
  innerComponentData,
}) => {
  const creditFileData: any = useState(creditData);
  const creditDataResponse: any = creditFileData.attach(Downgraded).get();
  const primaryApplicant = creditDataResponse?.formData || [];
  const primaryApplicantIndividualID = primaryApplicant?.personalData?.individualId;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [singleDocIsLoading, setSingleDocIsLoading] = React.useState<string>("");
  const [progress, setProgress] = React.useState<number>(0);
  const [documentUrl, setDocumentUrl] = React.useState<any>({});

  const { formData } = creditFileData.attach(Downgraded).get();
  const getCreditData = formData?.creditData;

  const productCatalog = `${getCreditData?.type}-${getCreditData?.sector}-${getCreditData?.scheme}`;

  const entryId = data?.formData?.personalData?.individualId;

  const expandKey = innerComponentData?.id;
  const sectionKey = isGuarantor === true || isJointBurrower === true ? guarantorJBSection : expandKey;

  const getKeyByValue = (mapping, targetValue) => {
    for (const key in mapping) {
      if (mapping[key].includes(targetValue)) {
        return key;
      }
    }
    return null; // Return null if the value is not found in any key
  };

  useEffect(() => {
    (async function () {
      setIsLoading(true);

      let participantKey: any = 1;

      if (isJointBurrower === true) {
        participantKey = 2;
      } else if (isGuarantor === true) {
        participantKey = 3;
      } else {
        participantKey = getKeyByValue(documentSectionParticipantMapping, sectionKey);
      }

      if (typeof getCreditData === "object" && getCreditData !== null) {
        const params = {
          sectionKey: sectionKey,
          productCatalog: productCatalog,
          applicationId: applicationId,
          entryId: entryId,
          participantKey: participantKey,
          primaryApplicantIndividualID: primaryApplicantIndividualID,
        };

        const response = await getSectionDocuments(params);

        setDocumentUrl(response);
      }

      if (sectionKey === "inquiry-of-obligations") {
        let cribDetails: any = {};
        const cribRecordResponse = await getCribRecordList(applicationId);

        if (cribRecordResponse) {
          const resultCribList = cribRecordResponse?.resultCribList;
          if (Object.keys(resultCribList).length > 0) {
            const getParticipantId = cribRecordResponse?.participantEntryMap;
            const docEntryID = getParticipantId[entryId];

            const cribData = resultCribList[docEntryID] || [];

            if (cribData.length > 0) {
              const getCribData = cribData[0];
              const docId = getCribData?.docId;

              const docResult = await getCribRecordDocFile(docId);

              if (docResult?.fileData?.data) {
                cribDetails = {
                  filePath: docResult?.filePath,
                  entryId: docEntryID,
                  documentName: "CRIB Record",
                };
              }
            }
          }
          if (Object.keys(cribDetails).length > 0) {
            setDocumentUrl((prevDocumentUrl) => [...prevDocumentUrl, cribDetails]);
          }
        }
      }

      setIsLoading(false);
    })();
  }, [getCreditData, entryId, sectionKey]);

  const openWithFileServer = async (path) => {
    path = readStaticDocumentPath(path);

    const popupWindow: any = window.open(
      `${path}`,
      "targetWindow",
      `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=500,
      height=500`
    );

    popupWindow.onload = () => {
      popupWindow.replaceState(null, '', applicationId);
    };

    window.addEventListener('message', (event) => {
      if (event.origin === window.location.origin) {
        if (event.data === 'documentReady') {
          history.replaceState(null, '', path);
        }
      }
    });
  }

  const previewClicked = async (blobUrl?: any) => {
    if (AppConfig.config.fileServer) {
      openWithFileServer(blobUrl);
      return;
    }
    setSingleDocIsLoading(blobUrl);
    const params = {
      blobUrl,
    };
    try {
      const response = await getSectionSingleDocument(params, (progress) => {
        setProgress(progress);
      });

      if (response?.status === ERROR_CODES.success) {
        let url = bufferToBlobUrl(response?.data?.fileData?.data, response?.data?.contentType, (progress) => { });
        window.open(
          `${url}`,
          "targetWindow",
          `toolbar=no,
          location=no,
          status=no,
          menubar=no,
          scrollbars=yes,
          resizable=yes,
          width=500,
          height=500`
        );
        setSingleDocIsLoading("");
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: "No Document Found",
        });
        setSingleDocIsLoading("");
      }
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: "No Document Found",
      });
      setSingleDocIsLoading("");
    }
  };

  const BuildProgress = (progress: number) => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgressComponent variant={progress < 10 ? undefined : "determinate"} size={16} value={progress} />
      </Box>
    );
  };

  return (
    <>
      {isLoading ? (
        <CircularProgressComponent size={25} />
      ) : (
        Array.isArray(documentUrl) &&
        documentUrl.length > 0 && (
          <Grid container p={1} style={{ backgroundColor: "#e6f0fa" }} mb={1}>
            <Grid item xs={6} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left">
              Documents
            </Grid>
            <Grid item xs={6} className="full-height full-width align-right" pr={1}>
              {documentUrl.map((obj: any, index) => {
                const title = obj?.documentName.toString().substring(0, 20);
                return (
                  <ButtonComponent
                    startIcon={singleDocIsLoading === obj?.filePath ? BuildProgress(progress) : <VisibilityIcon />}
                    title={title}
                    variant="outlined"
                    onClick={() => {
                      previewClicked(obj?.filePath);
                    }}
                    style={{ marginLeft: "10px" }}
                    disabled={isLoading}
                    loadingbtn={true}
                    loading={isLoading}
                    key={index}
                  />
                );
              })}
            </Grid>
          </Grid>
        )
      )}
    </>
  );
};

export default CreditFileDocumentsViewSection;
