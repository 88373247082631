import { Button, Stack } from "@mui/material";
import React from "react";
import { SelectComponent } from "../../../components/InputComponents/SelectComponent/SelectComponent";
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { select_basic } from "../../../styles";

function SelectHubModal(params) {
  const [selectHub, setSelectHub] = React.useState<any>("");
  const zone = params?.zoneMasterData;

  const handleSelectHub = (e: number) => {
    setSelectHub(e);
  };

  const saveSelectHub = () => {
    params.callBackOnAction(selectHub);
    closeGlobalModal();
  };

  return (
    <>
      <Stack m={1}>
        <SelectComponent
          label={"Select Hub"}
          value={selectHub}
          values={zone}
          onChange={(e) => handleSelectHub(e.target.value)}
          sx={select_basic}
        />
      </Stack>
      <Stack alignItems={"flex-end"}>
        <Button onClick={saveSelectHub} variant="contained" disabled={selectHub !== "" ? false : true}>
          Submit
        </Button>
      </Stack>
    </>
  );
}

export default SelectHubModal;
