import { Stack } from "@mui/material";
import CreditFileDocumentsViewSection from "../CreditFileDocumentsViewSection";
import ApplicationHeader from "./ApplicationHeader";
import IdentificationDetailItems from "./IdentificationDetails/IdentificationDetailItems";

const IdentificationDetails = ({
  data,
  applicationId,
  mainTabData = { isAppraisal: false },
  isMainContactDetails = true,
  label = undefined,
  isRawVersion = false,
  printMode = false,
  isGuarantor = false,
  isJointBurrower = false,
  guarantorJBSection = "",
  innerComponentData,
  tabsToEdit,
}) => {
  const params = { isMainContactDetails, isGuarantor, data, isJointBurrower };
  label = label ? label : data?.formStructure?.primaryApplicant?.[innerComponentData?.id]?.label;

  return <Stack>
    <Stack style={{ position: "sticky", top: "0px", zIndex: 100 }}>
      {isMainContactDetails && (!isRawVersion || printMode) && (
        <ApplicationHeader
          formData={data?.formData}
          requestId={data?.requestId}
          applicationId={applicationId}
          title={label ? label : "Personal Details"}
          isAppraisal={mainTabData?.isAppraisal}
          isRawVersion={isRawVersion}
          disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)}
        />
      )}
    </Stack>
    <Stack height="100%">
      <CreditFileDocumentsViewSection
        data={data}
        applicationId={applicationId}
        isGuarantor={isGuarantor}
        guarantorJBSection={guarantorJBSection}
        isJointBurrower={isJointBurrower}
        innerComponentData={innerComponentData}
      />
      <IdentificationDetailItems {...params} />
    </Stack>
  </Stack>
};

export default IdentificationDetails;
