import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { closeBtn } from "../../../../../styles";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";

import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CheckBoxComponent } from "../../../../InputComponents/CheckBoxComponent/CheckBoxComponent";

const ChargesModalV2 = (params) => {
  const [checkedItems, setCheckedItems] = React.useState<any>([]);
  const [isSaveDisable, setIsSaveDisable] = React.useState<any>(true);
  const [searchText, setSearchText] = React.useState("");
  const any = React.useState(false);

  const { chargesMap, chargesList } = params || { chargesMap: { activeCharges: [] } };
  const { activeCharges = [] } = chargesMap || {};

  const activeChargeIds = activeCharges?.get()?.map(charge => charge.typeId) || [];
  const activeProductCharges = chargesList?.filter(charge => charge.isActive === 1).filter(charge => !activeChargeIds.includes(charge.typeId));

  const saveCharges = async () => {
    closeGlobalModal();
    params.addNewCharges(checkedItems);
  };

  const handleToggle = (value: any) => () => {
    const currentIndex = checkedItems.findIndex((obj: any) => obj.typeId === value.typeId);
    const newChecked: any = [...checkedItems];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedItems(newChecked);

    setIsSaveDisable(newChecked.length === 0);
  };

  const handleToggleAll = (e) => {
    if (e.target.checked) {
      setCheckedItems(activeProductCharges);
      setIsSaveDisable(false);
    } else {
      setCheckedItems([]);
      setIsSaveDisable(true);
    }
  };

  const filteredItems = () => {
    if (!activeProductCharges) {
      return [];
    }
    const items = activeProductCharges.filter((obj) => {
      return obj.desc && obj.desc.toLowerCase().includes(searchText.toLowerCase());
    });
    return items;
  };

  return (
    <Stack>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" mt={2}>
        <div className="basic-font font-size-18 basic-font-color-bold">Add Charge</div>
      </Stack>

      <Stack style={{ top: "0px", position: "sticky", zIndex: 100, background: "white" }}>
        <Divider style={{ marginBottom: "10px", marginTop: "10px" }} />
        <Stack>
          <Stack direction={"row"}>
            <CheckBoxComponent
              onChange={handleToggleAll}
              disabled={activeProductCharges.length === 0}
              inputProps={{
                "aria-label": "all Items selected",
              }}
            />
            <TextBoxComponent
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              variant="outlined"
              size="small"
              multiline={true}
              minRows={1}
            />
          </Stack>
          <Stack ml={6}>
            <Typography variant="caption" color={"gray"}>{`${checkedItems.length}/${activeProductCharges.length} selected`}</Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack>
        <List
          sx={{
            width: "100%",
            maxWidth: "100%",
            bgcolor: "background.paper",
          }}
        >
          {filteredItems().map((value: any) => {
            const labelId = `checkbox-list-label-${value.typeId}`;

            return (
              <ListItem key={value.typeId} role="listitem" button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checkedItems.findIndex((obj: any) => obj.typeId === value.typeId) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value.desc}`} />
              </ListItem>
            );
          })}
        </List>
      </Stack>

      <Stack style={{ bottom: "0px", position: "sticky", zIndex: 100, background: "white" }}>
        <Divider style={{ marginBottom: "10px", marginTop: "10px" }} />
        <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
          <ButtonComponent title="Close" startIcon={<CloseIcon />} variant="contained" onClick={closeGlobalModal} loadingbtn={true} style={closeBtn} />
          <ButtonComponent startIcon={<DoneIcon />} title="Add" variant="contained" onClick={saveCharges} loadingbtn={true} color="info" disabled={isSaveDisable} />
        </Stack>
      </Stack>
    </Stack>
  );
};


export default ChargesModalV2;
