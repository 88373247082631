import { useHookstate } from '@hookstate/core';
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import MinimizeIcon from "@mui/icons-material/Minimize";
import { Divider, Stack, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { ButtonComponent } from './components/InputComponents/ButtonComponent/ButtonComponent';
import { openGlobalModal } from './components/OtherComponents/GlobalModal/GlobalModal';
import { floatingWidgetState, globalImageDocumentsState } from './configs/mainStore';

const FloatingWidget = (props) => {
    const [minimize, setMinimize] = useState(false);
    const [dragPosition, setDragPosition]: any = useState(null);
    const _floatingWidgetState: any = useHookstate(floatingWidgetState);
    const nodeRef = useRef(null);

    if (!_floatingWidgetState.show.get()) {
        return <Stack></Stack>;
    }

    return (
        <Draggable onStop={() => { setMinimize(false); setDragPosition(null); }} position={dragPosition} defaultPosition={{ x: 0, y: 0 }} ref={nodeRef}>
            <div className={"floating-widget"} style={{ width: props.width || '300px' }}>
                <Stack direction={'column'} px={1}>
                    <Stack direction={'row'} justifyContent={'space-between'} mb={1}> <Typography>{props.title}</Typography>
                        <Stack direction="row" spacing={1}>
                            <MinimizeIcon
                                onClick={() => {
                                    setMinimize(true);
                                    setDragPosition({ x: 0, y: 0 });
                                }}
                            />
                            <CloseIcon
                                onClick={() => {
                                    _floatingWidgetState.show.set(false);
                                }}
                            />
                        </Stack>
                    </Stack>
                </Stack>
                <Divider></Divider>
                <Stack direction={'column'} height={minimize ? '0px' : '400px'} p={2} style={{ overflow: 'hidden', overflowY: 'auto' }}>
                    {props.children}
                </Stack>
            </div>
        </Draggable>
    );
};

export const FloatingWidgetImage = (props) => {
    const documents: any = useHookstate(globalImageDocumentsState);
    const docs = documents.get() || [];
    const [index, setIndex] = useState(0);

    if (!docs.length) {
        return <Stack></Stack>;
    }

    return (
        <Draggable defaultPosition={{ x: 0, y: 0 }}>
            <div className={"floating-widget"}>
                <Stack direction={'column'} width={'300px'} px={1}>
                    <Stack direction={'row'} justifyContent={'space-between'} mb={1}> <Typography>{props.title || "Document Preview"} </Typography>
                        <Stack direction="row" spacing={1}>
                            <InfoIcon
                                onClick={() => {
                                    openGlobalModal({
                                        modalSize: "xs",
                                        title: "",
                                        bodyId: "document-details-info-modal",
                                        close: false,
                                        modalParams: { document: docs[index] },
                                    });
                                }}
                            />
                            <CloseIcon
                                onClick={() => {
                                    documents.set("");
                                }}
                            />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack height={"400px"}>
                    {docs?.[index]?.filePath && <iframe src={`${docs?.[index]?.filePath}#toolbar=0`} width="100%" height="100%" loading="eager" allowFullScreen style={{
                        border: 0,
                    }} title='Info' />}
                </Stack>
                {docs.length > 1 && <Stack direction={"row"} mt={1}>
                    {docs.map((item, idx) => {
                        let { details } = item;
                        details = JSON.parse(details);
                        return <ButtonComponent key={details.name} onClick={() => setIndex(idx)} title={details.name}></ButtonComponent>
                    })}
                </Stack>}
            </div>
        </Draggable>
    );
};

export default FloatingWidget;