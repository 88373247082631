import { Stack } from "@mui/material";
import CreditFileDocumentsViewSection from "../CreditFileDocumentsViewSection";
import ApplicationHeader from "./ApplicationHeader";
import CreditDetailItem from "./CreditDetails/CreditDetailItem";

const CreditDetails = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion = false, printMode = false, innerComponentData, tabsToEdit }) => {
  const { formStructure: { primaryApplicant } } = data;
  const { label = "Credit Details" } = primaryApplicant?.[innerComponentData?.id];

  return <Stack>
    {(!isRawVersion || printMode) && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={label ? label : "Credit Details"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)} />}
    <Stack>
      <CreditFileDocumentsViewSection applicationId={applicationId} innerComponentData={innerComponentData} />
      <CreditDetailItem formData={data?.formData} />
    </Stack>
  </Stack>;
};

export default CreditDetails;
