import { Box, Grid, Stack, Typography } from "@mui/material";
import { standardDateFormatter } from "los-util";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import { DatePickerComponent } from "../../../../../../../InputComponents/DatePickerComponent/DatePickerComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";

export const TresuryBond = (props) => {
  const { t: translate } = useTranslation();

  const { tbValueOfSecurity, tbSecuredValue, tbDescription, tbLetterNumber, tbIssuingAuthority, tbMatureDate, tbBondAmount, tbCoupounRate, tbSpecialConditions } = props.fields || {};

  return (
    <div className="full-width full-height">
      <Grid container>
        <Stack spacing={1} m={1} className="full-width">
          <Box mt={2} className="securityWrapper" p={2}>
            <Fragment>
              {props?.isNotNeedComponentTitle ? (
                <></>
              ) : (
                <InputFieldsStack>
                  <Typography variant="subtitle1" pl={2.5}>
                    Tresury Bond {props.index > 0 ? "(" + parseInt(props.index) + ")" : ""}
                  </Typography>
                </InputFieldsStack>
              )}
              <InputFieldsStack>
                <InputGrid visibility={tbValueOfSecurity?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.tbValueOfSecurity?.get())} onChange={(e) => props?.doc?.tbValueOfSecurity?.set(e.target.value)} label={translate("Value of the Security")} onInput={OnDemandValueFormatter.numeric} required={tbValueOfSecurity?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={tbSecuredValue?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.tbSecuredValue?.get())} onChange={(e) => props?.doc?.tbSecuredValue?.set(e.target.value)} label={translate("Secured Value")} onInput={OnDemandValueFormatter.numeric} required={tbSecuredValue?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={tbDescription?.visible}>
                  <TextBoxComponent value={props?.doc?.tbDescription?.get()} onChange={(e) => props?.doc?.tbDescription?.set(e.target.value)} label={translate("Description")} required={tbDescription?.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.alphaNumeric} />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={tbLetterNumber?.visible}>
                  <TextBoxComponent value={props?.doc?.tbLetterNumber?.get()} onChange={(e) => props?.doc?.tbLetterNumber?.set(e.target.value)} label={translate("Letter Number")} onInput={OnDemandValueFormatter.numeric} required={tbLetterNumber?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={tbIssuingAuthority?.visible}>
                  <TextBoxComponent value={props?.doc?.tbIssuingAuthority?.get()} onChange={(e) => props?.doc?.tbIssuingAuthority?.set(e.target.value)} label={translate("Issuing Authority")} onInput={OnDemandValueFormatter.alphabeticalSpaces} required={tbIssuingAuthority?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={tbBondAmount?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.tbBondAmount?.get())} onChange={(e) => props?.doc?.tbBondAmount?.set(e.target.value)} label={translate("Bond Amount")} onInput={OnDemandValueFormatter.numeric} required={tbBondAmount?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={tbMatureDate?.visible}>
                  <DatePickerComponent
                    value={formattedDate(props?.doc?.tbMatureDate?.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.tbMatureDate?.set(preparedDate);
                      }
                    }}
                    label={translate("Mature Date (DD/MM/YYYY)")}
                    enableFuture={false}
                    disablePast={false}
                    required={tbMatureDate?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={tbSpecialConditions?.visible}>
                  <TextBoxComponent value={props?.doc?.tbSpecialConditions?.get()} onChange={(e) => props?.doc?.tbSpecialConditions?.set(e.target.value)} label={translate("Special Conditions")} onInput={OnDemandValueFormatter.alphabeticalSpaces} required={tbSpecialConditions?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={tbCoupounRate?.visible}>
                  <TextBoxComponent value={props?.doc?.tbCoupounRate?.get()} onChange={(e) => props?.doc?.tbCoupounRate?.set(e.target.value)} label={translate("Coupoun Rate ")} onInput={OnDemandValueFormatter.numeric} required={tbCoupounRate?.mandatory === 1 ? true : false} />
                </InputGrid>
              </InputFieldsStack>
            </Fragment>
          </Box>
        </Stack>
      </Grid>
    </div>
  );
};
