import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { ErrorMessageComponent } from '../../../../../../ErrorMessageComponent';
import { CircularProgressComponent } from '../../../../../../ProgressComponent/ProgressComponent';
import { getRatioAnalysis } from '../../Api/AppraisalApi';
import MainHeader from '../../Helpers/MainHeader/MainHeader';
import RatioAnalysisVersionTwo from './RatioAnalysisVersionTwo';
import { RatioAnalysisContext } from './Stores/Context';

const RatioAnalysisContainer = ({ applicationId }) => {
    const [ratioAnalysisData, setRatioAnalysisData] = useState<Object>({});
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        getRatioData();
    }, []);

    const getRatioData = async () => {
        try {
            let response: any = await getRatioAnalysis(applicationId);

            if (response && Object.keys(response).length) {
                setRatioAnalysisData({ ...response });
            } else {
                setIsError(true);
            }

        } catch (error) {
            throw new Error(`${error} | Micro | RatioAnalysisContainer| getRatioData`);
        } finally {
            setIsLoading(false)
        }
    }

    if (isLoading && Object.keys(ratioAnalysisData).length) {
        return <Stack style={{ height: "100vh", width: "100vw" }} justifyContent={"center"} alignItems={"center"}>
            <CircularProgressComponent size={30} />
        </Stack>
    }

    if (isError) return <ErrorMessageComponent headMessage={""} errorMessage={"No Data Avaible to Show"} showImg={false} />

    return (
        <MainHeader title={"Ratio Analysis"} onSubmit={undefined}>
            <RatioAnalysisContext.Provider value={ratioAnalysisData} >
                <RatioAnalysisVersionTwo />
            </RatioAnalysisContext.Provider>
        </MainHeader>
    )
}

export default RatioAnalysisContainer
