import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";

import { openGlobalModal } from "../../../GlobalModal/GlobalModal";

import FindInPageIcon from "@mui/icons-material/FindInPage";
import { bufferToBlobUrl } from "../../../../../configs/base64Conversion";
import { getSubflowDocuments } from "../../../../../services/creditFileApiCall";
import AppConfig from "../../../../../utility/AppConfig";
import { disableAccess } from "../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

export const previewBtnClicked = async (obj) => {

  const fileBuffer = obj?.fileData.data;
  const contentType = obj?.contentType;

  const blobUrl = bufferToBlobUrl(fileBuffer, contentType);

  window.open(
    `${blobUrl}`,
    "targetWindow",
    `toolbar=no,
       location=no,
       status=no,
       menubar=no,
       scrollbars=yes,
       resizable=yes,
       width=500,
       height=500`
  );
};

interface DocumentPreviewPropTypes {
  applicationId?: any;
  innerComponentData?: any;
  tabsToEdit?: any;
}

const RiskDocumentsList = ({ applicationId, innerComponentData, tabsToEdit }: DocumentPreviewPropTypes) => {
  const riskGroupId = AppConfig.config.riskGroupId;

  const [isLoading, setIsLoading] = React.useState(true);
  const [riskList, setRiskList] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");

  const colorStatus: any = [
    {
      borderRadius: "5px",
      backgroundColor: "rgba(213, 200, 28, 0.24)",
      border: "1px solid rgb(213, 200, 28)",
    },
    {
      borderRadius: "5px",
      backgroundColor: "rgba(0, 255, 76, 0.24)",
      border: "1px solid rgb(0, 255, 76)",
    },
    {
      borderRadius: "5px",
      backgroundColor: "rgba(255, 144, 144, 0.24)",
      border: "1px solid rgb(255, 144, 144)",
    },
  ];

  useEffect(() => {
    (async function () {
      if (applicationId) {
        const data = await getSubflowDocuments(applicationId, riskGroupId, true);

        if (data && data.length > 0) {
          setRiskList(data);
        } else {
          setErrorMessage("No documents found");
        }
      }
      setIsLoading(false);
    })();
  }, []);

  const viewDocument = (obj) => {
    previewBtnClicked(obj);
  };

  const documentDetailViewDispatcher = (obj: any) => {
    openGlobalModal({
      modalSize: "lg",
      title: "",
      bodyId: "subflow-document-preview",
      close: true,
      modalParams: {
        applicationId: applicationId,
        disableStatus: checkPrivilegeStatus,
        item: obj,
        callBackOnAction: viewDocument,
      },
    });
  };

  const checkPrivilegeStatus = disableAccess({ isTabInEditString: tabsToEdit.includes(innerComponentData?.id) });

  return (
    <Grid container className="full-width" alignItems="center">
      {isLoading ? (
        <CircularProgressComponent
          size={30}
          sx={{
            marginTop: "30px",
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 100,
          }}
        />
      ) : errorMessage ? (
        <ErrorMessageComponent headMessage={"Empty"} errorMessage={errorMessage} />
      ) : (
        <Grid container p={1} className="data-box full-width" m={2} width={"100%"} gap={2}>
          {
            riskList.map((item: any, i) => {
              const number = riskList.length - i
              return <Accordion style={{ width: "100%" }}>
                <AccordionSummary
                  expandIcon={<KeyboardDoubleArrowUpIcon className="basic-font-color" fontSize="medium" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid container columns={12}>
                    <Grid item xs={0.5}>
                      <CheckCircleIcon sx={{ color: "#00ff4c" }} />
                    </Grid>
                    <Grid item xs={9.5}>
                      <Typography>{`${number} - Risk Documents - ${item.title}`}</Typography>
                    </Grid>
                    <Grid item xs={2} pl={0.75}>
                      <Box className={`document-upload-wrapper-text`} sx={{ textAlign: "left" }}>
                        <small className="basic-font opacity-7 font-size-14">Total: {item?.attachments?.length}</small>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack>
                    <Grid container gap={2}>
                      {item?.attachments?.map((obj: any) => {
                        return (
                          <Grid item>
                            <Button
                              className="risk-legal-insurance-document-view-button"
                              style={colorStatus[1]}
                              disabled={false}
                              onClick={() => documentDetailViewDispatcher(obj)}
                            >
                              <FindInPageIcon
                                style={{
                                  color: "#00ff4c",
                                  fontSize: 40,
                                  cursor: "pointer",
                                }}
                              />
                            </Button>
                            <Typography textAlign={"center"}>{`${obj.note}`}</Typography>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            })
          }

        </Grid>
      )}
    </Grid>
  );
};

export default RiskDocumentsList;
