import { Downgraded } from "@hookstate/core";
import { creditData } from "../../../../../../configs/mainStore";
import { loadApplicationFrom } from "../../../../../../services/applicationFormApis";
import { messageMappings } from "../../../../../../utility/helpers/ApplicationMandatoryValidations/messageMapping";

export const getFormSet = async (appFormSet) => {
  const creditFileDetails = creditData.attach(Downgraded).get();
  const { formData, applicantType }: any = creditFileDetails;
  const { type, sector, scheme } = formData?.creditData;

  if (appFormSet.get().length > 0) {
    return appFormSet.get();
  } else {
    const getApplicationform = await loadApplicationFrom(type, sector, scheme, applicantType, 1, appFormSet, true);
    return getApplicationform;
  }
};

export const collateralApplicationFormGetter = async (key: string, appFormSet) => {
  const formJson: Array<any> = await getFormSet(appFormSet);
  const getApplicationform = formJson;
  let securityFields: Array<any> = [];

  if (getApplicationform && getApplicationform.length > 0) {
    let currentApplicationForm: Array<any> = [];
    for (let i = 0; i < getApplicationform.length; i++) {
      if (getApplicationform[i]?.APPLICANT_TYPE == 1) {
        currentApplicationForm = JSON.parse(getApplicationform[i]?.FORM_STRUCTURE);
      } else continue;
    }
    if (currentApplicationForm && currentApplicationForm.length > 0) {
      securityFields = getSecuriyFields(currentApplicationForm, key);
      return securityFields && securityFields.length > 0 ? securityFields[0] : {};
    } else return {};
  } else return {};
};

const getSecuriyFields = (itemArray: Array<any>, key: string) => {
  let securityFields: Array<any> = [];

  itemArray.forEach((item) => {
    if (item.key === "security-details") {
      securityFields = securityFields.concat(getSecuriyFields(item.content, key));
    } else if (item.content) {
      securityFields = securityFields.concat(getSecuriyFields(item.content, key));
    } else if (item.key === key) {
      securityFields.push(item.fields);
    }
  });

  return securityFields;
};

export const getMandatoryFields = (fields: object): Array<string> => {
  let intermediateArray: Array<string> = [];

  for (const key in fields) {
    if (fields[key].mandatory === 1) {
      intermediateArray.push(key);
    }
  }
  return intermediateArray;
};

export const handleMandatory = (loanArray, mandatoryKeys): Array<any> => {
  const errorArray: Array<string> = [];
  for (const obj of loanArray) {
    if (obj !== null && !obj.hasOwnProperty("removedItem")) {
      mandatoryKeys.forEach((element: string) => {
        if (obj.hasOwnProperty(element)) {
          if (obj[element] === "" || obj[element] === null) errorArray.push(element);
          if (obj[element] === "Invalid date") errorArray.push(element);
        }
      });
    }
  }

  let uniqueErrorArray: Array<string> = errorArray.reduce(function (acc: Array<string>, curr: string): Array<string> {
    if (!acc.includes(curr)) acc.push(curr);
    return acc;
  }, []);

  return messageMappings(uniqueErrorArray);
};
