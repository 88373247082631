import { Downgraded } from '@hookstate/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { userDetails } from "../../../../../configs/mainStore";
import { deleteNoteData } from "../../../../../services/creditFileApiCall";
import { dateFormatWithTimeChange } from '../../../../../utility/fomatters';
import { getUser } from '../../../../../utility/helpers/creditFileUtility';
import { closeGlobalModal, openGlobalModal } from "../../../GlobalModal/GlobalModal";
import { Toast } from "../../../GlobalToast";

const ReferItemRow = ({ data, onNoteDeleted }) => {
  const { userId }: any = userDetails.attach(Downgraded).get();
  const createdDate = data.createdDate ? dateFormatWithTimeChange(data.createdDate) : '';
  const index = data.sequence;

  const handleDelete = async (data, index) => {
    closeGlobalModal();

    if (!data.noteId) {
      Toast.error("Missing required data");
    } else {
      try {
        await deleteNoteData(data.noteId);
        Toast.success(`"${data.noteTitle}" Note deleted successfully!`);
        onNoteDeleted(data.noteId);
      } catch (error) {
        Toast.error("Failed to delete note");
      }
    }
  };

  const deleteConfirm = (object, index) => {
    openGlobalModal({
      modalSize: "sm",
      title: "Are you sure?",
      bodyId: "condition-delete-confirm",
      close: false,
      modalParams: {
        data: { ...object, desc: object.noteTitle },
        callBackOnAction: () => handleDelete(object, index),
      },
    });
  };

  return (
    <Grid container p={1} key={data.sequence}>
      <Grid container mt={1} className={'data-box'} padding={2}>
        <Grid item xs={24}>
          <div
            className="basic-font basic-font-color-bold font-size-18"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {data?.noteTitle}
          </div>
        </Grid>
        <Grid item xs={24}>
          <div
            className="basic-font basic-font-color opacity-7 font-size-14"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {data?.noteDescription}
          </div>
        </Grid>
        <Grid item xs={13}>
        </Grid>
        <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={4}>
          <Typography variant="body2" color={grey[500]}>
            {getUser(data?.createdBy)?.name ?? ""}
          </Typography>
          <Typography variant="body2" color={grey[500]}>
            {createdDate}
          </Typography>
          {userId === data?.createdBy && (
            <DeleteIcon style={{ cursor: "pointer" }} fontSize="small" onClick={() => deleteConfirm(data, index)} />
          )}
        </Grid>
        <Grid item xs={4}>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReferItemRow;
