import { Divider, Stack, Typography } from "@mui/material";

import { Downgraded, useState } from "@hookstate/core";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import {
  userDetails,
} from "../../../../../configs/mainStore";
import { dateFormatWithTimeChange } from "../../../../../utility/fomatters";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";

import ForwardIcon from "@mui/icons-material/Forward";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { getUser } from "../../../../../utility/helpers/creditFileUtility";
import { taskAction, taskStatus } from "../../../../../utility/util";
import { CheckBoxComponent } from "../../../../InputComponents/CheckBoxComponent/CheckBoxComponent";
import { ChipsComponent } from "../../../../InputComponents/ChipsComponent/ChipsComponent";
import { openGlobalModal } from "../../../GlobalModal/GlobalModal";
import { IconRowComponent } from "../IconRowComponent";

const TaskItemRow = ({
  data,
  applicationId,
  callBackOnActionEdit,
  activeTasks,
  updateActiveTasks,
}) => {
  const { userId }: any = userDetails.attach(Downgraded).get();

  const isExpand = useState(false);

  const actionTime = data.actionTime
    ? dateFormatWithTimeChange(data.actionTime)
    : "";
  const actionStat = data?.action ? taskAction[data?.action] : "";

  const color = actionStat?.color
    ? actionStat?.color
    : taskAction["default"]?.color;

  const user = getUser(data?.assignee) ?? {};

  const taskStat = taskStatus[data?.taskStatus] ?? "";

  const createdDate = data.createdDate
    ? dateFormatWithTimeChange(data.createdDate)
    : "";
  const createdBy = getUser(data?.createdBy)?.name ?? "";
  const description = data.taskDescription;
  const actionNote = data.actionNote;
  const isClosedTask = !!data?.action;

  const onClickedAction = () => {
    openGlobalModal({
      modalSize: "sm",
      title: "",
      bodyId: "task-action-modal",
      close: false,
      modalParams: {
        description: description,
        title: data.taskTitle,
        taskId: data.taskId,
        callBackOnAction: callBackOnActionEdit,
        applicationId: applicationId,
      },
    });
  };

  // return (
  //   <>
  //     <Grid
  //       container
  //       alignItems="center"
  //       className="row_list_item"
  //       p={2}
  //       key={data.sequence}
  //       style={{
  //         backgroundColor: `${color}3d`,
  //       }}
  //     >
  //       <Grid
  //         container
  //         className="full-width"
  //         columns={28}
  //         alignItems="center"
  //         spacing={2}
  //       >
  //         <Grid item xs={1}>
  //           {data?.assignee === userId ? (
  //             <CheckBoxComponent
  //               checked={activeTasks.includes(data.taskId) ? true : false}
  //               onChange={(e) =>
  //                 updateActiveTasks(e.target.checked, data.taskId)
  //               }
  //               disabled={data?.taskStatus === 0 ? false : true}
  //             />
  //           ) : (
  //             <></>
  //           )}
  //         </Grid>

  //         <Grid item xs={7}>
  //           <IconRowComponent
  //             Icon={RadioButtonCheckedIcon}
  //             subTxt="Task Title"
  //             mainTxt={data.taskTitle}
  //             color={color}
  //           />
  //         </Grid>

  //         <Grid item xs={4}>
  //           <IconRowComponent
  //             Icon={RadioButtonCheckedIcon}
  //             subTxt="Task Status"
  //             mainTxt={taskStat?.name}
  //             color={color}
  //           />
  //         </Grid>

  //         <Grid item xs={4}>
  //           <IconRowComponent
  //             // Icon={PersonIcon}
  //             subTxt="Assignee"
  //             mainTxt={user?.fullName}
  //             color={color}
  //           />
  //         </Grid>

  //         {isClosedTask ? (
  //           <>
  //             <Grid item xs={3}>
  //               <IconRowComponent
  //                 Icon={OutputIcon}
  //                 subTxt="Action"
  //                 mainTxt={actionStat?.name}
  //                 color={color}
  //               />
  //             </Grid>

  //             <Grid item xs={5}>
  //               <IconRowComponent
  //                 Icon={InsertInvitationIcon}
  //                 subTxt="Action Time"
  //                 mainTxt={actionTime}
  //                 color={color}
  //               />
  //             </Grid>
  //           </>
  //         ) : (
  //           <Grid item xs={8} className="align-right">
  //             <ButtonComponent
  //               startIcon={<ForwardIcon />}
  //               variant="contained"
  //               onClick={onClickedAction}
  //               style={{}}
  //               title={"Action"}
  //               disabled={userId !== user?.id}
  //             />
  //           </Grid>
  //         )}
  //         <Grid item xs={1} className="align-center">
  //           <ButtonComponent
  //             iconbtn
  //             children={
  //               isExpand.get() ? (
  //                 <KeyboardDoubleArrowUpIcon className="basic-font-color" />
  //               ) : (
  //                 <KeyboardDoubleArrowDownIcon className="basic-font-color" />
  //               )
  //             }
  //             onClick={() => isExpand.set(!isExpand.get())}
  //           />
  //         </Grid>
  //       </Grid>

  //       {isExpand.get() && (
  //         <Grid
  //           container
  //           className="full-width row_list_item"
  //           columns={24}
  //           mt={1}
  //           pt={1}
  //           spacing={1}
  //         >
  //           <Grid item xs={12}>
  //             <Stack direction="row" spacing={2}>
  //               <div
  //                 className={`basic-font basic-font-color opacity-7 font-size-14`}
  //               >
  //                 Created By
  //               </div>
  //               <div className={`basic-font basic-font-color font-size-14`}>
  //                 {createdBy?.fullName}{" "}
  //               </div>
  //             </Stack>
  //           </Grid>
  //           <Grid item xs={12}>
  //             <Stack direction="row" spacing={2}>
  //               <div
  //                 className={`basic-font basic-font-color opacity-7 font-size-14`}
  //               >
  //                 Created Date
  //               </div>
  //               <div className={`basic-font basic-font-color font-size-14`}>
  //                 {createdDate}{" "}
  //               </div>
  //             </Stack>
  //           </Grid>
  //           <Grid item xs={24}>
  //             <Stack direction="row" spacing={2}>
  //               <div
  //                 className={`basic-font basic-font-color opacity-7 font-size-14`}
  //               >
  //                 Task Description
  //               </div>
  //               <div className={`basic-font basic-font-color font-size-14`}>
  //                 {data.taskDescription}{" "}
  //               </div>
  //             </Stack>
  //           </Grid>
  //           <Grid item xs={24}>
  //             <Stack direction="row" spacing={2}>
  //               <div
  //                 className={`basic-font basic-font-color opacity-7 font-size-14`}
  //               >
  //                 Action Note
  //               </div>
  //               <div className={`basic-font basic-font-color font-size-14`}>
  //                 {data.actionNote ? data.actionNote : "-"}{" "}
  //               </div>
  //             </Stack>
  //           </Grid>
  //         </Grid>
  //       )}
  //     </Grid>
  //   </>
  // );

  return (
    <>
      <Stack
        flex={1}
        key={data.sequence}
        style={{
          borderRadius: "4px",
          backgroundColor: `${color}30`,
        }}
      >
        <Stack direction={"row"} flex={1} px={2} py={1} alignItems={"center"}>
          {data?.assignee === userId && (
            <CheckBoxComponent
              checked={activeTasks.includes(data.taskId) ? true : false}
              onChange={(e) => updateActiveTasks(e.target.checked, data.taskId)}
              disabled={data?.taskStatus === 0 ? false : true}
            />
          )}

          <Stack flex={1}>
            <IconRowComponent
              Icon={RadioButtonCheckedIcon}
              subTxt="Task Title"
              mainTxt={data.taskTitle}
              color={color}
            />
          </Stack>

          <Stack width={"200pt"}>
            <Stack direction={"row"}>
              <ChipsComponent
                size="small"
                sx={{ backgroundColor: color }}
                label={
                  <Typography variant="caption">{taskStat?.name}</Typography>
                }
              ></ChipsComponent>
            </Stack>
            <Typography variant="caption" color={"gray"} fontWeight={300}>
              Assignee: {user?.name ?? ""}
            </Typography>
            {isClosedTask && (
              <Typography variant="caption" fontWeight={100}>
                Actioned: {actionTime}
              </Typography>
            )}
          </Stack>

          {isClosedTask ? (
            <Stack width={"80pt"}></Stack>
          ) : (
            <ButtonComponent
              startIcon={<ForwardIcon />}
              variant="contained"
              onClick={onClickedAction}
              style={{}}
              title={"Action"}
              disabled={userId !== user?.userId}
            />
          )}
          <ButtonComponent
            iconbtn
            onClick={() => isExpand.set(!isExpand.get())}
          >
            {
              isExpand.get() ? (
                <KeyboardDoubleArrowUpIcon className="basic-font-color" />
              ) : (
                <KeyboardDoubleArrowDownIcon className="basic-font-color" />
              )
            }
          </ButtonComponent>
        </Stack>

        {isExpand.get() && (
          <Stack mt={1} pt={1} pb={1} spacing={1}>
            <Divider></Divider>
            <Stack direction={"row"} px={8} justifyContent={"space-between"}>
              <Typography variant="caption" color={"gray"} fontWeight={300}>
                Created By: <b>{createdBy?.replaceAll("null", "")}</b>
              </Typography>
              <Typography variant="caption" color={"gray"} fontWeight={300}>
                Created Date: <b>{createdDate}</b>
              </Typography>
            </Stack>
            <Stack direction={"column"} px={8}>
              <Typography variant="caption" color={"gray"} fontWeight={300}>
                Task Description:
              </Typography>
              <Typography fontWeight={300}>{data.taskDescription}</Typography>
            </Stack>
            <Stack direction={"column"} px={8}>
              <Typography variant="caption" color={"gray"} fontWeight={300}>
                Action Note:
              </Typography>
              <Typography fontWeight={300}>
                {" "}
                {data.actionNote ? data.actionNote : "-"}{" "}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default TaskItemRow;