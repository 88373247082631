import { Downgraded, useState } from "@hookstate/core";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { formStructureSet, selectedApplicationReferenceToEditState } from "../../../../../configs/mainStore";
import { treasuryBondDefault } from "../../../../../configs/stores/securityDataSubSection/securityDataSubSections.module";
import { saveAppFormDataJson } from "../../../../../services/apiCalls";
import { applicationFormSectionDetailsDelete } from "../../../../../services/applicationApis";
import { deleteMultipleInputItem, resetInputItems } from "../../../../../services/multipleInputDelete";
import { disableAccess } from "../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { TresuryBond } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/PaperDocs/TresuryBond/TresuryBond";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { closeGlobalModal, openGlobalModal } from "../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { collateralApplicationFormGetter, getMandatoryFields, handleMandatory } from "./collateral-apis/CollateralApplicationFormGetter";
import HeaderCollateral from "./helpers/CollateralHeaderComponent";
import { collateralValidationErrorArray } from "./validations/validations";

export const CreditTresuryBond = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion, apiCallBack, innerComponentData, tabsToEdit }) => {
  const [fullObj, setFullObj]: any = React.useState({});
  const [loading, setLoading]: any = React.useState(false);
  const requestId = data?.requestId;

  const [fields, setFields]: any = React.useState({});
  const existingData = useState<Array<any>>([]);
  const [fetching, setFetching] = React.useState<boolean>(false);
  let itemIndex = 0;

  const exisitingObject = data?.formData?.securityData?.paperDocs?.treasuryBond;

  const selectedApplicationReferenceId = useState(selectedApplicationReferenceToEditState);
  const appFormSet = useState(formStructureSet);
  const checkPrivilegeStatus = disableAccess({ isTabInEditString: tabsToEdit.includes(innerComponentData?.id) });

  const handleGetApplicationData = async () => {
    await getApplicationForm();
  };

  useEffect(() => {
    setFetching(true);
    handleGetApplicationData()
      .then(() => {
        setExistingData();
      })
      .then(() => {
        setFullObj(JSON.parse(JSON.stringify(data?.formData?.securityData?.paperDocs ?? {})));
      });
    setFetching(false);
  }, []);

  const getApplicationForm = async () => {
    const key = "treasuryBond";
    const response = await collateralApplicationFormGetter(key, appFormSet);
    if (response) {
      setFields(response);
    } else setFields({});
  };

  const setExistingData = () => {
    if (exisitingObject) {
      existingData.set(JSON.parse(JSON.stringify(exisitingObject)));
    }
  };

  const handleFieldsAdding = () => {
    let treasuryBond = {
      itemIndex: 0,
      entryId: 0,
      ...JSON.parse(JSON.stringify(treasuryBondDefault)),
    };

    try {
      if (existingData.get()?.length > 0) {
        treasuryBond["itemIndex"] = existingData.get()?.length;
        treasuryBond["entryId"] = existingData.get()?.length;
      }
      const tempArray = existingData.get() ? [...existingData.get(), treasuryBond] : [treasuryBond];
      existingData.set(JSON.parse(JSON.stringify(tempArray)));
    } catch (error) { }
  };

  const TRESURY_BOND_MANDATORY = [...getMandatoryFields(fields)];

  const handleSave = async () => {
    setLoading(true);
    const mandatoryRes = handleMandatory(existingData.attach(Downgraded).get(), TRESURY_BOND_MANDATORY);
    const finalArr: any = [];
    delete fullObj.treasuryBond;

    for (let index = 0; index < existingData.get().length; index++) {
      const element = existingData[index].get();

      let obj = {
        type: "paperDocs_treasuryBond",
        itemIndex: index,
        entryId: index,
        ...element,
      };
      finalArr[finalArr.length] = obj;
    }

    let finalObj = {
      paperDocs: {
        treasuryBond: finalArr,
        ...fullObj,
      },
    };

    let errorArray = collateralValidationErrorArray("paperDocs", "treasuryBond", finalObj);

    const lastValidation: Array<any> = [...errorArray, ...mandatoryRes];

    if (lastValidation?.length > 0) {
      const errorMessage: string = lastValidation.join("\n");
      addToaster({
        status: "error",
        title: "Mandatory Validation Error",
        message: `${errorMessage}`,
      });
      setLoading(false);
    } else {
      let data = JSON.parse(JSON.stringify(finalObj));

      let res = await saveAppFormDataJson(requestId, "securityData", data);

      if (res?.status === 1) {
        apiCallBack();
        setLoading(false);
        addToaster({
          status: "success",
          title: "Updated",
          message: "Security Details Updated",
        });
      } else if (res?.error) {
        setLoading(false);
        addToaster({
          status: "warning",
          title: "Not Updated",
          message: "Update Error",
        });
      } else {
        setLoading(false);
        addToaster({
          status: "error",
          title: "Error",
          message: "Something went Wrong!",
        });
      }
    }
  };

  const deleteDispatcher = async (type: any = "", index: any = "", selectedSecurityTypeItem: any = {}, securityType: any = "") => {
    if (type && type == "all") {
      openGlobalModal({
        modalSize: "sm",
        title: "Are you sure ?",
        bodyId: "app-section-delete-confirm",
        close: false,
        modalParams: {
          textMessage: "This will delete all collaterals except guarantors!.",
          callBackOnAction: () => onDeleteSection(type, index),
        },
      });
    } else {
      onDeleteSection(type, index, selectedSecurityTypeItem, securityType);
    }
  };

  const removeFullState = () => {
    // For now there is no full state delete button on collateral then this will be activated later if needed
  };

  const onDeleteSection = async (type: any = "", index: any = "", selectedSecurityTypeItem: any = {}, securityType: any = "") => {
    if (type) {
      closeGlobalModal();
    }

    let securityTypes: any[] = securityType.split(":") || [];

    try {
      let dataObj: any = {};

      if (type) {
        dataObj = {
          applicationId: selectedApplicationReferenceId?.get(),
          sectionTravers: `securityData.${securityTypes[0]}.${securityTypes[1]}`,
        };
      } else {
        dataObj = {
          applicationId: selectedApplicationReferenceId?.get(),
          sectionTravers: `securityData.${securityTypes[0]}.${securityTypes[1]}[${index}]`,
        };
      }

      await applicationFormSectionDetailsDelete(dataObj);

      if (type) {
        removeFullState();
      } else {
        deleteMultipleInputItem(selectedSecurityTypeItem);
      }
      addToaster({
        status: "success",
        title: "Deleted!",
        message: `${securityTypes[2]} deleted successfully!`,
      });

    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: `Error ${securityTypes[2]} deletion, try again shortly!`,
      });
    }
  };

  return (
    <>
      {fetching ? (
        <Grid
          container
          alignItems="center"
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
            position: "relative",
          }}
        >
          <CircularProgressComponent size={30} sx={{ left: "50%", position: "absolute", zIndex: 100 }} />
        </Grid>
      ) : (
        <>
          <div className="full-width full-height">
            {checkPrivilegeStatus ? <Stack style={{ width: "100%", height: "100vh", position: "absolute", left: 0, top: 0, backgroundColor: "255, 255, 255, 0.1", zIndex: 1000 }}></Stack> : <></>}
            {isMainContactDetails && <HeaderCollateral loading={loading} handleFieldsAdding={handleFieldsAdding} requestDtlData={existingData} collateralApplicationForm={fields} handleSave={handleSave} headerTitle={"Tresury Bond"} />}

            {/* Security Form */}
            <Grid container className="inner-component-height">
              <Stack spacing={1} m={1} className="full-width">
                {existingData.get()?.length > 0 ? (
                  existingData?.map((doc, index) => {
                    if (!doc.get()) return <></>;
                    const elementKeys = Object.keys(doc);
                    if (!elementKeys.includes("removedItem")) {
                      itemIndex = itemIndex + 1;

                      return (
                        <Box display="flex" alignItems="center" mb={1} key={`TresuryBond_${itemIndex * itemIndex}`}>
                          <Box style={{ width: "100%" }}>
                            <TresuryBond doc={doc} fields={fields} index={itemIndex} isNotNeedComponentTitle={true} />
                          </Box>
                          <Box>
                            <Box pb={3}>
                              <Tooltip title={"Reset"}>
                                <Box>
                                  <ButtonComponent
                                    iconbtn={true}
                                    onClick={() => {
                                      resetInputItems(doc, "paperDocs", "loanProtection");
                                    }}
                                    style={{ border: "1px solid gray" }}
                                    size="small"
                                  >
                                    <RestartAltIcon />
                                  </ButtonComponent>
                                </Box>
                              </Tooltip>
                            </Box>
                            <Box pt={3}>
                              <Tooltip title={"Delete"}>
                                <Box>
                                  <ButtonComponent iconbtn onClick={() => deleteDispatcher("", index, doc, "paperDocs:treasuryBond:Paper Docs Treasury Bond")}>
                                    <DeleteIcon />
                                  </ButtonComponent>
                                </Box>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Box>
                      );
                    }
                  })
                ) : (
                  <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
                )}
              </Stack>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};
