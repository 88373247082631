import { useState } from '@hookstate/core';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Grid, Stack } from '@mui/material';
import React, { createRef, useEffect } from 'react';
import { userDetails, workflowMasterData } from '../../../../../configs/mainStore';
import { uploadInsurancePolicyDocument } from '../../../../../services/creditFileApiCall';
import { ButtonComponent } from '../../../../InputComponents/ButtonComponent/ButtonComponent';
import { TextBoxComponent } from '../../../../InputComponents/TextBoxComponent/TextBoxComponent';
import { closeGlobalModal } from '../../../GlobalModal/GlobalModal';
import { addToaster } from '../../../GlobalToast';

import { RISK_DOCUMENT_ID } from '../../../../../configs/constants/contants';
import { closeBtn } from '../../../../../styles';
import AppConfig from '../../../../../utility/AppConfig';
import ModalInputComponent from '../../../../InputComponents/ModalInputComponent/ModalInputComponent';
import { CMTextInput } from '../../../CommunicationService/CMTextInput';
import { FileUploadComponent } from '../../../DropzoneComponent/FileUploadComponent';

const InsuranceDepReferralModal = (params) => {
  const initialData = { title: '', description: '' };
  const showErrors = useState(false);
  const insurancePolicyState: any = useState(initialData);
  const loginUser = useState(userDetails);
  const { wf_system_users }: any = useState(workflowMasterData);

  const [isLoading, setIsLoading] = React.useState<any>(false);
  const docUploadRef: any = createRef();

  useEffect(() => {
    let tempUserList: any = [];
    wf_system_users?.get()?.map((item: any) => {
      if (item?.id !== loginUser?.get()?.userId) {
        tempUserList.push({ name: item?.fullName, id: item.id });
      }
    });

  }, []);

  const saveInsurancePolicy = async () => {
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      setIsLoading(true);

      const insPolicyDocumentUploadResult = await docUploadRef?.current?.uploadSingleImg();

      if (insPolicyDocumentUploadResult?.status === true) {
        addToaster({
          status: 'success',
          title: 'Updated',
          message: 'New Insurance Policy Record Added',
        });

        if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
          params.callBackOnAction(insPolicyDocumentUploadResult?.data?.data);
        }
        AppConfig.invokers.insuarance();

        closeGlobalModal();
      } else {
        addToaster({
          status: 'error',
          title: 'Error',
          message: insPolicyDocumentUploadResult?.message,
        });
      }

      setIsLoading(false);
    }
  };

  const closeRisk = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return insurancePolicyState.title.get() === '' || insurancePolicyState.description.get() === '';
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  const extractValue = (value) => {
    if (value === 'null') {
      return null;
    }
    if (value === 'undefined') {
      return null;
    }
    if (value === '') {
      return null;
    }
    if (!value) {
      return null;
    }
    return value;
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Create Insurance Policy</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Insurance Policy Title"
            component={
              <TextBoxComponent
                value={insurancePolicyState?.title?.get()}
                onChange={(e) => {
                  resetError();
                  insurancePolicyState.title.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                minRows={1}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && insurancePolicyState.risk.get() === ''}
          />
        </Grid>
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Insurance Policy Description"
            component={
              <TextBoxComponent
                value={insurancePolicyState?.description.get()}
                onChange={(e) => {
                  resetError();
                  insurancePolicyState.description.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                minRows={12}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && insurancePolicyState.risk.get() === ''}
          />
        </Grid>
        <Grid item xs={12} md={12} className="">
          <CMTextInput
            desc={'Upload Insurance Policy Document'}
            component={
              <FileUploadComponent
                fileDetails={{
                  //TODO RISK DOC GET FROM DB
                  ref: docUploadRef,
                  isMandatory: true,
                  allowedFormats: 'application/pdf',
                  maxFileSize: 4000000,
                  documentKey: '',
                }}
                uploadDataObject={{
                  title: insurancePolicyState?.title?.get(),
                  description: insurancePolicyState?.description.get(),
                  createdBy: loginUser?.userId?.get(),
                  applicationId: params?.applicationId,
                  workflowId: params?.currentWorkflow,
                  stageId: params?.currentWorkflowStage,
                  referralId: params?.referId || '',
                  approvalFrom: insurancePolicyState?.approvalFrom?.get(),
                  documentId: RISK_DOCUMENT_ID,
                }}
                isPreviewHave={false}
                fileUploadApi={uploadInsurancePolicyDocument}
              />
            }
          // error={errorMessage?.cribRecordKey !== '' ? true : false}
          />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeRisk}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={saveInsurancePolicy}
          loadingbtn={true}
          loading={isLoading}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default InsuranceDepReferralModal;
