import { useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { masterDataDetails } from "../../../../../configs/mainStore";
import { formatCurrency } from "../../../../../services/currencyFormater";
import { calculateAnnualValue, incomeExpenseFrequency } from "../../../../../utility/util";
import MandatoryFilterComponent from "../../../../DataValidationComponents/MandatoryFilterComponent";
import { LengthItemValidate } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";
import CreditFileDocumentsViewSection from "../CreditFileDocumentsViewSection";
import DetailItem from "../DetailItem";
import ApplicationHeader from "./ApplicationHeader";

const ExpensesDetails = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion = false, printMode = false, isGuarantor = false, isJointBurrower = false, guarantorJBSection = "", innerComponentData, tabsToEdit }) => {
  const masterData: any = useState(masterDataDetails);
  const expenseDataList: any = data?.formData?.expenseData?.expenses || [];
  const filteredExpenseDataList: any[] = LengthItemValidate.validatedArray(expenseDataList) || [];
  const { t: translate } = useTranslation();
  const presenceOfRegulatedFinSystem = masterData?.PRE_REG_FIN_SYS?.get()?.find((t) => t.id == data?.formData?.expenseData?.presenceOfRegulatedFinSystem);

  const { formStructure: { primaryApplicant } } = data;
  const { label = "Expense Details" } = primaryApplicant?.[innerComponentData?.id];

  return (
    <MandatoryFilterComponent
      errorMessage={"No Expense Data"}
      items={filteredExpenseDataList}
      mandatory={["expenseType", "expenseFrequency", "expenseAmount"] as never[]}
      options={{
        isMainContactDetails: isMainContactDetails,
        formData: data?.formData,
        requestId: data?.requestId,
        applicationId: applicationId,
        title: label ? label : "Expense Details",
        isAppraisal: mainTabData?.isAppraisal,
        isRawVersion: isRawVersion,
        disableAccordingToTab: false,
        printMode: printMode,
      }}
    >
      <div className={`mainSectionWrapper ${isMainContactDetails ? "full-width full-height" : "full-width"}`}>
        {isMainContactDetails && (!isRawVersion || printMode) && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={label ? label : "Expense Details"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)} />}
        {!isMainContactDetails && (
          <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa" }}>
            {label ? label : "Expense Details"}
          </Grid>
        )}

        {filteredExpenseDataList.length > 0 ? (
          <Grid container className={isMainContactDetails ? "inner-component-height padding-sm" : "margin-xs-t"}>
            <Grid container>
              <Grid container className={`border-radius-5 ${isMainContactDetails ? (isRawVersion ? "" : "sub-header-height") : ""}`}>
                <CreditFileDocumentsViewSection data={data} applicationId={applicationId} isGuarantor={isGuarantor} guarantorJBSection={guarantorJBSection} isJointBurrower={isJointBurrower} innerComponentData={innerComponentData} />
                <Grid container pl={1} className="data-box">
                  <Grid container p={1} spacing={1}>
                    {filteredExpenseDataList?.length > 0 &&
                      filteredExpenseDataList?.filter((obj) => obj)
                        ?.filter((obj) => !obj.removedItem)?.map((expenseData, index) => {
                          const expenseType = masterData?.EXPENSES_TYPE?.get()?.find((t) => t.id == expenseData?.expenseType);
                          const expenseFrequency: any = incomeExpenseFrequency?.find((t) => t.id == expenseData?.expenseFrequency);
                          const frequencyName: any = expenseFrequency?.name ? translate(expenseFrequency.name) : "";
                          const annual = calculateAnnualValue(expenseData?.expenseAmount, expenseData.frequencyForMonth, expenseData.frequencyForAnnual);
                          return (
                            <Grid container p={1} key={index} className="data-box" item mt={1} spacing={1}>
                              <Grid item xs={3}>
                                <DetailItem title={translate("expensesType")} value={expenseType?.name ? expenseType.name : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem title={translate("expenseFrequency")} value={frequencyName ? frequencyName : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              {expenseData.frequencyForMonth && (
                                <Grid item xs={3}>
                                  <DetailItem title={translate("frequencyForMonth")} value={expenseData.frequencyForMonth ? expenseData.frequencyForMonth : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                                </Grid>
                              )}
                              {expenseData.frequencyForAnnual && (
                                <Grid item xs={3}>
                                  <DetailItem title={translate("frequencyForAnnual")} value={expenseData.frequencyForAnnual ? expenseData.frequencyForAnnual : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                                </Grid>
                              )}

                              <Grid item xs={3}>
                                <DetailItem title={translate("expenses")} value={expenseData?.expenseAmount ? formatCurrency(expenseData?.expenseAmount) : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                              <Grid item xs={3}>
                                <DetailItem title="Annual Expenses" value={annual ? formatCurrency(annual) : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                              </Grid>
                            </Grid>
                          );
                        })}
                  </Grid>
                  <Grid container p={1} spacing={1}>
                    {presenceOfRegulatedFinSystem != undefined && (
                      <Grid item xs={6}>
                        <DetailItem title={translate("presenceOfRegulatedFinSystem")} value={presenceOfRegulatedFinSystem?.name ? presenceOfRegulatedFinSystem.name : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <DetailItem title={translate("numberOfDepends")} value={data?.formData?.expenseData?.numberOfDepends ? data?.formData?.expenseData?.numberOfDepends : "N/A"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </div>
    </MandatoryFilterComponent>
  );
};

export default ExpensesDetails;
