import { useState } from "@hookstate/core";
import { CircularProgress, Paper, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { CORE_BANK_KEYS } from "../../../../../configs/constants/contants";
import { setCoreBankData } from "../../../../../services/bankServiceInvorker";
import { select_basic } from "../../../../../styles";
import { currentUserAccess, extractClientDetails, hasPrivilegeAccess, hasTabAccess } from "../../../../../utility/helpers/creditFileUtility";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../../InputComponents/SelectComponent/SelectComponent";
import { addToaster } from "../../../GlobalToast";

const CustomerCIFInformation = ({ type, payload, actions }) => {
  const selectCif: any = useState(payload.selectedCIF);
  const { reloadData, requestPayloadData } = actions();
  const isSaving = useState(false);

  const saveCIF = async () => {
    const cif = selectCif.get();
    if (!cif) {
      addToaster({
        status: "warning",
        title: "Empty",
        message: "Select CIF Account",
      });
      return;
    }

    const { applicationId, individualId } = payload;

    try {
      isSaving.set(true);
      const payload = {
        [individualId]: { CIF: cif },
      };

      if (requestPayloadData && type === "primary") {
        requestPayloadData(payload);
      }
      const { message } = await setCoreBankData(applicationId, CORE_BANK_KEYS.CIF, payload);

      if (!message) {
        throw new Error("Failed to proceed");
      }

      addToaster({
        status: "success",
        title: "Success",
        message,
      });

      if (reloadData) {
        reloadData();
      }
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: "Failed to update CIF",
      });
    } finally {
      isSaving.set(false);
    }
  };

  const NICComponent = () => {
    const { newNic, oldNic } = payload;

    return <Stack direction="column" flex={1}>
      <Typography color={grey[700]} variant="subtitle2">{[newNic, oldNic].filter(item => item).join(",")}</Typography>
      <Typography variant="caption" color={grey[500]}> NIC </Typography>
    </Stack>;
  };

  const checkPrivilegeStatus = (key = "") => {
    let isDisabled = !currentUserAccess();

    if (!isDisabled) {
      isDisabled = !hasTabAccess({ tab: "loan_related_cif" });
    }

    if (!isDisabled) {
      isDisabled = !hasPrivilegeAccess({ prvKey: key });
    }

    return isDisabled;
  };

  const { name, clientDetails, primary } = payload;

  const typeMap = {
    "primary": "Primary Applicant",
    "jb": "Joint Borrower",
    "gr": "Guarantor"
  };

  const { cifList, cifIds, cifIdsMap } = extractClientDetails(clientDetails, type);

  const customerName = () => {
    const cif = type !== "jb" ? selectCif.get() : primary?.[0]?.selectedCIF;
    if (!cif) {
      return "";
    }

    if (type === "jb") {
      const { cifIdsMap } = extractClientDetails(primary?.[0]?.clientDetails);
      return (cifIdsMap[cif]?.initialInFull?.trim()?.split(" ")?.filter(item => item)?.join("") || "").replaceAll(",", ", ");
    }

    return (cifIdsMap[cif]?.initialInFull?.trim()?.split(" ")?.filter(item => item)?.join(" ") || "").replaceAll(",", ", ");
  }

  return <Paper style={{ width: "100%", marginTop: "10pt" }}>
    <Stack direction={"column"}>
      <Stack flex={1} p={2} direction={{ md: "row", sm: "column" }} justifyContent={"space-between"} spacing={2}>
        <Stack flex={1} >
          <Typography color={grey[700]} variant="subtitle2">{name}</Typography>
          <Typography variant="caption" color={grey[500]}> {typeMap[type]} </Typography>
        </Stack>
        <Stack flex={1} >
          <NICComponent />
        </Stack >
        <Stack flex={1} >
          <Typography color={grey[700]} variant="subtitle2">{type !== "jb" ? selectCif.get() : primary?.[0]?.selectedCIF}</Typography>
          <Typography variant="caption" color={grey[500]}> Selected CIF </Typography>
        </Stack>
        {type !== "jb" ?
          <Stack flex={1} spacing={1} direction={"row"} alignItems={"center"}>
            <Stack width={"250pt"}>
              <SelectComponent
                label={"Select CIF"}
                sx={select_basic}
                value={cifIds.includes(selectCif.get()) ? selectCif.get() : ``}
                values={cifList}
                onChange={(e) => {
                  selectCif.set(e.target.value);
                }}
                required={true}
                defaultPlaceholder={true}
                disabled={checkPrivilegeStatus("BANK_SERVICES_SELECT_CIF")}
                key={new Date().getTime()}
              />
            </Stack>
          </Stack> : <Stack flex={1} spacing={1} direction={"row"} alignItems={"center"}> <Stack width={"250pt"}></Stack></Stack>}
        {type !== "jb" ? <Stack flex={1} direction={"row-reverse"} alignItems={"center"}>
          {isSaving.get() ? <CircularProgress size={24} /> : <ButtonComponent variant="outlined" onClick={saveCIF} title={"Submit"} disabled={checkPrivilegeStatus("BANK_SERVICES_SELECT_CIF")} />}
        </Stack> : <Stack flex={1} direction={"row-reverse"}>
        </Stack>}
      </Stack>
      <Stack pl={2} pb={2}>
        <Typography color={grey[700]} variant="subtitle2">{customerName()}</Typography>
        <Typography variant="caption" color={grey[500]}> CIF Initials In Full </Typography>
      </Stack>
    </Stack>
  </Paper>;
};

export default CustomerCIFInformation;
