import { useState } from "@hookstate/core";
import { Box, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { getFilledPendingInpectionFormData } from "../../../../../../../services/apiCalls";
import { addToaster, toast } from "../../../../../GlobalToast";
import { SectionKey } from "../../FormBuilder/Tools/Helpers/inspectionKeyEnums";
import { inspectionForm, inspetionIdState } from "../../FormBuilder/Tools/Helpers/inspectionStores/dataStore";
import { InspectionProps, SaveInspectionChanges } from "../../FormBuilder/Tools/Helpers/OnSubmitInspections/SaveChangesInspection";
import MultipleObjectArrayHandler from "../../Helpers/MultipleObjectArrayHandler";
import { componentMapping, titleMapping } from "./Helpers/config";
import { currentKey } from "./Helpers/jbStore";

const JBInspectionsContainer = ({ data, applicationId, innerComponentData, tabsToEdit }) => {
  const currentId = useState<string>(currentKey);
  const { formData } = data;
  const inspetionId = useState<any>(inspetionIdState);
  const inspectionFormState: any = useState<InspectionProps>(inspectionForm);
  const isLoading = useState<boolean>(true);

  const otherParams: any = {
    formData,
    applicationId,
    innerComponentData,
    tabsToEdit,
  };

  useEffect(() => {
    getExistingInspectionForm();
  }, []);

  const getExistingInspectionForm = async () => {
    isLoading.set(true);

    try {
      const { response }: { data: Object, status: number } | any = await getFilledPendingInpectionFormData(applicationId);

      let { ID: inspectionId = null, INSPECTION_DATA: inspectionData = {} } = response;

      try {
        inspectionData = JSON.parse(inspectionData);
      } catch (error) { }

      let { jointBorrower: data = [] } = inspectionData;

      inspectionFormState.jointBorrower.set(data);
      inspetionId.set(inspectionId);


    } catch (error) {
      toast({
        status:
          "error", message: "Something went wrong while fetching data...!"
      })
    } finally {
      isLoading.set(false);
    }
  }

  const onSubmit = async (dataObjctArray) => {
    let theDataTobeSubmitted = {};

    dataObjctArray.forEach((element) => {
      theDataTobeSubmitted = {
        ...theDataTobeSubmitted,
        [element.individualId]: element,
      };
    });

    const respose: any = await SaveInspectionChanges(theDataTobeSubmitted, SectionKey.jbInspection, inspectionFormState, applicationId);

    if (respose.status == 1) {
      return addToaster({
        status: "success",
        title: "SuccessFull",
        message: "Successfully Submitted Inpection Form",
      });
    } else {
      return addToaster({
        status: "error",
        title: "Somthing went wrong..!",
        message: "Something went wrong while Submitting Inspection Form..!",
      });
    }
  };

  const props = {
    data: formData?.jointBorrowerData,
    titleMapping,
    currentId,
    componentMapping,
    applicationId,
    onSubmit,
    dataItemArray: [],
    otherParams,
  };

  if (isLoading.get()) {
    return (
      <Box sx={{ display: "flex", width: 100, justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return <MultipleObjectArrayHandler {...props} />;
};

export default JBInspectionsContainer;
