import { List, ListItem, Stack, Typography } from "@mui/material";

import { Downgraded, useState } from "@hookstate/core";
import React, { useEffect } from "react";
import { ButtonComponent } from "../../components/InputComponents/ButtonComponent/ButtonComponent";
import { CheckBoxComponent } from "../../components/InputComponents/CheckBoxComponent/CheckBoxComponent";
import { closeGlobalModal } from "../../components/OtherComponents/GlobalModal/GlobalModal";
import { addToaster } from "../../components/OtherComponents/GlobalToast";
import { appSetting } from "../../configs/mainStore";
import { userListStore } from "../../configs/stores/userStore";
import { fetchPrivilegeGroups, savePrivilegeGroupsById } from "../../services/configurationApis";
import { getUserGroupList } from "../../services/userPrivilegeService";

const PrevilegeGroupModal = (params) => {
  const [activePrivilegeGroupsArr, setactivePrivilegeGroupsArr] = React.useState<any>([]);
  const userId = params?.userId;
  const loading: any = useState("");
  const selectedGroups: any = useState(false);

  useEffect(() => {
    fetchPrivilegeGroupsList();
  }, [params]);

  const fetchPrivilegeGroupsList = async () => {
    let data;

    if (Object.values(userGroupList.get()).length <= 0) {
      data = await getUserGroupList(appSettings.get().language);
    }

    if (data) {
      userGroupList.set(data);
    }
    extractPrivileges(userId);
  };
  async function extractPrivileges(index: any) {
    loading.set(true);
    const privilegeGroupData = await fetchPrivilegeGroups(index);
    const privilegeGroupDataResponse = privilegeGroupData?.data ?? [];
    let checkedPrivilegeGroupshIds: any = [];
    if (privilegeGroupDataResponse && privilegeGroupDataResponse.length > 0) {
      try {
        privilegeGroupDataResponse.forEach((item) => {
          checkedPrivilegeGroupshIds.push(item.groupId);
        });
      } catch (error) { }
    }

    if (checkedPrivilegeGroupshIds.length > 0) {
      setactivePrivilegeGroupsArr(checkedPrivilegeGroupshIds);
    }
    loading.set(false);
  }
  const userListData = userListStore;

  const privilegeGroup: any = userListData?.userGroupList?.attach(Downgraded)?.get() || [];

  const { userGroupList }: any = useState(userListStore);

  const appSettings: any = useState(appSetting);
  const filteredPrivilegeGroups: any = useState([]);

  let checkedPrivilegeGroups: any = [];

  useEffect(() => {
    filteredPrivilegeGroups.get().forEach((element) => {
      checkedPrivilegeGroups.push(element);
    });
    if (checkedPrivilegeGroups.length > 0) {
      setactivePrivilegeGroupsArr(checkedPrivilegeGroups);
    }
  }, []);

  const updateActivePrivilegeGroups = (status, id) => {
    let privilegeGroups: any = [];
    if (activePrivilegeGroupsArr.includes(id)) {
      privilegeGroups = activePrivilegeGroupsArr.filter((p) => parseInt(p) !== id);
    } else {
      privilegeGroups = [...activePrivilegeGroupsArr, id];
    }
    setactivePrivilegeGroupsArr(privilegeGroups);
  };

  const closeCondition = async () => {
    let obj = {
      userId: userId,
      groupIds: activePrivilegeGroupsArr,
    };

    if (!userId) {
      addToaster({
        status: "error",
        title: "Validation Error",
        message: "User Id not found!",
      });
    }

    try {
      const res = await savePrivilegeGroupsById(obj);
      if (res?.data.length > 0) {
        addToaster({
          status: "success",
          title: "Updated",
          message: `successfully updated !`,
        });
      } else {
        addToaster({
          status: "warning",
          title: "No Changes",
          message: "Do not have changes to update!",
        });
      }
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: "Failed to update permitted branches.",
      });
    }

    closeGlobalModal();
    params?.actionBackToList();
  };

  return (
    <>
      <List component="nav" style={{ height: '310px', overflowY: 'scroll' }}>
        {privilegeGroup.map((p: any, index) => selectedGroups.get() && !activePrivilegeGroupsArr.includes(parseInt(p.id)) ? <div key={index}></div> : (
          <ListItem divider key={index}>
            <CheckBoxComponent checked={activePrivilegeGroupsArr.includes(parseInt(p.id)) ? true : false} onChange={(e) => updateActivePrivilegeGroups(e.target.checked, parseInt(p.id))} />
            <Stack direction={'row'} flex={1} justifyContent={'space-between'}>
              <Typography variant="body1" fontWeight={"100"}>
                {p.name}
              </Typography>
              <Typography variant="caption">{p.id}</Typography>
            </Stack>
          </ListItem>
        ))}
      </List >
      <Stack direction="row" spacing={2} justifyContent="space-between" pt={2}>
        <ButtonComponent title={selectedGroups.get() ? "All Groups" : "Selected Groups"} variant="contained" onClick={() => selectedGroups.set(!selectedGroups.get())} loadingbtn={true} color="info" />
        <ButtonComponent title="Assign To Privilege Group(s)" variant="contained" onClick={closeCondition} loadingbtn={true} color="info" />
      </Stack>
    </>
  );
};

export default PrevilegeGroupModal;
