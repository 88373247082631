import { Box, Stack } from "@mui/material";
import { standardDateFormatter } from "los-util";
import { useTranslation } from "react-i18next";
import { deSelectedBtn, selectedBtn, VARIANT } from "../../../../../../../../configs/constants/styleValues";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import { StyledButton } from "../../../../../../../InputComponents/ButtonComponent/StyledButton";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { FormLabelComponentResponsive } from "../../../../../../../InputHelperComponents/FormLabelComponent/FormLabelComponent";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
/**---------------------------------Property/Land is having Building------------------------------------------**/

export const PropertyOrLandIsHavingBuildingSection = ({ props }) => {
  return (
    <>
      <InputFieldsStack>
        <InputGrid visibility={1}>
          <Box mb={2}>
            <FormLabelComponentResponsive>Property/Land is having Building ?</FormLabelComponentResponsive>
          </Box>
          <Box display="flex">
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionPropertyOrLandIsHavingBuilding.get() === true ? selectedBtn : deSelectedBtn}
                title={"Yes"}
                onClick={() => {
                  props?.doc?.actionPropertyOrLandIsHavingBuilding.set(true);
                }}
              />
            </Box>
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionPropertyOrLandIsHavingBuilding.get() === false ? selectedBtn : deSelectedBtn}
                title={"No"}
                onClick={() => {
                  props?.doc?.actionPropertyOrLandIsHavingBuilding.set(false);
                }}
              />
            </Box>
          </Box>
        </InputGrid>
      </InputFieldsStack>
      {props?.doc?.actionPropertyOrLandIsHavingBuilding.get() && <PropertyOrLandIsHavingBuildingInputState props={props} />}
    </>
  );
};

export const PropertyOrLandIsHavingBuildingInputState = ({ props }) => {
  const { t: translate } = useTranslation();
  const {
    propBuildUpAreaOfBuildings, //0
  } = props.fields || {};
  return (
    <>
      <InputFieldsStack>
        <InputGrid visibility={propBuildUpAreaOfBuildings.visible}>
          <TextBoxComponent
            //value={''}
            value={props?.doc?.propBuildUpAreaOfBuildings.get()}
            onChange={(e) => {
              props?.doc?.propBuildUpAreaOfBuildings.set(e.target.value);
            }}
            label={translate("Build Up Area of Buildings")}
            onInput={OnDemandValueFormatter.alphaNumericSpaces}
            required={propBuildUpAreaOfBuildings.mandatory === 1 ? true : false}
          />
        </InputGrid>
      </InputFieldsStack>
    </>
  );
};

/**---------------------------------Special Condition Of Approved Buliding Plan ?------------------------------------------**/

export const SpecialConditionOfApprovedBulidingPlanSection = ({ props }) => {
  return (
    <>
      <InputGrid visibility={1} variant={VARIANT?.extraLongGrid}>
        <Box mb={2} width={"100%"}>
          <FormLabelComponentResponsive sx={{ display: "flex", flexWrap: "wrap !important" }}>
            {/* <Typography> */}
            Special Condition Of Approved Buliding Plan ?{/* </Typography> */}
          </FormLabelComponentResponsive>
        </Box>
        <Box display="flex">
          <Box mr={1}>
            <StyledButton
              styles={props?.doc?.actionSpecialConditionOfApprovedBulidingPlan.get() === true ? selectedBtn : deSelectedBtn}
              title={"Yes"}
              onClick={() => {
                props?.doc?.actionSpecialConditionOfApprovedBulidingPlan.set(true);
              }}
            />
          </Box>
          <Box mr={1}>
            <StyledButton
              styles={props?.doc?.actionSpecialConditionOfApprovedBulidingPlan.get() === false ? selectedBtn : deSelectedBtn}
              title={"No"}
              onClick={() => {
                props?.doc?.actionSpecialConditionOfApprovedBulidingPlan.set(false);
              }}
            />
          </Box>
        </Box>
      </InputGrid>
    </>
  );
};

export const SpecialConditionOfApprovedBulidingPlanInputState = ({ props }) => {
  return;
};

/**---------------------------------Approved Building plan  ?------------------------------------------**/

export const ApprovedBuildingplan = ({ props }) => {
  return (
    <>
      <InputGrid visibility={1}>
        <Box mb={2}>
          <FormLabelComponentResponsive>Approved Building plan ?</FormLabelComponentResponsive>
        </Box>
        <Box display="flex">
          <Box mr={1}>
            <StyledButton
              styles={props?.doc?.approvedBuildingPlan.get() === true ? selectedBtn : deSelectedBtn}
              title={"Yes"}
              onClick={() => {
                props?.doc?.approvedBuildingPlan.set(true);
              }}
            />
          </Box>
          <Box mr={1}>
            <StyledButton
              styles={props?.doc?.approvedBuildingPlan.get() === false ? selectedBtn : deSelectedBtn}
              title={"No"}
              onClick={() => {
                props?.doc?.approvedBuildingPlan.set(false);
              }}
            />
          </Box>
        </Box>
      </InputGrid>
    </>
  );
};

/**--------------------------------- Legal Opinion Obtained ------------------------------------------**/
export const LegalOpinionObtainedSection = ({ props }) => {
  return (
    <>
      <InputFieldsStack>
        <InputGrid visibility={1}>
          <Box mb={2}>
            <FormLabelComponentResponsive>Legal Opinion Obtained ?</FormLabelComponentResponsive>
          </Box>
          <Box display="flex">
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionLegalOpinionObtained.get() === true ? selectedBtn : deSelectedBtn}
                title={"Yes"}
                onClick={() => {
                  props?.doc?.actionLegalOpinionObtained.set(true);
                }}
              />
            </Box>
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionLegalOpinionObtained.get() === false ? selectedBtn : deSelectedBtn}
                title={"No"}
                onClick={() => {
                  props?.doc?.actionLegalOpinionObtained.set(false);
                }}
              />
            </Box>
          </Box>
        </InputGrid>
        {props?.doc?.actionLegalOpinionObtained.get() && <LegalOpinionObtainedInputState props={props} />}
      </InputFieldsStack>
    </>
  );
};

export const LegalOpinionObtainedInputState = ({ props }) => {
  const { t: translate } = useTranslation();
  const { propObtainedDate } = props.fields || {};
  return (
    <InputGrid visibility={propObtainedDate.visible}>
      <ValidateDatePicker
        value={formattedDate(props?.doc?.propObtainedDate.get())}
        onChange={(date) => {
          const preparedDate = standardDateFormatter(date);
          if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
            props?.doc?.propObtainedDate.set(preparedDate);
          }
        }}
        label={`${translate("Obtained Date")} (DD/MM/YYYY)`}
        enableFuture={false}
        disablePast={false}
        required={propObtainedDate.mandatory === 1 ? true : false}
      />
    </InputGrid>
  );
};

/**-------------------------------Title Insurance----------------------------------------------------**/

export const TitleInsuranceSection = ({ props }) => {
  return (
    <>
      <Stack width={"100%"} direction={"row"} alignItems={"flex-end"} gap={2} mt={2}>
        <InputGrid visibility={1}>
          <Box mb={2}>
            <FormLabelComponentResponsive>Title Insurance Required?</FormLabelComponentResponsive>
          </Box>
          <Box display="flex">
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionTitleInsurance.get() === true ? selectedBtn : deSelectedBtn}
                title={"Yes"}
                onClick={() => {
                  props?.doc?.actionTitleInsurance.set(true);
                }}
              />
            </Box>
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionTitleInsurance.get() === false ? selectedBtn : deSelectedBtn}
                title={"No"}
                onClick={() => {
                  props?.doc?.actionTitleInsurance.set(false);
                }}
              />
            </Box>
          </Box>
        </InputGrid>
        <TitleInsuranceInputState props={props} />
      </Stack>
    </>
  );
};

export const TitleInsuranceInputState = ({ props }) => {
  const { t: translate } = useTranslation();
  const { titleInsuranceCompanyName, titleInsurancePolicyNumber, titleInsuredAmount, insuranceCompanyName, insurancePolicyNumber, insuredAmount } = props.fields || {};

  if (!props?.doc?.actionTitleInsurance.get()) {
    return <></>;
  }

  return (
    <>
      <InputGrid visibility={titleInsuranceCompanyName.visible}>
        <TextBoxComponent
          value={props?.doc?.titleInsuranceCompanyName.get()}
          onChange={(e) => {
            props?.doc?.titleInsuranceCompanyName.set(e.target.value);
          }}
          label={translate("Insurance Company Name")}
          onInput={OnDemandValueFormatter.alphabeticalSpaces}
          required={props?.doc?.actionTitleInsurance.get() ? true : false}
        />
      </InputGrid>
      <InputGrid visibility={titleInsurancePolicyNumber.visible}>
        <TextBoxComponent
          value={props?.doc?.titleInsurancePolicyNumber.get()}
          //value={props?.doc?.issuingAuthority}
          onChange={(e) => {
            props?.doc?.titleInsurancePolicyNumber.set(e.target.value);
          }}
          label={translate("Insurance Policy Number")}
          onInput={OnDemandValueFormatter.alphaNumericSpaces}
          required={props?.doc?.actionTitleInsurance.get() ? true : false}
        />
      </InputGrid>
      <InputGrid visibility={titleInsuredAmount.visible}>
        <TextBoxComponent
          value={formatCurrency(props?.doc?.titleInsuredAmount.get())}
          onChange={(e) => {
            props?.doc?.titleInsuredAmount.set(e.target.value);
          }}
          label={translate("Insured Value")}
          onInput={OnDemandValueFormatter.numeric}
          required={props?.doc?.actionTitleInsurance.get() ? true : false}
        />
      </InputGrid>
    </>
  );
};
