import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { enDictionary } from "../../../../../../configs/languageFiles/english";
import { getApplicationHistoryFieldsData } from "../../../../../../services/creditFileApiCall";
import { closeBtn } from "../../../../../../styles";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../../../GlobalModal/GlobalModal";

const ApplicationChangesModal = (params) => {
  const [newData, setNewData] = React.useState<any>({});
  const [oldData, setOldData] = React.useState<any>({});

  const closeCondition = () => {
    closeGlobalModal();
  };

  useEffect(() => {
    const dataObject = params?.item?.other ?? [];

    if (dataObject?.length !== 0) {
      let dataObjectJson: any = null;

      try {
        dataObjectJson = JSON.parse(dataObject);
      } catch (error) {
        setNewData({ "new": "No Data Available" });
        setOldData({ "old": "No Data Available" });
        return;
      }

      const { newJson: newDataJson, oldJson: oldDataJson } = dataObjectJson?.data;

      if (!newDataJson || !oldDataJson) {
        setNewData({ "new": "No Data Available" });
        setOldData({ "old": "No Data Available" });
        return;
      }

      (async () => {
        const { new: newData, old: oldData } = await getApplicationHistoryFieldsData({ newDataJson, oldDataJson });

        if (newData && oldData) {
          setNewData(newData);
          setOldData(oldData);
        }
      })();
    }
  }, []);

  return (
    <Box>
      <Stack direction={"row"} spacing={10}>
        <Stack flex={1}>
          <h3 className="basic-font" style={{ marginBottom: "10px" }}>
            Old Data
          </h3>
          {Object.keys(oldData).map((key, index) => {
            return <Stack key={index}>
              <Stack direction={"row"} flex={1} py={1}>
                <Typography variant="subtitle1" flex={1}>
                  {enDictionary[key] ? enDictionary[key] : key.replaceAll("\"", "")}{oldData[key] != newData[key] ? <span style={{ color: 'red' }}>*</span> : <></>}
                </Typography>
                <Typography variant="subtitle1" flex={1} color={oldData[key] != newData[key] ? 'red' : undefined}>
                  {oldData[key]}
                </Typography>
              </Stack>
              <Divider />
            </Stack>
          })}
        </Stack>

        <Stack flex={1}>
          <h3 className="basic-font" style={{ marginBottom: "10px" }}>
            New Data
          </h3>
          {Object.keys(newData).map((key, index) => {
            return <Stack key={index}>
              <Stack direction={"row"} flex={1} py={1}>
                <Typography variant="subtitle1" flex={1}>
                  {enDictionary[key] ? enDictionary[key] : key.replaceAll("\"", "")}{oldData[key] != newData[key] ? <span style={{ color: 'red' }}>*</span> : <></>}
                </Typography>
                <Typography variant="subtitle1" flex={1} color={oldData[key] != newData[key] ? 'red' : undefined}>
                  {newData[key]}
                </Typography>
              </Stack>
              <Divider />
            </Stack>
          })}
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2} justifyContent="end" pt={3} mt={2} pr={2} pb={1}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeCondition}
          loadingbtn={true}
          style={closeBtn}
        />
      </Stack>
    </Box>
  );
};

export default ApplicationChangesModal;
