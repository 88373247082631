import { useHookstate } from "@hookstate/core";
import { Box, Grid, Paper, Stack, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { addToaster } from "../components/OtherComponents/GlobalToast";
import { CircularProgressComponent } from "../components/OtherComponents/ProgressComponent/ProgressComponent";
import { StyledTableRow } from "../components/OtherComponents/TabComponent/StyledTableComponents";
import { getInvokeTC } from "../services/creditFileApiCall";

let tcPayload = {
  loanAmount: null,
  loanTenure: null,
  loanInterestRate: null,
  loanRepayDay: "",
  loanRepayOn: "F",
  loanRepayFrequency: "M",
  loanGracePeriod: 0,
};

function ViewTC({ title = true, applicationId = null, trailCalParams, numTerms, loanAmount, interestRate, schemeSubmit = null, trailCalResult = [] }) {
  const loading = useHookstate(false);
  let repaymentDate = "";
  const [installmentData, setInstallementData] = React.useState<any>();

  useEffect(() => {
    if (trailCalResult && trailCalResult?.length > 0) {
      setInstallementData(trailCalResult);
    }

    if (trailCalParams) {
      repaymentDate = trailCalParams?.repaymentDate || trailCalParams.LoanRepayDay || "";

      tcPayload.loanAmount = trailCalParams.LoanAmount ?? loanAmount;
      tcPayload.loanTenure = trailCalParams.LoanTenure ?? numTerms;
      tcPayload.loanInterestRate = interestRate;
      tcPayload.loanRepayDay = repaymentDate;
    }

    return () => {
      for (const key of ["loanAmount", "loanTenure", "loanInterestRate", "loanRepayDay"]) {
        tcPayload[key] = null;
      }
    };
  }, []);

  const validCalculation = (payload) => {
    let valid: boolean = true;
    const data = payload.get();
    for (const key of ["loanAmount", "loanTenure", "loanInterestRate", "loanRepayDay"]) {
      if (!data[key]) {
        valid = false;
        break;
      }
    }

    return valid;
  };

  const invokeTC = async (payload) => {
    if (validCalculation(payload) === false) {
      addToaster({
        status: "error",
        title: "Empty",
        message: "Please fill required fields",
      });
      return;
    }

    const _payload = {
      LoanAmount: Number(payload?.loanAmount?.get() ?? 0),
      LoanTenure: Number(payload?.loanTenure?.get() ?? 0),
      LoanInterestRate: Number(payload?.loanInterestRate?.get() ?? 0),
      LoanRepayOn: "F",
      LoanRepayFrequency: "M",
      LoanRepayDay: Number(payload?.loanRepayDay?.get() ?? ""),
      LoanGracePeriod: 0,
      ApplicationReferance: (applicationId ?? "").toString(),
    };
    loading.set(true);

    let invokeRes = await getInvokeTC(_payload);

    if (invokeRes?.status === 1) {
      setInstallementData(invokeRes?.data ?? []);
    }

    if (schemeSubmit) {
      const action: any = schemeSubmit ?? {};
      await action();
    }

    loading.set(false);
  };

  const TCResult = ({ installments }) => {
    return (
      <Box m={2} mt={2}>
        <Paper elevation={0} sx={{ padding: 2 }}>
          {loading.get() ? (
            <CircularProgressComponent size={30} />
          ) : (
            <>
              {installments?.length > 0 && <TableHeadComponent />}
              <TableBodyComponent records={installments} loanAmount={tcPayload?.loanAmount ?? 0}></TableBodyComponent>
            </>
          )}
        </Paper>
      </Box>
    );
  };

  return (
    <>
      <Box m={2}>
        {title && (
          <Paper elevation={0}>
            <Grid container>
              <Stack m={2} justifyContent={"space-between"} direction={"row"}>
                <Box className="screen_title--basic align-center">CBS TC Calculator</Box>
              </Stack>
            </Grid>
          </Paper>
        )}

        <Box></Box>

        <Paper elevation={0}>
          <TCResult installments={installmentData}></TCResult>
        </Paper>
      </Box>
    </>
  );
}

const TableHeadComponent = () => {
  return (
    <TableHead>
      <TableRow>
        <TableHeaderRow id="Serial No" align="left" />
        <TableHeaderRow id="Payment Date" align="right" />
        <TableHeaderRow id="Capital Recovery" align="right" />
        <TableHeaderRow id="Interest Recovery" align="left" />
        <TableHeaderRow id="Termly Installment" align="left" />
        <TableHeaderRow id="Capital Outstanding" align="left" />
      </TableRow>
    </TableHead>
  );
};

const TableHeaderRow = ({ id, align }) => {
  const { t: translate } = useTranslation();

  return (
    <TableCell className="basic-font basic-font-color-bold font-size-16" align={align}>
      {translate(id)}
    </TableCell>
  );
};
const TableDataRow = ({ value, align }) => {
  return (
    <TableCell className="basic-font basic-font-color font-size-14" align={align}>
      {value}
    </TableCell>
  );
};

const TableBodyComponent = ({ records = [], loanAmount = 0 }) => {
  let totalCapital = 0;
  let totalInterest = 0;
  let totalInstallment = 0;
  let totalOutstading = loanAmount;

  if (records.length === 0) {
    return (
      <>
        <Box p={10}>
          <Typography textAlign={"center"}>No Records Found.</Typography>
        </Box>
      </>
    );
  }

  return (
    <TableBody>
      {records.map((item: any, index) => {
        let record: any = {};
        if (!item.Sl_No) {
          record = {
            serialNo: index,
            paymentDate: item?.paymentDate,
            capitalRecovery: Number(item?.capitalRecovery),
            interestRecovery: Number(item?.interestRecovery),
            termlyInstallment: Number(item?.termlyInstallment),
            capitalOutstanding: Number(item?.capitalOutstanding),
          };
        } else {
          record = {
            serialNo: item?.Sl_No,
            paymentDate: item?.RepaymentDate,
            capitalRecovery: item?.Principal,
            interestRecovery: item?.Interest,
            termlyInstallment: item?.Installment,
            capitalOutstanding: item?.Installment,
          };
        }

        totalCapital = totalCapital + record.capitalRecovery;
        totalInterest = totalInterest + record.interestRecovery;
        totalInstallment = totalInstallment + record.termlyInstallment;
        totalOutstading = totalOutstading - record.capitalRecovery;

        return (
          <StyledTableRow key={index}>
            <TableCell component="th" scope="row" className="basic-font font-size-14 basic-font-color-bold bold">
              {record.serialNo}
            </TableCell>
            <TableDataRow value={record.paymentDate} align="center" />
            <TableDataRow value={record.capitalRecovery} align="right" />
            <TableDataRow value={record.interestRecovery} align="right" />
            <TableDataRow value={record.termlyInstallment} align="right" />
            <TableDataRow value={totalOutstading.toFixed(2)} align="right" />
          </StyledTableRow>
        );
      })}
      <Box key={records.length + 1} py={2}>
        <div>{` `}</div>
      </Box>
      <StyledTableRow key={records.length}>
        <TableCell component="th" scope="row" className="basic-font font-size-14 basic-font-color-bold bold"></TableCell>
        <TableDataRow value={`${records.length} Terms`} align="center" />
        <TableDataRow value={totalCapital.toFixed(2)} align="right" />
        <TableDataRow value={totalInterest.toFixed(2)} align="right" />
        <TableDataRow value={totalInstallment.toFixed(2)} align="right" />
        <TableDataRow value={Number(loanAmount ?? "0.00")?.toFixed(2)} align="right" />
      </StyledTableRow>
    </TableBody>
  );
};

export default ViewTC;
