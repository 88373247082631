import { useState } from "@hookstate/core";
import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { bufferToBlobUrl } from "../../../../../configs/base64Conversion";
import { appSetting } from "../../../../../configs/mainStore";
import { getPriorApprovalMetaList, getPriorDocumentList } from "../../../../../services/applicationApis";
import { fetchPrivilegeGroups } from "../../../../../services/configurationApis";
import { getPriorApprovalDocument } from "../../../../../services/creditFileApiCall";
import { getUserGroupList } from "../../../../../services/userPrivilegeService";
import { getUserDetailsById } from "../../../../../services/userService";
import { dateFormatWithTimeChange } from "../../../../../utility/fomatters";
import { disableAccess } from "../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { addToaster } from "../../../GlobalToast";
import PriorLeadListComponent from "../../../RejectedLeads/PriorLeadListComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";

const CreditLeadPriorApproval = ({ data, applicationId, currentApplicationWfData, innerComponentData, tabsToEdit }) => {
  const [priorApprovalData, setPriorApprovalData]: any = React.useState({});
  const appSettings: any = useState(appSetting);
  const [requestRoleNames, setRequestRoleNames]: any = React.useState([]);
  const [approveRoleNames, setApproveRoleNames]: any = React.useState([]);
  const [documentList, setDocumentList]: any = React.useState([]);

  useEffect(() => {
    getPriorApprovalLists();
    getPriorApplicationApprovalDocument();
  }, [applicationId]);

  const getPriorApplicationApprovalDocument = async () => {
    let { data } = await getPriorDocumentList({ applicationId });
    setDocumentList(data || []);
  }

  const getPriorApprovalLists = async () => {
    try {
      let response = await Promise.all([getUserGroupList(appSettings.get().language), getPriorApprovalMetaList(applicationId)]);

      let priorApprovalDataObj = response[1].data[0] || {};
      let reuestByUserRoles: any[] = [];
      let reuestByUserRolesIds: any[] = [];
      let filteredreuestByUserRoles: any[] = [];
      let filteredreuestByUserRoleNames: any[] = [];
      let approvedByUserRoles: any[] = [];
      let approvedByUserRolesIds: any[] = [];
      let filteredapprovedByUserRoles: any[] = [];
      let filteredapprovedByUserRoleNames: any[] = [];

      if (priorApprovalDataObj) {
        let userGroupsData = await Promise.all([fetchPrivilegeGroups(priorApprovalDataObj?.requestUserId), fetchPrivilegeGroups(priorApprovalDataObj?.actionedBy)]);
        if (userGroupsData.length > 0) {
          reuestByUserRoles = userGroupsData[0].data || [];
          approvedByUserRoles = userGroupsData[1].data || [];
        }

        if (reuestByUserRoles.length > 0) {
          reuestByUserRoles.forEach((obj) => {
            reuestByUserRolesIds.push(obj.groupId);
          });

          if (response[0].length > 0) {
            filteredreuestByUserRoles = response[0].filter((obj) => {
              return reuestByUserRolesIds.includes(obj.id);
            });

            filteredreuestByUserRoles.forEach((obj) => {
              filteredreuestByUserRoleNames.push(obj.name);
            });
          }

          setRequestRoleNames(filteredreuestByUserRoleNames);
        } else {
          setRequestRoleNames([]);
        }

        if (approvedByUserRoles.length > 0) {
          approvedByUserRoles.forEach((obj) => {
            approvedByUserRolesIds.push(obj.groupId);
          });

          if (response[0].length > 0) {
            filteredapprovedByUserRoles = response[0].filter((obj) => {
              return approvedByUserRolesIds.includes(obj.id);
            });

            filteredapprovedByUserRoles.forEach((obj) => {
              filteredapprovedByUserRoleNames.push(obj.name);
            });
          }

          setApproveRoleNames(filteredapprovedByUserRoleNames);
        } else {
          setApproveRoleNames([]);
        }
      }

      if (response[1].data.length > 0) {
        setPriorApprovalData(response[1].data[0]);
      } else {
        setPriorApprovalData({});
      }
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: "Error on fetching prior approval data",
      });
    }
  };

  const previewBtnClicked = async (id) => {
    const resultFile: any = await getPriorApprovalDocument(id);

    if (resultFile?.fileData?.data) {
      const fileBuffer = resultFile?.fileData.data;
      const contentType = resultFile?.contentType;

      const blobUrl = bufferToBlobUrl(fileBuffer, contentType);

      window.open(
        `${blobUrl}`,
        "targetWindow",
        `toolbar=no,
           location=no,
           status=no,
           menubar=no,
           scrollbars=yes,
           resizable=yes,
           width=500,
           height=500`
      );
    }
  };

  const checkPrivilegeStatus = disableAccess({ isTabInEditString: tabsToEdit.includes(innerComponentData?.id) });

  return (
    <div className="full-width full-height">
      <InnerSectionHeaderComponent innerComponentData={innerComponentData} applicationId={applicationId} currentApplicationWfData={currentApplicationWfData} callBackOnAction={() => { }} isOfficerGroup={false} isLoading={false} officerGroupId={null} disabled={checkPrivilegeStatus} />
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          {priorApprovalData ? (
            <Grid container columns={12} justifyContent={"space-between"}>
              <Grid xs={5.75} p={1.5} style={{ borderRadius: "12px" }} className="prior-request-meta">
                <Grid container>
                  <Stack direction={"row"} width={"100%"} mb={1.5}>
                    <Stack>
                      <Typography variant="body1">Requested User:</Typography>
                    </Stack>
                    <Stack ml={1.5}>{priorApprovalData?.requestUserId ? getUserDetailsById(priorApprovalData?.requestUserId)[appSettings.get().language]?.name : "N/A"}</Stack>
                  </Stack>
                  <Stack direction={"row"} width={"100%"} mb={1.5}>
                    <Stack>
                      <Typography variant="body1">User Role:</Typography>
                    </Stack>
                    <Stack ml={1.5}>{requestRoleNames.length > 0 ? requestRoleNames.map((element) => element).join(",") : "N/A"}</Stack>
                  </Stack>
                  <Stack direction={"row"} width={"100%"} mb={1.5}>
                    <Stack>
                      <Typography variant="body1">Request Comment:</Typography>
                    </Stack>
                    <Stack ml={1.5}>{priorApprovalData.approvalComment ? priorApprovalData.approvalComment : "N/A"}</Stack>
                  </Stack>
                  <Stack direction={"row"} width={"100%"} mb={1.5}>
                    <Stack>
                      <Typography variant="body1">Date and Time:</Typography>
                    </Stack>
                    <Stack ml={1.5}>{priorApprovalData.requestDate ? dateFormatWithTimeChange(priorApprovalData.requestDate) : "N/A"}</Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Grid xs={5.75} p={1.5} style={{ borderRadius: "12px" }} className="prior-approve-meta">
                <Grid container>
                  <Stack direction={"row"} width={"100%"} mb={1.5} justifyContent={"space-between"}>
                    <Stack direction={"row"}>
                      <Typography variant="body1">Approved By:</Typography>
                      <Stack ml={1.5}>{priorApprovalData?.actionedBy ? getUserDetailsById(priorApprovalData?.actionedBy)[appSettings.get().language]?.name : "N/A"}</Stack>
                    </Stack>
                    <Stack ml={1.5}>
                      {documentList?.length > 0 ? <PriorLeadListComponent
                        docList={documentList}
                        previewBtnClicked={previewBtnClicked}
                      /> : <></>
                      }
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} width={"100%"} mb={1.5}>
                    <Stack>
                      <Typography variant="body1">User Role:</Typography>
                    </Stack>
                    <Stack ml={1.5}>{approveRoleNames.length > 0 ? approveRoleNames.map((element) => element).join(",") : "N/A"}</Stack>
                  </Stack>
                  <Stack direction={"row"} width={"100%"} mb={1.5}>
                    <Stack>
                      <Typography variant="body1">Comment:</Typography>
                    </Stack>
                    <Stack ml={1.5}>{priorApprovalData.approvedComment || "N/A"}</Stack>
                  </Stack>
                  <Stack direction={"row"} width={"100%"} mb={1.5}>
                    <Stack>
                      <Typography variant="body1">Date and Time:</Typography>
                    </Stack>
                    <Stack ml={1.5}>{priorApprovalData.approvedDate ? dateFormatWithTimeChange(priorApprovalData.approvedDate) : "N/A"}</Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
          )}
        </Stack>
      </Grid>
    </div>
  );
};

export default CreditLeadPriorApproval;
