import { useState } from '@hookstate/core';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Box, Grid, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { ERROR_CODES } from '../../../../../configs/constants/errorCode';
import { userDetails, workflowMasterData } from '../../../../../configs/mainStore';
import {
  applicationTimelineApi,
  getLegalList,
  getRiskList,
  updateRefer,
} from '../../../../../services/creditFileApiCall';
import { closeBtn, doneBtn, rejectBtn } from '../../../../../styles';
import { responseSuccessCode } from '../../../../../utility/other';
import { referAction, referStatus } from '../../../../../utility/util';
import { ButtonComponent } from '../../../../InputComponents/ButtonComponent/ButtonComponent';
import ModalInputComponent from '../../../../InputComponents/ModalInputComponent/ModalInputComponent';
import { TextBoxComponent } from '../../../../InputComponents/TextBoxComponent/TextBoxComponent';
import { closeGlobalModal } from '../../../GlobalModal/GlobalModal';
import { addToaster } from '../../../GlobalToast';

const ReferActionOppinionModal = (params) => {
  const { wf_system_users }: any = useState(workflowMasterData);
  const initialData = { note: params?.note, opinionNote: '', referId: params?.referId };

  const loginUser = useState(userDetails);

  const referState: any = useState(initialData);
  const showErrors = useState(false);

  const createdBy = wf_system_users?.get()?.find((usr) => usr?.id == params?.data?.createdBy);

  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [referredBy, setReferredBy]: any = React.useState('');

  const referredData = wf_system_users?.get()?.find((usr) => usr?.id == referredBy);

  const updateReferHandle = async (status) => {
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      const action = Object.keys(referAction)?.find((act) => referAction[act]?.key == status);
      const referStat = Object.keys(referStatus)?.find((sts) => referStatus[sts]?.key == 'oppinion');

      const data = {
        applicationId: params?.applicationId,
        referId: referState.referId.get(),
        action: action,
        referStatus: referStat,
        assignedBy: referredBy,
        opinionNote: referState.opinionNote.get(),
        createdBy: createdBy?.id,
      };
      const res: any = await updateRefer(data);

      if (res?.status == responseSuccessCode) {
        addToaster({
          status: 'success',
          title: 'Success',
          message: res?.data?.msg,
        });

        const referenceId = params?.referId;
        const referenceType = params?.referType;

        const appTimelineParams = {
          referenceId,
          applicationId: params?.applicationId,
          referenceType,
          actionedBy: loginUser?.userId?.get(),
          assignee: createdBy?.id,
          actionNote: referState?.opinionNote?.get() || '',
          actionType: 'OPINION_ADDED',
        };

        const applicationTimelineApiResponse = await applicationTimelineApi(appTimelineParams);

        if (applicationTimelineApiResponse?.status === ERROR_CODES.success) {
          addToaster({
            status: 'success',
            title: 'Success',
            message: applicationTimelineApiResponse?.data?.message,
          });
        } else {
          addToaster({
            status: 'error',
            title: 'Error',
            message: 'Timeline Update Failed',
          });
        }

        // calling the modal callback passing necessary arguments
        if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
          params.callBackOnAction(res?.data?.data);
        }
      } else {
        addToaster({
          status: 'error',
          title: 'Error',
          message: res?.data?.msg,
        });
      }

      closeGlobalModal();
    }
  };

  const closeRefer = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return referState.opinionNote.get() === '';
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  useEffect(() => {
    (async function () {
      if (params?.addOppinion === true) {
        const { decisionId } = params?.data;
        if (params?.applicationId) {
          if (params?.referType === 9) {
            const data = await getLegalList(params?.applicationId, decisionId);
            if (data && data.length > 0) {
              setTitle(data[0]?.title);
              setDescription(data[0]?.description);
              setReferredBy(data[0]?.createdBy);
            }
          } else if (params?.referType === 8) {
            const data = await getRiskList(params?.applicationId, decisionId);
            if (data && data.length > 0) {
              setTitle(data[0]?.title);
              setDescription(data[0]?.description);
              setReferredBy(data[0]?.createdBy);
            }
          }
        }
      }
    })();
  });

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Add Opinion</div>
      </Stack>

      <Grid container spacing={1} mt={2} rowSpacing={2} className="modal-text-field">
        <ModalInputComponent
          title="Referral Id"
          component={
            <div className="align-left font-size-14 basic-font basic-font-color-bold">{referState?.referId?.get()}</div>
          }
          isMandatory={false}
          showErrors={false}
        />
        <ModalInputComponent
          title="Created By"
          component={
            <div className="align-left font-size-14 basic-font basic-font-color-bold">{createdBy?.fullName}</div>
          }
          isMandatory={false}
          showErrors={false}
        />
        <ModalInputComponent
          title="Referral Note"
          component={
            <div className="align-left font-size-14 basic-font basic-font-color-bold">{referState?.note?.get()}</div>
          }
          isMandatory={false}
          showErrors={false}
        />
        <ModalInputComponent
          title={params?.referType === 9 ? 'Legal Note Title' : 'Risk Note Title'}
          component={<div className="align-left font-size-14 basic-font basic-font-color-bold">{title}</div>}
          isMandatory={false}
          showErrors={false}
        />
        <ModalInputComponent
          title={params?.referType === 9 ? 'Legal Note Description' : 'Risk Note Description'}
          component={<div className="align-left font-size-14 basic-font basic-font-color-bold">{description}</div>}
          isMandatory={false}
          showErrors={false}
        />
        <ModalInputComponent
          title="Referred By"
          component={
            <div className="align-left font-size-14 basic-font basic-font-color-bold">{referredData?.fullName}</div>
          }
          isMandatory={false}
          showErrors={false}
        />
        <ModalInputComponent
          title="Opinion Note"
          component={
            <TextBoxComponent
              value={referState?.opinionNote?.get()}
              onChange={(e) => {
                resetError();
                referState.opinionNote.set(e.target.value);
              }}
              variant="outlined"
              size="small"
              multiline={true}
              minRows={4}
            />
          }
          isMandatory={true}
          showErrors={showErrors.get() && referState.opinionNote.get() === ''}
        />
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeRefer}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<NotInterestedIcon />}
          title="Reject"
          variant="contained"
          onClick={() => updateReferHandle('reject')}
          loadingbtn={true}
          style={rejectBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Approve"
          variant="contained"
          onClick={() => updateReferHandle('oppinion')}
          loadingbtn={true}
          style={doneBtn}
        />
      </Stack>
    </Box>
  );
};

export default ReferActionOppinionModal;
