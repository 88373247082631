import { Stack } from "@mui/material";
import React from "react";
import { searchAndVerifyWidget } from "../SearchAndVerifyWidget";

const Wrapper = ({ alphaChildren, key, type, value, onChange, otherParams }) => {
  if (alphaChildren.length === 0) {
    return <React.Fragment key={"Wrapper" + key}></React.Fragment>;
  }

  return (
    <Stack direction={"row"} paddingTop={1} flexWrap={"wrap"} gap={2.5} columnGap={3} key={"Stack" + key}>
      {alphaChildren.map((beta: any) => {
        return (
          <React.Fragment key={"childFragments_Wrapper" + beta?.dataKey}>
            {React.createElement(searchAndVerifyWidget(beta?.inputOptions?.type ? beta?.inputOptions?.type : beta?.type), {
              key: beta?.dataKey,
              alphaChildren: beta?.children,
              type: beta?.inputOptions?.type ? beta?.inputOptions?.type : beta?.type,
              metaData: beta,
              value,
              onChange,
              otherParams,
            })}
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

export default Wrapper;
