import { Downgraded, State } from "@hookstate/core";
import moment from "moment";
import { LengthItemValidate } from "../components/OtherComponents/ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";
import { FORMSTRUCTURE } from "../configs/interfaces";

export const taskStatus = {
  0: { color: "#6cd3ff", id: 1, name: "Open", key: "open" },
  1: { color: "#6cd3ff", id: 1, name: "Open", key: "open" },
  2: { color: "#1cd596", id: 2, name: "Closed", key: "closed" },
};

export const branchCodeMap = {
  Bulathsinhala: "1010",
  Walagedara: "1020",
  Agalawatta: "1030",
  Millaniya: "1040",
  Gonapola: "1050",
  Moranthuduwa: "1060",
  Beruwala: "1070",
  Panadura: "1080",
  Horana: "1090",
  Warakagoda: "1100",
  Ingiriya: "1110",
  Dodangoda: "1120",
  Meegahatenna: "1130",
  Baduraliya: "1140",
  Kalutara: "1150",
  Gampaha: "1160",
  Mawaramandiya: "1170",
  Minuwangoda: "1180",
  Mirigama: "1190",
  Moragahahena: "1200",
  Mathugama: "1210",
  Negombo: "1220",
  Divulapitiya: "1230",
  Nittambuwa: "1240",
  Homagama: "1250",
  Kolonnawa: "1260",
  Awissawella: "1270",
  Piliyandala: "1280",
  Ragama: "1290",
  Wadduwa: "1300",
  Kirindiwela: "1310",
  JaEla: "1320",
  Miriswatte: "1330",
  Kelaniya: "1340",
  Nugegoda: "1350",
  Hakmana: "2010",
  Urubokka: "2020",
  Deiyandara: "2030",
  Akuressa: "2040",
  Weligama: "2050",
  Gandara: "2060",
  Kekanadura: "2070",
  Ambalantota: "2080",
  Agunakolapelessa: "2090",
  Katuwana: "2100",
  Beliatta: "2110",
  Elpitiya: "2120",
  Batapola: "2130",
  Pitigala: "2140",
  Gonagalapura: "2150",
  Imaduwa: "2160",
  Baddegama: "2170",
  Thissamaharama: "2180",
  Lunugamvehera: "2190",
  Pitabeddara: "2200",
  Thalgaswala: "2210",
  Akmeemana: "2220",
  Karandeniya: "2230",
  Sooriyawewa: "2240",
  Kamburugamuwa: "2250",
  "Mirissa Ext. Office": "2251",
  Deniyaya: "2260",
  Kamburupitiya: "2270",
  Galle: "2280",
  Uragasmanhandiya: "2290",
  Yakkalamulla: "2300",
  Weeraketiya: "2310",
  Thihagoda: "2320",
  "Matara City": "2330",
  Tangalle: "2340",
  Neluwa: "2350",
  Mawarala: "2360",
  Morawaka: "2370",
  Hambantota: "2380",
  Walasmulla: "2390",
  Barawakumbuka: "2400",
  Udugama: "2410",
  Ranna: "2420",
  Ahangama: "2430",
  Hikkaduwa: "2440",
  Kirinda: "2450",
  Middeniya: "2460",
  Dikwella: "2470",
  Karapitiya: "2480",
  Balapitiya: "2490",
  Pamburana: "2500",
  Mirissa: "2510",
  Kaluwella: "2520",
  Warapitiya: "2530",
  Devinuwara: "2540",
  Kegalle: "3010",
  Pitagaldeniya: "3020",
  Rambukkana: "3030",
  Mawanella: "3040",
  Warakapola: "3050",
  Aranayake: "3060",
  Kitulgala: "3070",
  Ruwanwella: "3080",
  Dewalegama: "3090",
  Bulathkohupitiya: "3100",
  Deraniyagala: "3110",
  Rathnapura: "3120",
  Pelmadulla: "3130",
  Balangoda: "3140",
  Embilipitiya: "3150",
  Hemmathagama: "3160",
  Kolonna: "3170",
  Eheliyagoda: "3180",
  Nelumdeniya: "3190",
  Kalawana: "3200",
  Yatiyanthota: "3210",
  Godakawela: "3220",
  Erathna: "3230",
  Kuruvita: "3240",
  Nivithigala: "3250",
  Kahawatta: "3260",
  Kotiyakumbura: "3270",
  Rakwana: "3280",
  Dehiowita: "3290",
  Kiriella: "3300",
  Pothupitiya: "3310",
  Weligepola: "3320",
  "Sri Palabaddala": "3330",
  Pulungupitiya: "3340",
  Gampola: "4010",
  Udawela: "4020",
  Hataraliyadda: "4030",
  Marassana: "4040",
  Danture: "4050",
  Wattegama: "4060",
  Morayaya: "4070",
  Teldeniya: "4080",
  Pujapitiya: "4090",
  Nuwaraeliya: "4100",
  Rikillagaskada: "4110",
  Senkadagala: "4120",
  Ginigathhena: "4130",
  Pundaluoya: "4140",
  Katugastota: "4150",
  Nildandahinna: "4160",
  Agarapathana: "4170",
  Ududumbara: "4180",
  Matale: "4190",
  Dambulla: "4200",
  Galewela: "4210",
  Laggala: "4220",
  Raththota: "4230",
  Kotagala: "4240",
  Manikhinna: "4250",
  Hanguranketha: "4260",
  Daulagala: "4270",
  Naula: "4280",
  Nawalapitiya: "4290",
  Hedeniya: "4300",
  Wilgamuwa: "4310",
  Kandy: "4320",
  Peradeniya: "4330",
  "Matale District Office": "4340",
  "Kandy 2 nd": "4350",
  Buttala: "5010",
  Medagama: "5020",
  Monaragala: "5030",
  Thanamalvila: "5040",
  Badulla: "5050",
  Passara: "5060",
  Welimada: "5070",
  Kandeketiya: "5080",
  Mahiyangana: "5090",
  Wellawaya: "5100",
  Badalkumbura: "5110",
  Haputhale: "5120",
  Rideemaliyadda: "5130",
  "Uva Paranagama": "5140",
  Bandarawela: "5150",
  Meegahakiwula: "5160",
  Lunugala: "5170",
  Haldummulla: "5180",
  Girandurukotte: "5190",
  Bogahakumbura: "5200",
  Bibile: "5210",
  "Uva Maligathenna": "5220",
  Siyambalanduwa: "5230",
  Diyathalawa: "5240",
  Sevanagala: "5250",
  Madulla: "5260",
  Ampara: "6010",
  Dehiatthakandiya: "6020",
  Sammanthurai: "6030",
  Damana: "6040",
  Akkaraipatthu: "6050",
  Kalmunai: "6060",
  Mahaoya: "6070",
  Pothuwil: "6080",
  Uhana: "6090",
  Nintavur: "6100",
  Batticaloa: "6110",
  Eraur: "6120",
  Chenkalady: "6130",
  Kanthale: "6140",
  Valachchenai: "6150",
  Kattankudy: "6160",
  Trincomalee: "6170",
  Kaluwanchikudy: "6180",
  Kokkaddicholai: "6190",
  Muththur: "6200",
  "Batticaloa 2 nd": "6210",
  Kuliyapitiya: "7010",
  Pannala: "7020",
  Panduwasnuwara: "7030",
  Alawwa: "7040",
  Dummalasuriya: "7050",
  Pothuhera: "7060",
  Nikaweratiya: "7070",
  Rideegama: "7080",
  Mawathagama: "7090",
  Wariyapola: "7100",
  Kurunegala: "7110",
  Galgamuwa: "7120",
  Chilaw: "7130",
  Palakuda: "7140",
  Anamaduwa: "7150",
  Nattandiya: "7160",
  Kirimatiyana: "7170",
  Puttalam: "7180",
  Maho: "7190",
  Giriulla: "7200",
  Ibbagamuwa: "7210",
  Mundel: "7220",
  Nawagaththegama: "7230",
  Mampuri: "7240",
  Mahawewa: "7250",
  Narammala: "7260",
  Polpithigama: "7270",
  Bowatta: "7280",
  Athugalpura: "7290",
  Melsiripura: "7300",
  Ambanpola: "7310",
  "Santa Anapura": "7320",
  Paragahadeniya: "7330",
  Perakumpura: "7340",
  Kalpitiya: "7350",
  Wennappuwa: "7360",
  Mihinthale: "8010",
  Anuradhapura: "8020",
  Thambuththegama: "8030",
  Kahatagasdigiliya: "8040",
  Galnewa: "8050",
  Thalawa: "8060",
  Madawachchiya: "8070",
  Thirappane: "8080",
  Rambewa: "8090",
  Polonnaruwa: "8100",
  Medirigiriya: "8110",
  Pulasthigama: "8120",
  Hingurakgoda: "8130",
  Bakamoona: "8140",
  Galenbidunuwewa: "8150",
  Gonapathirawa: "8160",
  Manampitiya: "8170",
  Galamuna: "8180",
  "New Town - Anuradhapura": "8190",
  Siripura: "8200",
  Kaduruwela: "8210",
  Kekirawa: "8220",
  Aralaganwila: "8230",
  "Thambuththegama City": "8240",
  Sewanapitiya: "8250",
  Bogaswewa: "8260",
  Vavuniya: "9010",
  Kanakarayankulam: "9020",
  Mannar: "9030",
  Chunnakam: "9040",
  Jaffna: "9050",
  Kilinochchi: "9060",
};

export const taskAction = {
  1: { color: "#1cd596", id: 1, name: "Done", key: "done" },
  2: { color: "#ff9090", id: 2, name: "Rejected", key: "reject" },
  default: { color: "#7fb4de" },
};

export const referStatus = {
  1: { color: "#6cd3ff", id: 1, name: "Pending", key: "active" },
  2: { color: "#1cd596", id: 2, name: "Completed", key: "complete" },
  3: { color: "#7fb4de", id: 3, name: "Opinion", key: "oppinion" },
};

export const referAction = {
  1: { color: "#1cd596", id: 1, name: "Approved", key: "approve" },
  2: { color: "#ff9090", id: 2, name: "Rejected", key: "reject" },
  3: { color: "#7fb4de", id: 3, name: "Opinion", key: "oppinion" },
  default: { color: "#7fb4de" },
};

export const defaultOptions = {
  color: "#7fb4de",
};

export const permissionTypes = [
  { id: 1, name: "Hierarchy", key: "hierarchy" },
  { id: 2, name: "Group", key: "group" },
  { id: 3, name: "User", key: "user" },
];

export type IncomeExpenseFrequency = { id: number; name: string; display: string; defaultFrequencyForMonth: number; defaultFrequencyForYear: number };

export const incomeExpenseFrequency: IncomeExpenseFrequency[] = [
  {
    id: 1,
    name: "daily",
    display: "Days",
    defaultFrequencyForMonth: 30,
    defaultFrequencyForYear: 12,
  },
  {
    id: 2,
    name: "weekly",
    display: "Weeks",
    defaultFrequencyForMonth: 4,
    defaultFrequencyForYear: 12,
  },
  {
    id: 3,
    name: "monthly",
    display: "Months",
    defaultFrequencyForMonth: 1,
    defaultFrequencyForYear: 12,
  },
  {
    id: 4,
    name: "quarterly",
    display: "Quarters",
    defaultFrequencyForMonth: 1,
    defaultFrequencyForYear: 4,
  },
  {
    id: 5,
    name: "biannual",
    display: "Half Years",
    defaultFrequencyForMonth: 1,
    defaultFrequencyForYear: 2,
  },
  {
    id: 6,
    name: "annually",
    display: "Years",
    defaultFrequencyForMonth: 1,
    defaultFrequencyForYear: 1,
  },
];

export const liabilityFrequency = [
  {
    id: 1,
    name: "daily",
    display: "Days",
    defaultFrequencyForMonth: 1,
    defaultFrequencyForYear: 365,
  },
  ...incomeExpenseFrequency.filter(({ id }) => ![1, 2].includes(id)),
  {
    id: 2,
    name: "weekly",
    display: "Weeks",
    defaultFrequencyForMonth: 1,
    defaultFrequencyForYear: 52,
  },
]

export const updatedIncomeExpenseFrequency = [
  ...incomeExpenseFrequency,
  {
    id: 7,
    name: "Fortnight",
    display: "Fortnight",
    defaultFrequencyForMonth: 2,
    defaultFrequencyForYear: 12,
  },
];

export const ownershipList = [
  { id: 1, name: "Applicant" },
  { id: 2, name: "Spouse" },
  { id: 3, name: "Father" },
  { id: 4, name: "Mother" },
  { id: 5, name: "Brother" },
  { id: 6, name: "Sister" },
  { id: 7, name: "Child" },
];
export const jointBorrowerOwnershipList = [
  { id: 1, name: "Borrower" },
  { id: 2, name: "Spouse" },
  { id: 3, name: "Father" },
  { id: 4, name: "Mother" },
  { id: 5, name: "Brother" },
  { id: 6, name: "Sister" },
  { id: 7, name: "Child" },
];
export const securityDataOwnershipList = [
  { id: 1, name: "Guarantor" },
  { id: 2, name: "Spouse" },
  { id: 3, name: "Father" },
  { id: 4, name: "Mother" },
  { id: 5, name: "Brother" },
  { id: 6, name: "Sister" },
  { id: 7, name: "Child" },
];

export const summaryCalculationSettings = {
  addExpenses: 1,
  addLiability: 1,
  addJB: 0,
  addIncome: 1,
};

export const villageLeaderList = [
  { id: 1, name: "Village Leader 1" },
  { id: 2, name: "Village Leader 2" },
  { id: 3, name: "Village Leader 3" },
  { id: 4, name: "Village Leader 4" },
  { id: 5, name: "Village Leader 5" },
  { id: 6, name: "Village Leader 6" },
  { id: 7, name: "Village Leader 7" },
];

export const loanRequirementOptions = [
  { id: 1, name: "Investment" },
  { id: 2, name: "Working Capital" },
];

export const frequencyForMonthly = [1, 2];
export const frequencyForYearly = [1, 2, 3, 4, 5];

export const getDefaultFrequencies = (id: number) => {
  let annualDefaultVal = 0;
  let monthlyDefaultVal = 0;

  incomeExpenseFrequency?.forEach((item) => {
    if (item.id === id) {
      annualDefaultVal = item.defaultFrequencyForYear;
      monthlyDefaultVal = item.defaultFrequencyForMonth;
    }
  });
  updatedIncomeExpenseFrequency?.forEach((item) => {
    if (item.id === id) {
      annualDefaultVal = item.defaultFrequencyForYear;
      monthlyDefaultVal = item.defaultFrequencyForMonth;
    }
  });

  return { annualDefaultVal, monthlyDefaultVal };
};

export const calculateAnnualValue = (val: number, monthFrequency: number, yearFrequency: number) => {
  let value = 0;
  const m = !monthFrequency ? 1 : monthFrequency;
  const y = !yearFrequency ? 1 : yearFrequency;

  if (m && y && val) {
    value = val * parseInt(m.toString()) * parseInt(y.toString());
  }
  return value;
};

export const calculateTotalAnnualValue = (array: any[]) => {
  let total = 0;
  array.forEach((expense: { expenseAmount: any; frequencyForMonth: any; frequencyForAnnual: any }) => {
    let totalItem = calculateAnnualValue(expense.expenseAmount, expense.frequencyForMonth, expense.frequencyForAnnual);
    total += totalItem;
  });

  return total;
};

export const getGrossIncome = (salary: string, allowances: string, fixedAllowances: string) => {
  const s = salary ? parseFloat(salary) : 0;
  const a = allowances ? parseFloat(allowances) : 0;
  const f = fixedAllowances ? parseFloat(fixedAllowances) : 0;

  return s + a + f;
};

export const getNetIncome = (salary: any, allowances: any, fixedAllowances: any, deduction: string) => {
  const g = getGrossIncome(salary, allowances, fixedAllowances);
  const d = deduction ? parseFloat(deduction) : 0;

  return g - d;
};

export const getAnnualIncome = (salary: any, allowances: any, fixedAllowances: any, deduction: string, monthFrequency: any, yearFrequency: any) => {
  const g = getGrossIncome(salary, allowances, fixedAllowances);
  const d = deduction ? parseFloat(deduction) : 0;
  const m = !monthFrequency ? 1 : monthFrequency;
  const y = !yearFrequency ? 1 : yearFrequency;

  return (g - d) * m * y;
};

export const getMonthlyIncome = (salary: any, allowances: any, fixedAllowances: any, deduction: string, monthFrequency: any) => {
  const g = getGrossIncome(salary, allowances, fixedAllowances);
  const d = deduction ? parseFloat(deduction) : 0;
  const m = !monthFrequency ? 1 : monthFrequency;

  return (g - d) * m;
};

export const getFrequencyText = (id: number) => {
  const selectFreq = incomeExpenseFrequency.find((f) => f.id === id);

  return selectFreq?.display;
};

export const getFrequencyTexts = (id: number) => {
  const selectFreq = updatedIncomeExpenseFrequency.find((f) => f.id === id);

  return selectFreq?.display;
};

export const isDataValuesExists = (dataArray: string | any[]) => {
  const arrayFirstObject = dataArray.length > 0 ? dataArray[0] : {};
  const dataExists = !!(arrayFirstObject && arrayFirstObject["entryId"]) || arrayFirstObject["entryId"] === 0;
  return dataExists;
};

export const isArrayIncludeData = (dataArray: any | State<any[]>) => {
  try {
    dataArray = dataArray?.attach(Downgraded).get() || []
  } catch (error) { }

  return LengthItemValidate.validatedArray(dataArray).length && Object.keys(LengthItemValidate.validatedArray(dataArray)[0] || {}).length
}

export const loanLeaseAnnualAmount = (noOfInstallmentsLeft: number, installmentAmount: string | number) => {
  let annualAmount = 0;
  const y = noOfInstallmentsLeft ? noOfInstallmentsLeft : 0;
  const i = installmentAmount ? parseFloat(installmentAmount.toString()) : 0;

  if (y > 0) {
    if (y <= 12) {
      annualAmount = y * i;
    } else if (y > 12) {
      annualAmount = 12 * i;
    }
  }

  return annualAmount;
};

export const loanLeaseAnnualAmountRevaamp = (noOfInstallmentsLeft: number, installmentAmount: string | number, installmentFrequency: string | number) => {
  let annualAmount = 0;
  const y = noOfInstallmentsLeft ? noOfInstallmentsLeft : 0;
  const i = installmentAmount ? parseFloat(installmentAmount.toString()) : 0;
  const { defaultFrequencyForMonth = 0, defaultFrequencyForYear = 0 } = liabilityFrequency.find(({ id }) => (id.toString() === installmentFrequency.toString())) || {}
  const loanFrequency = Number(defaultFrequencyForMonth) * Number(defaultFrequencyForYear);

  if (y > 0) {
    if (y <= loanFrequency) {
      annualAmount = y * i;
    } else if (y > loanFrequency) {
      annualAmount = loanFrequency * i;
    }
  }

  return annualAmount;
};

export const creditCardAnnualAmount = (limit: string) => {
  let annualAmount = 0;
  const y = limit ? parseFloat(limit) : 0;
  if (y > 0) {
    annualAmount = (5 * y) / 100;
  }
  return annualAmount;
};

export const pawningAnnualAmount = (outstanding: string | number, interest: string | number) => {
  let annualAmount = 0;
  const y = outstanding ? parseFloat(outstanding.toString()) : 0;
  const i = interest ? parseFloat(interest.toString()) : 0;
  const outstadingPercentageValue = (y * i) / 100;

  annualAmount = y + outstadingPercentageValue;
  return annualAmount;
};

export const overDraftAnnualAmount = (outstanding: any) => {
  return outstanding;
};

export const formatValues = (data: { [x: string]: any; applicationId?: string; applicationRef?: string; employeeId?: string; workflowStage?: string; province?: string; district?: string; branch?: string; zone?: string; type?: string; sector?: string; scheme?: string; name?: string; nic?: string; mobile?: string; parentStage?: string; cif?: string; accountNumber?: string; revertStatus?: string; stage?: string; applicantType?: string; status?: string; assignedUser?: string; actionedUser?: string; approvedBy?: string; fromDate?: string; toDate?: string; cid?: string; workflowStatus?: string; ccuOfficer?: string; ccuManager?: string }) => {
  const processedData = {};
  Object.keys(data).forEach((key) => {
    if (data[key] && data[key] !== "undefined" && data[key] !== "null") {
      processedData[key] = data[key];
    }
  });
  return processedData;
};

export const standardDateFormat = "DD/MM/YYYY";

export const standardDateFormatter = (date: moment.MomentInput) => {
  return moment(date).format(standardDateFormat);
};

export const postalCodeId = (event: { name: string }) => {
  let str = event?.name?.split(" - ");
  return str[0];
};

export const totalMonthlyIncomeData = (incomeData: any[]) => {
  let total = 0;
  incomeData.forEach((personnel: { basicSalary: any; averageVariableAllowance: any; fixedAllowance: any; lastThreeAverageDeduction: any; frequencyForMonth: any }) => {
    const totalItem = getMonthlyIncome(personnel?.basicSalary, personnel?.averageVariableAllowance, personnel?.fixedAllowance, personnel?.lastThreeAverageDeduction, personnel?.frequencyForMonth);
    total += totalItem;
  });
  return total;
};

export const numberToWords = (amount: number): string => {
  const ones = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];

  const tens = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];

  const numToWords = (num: number): string => {
    if (num === 0) {
      return "zero";
    }
    if (num < 0) {
      return "minus " + numToWords(Math.abs(num));
    }
    let words = "";
    if (num >= 1000000) {
      words += numToWords(Math.floor(num / 1000000)) + " million ";
      num %= 1000000;
    }
    if (num >= 1000) {
      words += numToWords(Math.floor(num / 1000)) + " thousand ";
      num %= 1000;
    }
    if (num >= 100) {
      words += numToWords(Math.floor(num / 100)) + " hundred ";
      num %= 100;
    }
    if (num >= 20) {
      words += tens[Math.floor(num / 10)] + " ";
      num %= 10;
    }
    if (num > 0) {
      words += ones[num] + " ";
    }
    return words.trim();
  };

  const rupees = Math.floor(amount);
  const cents = Math.round((amount - rupees) * 100);
  const rupeesInWords = numToWords(rupees);
  const centsInWords = numToWords(cents);

  if (cents === 0) {
    return rupeesInWords + " rupees";
  } else if (rupees === 0) {
    return centsInWords + " cents";
  } else {
    return rupeesInWords + " rupees and " + centsInWords + " cents";
  }
};

const numbersToWords = {
  0: "zero",
  1: "First",
  2: "Second",
  3: "Third",
  4: "Fourth",
  5: "Fifth",
  6: "Sixth",
  7: "Seventh",
  8: "Eighth",
  9: "Nineth",
  10: "Tenth",
};

export const convertNumberToWords = (number: string) => {
  if (number in numbersToWords) return numbersToWords[number];
};

export const typeOfCreditCards = [
  {
    id: 1,
    name: "Visa",
  },
  {
    id: 2,
    name: "Master Card",
  },
  {
    id: 3,
    name: "JCB",
  },
  {
    id: 4,
    name: "American Express",
  },
  {
    id: 5,
    name: "Discover",
  },
  {
    id: 6,
    name: "Union Pay",
  },
];

export const calculateTotalAnnualLiability = (obligationDetails: { creditCard: any[]; loanLease: any[]; pawning: any[]; overdraft: any[] }) => {
  let total = 0.0;
  let totalCreditAmount = 0.0;
  let totalLoeanLeaseAmount = 0.0;
  let totalPawingAmount = 0.0;
  let annualOverdraft = 0.0;

  // Annual Credit
  if (obligationDetails.creditCard.length < 0) {
    return null;
  } else {
    obligationDetails.creditCard.forEach((element: { limit: any }) => {
      totalCreditAmount += creditCardAnnualAmount(element.limit);
    });
  }

  // Annual Loan Lease
  if (obligationDetails.loanLease.length < 0) {
    return null;
  } else {
    obligationDetails.loanLease.forEach((element: { installmentLeft: any; installmentAmount: any, installmentFrequency: string | number }) => {
      totalLoeanLeaseAmount += loanLeaseAnnualAmountRevaamp(element.installmentLeft, element.installmentAmount, element.installmentFrequency);
    });
  }

  // Annual Pawing
  if (obligationDetails.pawning.length < 0) {
    return null;
  } else {
    obligationDetails.pawning.forEach((element: { outstanding: any; annualInterest: any }) => {
      totalPawingAmount += pawningAnnualAmount(element.outstanding, element.annualInterest);
    });
  }

  // Annual Overdraft
  if (obligationDetails.overdraft.length < 0) {
    return null;
  } else {
    obligationDetails.overdraft.forEach((element: { outstanding: any }) => {
      annualOverdraft += overDraftAnnualAmount(element.outstanding);
    });
  }

  total = +totalCreditAmount + +totalLoeanLeaseAmount + +totalPawingAmount + +annualOverdraft;
  return total;
};

export const apiJOBTranslator = {
  documentMandatoryValidation: "SYSTEM: All Mandatory Documents Upload Validation",
  preDisbursementCheckListValidate: "SYSTEM: Pre-Disbursement Check List Validation",
  securityCheckListValidate: "SYSTEM: Security Check List Validation",
  dataVerificationValidate: "SYSTEM: Data Verification List Validation",
  checkListValidate: "SYSTEM: Documents Check List Validation",
  documentApprovalValidation: "SYSTEM: All Mandatory/Non-Mandatory Documents Approval Validation",
  recoveryTrailCalculatorValidate: "SYSTEM: Recovery Account Selection and Trial Calculator Invoker Validation",
};

export const convertDisbursementCount = {
  0: "zero",
  1: "First Disbursement",
  2: "Second Disbursement",
  3: "Third Disbursement",
  4: "Fourth Disbursement",
  5: "Fifth Disbursement",
  6: "Sixth Disbursement",
  7: "Seventh Disbursement",
  8: "Eighth Disbursement",
  9: "Nineth Disbursement",
  10: "Tenth Disbursement",
};

export class DateDiff {
  time1: number;
  time2: number;
  date1: Date;
  date2: Date;

  constructor(d1: Date, d2: Date) {
    this.time1 = d1.getTime();
    this.time2 = d2.getTime();
    this.date1 = d1;
    this.date2 = d2;
  }

  get minutes() {
    return Math.floor((this.time2 - this.time1) / (60 * 1000));
  }

  get hours() {
    return Math.floor((this.time2 - this.time1) / (3600 * 1000));
  }

  get days() {
    return Math.floor((this.time2 - this.time1) / (24 * 3600 * 1000));
  }

  get weeks() {
    return parseInt(((this.time2 - this.time1) / (24 * 3600 * 1000 * 7)).toString());
  }

  get months() {
    var d1Y = this.date1.getFullYear();
    var d2Y = this.date2.getFullYear();
    var d1M = this.date1.getMonth();
    var d2M = this.date2.getMonth();

    return d2M + 12 * d2Y - (d1M + 12 * d1Y);
  }

  get years() {
    return this.date2.getFullYear() - this.date1.getFullYear();
  }
}

export const printFormTemplate = async (fn, file) => {
  try {
    const data = await fn(file, true);
    const strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
    window.open(data, "_blank", strWindowFeatures);
  } catch (error: any) {
    throw error;
  }
};

export function camelCaseToWords(s: string) {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export const extractDate = function (date) {
  let _date = date.get() === "" || date.get() == "Invalid Date" ? null : date.get();

  try {
    return _date;
  } catch (error) {
    return null;
  }
};

export const validateTypedDateWithFutureDateEnabled = (date, field = "", prefix = "") => {
  // Below List defined due to the format recieved as MM/DD/YYY, Eg: Picker: 09/05/2024 =====> Function Param: 05/09/2024
  const dateFormatIgnoreList: any = ["previous_futureDate_startDate", "previous_futureDate_endDate"];

  let isError = false;
  if (date != null) {
    let formattedField = `${prefix}_${field}`;

    let formattedDate: any = "";

    if (!dateFormatIgnoreList.includes(formattedField)) {
      let dateArr = date.toString().split("/");
      formattedDate = `${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`; // MM/DD/YYYY
    } else {
      formattedDate = date;
    }

    const inputDate = new Date(formattedDate).valueOf();
    const currentDate = new Date().valueOf();

    if (inputDate > currentDate) {
      isError = true;
    }
  }

  return isError;
};

/**
 * Below List defined due to the format recieved as MM/DD/YYY, Eg: Picker: 09/05/2024 =====> Function Param recieved as: 05/09/2024
 */
export const validateTypedDateWithPastDateEnabled = (date, field = "", prefix = "") => {
  const dateFormatIgnoreList: any = [];
  let isError = false;
  if (date != null) {
    let formattedField = `${prefix}_${field}`;
    let formattedDate: any = "";

    if (!dateFormatIgnoreList.includes(formattedField)) {
      let dateArr = date.toString().split("/");
      formattedDate = `${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`; // MM/DD/YYYY
    } else {
      formattedDate = date;
    }

    const inputDate = new Date(formattedDate).valueOf();
    const currentDate = new Date().valueOf();

    if (inputDate < currentDate) {
      isError = true;
    }
  }

  return isError;
};
export const formattedLoanTerm = (loanFrequncy: number, { min, max }) => {
  const label = "Loan Tenor";

  let maxLoanTenor: any = 0;
  let minLoanTenor: any = 0;

  if (loanFrequncy === 3) {
    minLoanTenor = min;
    maxLoanTenor = max;
  } else if (loanFrequncy === 1) {
    minLoanTenor = min * 30;
    maxLoanTenor = max * 30;
  } else if (loanFrequncy === 6) {
    minLoanTenor = min / 12;
    maxLoanTenor = max / 12;
  } else if (loanFrequncy === 2) {
    minLoanTenor = min * 4;
    maxLoanTenor = max * 4;
  } else if (loanFrequncy === 4) {
    minLoanTenor = min / 3;
    maxLoanTenor = max / 3;
  } else if (loanFrequncy === 5) {
    minLoanTenor = min / 6;
    maxLoanTenor = max / 6;
  }

  return { label: `${label} (${getFrequencyText(loanFrequncy)}) [${Math.floor(minLoanTenor)} - ${Math.ceil(maxLoanTenor)}]`, minLoanTenor, maxLoanTenor: Math.ceil(maxLoanTenor) };
};

export function generateUniqueId() {
  return (
    Date.now().toString(36) + Math.random().toString(36).substring(2, 15)
  );
}


export function isJSON(str) {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

export const trimWithDelimiter = (str: string, delimiter = ",") => {
  const parts = str.split(delimiter);

  const trimmedParts = parts.map(part => part.trim());

  const trimmedString = trimmedParts.filter(item => item).join(`${delimiter} `);

  return trimmedString;
};

export const getCopy = (object) => {
  try {
    object = JSON.parse(JSON.stringify(object));
  } catch (error) {
    object = {}
  }

  return object;
}

export const getLowerCaseString = (dataString: string) => {
  return dataString.toLowerCase().replace(/\s+/g, '').replace(/-/g, '').trim();
}

export const inspectionDeductionsTypes = [
  {
    id: 1,
    name: "Sales Returns",
  },
  {
    id: 2,
    name: "Sales Discounts",
  },
  {
    id: 3,
    name: "Cost of Goods Sold",
  },
  {
    id: 4,
    name: "Direct Cost",
  },
];

export const jointBorrowerIncomeHolder = [
  { id: 1, name: "Borrower" },
  { id: 2, name: "Father" },
  { id: 3, name: "Mother" },
  { id: 4, name: "Spouse" },
  { id: 5, name: "Brother" },
  { id: 6, name: "Sister" },
  { id: 7, name: "Child" },
];
export const securityDataIncomeHolder = [
  { id: 1, name: "Guarantor" },
  { id: 2, name: "Father" },
  { id: 3, name: "Mother" },
  { id: 4, name: "Spouse" },
  { id: 5, name: "Brother" },
  { id: 6, name: "Sister" },
  { id: 7, name: "Child" },
];


export const convertFormStructure = (formStructure: FORMSTRUCTURE) => {
  if (!formStructure || Object.keys(formStructure).length === 0) return formStructure;

  return Object.entries(formStructure).reduce((acc, [key, struture]: [string, any[]]) => {

    let obj = {};

    struture.forEach((item) => {
      obj[item.key] = item
    })

    acc[key] = obj;

    return acc;
  }, {});
}
