import { CircularProgress } from "@mui/material";
import React from "react";
import { FormMasterBuilder } from "../FormMasterBuilder";
import { searchAndVerifyWidget } from "./SearchAndVerifyWidget";

export const manipulateAndDestrutureCompositeComponents = ({ metaData, alphaChildren, value, onChange, otherParams }) => {
  if (alphaChildren.length > 0) {
    return <React.Suspense fallback={<CircularProgress />}>{alphaChildren.map((child: any) => React.createElement(FormMasterBuilder, { onChange, formId: child?.dataKey, applicationDataObject: value, otherParams }))}</React.Suspense>;
  }
  return <React.Suspense fallback={<CircularProgress />}>{React.createElement(searchAndVerifyWidget(metaData?.subtype), { metaData, alphaChildren, value, onChange, otherParams })}</React.Suspense>;
};
