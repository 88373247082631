import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Grid, Stack, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import {
  DocumentSectionComponentPropsTypes,
} from "../../../configs/interfaces";
import { documentKeys } from "../../../configs/revokeDocumentKeys";
import { panelExpandIconBox } from "../../../styles";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import { NavigationButtons } from "../NavigationButtons/NavigationButtons";

export const InspectionUploadSectionWrapper: FC<DocumentSectionComponentPropsTypes> = ({
  title,
  children,
  onBack,
  onNext,
  noNext,
  noBack,
  keyCode,
  loading,
  onExpand,
  noBtn,
  subTxt,
  numOfUploadedFiles,
  numOfMandatory,
  numOfUploadedMandatoryFiles,
  documentComponentId,
  personalData,
  creditFileData,
  onSync,
  sectionLoading,
  disableAccordingToTab = false,
  isLoggedInUserNotTheAssignee = false,
  documentTypes,
  documentTypeId,
  onClickCreate,
}) => {
  const [Visibility, setVisibility] = useState(false);
  const [syncButton, setSyncButton] = useState(false);
  const [BtnLoading, setBtnLoading] = useState(false);

  const handleExpand = () => {
    onExpand && onExpand();
    setVisibility(true);
  };

  useEffect(() => {
    if (documentComponentId === "joint_borrower_documents") {
      if (personalData?.individualId === 0) {
        setSyncButton(true);
      } else {
        setSyncButton(false);
      }
    } else {
      setSyncButton(false);
    }
    setBtnLoading(sectionLoading);
  }, [sectionLoading]);

  useEffect(() => {
    if (documentTypes?.length > 0) {
      let setRevokePermissions = false;
      documentTypes.map((item: any) => {
        setRevokePermissions = documentKeys.includes(item?.documentKey) === true ? true : false;
      });
    }
  }, [documentTypes]);

  const onSubmitSync = (e) => {
    onSync && onSync(e);
    setSyncButton(sectionLoading);
  };

  const onClick = async () => { };

  return (
    <div
      className={`${Visibility ? "template__panel--show" : "template__panel--hide"}`}
      key={keyCode}>
      <Box display="flex" alignItems="center">
        <Grid container columns={12}>
          <Grid container item xs={0.5} sm={0.5} direction="column" justifyContent="center">
            {numOfUploadedMandatoryFiles === numOfMandatory ? (
              <CheckCircleIcon sx={{ color: "#00AB71" }} />
            ) : (
              <ErrorIcon sx={{ color: "#ffdd00" }} />
            )}
          </Grid>
          <Grid container item xs={3} sm={3} direction="column" justifyContent="center">
            <Box>
              <Box className="mainSection__heading--bold">{title}</Box>

              <Box className="mainSection__description">
                <small>{subTxt ? subTxt : "sample dummy text for testing"}</small>
              </Box>
            </Box>
          </Grid>
          <Grid container item xs={5.5} sm={5.5} direction="column" justifyContent="center">
            <Box>
              <Box
                className={`document-upload-wrapper-text ${numOfUploadedFiles === numOfUploadedFiles ? "green-font" : "yellow-font"
                  }`}
                sx={{ textAlign: "right" }}>
                <small className="basic-font opacity-7 font-size-14">
                  Total: {numOfUploadedFiles} / {numOfUploadedFiles}
                </small>
              </Box>
            </Box>
          </Grid>
          <Grid container item xs={1.5} sm={1.5} direction="column" justifyContent="center">
            <Box>
              <Box
                className={`document-upload-wrapper-text ${numOfUploadedMandatoryFiles === numOfMandatory ? "green-font" : "red-font"
                  }`}
                sx={{ textAlign: "right" }}>
                <small className="basic-font opacity-7 font-size-14">
                  Mandatory: 0 / 0
                </small>
              </Box>
            </Box>
          </Grid>
          <Grid container item xs={1.5} sm={1.5} direction="column" justifyContent="center">
            <Box sx={panelExpandIconBox}>
              {syncButton ? (
                <ButtonComponent
                  startIcon={<RestartAltIcon />}
                  onClick={onClick}
                  title={"Sync"}
                  variant="contained"
                  disabled={BtnLoading || disableAccordingToTab || isLoggedInUserNotTheAssignee}
                  loadingbtn={true}
                  loading={BtnLoading}
                />
              ) : Visibility ? (
                <Tooltip title="Collapse section">
                  <KeyboardDoubleArrowUpIcon
                    className="basic-font-color"
                    onClick={() => setVisibility(false)}
                    fontSize="medium"
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Expand section">
                  <KeyboardDoubleArrowDownIcon
                    className="basic-font-color"
                    onClick={handleExpand}
                    fontSize="medium"
                  />
                </Tooltip>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* //Section */}
      <div className="mainSection_content--background">{children}</div>

      {/* //Navigation Buttons */}
      {!noBtn && (
        <Stack mt={2} direction="row-reverse" sx={{ justifyContent: 'space-between' }}>
          {/* No back button appear */}
          {noBack && (
            <Box sx={{ justifyContent: 'flex-end' }}>
              <NavigationButtons
                onNext={onNext}
                noBack
                loading={loading}
                disabled={disableAccordingToTab}
              />
            </Box>
          )}
          {documentTypeId === 8 ?
            <Box>
              <ButtonComponent disabled={false} variant="contained" loadingbtn={false} loading={false} onClick={onClickCreate} title={"Add Documents"} />
            </Box>
            : <></>
          }
        </Stack>
      )}
    </div>
  );
};
