import { useState } from "@hookstate/core";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Grid, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { standardDateFormatter } from "los-util";
import React, { FC, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { formatCurrency } from "../../../../services/currencyFormater";
import { formattedDate } from "../../../../utility/fomatters";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { DatePickerComponent } from "../../../InputComponents/DatePickerComponent/DatePickerComponent";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { BuildHeader } from "./Controller";

interface ScheduleDisbursementsPropTypes {
    disbursementLoanAmount: any;
    applicationId: any;
    disbursementPreviousData: any;
    saveCoreBankData: any;
}

const ScheduleDisbursements: FC<ScheduleDisbursementsPropTypes> = ({ disbursementLoanAmount, applicationId, disbursementPreviousData, saveCoreBankData }) => {
    const isEditing: any = useState(false);
    let display: any = true;
    const [selectedDate, setSelectedDate]: any = React.useState(null);

    function TableHeaderCell(props) {
        return (
            <TableCell align={props.align} width={props.width}>
                <Typography className="border-radius-5 basic-font basic-font-color-bold font-size-16">{props.value}</Typography>
            </TableCell>
        );
    }

    useEffect(() => {
        setSelectedDate(selectedDate);
    }, [disbursementPreviousData]);


    const status = (props) => {
        let status = {}
        if (props == 'Complete') {
            status = ("#aee7a144");
        } else if (props == 'Pending') {
            status = ("#ffb26644");
        } else {
            status = ("#66c4ff44");
        }
        return status;
    }


    function TableDataCell(props) {
        return (
            <TableCell align={props.align} width={props.width}>
                <Typography variant="subtitle2">{props.value}</Typography>
            </TableCell>
        );
    }

    const BuildDisbursement = () => {
        const calculatePercentage = (index, value) => {
            const currentValue = value;
            let percentage;

            percentage = (currentValue / disbursementLoanAmount) * 100;
            disbursementPreviousData[index].percentage = percentage;

            return percentage;
        };

        const remainingDisbursementLoanAmount = (currentAmount, index) => {

            disbursementPreviousData[index + 1].disbursementAmount = currentAmount?.floatValue;

            let cumulativeAmounts = Object.keys(disbursementPreviousData)
                .reduce((total, key) => {
                    const item = disbursementPreviousData[key];
                    return total + parseFloat(item?.disbursementAmount == '' ? 0 : item?.disbursementAmount);
                }, 0.0);

            if (index === 0) {
                cumulativeAmounts = parseFloat(currentAmount.value);
            }

            if (cumulativeAmounts > parseFloat(disbursementLoanAmount)) {
                return false;
            }

            return true;
        };

        return (
            <Grid padding={'5px'}>
                <TableBody>
                    {Object.keys(disbursementPreviousData)?.map((key: any, index) => (
                        <TableRow key={index} style={{ backgroundColor: `${status(disbursementPreviousData[key]?.status)}` }}>
                            <TableDataCell value={key} width="10%" />
                            <TableDataCell value={
                                <NumericFormat
                                    label={undefined}
                                    customInput={TextBoxComponent}
                                    variant="outlined"
                                    required
                                    thousandSeparator={true}
                                    value={formatCurrency(disbursementPreviousData[key]?.disbursementAmount)}
                                    decimalScale={2}
                                    onValueChange={(e: any) => {
                                        const { value } = e;
                                        const obj: any = disbursementPreviousData[key];
                                        obj.disbursementAmount = value;
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">LKR</InputAdornment>,
                                    }}
                                    isAllowed={(values) => {
                                        return remainingDisbursementLoanAmount(values, index);
                                    }}
                                    style={{ height: "23px !important" }}
                                    disabled={disbursementPreviousData[key]?.status === 'Complete'}
                                />
                            } width="10%" />
                            <TableDataCell value={
                                <NumericFormat
                                    customInput={TextBoxComponent}
                                    style={{ width: "160px" }}
                                    variant="outlined"
                                    required
                                    thousandSeparator={true}
                                    value={calculatePercentage(key, disbursementPreviousData[key]?.disbursementAmount)}
                                    decimalScale={2}
                                    onValueChange={(e: any) => {
                                        const { value } = e;
                                        const obj: any = disbursementPreviousData[key];
                                        obj.percentage = value;
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    disabled={true}
                                />} width="10%" />
                            <TableDataCell value={disbursementPreviousData[key]?.status} width="10%" />
                            <TableDataCell value={<DatePickerComponent label={'Disbursement Date'}
                                value={formattedDate(disbursementPreviousData[key]?.date)}
                                onChange={(date) => {
                                    const obj: any = disbursementPreviousData[key];
                                    obj.date = standardDateFormatter(date);
                                    setSelectedDate(standardDateFormatter(date));
                                }}
                                enableFuture={true} disablePast={true} />} width="10%" />
                        </TableRow>
                    ))}
                </TableBody>
            </Grid>
        )
    };

    return (
        <Grid>
            {display ?
                <div>
                    <Grid display={"flex"} justifyContent={"space-between"}>
                        <BuildHeader label={"Schedule Disbursements"}></BuildHeader>
                        {isEditing?.get() ? <ButtonComponent startIcon={<SaveIcon />} title={'Save'} variant="contained" onClick={() => saveCoreBankData()} sx={{ alignSelf: "center" }} />
                            : <ButtonComponent startIcon={<EditIcon />} title={'Edit'} variant="contained" onClick={() => { isEditing.set(true) }} sx={{ alignSelf: "center" }} />
                        }
                    </Grid>
                    <Grid container padding={"20px"} className="data-box">
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableHeaderCell value="Number" width="10%" />
                                        <TableHeaderCell value="Disbursement Amount" width="10%" />
                                        <TableHeaderCell value="Percentage" width="10%" />
                                        <TableHeaderCell value="Status" width="10%" />
                                        <TableHeaderCell value="Date" width="10%" />
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <BuildDisbursement ></BuildDisbursement>
                </div>
                : <></>}
        </Grid>
    )
}

export default ScheduleDisbursements;