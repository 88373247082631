import { useState } from "@hookstate/core";
import SyncIcon from "@mui/icons-material/Sync";
import { IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { getSCVClientDetails } from "../../../../../services/bankServiceInvorker";
import { loadCustomerData } from "../../../../../utility/helpers/creditFileUtility";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { RadioButtonComponent } from "../../../../InputComponents/RadioButtonComponent/RadioButtonComponent";
import { SelectComponent } from "../../../../InputComponents/SelectComponent/SelectComponent";
import { toast } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

const LoanRelatedNIC = ({ applicationId, data, setReferAccountList, accountsLoader, actions }) => {
  const loaders = useState({ loading: true, cbsFetch: false });

  const [details, setDetails]: any = React.useState({
    nics: [],
    cifs: [],
    savingAccounts: []
  });

  const [selections, setSelections]: any = React.useState({
    nic: "",
    cif: "",
    searchKey: "nic"
  });

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (actions.get()) {
      loadCIFList(true);
      actions.set(false);
    }
  }, [actions]);

  const onChangeHandler = (nic) => {
    setSelections({ ...selections, nic: nic });
  };

  useEffect(() => {
    if (selections.nic && selections.searchKey.toLowerCase() === "cif") {
      loadCIFList(false, "NIC");
    }
  }, [selections.nic]);

  const loadCIFList = async (force = false, searchKey = "") => {
    try {
      const key = searchKey || selections.searchKey;

      if (key.toLowerCase() === "nic" && !selections.nic) {
        toast({ status: "error", message: "Please select NIC to proceed." });
        return;
      }

      if (key.toLowerCase() === "cif" && !selections.cif) {
        toast({ status: "error", message: "Please select CIF to proceed." });
        return;
      }

      const searchValue = key.toLowerCase() === "nic" ? [selections.nic] : [selections.cif];

      loaders.cbsFetch.set(true);
      accountsLoader.set(true);
      const res = await getSCVClientDetails(searchValue, force, applicationId, key.toUpperCase());

      const { scvDetails: { savings }, clientDetails } = res;

      const savingAccounts: any = [];
      for (const saving of (savings || [])) {
        const { productName = "", accountNumber = "" } = saving;
        savingAccounts.push({
          id: accountNumber,
          name: `${accountNumber} (${productName})`
        });
      }

      if (key.toLowerCase() === "nic") {
        const listCIF: any = [];
        const listCIFIds: any = [];
        const { IndividualProfiles = [], JoinedProfiles = [] } = clientDetails;
        let _details = [...IndividualProfiles, ...JoinedProfiles].filter(item => item);
        for (const clientDetail of (_details || [])) {
          const { CIF: cif = "", initialInFull = "", customerType } = clientDetail;
          if (listCIFIds.includes(cif)) {
            continue;
          }
          listCIF.push({
            id: cif,
            name: `${cif} - (${customerType} - ${initialInFull.replaceAll("  ", " ")})`
          });
          listCIFIds.push(cif);
        }
        setDetails({ ...details, cifs: listCIF, savingAccounts: savingAccounts });
      } else {
        setDetails({ ...details, savingAccounts: savingAccounts });
      }

      setReferAccountList(savingAccounts);

      loaders.cbsFetch.set(false);
      accountsLoader.set(false);
    } catch (error) {
      console.log(error);

    }
  }

  const loadData = async () => {
    const list: any = loadCustomerData(data);
    setDetails({ ...details, nics: list });
    loaders.loading.set(false);
  };

  const forceReloadAccountsByNIC = () => {
    loadCIFList(true, "NIC");
  }

  if (loaders.loading?.get()) {
    return <Stack justifyContent={"center"} alignItems={"center"}><CircularProgressComponent size={25} /></Stack>;
  }

  return <Stack px={2} py={1} sx={{ background: "white" }} direction={{ md: "column", lg: "row" }} spacing={4}>
    <Stack spacing={2} className="data-box" p={1}>
      <Stack direction={"row"} alignItems={"center"}>
        <RadioButtonComponent checked={selections.searchKey === "nic"} value={"nic"} name={"nic"} onChange={() => { setSelections({ ...selections, searchKey: "nic" }) }} />
        <Typography variant="subtitle2" color={"gray"}>Borrower NIC(s)</Typography>
      </Stack>
      <Stack direction={"row"} alignItems={"center"}>
        <RadioButtonComponent checked={selections.searchKey === "cif"} value={"cif"} name={"cif"} onChange={() => setSelections({ ...selections, searchKey: "cif" })} />
        <Typography variant="subtitle2" color={"gray"}>CIF(s)</Typography>
      </Stack>
    </Stack>

    <Stack width={"5pt"}></Stack>

    <Stack spacing={2} className="data-box" p={1}>
      <Stack direction={"row"} width={"380pt"} spacing={1}>
        <SelectComponent values={details.nics} value={selections.nic} onChange={(e) => {
          onChangeHandler(e.target.value);
        }}></SelectComponent>
        <Stack direction={"row"} alignItems={"center"}>
          <IconButton
            size="small"
            color="primary"
            children={<SyncIcon />}
            onClick={() => {
              forceReloadAccountsByNIC();
            }}
          />
        </Stack>
      </Stack>
      {selections.searchKey.toLowerCase() === "cif" && <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <SelectComponent values={details.cifs} value={selections.cif} onChange={(e) => { setSelections({ ...selections, cif: e.target.value }) }}></SelectComponent>
        {<Stack><ButtonComponent
          style={{ width: "80pt" }}
          color="primary"
          title={"FIND CIF"}
          variant={"outlined"}
          size={"small"}
          onClick={() => {
            loadCIFList(false, "NIC");
          }}
        /></Stack>}
      </Stack>}
    </Stack>

    <Stack width={"5pt"}></Stack>

    <Stack justifyContent={"flex-end"} p={1}>
      <ButtonComponent variant={"outlined"} title={`Search by ${selections.searchKey.toUpperCase()}`} onClick={async () => {
        await loadCIFList();
      }}></ButtonComponent>
    </Stack>
  </Stack>;
};

export default LoanRelatedNIC;
