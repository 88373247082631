import { Grid, Stack } from "@mui/material";

import { Downgraded, useState } from "@hookstate/core";
import ForwardIcon from "@mui/icons-material/Forward";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import OutputIcon from "@mui/icons-material/Output";
import PersonIcon from "@mui/icons-material/Person";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import React, { useEffect } from "react";
import { bufferToBlobUrl } from "../../../../../configs/base64Conversion";
import { userDetails, workflowMasterData } from "../../../../../configs/mainStore";
import {
  getCauOfficerAssignActions,
  getLegalList,
  getLegalRecordDocFile,
  getRiskList,
  getRiskRecordDocFile
} from "../../../../../services/creditFileApiCall";
import { dateFormatWithTimeChange } from "../../../../../utility/fomatters";
import { referAction, referStatus } from "../../../../../utility/util";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { IconRowComponent } from "../IconRowComponent";

export const previewBtnClicked = async (docId, referSectionType, referalGroupIDs) => {
  let resultFile;
  if (referSectionType === referalGroupIDs?.riskGroupId) {
    resultFile = await getRiskRecordDocFile(docId);
  } else if (referSectionType === referalGroupIDs?.legalGroupId) {
    resultFile = await getLegalRecordDocFile(docId);
  }

  if (resultFile?.fileData?.data) {
    const fileBuffer = resultFile?.fileData.data;
    const contentType = resultFile?.contentType;

    const blobUrl = bufferToBlobUrl(fileBuffer, contentType);

    window.open(
      `${blobUrl}`,
      "targetWindow",
      `toolbar=no,
         location=no,
         status=no,
         menubar=no,
         scrollbars=yes,
         resizable=yes,
         width=500,
         height=500`
    );
  }
};

const ReferItemRow = ({
  data,
  callBackOnActionEdit,
  list,
  applicationId,
  currentApplicationWfData,
  isOfficerGroup,
  referalGroupIDs,
}) => {
  const { wf_system_users }: any = useState(workflowMasterData);
  const userDetailsResponse = userDetails.attach(Downgraded).get();
  const { userId }: any = userDetailsResponse;

  const isExpand = useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const actionTime = data.actionTime ? dateFormatWithTimeChange(data.actionTime) : "";
  const actionStat = data?.action ? referAction[data?.action] : "";
  const color = actionStat?.color ? actionStat?.color : referAction["default"]?.color;
  const user = wf_system_users?.get()?.find((usr) => usr?.id == data?.assignee);
  const referStat = data?.referStatus ? referStatus[data?.referStatus] : "";
  const createdDate = data.createdDate ? dateFormatWithTimeChange(data.createdDate) : "";
  const createdBy = wf_system_users?.get()?.find((usr) => usr?.id == data?.createdBy);
  const isClosedRefer = data?.action === 3 ? !data?.action : !!data?.action;

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const res = await getCauOfficerAssignActions(data?.referId);
      let max_action_taken = 0;
      if (res?.length > 0) {
        for (let i = 0; i < res.length; i++) {
          const action_taken = res[i].actionTaken;
          if (action_taken > max_action_taken) {
            max_action_taken = action_taken;
          }
        }
      }
      setIsLoading(false);
    })();
  }, []);

  const onClickedAction = () => {
  };

  const checkActionAvailability = () => {
    const isLoggedInUserTheAssignee = userId === user?.id;
    let nestedState = true;

    const isPendingStatusKey: any = Object.keys(referStatus)?.find((key) => referStatus[key]?.key == "active");
    const pendingStatus = referStatus[isPendingStatusKey]?.id;

    const opinionPendingStatus = referStatus[isPendingStatusKey]?.id;

    list?.forEach((item, index) => {
      if (item?.referStatus == opinionPendingStatus && item?.createdBy == userId) {
        nestedState = true;
      } else if (item?.referStatus == pendingStatus && item?.createdBy == userId) {
        nestedState = false;
      }
    });

    return isLoggedInUserTheAssignee && nestedState;
  };

  const onExpand = () => {
    if (!isExpand.get()) {
      (async function () {
        setIsLoading(true);
        if (data?.referType === referalGroupIDs?.legalGroupId) {
          const legalListData = await getLegalList(applicationId);

          const itemLegalData: any = legalListData.find((obj) => {
            return obj?.id === data?.decisionId;
          });
        } else if (data?.referType === referalGroupIDs?.riskGroupId) {
          const riskListData = await getRiskList(applicationId);

          const itemRiskData: any = riskListData.find((obj) => {
            return obj?.id === data?.decisionId;
          });
        }
        setIsLoading(false);
      })();
    }
    isExpand.set(!isExpand.get());
  };

  return (
    <Grid
      container
      alignItems="center"
      className="row_list_item"
      p={2}
      key={data.sequence}
      style={{
        backgroundColor: `${color}3d`,
      }}
    >
      <Grid container className="full-width" columns={24} alignItems="center" spacing={2}>
        <Grid item xs={7}>
          <IconRowComponent Icon={RadioButtonCheckedIcon} subTxt="Referral Note" mainTxt={data.note} color={color} />
        </Grid>

        <Grid item xs={4}>
          <IconRowComponent
            Icon={RadioButtonCheckedIcon}
            subTxt="Referral Status"
            mainTxt={referStat?.name}
            color={color}
          />
        </Grid>
        <Grid item xs={4}>
          <IconRowComponent Icon={PersonIcon} subTxt="Assignee" mainTxt={user?.fullName} color={color} />
        </Grid>

        {isClosedRefer ? (
          <>
            <Grid item xs={3}>
              <IconRowComponent Icon={OutputIcon} subTxt="Action" mainTxt={actionStat?.name} color={color} />
            </Grid>

            <Grid item xs={5}>
              <IconRowComponent Icon={InsertInvitationIcon} subTxt="Action Time" mainTxt={actionTime} color={color} />
            </Grid>
          </>
        ) : (
          <Grid item xs={8} className="align-right">
            <ButtonComponent
              startIcon={<ForwardIcon />}
              variant="contained"
              onClick={onClickedAction}
              style={{}}
              title={"Action"}
              disabled={!checkActionAvailability()}
              loadingbtn={isLoading}
              loading={true}
            />
          </Grid>
        )}
        <Grid item xs={1} className="align-center">
          <ButtonComponent
            iconbtn
            onClick={onExpand}
          >
            {
              isExpand.get() ? (
                <KeyboardDoubleArrowUpIcon className="basic-font-color" />
              ) : (
                <KeyboardDoubleArrowDownIcon className="basic-font-color" />
              )
            }
          </ButtonComponent>
        </Grid>
      </Grid>

      {isExpand.get() && (
        <Grid container className="full-width row_list_item" columns={24} mt={1}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <div className={`basic-font basic-font-color opacity-7 font-size-14`}>Created By</div>
              <div className={`basic-font basic-font-color font-size-14`}>{createdBy?.fullName} </div>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <div className={`basic-font basic-font-color opacity-7 font-size-14`}>Created Date</div>
              <div className={`basic-font basic-font-color font-size-14`}>{createdDate} </div>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <div className={`basic-font basic-font-color opacity-7 font-size-14`}>Action Note</div>
              <div className={`basic-font basic-font-color font-size-14`}>
                {data.actionNote ? data.actionNote : "-"}{" "}
              </div>
            </Stack>
          </Grid>
          {data.oppinionNote && (
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <div className={`basic-font basic-font-color opacity-7 font-size-14`}>Opinion Note</div>
                <div className={`basic-font basic-font-color font-size-14`}>
                  {data.oppinionNote ? data.oppinionNote : "-"}{" "}
                </div>
              </Stack>
            </Grid>
          )}
          {data.riskTitle && (
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <div className={`basic-font basic-font-color opacity-7 font-size-14`}>Risk Title</div>
                <div className={`basic-font basic-font-color font-size-14`}>
                  {data.riskTitle ? data.riskTitle : "-"}{" "}
                </div>
              </Stack>
            </Grid>
          )}
          {data.riskDescription && (
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <div className={`basic-font basic-font-color opacity-7 font-size-14`}>Risk Description</div>
                <div className={`basic-font basic-font-color font-size-14`}>
                  {data.riskDescription ? data.riskDescription : "-"}{" "}
                </div>
              </Stack>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ReferItemRow;
