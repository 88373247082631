import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { Divider, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { useEffect, useState } from "react";

import { creditData } from "../../../../../configs/mainStore";
import { dateFormatMMDDYYYY, dateFormatWithDate, dateFormatWithTimeChange, timeFormatWithTimeChange } from "../../../../../utility/fomatters";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { openGlobalModal } from "../../../GlobalModal/GlobalModal";

import AddIcon from "@mui/icons-material/Add";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import ManageHistoryIcon from "@mui/icons-material/BookmarkRemove";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PersonIcon from "@mui/icons-material/Person";
import PreviewIcon from "@mui/icons-material/Preview";
import { MASTER_DATA_KEYS } from "../../../../../configs/constants/masterDataKeys";
import { getUserDetailsById } from "../../../../../services/userService";
import { cribStatusColors } from "../../../WorkflowComponents/utils";
import { IconRowComponent } from "../IconRowComponent";

import { privilegeKeys } from "../../../../../configs/constants/privilegeKeys";
import { getCribNote } from "../../../../../services/creditFileApiCall";
import { Access, userName } from "../../../../../utility/helpers/creditFileUtility";
import { getIsDisbursed } from "../../../../../utility/helpers/getIsDisbursed";
import { Toast } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

const CribItemRow = ({ title, subTitle, keyCode, user, uploadCribRecord, masterDataStore, participantEntryMap, cribList, appSettings, previewBtnClicked, disbursed, clientType, disabled = false, removeCribDocument = (a, b) => { } }) => {
  const [isExpand, setIsExpand] = useState(false);
  let onChangeNote;

  const extractAddress = () => {
    let address = "";
    address = [user?.address?.addressLine1 ?? "", user?.address?.addressLine2 ?? "", user?.address?.addressLine3 ?? "", user?.address?.addressLine4 ?? "", user?.address?.postalCode ?? ""].filter((obj) => obj).join(", ");
    return address;
  };

  let nics: any[] = [];
  let filteredNics: any[] = [];

  if (cribList[participantEntryMap[user?.participantId]]?.length > 0) {
    cribList[participantEntryMap[user?.participantId]].forEach((element) => {
      nics.push(element.cribKey);
    });
  }

  if (nics.length > 0) {
    filteredNics = nics.filter((item, index) => nics.indexOf(item) === index);
  }

  const getNicDetails = (nics: any[] = []) => {
    let nicDetails: any = "";

    if (nics.length > 0) {
      nicDetails = nics.map((nic) => nic).join(", ");
    }
    return nicDetails;
  };

  const addNewCribNote = (info) => {
    openGlobalModal({
      modalSize: "md",
      title: "",
      bodyId: "add-crib-note",
      close: false,
      modalParams: {
        info,
        title: "Main Applicant",
        application: creditData,
        onChange: onChangeNote,
      },
    });
  };

  const confirmRemoveCribAdmin = (info, k) => {
    openGlobalModal({
      modalSize: "xs",
      title: "Delete Crib Document",
      bodyId: "confirm-dialog-modal",
      close: false,
      modalParams: {
        description: `Added crib document for Crib Key ${info.cribKey} will be deleted`,
        onAction: async () => {
          try {
            removeCribDocument(info, k);
            Toast.success(`Crib document for Crib Key ${info.cribKey} deleted successfully!`);
          } catch (error) {
            Toast.error("Failed to delete crib document");
          }
        },
      },
    });
  };

  const CribNotes = () => {
    const [notes, setNotes]: any = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      (async () => {
        downloadNotes();
      })();

      onChangeNote = (note) => {
        downloadNotes();
      };

      return () => { };
    }, []);

    const downloadNotes = async () => {
      try {
        setLoading(true);
        const data = { applicationId: creditData.get().applicationId, participantId: user?.participantId };
        const result = await getCribNote(data);
        setNotes(result);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <Stack width={"100%"}>
        <Stack direction={"row"} px={1} spacing={1} alignItems={"center"}>
          {loading && <CircularProgressComponent size={12} />}
          <Typography variant="caption" color={"gray"}>
            CRIB Notes {notes.length ? `(${notes.length})` : ""}
          </Typography>
        </Stack>
        <Divider sx={{ marginTop: 1, marginBottom: 1 }}></Divider>
        <Stack px={1} direction={"column"} maxHeight={"200px"} sx={{ overflowY: "auto", overflowX: "clip" }}>
          {notes.map((note, index) => (
            <Stack key={index} direction={"column"} mb={2}>
              <Typography fontWeight={200} variant="body2" style={{ overflowWrap: "break-word" }}>
                {note.note}
              </Typography>
              <Stack direction={"row"} justifyContent={"space-between"} flex={1} mt={1}>
                <Typography variant="caption" color={"gray"}>
                  {userName(note.createdBy)}
                </Typography>
                <Typography variant="caption" color={"gray"}>
                  {dateFormatMMDDYYYY(note.createdAt)} {timeFormatWithTimeChange(note.createdAt)}
                </Typography>
              </Stack>
              <Divider style={{ marginBottom: 10 }}></Divider>
            </Stack>
          ))}
        </Stack>
      </Stack>
    );
  };

  return (
    <Grid container className="full-width" alignItems="center" mb={2}>
      <Grid style={{ backgroundColor: "#e8ecf1" }} container p={2} className="full-width border-radius-5" columns={24} alignItems="center">
        <Grid item xs={22}>
          <Stack>
            <div className="basic-font basic-font-color-bold font-size-16 align-left">{title}</div>
            {/* <div className="basic-font font-size-14 basic-font-color">{subTitle}</div> */}
          </Stack>
        </Grid>

        <Grid item xs={2} style={{ textAlign: "right" }}>
          <ButtonComponent
            iconbtn
            onClick={() => setIsExpand(!isExpand)}
          // disabled={disabled}
          >
            {isExpand ? <KeyboardDoubleArrowUpIcon className="basic-font-color" /> : <KeyboardDoubleArrowDownIcon className="basic-font-color" />}
          </ButtonComponent>
        </Grid>
      </Grid>
      {isExpand && (
        <Grid container p={1} className="data-box" style={{ backgroundColor: "#e8ecf1" }}>
          <Grid container p={1} className="full-width" columns={24} alignItems="center">
            <Stack direction={"row"} flex={1} justifyContent={"space-between"}>
              <Stack flex={1}>
                <IconRowComponent Icon={PersonIcon} subTxt={"Full Name"} mainTxt={user?.initialsInFull ? user?.initialsInFull : "-"} color={"#1976d2"} />
              </Stack>

              <Stack direction={"row"} spacing={2}>
                <ButtonComponent
                  startIcon={<NoteAddIcon />}
                  title={"Add Note"}
                  variant="contained"
                  onClick={() => {
                    addNewCribNote({
                      name: user?.initialsInFull ?? "",
                      participantId: user?.participantId,
                      clientType,
                    });
                  }}
                  disabled={getIsDisbursed(privilegeKeys.DECISION_ENGINE_TAB_UPLOAD_CRIB, disbursed) || disabled}
                  loadingbtn={true}
                  loading={false}
                />
                <ButtonComponent
                  startIcon={<AddIcon />}
                  title={"Upload CRIB"}
                  variant="contained"
                  onClick={() => {
                    uploadCribRecord(user?.participantId, clientType);
                  }}
                  disabled={getIsDisbursed(privilegeKeys.DECISION_ENGINE_TAB_UPLOAD_CRIB, disbursed) || disabled}
                  loadingbtn={true}
                  loading={false}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid container p={1} className="full-width" columns={24} alignItems="center">
            <Grid item sm={8} style={{ textAlign: "left" }}>
              <IconRowComponent Icon={CreditScoreIcon} subTxt={"NIC"} mainTxt={getNicDetails(filteredNics)} color={"#1976d2"} />
            </Grid>
            <Grid item sm={8} style={{ textAlign: "left" }}>
              <IconRowComponent Icon={AddIcCallIcon} subTxt={"Mobile"} mainTxt={user?.phoneNumber ? user?.phoneNumber : "--"} color={"#1976d2"} />
            </Grid>
            <Grid item sm={8} style={{ textAlign: "left" }}>
              <IconRowComponent Icon={EmailIcon} subTxt={"Email"} mainTxt={user?.email ? user?.email : "--"} color={"#1976d2"} />
            </Grid>
          </Grid>
          <Grid container p={1} className="full-width" columns={24} alignItems="center">
            <Grid item sm={24} style={{ textAlign: "left" }}>
              <IconRowComponent Icon={HomeIcon} subTxt={"Address"} mainTxt={extractAddress()} color={"#1976d2"} />
            </Grid>
          </Grid>
          <Grid container pt={1} pr={1} pl={1} pb={2} className="full-width" columns={24} alignItems="center">
            <Grid container item sm={24} style={{ textAlign: "left" }}>
              <Grid item sm={24}>
                <div className="basic-font basic-font-color opacity-7 font-size-12">CRIB Records</div>
              </Grid>
              <Grid item sm={24}>
                <div className="basic-font basic-font-color-bold font-size-14">
                  {participantEntryMap[user?.participantId] && (
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow className="basic-font font-size-12">
                            <TableCell align="left">
                              <b>#</b>
                            </TableCell>
                            <TableCell align="left">
                              <b>CRIB Record ID</b>
                            </TableCell>
                            <TableCell align="left">
                              <b>Performance</b>
                            </TableCell>
                            <TableCell align="left">
                              <b>Date Received</b>
                            </TableCell>
                            <TableCell align="left">
                              <b>Date Uploaded</b>
                            </TableCell>
                            <TableCell align="left">
                              <b>Arrears Status</b>
                            </TableCell>
                            <TableCell align="left">
                              <b>Status</b>
                            </TableCell>
                            <TableCell align="left">
                              <b>Uploaded On</b>
                            </TableCell>
                            <TableCell align="left">
                              <b>Uploaded By</b>
                            </TableCell>
                            <TableCell align="left"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {cribList[participantEntryMap[user?.participantId]]?.map((col, k) => {
                            let color = cribStatusColors[col?.status]?.color ? cribStatusColors[col?.status]?.color : cribStatusColors["default"]?.color;

                            return (
                              col?.cribKey && (
                                <TableRow
                                  key={k}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row" align="left" className="basic-font opacity-7 font-size-12">
                                    {k + 1}
                                  </TableCell>
                                  <TableCell align="left" className="basic-font opacity-7 font-size-12">
                                    {col?.cribKey}
                                  </TableCell>
                                  <TableCell align="left" className="basic-font opacity-7 font-size-12">
                                    <div
                                      className="basic-font font-size-12 label"
                                      style={{
                                        backgroundColor: `${color}3d`,
                                        color: color,
                                        border: `2px solid ${color}`,
                                        borderRadius: "10px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {col?.status ? masterDataStore?.getMasterDataDescription(MASTER_DATA_KEYS?.CRIB_STATUS, col?.status) : "-"}
                                    </div>
                                  </TableCell>
                                  <TableCell align="left" className="basic-font opacity-7 font-size-12">
                                    {col?.dateReceived ? dateFormatWithDate(col?.dateReceived) : "--"}
                                  </TableCell>
                                  <TableCell align="left" className="basic-font opacity-7 font-size-12">
                                    {col?.uploadedDate ? dateFormatWithTimeChange(col?.uploadedDate) : "--"}
                                  </TableCell>
                                  <TableCell className="basic-font opacity-7 font-size-12">{col?.arrearsStatus ? masterDataStore?.getMasterDataDescription(MASTER_DATA_KEYS?.CRIB_RECODE, col?.arrearsStatus) : "--"}</TableCell>
                                  {/* <TableCell
                                    align="left"
                                    className="basic-font opacity-7 font-size-12"
                                  >
                                    {col?.cribDeductions?.toLocaleString()}
                                  </TableCell> */}
                                  <TableCell align="left" className="basic-font opacity-7 font-size-12">
                                    {col?.isActive === 1 ? <Chip label="Active" color="success" /> : <Chip label="Inactive" color="error" />}
                                  </TableCell>
                                  <TableCell align="left" className="basic-font opacity-7 font-size-12">
                                    {col?.uploadedDate ? dateFormatWithTimeChange(col?.uploadedDate) : "--"}
                                  </TableCell>
                                  <TableCell align="left" className="basic-font opacity-7 font-size-12">
                                    {getUserDetailsById(col?.uploadedBy)[appSettings.get().language]?.name}
                                  </TableCell>
                                  <TableCell align="right">
                                    <Stack direction="row" spacing={2}>
                                      <PreviewIcon
                                        onClick={() => {
                                          previewBtnClicked(col?.docId);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          color: "#1976d2",
                                        }}
                                      />
                                      {Access.privilege(privilegeKeys.SYS_CONTROL_TROUBLESHOORT) && <ManageHistoryIcon
                                        onClick={() => {
                                          confirmRemoveCribAdmin(col, k);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          color: "#dd0000",
                                        }}
                                      />}
                                    </Stack>
                                  </TableCell>
                                </TableRow>
                              )
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <CribNotes></CribNotes>
        </Grid>
      )}
    </Grid>
  );
};

export default CribItemRow;
