import { useState } from "@hookstate/core";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { TabPropTypes } from "../../../configs/interfaces";
import { currentExpandSectionState } from "../../../configs/mainStore";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: any;
}

export default function VerticalTabComponent({
  children,
  classes,
  disabled,
  disableFocusRipple,
  disableRipple,
  icon,
  iconPosition,
  label,
  value,
  wrapped,
  sx,
  orientation,
  tabOptions,
  onChange,
  textColor,
  indicatorColor,
  variant,
  labelStyle,
  maxHeight,
  showContent,
}: TabPropTypes) {
  const expanded: any = useState(currentExpandSectionState);

  return (
    <Box sx={{ flexGrow: 1, display: "flex", height: "calc(100vh - 140px)" }} className="tab-paper">
      <Tabs
        orientation="vertical"
        value={value}
        onChange={onChange}
        textColor={textColor}
        indicatorColor={indicatorColor}
        sx={sx}
        scrollButtons="auto"
        variant={variant}
      >
        {tabOptions?.length > 0 &&
          tabOptions
            ?.filter((obj) => obj)
            .map((item, key) => {
              return (
                <Tab
                  disabled={disabled}
                  disableFocusRipple={disableFocusRipple}
                  disableRipple={disableRipple}
                  icon={icon}
                  iconPosition={iconPosition}
                  key={item?.id}
                  value={item?.id}
                  label={
                    <Stack direction={"row"}>
                      <Typography>{item?.title}</Typography>
                    </Stack>
                  }
                  sx={labelStyle}
                  onClick={() => {
                    // console.log(item?.id);
                    expanded?.set({
                      participant: null,
                      section: 0,
                      key: item?.id,
                    });
                  }}
                />
              );
            })}
      </Tabs>
      {showContent &&
        tabOptions.map((item, key) => {
          return (
            <TabPanel index={item.id} value={value} key={key}>
              {item.content}
            </TabPanel>
          );
        })}
    </Box>
  );
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}
