import { Downgraded, useState } from "@hookstate/core";
import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import {
  creditData,
  userDetails
} from "../../../../configs/mainStore";
import ProductDocumentUploadLayout from "../../../../layouts/ProductDocumentUploadLayout";
import { getApplicationData } from "../../../../services/creditFileApiCall";
import { CircularProgressComponent } from "../../ProgressComponent/ProgressComponent";

const JointBorrowerDocument = ({ data, applicationId, innerComponentData, tabsToEdit }) => {
  const [jointBorrowerArrData, setJointBorrowerArrData] = React.useState<any>({});
  const [sectionLoading, setSectionLoading] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const creditFileData: any = useState(creditData);
  const { productId, applicantType }: any = creditFileData.attach(Downgraded).get();

  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  useEffect(() => {
    setJointBorrowerArrData(data?.formData?.jointBorrowerData);
    setIsLoading(false);
  }, [jointBorrowerArrData]);

  const onSyncSuccess = async (e) => {
    setIsLoading(true);
    const response = await getApplicationData(applicationId);
    setJointBorrowerArrData(response?.APPLICANT_FORM_DATA?.jointBorrowerData);
    setIsLoading(false);
  };

  const onSync = (e) => {
    setSectionLoading(e);
  };

  return (
    <Grid container className="inner-component-full-height">
      <div className="full-width full-height">
        {isLoading ? (
          <CircularProgressComponent
            size={30}
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
              marginLeft: "auto",
              marginRight: "auto",
              zIndex: 100,
            }}
          />
        ) : (
          jointBorrowerArrData &&
          Object.keys(jointBorrowerArrData).length > 0 &&
          jointBorrowerArrData?.map((item, i) => {
            return (
              <ProductDocumentUploadLayout
                key={i}
                applicationId={applicationId}
                productId={productId}
                applicantType={applicantType}
                documentTypeId={innerComponentData?.documentTypeId}
                documentSectionName={innerComponentData?.title}
                documentComponentId={innerComponentData?.id}
                participantId={item?.personalData?.individualId}
                personalData={item?.personalData}
                onSyncSuccess={onSyncSuccess}
                onSync={onSync}
                sectionLoading={sectionLoading}
                disableAccordingToTab={tabsToEdit.includes(innerComponentData?.id)}
                isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee}
                participantType={innerComponentData?.participantType}
              />
            );
          })
        )}
      </div>
    </Grid>
  );
};

export default JointBorrowerDocument;
