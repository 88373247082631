import { Downgraded } from "@hookstate/core";
import { Typography } from "@mui/material";
import { creditData } from "../../../configs/mainStore";

export const DocumentFooter = ({ positionStyleType }) => {
  const creditFileData: any = creditData.attach(Downgraded).get();

  return (
    <div>
      <Typography className={positionStyleType} color={"#737475"} variant="caption">
        <div id="content">{`Ref: ${creditFileData?.applicationSerial} `}</div>
      </Typography>
    </div>
  );
};
