import { useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { appSetting, userDetails } from "../../../configs/mainStore";
import { changePasswordService } from "../../../services/userService";
import { closeBtn } from "../../../styles";
import { hashValue } from "../../../utility/other";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import ModalInputComponent from "../../InputComponents/ModalInputComponent/ModalInputComponent";
import { TextBoxComponent } from "../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../GlobalModal/GlobalModal";
import { addToaster } from "../GlobalToast";

const ResetPasswordModal = (params) => {
  const showErrors = useState(false);
  // const conditionState: any = useState();
  const loginUser = useState(userDetails);
  const appSettings: any = useState(appSetting);
  const [isSaveDisable, setIsSaveDisable] = React.useState<any>(true);
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  useEffect(() => {
    if (currentPassword != "" && newPassword != "" && confirmPassword != "") {
      setIsSaveDisable(false);
    } else {
      setIsSaveDisable(true);
    }
  }, [currentPassword, newPassword, confirmPassword]);

  const actionProceed = async () => {
    const payload: any = {
      password: currentPassword ? hashValue(currentPassword) : "",
      newPassword: newPassword ? hashValue(newPassword) : "",
    };

    if (!payload.password) {
      addToaster({
        status: "error",
        title: "Current Password",
        message: "Current Password Required",
      });
    } else if (!payload.newPassword) {
      addToaster({
        status: "error",
        title: "New Password",
        message: "New Password Required",
      });
    } else if (newPassword != confirmPassword) {
      addToaster({
        status: "error",
        title: "Confirm Password",
        message: "Confirm Password Mismatched",
      });
    } else {
      let result: any = {};
      try {
        result = await changePasswordService(payload);
        addToaster({
          status: "success",
          title: "Success",
          message: "Password Changed",
        });
        params.userLogout();
      } catch (error) {
        addToaster({
          status: "error",
          title: "Password Reset Error",
          message: `${error} occured`,
        });
      }
    }
  };
  // const validateSave = () => {
  //   return conditionState.type.get() === null || conditionState.title.get() === "" || conditionState.description.get() === "";
  // };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  const closeResetPasswordModal = () => {
    closeGlobalModal();
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Reset Password</div>
      </Stack>
      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12} mb={2}>
          <ModalInputComponent
            title="Current Password"
            component={
              <TextBoxComponent
                label={"Current Password"}
                value={currentPassword}
                required
                fullWidth={true}
                onChange={(e) => {
                  setCurrentPassword(e.target.value);
                }}
                type={"password"}
              />
            }
            isMandatory={false}
            showErrors={showErrors.get()}
          />
        </Grid>
        <Grid container item xs={12} mb={2}>
          <ModalInputComponent
            title="New Password"
            component={
              <TextBoxComponent
                label={"New Password"}
                value={newPassword}
                required
                fullWidth={true}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                type={"password"}
              />
            }
            isMandatory={false}
            showErrors={showErrors.get()}
          />
        </Grid>
        <Grid container item xs={12} mb={2}>
          <ModalInputComponent
            title="Confirm Password"
            component={
              <TextBoxComponent
                label={"Confirm Password"}
                value={confirmPassword}
                required
                fullWidth={true}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                type={"password"}
              />
            }
            isMandatory={false}
            showErrors={showErrors.get()}
          />
        </Grid>
      </Grid>
      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent title="Close" startIcon={<CloseIcon />} variant="contained" onClick={closeResetPasswordModal} loadingbtn={true} style={closeBtn} />
        <ButtonComponent startIcon={<DoneIcon />} title="Save" variant="contained" onClick={actionProceed} loadingbtn={true} color="info" disabled={isSaveDisable} />
      </Stack>
    </Box>
  );
};
export default ResetPasswordModal;
