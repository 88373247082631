import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Grid } from "@mui/material";
import { defaultOptions } from "../../../../../utility/util";
import { IconRowConditionComponent } from "../IconRowConditionComponent";

const OfferLetterConditionItemRow = (params) => {
  const {
    data,
    applicationId,
    setNewDataArray,
    creditFileData,
    actionDelete,
    disableAccordingTo,
    trialCalcResult,
    trialCalcParams,
    index,
    handleInstantDelete,
    recommendedInterestRate,
    regularPaymentFactor
  } = params;

  const color = defaultOptions?.color;

  return (
    <Grid
      container
      alignItems="center"
      className="row_list_item"
      p={2}
      key={data.sequence}
      style={{
        backgroundColor: `${color}3d`,
      }}
    >
      <Grid container className="full-width" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <IconRowConditionComponent
            Icon={RadioButtonCheckedIcon}
            subTxt={data?.title || data?.TITLE}
            mainTxt={data?.description}
            color={color}
            data={data}
            applicationId={applicationId}
            setNewDataArray={setNewDataArray}
            creditFileData={creditFileData}
            actionDelete={actionDelete}
            disableAccordingTo={disableAccordingTo}
            trialCalcResult={trialCalcResult}
            trialCalcParams={trialCalcParams}
            conditionType={"offerCondition"}
            index={index}
            handleInstantDelete={handleInstantDelete}
            recommendedInterestRate={recommendedInterestRate}
            regularPaymentFactor={regularPaymentFactor}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OfferLetterConditionItemRow;
