import { Downgraded, useState } from "@hookstate/core";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { creditData, formStructureSet } from "../../../../../configs/mainStore";
import { lifeInsuranceDefault } from "../../../../../configs/stores/securityDataSubSection/securityDataSubSections.module";
import { saveAppFormDataJson } from "../../../../../services/apiCalls";
import { resetInputItems } from "../../../../../services/multipleInputDelete";
import { disableAccess } from "../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { LifeInsurance } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/PaperDocs/LifeInsurance/LifeInsurance";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { collateralApplicationFormGetter, getMandatoryFields, handleMandatory } from "./collateral-apis/CollateralApplicationFormGetter";
import { collateralSectionDeletion } from "./collateral-utils/Utility";
import { lifeInsuranceValidationCheck } from "./controllers/Controllers";
import HeaderCollateral from "./helpers/CollateralHeaderComponent";
import { collateralValidationErrorArray } from "./validations/validations";

export const CreditLifeInsurance = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion, apiCallBack, innerComponentData, tabsToEdit }) => {
  const [fullObj, setFullObj]: any = React.useState({});
  const [loading, setLoading]: any = React.useState(false);
  const requestId = data?.requestId;
  const [fields, setFields]: any = React.useState({});
  const existingData = useState<Array<any>>([]);
  const [fetching, setFetching] = React.useState<boolean>(false);
  let itemIndex = 0;
  const [validateMandatoeyObj, setValidateMandatoeyObj]: any = React.useState({});

  const exisitingObject = data?.formData?.securityData?.paperDocs?.lifeInsurance ?? [];

  const creditDetailsState: any = useState(creditData);
  const { paperDocs } = creditDetailsState?.formData?.securityData;
  const appFormSet = useState(formStructureSet);

  const checkPrivilegeStatus = disableAccess({ isTabInEditString: tabsToEdit.includes(innerComponentData?.id) });

  const handleGetApplicationData = async () => {
    await getApplicationForm();
  };

  useEffect(() => {
    setFetching(true);
    handleGetApplicationData()
      .then(() => {
        setExistingData();
      })
      .then(() => {
        if (data?.formData?.securityData?.paperDocs) {
          setFullObj(JSON.parse(JSON.stringify(data?.formData?.securityData?.paperDocs)));
        } else setFullObj([]);
      });
    setFetching(false);
  }, []);

  const LIFE_INSURANCE_MANDATORY = [...getMandatoryFields(fields)];

  const getApplicationForm = async () => {
    const key = "lifeInsurance";
    const response = await collateralApplicationFormGetter(key, appFormSet);
    if (response) {
      setFields(response);
    } else setFields({});
  };

  const setExistingData = () => {
    if (exisitingObject) {
      existingData.set(JSON.parse(JSON.stringify(exisitingObject)));
    }
  };

  const handleFieldsAdding = () => {
    let lifeInsurance = {
      itemIndex: 0,
      entryId: 0,
      ...JSON.parse(JSON.stringify(lifeInsuranceDefault)),
    };

    try {
      if (existingData.get()?.length > 0) {
        lifeInsurance["itemIndex"] = existingData.get()?.length;
        lifeInsurance["entryId"] = existingData.get()?.length;
      }
      const tempArray = existingData.get() ? [...existingData.get(), lifeInsurance] : [lifeInsurance];
      existingData.set(JSON.parse(JSON.stringify(tempArray)));
    } catch (error) { }
  };

  const handleSave = async (props = {}) => {
    setLoading(true);

    const finalArr: any = [];
    if ("lifeInsurance" in fullObj) {
      delete fullObj.lifeInsurance;
    }

    let keyIndex = 0;

    for (let index = 0; index < existingData.get().length; index++) {
      const element = existingData[index].get();

      if (!element || element === null) continue;

      // if (element?.removedItem) continue;

      let obj = {
        type: "paperdocs_lifeInsurance",
        itemIndex: index,
        entryId: index,
        ...element,
      };
      keyIndex += 1;
      finalArr[finalArr.length] = obj;
    }

    let finalObj = {
      paperDocs: {
        lifeInsurance: finalArr,
        ...fullObj,
      },
    };

    let mandatoryRes: any[] = [];
    let errorArray = [];

    if (Object.values(props).length > 0) {
      errorArray = collateralValidationErrorArray("paperDocs", "lifeInsurance", finalObj);

      mandatoryRes = handleMandatory(existingData.attach(Downgraded).get(), LIFE_INSURANCE_MANDATORY);

      const getReadyForValidate = JSON.parse(JSON.stringify(validateMandatoeyObj));

      if (Object.keys(getReadyForValidate).length > 0 && getReadyForValidate["removedItem"] !== true) {
        for (const key in getReadyForValidate) {
          let returned = lifeInsuranceValidationCheck(true, getReadyForValidate[key]);

          if (returned?.isError) {
            setLoading(false);
            return addToaster({
              status: "error",
              title: "Mandatory Validation Error",
              message: `${returned?.message}`,
            });
          } else continue;
        }
      }
    }

    const lastValidation: Array<any> = [...errorArray, ...mandatoryRes];

    if (lastValidation?.length > 0) {
      const errorMessage: string = lastValidation.join("\n");
      addToaster({
        status: "error",
        title: "Mandatory Validation Error",
        message: `${errorMessage}`,
      });
      setLoading(false);
    } else {
      let data = JSON.parse(JSON.stringify(finalObj));
      let res = await saveAppFormDataJson(requestId, "securityData", data);

      if (res?.status === 1) {
        paperDocs.set({ ...data.paperDocs });
        setLoading(false);
        addToaster({
          status: "success",
          title: "Updated",
          message: "Security Details Updated",
        });
      } else if (res?.error) {
        setLoading(false);
        addToaster({
          status: "warning",
          title: "Not Updated",
          message: "Update Error",
        });
      } else {
        setLoading(false);
        addToaster({
          status: "error",
          title: "Error",
          message: "Something went Wrong!",
        });
      }
    }
  };

  const lifeInsuranceHandledValidationKeys = (objectName: string, key: string, value: number) => {
    let validateMandatoryObject = JSON.parse(JSON.stringify(validateMandatoeyObj));

    let Obj = {
      ...validateMandatoryObject,
      [objectName]: {
        ...validateMandatoryObject?.[objectName],
        [key]: value,
      },
    };

    setValidateMandatoeyObj(Obj);
  };

  return (
    <>
      {fetching ? (
        <Grid
          container
          alignItems="center"
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
            position: "relative",
          }}
        >
          <CircularProgressComponent size={30} sx={{ left: "50%", position: "absolute", zIndex: 100 }} />
        </Grid>
      ) : (
        <>
          <div className="full-width full-height" style={{ position: "relative" }}>
            {checkPrivilegeStatus ? <Stack style={{ width: "100%", height: "100vh", position: "absolute", left: 0, top: 0, backgroundColor: "255, 255, 255, 0.1", zIndex: 1000 }}></Stack> : <></>}
            {isMainContactDetails && (
              // <Header handleFieldsAdding={handleFieldsAdding} />
              <HeaderCollateral loading={loading} handleFieldsAdding={handleFieldsAdding} requestDtlData={existingData} collateralApplicationForm={fields} handleSave={handleSave} headerTitle={"Life Insurance"} />
            )}

            {/* Security Form */}
            <Grid container className="inner-component-height">
              <Stack spacing={1} m={1} className="full-width">
                {existingData.get()?.length > 0 ? (
                  existingData?.map((item: any, key) => {
                    if (item === null) return <></>;
                    const elementKeys = Object.keys(item);
                    if (!elementKeys.includes("removedItem")) {
                      itemIndex = itemIndex + 1;
                      return (
                        <Box display="flex" alignItems="center" mb={1} key={key * itemIndex}>
                          <Box style={{ width: "100%" }}>
                            <LifeInsurance doc={item} fields={fields} index={itemIndex} lifeInsuranceHandledValidationKeys={lifeInsuranceHandledValidationKeys} isNotNeedComponentTitle={true} />
                          </Box>
                          <Box>
                            <Box pb={3}>
                              <Tooltip title={"Reset"}>
                                <Box>
                                  <ButtonComponent
                                    iconbtn={true}
                                    onClick={() => {
                                      resetInputItems(item, "paperDocs", "loanProtection");
                                    }}
                                    style={{ border: "1px solid gray" }}
                                    size="small"
                                  >
                                    <RestartAltIcon />
                                  </ButtonComponent>
                                </Box>
                              </Tooltip>
                            </Box>
                            <Box pt={3}>
                              <Tooltip title={"Delete"}>
                                <Box>
                                  <ButtonComponent iconbtn onClick={() => {
                                    collateralSectionDeletion({ applicationId, collateralDataState: existingData, itemIndex: key, collateralKeyString: "paperDocs:lifeInsurance:Paper Docs Life Insurance" })

                                  }
                                  }>
                                    <DeleteIcon />
                                  </ButtonComponent>
                                </Box>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Box>
                      );
                    }
                  })
                ) : (
                  <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
                )}
              </Stack>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};
