import { Downgraded } from "@hookstate/core";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, CircularProgress, Grid, IconButton, Stack } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { creditData, userDetails } from "../../../../../configs/mainStore";
import { getPreviousBurrowingDetails, setLoanReportDataChanges } from "../../../../../services/bankServiceInvorker";
import { Toast } from "../../../GlobalToast";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";
import ReportOnBurrowingLayout from "./ReportOnBurrowingLayout";

// Tab View Required Imports
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import { LengthItemValidate } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Helpers/LengthItemValidatedFn";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

interface NicSearchResult {
  nic: string;
  selectedData: any;
  settiledLoans?: any;
  ongloingLoans?: any;
}

const ReportsPreviousBorrowings = ({ data, applicationId, innerComponentData, tabsToEdit }) => {
  const creditFileData: any = creditData.attach(Downgraded).get();
  const [loanData, setLoanData]: any[] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [submitSelectedData, setSubmitSelectedData] = useState<any>({});
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  const [selectedTab, setSelectedTab] = React.useState("PA");
  const jointBorrowerDetails: any[] = creditFileData?.formData?.jointBorrowerData || [];

  useEffect(() => {
    (async function () {
      setIsLoading(true);

      if (creditFileData) {
        await onRefreshData();
      }

      setIsLoading(false);
    })();
  }, [creditFileData, selectedTab]);

  const getNics: Record<string, string> = useMemo(() => {
    const getUserNic = (jbPersonalData = undefined): Record<string, string> => {
      if (selectedTab == "PA" || jbPersonalData) {
        let { oldNic = null, newNic = null } = jbPersonalData ? jbPersonalData : creditFileData?.formData?.personalData;
        let nicObj: Record<string, string | null> = { oldNic, newNic };

        return Object.entries(nicObj).reduce((returnedObj: Record<string, string>, [key, curr]: [string, string | null]) => curr ? { ...returnedObj, [key]: curr } : returnedObj, {})
      } else {
        let selectedJBTab: string = selectedTab.split("-")[1].trim();

        return LengthItemValidate.validatedArray(jointBorrowerDetails).reduce((returnedObj: Record<string, string>, currJb, index) => Number(selectedJBTab) - 1 === index ? getUserNic(currJb.personalData) : returnedObj, {})
      }
    }
    return getUserNic()
  }, [selectedTab])

  async function onRefreshData(force = false) {

    const promiseList: Promise<any[]>[] = Object.entries(getNics).reduce((promiseList: Promise<any[]>[], [key, nic]: [string, string]) => [...promiseList, getPreviousBurrowingDetails(nic, force, applicationId)], []);

    const results: any[] = await Promise.allSettled(promiseList);

    const resultObj: any = Object.entries(getNics).reduce((accData, [key, nic], index) => {
      let result: Record<string, any> | undefined = results[index]?.status === "fulfilled" ? results[index].value : undefined;

      if (!result) return accData;

      const { selectedData, loanDetails } = result;
      let dataObj: NicSearchResult = { nic, selectedData };

      loanDetails.forEach(({ interestDue = 0, outstandingBalanace = 0, totalPrincipalDue = 0 }) => {
        dataObj = (interestDue && outstandingBalanace && totalPrincipalDue) ? { ...dataObj, ongloingLoans: loanDetails } : { ...dataObj, settiledLoans: loanDetails };
      });

      accData[key] = dataObj

      return accData;
    }, [])

    let oldNicLoansObj: any = resultObj["oldNic"] || {};
    let newNicLoansObj: any = resultObj["newNic"] || {};

    setLoanData([newNicLoansObj, oldNicLoansObj]);
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const onClickedSend = async () => {
    setIsLoading(true);
    try {
      if (!Object.keys(submitSelectedData).length) {
        Toast.warning("No changes found", "No Updates");
      } else {
        const data = await setLoanReportDataChanges(submitSelectedData);

        data?.message ? Toast.success(data?.message, "Success") : Toast.error("Not Updated", "Error");

        Toast.success(data?.message, "Success")
      }
    } catch (error) {
      throw new Error("ReportsPreviousBorrowings | onClickedSend" + error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="full-width full-height">
      <Stack>
        <InnerSectionHeaderComponent
          innerComponentData={innerComponentData}
          applicationId={applicationId}
          disabled={isLoggedInUserNotTheAssignee}
          children={
            <IconButton
              size="small"
              color="primary"
              children={isLoading ? <CircularProgress size={24} /> : <SyncIcon />}
              onClick={async () => {
                setIsLoading(true);
                await onRefreshData(true);
                setIsLoading(false);
              }}
              sx={{ right: 20 }}
            />
          }
        />
      </Stack>
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Primary Applicant" value="PA" />
                {jointBorrowerDetails.length > 0 &&
                  jointBorrowerDetails.map((jb, index) => {
                    return <Tab key={index} label={`Joint Borrower ${index + 1}`} value={`JB-${index + 1}`} />;
                  })}
              </TabList>
            </Box>
            {isLoading ?
              <Stack width={'100%'} direction={'row'} justifyContent={"center"}>
                <CircularProgressComponent size={30} />
              </Stack>
              :
              <>
                <TabPanel value="PA">
                  <ReportOnBurrowingLayout data={data} applicationId={applicationId} innerComponentData={innerComponentData} tabsToEdit={tabsToEdit} loanData={loanData} onClickedSend={onClickedSend} selectedTab={selectedTab} setSubmitSelectedData={setSubmitSelectedData} />
                </TabPanel>

                {jointBorrowerDetails.length > 0 &&
                  jointBorrowerDetails.map((jb, index) => {
                    return (
                      <TabPanel value={`JB-${index + 1}`} key={index}>
                        <ReportOnBurrowingLayout data={data} applicationId={applicationId} innerComponentData={innerComponentData} tabsToEdit={tabsToEdit} loanData={loanData} onClickedSend={onClickedSend} selectedTab={selectedTab} setSubmitSelectedData={setSubmitSelectedData} />
                      </TabPanel>
                    );
                  })}
              </>
            }
          </TabContext>
        </Stack>
      </Grid>
    </div>
  );
};

export default ReportsPreviousBorrowings;
