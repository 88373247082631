import { Grid, Stack } from "@mui/material";

function SummaryBranchDate({ data, branch, date, typeCaseClass, renderTitles }) {
  const personalData = data?.formData?.personalData;
  const primaryAddress = data?.formData?.addressData?.permanentAddress;
  const jointBorrowerData = data?.formData?.jointBorrowerData || [];

  const PAAdress = (paData) => {
    if (!paData || paData.length === 0) {
      return <></>;
    }
    let paAddresses: String[] = [];
    let paAddressSegments = [primaryAddress?.addressLine1 || "", primaryAddress?.addressLine2 || "", primaryAddress?.addressLine3 || "", primaryAddress?.addressLine4 || ""].filter((obj) => obj);
    const paAddress = paAddressSegments.join(", ").trim();
    paAddresses.push(paAddress);

    return (
      <Stack direction={"row"} flex={1}>
        <Grid item className={`security-doc-type-variable ${typeCaseClass}`}>
          {paAddressSegments.map((line, i) => (
            <div key={i}>
              {line}
              {/* {paAddressSegments.length - 1 === i ? "." : ","} */}
            </div>
          ))}
        </Grid>
      </Stack>
    );
  };

  const JBAdress = ({ jb }) => {
    let addresses: String[] = [];
    let addressSegments = [jb?.addressData?.permanentAddress?.addressLine1 || "", jb?.addressData?.permanentAddress?.addressLine2 || "", jb?.addressData?.permanentAddress?.addressLine3 || "", jb?.addressData?.permanentAddress?.addressLine4 || ""].filter((obj) => obj);
    const address: any = addressSegments.join(", ").trim();
    addresses.push(address);

    return (
      <>
        {addresses.map((item, i) => {
          return (
            <Grid item className={`security-doc-type-variable ${typeCaseClass}`}>
              {addressSegments.map((line, i) => (
                <div key={i}>
                  {line}
                  {/* {addressSegments.length - 1 === i ? "." : ","} */}
                </div>
              ))}
            </Grid>
          );
        })}
      </>
    );
  };

  const renderNicDetails = (type = "", item: any = {}) => {
    let nic = "";
    if (type == "") {
      return null;
    }

    if (type == "PA") {
      if (personalData.newNic != null && personalData.newNic != "") {
        nic = `(NIC No : ${personalData.newNic})`;
      } else if (personalData.oldNic != null && personalData.oldNic != "") {
        nic = `(NIC No : ${personalData.oldNic})`;
      }
    } else if (type == "JB") {
      if (item?.personalData?.newNic != null && item?.personalData?.newNic != "") {
        nic = `(NIC No : ${item?.personalData?.newNic})`;
      } else if (item?.personalData?.oldNic != null && item?.personalData?.oldNic != "") {
        nic = `(NIC No : ${item?.personalData?.oldNic})`;
      } else {
        nic = "";
      }
    } else if (type == "GR") {
      if (item?.personalData?.newNic != null && item?.personalData?.newNic != "") {
        nic = `${item?.personalData?.newNic}`;
      } else if (item?.personalData?.oldNic != null && item?.personalData?.oldNic != "") {
        nic = `${item?.personalData?.oldNic}`;
      } else {
        nic = "";
      }
    }

    return nic;
  };

  return (
    <Grid container columns={12} p={1}>
      <Grid item xs={12} mt={1}></Grid>
      <Grid item xs={12} mt={1}>
        <Grid item mb={1.25} sx={{ width: "100%" }}>
          <span className={`security-doc-type-variable ${typeCaseClass}`}>
            {renderTitles(personalData?.primaryTitle)} {`${personalData?.initialsInFull} ${personalData?.primaryLastName}`}
          </span>{" "}
          {renderNicDetails("PA")}
        </Grid>
        <PAAdress />
      </Grid>
      <Grid item xs={12}>
        {jointBorrowerData.length > 0 &&
          jointBorrowerData?.map((item, i) => {
            return (
              <>
                <Grid item mt={1} sx={{ width: "100%" }}>
                  <span className={`security-doc-type-variable ${typeCaseClass}`}>
                    {renderTitles(item?.personalData?.primaryTitle)} {`${item?.personalData?.initialsInFull + " " + item?.personalData?.primaryLastName}`}
                  </span>{" "}
                  {renderNicDetails("JB", item)}
                </Grid>
                <Stack mt={1.25}>
                  <JBAdress jb={item} />
                </Stack>
              </>
            );
          })}
      </Grid>
      <Grid item xs={12} mt={3}>
        Dear Madam/Sir,
        <br />
        <h4 style={{ textDecoration: "underline" }}>BANKING FACILITIES</h4>
        We are pleased to confirm that we are willing to make the following facilities available to you on the terms, conditions and securities set out in this letter.
        <br />
        <br /> Kindly note that this offer will be lapsed in 30 days from the date of offer, if you do not accept.
      </Grid>
    </Grid>
  );
}

export default SummaryBranchDate;
