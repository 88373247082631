import { useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Stack } from "@mui/material";
import React, { createRef, useEffect } from "react";
import { ERROR_CODES } from "../../../../../../configs/constants/errorCode";
import { userDetails } from "../../../../../../configs/mainStore";
import {
  applicationTimelineApi,
  createCauHeadAssign,
  getOfficerManagerGroupId,
  uploadCauReferenceFiles,
} from "../../../../../../services/creditFileApiCall";
import { closeBtn } from "../../../../../../styles";
import AppConfig from "../../../../../../utility/AppConfig";
import { userName } from "../../../../../../utility/helpers/creditFileUtility";
import { referStatus } from "../../../../../../utility/util";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import ModalInputComponent from "../../../../../InputComponents/ModalInputComponent/ModalInputComponent";
import { RadioButtonComponent } from "../../../../../InputComponents/RadioButtonComponent/RadioButtonComponent";
import { SelectComponent } from "../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { FileUploadComponent } from "../../../../DropzoneComponent/FileUploadComponent";
import { closeGlobalModal } from "../../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../../GlobalToast";

const OfficerHeadReferManagerModal = (params) => {
  const referGroup = [];
  const initialData = { note: "", user: "" };
  const showErrors = useState(false);
  const referState: any = useState(initialData);
  const loginUser = useState(userDetails);
  const [headOfficerList, setHeadOfficerList] = React.useState<any>(false);
  const [headOfficerValue, setHeadOfficerValue] = React.useState<any>(null);
  const [assignToAdminOpinion, setAssignToAdminOpinion] = React.useState<any>(null);

  const docUploadRef: any = createRef();

  const [fileList] = React.useState<any>([
    {
      //TODO LEGAL DOC GET FROM DB
      ref: docUploadRef,
      isMandatory: true,
      allowedFormats: "application/pdf",
      maxFileSize: 4000000,
      documentKey: "",
    },
  ]);

  const radioButtonGroup = [
    {
      id: 1,
      name: "Yes",
      label: "Yes",
      value: true,
    },
    {
      id: 0,
      name: "No",
      label: "No",
      value: false,
    },
  ];

  useEffect(() => {
    (async function () {
      let tempUserList = {};
      const res = await getOfficerManagerGroupId(params?.data?.referType);

      const uniqueRes = new Set(res);

      if (res) {
        tempUserList = Array.from(uniqueRes.values()).map((obj) => {
          return {
            name: userName(obj),
            id: obj,
          };
        });

        setHeadOfficerList(tempUserList);
      }
    })();
  }, []);

  const saveRefer = async () => {
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      const promises: any = [];

      fileList.map((item: any) => {
        promises.push(item.ref.current?.uploadSingleImg());
      });

      const referStat = Object.keys(referStatus)?.find((sts) => referStatus[sts]?.key == "active");
      const data = {
        applicationId: params?.applicationId,
        createdBy: loginUser?.userId?.get(),
        workflowId: params?.currentWorkflow,
        stageId: params?.currentWorkflowStage,
        referStatus: referStat,
        referType: referGroup,
        cauOfficer: true,
        referId: params?.referId,
        assignToAdminOpinion: assignToAdminOpinion,
        assignee: assignToAdminOpinion === false ? params?.data?.createdBy : referState.user.get(),
        ...referState.get(),
      };

      const res = await createCauHeadAssign(data);
      if (res?.status?.code === 1) {
        addToaster({
          status: "success",
          title: "Success",
          message: res?.msg,
        });
        const referenceId = params?.data?.referId;
        const appTimelineParams = {
          referenceId,
          applicationId: params?.applicationId,
          actionedBy: loginUser?.userId?.get(),
          assignee: headOfficerValue,
          actionNote: referState?.note?.get() || "",
          actionType: "ASSIGN_TO_CAU_HEAD",
        };
        const applicationTimelineApiResponse = await applicationTimelineApi(appTimelineParams);
        if (applicationTimelineApiResponse?.status === ERROR_CODES.success) {
          addToaster({
            status: "success",
            title: "Success",
            message: applicationTimelineApiResponse?.data?.message,
          });
        } else {
          addToaster({
            status: "error",
            title: "Error",
            message: "Timeline Update Failed",
          });
        }
        // calling the modal callback passing necessary arguments
        if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
          params.callBackOnAction(res?.data);
        }
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: res?.data?.msg,
        });
      }
      closeGlobalModal();
    }
  };

  const closeRefer = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return referState.note.get() === "";
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  const { cau_document_id: documentId } = AppConfig.config;

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Create Referral</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Note"
            component={
              <TextBoxComponent
                value={referState?.note?.get()}
                onChange={(e) => {
                  resetError();
                  referState.note.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                minRows={4}
                inputProps={{ maxLength: 100 }}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && referState.note.get() === ""}
          />
        </Grid>
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Require Head of Credit Admmin Opinion"
            component={
              radioButtonGroup.map((item) => {
                return (
                  <>
                    <RadioButtonComponent
                      checked={assignToAdminOpinion === item?.value}
                      value={item?.value}
                      name={item?.name}
                      onChange={() => setAssignToAdminOpinion(item?.value)} // onChange={() => selectedFrom.set(element)}
                    />
                    <span className={"user-preference-label"}>{item?.label}</span>
                  </>
                );
              })
            }
            isMandatory={true}
            showErrors={showErrors.get() && referState.note.get() === ""}
          />
        </Grid>
        {assignToAdminOpinion === true && headOfficerList && (
          <Grid container item xs={12}>
            <ModalInputComponent
              title="Head of Credit Admin"
              component={
                <SelectComponent
                  onChange={(e) => {
                    resetError();
                    setHeadOfficerValue(e.target.value);
                    referState.user.set(e.target.value);
                  }}
                  values={headOfficerList}
                  value={headOfficerValue}
                  size="small"
                  label={"Select Head of Credit Admin"}
                />
              }
              isMandatory={true}
              showErrors={showErrors.get() && !referState.user.get()}
            />
          </Grid>
        )}

        {fileList.map((item, i) => {
          return (
            <FileUploadComponent
              fileDetails={item}
              uploadDataObject={{
                applicationId: params?.applicationId,
                referenceId: params?.referId,
                docIndex: i,
                documentId,
                createdBy: loginUser?.userId?.get(),
                assignToAdminOpinion: assignToAdminOpinion,
                assignee: assignToAdminOpinion === false ? params?.data?.assignee : "",
              }}
              isPreviewHave={false}
              fileUploadApi={uploadCauReferenceFiles}
            />
          );
        })}
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeRefer}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={saveRefer}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default OfficerHeadReferManagerModal;
