import { Box, Grid, Paper, Stack } from "@mui/material";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { CMTextInput } from "../CMTextInput";

import { Downgraded, useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SendIcon from "@mui/icons-material/Send";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { privilegeKeys } from "../../../../configs/constants/privilegeKeys";
import { appSetting, userDetails } from "../../../../configs/mainStore";
import { languageListStore } from "../../../../configs/stores/languageStore";
import {
  createNewPlaceholderApi,
  editSmsTemplateApi,
  getSmsTemplateById
} from "../../../../services/smsTemplateService";
import { isPrivilegeHave } from "../../../../services/utilService";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { addToaster } from "../../GlobalToast";

export const AddEditSmsPlaceholderTemplateComponent = ({
  smsListChanged,
  editTemplateItem,
  closeCondition,
  callBackOnAction,
}) => {
  const appSettings: any = useState(appSetting);
  const { systemLanguageList }: any = useState(languageListStore);
  const languageList: any = systemLanguageList.attach(Downgraded).get();
  const { userPrivilege }: any = userDetails.attach(Downgraded).get();

  const { t: translate } = useTranslation();

  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  const [values, setValues] = React.useState<any>({});
  const [isActive, setIsActive] = React.useState(true);

  const [languageData, setLanguageData] = React.useState<any>([]);

  useEffect(() => {
    const languagesObj = appSettings?.language.get() && languageList[appSettings?.language.get()];
    const languagesArray = Object.values(languagesObj).sort((a: any, b: any) =>
      a.DISPLAY_ORDER - b.DISPLAY_ORDER
    );

    setLanguageData(languagesArray.map((value) => value));
  }, []);

  useEffect(() => {
    setValues({});

    if (editTemplateItem?.id) {
      (async () => {
        const data = await getSmsTemplateById(editTemplateItem?.id);

        if (data?.tplId === editTemplateItem?.id) {
          setIsActive(editTemplateItem?.isActive === 1 ? true : false);
          setIsEdit(true);

          let tempValues;
          languageData?.map((item) => {
            let tempLang = (item?.CODE).toLowerCase();

            if (tempLang && data?.[item.CODE]) {
              tempValues = {
                ...tempValues,
                [`${tempLang}Name`]: data[item.CODE]?.name || "",
                [`${tempLang}Desc`]: data[item.CODE]?.desc || "",
              };
            }
          });

          setValues(tempValues);
        } else {
          addToaster({
            status: "error",
            title: "Update Error",
            message: "Something went Wrong",
          });
        }
      })();
    } else {
      setIsActive(true);
      setIsEdit(false);

      setValues({});
    }
  }, [editTemplateItem]);

  const onChangeField = (fieldName, value) => {
    setValues({ ...values, ...{ [fieldName]: value } });
  };

  const updateNewSmsTemplate = async () => {
    setUpdateLoading(true);

    let templateData: any = [];
    languageData?.map((item) => {
      let tempLang = (item?.CODE).toLowerCase();

      if (tempLang) {
        templateData.push({
          languageId: item?.CODE,
          name: values[`${tempLang}Name`],
          desc: values[`${tempLang}Desc`],
        });
      }

      templateData.push({
        languageId: item?.CODE,
        name: values[`${tempLang}Name`],
        desc: values[`${tempLang}Desc`],
      });
    });

    let result;

    if (isEdit) {
      result = await editSmsTemplateApi(editTemplateItem?.id, isActive, templateData);
    } else {
      result = await createNewPlaceholderApi(isActive, templateData);
    }

    if (result) {
      addToaster({
        status: "success",
        title: isEdit ? translate("smsModule.form.updated") : translate("smsModule.form.newAdded"),
        message: result,
      });

      setValues({});
      setIsActive(true);
      smsListChanged();

      callBackOnAction();
    } else {
      addToaster({
        status: "error",
        title: translate("errorMsg.updateError"),
        message: translate("errorMsg.someError"),
      });
    }

    setUpdateLoading(false);
  };

  return (
    <Paper elevation={0}>
      <Box p={1}>
        <Grid container columns={8} pl={1} pr={1}>
          <Grid item xs={8} sm={8}>
            <Grid container className="align-start" columns={8} pl={1} pr={1}>
              <Grid item xs={8} sm={8} className="align-start">
                <h3 className="basic-font" style={{ marginBottom: 0 }}>
                  {isEdit ? translate("smsModule.form.editSmsTitle") : translate("smsModule.form.createSmsTitle")}
                </h3>
                <p className="basic-font basic-font-color font-size-14" style={{ marginTop: 0 }}>
                  {isEdit && `${editTemplateItem?.name}`}
                </p>
              </Grid>
            </Grid>
            <Grid container columns={8} p={1}>
              {languageData?.map((item: any, i) => {
                let tempLang = (item?.CODE).toLowerCase();

                if (!tempLang) {
                  return null;
                }

                return (
                  <Grid container columns={8} key={i}>
                    <Grid item xs={8} sm={8}>
                      <CMTextInput
                        desc={`Name (${item?.DESCRIPTION})`}
                        component={
                          <TextBoxComponent
                            onChange={(e) => {
                              onChangeField(`${tempLang}Name`, e.target.value);
                            }}
                            multiline={true}
                            value={values[`${tempLang}Name`] ? values[`${tempLang}Name`] : ""}
                            disabled={updateLoading}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={8} sm={8}>
                      <CMTextInput
                        desc={`Description (${item?.DESCRIPTION})`}
                        component={
                          <TextBoxComponent
                            onChange={(e) => {
                              onChangeField(`${tempLang}Desc`, e.target.value);
                            }}
                            multiline={true}
                            value={values[`${tempLang}Desc`] ? values[`${tempLang}Desc`] : ""}
                            disabled={updateLoading}
                          />
                        }
                      />
                    </Grid>
                    <hr
                      style={{
                        borderTop: "1px solid #454c661a",
                        marginTop: "20px",
                        width: "100%",
                      }}
                    />
                  </Grid>
                );
              })}
              <Grid item xs={8} sm={8} textAlign="end" mt={1} spacing={2}>
                <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
                  <ButtonComponent
                    title="Close"
                    startIcon={<CloseIcon />}
                    variant="contained"
                    onClick={closeCondition}
                    loadingbtn={true}
                  />
                  {isEdit && (
                    <ButtonComponent
                      startIcon={<RestartAltIcon />}
                      title={"RESET"}
                      variant="contained"
                      onClick={() => {
                        setValues({});
                      }}
                      disabled={updateLoading || !isPrivilegeHave(userPrivilege, privilegeKeys.RESET_SMS_TEMPLATE)}
                      loadingbtn={true}
                      loading={updateLoading}
                      style={{ marginRight: "5px", backgroundColor: "red" }}
                    />
                  )}
                  <ButtonComponent
                    startIcon={<SendIcon />}
                    title={isEdit ? "Edit Template" : "Create"}
                    variant="contained"
                    onClick={() => {
                      updateNewSmsTemplate();
                    }}
                    disabled={updateLoading || !isPrivilegeHave(userPrivilege, privilegeKeys.CREATE_SMS)}
                    loadingbtn={true}
                    loading={updateLoading}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
