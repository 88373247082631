import { useState } from '@hookstate/core';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { followUpChecklistStatus } from '../../../../../../configs/constants/contants';
import { workflowMasterData } from '../../../../../../configs/mainStore';
import { getTaskChecklistList, taskChecklistItemStatusChange } from '../../../../../../services/creditFileApiCall';
import { dateFormatWithDate } from '../../../../../../utility/fomatters';
import { CheckBoxComponent } from '../../../../../InputComponents/CheckBoxComponent/CheckBoxComponent';
import { ErrorMessageComponent } from '../../../../ErrorMessageComponent';
import { addToaster } from '../../../../GlobalToast';
import { CircularProgressComponent } from '../../../../ProgressComponent/ProgressComponent';
import { IconRowComponent } from '../../IconRowComponent';
import InnerSectionHeaderComponent from '../../InnerSectionHeaderComponent';

const TaskChecklist = ({ applicationId, innerComponentData }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [doneChecklistList, setDoneCheckList] = React.useState<any>();
  const [pendingChecklistList, setPendingCheckList] = React.useState<any>();
  const [errorMessage, setErrorMessage] = React.useState('');

  const [isChanged, setIsChanged] = React.useState(false);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const data = await getTaskChecklistList(applicationId);

      if (data) {
        setDoneCheckList(data?.fundReleaseDoneList);
        setPendingCheckList(data?.fundReleasePendingList);
      } else {
        setErrorMessage('Something went Wrong!');
      }
      setIsLoading(false);
    })();
  }, [applicationId, isChanged]);

  const statusUpdate = async (itemId, isChecked, setIsChecked) => {
    setIsChecked(!isChecked);

    const result = await taskChecklistItemStatusChange(applicationId, itemId, isChecked);

    if (result?.msg) {
      addToaster({
        status: 'success',
        title: 'Updated',
        message: 'Check List Item Updated',
      });
    } else if (result?.error) {
      addToaster({
        status: 'warning',
        title: 'Not Updated',
        message: 'Update Error',
      });
    } else {
      addToaster({
        status: 'error',
        title: 'Error',
        message: 'Something went Wrong!',
      });
    }
  };

  return (
    <div className="full-width full-height">
      <InnerSectionHeaderComponent
        innerComponentData={innerComponentData}
        applicationId={applicationId}
        currentApplicationWfData={{}}
        callBackOnAction={() => {
          setIsChanged(!isChanged);
        }}
      />
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          {isLoading ? (
            <CircularProgressComponent
              size={30}
              sx={{ marginTop: '20px', marginBottom: '20px', marginLeft: 'auto', marginRight: 'auto', zIndex: 100 }}
            />
          ) : errorMessage ? (
            <ErrorMessageComponent headMessage={'Error!'} errorMessage={errorMessage} />
          ) : pendingChecklistList?.length > 0 || doneChecklistList?.length > 0 ? (
            <>
              {pendingChecklistList?.map((item, i) => {
                return <TaskChecklistRow itemObj={item} statusUpdate={statusUpdate} key={i} />;
              })}
              {doneChecklistList?.map((item, i) => {
                return <TaskChecklistRow itemObj={item} statusUpdate={statusUpdate} key={i} />;
              })}
            </>
          ) : (
            <ErrorMessageComponent headMessage={'Data Not Available'} errorMessage={''} />
          )}
        </Stack>
      </Grid>
    </div>
  );
};

const TaskChecklistRow = ({ itemObj, statusUpdate }) => {
  const [isChecked, setIsChecked] = React.useState<any>(false);

  useEffect(() => {
    itemObj?.status === followUpChecklistStatus.done ? setIsChecked(true) : setIsChecked(false);
  }, []);

  const InfoDetailsRow = () => {
    const { wf_system_users }: any = useState(workflowMasterData);
    const renderUser = (userId) => {
      const createdBy = wf_system_users?.get()?.find((usr) => usr?.id == userId);
      return createdBy?.fullName;
    };

    return <Stack
      direction="row"
      flex={1}
      divider={<Divider orientation="vertical" flexItem />}
      spacing={4}
      mt={2}
      justifyContent={'flex-end'}
    >

      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Typography color={'grey'} variant={'caption'}>
          {'Created By:'}
        </Typography>
        <Typography variant={'caption'}>
          {renderUser(itemObj.createdBy)}
        </Typography>
      </Stack>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Typography color={'grey'} variant={'caption'}>
          {'Created At:'}
        </Typography>
        <Typography variant={'caption'}>
          {itemObj?.createdDate ? dateFormatWithDate(itemObj?.createdDate) : '--'}
        </Typography>
      </Stack>
      {itemObj?.completedDate && <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Typography variant={'caption'} color={'grey'}>
          {'Completed At:'}
        </Typography>
        <Typography variant={'caption'}>
          {itemObj?.completedDate ? dateFormatWithDate(itemObj?.completedDate) : '--'}
        </Typography>
      </Stack>}
    </Stack>
  }

  return (
    <Box>
      <Grid
        className="row_list_item"
        style={{
          boxShadow: `0px 0px 10px ${isChecked ? '#39a21a' : '#ff1b1b'}3d`,
        }}
        container
        direction="column"
        p={2}
      >
        <Grid container direction="row">
          <Grid container style={{ textAlign: 'left' }} alignItems={'center'}>
            <CheckBoxComponent
              checked={isChecked}
              onChange={() => {
                statusUpdate(itemObj?.id, isChecked, setIsChecked);
              }}
            />
            <Grid flex={1}
            ><IconRowComponent subTxt={''} mainTxt={itemObj?.itemName} color={isChecked ? '#39a21a' : '#ff1b1b'} /></Grid>

            <IconRowComponent
              Icon={isChecked ? DoneIcon : CloseIcon}
              subTxt={''}
              mainTxt={isChecked ? 'Done' : 'Pending'}
              color={isChecked ? '#39a21a' : '#ff1b1b'}
            />
          </Grid>

          <InfoDetailsRow></InfoDetailsRow>

        </Grid>
      </Grid>
    </Box>
  );
};

export default TaskChecklist;
