export class GlobalWindowListener {

    static eventMap = {}

    static emitEvent({ key, storageData }) {
        const event = new CustomEvent(key, { detail: storageData });
        window.dispatchEvent(event);
    }

    static subscribeEvent({ key, option }) {
        let handler = this.buildHandler({ key, option });
        window.addEventListener(key, handler);
    }

    static unSubscribeEvent(key: string) {
        window.removeEventListener(key, this.eventMap[key]);
    }

    static buildHandler({ key, option }) {
        return () => { };
    }
}

export class GlobalTextChangeListener extends GlobalWindowListener {
    static buildHandler({ key, option }) {
        let handler = this.eventMap?.[key];

        if (!handler) {
            this.eventMap[key] = ({ detail: { initialsInFull = "", primaryLastName = "" } }) => {
                const element = document.getElementById(option.elementId);
                if (element) {
                    element.innerHTML = `${initialsInFull} ${primaryLastName}`.toUpperCase();
                }
            }
            handler = this.eventMap[key];
        }
        return handler
    }
}

type GetNicType = 'OLD' | 'NEW' | undefined;
export class Utility {

    static getNicType(nic: string): GetNicType {
        const newNicPattern = /^[0-9]{12}$/;
        const oldNicPattern = /^[0-9]{9}[Vv]$/;

        if (newNicPattern.test(nic)) {
            return 'NEW'
        }

        if (oldNicPattern.test(nic)) {
            return 'OLD'
        }
    }
}

interface FormData {
    [key: string]: any;
}

interface DataItem {
    sectionErrorStatus?: string;
    individualId?: number;
    securityData?: SecurityData;
}

interface SecurityData {
    guarantors?: any[];
}

export const convertReturnedDataToRawData = ({ formData }: { formData: FormData }): FormData => {
    if (!formData) return {};

    return Object.entries(formData).reduce((obj: FormData, [dataKey, currItem]: [string, any]) => {

        if (Array.isArray(currItem)) {
            if (dataKey === 'jointBorrowerData') {
                obj[dataKey] = currItem.map((item) => convertReturnedDataToRawData({ formData: item }));
            } else {
                currItem[0].sectionErrorStatus = 'pending';
                obj[dataKey] = currItem;
            }
        } else {
            if ('individualId' in currItem) {
                currItem.individualId = 0;
            }

            if (dataKey === 'securityData' && currItem.securityData?.guarantors) {
                currItem.securityData.guarantors = currItem.securityData.guarantors.map((guarantor: any) =>
                    convertReturnedDataToRawData({ formData: guarantor })
                );
            }

            currItem.sectionErrorStatus = 'pending';
            obj[dataKey] = currItem;
        }

        return obj;
    }, {});
};

export const compareArrays = (alphaArray, betaArray) => {
    if (alphaArray.length !== betaArray.length) {
        return false;
    }

    const sortedArray1 = [...alphaArray].sort((a, b) => a - b);
    const sortedArray2 = [...betaArray].sort((a, b) => a - b);

    for (let i = 0; i < sortedArray1.length; i++) {
        if (sortedArray1[i] !== sortedArray2[i]) {
            return false;
        }
    }

    return true;
};

