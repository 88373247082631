import { Grid } from "@mui/material";
import { MASTER_DATA_KEYS } from "../../../../../../configs/constants/masterDataKeys";
import { formatCurrency } from "../../../../../../services/currencyFormater";
import MasterDataProvider from "../../../../../../utility/MasterDataProvider";
import { trimWithDelimiter } from "../../../../../../utility/util";

function Security({ data, disburseLoanAmount, typeCaseClass }) {
  const personalGurantor = data?.formData?.securityData?.guarantors || [];
  const paperDocs = data?.formData?.securityData?.paperDocs || {};
  const movable = data?.formData?.securityData?.movable || {};
  const immovable = data?.formData?.securityData?.immovable || {};
  const masterDataStore = MasterDataProvider.provideMasterData();

  const personalData = data?.formData?.personalData;

  const renderNicDetails = (type = "", item: any = {}) => {
    let nic = "";
    if (type == "") {
      return null;
    }

    if (type == "PA") {
      if (personalData.newNic != null && personalData.newNic != "") {
        nic = `(NIC No: ${personalData.newNic})`;
      } else if (personalData.oldNic != null && personalData.oldNic != "") {
        nic = `(NIC No: ${personalData.oldNic})`;
      }
    } else if (type == "JB") {
      if (item?.personalData?.newNic != null && item?.personalData?.newNic != "") {
        nic = `(NIC No: ${item?.personalData?.newNic})`;
      } else if (item?.personalData?.oldNic != null && item?.personalData?.oldNic != "") {
        nic = `(NIC No: ${item?.personalData?.oldNic})`;
      } else {
        nic = "";
      }
    } else if (type == "GR") {
      if (item?.personalData?.newNic != null && item?.personalData?.newNic != "") {
        nic = `(NIC No: ${item?.personalData?.newNic})`;
      } else if (item?.personalData?.oldNic != null && item?.personalData?.oldNic != "") {
        nic = `(NIC No: ${item?.personalData?.oldNic})`;
      } else {
        nic = "";
      }
    }

    return nic;
  };

  const extractAddress = (gr) => {
    let address = "";
    address = [gr?.addressData?.permanentAddress?.addressLine1 ?? "", gr?.addressData?.permanentAddress?.addressLine2 ?? "", gr?.addressData?.permanentAddress?.addressLine3 ?? "", gr?.addressData?.permanentAddress?.addressLine4 ?? ""].filter((obj) => obj).join(", ");
    address = trimWithDelimiter(address);
    return address;

  };

  const renderGuranteeAmountOfLoans = (personalGurantor: any[] = []) => {
    if (personalGurantor.length < 1) {
      return null;
    }
    return (
      <>
        <Grid item xs={12}>
          Joint and several personal guarantors.{" "}
        </Grid>
      </>
    );
  };

  const renderGurantorNames = (personalGurantor: any[] = []) => {
    if (personalGurantor.length < 1) {
      return null;
    }
    return (
      <>
        <Grid item xs={12} mt={2} className={`${typeCaseClass}`}>
          <Grid container>
            {personalGurantor.map((gr, i) => {
              return (
                <>
                  <Grid item xs={0.5}>{`${i + 1}.`}</Grid>
                  <Grid item xs={11.5}>{`${gr?.personalData?.initialsInFull ?? "N/A"} ${gr?.personalData?.primaryLastName ?? "N/A"} ${renderNicDetails("GR", gr)} of ${extractAddress(gr)}`}</Grid>
                </>
              );
            })}
          </Grid>
        </Grid>
      </>
    );
  };

  const renderPledges = (pledge) => {
    if (pledge.length < 1) {
      return null;
    }
    return (
      <>
        <Grid item xs={12} mt={2} className={`${typeCaseClass}`}>
          <Grid container>
            {pledge.map((pld, i) => {
              return (
                <>
                  <Grid item xs={0.5} key={i}>
                    {`${i + 1}.`}
                  </Grid>
                  <Grid item xs={11.5}>
                    <Grid container xs={12} p={1} mb={1.5}>
                      <Grid item xs={12}>
                        Description of Goods : <span className={`security-doc-type-variable ${typeCaseClass}`}>{pld?.pledgeDescription}</span>
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        Quantity of Goods : <span className={`security-doc-type-variable ${typeCaseClass}`}>{pld?.pledgePreQuantityOfUnitsTested ? pld?.pledgePreQuantityOfUnitsTested : "N/A"}</span>
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        Value of Goods : <span className={`security-doc-type-variable ${typeCaseClass}`}>{pld?.pledgePreTotalValueChecked ? formatCurrency(pld?.pledgePreTotalValueChecked, 2) : "N/A"}</span>
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        Identification No : <span className={`security-doc-type-variable ${typeCaseClass}`}>{pld?.pledgeKeyNumber}</span>
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        Kept at Address : <span className={`security-doc-type-variable ${typeCaseClass}`}>{pld?.pledgeWarehouseAddress}</span>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Grid>
      </>
    );
  };

  const renderMachineryEquipment = (machineryEquipment, type) => {
    if (machineryEquipment.length < 1) {
      return null;
    }
    return (
      <>
        {type == "movable" && (
          <Grid item xs={12} mt={2} className={`${typeCaseClass}`}>
            <Grid container>
              {machineryEquipment.map((meq, i) => {
                return (
                  <>
                    <Grid item xs={0.5}>
                      {`${i + 1}.`}
                    </Grid>
                    <Grid item xs={11.5}>
                      <Grid container xs={12} p={1} mb={1.5}>
                        <Grid item xs={12}>
                          All those machineries/ equipment kept at {meq?.mmAddress} in the Democratic Socialist Republic of Sri Lanka described as follows:
                        </Grid>
                        <Grid item xs={12} mt={1}>
                          <span className={`security-doc-type-variable ${typeCaseClass}`}>{meq?.mmMachineType}</span> Machine (name of the machine/ equipment)
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Country of Origin : <span className={`security-doc-type-variable ${typeCaseClass}`}>{meq?.mmCountryOfManufacture}</span>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Model : <span className={`security-doc-type-variable ${typeCaseClass}`}>{meq?.mmModel}</span>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Year of Manufacture : <span className={`security-doc-type-variable ${typeCaseClass}`}>{meq?.mmYearOfManufacture}</span>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Capacity : <span className={`security-doc-type-variable ${typeCaseClass}`}>{meq?.mmCapacity}</span>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Value : <span className={`security-doc-type-variable ${typeCaseClass}`}>{formatCurrency(meq?.mmMarketValue, 2)}</span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>
        )}
        {type == "immovable" && (
          <Grid item xs={12} mt={2} className={`${typeCaseClass}`}>
            <Grid container>
              {machineryEquipment.map((meq, i) => {
                return (
                  <>
                    <Grid item xs={0.5}>
                      {`${i + 1}.`}
                    </Grid>
                    <Grid item xs={11.5}>
                      <Grid container xs={12} p={1} mb={1.5}>
                        <Grid item xs={12}>
                          All those machineries/ equipment kept at {meq?.immAddress} in the Democratic Socialist Republic of Sri Lanka described as follows:
                        </Grid>
                        <Grid item xs={12} mt={1}>
                          <span className={`security-doc-type-variable ${typeCaseClass}`}>{meq?.immMachineType}</span> Machine (name of the machine/ equipment)
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Country of Origin : <span className={`security-doc-type-variable ${typeCaseClass}`}>{meq?.immCountryOfManufacture}</span>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Model : <span className={`security-doc-type-variable ${typeCaseClass}`}>{meq?.immModel}</span>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Year of Manufacture : <span className={`security-doc-type-variable ${typeCaseClass}`}>{meq?.immYearOfManufacture}</span>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Capacity : <span className={`security-doc-type-variable ${typeCaseClass}`}>{meq?.immCapacity}</span>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Value : <span className={`security-doc-type-variable ${typeCaseClass}`}>{formatCurrency(meq?.immMarketValue, 2)}</span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const renderVehicle = (vehicle) => {
    if (vehicle.length < 1) {
      return null;
    }
    return (
      <>
        <Grid item xs={12} mt={2} className={`${typeCaseClass}`}>
          <Grid container>
            {vehicle
              .filter((veh) => veh != null)
              .map((vh, i) => {
                return (
                  <>
                    <Grid item xs={0.5}>
                      {`${i + 1}.`}
                    </Grid>
                    <Grid item xs={11.5}>
                      <Grid container xs={12} p={1} mb={1.5}>
                        <Grid item xs={12} mt={2}>
                          Registration Number : <span className={`security-doc-type-variable ${typeCaseClass}`}>{vh?.vhRegitrationNumber}</span>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Make : <span className={`security-doc-type-variable ${typeCaseClass}`}>{vh?.vhVehicleMake}</span>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Model : <span className={`security-doc-type-variable ${typeCaseClass}`}>{vh?.vhVehicleModel}</span>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Color : <span className={`security-doc-type-variable ${typeCaseClass}`}>{vh?.vhColour}</span>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Chassis No : <span className={`security-doc-type-variable ${typeCaseClass}`}>{vh?.vhChassisNo}</span>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Engine No : <span className={`security-doc-type-variable ${typeCaseClass}`}>{vh?.vhEngineNo}</span>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Year of Manufacture : <span className={`security-doc-type-variable ${typeCaseClass}`}>{vh?.vhYearOfManufacture}</span>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          Kept at Address : <span className={`security-doc-type-variable ${typeCaseClass}`}>{vh?.vhKeptAtAddress}</span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
          </Grid>
        </Grid>
      </>
    );
  };

  const renderProperty = (property) => {
    if (property.length < 1) {
      return null;
    }
    return (
      <>
        <Grid item xs={12} mt={2} className={`${typeCaseClass}`}>
          <Grid container>
            {property
              .filter((pr) => pr != null)
              .map((prp, i) => {
                return (
                  <>
                    <Grid item xs={0.5} key={i}>
                      {`${i + 1}.`}
                    </Grid>
                    <Grid item xs={11.5}>
                      <Grid container xs={12} p={1} mb={1.5}>
                        <Grid item xs={12} mt={2}>
                          Primary/ Secondary/ Tertiary mortgage over following immovable property
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          The allotment of land marked Lot
                          <span className={`security-doc-type-variable ${typeCaseClass}`}> {prp?.propLotNo}</span> depicted in Plan of No.
                          <span className={`security-doc-type-variable ${typeCaseClass}`}> {prp?.propSuveryPlanNo} </span> dated
                          <span className={`security-doc-type-variable ${typeCaseClass}`}> {prp?.propSurveyPlanDate} </span> made by
                          <span className={`security-doc-type-variable ${typeCaseClass}`}> {prp?.propSurveyPlanDate} </span>, Licensed Surveyor of the land called “<span className={`security-doc-type-variable ${typeCaseClass}`}> {prp?.propNameSurveyorMeasuredPlan}</span>” situated at
                          <span className={`security-doc-type-variable ${typeCaseClass}`}> {prp?.propAddressOfTheLocationOfTheLandOrBuilding} </span> village
                          <span className={`security-doc-type-variable ${typeCaseClass}`}> {prp?.propVillage} </span> in the Grama Niladhari Division of
                          <span className={`security-doc-type-variable ${typeCaseClass}`}> {prp?.propGramNiladariDivision} </span>,<span className={`security-doc-type-variable ${typeCaseClass}`}> {prp?.propDivisionalSecretaries} </span> Divisional Secretariat within the Pradeshiya Sabha Limits of <span className={`security-doc-type-variable ${typeCaseClass}`}> {prp?.propPradeshiyaSabhaLimists}</span>, in the
                          <span className={`security-doc-type-variable ${typeCaseClass}`}> {masterDataStore?.getMasterDataDescription(MASTER_DATA_KEYS?.DISTRICT, prp?.propDistric)} </span> District
                          <span className={`security-doc-type-variable ${typeCaseClass}`}> {masterDataStore?.getMasterDataDescription(MASTER_DATA_KEYS?.PROVINCE, prp?.propProvince)} </span> Province The present owner of the above land is.
                          <span className={`security-doc-type-variable ${typeCaseClass}`}> {prp?.propDetailsOfPrimary} </span> under deed No
                          <span className={`security-doc-type-variable ${typeCaseClass}`}> {prp?.propMortgageDeedNumber} </span> dated
                          <span className={`security-doc-type-variable ${typeCaseClass}`}> {prp?.propDatePreparedMortgageDeed} </span> attested by
                          <span className={`security-doc-type-variable ${typeCaseClass}`}> {prp?.propNameNotaryPreparedDeed} </span> Notary Public
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
          </Grid>
        </Grid>
      </>
    );
  };

  const movableAvailableStatus = () => {
    if ((movable?.pledge != null && movable?.pledge.length && movable?.pledge.some((item) => item.removedItem !== true)) || (movable?.machineryEquipment != null && movable?.machineryEquipment.length && movable?.machineryEquipment.some((item) => item.removedItem !== true)) || (movable?.vehicle != null && movable?.vehicle.length && movable?.vehicle.some((item) => item.removedItem !== true))) {
      return true;
    } else {
      return false;
    }
  };

  const immovableAvailableStatus = () => {
    if ((immovable?.property != null && immovable?.property.length && immovable?.property.some((item) => item.removedItem !== true)) || (immovable?.immovableMachineryEquipement != null && immovable?.immovableMachineryEquipement.length && immovable?.immovableMachineryEquipement.some((item) => item.removedItem !== true))) {
      return true;
    } else {
      return false;
    }
  };

  const validateSecurityDataAvailability = () => {
    if (personalGurantor.length > 0 || movableAvailableStatus() == true || immovableAvailableStatus() == true) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {validateSecurityDataAvailability() && (
        <Grid container columns={12} item p={1} textAlign={"left"}>
          <Grid item xs={12}>
            <h4 style={{ textDecoration: "underline" }}>SECURITY</h4>
          </Grid>
          <Grid item xs={12} mt={3}>
            {personalGurantor.length > 0 && (
              <Grid container xs={12} mb={1.5}>
                <Grid item xs={12}>
                  Personal Guarantee
                </Grid>
              </Grid>
            )}
            {personalGurantor.length > 0 && (
              <Grid container xs={12} p={1} mb={1.5} sx={{ border: "1px solid #000000" }}>
                {renderGuranteeAmountOfLoans(personalGurantor)}
                {renderGurantorNames(personalGurantor)}
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} mt={3}>
            {movableAvailableStatus() && (
              <Grid container xs={12} mb={1.5}>
                <Grid item xs={12}>
                  Movable
                </Grid>
              </Grid>
            )}

            {movable?.pledge != null && movable?.pledge.length > 0 && movable?.pledge.some((item) => item.removedItem !== true) && (
              <Grid container xs={12}>
                Pledge
              </Grid>
            )}

            {movable?.pledge != null && movable?.pledge.length > 0 && movable?.pledge.some((item) => item.removedItem !== true) && (
              <Grid container xs={12} p={1} mb={1.5} sx={{ border: "1px solid #000000" }}>
                {renderPledges(movable?.pledge)}
              </Grid>
            )}

            {movable?.machineryEquipment != null && movable?.machineryEquipment.length > 0 && movable?.machineryEquipment.some((item) => item.removedItem !== true) && (
              <Grid container xs={12}>
                Machinery and Equipment
              </Grid>
            )}

            {movable?.machineryEquipment != null && movable?.machineryEquipment.length > 0 && movable?.machineryEquipment.some((item) => item.removedItem !== true) && (
              <Grid container xs={12} p={1} mb={1.5} sx={{ border: "1px solid #000000" }}>
                {renderMachineryEquipment(movable?.machineryEquipment, "movable")}
              </Grid>
            )}

            {movable?.vehicle != null && movable?.vehicle.length > 0 && movable?.vehicle.some((item) => item.removedItem !== true) && (
              <Grid container xs={12}>
                Vehicle
              </Grid>
            )}

            {movable?.vehicle != null && movable?.vehicle.length > 0 && movable?.vehicle.some((item) => item.removedItem !== true) && (
              <Grid container xs={12} p={1} mb={1.5} sx={{ border: "1px solid #000000" }}>
                {renderVehicle(movable?.vehicle)}
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} mt={3}>
            {immovableAvailableStatus() && (
              <Grid container xs={12} mb={1.5}>
                <Grid item xs={12}>
                  Immovable
                </Grid>
              </Grid>
            )}

            {immovable?.property != null && immovable?.property.length > 0 && immovable?.property.some((item) => item.removedItem !== true) && (
              <Grid container xs={12}>
                Property
              </Grid>
            )}

            {immovable?.property != null && immovable?.property.length > 0 && immovable?.property.some((item) => item.removedItem !== true) && (
              <Grid container xs={12} p={1} mb={1.5} sx={{ border: "1px solid #000000" }}>
                {renderProperty(immovable?.property)}
              </Grid>
            )}

            {immovable?.immovableMachineryEquipement != null && immovable?.immovableMachineryEquipement.length > 0 && immovable?.immovableMachineryEquipement.some((item) => item.removedItem !== true) && (
              <Grid container xs={12}>
                Machinery and Equipment
              </Grid>
            )}

            {immovable?.immovableMachineryEquipement != null && immovable?.immovableMachineryEquipement.length > 0 && immovable?.immovableMachineryEquipement.some((item) => item.removedItem !== true) && (
              <Grid container xs={12} p={1} mb={1.5} sx={{ border: "1px solid #000000" }}>
                {renderMachineryEquipment(immovable?.immovableMachineryEquipement, "immovable")}
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Security;
