import { Downgraded } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import { FC } from "react";
import { branchDetails, creditData } from "../../../../../../../configs/mainStore";
import { SinglePageBreak } from "../../CommonPageBreaks";
import CommonSignatureBlock from "../../CommonSignatureBlock";
import CommonWitnessBlock from "../../CommonWitnessBlock";

interface LoanAgreementSignatureWitnessPropType {
  data?: any;
  nearestBranch?: any;
  typeCaseClass?: any;
}

export const LoanAgreementSignatureWitness: FC<LoanAgreementSignatureWitnessPropType> = ({ data, nearestBranch, typeCaseClass }) => {
  const personalData = data?.formData?.personalData;

  const creditFileData: any = creditData.attach(Downgraded).get();
  const usedBranchId = creditFileData["originationBranch"] || 1;
  const nearestBranchName = branchDetails.branchMap[usedBranchId].name.get();

  const renderNicDetails = (type = "", item: any = {}) => {
    let nic = "";
    if (type == "") {
      return null;
    }

    if (type == "PA") {
      if (personalData.newNic != null && personalData.newNic != "") {
        nic = personalData.newNic;
      } else if (personalData.oldNic != null && personalData.oldNic != "") {
        nic = personalData.oldNic;
      }
    } else if (type == "JB") {
      if (item?.personalData?.newNic != null && item?.personalData?.newNic != "") {
        nic = `${item?.personalData?.newNic}`;
      } else if (item?.personalData?.oldNic != null && item?.personalData?.oldNic != "") {
        nic = `${item?.personalData?.oldNic}`;
      } else {
        nic = "";
      }
    } else if (type == "GR") {
      if (item?.personalData?.newNic != null && item?.personalData?.newNic != "") {
        nic = `(${item?.personalData?.newNic})`;
      } else if (item?.personalData?.oldNic != null && item?.personalData?.oldNic != "") {
        nic = `(${item?.personalData?.oldNic})`;
      } else {
        nic = "";
      }
    }

    return nic;
  };

  return (
    <>
      <SinglePageBreak />
      <Grid container p={1}>
        <Grid item xs={12} mb={3}>
          This .......... day of .............................................. 20..... at <span className={`security-doc-type-variable ${typeCaseClass}`}>{nearestBranchName}</span> Branch
        </Grid>
        <Grid item xs={12} mb={2}>
          <Grid container columns={12}>
            <Grid item xs={12} mb={4}>
              Signature/ s of Applicant/s :
            </Grid>
            <CommonSignatureBlock creditFileData={data} typeCaseClass={typeCaseClass || ""} renderNicDetails={renderNicDetails} isThisDayAvailable={false} isDateAvailable={false} isSignAtAvailable={false} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container p={1} mb={2}>
        <Grid item xs={12}>
          <Stack direction={"column"} flex={1}>
            <Stack mb={2}>Witnesses :</Stack>
            <CommonWitnessBlock index={1} fields={["Signature", "Name", "NIC", "Designation", "Service Number"]} />
            <SinglePageBreak />
            <Stack mt={2}></Stack>
            <CommonWitnessBlock index={2} fields={["Signature", "Name", "NIC", "Designation", "Service Number"]} />
          </Stack>
        </Grid>
      </Grid>
      <Grid container p={1}>
        <Grid item xs={12} className="onbehalf-of-bank" mb={3}>
          ON BEHALF OF THE BANK
        </Grid>
        <Grid item xs={12}>
          <Stack direction={"column"} mt={3}>
            <Stack width={"40%"} style={{ borderBottom: "dotted 2px grey" }}></Stack>
          </Stack>
          <Stack direction={"column"}>Manager</Stack>
          <Stack direction={"column"}>Signature &amp; Seal</Stack>
        </Grid>
      </Grid>
    </>
  );
};
