import { useHookstate } from "@hookstate/core";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Stack, Typography } from "@mui/material";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../components/InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { closeBtn } from "../../../styles";

const section = { title: '', description: '', key: '' };

const AddDocumentSection = (params) => {
    const showErrors = useHookstate(false);
    const applicationSection = useHookstate(section);

    const addCondition = async () => {
        const errorState = validateSave();
        if (errorState) {
            showErrors.set(true);
        } else {
            // api request to save new application section

            closeGlobalModal();
        }
    };

    const closeCondition = () => {
        closeGlobalModal();
    };

    const validateSave = () => true;

    return (
        <Box>
            <Stack direction={'column'}>
                {/* section key */}
                <Stack width={'100%'}>
                    <Typography variant="subtitle2" color={'gray'}>
                        Section Key <span className="error">*</span>
                    </Typography>
                    <TextBoxComponent
                        value={applicationSection.key.get()}
                        onChange={(e) => {
                            const text = e.target.value.toString().split(' ').map(obj => obj.toUpperCase()).join("_");
                            applicationSection.key.set(text);
                            showErrors.set(false);
                        }}
                        variant="outlined"
                        size="small"
                        multiline={false}
                        minRows={1}
                    />
                </Stack>

                {/* section name */}
                <Stack width={'100%'} mt={2}>
                    <Typography variant="subtitle2" color={'gray'}>
                        Section Name <span className="error">*</span>
                    </Typography>
                    <TextBoxComponent
                        value={applicationSection.title.get()}
                        onChange={(e) => {
                            const text = e.target.value;
                            applicationSection.title.set(text);
                            showErrors.set(false);
                        }}
                        variant="outlined"
                        size="small"
                        multiline={false}
                        minRows={1}
                    />
                </Stack>

                {/* section description */}
                <Stack width={'100%'} mt={2}>
                    <Typography variant="subtitle2" color={'gray'}>
                        Section Description <span className="error">*</span>
                    </Typography>
                    <TextBoxComponent
                        value={applicationSection.description.get()}
                        onChange={(e) => {
                            const text = e.target.value;
                            applicationSection.description.set(text);
                            showErrors.set(false);
                        }}
                        variant="outlined"
                        size="small"
                        multiline={true}
                        minRows={1}
                    />
                </Stack>
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="end" pt={3} mt={2} pr={2} pb={1}>
                <ButtonComponent
                    title="Close"
                    startIcon={<CloseIcon />}
                    variant="contained"
                    onClick={closeCondition}
                    loadingbtn={true}
                    style={closeBtn}
                />
                <ButtonComponent
                    startIcon={<DoneIcon />}
                    title="Add"
                    variant="contained"
                    onClick={addCondition}
                    loadingbtn={true}
                    color="info"
                />
            </Stack>
        </Box>
    );
};

export default AddDocumentSection;