import { Downgraded, useState } from "@hookstate/core";
import React, { useEffect } from "react";
import { appSetting } from "../../../configs/mainStore";
import { languageListStore } from "../../../configs/stores/languageStore";
import { getLoginLanguageDataList } from "../../../services/other";
import { addToaster } from "../../OtherComponents/GlobalToast";
import { SelectComponent } from "./SelectComponent";

interface LanguageSelectProps {
  label?: string;
  onChange?: any;
  required?: boolean;
  value?: any;
  disabled?: any;
}

export const LoginLanguageSelect = ({ label, onChange, required, value, disabled }: LanguageSelectProps) => {
  const { loginLanguageList }: any = useState(languageListStore);

  const [dropdownLanguageList, setDropdownLanguageList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const languageList: any = loginLanguageList.attach(Downgraded).get();

    setIsLoading(true);
    (async () => {
      let tempDropdownElement: any = [];
      let data;
      if (languageList.length) {
        data = languageList;
      } else {
        data = await getLoginLanguageDataList();
      }

      if (data?.length) {
        data?.map((item) => {
          tempDropdownElement.push({ id: item.CODE, name: item.DESCRIPTION });
        });
      } else {
        addToaster({
          status: "error",
          title: "Empty",
          message: "Login Languages Not Found",
        });
      }

      setDropdownLanguageList(tempDropdownElement);

      setIsLoading(false);
    })();
  }, [dropdownLanguageList.length]);

  return (
    <SelectComponent
      label={label}
      value={dropdownLanguageList.length > 0 ? (value ? value : "") : ""}
      values={dropdownLanguageList}
      onChange={(e) => {
        onChange(e);
      }}
      required={required}
      disabled={isLoading}
    />
  );
};

export const SystemLanguageSelect = ({ label, onChange, required, value, disabled }: LanguageSelectProps) => {
  const appSettings: any = useState(appSetting);
  const { systemLanguageList }: any = useState(languageListStore);

  const [dropdownLanguageList, setDropdownLanguageList] = React.useState([]);

  useEffect(() => {
    const languageList: any = systemLanguageList.attach(Downgraded).get();

    let tempDropdownElement: any = [];
    if (languageList[appSettings.get().language]) {
      Object.values(languageList[appSettings.get().language]).map((item: any) => {
        tempDropdownElement.push({ id: item.CODE, name: item.DESCRIPTION });
      });
    } else {
      addToaster({
        status: "error",
        title: "Empty",
        message: "System Languages Not Found",
      });
    }

    setDropdownLanguageList(tempDropdownElement);
  }, []);

  return (
    <SelectComponent
      label={label}
      value={dropdownLanguageList.length > 0 ? (value ? value : "") : ""}
      values={dropdownLanguageList}
      onChange={(e) => {
        onChange(e);
      }}
      required={required}
      disabled={disabled}
    />
  );
};

export const CommunicationLanguageSelect = ({ label, onChange, required, value, disabled }: LanguageSelectProps) => {
  const appSettings: any = useState(appSetting);
  const { communicationLanguageList }: any = useState(languageListStore);

  const [dropdownLanguageList, setDropdownLanguageList] = React.useState([]);

  useEffect(() => {
    const languageList: any = communicationLanguageList.attach(Downgraded).get();

    let tempDropdownElement: any = [];

    if (languageList[appSettings.get().language]) {
      Object.values(languageList[appSettings.get().language]).map((item: any) => {
        tempDropdownElement.push({ id: item.CODE, name: item.DESCRIPTION });
      });
    } else {
      addToaster({
        status: "error",
        title: "Empty",
        message: "Communication Languages Not Found",
      });
    }

    setDropdownLanguageList(tempDropdownElement);
  }, []);

  return (
    <SelectComponent
      label={label}
      value={dropdownLanguageList.length > 0 ? (value ? value : "") : ""}
      values={dropdownLanguageList}
      onChange={(e) => {
        onChange(e);
      }}
      required={required}
      disabled={disabled}
    // defaultPlaceholder={true}
    />
  );
};
