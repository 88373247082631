import { useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Stack } from "@mui/material";
import { userDetails, workflowMasterData } from "../../../../../configs/mainStore";
import { taskStoreState } from "../../../../../configs/stores/credit-file-store/creditFileStore";
import { saveTaskData } from "../../../../../services/creditFileApiCall";
import { closeBtn } from "../../../../../styles";
import { responseSuccessCode } from "../../../../../utility/other";
import { taskStatus } from "../../../../../utility/util";
import AutoCompleteProxyComponent from "../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import ModalInputComponent from "../../../../InputComponents/ModalInputComponent/ModalInputComponent";
import { TextBoxComponent } from "../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../GlobalToast";

const TaskModal = (params) => {
  const initialData = { title: "", description: "", user: "" };
  const showErrors = useState(false);
  const { wf_system_users }: any = useState(workflowMasterData);
  const { taskList }: any = useState(taskStoreState);
  const taskState: any = useState(initialData);
  const loginUser = useState(userDetails);

  const saveTask = async () => {
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      const taskStat = Object.keys(taskStatus)?.find((sts) => taskStatus[sts]?.key == "open");
      const data = {
        applicationId: params?.applicationId,
        createdBy: loginUser?.userId?.get(),
        workflowId: params?.currentWorkflow,
        stageId: params?.currentWorkflowStage,
        taskStatus: taskStat,
        ...taskState.get(),
      };

      const res = await saveTaskData(data);

      if (res?.status === responseSuccessCode) {
        addToaster({
          status: "success",
          title: "Success",
          message: res?.data?.msg,
        });

        // calling the modal callback passing necessary arguments
        if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
          params.callBackOnAction(res?.data?.data);
        }
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: res?.data?.msg,
        });
      }

      closeGlobalModal();
    }
  };

  const closeTask = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return taskState.title.get() === "" || taskState.description.get() === "" || !taskState.user.get();
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Create Task</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Title"
            component={
              <TextBoxComponent
                value={taskState?.title?.get()}
                onChange={(e) => {
                  resetError();
                  taskState.title.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                // maxRows = {2}
                inputProps={{ maxLength: 100 }}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && taskState.title.get() === ""}
          />
          {/* <Grid item xs={6} md={4} className="align-center">
                        <div className="align-left font-size-14 basic-font basic-font-color">Title</div>
                    </Grid>
                    <Grid item xs={6} md={8}>
                        <TextBoxComponent
                            value={taskState?.title?.get()}
                            onChange={(e) => taskState.title.set(e.target.value)}
                            variant="outlined"
                            size="small"
                            multiline={true}
                            minRows={2}
                        />
                    </Grid> */}
        </Grid>
        <Grid container item xs={12}>
          {/* <Grid item xs={6} md={4} className="align-center">
                        <div className="align-left font-size-14 basic-font basic-font-color">Description</div>
                    </Grid>
                    <Grid item xs={6} md={8}>
                        <TextBoxComponent
                            value={taskState?.description?.get()}
                            onChange={(e) => taskState.description.set(e.target.value)}
                            variant="outlined"
                            size="small"
                            multiline={true}
                            minRows={4}
                        />
                    </Grid> */}

          <ModalInputComponent
            title="Description"
            component={
              <TextBoxComponent
                value={taskState?.description?.get()}
                onChange={(e) => {
                  resetError();
                  taskState.description.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                maxRows={4}
                rows={4}
                inputProps={{ maxLength: 4000 }}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && taskState.description.get() === ""}
          />
        </Grid>
        <Grid container item xs={12}>
          {/* <Grid item xs={6} md={4} className="align-center">
                        <div className="align-left font-size-14 basic-font basic-font-color">User</div>
                    </Grid>
                    <Grid item xs={6} md={8}>
                        <SelectComponent
                            onChange={(e) => taskState.user.set(e.target.value)}
                            values={wf_system_users?.get()?.map(d => ({ name: d?.fullName, id: d.id }))}
                            value={taskState?.user?.get()}
                            size="small"
                            defaultValue={'Select User'}
                        />
                    </Grid> */}

          <ModalInputComponent
            title="User"
            component={
              <AutoCompleteProxyComponent
                options={wf_system_users?.get()?.map((d) => ({ name: d?.fullName, id: d.id }))}
                fullWidth={true}
                onChange={(e) => {
                  resetError();
                  return taskState.user.set(e ? e.id : null);
                }}
                label={"Select User"}
                value={taskState?.user?.get()}
                cls="select_basic"
                size="small"
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && !taskState.user.get()}
          />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent title="Close" startIcon={<CloseIcon />} variant="contained" onClick={closeTask} loadingbtn={true} style={closeBtn} />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={saveTask}
          // disabled={disableSave()}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default TaskModal;
