import { Downgraded, useState } from "@hookstate/core";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import HubIcon from "@mui/icons-material/Hub";
import FilterIcon from "@mui/icons-material/Sort";
import { Box, Button, Grid, List, ListItem, ListItemButton, Menu, MenuItem, Paper, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { CheckBoxComponent } from "../../../components/InputComponents/CheckBoxComponent/CheckBoxComponent";
import { SearchSuffixComponent } from "../../../components/OtherComponents/DecisionEngineComponent/subComponents/SearchComponent";
import { openGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { addToaster } from "../../../components/OtherComponents/GlobalToast";
import { CircularProgressComponent } from "../../../components/OtherComponents/ProgressComponent/ProgressComponent";
import { bankCode } from "../../../configs/constants/contants";
import { branchDetails, masterDataDetails } from "../../../configs/mainStore";
import { fetchUsersBranches, saveBranchById } from "../../../services/configurationApis";
import { getUsersByBranchId } from "../../../services/userPrivilegeService";
import { compare } from "../../../utility/other";
import { branchCodeMap } from "../../../utility/util";

function Branches({ user, willAssignBranches }) {
  const masterData: any = useState(masterDataDetails);
  const zoneMasterData: any = masterData?.ZONE?.get();

  const searchText = useState("");

  const branches: any = useState([]);

  const updating: any = useState(false);
  const sorting: any = useState(false);
  const loading: any = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [activeBranchesArr, setActiveBranchesArr] = React.useState<any>([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };


  const processSearchText = (text) => {
    searchText.set(text);
  };

  const loadBranches = () => {
    const branchState: any = branchDetails?.attach(Downgraded).get();
    let branchList = branchState?.branchedByInstitutions[bankCode];
    branchList = branchList?.map((obj) => {
      return { ...obj, checked: false };
    });
    branches.set(branchList);

    extractBranches(user);
  };

  useEffect(() => {
    setActiveBranchesArr([]);
    loadBranches();
  }, [user]);

  const selectAllRowClicked = (e) => {
    if (e.target.checked) {
      let branches: any = [];
      filteredBranches().forEach((element: any) => {
        branches.push(element.id);
      });
      setActiveBranchesArr(branches);
    } else {
      setActiveBranchesArr([]);
    }
  };

  const updateActiveBranch = (status, id) => {
    let branches: any = [];
    if (activeBranchesArr.includes(id)) {
      branches = activeBranchesArr.filter((b) => parseInt(b) !== id);
    } else {
      branches = [...activeBranchesArr, id];
    }
    setActiveBranchesArr(branches);
  };

  const handleSave = async () => {
    if (willAssignBranches) {
      updating.set(true);
      const proceed = await willAssignBranches(activeBranchesArr, updating);
      updating.set(false);
      if (proceed === false) {
        return;
      }
    }

    let obj = {
      userId: user,
      branchList: activeBranchesArr,
    };

    if (!user) {
      addToaster({
        status: "error",
        title: "Validation Error",
        message: "User Id not found!",
      });
      return;
    }

    try {
      updating.set(true);
      const res = await saveBranchById(obj);
      if (res?.status === 1) {
        addToaster({
          status: "success",
          title: "Updated",
          message: `successfully updated !`,
        });

      } else {
        addToaster({
          status: "warning",
          title: "No Changes",
          message: "Do not have changes to update!",
        });
      }
    } catch (error) {
      addToaster({
        status: "error",
        title: "Error",
        message: "Failed to update permitted branches.",
      });
    } finally {
      updating.set(false);
    }
  };

  const filteredBranches = () => {
    let _branches: any = [];

    _branches = branches.get && branches.attach(Downgraded).get() || [];

    _branches = _branches.sort((a, b) => sorting.get() == true ? compare(b, a, "name") : compare(a, b, "name"));

    _branches = _branches.filter((b: any) => b?.name?.toLowerCase().includes(searchText.get()) || b?.id?.toString().includes(searchText.get()))
      .reduce((objs, element) => {
        if (activeBranchesArr.includes(element.id)) {
          return [element, ...objs];
        }
        return [...objs, element];
      }, []);
    return _branches;
  };

  async function extractBranches(index: any) {
    loading.set(true);
    const branchData = await fetchUsersBranches(index);
    const branchDataResponse = branchData?.data ?? [];
    let checkedBranchIds: any = [];
    if (branchDataResponse && branchDataResponse.length > 0) {
      branchDataResponse.forEach((item) => {
        checkedBranchIds.push(item.BRANCH_ID);
      });
    }
    if (checkedBranchIds.length > 0) {
      setActiveBranchesArr(checkedBranchIds);
    }
    loading.set(false);
  }

  const handleSort = () => {
    const state = sorting.get();
    sorting.set(!state);
  };

  const handleListItemClick = async (index) => {
    setSelectedIndex(index);
    try {
      updating.set(true);

      let userListData = await getUsersByBranchId(index);

      let groupedData: any = [];
      let groupNames = {};

      userListData.forEach((item) => {
        groupedData[item.groupId] = groupedData[item.groupId] || [];
        groupedData[item.groupId].push(item);
        groupNames[item.groupId] = item.groupName;
      });
      updating.set(false);

      return { groupNames, groupedData };
    } catch (error) {
      updating.set(true);
    }
  };

  const viewUsers = async (modalBodyKey, group) => {
    const data: any = await handleListItemClick(group.id);
    openGlobalModal({
      modalSize: "sm",
      title: "",
      bodyId: modalBodyKey,
      close: false,
      modalParams: {
        list: data.groupedData,
        group,
        groupNames: data.groupNames,
      },
    });
  };

  const callBackOnAction = (e) => {
    const branchState: any = branchDetails?.attach(Downgraded).get();
    const { zoneMap } = branchState;

    const zoneByBranch = zoneMap[e.id] || [];

    setActiveBranchesArr(zoneByBranch);
  };

  return (
    <Paper elevation={0}>
      <Box p={1}>
        <Grid container className="align-center" columns={8} pl={1} pr={1}>
          <Grid item xs={8} sm={8} className="align-center">
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
              <Stack justifyContent="flex-start" direction="row">
                <CheckBoxComponent onChange={selectAllRowClicked} />
                <Stack direction="column" alignItems="flex-start">
                  <Typography color="gray">Branches</Typography>
                  <Typography variant="caption" color="gray">
                    {`Permitted Branches (${activeBranchesArr.length}/${branches?.length})`}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems={"flex-end"}>
                {updating.get() ? (
                  <CircularProgressComponent size={24} />
                ) : (
                  <ButtonComponent
                    disabled={updating.get()}
                    variant="contained"
                    loadingbtn={false}
                    loading={false}
                    onClick={handleSave}
                    title={"Save"}
                  />
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box p={1} marginTop={-3}>
        <div className="relative w-[200px] h-[40px] items-center justify-center">
          <div>
            <Box m={2}>
              <SearchSuffixComponent
                placeholder={undefined}
                handleChangeSearchText={processSearchText}
                right={
                  <Stack direction={"row"} style={{ paddingRight: "10px" }} >
                    <Stack>
                      <Button
                        size="small"
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        sx={{ padding: "0px", width: "24pt" }}
                      >
                        <HubIcon fontSize="small" />
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={(e) => setAnchorEl(null)}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        {zoneMasterData.map(zone => <MenuItem onClick={() => { setAnchorEl(null); callBackOnAction(zone) }}>{zone.name}</MenuItem>)}
                      </Menu>
                    </Stack>
                    <FilterIcon sx={{ marginLeft: "10px" }} onClick={handleSort} color={sorting.get() ? "primary" : "disabled"} />
                  </Stack>
                }
              />
            </Box>
          </div>
        </div>

        {loading.get() ? (
          <Box sx={{ height: "200px" }} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <CircularProgressComponent size={25} />
          </Box>
        ) : (
          <List component="nav">
            <div
              className="basic-font basic-font-color font-size-15 font-weight-100"
              style={{
                marginBottom: 0,
                marginTop: 0,
                overflowX: "hidden",
                overflowY: "scroll",
                maxHeight: "calc(100vh - 350px)",
              }}
            >
              {filteredBranches().map((b: any, index) => {
                return (
                  <ListItem divider key={index}>
                    <ListItemButton
                      selected={selectedIndex === b.id}
                      onClick={(event) => {
                        handleListItemClick(b.id);
                      }}
                    >
                      <Grid container item xs={12}>
                        <Grid item xs={12}>
                          <Stack direction={"row"} justifyContent={"space-between"}>
                            <Grid
                              item
                              xs={12}
                              alignSelf="center"
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <CheckBoxComponent
                                checked={activeBranchesArr.includes(parseInt(b.id)) ? true : false}
                                onChange={(e) => updateActiveBranch(e.target.checked, parseInt(b.id))}
                              />
                              <Stack
                              >
                                {b.name}
                                <Typography variant="caption">({branchCodeMap[b.name] || ""})</Typography>
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Typography m={1} mb={1} variant={"caption"}>
                                {b.id}
                              </Typography>
                              <Tooltip title="View Users">
                                <GroupAddIcon
                                  className="icon-basic basic-font-color"
                                  onClick={() => {
                                    viewUsers("user-configeration-branch-modal", b);
                                  }}
                                />
                              </Tooltip>
                            </Grid>
                          </Stack>
                        </Grid>
                      </Grid>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </div>
          </List>
        )}
      </Box>
    </Paper>
  );
}
export default Branches;
