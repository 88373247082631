import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Grid, Stack } from "@mui/material";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { securityDocumentPermissions } from "../../../../../../configs/constants/contants";
import { disableAccess } from "../../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SinglePageBreak } from "../CommonPageBreaks";
import CommonSecurityDocHeader from "../CommonSecurityDocHeader";

export const CardHolderAndBankOfficerDeclaration = ({ data }) => {
    const componentRef: any = useRef();
    const [typeCaseClass, settypeCaseClass] = React.useState();

    const {
        applicationId,
        approvedLoanAmount,
        amount,
        trialData: { trailCalParams = {} } = {},
    } = (data as any);

    const documentId = securityDocumentPermissions.loanReceipt || "receipt";

    let loanAmount = 0;

    if (!loanAmount) {
        const { LoanAmount: _loanAmount, loanAmount: __loanAmount } = trailCalParams;
        loanAmount = _loanAmount || __loanAmount;
    }

    if (!loanAmount) {
        loanAmount = approvedLoanAmount || amount;
    }

    const noPermissionPrint = disableAccess({
        documentComponentId: documentId,
        privilegeKeyforSec: "SEC_DOC_PRINT_SECURITY_DOC",
    });

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
    @media print {
      #Header, #Footer {
        display: none !important;
      }
    }
  `,
    });

    const getPageMargins = () => {
        return `@page { margin: ${"7mm"} ${"10mm"}  ${"15mm"} ${"15mm"} !important; }`;
    };

    return (
        <Grid container className="inner-component-full-height basic-font">
            <style>{getPageMargins()}</style>
            <div className="full-width full-height">
                <Grid container pt={2} pr={2} pl={2} pb={1} ref={componentRef} columns={24}>
                    <CommonSecurityDocHeader documentName={"CARDHOLDER DECLARATION"} textCase={"lower"} />
                    <Grid item xs={24} mt={3}>
                        <Stack direction="row" spacing={1} justifyContent="space-Between" p={1} alignItems="center">
                            <Grid item style={{ textAlign: "justify" }}>
                                <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                                    This declaration is made to National Development Bank PLC (NDB Bank)
                                </div>
                                By signing below, I/We ask that an account be opened for me/us and Card(s) issued as requested and that you renew and replace them until I/We surrender my/our right to use the Card(s) by cutting the Card(s) in half and returning both pieces to you. I/We authorize your bankers or any other sources to release any information to you or your representatives that you may require from time to time without reference to me/us. I/We agree that my/our Credit Card(s) may be only used subject to the terms and conditions of the Credit Cardholder Agreement, ATM, and other account terms and conditions issued by the NDB Bank.
                                I/We further agree to accept and be bound by the terms and conditions of the Credit Cardholder Agreement issued by the NDB Bank. I/We accept that the usage of the new Credit Card will be construed by the NDB Bank as acceptance of the terms and conditions by me/us. I/We am/are aware that deposits or transfers to my/our Credit Card account(s) or temporary limit increases will not increase my/our cash advance limit.
                                I/We am/are aware that certain ATM machine/bank counter restrictions may apply to the usage of my/our Credit Card(s) in Sri Lanka and overseas. I/We am/are aware that the NDB Bank may change my correspondence address if delivery cannot be made to my preference. I/We agree not to use my/our Credit Card(s) overseas to purchase goods in commercial quantities and for the transfer of capital out of Sri Lanka.
                                I/We affirm that I/We shall surrender my/our Credit Card(s) to the NDB Bank and settle all dues in full in the event I/We migrate or leave Sri Lanka for overseas employment. I/We agree to be liable jointly and severally for all charges to the Primary and Supplementary Card(s) issued on my/our request.
                                I/We accept that the NDB Bank is entitled to communicate to customers by way of post cards, fax transmissions, e-mails, telegrams, and SMS. I/We hereby warrant that the above information given in this application is true and correct. I/We accept that Credit Card(s) will be issued at the sole discretion of the NDB Bank.
                                I/We hereby confirm that I/We am/are aware of the conditions imposed under the Exchange Control Act in the notice published in the Extraordinary Gazette No. 1950/40 dated 20th January 2016, subject to which the Credit Card(s) may be used for transactions in foreign exchange, and I/We hereby undertake to abide by the said conditions.
                                <br /><br /><br />
                                <SinglePageBreak />
                                <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                                    Declaration by the Applicant/s for Electronic Fund Transfer Cards
                                </div>
                                I / We,....................................................................................................(Basic Cardholder/Supplementary Cardholder),.................................................................(Basic Cardholder/Supplementary Cardholder),
                                declare that all details given above by me/us on this form are true and correct.
                                <br />
                                I/We hereby confirm that I/We am/are aware of the conditions imposed under the Exchange Control Act Notice published in the Extraordinary Gazette No. 1950/40 dated 20th January 2016 subject to which the Credit Card(s) may be used for transactions in foreign exchange and I/We hereby undertake to abide by the said conditions.
                                <br />
                                I/We further agree to provide any information on transactions carried out by me/us in foreign exchange on the card issued to me/us as ............................................ bank may require for the purpose of Exchange Control Act.
                                <br />
                                I/We also affirm that I/We undertake to surrender the Credit Card/s to .................................................. bank if I/We migrate or leave Sri Lanka for employment abroad.
                                <br />
                                I/We am/are aware that the Authorized Dealer is required to suspend availability of foreign exchange on EFTC if reasonable grounds exist to suspect that unauthorized foreign exchange transactions are being carried out on the EFTC issued to me/us.
                                <br /><br />
                                The information furnished in the Credit Card application form and the declaration above were read and understood by me/us prior to placing my/our signature(s).
                                <br /><br />
                                I hereby confirm to have read and understood the Terms & Conditions of both the Credit Card & E-statement facility.
                            </Grid>
                        </Stack>
                    </Grid>
                    <Grid item xs={24} mt={3} p={1}>
                        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                            {["Signature of the Basic Cardholder", "Signature of the Supplementary Cardholder", "Date"].map((name, index) => (
                                <Grid item xs={4} key={index} style={{ textAlign: "center" }}>
                                    <div style={{ borderBottom: "1px dotted", marginTop: "10px", marginBottom: "10px" }}>&nbsp;</div>
                                    <div style={{ fontWeight: "bold" }}>{name}</div>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <br></br>
                <hr />
                <Grid item xs={24} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
                    <div className="basic-font-color-bold font-size-16" style={{ margin: "auto" }}>
                        BANK OFFICER'S DECLARATION
                    </div>
                </Grid>
                <Grid item xs={24} mt={3}>
                    <Stack direction="row" spacing={1} justifyContent="space-Between" p={1} alignItems="center">
                        <Grid item style={{ textAlign: "justify" }}>
                            I ................................................................. have carefully examined the information together with relevant documents submitted by ................................................................. and satisfied myself that the said information and documents are in conformity with Exchange Control requirements and the internal policies of the Bank. The Bank undertakes to exercise due diligence on the transactions carried out by the Cardholder on his/her EFTC in foreign exchange and to suspend the availability of foreign exchange on the EFTC if reasonable grounds exist to suspect that unauthorized foreign exchange transactions are being carried out on the EFTC in violation of the undertaking given by the Cardholder and to bring the matter to the notice of the Controller of Exchange.
                        </Grid>
                    </Stack>
                </Grid>
                <Grid item xs={24} mt={3} p={1}>
                    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                        {["Signature of the Bank Officer", "Date"].map((name, index) => (
                            <Grid item xs={4} key={index} style={{ textAlign: "center" }}>
                                <div style={{ borderBottom: "1px dotted", marginTop: "10px", marginBottom: "10px" }}>&nbsp;</div>
                                <div style={{ fontWeight: "bold" }}>{name}</div>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <br></br>
                <hr />
                <Stack m={1} direction={"row-reverse"}>
                    <ButtonComponent
                        startIcon={<LocalPrintshopIcon />}
                        title={"Print PDF"}
                        variant="contained"
                        onClick={() => {
                            handlePrint();
                        }}
                        style={{ maxHeight: "40px", marginTop: "auto" }}
                        disabled={noPermissionPrint}
                        loadingbtn={true}
                        loading={false}
                    />
                </Stack>
            </div>
        </Grid>
    );
};