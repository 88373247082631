import { Grid } from '@mui/material';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { formatCurrency } from '../../../services/currencyFormater';
import { CheckBoxComponent } from '../../InputComponents/CheckBoxComponent/CheckBoxComponent';
import { SelectComponent } from '../../InputComponents/SelectComponent/SelectComponent';

export const MetaDataFieldRow = ({ label, value }) => {
  return (
    <Grid container item direction="row" xs={12} mb={1} pr={3}>
      <Grid container item sm={5}>
        <div className="basic-font basic-font-color opacity-7 font-size-14">{label}</div>
      </Grid>
      <Grid container item sm={7}>
        <div className="basic-font blue-font-color opacity-7 font-size-14">{value}</div>
      </Grid>
    </Grid>
  );
};

export const NumericDataInputFieldRow = ({
  label,
  value,
  maxValue,
  minValue,
  onChange,
  thousandSeparator = true,
  xs = 2,
  isLoading,
}) => {
  return (
    <Grid container item direction="row" xs={xs} mb={1} pr={3}>
      <Grid container item sm={12}>
        <div className="basic-font basic-font-color opacity-7 font-size-14">{label}</div>
      </Grid>
      <Grid container item sm={12}>
        <NumericFormat
          className="trail-calculate-input"
          thousandSeparator={thousandSeparator}
          value={formatCurrency(value)}
          decimalScale={3}
          onValueChange={(e) => {
            onChange(e);
          }}
          isAllowed={(values) => {
            const { formattedValue, floatValue } = values;
            if (floatValue == null) {
              return formattedValue === '';
            } else {
              return floatValue <= maxValue && floatValue >= minValue;
            }
          }}
          disabled={isLoading}
        />
      </Grid>
    </Grid>
  );
};

export const SelectMethodsFieldRow = ({ label, dropdownLanguageList, value, onChange, xs = 3, isLoading }) => {
  return (
    <Grid container item direction="row" xs={xs} mb={1} pr={3}>
      <Grid container item sm={12}>
        <div className="basic-font basic-font-color opacity-7 font-size-14">{label}</div>
      </Grid>
      <Grid container item sm={12}>
        <SelectComponent
          className={'trail-calculate-select'}
          defaultPlaceholder="Select"
          value={dropdownLanguageList?.length > 0 ? (value ? value : '') : ''}
          values={dropdownLanguageList}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          disabled={isLoading}
        />
      </Grid>
    </Grid>
  );
};

export const OtherFeesFieldRow = ({ id, title, subTxt, value, maxValue, minValue, onAmountChange, isUpdating }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [amount, setAmount] = useState(value);

  const changeCheckbox = (value) => {
    setIsChecked(value);

    if (value) {
      onAmountChange(id, amount);
    } else {
      onAmountChange(id, 0);
    }
  };

  const amountChange = (value) => {
    setAmount(value?.floatValue);
    onAmountChange(id, value?.floatValue);
  };

  return (
    <Grid container item direction="row" xs={12} mb={1}>
      <Grid container item sm={1}>
        <CheckBoxComponent
          checked={isChecked}
          onChange={() => {
            changeCheckbox(!isChecked);
          }}
          disabled={isUpdating}
        />
      </Grid>
      <Grid container item sm={4}>
        <p className="basic-font basic-font-color opacity-7 font-size-14">{title}</p>
      </Grid>
      <Grid container item sm={3}>
        <NumericFormat
          className="trail-calculate-input"
          thousandSeparator={true}
          value={value ? formatCurrency(value, 2) : 0}
          decimalScale={2}
          onValueChange={(e) => {
            amountChange(e);
          }}
          prefix="LKR "
          disabled={!isChecked || isUpdating}
          isAllowed={(values) => {
            const { formattedValue, floatValue } = values;
            if (floatValue == null) {
              return formattedValue === '';
            } else {
              return floatValue <= maxValue && floatValue >= minValue;
            }
          }}
        />
      </Grid>
      <Grid container item sm={4} justifyContent="flex-end">
        <p className="basic-font basic-font-color opacity-7 font-size-14">{subTxt}</p>
      </Grid>
    </Grid>
  );
};
