import { Downgraded, useHookstate, useState } from "@hookstate/core";
import CancelIcon from "@mui/icons-material/CancelRounded";
import ClearIcon from "@mui/icons-material/Clear";
import RemoveIcon from "@mui/icons-material/RemoveCircle";
import SendIcon from "@mui/icons-material/Send";
import {
  Divider,
  Grid,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect } from "react";
import {
  excludesTabs,
  workflowMasterData
} from "../../../../configs/mainStore";
import CreateTab from "../../../../layouts/PrivilegeConfigs/Components/AddNewTab";
import { deleteTab } from "../../../../services/workflowApiCalls";
import { capitalize } from "../../../../utility/fomatters";
import { compare } from "../../../../utility/other";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { CheckBoxComponent } from "../../../InputComponents/CheckBoxComponent/CheckBoxComponent";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { addToaster } from "../../GlobalToast";
import { CircularProgressComponent } from "../../ProgressComponent/ProgressComponent";

const WorkflowStageControl = ({
  onSave,
  loading,
  updating,
  stages,
  removeTabData,
  onUpdateChange,
  workFlow
}) => {
  const [openAddTab, setOpenAddTab] = React.useState<any>(false);
  const [editTab, setEditTab] = React.useState<any>(false);

  const { wf_stages }: any = useState(workflowMasterData);

  const _stages: any = useHookstate([]);
  let lock = true;

  useEffect(() => {
    return () => {
      lock = false;
    };
  }, []);

  useEffect(() => {
    if (lock) {
      if (!_stages.get().length) {
        _stages.set(stages);
      }
    }
  }, [stages]);

  const BuildButton = ({ updating }) => {
    if (_stages.get().length > 0) {
      return (
        <Grid item xs={8} sm={8} textAlign="end" mt={1}>
          {editTab && <ButtonComponent
            startIcon={
              updating.get() ? (
                <CircularProgressComponent size={16} />
              ) : (
                <SendIcon />
              )
            }
            title={"Submit"}
            variant="contained"
            disabled={updating.get()}
            onClick={() => {
              onSave(_stages);
            }}
          />}
        </Grid>
      );
    }

    return <></>;
  };


  const handleAddTab = async () => {
    setOpenAddTab(true);
  };

  const BuildStages = ({ editTab, onUpdate }) => {
    const [selectedData, setSelectedData] = React.useState({
      freezeStage: null,
      startStage: null,
      stopStage: null,
      tabKey: null,
      workFlowId: null,
    });
    let tabKeyMap: any = {};
    const [deleteIndex, setDeleteIndex] = React.useState(-1);
    const [deleting, setDeleting] = React.useState(false);
    const [reloading, setReloading] = React.useState(false);
    const search: any = useHookstate({
      tabKey: null,
    });

    if (loading) {
      return (
        <Grid
          height={"200px"}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgressComponent size={30} />
        </Grid>
      );
    }

    if (!_stages || _stages.get().length === 0) {
      return (
        <Grid
          height={"200px"}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant="subtitle2" color={grey[500]}>
            No Data Available.
          </Typography>
        </Grid>
      );
    }

    let list = _stages;

    list = list.filter((obj) => {
      return !excludesTabs.includes(obj.tabKey.get());
    });

    list = list.sort((a, b) => compare(a.get(), b.get(), "tabKey"));

    tabKeyMap = list.reduce((obj, item) => {
      obj[item.tabKey.get()] = item.attach(Downgraded).get() ?? {};
      return obj;
    }, {});

    const heightStyle: any = { height: "calc(100vh - 350px)", overflowY: "auto" };

    const itemStyle: any = (selected) => {
      return { cursor: "pointer", backgroundColor: selected ? grey[200] : "white", borderRadius: 5 };
    };

    const handleTabSelection = (item) => {
      setSelectedData(item.attach(Downgraded).get());
    }

    const handleStartStage = async (stage, index) => {
      if (!editTab) {
        return;
      }

      const data = selectedData;
      data.startStage = stage?.id?.get();
      setSelectedData(data);

      setDeleteIndex(index);
      setDeleting(true);
      await onUpdate(selectedData);
      setDeleting(false);

      setReloading(true);
      let timeout = setTimeout(() => {
        setReloading(false);
        setDeleteIndex(-1);
        clearTimeout(timeout);
      }, 200);
    }

    const handleStopStage = async (stage, index) => {
      if (!editTab) {
        return;
      }

      const data = selectedData;
      data.stopStage = stage?.id?.get();
      setSelectedData(data);

      setDeleteIndex(index);
      setDeleting(true);
      await onUpdate(selectedData);
      setDeleting(false);

      setReloading(true);
      let timeout = setTimeout(() => {
        setReloading(false);
        setDeleteIndex(-1);
        clearTimeout(timeout);
      }, 200);
    }

    const handleDeleteTabKey = (stage, index) => {
      setDeleteIndex(index);

      if (deleteIndex !== -1) {
        handleDeleteEntry(stage.tabKey.get());
      }
    }

    const handleDeleteEntry = async (key) => {
      let wfId = selectedData?.workFlowId;
      let alertPayload = {
        status: "success",
        title: "Success",
        message: "Successfully deleted the tab key.",
      };
      try {
        setDeleting(true);
        await deleteTab(wfId, key);

        const items = _stages.attach(Downgraded).get().filter((item) => item.tabKey != key);
        _stages.set(items);
        setDeleteIndex(-1);
      } catch (error) {
        alertPayload = {
          status: "error",
          title: "Error",
          message: "Failed to update control tabs!",
        };
      } finally {
        addToaster(alertPayload);
        setDeleting(false);
      }
    };

    if (reloading) {
      return <Stack direction={"row"} flex={1} justifyContent={"center"} alignItems={"center"}><CircularProgressComponent size={30} /></Stack>;
    }

    return <Stack direction={"row"} flex={1}>
      <Stack style={heightStyle} p={1} flex={1}>
        <Stack style={{ top: -8, position: "sticky", zIndex: 100, backgroundColor: "white" }}>
          <TextBoxComponent value={search.tabKey.get() || ""} onChange={(e) => { search.tabKey.set(e.target.value) }} InputProps={{ endAdornment: <ClearIcon onClick={() => { search.tabKey.set(null) }} style={{ cursor: "pointer" }} /> }}></TextBoxComponent>
        </Stack>
        {list.filter(item => !search?.tabKey?.get() ? item : item?.tabKey?.get()?.toLowerCase()?.includes(search?.tabKey?.get()?.toLowerCase())).map((item, index) => <Stack key={index} onClick={() => handleTabSelection(item)} p={1} style={{ cursor: "pointer", backgroundColor: item.tabKey.get() === selectedData.tabKey ? grey[200] : "white", borderRadius: 5 }} borderBottom={1} borderColor={grey[100]}>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            {deleteIndex === index ? <Stack>
              <Typography variant="caption">Delete again to confirm delete '{capitalize(item.tabKey.get())}'</Typography>
            </Stack> : <Stack>
              <Typography>{capitalize(item.tabKey.get())}</Typography>
              <Typography variant="caption" color={"gray"}>{item.tabKey.get()}</Typography>
            </Stack>}
            {deleting && deleteIndex === index ? <CircularProgressComponent size={16} /> : <Stack direction={"row"} spacing={1}>
              {deleteIndex === index && <CancelIcon style={{ color: 'gray', fontSize: 20 }} onClick={() => setDeleteIndex(-1)} />}
              <RemoveIcon style={{ color: 'red', fontSize: 20 }} onClick={() => handleDeleteTabKey(item, index)} />
            </Stack>}
          </Stack>
        </Stack>)}</Stack>
      <Stack style={heightStyle} p={1} flex={1}>
        {selectedData?.tabKey && <Stack style={{ top: -8, position: "sticky", zIndex: 100, backgroundColor: "white" }} px={1}>
          <Typography variant="caption">Selected: {wf_stages.filter(stage => tabKeyMap?.[selectedData?.tabKey || '']?.startStage === stage.id.get())?.[0]?.name.get()}</Typography>
          <Divider />
        </Stack>}
        {wf_stages.map((stage, index) => <Stack p={1} key={`startStage_${index}`} style={itemStyle(selectedData.tabKey && tabKeyMap?.[selectedData.tabKey]?.startStage === stage.id.get())} onClick={() => handleStartStage(stage, index)}>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} borderBottom={1} borderColor={grey[100]}>
            <Typography variant="caption">{stage.name.get()}</Typography>
            <Typography variant="caption" color={"gray"}>{stage.id.get()}</Typography>
          </Stack>
        </Stack>)}
      </Stack>
      <Stack style={heightStyle} p={1} flex={1}>
        {selectedData?.tabKey && <Stack style={{ top: -8, position: "sticky", zIndex: 100, backgroundColor: "white" }} px={1}>
          <Typography variant="caption">Selected: {wf_stages.filter(stage => tabKeyMap?.[selectedData?.tabKey || '']?.stopStage === stage.id.get())?.[0]?.name.get()}</Typography>
          <Divider />
        </Stack>}
        {wf_stages.map((stage, index) => <Stack p={1} key={`endStage_${index}`} style={itemStyle(selectedData.tabKey && tabKeyMap?.[selectedData.tabKey]?.stopStage === stage.id.get())} onClick={() => handleStopStage(stage, index)}>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} borderBottom={1} borderColor={grey[100]}>
            <Typography variant="caption">{stage.name.get()}</Typography>
            <Typography variant="caption" color={"gray"}>{stage.id.get()}</Typography>
          </Stack>
        </Stack>)}
      </Stack>
    </Stack>;
  };

  return (
    <Paper elevation={0}>
      <CreateTab
        openAddTab={openAddTab}
        setOpenAddTab={setOpenAddTab}
        workflowData={workFlow}
      ></CreateTab>
      <Stack>
        <Stack direction="row" my={1} alignItems={"center"} justifyContent={"space-between"}>
          <Stack flex={1}>
            <Typography color={grey[600]} flex={1}>
              Assign Tab Control Stages
            </Typography>
            <Typography variant="caption" color={"gray"}>
              {workFlow.workflowName || ""}
            </Typography>
          </Stack>

          <Stack direction="row-reverse" spacing={2} alignItems={"center"} width={"400px"}>
            <Stack direction={"row"}>
              <ButtonComponent
                size={"small"}
                fullWidth={true}
                variant="contained"
                color={"success"}
                title={"Add Tab"}
                onClick={handleAddTab}
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography color={"gray"} variant="caption">Edit Tab</Typography>
              <CheckBoxComponent value={editTab} onChange={(e) => setEditTab(e.target.checked)} />
            </Stack>
          </Stack>
        </Stack>
        <Divider></Divider>
        <Stack>
          <BuildStages editTab={editTab} onUpdate={onUpdateChange}></BuildStages>
          <Divider style={{ paddingTop: 10 }}></Divider>
          <BuildButton updating={updating}></BuildButton>
        </Stack>
      </Stack>
    </Paper >
  );
};

export default WorkflowStageControl;
