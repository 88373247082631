import { useState } from "@hookstate/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Divider, Stack } from "@mui/material";
import React, { createRef, useEffect } from "react";
import { userDetails } from "../../../../../../../../configs/mainStore";
import { documentUploadData } from "../../../../../../../../configs/stores/document-upload-store/documentUploadStore";
import { getInspectionDocuments } from "../../../../../../../../services/documentApis";
import { getDocumentDetailsList } from "../../../../../../../../services/documentUploadService";
import { ButtonComponent } from "../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";
import { InspectionDocumentUploadComponent } from "./FileUploadComponent/InspectionFileUploadComponent";

const BusinessBorrowerRoadMap = ({ metaData, value, setOpen }) => {
  const titleArray = metaData?.title.split("/");
  const { individualId } = value;
  const refreshMemo = useState(true);
  const [status, setStatus] = React.useState(-1);
  const loginUser = useState(userDetails);
  const { documentDetailsList }: any = useState(documentUploadData);
  const docUploadRef: any = createRef();
  const [refresh, setRefresh] = React.useState(false);
  const [fileList, setFileList] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const addDocumentdDefault = {
    ref: docUploadRef,
    isMandatory: true,
    allowedFormats: ["application/pdf", "image/jpg", "image/jpeg", "image/png"],
    maxFileSize: 4000000,
    doc_index: 1,
    status,
  };

  const defaultDocumentId = "IMAGES_DOCUMENTS_UPLOAD";

  useEffect(() => {
    getDocumentsListData();

    return () => {
      documentDetailsList.set({});
      setFileList([]);
    };
  }, []);

  const getDocumentsListData = async () => {
    let data = {};

    if (Object.values(documentDetailsList.get()).length === 0) {
      setIsLoading(true);

      let dataPayLoad = await getDocumentDetailsList();
      if (Object.values(dataPayLoad).length > 0) {
        data = dataPayLoad;
        documentDetailsList.set(dataPayLoad);
      }
      setIsLoading(false);
    }

    let payload = {
      applicationId: value?.applicationId,
      inspectionId: value?.inspetionId ?? null,
    };

    const documentId = value.isLocationSameAsPA
      ? getDocumentId(data, defaultDocumentId)
      : getDocumentId({}, defaultDocumentId);

    if (!!value?.applicationId) {
      setIsLoading(true);

      let uploadedDocument = await getInspectionDocuments(payload);

      uploadedDocument = individualId
        ? uploadedDocument?.filter((item) => item.participant_id === individualId.toString())
        : uploadedDocument?.filter((item) => item.participant_id === "undefined");

      if (uploadedDocument?.length > 0) {
        uploadedDocument?.forEach((obj) => (obj.ref = React.createRef()));
        const reducedData = uploadedDocument?.reduce((acc, obj) => {
          const key = `${obj["document_catalogue"]}`;
          (acc[key] = acc[key] || []).push(obj);
          return acc;
        }, {});

        if (reducedData[documentId]?.length > 0) {
          setFileList(reducedData[documentId]);
        } else {
          setFileList([addDocumentdDefault]);
        }

        setIsLoading(false);
      } else {
        setFileList([addDocumentdDefault]);
      }

      setIsLoading(false);
    }
  };

  const getDocumentId = (data = {}, stringDocumentId = titleArray[0]) => {
    let _stringDocumentId = stringDocumentId;
    let pureObject = {};
    _stringDocumentId = titleArray[0];

    if (Object.values(data).length > 0) {
      pureObject = data;
    } else {
      pureObject = JSON.parse(JSON.stringify(documentDetailsList.value));
    }

    let documentId = "";

    Object.values(pureObject).forEach((doc: any) => {
      if (doc.documentKey === _stringDocumentId) {
        documentId = doc.documentId;
      }
    });

    refreshMemo.set(false);
    // setDocumentId(documentId);
    return documentId;
  };

  const onClickAddBtn = () => {
    setStatus(-1);
    let tempFileList = fileList;
    tempFileList.push({ ...addDocumentdDefault, doc_index: fileList.length + 1 });

    setFileList(tempFileList);
    setRefresh(!refresh);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = async () => {
    refreshMemo.set(true);

    const promises: any = [];

    fileList?.map((item: any) => {
      promises.push(item.ref.current?.uploadSingleImg());
    });

    return await Promise.all(promises).then(() => {
      return true;
    });
  };

  return (
    <Stack spacing={2} mt={1} flex={1}>
      <Stack direction={"row"}>
        <div
          className="basic-font basic-font-color font-size-16 font-weight-100"
          style={{ marginBottom: 0, marginTop: 0, marginRight: 10 }}
        >
          Upload Inspection File
        </div>
        <AddCircleIcon style={{ color: "gray", fontSize: 25, cursor: "pointer" }} onClick={onClickAddBtn} />
      </Stack>

      <Divider></Divider>
      {isLoading ? (
        <Stack direction={"row"} justifyContent={"center"}>
          <CircularProgressComponent size={30} sx={{ top: "50%", right: "50%", position: "center", zIndex: 100 }} />
        </Stack>
      ) : (
        <Stack direction={"row"}>
          {fileList?.map((item, i) => (
            <InspectionDocumentUploadComponent
              fileDetails={item}
              uploadDataObject={{
                id: item?.id,
                applicationId: value?.applicationId,
                inspectionId: value?.inspetionId ?? null,
                createdBy: loginUser?.userId?.get(),
                participantId: value?.individualId,
                docIndex: item.doc_index,
                documentId: getDocumentId({}, defaultDocumentId),
                documentCatalog: 9,
              }}
              isPreviewHave={item.status != -1 ? true : false}
              status={item.status}
              onNext={fileList}
              reloadComponent={getDocumentsListData}
            />
          ))}
        </Stack>
      )}
      <Divider></Divider>

      <Stack direction={"row-reverse"} spacing={2}>
        <ButtonComponent variant={"outlined"} color={"info"} title={"Close"} onClick={handleClose} />
        <ButtonComponent variant={"contained"} color={"primary"} title={"Upload"} onClick={handleOk} />
      </Stack>
    </Stack>
  );
};

export default BusinessBorrowerRoadMap;
