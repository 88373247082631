import { Divider } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useEffect } from "react";
import { ButtonComponent } from "../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { ErrorMessageComponent } from "../../../../../ErrorMessageComponent";
import { CircularProgressComponent } from "../../../../../ProgressComponent/ProgressComponent";
import IntermediateFormBuilder from "../../FormBuilder/IntermediateFormBuilder";
import { CreditInspectionScoreCardQuestionProvider, ScoreCardQuestionsProviderProps, useScoreCardQuestions } from "./ScoreCardQuestionsProvider";

const ScoreCardQuestions = () => {
  const { initializeScoreCardQuestionLayout, scoreCardLayout,
    isLoading, currentSelectedButton, setCurrentSelectedButton, otherParams, onChangeValues, formDetails, clearAllSubcriptions, errorStatus, layoutMap
  }: ScoreCardQuestionsProviderProps = useScoreCardQuestions();

  useEffect(() => {
    initializeScoreCardQuestionLayout();

    return () => {
      clearAllSubcriptions()
    }
  }, []);

  if (isLoading.get()) {
    return <Box sx={{ height: "200px" }} display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <CircularProgressComponent size={25} />
    </Box>
  }

  if (errorStatus) {
    return <ErrorMessageComponent headMessage={"Error"} errorMessage={'No Score card Questions to show!'} />
  }

  return (
    <Box sx={{ width: "100%" }} mt={2}>
      <Stack direction="row" spacing={1} p={2}>
        {Object.keys(scoreCardLayout).length > 0 &&
          Object.keys(scoreCardLayout).map((key, index) => (
            <Stack flex={1} width={'100%'} key={index}>
              <ButtonComponent
                variant={currentSelectedButton === key ? "contained" : "outlined"}
                onClick={() => setCurrentSelectedButton(key as string)}
                title={layoutMap.get(key)?.name || ""}
              />
            </Stack>
          ))}

        { }
      </Stack>
      <Divider />
      <Stack p={2}>
        {scoreCardLayout[currentSelectedButton] ? <IntermediateFormBuilder {...{
          formChildren: scoreCardLayout[currentSelectedButton],
          otherParams,
          onChange: onChangeValues,
          value: formDetails[currentSelectedButton] ?? {},
        }} /> : <></>}
      </Stack>
    </Box>
  );
};

const ScoreCardQuestionsWrapper = (props) => <CreditInspectionScoreCardQuestionProvider {...props}>
  <ScoreCardQuestions />
</CreditInspectionScoreCardQuestionProvider>

export default ScoreCardQuestionsWrapper;
