import { Box } from '@mui/material';
import { Fragment } from 'react';

export const CMTextInput = ({ desc, component, error = false }) => {
  return (
    <Box style={{ marginTop: 8, marginBottom: 8 }}>
      <div
        className="basic-font basic-font-color font-size-16 font-weight-100"
        style={{ marginBottom: 0, marginTop: 0 }}
      >
        {desc} {component}
      </div>

      {error ? (
        <Box>
          <span className="error font-size-14">Required !</span>
        </Box>
      ) : (
        <Fragment />
      )}
    </Box>
  );
};
