import { useState } from "@hookstate/core"
import { Grid } from "@mui/material"
import { standardDateFormatter } from "los-util"
import { branchDetails } from "../../../../../../../../configs/mainStore"
import { figureFormat } from "../../../../../../../../utility/fomatters"
import ApplicationHeader from "../../../../Application/ApplicationHeader"
import DetailItem from "../../../../DetailItem"


const PreviousBorrowing = ({ data, applicationId, isMainContactDetails = true, mainTabData = { isAppraisal: false }, isRawVersion }) => {
  const previousBorrowings: any = data?.formData?.previousBorrowingsData
  const institutionState: any = useState(branchDetails)
  return (
    <div className="mainSectionWrapper full-width full-height">
      {isMainContactDetails && !isRawVersion && <ApplicationHeader formData={data?.formData} requestId={data?.requestId} applicationId={applicationId} title={"Reports on Previous Borrowings"} isAppraisal={mainTabData?.isAppraisal} isRawVersion={isRawVersion} />}
      <Grid container p={1} className="inner-component-height">
        <Grid container item p={1} style={{ backgroundColor: "#e6f0fa" }} className="border-radius-5 sub-header-height">
          <div className="basic-font basic-font-color-bold font-size-16 align-left">Reports on Previous Borrowings RDB or Other</div>
          <Grid container pt={1}>
            {previousBorrowings?.length > 0 &&
              previousBorrowings?.map((borrowing, index) => {
                const bankData: any = institutionState?.institutions?.get()?.find((institution) => institution?.id == borrowing?.bank)
                const branchData: any = institutionState?.branchedByInstitutions?.[borrowing?.bank].get()?.find((branch) => branch?.id == borrowing?.branch)
                return (
                  <Grid container p={1} className="data-box" key={index}>
                    <Grid container item mt={1}>
                      {
                        <Grid item xs={3}>
                          <DetailItem title="Bank Name" value={bankData?.name ? bankData.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                      }
                      {
                        <Grid item xs={3}>
                          <DetailItem title="Branch" value={branchData.name ? branchData.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                      }
                    </Grid>
                    <Grid container item mt={1}>
                      {
                        <Grid item xs={3}>
                          <DetailItem title="Loan Scheme" value={borrowing.loanScheme ? borrowing.loanScheme : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                      }

                      {
                        <Grid item xs={3}>
                          <DetailItem title="Loan Amount" value={borrowing.loanAmount ? borrowing.loanAmount : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                      }
                    </Grid>
                    <Grid container item mt={1}>
                      {
                        <Grid item xs={3}>
                          <DetailItem title="Loan Purpose" value={borrowing.loanPurpose ? borrowing.loanPurpose : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                      }
                      {
                        <Grid item xs={3}>
                          <DetailItem title="Granted Date" value={borrowing.grantedDate ? standardDateFormatter(borrowing.grantedDate) : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                      }
                    </Grid>
                    <Grid container item mt={1}>
                      {
                        <Grid item xs={3}>
                          <DetailItem title="Settled Date" value={borrowing.settledDate ? standardDateFormatter(borrowing.settledDate) : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                      }
                      {
                        <Grid item xs={3}>
                          <DetailItem title="Security Availability" value={borrowing.securityAvailability === 1 ? "Yes" : "No"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                      }
                    </Grid>
                    <Grid container item mt={1}>
                      {
                        <Grid item xs={3}>
                          <DetailItem title="Security Type" value={borrowing.securityType ? borrowing.securityType : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                      }
                      {borrowing?.currentArrears && (
                        <Grid item xs={3}>
                          <DetailItem title="Current Arrears" value={borrowing.currentArrears ? figureFormat(borrowing.currentArrears) : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                      )}
                    </Grid>
                    <Grid container item mt={1}>
                      {
                        <Grid item xs={12}>
                          <DetailItem title="Comment on previos / Existing loans" value={borrowing.moreInfoAbtRepayment ? borrowing.moreInfoAbtRepayment : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                      }
                    </Grid>
                    <Grid container item mt={1}>
                      {borrowing?.debtHandlingCapacity && (
                        <Grid item xs={12}>
                          <DetailItem title="Debt Handling Capacity" value={borrowing.debtHandlingCapacity ? borrowing.debtHandlingCapacity : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                      )}
                      {borrowing?.cribRecord && (
                        <Grid item xs={12}>
                          <DetailItem title="Crib Record" value={borrowing.cribRecord ? borrowing.cribRecord : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )
              })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default PreviousBorrowing
