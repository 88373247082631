import { Box, Divider, Grid, InputAdornment, Stack, Typography } from "@mui/material";
import { standardDateFormatter } from "los-util";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { PatternFormat } from "react-number-format";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandLengthValidator, OnDemandValueFormatter, OnDemandValueValidator } from "../../../../../../../../services/onDemandValidators";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";

export const WarehouseReceipt = (props) => {
  const { t: translate } = useTranslation();

  const { whrValueOfSecurity, whrSecuredValue, whrDescription, whrReceiptNo, whrLocation, whrCropDepositorName, whrNic, whrAddress, whrGrainDepositedInWarehouse, whrDepositedDate, whrPhoneNumber, whrQuantity, whrNoOfBags } = props.fields || {};

  return (
    <div className="full-width full-height">
      {/* <Grid container className="inner-component-height"> */}
      <Grid container>
        <Stack spacing={1} m={1} className="full-width">
          <Box mt={2} className="securityWrapper" p={2}>
            <Fragment>
              {props?.isNotNeedComponentTitle ? (
                <></>
              ) : (
                <InputFieldsStack>
                  <Typography variant="subtitle1" pl={2.5}>
                    Warehouse Receipt {props.index > 0 ? "(" + parseInt(props.index) + ")" : ""}
                  </Typography>
                </InputFieldsStack>
              )}
              <InputFieldsStack>
                <InputGrid visibility={whrValueOfSecurity?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.whrValueOfSecurity?.get())} onChange={(e) => props?.doc?.whrValueOfSecurity?.set(e.target.value)} label={translate("Value of the Security")} onInput={OnDemandValueFormatter.numeric} required={whrValueOfSecurity?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={whrSecuredValue?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.whrSecuredValue?.get())} onChange={(e) => props?.doc?.whrSecuredValue?.set(e.target.value)} label={translate("Secured Value")} onInput={OnDemandValueFormatter.numeric} required={whrSecuredValue?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={whrDescription?.visible}>
                  <TextBoxComponent value={props?.doc?.whrDescription?.get()} onChange={(e) => props?.doc?.whrDescription?.set(e.target.value)} label={translate("Description")} onInput={OnDemandValueFormatter.alphaNumericSpaces} required={whrDescription?.mandatory === 1 ? true : false} />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={whrReceiptNo?.visible}>
                  <TextBoxComponent value={props?.doc?.whrReceiptNo?.get()} onChange={(e) => props?.doc?.whrReceiptNo?.set(e.target.value)} label={translate("Receipt No")} onInput={OnDemandValueFormatter.numeric} required={whrReceiptNo?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={whrLocation?.visible}>
                  <TextBoxComponent value={props?.doc?.whrLocation?.get()} onChange={(e) => props?.doc?.whrLocation?.set(e.target.value)} label={translate("Location")} onInput={OnDemandValueFormatter.alphaNumericSpaces} required={whrLocation?.mandatory === 1 ? true : false} />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={whrCropDepositorName?.visible}>
                  <TextBoxComponent value={props?.doc?.whrCropDepositorName?.get()} onChange={(e) => props?.doc?.whrCropDepositorName?.set(e.target.value)} label={translate("Crop Depositor Name")} onInput={OnDemandValueFormatter.alphabeticalSpaces} required={whrCropDepositorName?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={whrNic?.visible}>
                  <TextBoxComponent value={props?.doc?.whrNic?.get()} onChange={(e) => props?.doc?.whrNic?.set(e.target.value)} label={"New NIC"} onInput={OnDemandLengthValidator.newNIC} required={whrNic?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={whrNic?.visible}>
                  <TextBoxComponent value={props?.doc?.whrNicOld?.get()} onChange={(e) => props?.doc?.whrNicOld?.set(e.target.value)} label={"Old NIC"} onInput={OnDemandLengthValidator.oldNIC} required={whrNic?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={whrPhoneNumber?.visible}>
                  <PatternFormat
                    format="(###) ### ####"
                    defaultValue={props?.doc?.whrPhoneNumber?.get()}
                    label={translate("Telephone no")}
                    fullWidth={true}
                    allowEmptyFormatting={false}
                    mask="_"
                    customInput={TextBoxComponent}
                    error={OnDemandValueValidator.phoneNumber(props?.doc?.whrPhoneNumber?.get())}
                    required={whrPhoneNumber.mandatory === 1 ? true : false}
                    onValueChange={(values, _) => {
                      props?.doc?.whrPhoneNumber?.set(values?.value);
                    }}
                  />
                </InputGrid>
                <InputGrid visibility={whrQuantity?.visible}>
                  <TextBoxComponent
                    value={formatCurrency(props?.doc?.whrQuantity?.get())}
                    onChange={(e) => props?.doc?.whrQuantity?.set(e.target.value)}
                    label={translate("Quantity")}
                    onInput={OnDemandValueFormatter.alphaNumericSpaces}
                    required={whrQuantity?.mandatory === 1 ? true : false}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">KG</InputAdornment>,
                    }}
                  />
                </InputGrid>
                <InputGrid visibility={whrNoOfBags?.visible}>
                  <TextBoxComponent value={props?.doc?.whrNoOfBags?.get()} onChange={(e) => props?.doc?.whrNoOfBags?.set(e.target.value)} label={translate("No of Bag")} onInput={OnDemandValueFormatter.numeric} required={whrNoOfBags?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={whrAddress?.visible}>
                  <TextBoxComponent value={props?.doc?.whrAddress?.get()} onChange={(e) => props?.doc?.whrAddress?.set(e.target.value)} label={translate("Address")} onInput={OnDemandValueFormatter.any} required={whrAddress?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={whrGrainDepositedInWarehouse?.visible}>
                  <TextBoxComponent value={props?.doc?.whrGrainDepositedInWarehouse?.get()} onChange={(e) => props?.doc?.whrGrainDepositedInWarehouse?.set(e.target.value)} label={translate("Grain Deposited in Warehouse")} onInput={OnDemandValueFormatter.alphaNumericSpaces} required={whrGrainDepositedInWarehouse?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={whrDepositedDate?.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.whrDepositedDate?.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.whrDepositedDate?.set(preparedDate);
                      }
                    }}
                    label={translate("Deposited Date (DD/MM/YYYY)")}
                    enableFuture={true}
                    disablePast={false}
                    required={whrDepositedDate?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
              </InputFieldsStack>
              <Divider />
            </Fragment>
          </Box>
        </Stack>
      </Grid>
    </div>
  );
};
