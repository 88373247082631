import {
  Box,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { MASTER_DATA_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { FieldSectionWrapper } from "../../../../../../FieldSectionsWrapper.tsx/FieldSectionWrapper";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import { InsuranceAvailableActivateState } from "../../SecurityTypeSubComponents/SecurityTypeSubComponent";

export const StockAsGold = (props) => {
  const { t: translate } = useTranslation();
  const masterDataStore: any = MasterDataProvider.provideMasterData();
  const masterDateStoreValues = {
    artilceTypeMasterData: masterDataStore?.getMasterData(
      MASTER_DATA_KEYS?.ARTICLE_TYPE
    ),
    karatageArticleMasterData: masterDataStore?.getMasterData(
      MASTER_DATA_KEYS?.KARATAGE_ARTICLE
    ),
  };

  const {
    sagValueOfSecurity,
    sagSecuredValue,
    sagDescription,
    sagNoOfJewelsOrPieces,
    sagArticleType,
    sagDescriptionOfTheOrnamentsOrArticle,
    sagKaratageOfArticle,
    sagGrossWeight,
    sagNetWeight,
    sagMarketRatePerGram,
    sagMarketValue,
    sagPermissibleRatePerGram,
    sagPermissibleValue,
  } = props.fields || {};

  return (
    <Box mt={2} className="securityWrapper">
      <FieldSectionWrapper>
        <InputFieldsStack>
          {props?.isNotNeedComponentTitle ? (
            <></>
          ) : (
            <Typography variant="subtitle1" pl={2.5}>
              Stock as Gold{" "}
              {props.index > 0 ? "(" + (parseInt(props.index) + 1) + ")" : ""}
            </Typography>
          )}
        </InputFieldsStack>
        <InputFieldsStack>
          <InputGrid visibility={sagValueOfSecurity.visible}>
            <TextBoxComponent
              value={formatCurrency(props?.doc?.sagValueOfSecurity.get())}
              onChange={(e) =>
                props?.doc?.sagValueOfSecurity.set(e.target.value)
              }
              label={translate("Value of the Security")}
              onInput={OnDemandValueFormatter.numeric}
              required={sagValueOfSecurity.mandatory === 1 ? true : false}
            />
          </InputGrid>
          <InputGrid visibility={sagSecuredValue.visible}>
            <TextBoxComponent
              value={formatCurrency(props?.doc?.sagSecuredValue.get())}
              onChange={(e) => props?.doc?.sagSecuredValue.set(e.target.value)}
              label={translate("Secured Value")}
              onInput={OnDemandValueFormatter.numeric}
              required={sagSecuredValue.mandatory === 1 ? true : false}
            />
          </InputGrid>
          <InputGrid visibility={sagDescription.visible}>
            <TextBoxComponent
              value={props?.doc?.sagDescription.get()}
              onChange={(e) => props?.doc?.sagDescription.set(e.target.value)}
              label={translate("Description")}
              onInput={OnDemandValueFormatter.alphaNumeric}
              required={sagDescription.mandatory === 1 ? true : false}
            />
          </InputGrid>
        </InputFieldsStack>

        <InputFieldsStack>
          <InputGrid visibility={sagNoOfJewelsOrPieces.visible}>
            <NumericFormat
              label={"No of Jewels/ Pieces"}
              customInput={TextBoxComponent}
              variant="outlined"
              thousandSeparator={true}
              value={formatCurrency(props?.doc?.sagNoOfJewelsOrPieces.get())}
              decimalScale={2}
              required={sagNoOfJewelsOrPieces.mandatory === 1 ? true : false}
              fullWidth={true}
              onValueChange={(e: any) => {
                const { value } = e;
                props?.doc?.sagNoOfJewelsOrPieces.set(value);
              }}
            />
          </InputGrid>
          <InputGrid visibility={sagArticleType.visible}>
            <SelectComponent
              label={translate("Article Type")}
              value={props?.doc?.sagArticleType.get()}
              values={masterDateStoreValues.artilceTypeMasterData}
              required={sagArticleType.mandatory === 1 ? true : false}
              onChange={(e) => props?.doc?.sagArticleType.set(e.target.value)}
              defaultPlaceholder={true}
            />
          </InputGrid>
          <InputGrid visibility={sagDescriptionOfTheOrnamentsOrArticle.visible}>
            <TextBoxComponent
              value={props?.doc?.sagDescriptionOfTheOrnamentsOrArticle.get()}
              onChange={(e) =>
                props?.doc?.sagDescriptionOfTheOrnamentsOrArticle.set(
                  e.target.value
                )
              }
              label={translate("Description of the Ornaments / Article")}
              //onInput={OnDemandValueFormatter.alphabeticalSpaces}
              required={
                sagDescriptionOfTheOrnamentsOrArticle.mandatory === 1
                  ? true
                  : false
              }
            />
          </InputGrid>
          <InputGrid visibility={sagKaratageOfArticle.visible}>
            <SelectComponent
              label={translate("Karatage Of Article")}
              value={props?.doc?.sagKaratageOfArticle.get()}
              values={masterDateStoreValues.karatageArticleMasterData}
              required={sagKaratageOfArticle.mandatory === 1 ? true : false}
              onChange={(e) =>
                props?.doc?.sagKaratageOfArticle.set(e.target.value)
              }
              defaultPlaceholder={true}
            />
          </InputGrid>
          <InputGrid visibility={sagGrossWeight.visible}>
            <NumericFormat
              label={"Gross Weight (g)"}
              customInput={TextBoxComponent}
              variant="outlined"
              thousandSeparator={true}
              value={formatCurrency(props?.doc?.sagGrossWeight.get())}
              decimalScale={2}
              required={sagGrossWeight.mandatory === 1 ? true : false}
              fullWidth={true}
              onValueChange={(e: any) => {
                const { value } = e;
                props?.doc?.sagGrossWeight.set(value);
              }}
            />
          </InputGrid>
          <InputGrid visibility={sagNetWeight.visible}>
            <NumericFormat
              label={"Net Weight (g)"}
              customInput={TextBoxComponent}
              variant="outlined"
              thousandSeparator={true}
              value={formatCurrency(props?.doc?.sagNetWeight.get())}
              decimalScale={2}
              required={sagNetWeight.mandatory === 1 ? true : false}
              fullWidth={true}
              onValueChange={(e: any) => {
                const { value } = e;
                props?.doc?.sagNetWeight.set(value);
              }}
            />
          </InputGrid>
          <InputGrid visibility={sagMarketRatePerGram.visible}>
            <NumericFormat
              label={"Market Rate Per Gram"}
              customInput={TextBoxComponent}
              variant="outlined"
              thousandSeparator={true}
              value={formatCurrency(props?.doc?.sagMarketRatePerGram.get())}
              decimalScale={2}
              required={sagMarketRatePerGram.mandatory === 1 ? true : false}
              fullWidth={true}
              onValueChange={(e: any) => {
                const { value } = e;
                props?.doc?.sagMarketRatePerGram.set(value);
              }}
            />
          </InputGrid>
          <InputGrid visibility={sagMarketValue.visible}>
            <NumericFormat
              label={"Market Value"}
              customInput={TextBoxComponent}
              variant="outlined"
              thousandSeparator={true}
              value={formatCurrency(props?.doc?.sagMarketValue.get())}
              decimalScale={2}
              required={sagMarketValue.mandatory === 1 ? true : false}
              fullWidth={true}
              onValueChange={(e: any) => {
                const { value } = e;
                props?.doc?.sagMarketValue.set(value);
              }}
            />
          </InputGrid>
          <InputGrid visibility={sagPermissibleRatePerGram.visible}>
            <NumericFormat
              label={"Permissible  Rate Per Gram"}
              customInput={TextBoxComponent}
              variant="outlined"
              thousandSeparator={true}
              value={formatCurrency(
                props?.doc?.sagPermissibleRatePerGram.get()
              )}
              decimalScale={2}
              required={
                sagPermissibleRatePerGram.mandatory === 1 ? true : false
              }
              fullWidth={true}
              onValueChange={(e: any) => {
                const { value } = e;
                props?.doc?.sagPermissibleRatePerGram.set(value);
              }}
            />
          </InputGrid>
          <InputGrid visibility={sagPermissibleValue.visible}>
            <NumericFormat
              label={"Permissible Value"}
              customInput={TextBoxComponent}
              variant="outlined"
              thousandSeparator={true}
              value={formatCurrency(props?.doc?.sagPermissibleValue.get())}
              decimalScale={2}
              required={sagPermissibleValue.mandatory === 1 ? true : false}
              fullWidth={true}
              onValueChange={(e: any) => {
                const { value } = e;
                props?.doc?.sagPermissibleValue.set(value);
              }}
            />
          </InputGrid>
        </InputFieldsStack>
        <InsuranceAvailableActivateState props={props} />
      </FieldSectionWrapper>
    </Box>
  );
};
