import { Downgraded, useHookstate, useState } from "@hookstate/core";
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { CheckBoxComponent } from "../../../components/InputComponents/CheckBoxComponent/CheckBoxComponent";
import { openGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { addToaster } from "../../../components/OtherComponents/GlobalToast";
import { CircularProgressComponent } from "../../../components/OtherComponents/ProgressComponent/ProgressComponent";
import { ERROR_CODES } from "../../../configs/constants/errorCode";
import { productDetails } from "../../../configs/mainStore";
import { deleteDLALimitsById, fetchUsersDlaLimits, saveDLALimitsById } from "../../../services/configurationApis";
import { ComponentMapper } from "../../Preferences/products/ComponentMapper";

function Extra({ user }) {
  const updating: any = useState(false);
  const loading: any = useState(true);
  const store: any = useHookstate(productDetails);
  const [values, setValues]: any = React.useState([]);
  const [selectedIndexes, setSelectedIndexes] = React.useState<any>([]);
  const [multipleSelection, enableMultipleSelection] = React.useState<any>(false);

  useEffect(() => {
    if (user === 0) {
      loading?.set(false);
      return;
    }
    resolveDependencies();
  }, [user]);

  const resolveDependencies = async () => {
    loading?.set(true);
    const { data: dlaData = [] } = await fetchUsersDlaLimits(user)
    await extracDlaMinMax(dlaData);
    await loadMappingData(dlaData);
    loading?.set(false);
  };

  const extracDlaMinMax = async (dlaData) => {
    if (!dlaData || dlaData?.length === 0) {
      enableMultipleSelection(false);
      return;
    }

    const checkedCatalogIds = dlaData.map(item => item.catalogKey);
    setSelectedIndexes(checkedCatalogIds);
    enableMultipleSelection(checkedCatalogIds.length > 1);
  };

  const loadMappingData = async (dlaData) => {
    try {
      const { catalogDefinitionMap }: any = store?.definitionMap?.attach(Downgraded)?.get() ?? {};

      for (let [key, value] of Object.entries(catalogDefinitionMap)) {
        if (typeof value === 'string') {
          catalogDefinitionMap[key] = { name: value };
        }
      }

      const dlaCatalogMap = dlaData.reduce((obj, element) => {
        obj[element.catalogKey] = element;
        return obj;
      }, {});

      for (let [key] of Object.entries(catalogDefinitionMap)) {
        const [part1, part2] = key.split('-');
        const dlaData = dlaCatalogMap[key] || dlaCatalogMap[`${part1}-${part2}-*`] || dlaCatalogMap[`${part1}-*-*`] || dlaCatalogMap[`*-*-*`];
        catalogDefinitionMap[key].id = key;

        if (!dlaData) {
          delete catalogDefinitionMap[key].desc
          delete catalogDefinitionMap[key].dlaLimitMin
          delete catalogDefinitionMap[key].dlaLimitMax
          continue;
        };

        catalogDefinitionMap[key].desc = `Min: ${dlaData.dlaLimitMin}, Max: ${dlaData.dlaLimitMax}`.trim();
        catalogDefinitionMap[key].dlaLimitMin = dlaData.dlaLimitMin;
        catalogDefinitionMap[key].dlaLimitMax = dlaData.dlaLimitMax;
      }

      setValues(Object.values(catalogDefinitionMap));
    } catch (error) {
      return null;
    } finally {
      loading.set(false);
    }
  };

  const handleSelection = async (status, data) => {
    let selectedData: any = [];
    if (selectedIndexes.includes(data.id)) {
      selectedData = selectedIndexes.filter((item) => item != data.id);
    } else {
      selectedData = [data.id, ...selectedIndexes];
    }

    setSelectedIndexes(selectedData);
    enableMultipleSelection(selectedData?.length > 1)
  };

  const handleDelete = async (payload: any = {}) => {
    try {
      let obj = {
        catalogKey: payload?.id,
        userId: user,
      };

      const result: any = await deleteDLALimitsById(obj);

      if (result?.status === ERROR_CODES.success) {
        addToaster({
          status: "success",
          title: "Deleted",
          message: `successfully deleted.`,
        });
        resolveDependencies();
      }
    } catch (error) {
      return null;
    } finally {
      updating.set(false);
    }
  };

  const manageDla = (data = []) => {
    openGlobalModal({
      modalSize: "sm",
      title: "",
      bodyId: "manage-product-dla",
      close: false,
      modalParams: {
        user,
        data,
        handleDelete,
        handleSave,
      },
    });
  };

  const handleSave = async (payload: any = {}) => {
    let catalogKey = payload?.id !== -1 ? [payload?.id] : selectedIndexes;

    let obj = {
      catalogKey,
      userId: user,
      dlaLimitMin: parseFloat(payload.dlaLimitMin.toString()),
      dlaLimitMax: parseFloat(payload.dlaLimitMax.toString()),
    };

    if (obj.dlaLimitMax <= obj.dlaLimitMin) {
      addToaster({
        status: "error",
        title: "Invalid range settings",
        message: "Check the maximum and minimum DLA limits",
      });
      return;
    }

    updating.set(true);
    try {
      const result: any = await saveDLALimitsById(obj);

      if (result?.status === ERROR_CODES.success) {
        addToaster({
          status: "success",
          title: "Updated",
          message: `successfully updated.`,
        });
        resolveDependencies();
      }
    } catch (error) {
      addToaster({
        status: "error",
        title: "Failed",
        message: "Failed to update user DLA limits",
      });
    } finally {
      updating.set(false);
    }
  };

  const actions = (item) => {
    return <IconButton
      aria-label="edit"
      onClick={() => manageDla(item)}
      disabled={user === 0 || multipleSelection}
    >
      <EditIcon />
    </IconButton>
  }

  if (loading?.get()) {
    return (
      <Stack flex={1}>
        <Paper elevation={0}>
          <CircularProgressComponent size={30} />
        </Paper>
      </Stack>
    );
  }

  return (
    <Paper elevation={0}>
      <Box p={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
          <Stack justifyContent="flex-start" direction="row">
            <Stack direction="column" alignItems="flex-start">
              <Typography color="gray">DLA Limit
              </Typography>
              <Typography variant="caption" color="gray">
                {`Delegated Lending Authority`}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography color="gray">
              <CheckBoxComponent
                checked={multipleSelection}
                onChange={(e) => {
                  enableMultipleSelection(e.target.checked);
                }}
                disabled={user === 0}
              />
              Multiple
            </Typography>
            <ButtonComponent
              disabled={!multipleSelection}
              variant="contained"
              onClick={() => manageDla()}
              title={"Manage DLA"}
            />
          </Stack>
        </Stack>
      </Box>
      <Box m={-1}>
        <ComponentMapper
          title={""}
          items={values}
          selections={selectedIndexes}
          handleSelection={handleSelection}
          actions={actions}
        >
        </ComponentMapper>
      </Box>
    </Paper>
  );
}
export default Extra;
