import { Downgraded, useState } from '@hookstate/core';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Box, Grid, Paper, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { ButtonComponent } from '../../components/InputComponents/ButtonComponent/ButtonComponent';
import { TextBoxComponent } from '../../components/InputComponents/TextBoxComponent/TextBoxComponent';
import { CMTextInput } from '../../components/OtherComponents/CommunicationService/CMTextInput';
import { closeGlobalModal } from '../../components/OtherComponents/GlobalModal/GlobalModal';
import { addToaster } from '../../components/OtherComponents/GlobalToast';
import { CircularProgressComponent } from '../../components/OtherComponents/ProgressComponent/ProgressComponent';
import { privilegeKeys } from '../../configs/constants/privilegeKeys';
import { appSetting, userDetails } from '../../configs/mainStore';
import { languageListStore } from '../../configs/stores/languageStore';
import { addNewEmailTemplateApi, editEmailTemplateApi, getEmailTemplateById } from '../../services/emailTemplateService';
import { isPrivilegeHave } from '../../services/utilService';


export const AddEditChannelManagementComponent = ({ emailListChanged, editTemplateItem }) => {
  const { userPrivilege }: any = userDetails.attach(Downgraded).get();
  const appSettings: any = useState(appSetting);
  const { systemLanguageList }: any = useState(languageListStore);
  const languageList: any = systemLanguageList.attach(Downgraded).get();

  const [isLoading, setIsLoading] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [values, setValues] = React.useState<any>({});

  const [isActive, setIsActive] = React.useState(true);
  const [languageData, setLanguageData] = React.useState<any>([]);

  useEffect(() => {
    const languagesObj = appSettings?.language.get() && languageList[appSettings?.language.get()];
    const languagesArray = Object.values(languagesObj).sort((a: any, b: any) =>
      a.DISPLAY_ORDER - b.DISPLAY_ORDER
    );

    setLanguageData(languagesArray.map((value) => value));
  }, []);

  useEffect(() => {
    // setFieldErrors({});
    setValues({});

    if (editTemplateItem?.id) {
      (async () => {
        setIsLoading(true);
        const data = await getEmailTemplateById(editTemplateItem?.id);

        if (data?.tplId === editTemplateItem?.id) {
          setIsActive(editTemplateItem?.isActive === 1 ? true : false);
          setIsEdit(true);

          let tempValues;
          languageData?.map((item) => {
            let tempLang = (item?.CODE).toLowerCase();

            tempValues = {
              ...tempValues,
              ...{ [`${tempLang}Name`]: data[item?.CODE]?.name, [`${tempLang}Desc`]: data[item?.CODE]?.desc },
            };
          });

          setValues(tempValues);
        } else {
          addToaster({
            status: 'error',
            title: 'Update Error',
            message: 'Something went Wrong',
          });
        }
        setIsLoading(false);
      })();
    } else {
      setIsActive(true);
      setIsEdit(false);

      setValues({});
    }
  }, [editTemplateItem]);

  const onChangeField = (fieldName, value) => {
    setValues({ ...values, ...{ [fieldName]: value } });
  };

  const closeTask = () => {
    closeGlobalModal();
  };

  const updateNewEmailTemplate = async () => {
    setUpdateLoading(true);

    let templateData: any = [];
    languageData?.map((item) => {
      let tempLang = (item?.CODE).toLowerCase();

      templateData.push({ languageId: item?.CODE, name: values[`${tempLang}Name`], desc: values[`${tempLang}Desc`] });
    });

    let result;

    if (isEdit) {
      result = await editEmailTemplateApi(editTemplateItem?.id, isActive, templateData);
    } else {
      result = await addNewEmailTemplateApi(isActive, templateData);
    }

    if (result) {
      addToaster({
        status: 'success',
        title: isEdit ? 'Template Updated' : 'New Template Inserted',
        message: result,
      });

      setValues({});
      setIsActive(true);
      emailListChanged();
    } else {
      addToaster({
        status: 'error',
        title: 'Update Error',
        message: 'Something went Wrong',
      });
    }

    setUpdateLoading(false);
  };

  return (
    <Box m={1}>
      {isLoading ? (
        <CircularProgressComponent size={30} sx={{ top: '50%', right: '20%', position: 'absolute', zIndex: 100 }} />
      ) : (
        <Paper elevation={0}>
          <Grid container justifyContent="space-between">
            <Grid item className="align-center">
              <Box>
                <Box className="screen_description--basic">
                  <p
                    className="basic-font basic-font-color font-size-20 font-weight-600"
                    style={{ marginBottom: 0, marginTop: 0 }}
                  >
                    {isEdit ? `Change Email Template` : 'Create Channel'}
                  </p>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <CloseIcon fontSize='large' onClick={closeTask} style={{ cursor: 'pointer' }} />
              </Box>
            </Grid>
          </Grid>
          <hr style={{ borderTop: '1px solid #454c661a', width: '100%' }} />
          <Box p={1}>
            <Grid container columns={8} >
              <Grid container columns={8} padding={2}>
                <Grid item xs={8} sm={8}>
                  <CMTextInput
                    desc={`Name`}
                    component={
                      <TextBoxComponent
                        onChange={(e) => {
                          onChangeField(`English Name`, e.target.value);
                        }}
                        multiline={true}
                        value={undefined}
                        disabled={updateLoading}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={8} sm={8}>
                  <CMTextInput
                    desc={`Description`}
                    component={
                      <TextBoxComponent
                        onChange={(e) => {
                          onChangeField(`English Desc`, e.target.value);
                        }}
                        multiline={true}
                        value={undefined}
                        disabled={updateLoading}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={8} sm={8} textAlign="end" mt={1}>
                <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
                  <ButtonComponent
                    startIcon={<SendIcon />}
                    title={isEdit ? 'CHANGE CHANNEL' : 'ADD NEW CHANNEL'}
                    variant="contained"
                    onClick={() => {
                      updateNewEmailTemplate();
                    }}
                    disabled={updateLoading || !isPrivilegeHave(userPrivilege, privilegeKeys.CREATE_EMAIL)}
                    loadingbtn={true}
                    loading={updateLoading}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
    </Box>
  );
};
