import { toast } from "../components/OtherComponents/GlobalToast";
import URL from "../configs/connection";
import { ERROR_CODES } from "../configs/constants/errorCode";
import { endpoints } from "../configs/endpoints";
import CachingStorage from "../services/cacheStorage";
import Api from "./apiCallsHandler";

const api = Api.getApi();
const editInspectionTemplateUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/edit-inspection-template`;
const addNewInspectionTemplateUrl = `${URL.REACT_APP_BACKEND_HOST}/communication-service/add-new-inspection-template`;

const inspectionService = `${URL.REACT_APP_BACKEND_HOST}/los/workflows/next/inspection/assignees`;

export const getInspectionTemplateList = async (languageId) => {
  try {
    const newList = JSON.parse(localStorage.getItem("createInspectionList") || "").flat();

    return newList;
  } catch (error) {
    return null;
  }
};

export const validateInspection = async (applicationId) => {
  try {
    const { data } = await api.get(`${endpoints.inspectionValidate}?applicationId=${applicationId}`);
    return data;
  } catch (error) {
    return null;
  }
};

export const getInspectionTemplateById = async (templateId) => {
  try {
    const newList = JSON.parse(localStorage.getItem("createInspectionList") || "").flat();
    const response = newList.filter((obj) => obj.id === templateId);

    return response;

  } catch (error) {
    return null;
  }
};

export const editInspectionTemplateApi = async (templateId: any, isActive: any, templateData: any) => {
  try {
    await api.patch(`${editInspectionTemplateUrl}`, {
      templateId,
      isActive: isActive ? 1 : 0,
      templateData,
    });
  } catch (error) {
    return null;
  }
};

export const addNewInspectionTemplateApi = async (isActive: any, templateData: any) => {
  try {
    await api.post(`${addNewInspectionTemplateUrl}`, {
      isActive: isActive ? 1 : 0,
      templateData,
    });

    localStorage.setItem(
      "createInspectionList",
      JSON.stringify(
        JSON.parse(localStorage.getItem("createInspectionList") || "0") !== 0
          ? [...JSON.parse(localStorage.getItem("createInspectionList") || ""), templateData]
          : templateData
      )
    );

    const filteredResponse = JSON.parse(localStorage.getItem("createInspectionList") || "")
      .flat()
      ?.filter((obj) => obj?.languageId === "EN");
    filteredResponse.forEach((element, index) => {
      element["id"] = element.id ? element.id : new Date().getTime();
      element["isActive"] = "1";
    });

    localStorage.setItem("createInspectionList", JSON.stringify(filteredResponse));

    return filteredResponse;
  } catch (error) {
    return null;
  }
};

export const fetchInspectionService = async (params) => {
  let response: unknown[] = CachingStorage.read(CachingStorage.Keys.INSPECTION_USER_LIST) || {};
  try {
    if (!response || !response.length) {
      const { data = [], status = 0 } = await api.get(`${inspectionService}`, { ...params });

      CachingStorage.store(CachingStorage.Keys.INSPECTION_USER_LIST, data);

      if (status === ERROR_CODES.success) {
        response = data;
      } else toast({ status: "error", message: "Error loading admins list!" })
    }

    response = CachingStorage.read(CachingStorage.Keys.INSPECTION_USER_LIST);
  } catch (error) { }

  return response;
};
