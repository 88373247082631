import { Downgraded, useState } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { bufferToBlobUrl } from "../../../../../configs/base64Conversion";
import {
  getAuditList, getAuditRecordFile,
} from "../../../../../services/creditFileApiCall";
import { compare } from "../../../../../utility/other";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";

import { creditData, userDetails } from "../../../../../configs/mainStore";
import AuditItemRow from "./AuditItemRow";

export const previewBtnClicked = async (docId) => {
  const resultFile = await getAuditRecordFile(docId);

  if (resultFile?.fileData?.data) {
    const fileBuffer = resultFile?.fileData.data;
    const contentType = resultFile?.contentType;

    const blobUrl = bufferToBlobUrl(fileBuffer, contentType);

    window.open(
      `${blobUrl}`,
      "targetWindow",
      `toolbar=no,
         location=no,
         status=no,
         menubar=no,
         scrollbars=yes,
         resizable=yes,
         width=500,
         height=500`
    );
  }
};

const ApplicationReviewAudit = ({
  data,
  applicationId,
  currentApplicationWfData,
  innerComponentData,
  tabsToEdit,
}) => {
  const auditList: any = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const creditDetailsState: any = useState(creditData);

  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      if (applicationId) {
        const data = await getAuditList(applicationId);
        if (data && data.length > 0) {
          const sortedList = data.sort((a, b) => compare(b, a, "id"));
          auditList.set(sortedList);
        }
      }
      setIsLoading(false);
    })();
  }, []);

  const callBackOnAction = (newAudit) => {
    const audit = JSON.parse(JSON.stringify(auditList.get()));
    const sortedList = [...audit, newAudit]?.sort((a, b) => compare(b, a, "id"));

    auditList.set(sortedList);
  };

  const checkPrivilegeStatus = () => {
    let isDisabled = false;
    if (isLoggedInUserNotTheAssignee) {
      isDisabled = true;
    }
    return isDisabled;
  };

  return (
    <div className="full-width full-height">
      <InnerSectionHeaderComponent
        innerComponentData={innerComponentData}
        applicationId={applicationId}
        currentApplicationWfData={currentApplicationWfData}
        callBackOnAction={callBackOnAction}
        modalSize="lg"
        disabled={checkPrivilegeStatus()}
      />
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          {isLoading ? (
            <CircularProgressComponent
              size={30}
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                zIndex: 100,
              }}
            />
          ) : auditList && auditList.length > 0 ? (
            auditList
              ?.get()
              ?.map((data, index) => (
                <AuditItemRow
                  data={data}
                  key={data?.id}
                  previewBtnClicked={previewBtnClicked}
                />
              ))
          ) : (
            <ErrorMessageComponent
              headMessage={"No data available"}
              errorMessage={""}
            />
          )}
        </Stack>
      </Grid>
    </div>
  );
};

export default ApplicationReviewAudit;
