import { Downgraded, useHookstate, useState } from '@hookstate/core'
import { Stack } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CachingStorage from '../../../../../../../../services/cacheStorage'
import { getCopy } from '../../../../../../../../utility/util'
import ErrorBoundary from '../../../../../../../ErrorHandleComponent/ErrorBoundary'
import { toast } from '../../../../../../GlobalToast'
import { CircularProgressComponent } from '../../../../../../ProgressComponent/ProgressComponent'
import { endPointKeys, getInspectionBusinesData, getProfitLossAccountsData, saveProfitLossAccountsData } from '../../Api/AppraisalApi'
import ApparaisalTabularComponent from '../../Helpers/HrizontalTabPanel/HorizontalTabPanel'
import MainHeader from '../../Helpers/MainHeader/MainHeader'
import { defaultComprehensiveProfitAndLoanAccounts, defaultGeneralizedProfitAndLoanAccounts, profitAndLossAccountsTypeKey } from '../../Stores/MicroAppraisalDefaultData'
import { comprehensiveProfitAndLoanAccountsData, financialPositionOfTheCompanyFinalObject, generalizedProfitAndLoanAccountsData, profitAndLossAccountsTypeState } from '../../Stores/MicroAppraisalStore'
import BalanceSheet from './Components/BalanceSheet/BalanceSheet'
import ForecastedCashFlow from './Components/ForecastedCashFlow/ForecastedCashFlow'
import SalesAndPurchaseInformationContainer from './Components/SalesAndPurchaseInformation/SalesAndPurchaseInformationContainer'
import FinancialPositionOfTheCompany from './FinancialPositionOfTheCompany'

const FinancialPositionOfTheCompanyContainer = ({ applicationId, data: { isAmountBelowTwoPointFive } }) => {
    const currentTabKey = useState(0);
    const { t: translate } = useTranslation();
    const isLoading = useState(true);
    const comprehensiveProfitAndLoanAccounts = useState(comprehensiveProfitAndLoanAccountsData);
    const generalizedProfitAndLoanAccounts = useState(generalizedProfitAndLoanAccountsData);
    const tots = useState(financialPositionOfTheCompanyFinalObject["totals"]);
    const doesTheApplicantHasEnoughFinancialInformation: any = useState<any>(financialPositionOfTheCompanyFinalObject["doesTheApplicantHasEnoughFinancialInformation"]);
    const profitLossAccountsDataType = useState(profitAndLossAccountsTypeState);
    const inspectionBusinessData = useHookstate(undefined);

    const doubleTypeProfitLossAccountsData = {
        generalized: generalizedProfitAndLoanAccounts,
        comprehensive: comprehensiveProfitAndLoanAccounts,
    }

    useEffect(() => {
        (async function () {
            try {
                const response: any = await getProfitLossAccountsData(applicationId);

                if (response?.data && Object.values(response.data).length > 0) {
                    doubleTypeProfitLossAccountsData[response.type].set(prev => ({ ...prev, ...JSON.parse(JSON.stringify(response)) }));
                    profitLossAccountsDataType.set(response.type);
                    doesTheApplicantHasEnoughFinancialInformation.set(response.doesTheApplicantHasEnoughFinancialData);
                } else {
                    let result = await getInspectionBusinesData(applicationId);
                    inspectionBusinessData.set(result);
                }

            } catch (error) {
            } finally {
                isLoading.set(false)
            }
        }());

        return () => {
            comprehensiveProfitAndLoanAccounts.set(getCopy(defaultComprehensiveProfitAndLoanAccounts));
            generalizedProfitAndLoanAccounts.set(getCopy(defaultGeneralizedProfitAndLoanAccounts));
            profitLossAccountsDataType.set(getCopy(profitAndLossAccountsTypeKey));
            tots.set({});
            doesTheApplicantHasEnoughFinancialInformation.set("");
        }
    }, [currentTabKey.get()]);

    const onSaveChanges = async () => {

        const profitLossAccountsType = JSON.parse(JSON.stringify(profitLossAccountsDataType.value));
        const profitLossAccountsData = JSON.parse(JSON.stringify(doubleTypeProfitLossAccountsData[profitLossAccountsType].value));
        const doesTheApplicantHasEnoughFinancialData = JSON.parse(JSON.stringify(doesTheApplicantHasEnoughFinancialInformation.value));

        const dataTobeSave = {
            applicationId,
            profitLossData: {
                ...profitLossAccountsData,
                type: profitLossAccountsType,
                doesTheApplicantHasEnoughFinancialData,
                totals: JSON.parse(JSON.stringify(tots.value)),
                isPublish: 0,
            }
        }


        const response = await saveProfitLossAccountsData(dataTobeSave);

        if (response.status) {
            CachingStorage.clear(`${applicationId}-${endPointKeys.profitLossAccountsData}`);
            toast({ status: "success", message: `${translate("profitAndLossAccounts")} saved successfully !` });
        }

        else toast({ status: "error", message: `Error on Saving ${translate("profitAndLossAccounts")} try again!` });
    }

    const onChange = (event, newKey) => currentTabKey.set(newKey);

    const componentsMapping = {
        0: FinancialPositionOfTheCompany,
        1: BalanceSheet,
        2: ForecastedCashFlow,
        3: SalesAndPurchaseInformationContainer
    }

    let financialPositionOfTheCompanyTitle = {
        0: "Profit and Loss Accounts Data",
        1: "Balance Sheet",
        2: "Forecasted Cash Flow",
        3: "Sales and purchase information"
    }

    const props = { title: financialPositionOfTheCompanyTitle, currentHorizintalTabKey: currentTabKey, allTabComponents: componentsMapping, applicationId, onChange, formData: {}, isAmountBelowTwoPointFive, onSaveChanges, inspectionBusinessData: inspectionBusinessData.attach(Downgraded).get() }

    return (
        <ErrorBoundary>
            <MainHeader title={"Financial Position of the Company"} onSubmit={currentTabKey.get() === 0 ? onSaveChanges : undefined}>

                {
                    isLoading.get() ? <Stack width={"100%"} alignContent={"center"}> <CircularProgressComponent size={30} /> </Stack>
                        : <ApparaisalTabularComponent {...props} />
                }
            </MainHeader>
        </ErrorBoundary>
    )
}

export default FinancialPositionOfTheCompanyContainer;
