import { Box } from "@mui/material";
import { standardDateFormatter } from "los-util";
import { useTranslation } from "react-i18next";
import { deSelectedBtn, selectedBtn, VARIANT } from "../../../../../../../../configs/constants/styleValues";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import { StyledButton } from "../../../../../../../InputComponents/ButtonComponent/StyledButton";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { FormLabelComponent } from "../../../../../../../InputHelperComponents/FormLabelComponent/FormLabelComponent";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";

/**-----------------------------------WAREHOUSE DETAILS REQUIRED---------------------------------------------**/

export const WhetherInsurancePolicySampledThirdPartyAction = ({ props }) => {
  return (
    <>
      <InputFieldsStack>
        <InputGrid visibility={1} variant={VARIANT?.longGrid}>
          <Box mb={2}>
            <FormLabelComponent>Whether the insurance policy is sampled in the name of a third party ?</FormLabelComponent>
          </Box>
          <Box display="flex">
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionWhetherInsurancePolicySampledThirdParty.get() === true ? selectedBtn : deSelectedBtn}
                title={"Yes"}
                onClick={() => {
                  props?.doc?.actionWhetherInsurancePolicySampledThirdParty.set(true);
                }}
              />
            </Box>
            <Box mr={1}>
              <StyledButton
                styles={props?.doc?.actionWhetherInsurancePolicySampledThirdParty.get() === false ? selectedBtn : deSelectedBtn}
                title={"No"}
                onClick={() => {
                  props?.doc?.actionWhetherInsurancePolicySampledThirdParty.set(false);
                }}
              />
            </Box>
          </Box>
        </InputGrid>
      </InputFieldsStack>
      {props?.doc?.actionWhetherInsurancePolicySampledThirdParty.get() && <WhetherInsurancePolicySampledThirdPartyInputsSection props={props} />}
    </>
  );
};

export const WhetherInsurancePolicySampledThirdPartyInputsSection = ({ props }) => {
  const { t: translate } = useTranslation();
  const { liDateLetterSignedThirdParty, liDateSignedLetterBeneficiaries } = props.fields || {};

  return (
    <InputFieldsStack>
      <InputGrid visibility={liDateLetterSignedThirdParty.visible}>
        <ValidateDatePicker
          value={formattedDate(props?.doc?.liDateLetterSignedThirdParty.get())}
          onChange={(date) => {
            const preparedDate = standardDateFormatter(date);
            if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
              props?.doc?.liDateLetterSignedThirdParty.set(preparedDate);
            }
          }}
          label={translate("Date of letter signed by the third party (DD/MM/YYYY)")}
          enableFuture={false}
          disablePast={false}
          required={liDateLetterSignedThirdParty.mandatory === 1 ? true : false}
        />
      </InputGrid>
      <InputGrid visibility={liDateSignedLetterBeneficiaries.visible}>
        <ValidateDatePicker
          value={formattedDate(props?.doc?.liDateSignedLetterBeneficiaries.get())}
          onChange={(date) => {
            const preparedDate = standardDateFormatter(date);
            if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
              props?.doc?.liDateSignedLetterBeneficiaries.set(preparedDate);
            }
          }}
          label={translate("The date of signed letter when beneficiaries are named in the policy (DD/MM/YYYY)")}
          enableFuture={false}
          disablePast={false}
          required={liDateSignedLetterBeneficiaries.mandatory === 1 ? true : false}
        />
      </InputGrid>
    </InputFieldsStack>
  );
};
