import CloseIcon from '@mui/icons-material/Close';
import { Stack } from "@mui/material";
import { ButtonComponent } from "../../../components/InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../../components/OtherComponents/GlobalModal/GlobalModal";
import { PrivilegeSectionView } from "../../PrivilegeLayout/PrivilegeSectionView";

const UserPrivilegeModal = (params) => {
    const privilegeTypeList = params.list;
    const loggedFrom = "UserConfigurations"

    const groupPrivilegeDataList: any = params.group;
    const closeCondition = () => {
        closeGlobalModal();
    };

    return (
        <>
            <div>
                <Stack direction={'column'}>
                    <PrivilegeSectionView privilegeTypeList={privilegeTypeList} isLoading={true} groupPrivilegeDataList={groupPrivilegeDataList} changePrivilegeArr={undefined} loggedFrom={loggedFrom} />
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
                    <ButtonComponent
                        title="Close"
                        startIcon={<CloseIcon />}
                        variant="contained"
                        onClick={closeCondition}
                        loadingbtn={true}
                        color="info"
                    />
                </Stack>
            </div>
        </>
    );
}

export default UserPrivilegeModal;
