import { useHookstate } from "@hookstate/core";
import { Box, Stack } from "@mui/material";
import React from "react";
import AutoCompleteProxyComponent from "../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { InputGroup } from "../InputGroup/InputGroup";
import { CircularProgressComponent } from "../ProgressComponent/ProgressComponent";

const Content = ({ data, actions, children }) => {
  const selectedGroup = useHookstate([]);
  const selectUser = useHookstate([]);

  const [selectedUsers, setSelectedUsers] = React.useState([]);

  const handleSelectedGroupChange = (event) => {
    selectedGroup.set(event.id);

    let _selectedUsers: any = [];
    let formattedUsers: any = [];

    for (const group in data.inspection) {
      const groupData = data.inspection[group].get();

      if (groupData.id === event.id) {
        if (groupData.users.length > 0) {
          _selectedUsers.push(groupData.users);
        }
      }
    }

    if (_selectedUsers?.[0].length > 0) {
      _selectedUsers?.[0].forEach((user) => {
        formattedUsers.push({ id: user?.id, name: user?.name.replaceAll("null", "") });
      });
    }

    setSelectedUsers(formattedUsers);
  };

  return (
    <Stack flex={1}>
      <InputGroup title="Select Inspection Group" padding={2} marginTop={1}>
        <AutoCompleteProxyComponent
          options={data.inspection}
          fullWidth={true}
          onChange={(e) => {
            handleSelectedGroupChange(e);
            return actions.groupDidSelect(e);
          }}
          label={undefined}
          value={selectedGroup.get()}
          cls="select_basic"
        />
      </InputGroup>
      {selectedUsers.length > 0 && (
        <InputGroup title="Select User" padding={2} marginTop={1}>
          <Stack direction={"row"} alignItems="center">
            <AutoCompleteProxyComponent
              options={selectedUsers || []}
              fullWidth={true}
              onChange={(e) => {
                selectUser.set(e.id);
                return actions.userDidSelect(e);
              }}
              label={undefined}
              value={selectUser.get()}
              cls="select_basic"
            />
          </Stack>
        </InputGroup>
      )}

      {data.loading.get() && (
        <>
          <Box pl={2}></Box>
          <CircularProgressComponent size={24} />
        </>
      )}

      {children}
    </Stack>
  );
};

export default Content;
