import { Downgraded } from "@hookstate/core";
import { securityDocumentPermissions } from "../../../configs/constants/contants";
import { privilegeKeys } from "../../../configs/constants/privilegeKeys";
import { creditData, productStageDocumentPermissionsState, userDetails } from "../../../configs/mainStore";
import { documentUploadData } from "../../../configs/stores/document-upload-store/documentUploadStore";
import AppConfig from "../../AppConfig";
import { stagewisePermissionCheck } from "../getIshavePermission";
import { isNotPermittedDocs } from "./isNotPermittedDocs";

export const isSecurityDocUploadApprovedPermitted = (documentFileId, privilegeKey) => {
  const { formData, currentWFStage }: any = creditData.attach(Downgraded).get();
  const { type, sector, scheme } = formData?.creditData;
  const productCatelog = `${type}-${sector}-${scheme}`;
  const productStagewiseDocumentPermissionsDetails = productStageDocumentPermissionsState.attach(Downgraded).get();
  const { documentDetailsList }: any = documentUploadData.attach(Downgraded).get();
  const securityDocKey = documentDetailsList[documentFileId]?.documentKey.toLowerCase();

  if (isNotPermittedDocs()) {
    return true;
  } else {
    const securityDocumentPermission = stagewisePermissionCheck(productStagewiseDocumentPermissionsDetails, currentWFStage, productCatelog, securityDocKey ? securityDocKey : documentFileId, privilegeKey);

    return securityDocumentPermission;
  }
};

export const multipleDisbursementDocApprovedPermitted = (disbStatus, privilegeKey, documentKey = "") => {
  const { currentWFStage, currentAssignee }: any = creditData.attach(Downgraded).get();
  const { userPrivilege, userId }: any = userDetails.attach(Downgraded).get();
  const multipleDisbursementStagedPermissions = AppConfig.config.receipt ?? AppConfig.config[documentKey];
  const closeStage = AppConfig?.config?.closeStage;

  if (currentAssignee !== userId) {
    return true;
  }

  if (currentWFStage === closeStage) {
    return true;
  }

  if (disbStatus === "Complete") {
    return true;
  }

  if (userPrivilege && !userPrivilege[privilegeKey]) {
    return true;
  }

  let currentProductPermissions: any[] = multipleDisbursementStagedPermissions[currentWFStage] || [];

  if (currentProductPermissions.length < 1) {
    return true;
  } else {
    if (!currentProductPermissions.includes(privilegeKey)) {
      return true;
    } else {
      return false;
    }
  }
};

export const isOtherDocUploadApprovedPermitted = (documentFileId, privilegeKey) => {
  const { formData, currentWFStage }: any = creditData.attach(Downgraded).get();
  const { type, sector, scheme } = formData?.creditData;
  const productCatelog = `${type}-${sector}-${scheme}`;
  const productStagewiseDocumentPermissionsDetails = productStageDocumentPermissionsState.attach(Downgraded).get();
  const { documentDetailsList }: any = documentUploadData.attach(Downgraded).get();
  const securityDocKey = documentDetailsList[documentFileId]?.documentKey.toLowerCase();
  const permissionData = productStagewiseDocumentPermissionsDetails[productCatelog];

  if (isNotPermittedDocs()) {
    return true;
  } else {
    let currentProductPermissions: any[] = permissionData[securityDocKey]?.[currentWFStage] || permissionData["other_documents"]?.[currentWFStage] || [];

    let isDisabled = true;

    if (currentProductPermissions.length < 1) {
      isDisabled = true;
    } else {
      if (!currentProductPermissions.includes(privilegeKey)) {
        isDisabled = true;
      } else {
        isDisabled = false;
      }
    }

    return isDisabled;
  }
};

export const disableAccess = ({ documentKey = "", secDocumentFileId = "", documentComponentId = "", isTabInEditString = false, privilegeKeyforSec = "", privilegeKeyforOther = "" }) => {
  const { formData, currentWFStage, currentAssignee }: any = creditData.attach(Downgraded).get();
  const { type, sector, scheme } = formData?.creditData;
  const productStagewiseDocumentPermissionsDetails = productStageDocumentPermissionsState.attach(Downgraded).get();
  const { documentDetailsList }: any = documentUploadData.attach(Downgraded).get();
  const { userPrivilege, userId }: any = userDetails.attach(Downgraded).get();

  const productCatelog = `${type}-${sector}-${scheme}`;
  const securityDocKey = documentDetailsList[documentComponentId]?.documentKey.toLowerCase();

  documentComponentId = securityDocKey ? securityDocKey : documentComponentId;

  const securityDocumentIdArr = Object.keys(securityDocumentPermissions).map((key) => securityDocumentPermissions[key]);
  const specialValidationDocumentArr = ["post_disbursements_documents", "post_condition_documents", "security_document_upload", ...securityDocumentIdArr];

  const otherPrivilageKeys = Object.keys(userPrivilege || {}) || [];
  const closeStage = AppConfig?.config?.closeStage;

  if (currentAssignee !== userId) {
    return true;
  }

  if (currentWFStage === closeStage) {
    return true;
  }

  if (AppConfig?.config?.postDisburesed?.includes(currentWFStage)) {
    const stageWisePermissionArr = productStagewiseDocumentPermissionsDetails[productCatelog][documentComponentId]?.[currentWFStage] || [];

    if (stageWisePermissionArr.length === 0) {
      return true;
    } else if (!stageWisePermissionArr.includes(privilegeKeys[privilegeKeyforSec])) {
      return true;
    } else {
      return false;
    }
  }

  if (isTabInEditString) {
    // ****this value comes from the Data base (CREDIT_FILE_CONTROL_META) ----> If Edit String has the key, the items will be disabled**** //
    return true;
  }

  if (privilegeKeyforOther && !specialValidationDocumentArr.includes(documentComponentId)) {
    const documentId = specialValidationDocumentArr.includes(documentComponentId) ? securityDocKey : "other_documents";
    const stageWisePermissionArr = productStagewiseDocumentPermissionsDetails?.[productCatelog]?.[documentId]?.[currentWFStage] || [];

    if (stageWisePermissionArr.length === 0) {
      return true;
    } else if (!stageWisePermissionArr.includes(privilegeKeys[privilegeKeyforOther])) {
      return true;
    } else if (otherPrivilageKeys.includes(privilegeKeyforOther)) {
      return false;
    } else {
      return true;
    }
  }

  if (specialValidationDocumentArr.includes(documentComponentId)) {
    // ****special scenarios**** //
    const documentId = securityDocKey ? securityDocKey : documentComponentId;
    const stageWisePermissionArr = productStagewiseDocumentPermissionsDetails?.[productCatelog]?.[documentId]?.[currentWFStage] || [];

    let secDocPermission = false;

    if (stageWisePermissionArr.length < 1) {
      secDocPermission = true;
    } else {
      if (stageWisePermissionArr.includes(privilegeKeys[privilegeKeyforSec])) {
        secDocPermission = false;
      } else {
        secDocPermission = true;
      }
    }
    return secDocPermission;
  }

  return false;
};

export const unclassifiedDisableAccess = () => {
  const { disbursed, currentAssignee }: any = creditData.attach(Downgraded).get();
  const { userId }: any = userDetails.attach(Downgraded).get();

  if (disbursed === 1) {
    return true;
  }

  if (currentAssignee !== userId) {
    return true;
  }

  return false;
};
