
import FilterIcon from "@mui/icons-material/Sort";
import { Stack } from "@mui/material";
import { SearchSuffixComponent } from "../../../components/OtherComponents/DecisionEngineComponent/subComponents/SearchComponent";

export const SearchBox = ({ list, setList, children, filtering }) => {

    const handleSearch = (list, value) => {
        setList(list.filter(item => JSON.stringify(item).toLowerCase().includes(value.trim().toLowerCase())));
    }

    const updateFilterState = () => {
        if (!filtering) {
            return;
        }

        filtering.set(!filtering.get());
    }

    return <Stack flex={1} direction={"row"} alignItems={"center"} spacing={2}>
        <SearchSuffixComponent
            placeholder={"Search"}
            handleChangeSearchText={(text) => handleSearch(list, text)}
            right={
                <Stack px={1} onClick={updateFilterState}><FilterIcon color={filtering.get() ? "primary" : "disabled"} /></Stack>
            }
        />
        {children && <Stack>{children}</Stack>}
    </Stack>;
}
