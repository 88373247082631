import { useState } from "@hookstate/core";
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { masterDataDetails } from "../../../../../../configs/mainStore";
import { formatCurrency } from "../../../../../../services/currencyFormater";
import { SinglePageBreak } from "../../SecurityDocuments/CommonPageBreaks";

function LoanTerms({ data, disburseLoanAmount, applicableRate, loanTenor, repaymentDate, installmentValue, trialCalcResults, typeCaseClass }) {
  const [disburseLoanValue, setDisburseLoanValue]: any = React.useState(0.0);
  const [annualInterestRate, setAnnualInterestRate]: any = React.useState(0.0);
  const [disburseLoanTenor, setDisburseLoanTenor]: any = React.useState(0);
  const masterData: any = useState(masterDataDetails);
  const creditData: any = data?.formData?.creditData;
  const loanPurpose: any = masterData?.LOAN_PURPOSE?.get()?.find((t) => t.id == creditData?.loanPurpose);

  useEffect(() => {
    loanCreditDetails();
  }, [disburseLoanAmount, applicableRate, loanTenor, repaymentDate, installmentValue]);

  const loanCreditDetails = () => {
    let disburseLoanAmt: any = disburseLoanAmount || data?.formData?.creditData?.loanAmount || 0.0;
    setDisburseLoanValue(disburseLoanAmt);

    let disburseInterestRate = applicableRate || parseFloat(data?.formData?.creditData?.interestRate) || 0.0;
    let interestRebate = parseFloat(data?.formData?.creditData?.regularPaymentFactor) || 0.0;
    let annualInterestRate = disburseInterestRate + interestRebate;

    setAnnualInterestRate(annualInterestRate);

    let loanTenors = loanTenor || data?.formData?.creditData?.loanTerms || 0;
    setDisburseLoanTenor(loanTenors);


    let trialResults = trialCalcResults || [];
    let differentInstallments: any[] = [];

    if (trialResults.length > 0) {
      trialResults.forEach((element, index) => {
        if (index === 0 || index === trialResults.length - 1) {
          differentInstallments.push(element?.termlyInstallment);
        }
      });
    }
  };

  return (
    <Grid container columns={12} pl={1} pr={1}>
      <Grid item xs={12}>
        <h4 style={{ textDecoration: "underline" }}>TERMS OF THE LOAN</h4>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6} p={0.25}>
            Nature of facility
          </Grid>
          <Grid item xs={6} p={0.25} className={`security-doc-type-variable ${typeCaseClass}`}>
            {data?.scheme}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6} p={0.25}>
            Limit
          </Grid>
          <Grid item xs={6} p={0.25}>
            Rs. <span className={`security-doc-type-variable ${typeCaseClass}`}>{formatCurrency(disburseLoanValue, 2)}</span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6} p={0.25}>
            Purpose
          </Grid>
          <Grid item xs={6} p={0.25} className={`security-doc-type-variable ${typeCaseClass}`}>
            {loanPurpose?.name}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6} p={0.25}>
            Interest Rate
          </Grid>
          <Grid item xs={6} p={0.25}>
            {annualInterestRate}% ({data?.formData?.creditData?.regularPaymentFactor ?? 0.0}% interest rebate is available on regular repayment of loan)
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6} p={0.25}>
            Terms / Repayments
          </Grid>
          <Grid item xs={6} p={0.25} mb={1}>
            To be repaid in {disburseLoanTenor} installments with monthly interest.
          </Grid>
        </Grid>
      </Grid>
      <SinglePageBreak />
      <Grid item xs={12}>
        <TableContainer>
          <Table sx={{ minWidth: "100%", padding: "6px", marginTop: "6px" }} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow sx={{ border: 1 }}>
                <TableCell sx={{ width: "50%", border: 1, borderColor: " #000000" }} colSpan={2}>
                  <Typography mb={0.5}>Loan Tenor</Typography>
                </TableCell>
                <TableCell sx={{ width: "50%", border: 1, borderColor: " #000000" }} colSpan={2}>
                  <Typography mb={1} className={`${typeCaseClass}`}>
                    {disburseLoanTenor}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow sx={{ border: 1 }}>
                <TableCell sx={{ width: "50%", border: 1, borderColor: " #000000" }} colSpan={2}>
                  <Typography mb={0.5}>Grace Period</Typography>
                </TableCell>
                <TableCell sx={{ width: "50%", border: 1, borderColor: " #000000" }} colSpan={2}>
                  <Typography mb={1}>{creditData?.gracePeriod || "None"}</Typography>
                </TableCell>
              </TableRow>
              <TableRow sx={{ border: 1 }}>
                <TableCell sx={{ width: "50%", border: 1, borderColor: " #000000" }} colSpan={2}>
                  <Typography mb={0.5}>Installment</Typography>
                </TableCell>
                <TableCell sx={{ width: "50%", border: 1, borderColor: " #000000" }} colSpan={2}>
                  <Typography mb={1}>Rs.</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} mt={2}>
        Due date of the monthly installment will be .................................. of each month
      </Grid>
    </Grid>
  );
}

export default LoanTerms;
