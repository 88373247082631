import { Downgraded, useState } from "@hookstate/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { userDetails } from "../../../../../../../../configs/mainStore";
import { responseSuccessCode } from "../../../../../../../../utility/other";
import ErrorBoundary from "../../../../../../../ErrorHandleComponent/ErrorBoundary";
import { toast } from "../../../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../../../ProgressComponent/ProgressComponent";
import { getAbilitytoproposeloanData, getProfitLossAccountsData, saveAbilitytoproposeloanData } from "../../Api/AppraisalApi";
import Comments from "../../Helpers/Comments/Comments";
import MainHeader from "../../Helpers/MainHeader/MainHeader";
import { abilityToRepayLoanDefaultData } from "../../Stores/MicroAppraisalDefaultData";
import { abilityToRepayLoanData } from "../../Stores/MicroAppraisalStore";
import AbilityToPayLoanAndProposedLoanAmountSummaryLayout from "./AbilityToPayLoanAndProposedLoanAmountSummaryLayout";

const AbilityToPayLoanAndProposedLoanAmount = ({ applicationId, data }) => {
  const { t: translate } = useTranslation();
  const abilityToRepayLoanDataState = useState<any>(abilityToRepayLoanData);
  const isLoading = useState(true);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const { userId }: any = userDetails.attach(Downgraded).get();
  const [resData, setResData] = React.useState({});
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  useEffect(() => {
    getAbilityToPayLoanAndProposedLoanAmountData();

    return () => {
      abilityToRepayLoanDataState.set(JSON.parse(JSON.stringify(abilityToRepayLoanDefaultData)));
    }
  }, [])

  const getAbilityToPayLoanAndProposedLoanAmountData = async () => {
    try {
      const [data, res] = await Promise.all([getProfitLossAccountsData(applicationId), getAbilitytoproposeloanData(applicationId)]);

      setResData(res);

      if (Object.values(data).length === 0) {
        return;
      }
      abilityToRepayLoanDataState["selectedYears"].set(data["selectedYears"]);
      abilityToRepayLoanDataState["data"].set(data["totals"]);

    } catch (error) {
      throw new Error("Error on Fetching Data getAbilityToPayLoanAndProposedLoanAmountData | Micro | CreditAppraisal")
    } finally {
      isLoading.set(false);
    }
  }

  const handleSubmit = async (abilityToPayLoanState = {}) => {
    let defaultMsg = { status: "success", message: "" }

    try {
      const res: any = await saveAbilitytoproposeloanData({ applicationId: applicationId, data: abilityToPayLoanState });

      if (res?.status == responseSuccessCode) {
        defaultMsg.message = res?.data?.msg

      } else {
        defaultMsg.message = res?.data?.msg;
        defaultMsg.status = "error"
      }

    } catch (error) {
      throw new Error(`${error} | micro | AbilityToPayLoanAndProposedLoanAmount | handleSubmit`);
    } finally {
      toast(defaultMsg);
    }
  }

  if (isLoading.get()) return <CircularProgressComponent size={30} sx={{ top: '50%', right: '50%', position: 'absolute', zIndex: 100 }} />

  return (
    <ErrorBoundary>
      <MainHeader title={translate("abilityToRepayTheProposedLoan")} onSubmit={undefined}>
        <AbilityToPayLoanAndProposedLoanAmountSummaryLayout creditFileData={data} />
        <Comments setOpenModal={setOpenModal} openModal={openModal} handleSubmitData={handleSubmit} userId={userId} getResponseData={resData} applicationId={applicationId} isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee} />
      </MainHeader>
    </ErrorBoundary>

  )
};

export default AbilityToPayLoanAndProposedLoanAmount;




