import DeleteIcon from "@mui/icons-material/Delete";
import { Chip, Grid, Stack, Tooltip, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import { predisbursementChecklistItemRemove } from "../../../../services/creditFileApiCall";
import { formatCurrency } from "../../../../services/currencyFormater";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal, openGlobalModal } from "../../GlobalModal/GlobalModal";
import { addToaster } from "../../GlobalToast";
import { CircularProgressComponent } from "../../ProgressComponent/ProgressComponent";

interface PreviewRowProps {
  Icon?: any;
  subTxt: string;
  mainTxt: string;
  color?: any;
  designation?: string;
  secondaryTxt?: string;
  data?: any;
  applicationId?: any;
  setNewDataArray?: any;
  creditFileData?: any;
  actionDelete?: any;
  handleInstantDelete?: any;
  disableAccordingTo?: boolean;
  trialCalcResult;
  trialCalcParams;
  conditionType?: any;
  index?: any;
  recommendedInterestRate?: any;
  regularPaymentFactor?: any;
}

export const IconRowConditionComponent: FC<PreviewRowProps> = ({ Icon, subTxt = "", mainTxt, color, designation = "", secondaryTxt = "", data, applicationId, setNewDataArray, creditFileData, actionDelete, handleInstantDelete, disableAccordingTo = false, trialCalcResult, trialCalcParams, conditionType, index, recommendedInterestRate, regularPaymentFactor }) => {
  const [reload, setReload] = React.useState<boolean>(false);

  useEffect(() => {
    setTrialCalcCreditDetails();
  }, []);

  useEffect(() => {
    setTrialCalcCreditDetails();
  }, [trialCalcResult, trialCalcParams, recommendedInterestRate]);

  const onClickCreate = (modalID, label, id, element, placeholderJson) => {
    const placeholder = placeholderJson[id];
    const callBackOnAction = (response, type) => {
      response = placeholder?.format === "currency" ? formatCurrency(response) : response;
      placeholder.value = response;

      let updateTitle = data?.DESCRIPTION.replace(type, response);

      data.DESCRIPTION = updateTitle;

      if (type === "__interest_rebate__") {
        try {
          setReload(true);

          const rate = parseFloat(response);
          const annualRate = parseFloat(placeholderJson["__annual_rate__"]?.value ?? "0");
          const sum = rate + annualRate;
          data.DESCRIPTION = data.DESCRIPTION.replaceAll("__annual_rate__ + __interest_rebate__", sum);

          setTimeout(() => {
            setReload(false);
          }, 200);
        } catch (error) {
          data.DESCRIPTION = updateTitle;
        }
      }

      element.innerHTML = response;

      placeholderJson[type] = placeholder;
      data.PLACEHOLDERS = JSON.stringify(placeholderJson);
    };

    openGlobalModal({
      modalSize: "md",
      title: "",
      bodyId: modalID,
      close: false,
      modalParams: {
        applicationId: applicationId,
        callBackOnAction: callBackOnAction,
        modalHeading: label,
        modalInputLabel: label,
        type: id,
        placeholder,
      },
    });
  };

  function buildPrePopulatedText(placeholder) {
    let value = "";

    if (placeholder?.traverse.includes("??")) {
      const segments = placeholder?.traverse.split("??");
      for (let i = 0; i < segments.length; i++) {
        value = eval(`creditFileData.formData.${segments[i].trim()}`);
        if (value) {
          if ((value as any) instanceof String && value.length > 0) {
            continue;
          }
        } else if ((value as any) !== 0) {
          continue;
        } else if (value == null || value == undefined) {
          continue;
        } else {
          break;
        }
      }
    } else {
      value = eval(`creditFileData.formData.${placeholder?.traverse}`);
    }

    if (placeholder.format === "currency") {
      value = formatCurrency(value, 2);
    }

    placeholder.value = value;

    return value || "N/A";
  }

  function BuildConditionItem({ data }) {
    const placeholder = data.PLACEHOLDERS ?? data.palceholders;
    const placeholderJson = placeholder ? JSON.parse(placeholder) : null;

    if (!placeholderJson) {
      return <div>{`${data?.DESCRIPTION || data?.description}`}</div>;
    }

    if (Object.keys(placeholderJson).length === 0) {
      return <div>{`${data?.DESCRIPTION || data?.description}`}</div>;
    }

    const keys = Object.keys(placeholderJson);
    const segments = (data.DESCRIPTION || data?.description || "").split(" ");

    const getHintDetails = (hintDetailsStr = "") => {
      let formattedHints: any[] = [];

      if (!hintDetailsStr) {
        return null;
      }

      let hintDetailsArr = JSON.parse(JSON.stringify(hintDetailsStr));
      if (hintDetailsArr.length === 0) {
        return null;
      }

      for (const hint of hintDetailsArr) {
        formattedHints.push(
          <div>
            <b>{hint.key}</b> : {hint.value}
          </div>
        );
      }

      return <div>{formattedHints.map((obj) => obj)}</div>;
    };

    return (
      <div>
        {segments.map((segment) => {
          if (!segment) return "";

          if (!keys.includes(segment)) {
            return <span>{`${segment} `}</span>;
          }

          let placeholder = placeholderJson[segment];

          if (keys.includes(segment)) {
            if (placeholder?.type === "manual") {
              let label = segment.replaceAll("__", "").toUpperCase();
              let labelText = label.replaceAll("_", " ");
              return <Chip id={segment} size="small" label={`${placeholder?.value ?? label} `} sx={{ fontSize: "11px", marginRight: "0.5em" }} onClick={(evt) => onClickCreate("additional-conditions-parameters", labelText, segment, evt.target, placeholderJson)} variant="outlined" />;
            } else {
              const prePopulatedText = buildPrePopulatedText(placeholder);

              if (placeholder?.hint != null && placeholder?.hint != undefined && placeholder?.hint != "") {
                return (
                  <Tooltip title={<div>{getHintDetails(placeholder?.hint)}</div>} className="align-center">
                    <span>
                      <Chip id={segment} size="small" label={`${prePopulatedText}`} sx={{ fontSize: "11px", marginRight: "0.5em" }} />
                    </span>
                  </Tooltip>
                );
              }

              return <Chip id={segment} size="small" label={`${prePopulatedText}`} sx={{ fontSize: "11px", marginRight: "0.5em" }} />;
            }
          }

          placeholderJson[segment] = placeholder;

          return <span>{`${segment} `}</span>;
        })}
        {<div style={{ display: "none" }}>{(data.PLACEHOLDERS = JSON.stringify(placeholderJson))}</div>}
      </div>
    );
  }

  const onClickHandler = (data) => {
    if (conditionType == "offerCondition") {
      if (data?.ID === 0) {
        handleDelete(index);
      } else {
        deleteConfirm(data);
      }
    } else if (conditionType == "approvingCondition") {
      deleteConfirm(data);
    }
  };

  const deleteConfirm = (object) => {
    if (object.ID === 0) {
      actionDelete([object]);
      return;
    }
    openGlobalModal({
      modalSize: "sm",
      title: "Are you sure ?",
      bodyId: "condition-delete-confirm",
      close: false,
      modalParams: {
        data: { ...object, desc: object?.title || object.TITLE },
        callBackOnAction: onDeleteSection,
      },
    });
  };

  const handleDelete = (index) => {
    return handleInstantDelete(index);
  };

  const onDeleteSection = async (object: any) => {
    const condition = object.data;
    let conditionId = condition.ID || condition.id;
    if (!conditionId) {
      addToaster({
        status: "error",
        title: "Error on delete",
        message: "Missing required data!",
      });
    } else {
      try {
        if (conditionType == "approvingCondition") {
          let response = await predisbursementChecklistItemRemove(condition.ID);
          addToaster({
            status: "success",
            title: "Deleted",
            message: "Condition Item Deleted!",
          });
          actionDelete(response);
        } else if (conditionType == "offerCondition") {
          actionDelete(object);
        }
      } catch (error) {
        addToaster({
          status: "error",
          title: "Error on delete",
          message: "Error on condition deletion!",
        });
      }
    }
    closeGlobalModal();
  };

  const setTrialCalcCreditDetails = () => {
    let trailCalParams: any = trialCalcParams;
    let trialCalResult: any[] = trialCalcResult;

    if (trailCalParams != null && trailCalParams != undefined) {
      if (trailCalParams.applicableRate != null || trailCalParams?.LoanInterestRate != null || trailCalParams?.loanInterestRate != null) {
        let interestRate: any = trailCalParams?.applicableRate ?? trailCalParams?.LoanInterestRate ?? trailCalParams?.loanInterestRate ?? 0.0;
        let regularPayFactor: any = 0.0;

        if (creditFileData.formData.creditData.regularPaymentFactor != null) {
          regularPayFactor = parseInt(creditFileData.formData.creditData.regularPaymentFactor);
        } else {
          regularPayFactor = 0.0;
        }
        creditFileData.formData.creditData.calculatedAnnualInterestRate = interestRate + regularPayFactor || "N/A";
        creditFileData.formData.creditData.loanAmount = trailCalParams?.LoanAmount ?? trailCalParams?.loanAmount ?? 0.0;
      } else {
        let interestRate: any = parseInt(creditFileData.formData.creditData.interestRate ?? creditFileData.formData.creditData.loanInterestRate ?? 0.0);
        let regularPayFactor: any = 0.0;

        if (creditFileData.formData.creditData.regularPaymentFactor != null) {
          regularPayFactor = parseInt(creditFileData.formData.creditData.regularPaymentFactor);
        } else {
          regularPayFactor = 0.0;
        }
        creditFileData.formData.creditData.calculatedAnnualInterestRate = interestRate + regularPayFactor || "N/A";
      }

      if (trailCalParams.LoanTenure != null && trailCalParams.LoanTenure != undefined) {
        let loanTenture: any = trailCalParams.LoanTenure ?? 0;
        creditFileData.formData.creditData.LoanTenure = loanTenture;
      } else {
        let loanTenture: any = creditFileData.formData.creditData.loanTerms ?? 0;
        creditFileData.formData.creditData.LoanTenure = loanTenture;
      }
    } else {
      let interestRate: any = parseInt(creditFileData.formData.creditData.interestRate ?? creditFileData.formData.creditData.loanInterestRate ?? 0.0);
      let regularPayFactor: any = 0.0;

      if (creditFileData.formData.creditData.regularPaymentFactor != null) {
        regularPayFactor = parseInt(creditFileData.formData.creditData.regularPaymentFactor);
      } else {
        regularPayFactor = 0.0;
      }
      creditFileData.formData.creditData.calculatedAnnualInterestRate = interestRate + regularPayFactor || "N/A";

      let loanTenture: any = creditFileData.formData.creditData.loanTerms ?? 0;
      creditFileData.formData.creditData.LoanTenure = loanTenture;
    }
    if (trialCalResult != null && trialCalResult != undefined) {
      if (trialCalResult.length > 0) {
        if (trialCalResult[1]["termlyInstallment"] != undefined && trialCalResult[1]["termlyInstallment"] != null && trialCalResult[1]["termlyInstallment"] != "") {
          creditFileData.formData.creditData.maxMonthInstallment = trialCalResult[1]["termlyInstallment"];
        } else {
          creditFileData.formData.creditData.maxMonthInstallment = 0.0;
        }
      } else {
        creditFileData.formData.creditData.maxMonthInstallment = 0.0;
      }
    } else {
      creditFileData.formData.creditData.maxMonthInstallment = 0.0;
    }

    creditFileData.formData.creditData.disbursedInterestRate = recommendedInterestRate || 0
    creditFileData.formData.creditData.interestRateRebate = regularPaymentFactor || 0
  };

  return (
    <Stack direction="row" spacing={2} alignItems={"center"}>
      {Icon && (
        <Grid item>
          <Icon
            sx={{
              width: "20px",
              height: "20px",
              color: color,
              background: `${color}3d`,
              borderRadius: "30px",
              padding: "9px 10px",
            }}
          />
        </Grid>
      )}
      {reload === true ? (
        <CircularProgressComponent
          size={24}
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 100,
          }}
        />
      ) : (
        <Grid item xs={24} style={{ textAlign: "left" }}>
          {subTxt && (
            <Grid item xs={24}>
              <Typography variant="subtitle1">{subTxt}</Typography>
            </Grid>
          )}
          <Grid item xs={24} component="div">
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <BuildConditionItem data={data} />
            </div>
            {secondaryTxt && <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{secondaryTxt}</div>}
            {designation && (
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "small",
                }}
              >
                {designation}
              </div>
            )}
          </Grid>
        </Grid>
      )}
      <ButtonComponent
        iconbtn
        size="small"
        onClick={() => {
          onClickHandler(data);
        }}
        className="opacity-7"
        disabled={disableAccordingTo}
      >
        <DeleteIcon fontSize="small" />
      </ButtonComponent>
    </Stack>
  );
};
