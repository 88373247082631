import { useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Stack } from "@mui/material";
import React, { createRef, useEffect } from "react";
import { CAU_OFFICER_DOCUMENT_ID } from "../../../../../configs/constants/contants";
import { userDetails, workflowMasterData } from "../../../../../configs/mainStore";
import {
  createReferalSubworkflowReferral,
  getReferalSubworkflow
} from "../../../../../services/applicationApis";
import { uploadCauOfficerFile } from "../../../../../services/creditFileApiCall";
import { getUsersByGroupId } from "../../../../../services/userPrivilegeService";
import { closeBtn } from "../../../../../styles";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import ModalInputComponent from "../../../../InputComponents/ModalInputComponent/ModalInputComponent";
import { SelectComponent } from "../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { CMTextInput } from "../../../CommunicationService/CMTextInput";
import { FileUploadComponent } from "../../../DropzoneComponent/FileUploadComponent";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

const ReferModal = (params) => {
  const initialData = { title: "", note: "", user: "" };
  const showErrors = useState(false);
  const { wf_system_users }: any = useState(workflowMasterData);
  const referState: any = useState(initialData);
  const loginUser = useState(userDetails);
  const referId = params?.data?.referId;

  const docUploadRef: any = createRef();

  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [isDropDownUserListLoading, setDropdownUserListLoading] = React.useState<any>(false);
  const [dropdownUserList, setDropdownUserList] = React.useState<any>([]);
  const [referGroupsList, setReferGroupsList] = React.useState<any>([]);
  const [referGroup, setReferGroup] = React.useState<any>([]);

  const [subWorkflowData, setSubWorkflowData] = React.useState<any>([]);

  const [refresh, setRefresh] = React.useState(false);
  const [fileList, setFileList] = React.useState<any>([
    {
      ref: docUploadRef,
      isMandatory: true,
      allowedFormats: "application/pdf",
      maxFileSize: 4000000,
      documentKey: "",
    },
  ]);

  const saveRefer = async () => {
    setIsLoading(true);
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      const referralFlowData = subWorkflowData.filter((obj) => obj?.groupId === referGroup);

      const _referralFlowData = referralFlowData[0];

      const data = {
        applicationId: params?.applicationId,
        productID: _referralFlowData?.productID,
        stageId: _referralFlowData?.wfData?.stageId,
        createdBy: loginUser?.userId?.get(),
        groupId: referGroup,
        ...referralFlowData,
        ...referState.get(),
      };

      const referralSubFlowCreate = await createReferalSubworkflowReferral(data);

      if (referralSubFlowCreate?.status === 1) {
        addToaster({
          status: "success",
          title: "Sccuess",
          message: "Referral adding success",
        });
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: "Referral adding error",
        });
      }

      closeGlobalModal();
    }
    setIsLoading(false);
  };

  const closeRefer = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return referState.note.get() === "";
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  const getReferGroupId = async (groupID) => {
    setDropdownUserListLoading(true);
    let usersListData = await getUsersByGroupId(groupID);

    const correspondingValues = Object.keys(usersListData).map((key) => {
      const id = parseInt(key);
      const object = wf_system_users?.find((obj) => obj?.get().id === id);
      return {
        name: object?.fullName,
        id: object?.id,
      };
    });

    setDropdownUserList(correspondingValues);
    setDropdownUserListLoading(false);
  };

  useEffect(() => {
    (async function () {
      const getSubWorkflowData = await getReferalSubworkflow(params?.catalogKey);

      const referTypes: any = [];

      if (getSubWorkflowData?.length) {
        for (const item in getSubWorkflowData) {
          const subFlowData = getSubWorkflowData[item];

          referTypes.push({ id: subFlowData?.groupId, name: subFlowData?.groupName });
        }

        setSubWorkflowData(getSubWorkflowData ?? []);
      }

      setReferGroupsList(referTypes ?? []);
    })();
  }, []);

  const onClickAddBtn = () => {
    let tempFileList = fileList;
    tempFileList.push({
      //TODO LEGAL DOC GET FROM DB
      ref: docUploadRef,
      isMandatory: false,
      allowedFormats: "application/pdf",
      maxFileSize: 4000000,
      documentKey: "",
    });

    setFileList(tempFileList);
    setRefresh(!refresh);
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Create Referral</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Title"
            component={
              <TextBoxComponent
                value={referState?.title?.get()}
                onChange={(e) => {
                  resetError();
                  referState.title.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 100 }}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && referState.title.get() === ""}
          />
        </Grid>
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Note"
            component={
              <TextBoxComponent
                value={referState?.note?.get()}
                onChange={(e) => {
                  resetError();
                  referState.note.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                minRows={4}
                inputProps={{ maxLength: 100 }}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && referState.note.get() === ""}
          />
        </Grid>
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Refer Type"
            component={
              <SelectComponent
                onChange={(e) => {
                  resetError();
                  setReferGroup(e.target.value);
                  getReferGroupId(e.target.value);
                }}
                values={referGroupsList}
                value={referGroup}
                size="small"
                label={"Select Refer Type"}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && !referState.user.get()}
          />
        </Grid>
        {isDropDownUserListLoading ? (
          <CircularProgressComponent size={20} />
        ) : (
          dropdownUserList?.length > 0 && (
            <Grid container item xs={12}>
              <ModalInputComponent
                title="User"
                component={
                  <SelectComponent
                    onChange={(e) => {
                      resetError();
                      referState.user.set(e.target.value);
                    }}
                    values={dropdownUserList}
                    value={referState?.user?.get()}
                    size="small"
                    label={"Select User"}
                  />
                }
                isMandatory={false}
                showErrors={showErrors.get() && !referState.user.get()}
              />
            </Grid>
          )
        )}
        {referId && (
          <Grid item xs={12} md={12} className="">
            <CMTextInput
              desc={"Upload Document"}
              component={
                <FileUploadComponent
                  fileDetails={{
                    ref: docUploadRef,
                    isMandatory: true,
                    allowedFormats: "application/pdf",
                    maxFileSize: 4000000,
                    documentKey: "",
                  }}
                  uploadDataObject={{
                    referalId: params?.data?.referId,
                    documentId: CAU_OFFICER_DOCUMENT_ID,
                    applicationId: params?.applicationId,
                  }}
                  isPreviewHave={false}
                  fileUploadApi={uploadCauOfficerFile}
                />
              }
            />
          </Grid>
        )}
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeRefer}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={saveRefer}
          disabled={isLoading}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default ReferModal;
