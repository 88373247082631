import {
  validatelength,
  validatemaximumAmount,
  validatePositiveNumber,
  validateTextWithCharactersSpacesAndNumbers
} from '../../validation-rules/validationRules';

export const addressDetailsValidationOnNext = (changes) => {
  // ================== Contact Details Erros Object - Start ==================//

  const addressDetailsErrors: any = {};

  const permanantCityVal = changes?.permanentAddress?.city
    ? permanantCityValidation('Permanent City', changes?.permanentAddress?.city)
    : null;
  const permanantPostalCodeVal = changes?.permanentAddress?.postalCode
    ? permanantPostalCodeValidation('Permanent Postal Code', changes?.permanentAddress?.postalCode, 5)
    : null;
  const residenceYearsVal = changes?.currentResidenceYears
    ? currentResidenceYearsValidation('Residence Years', changes?.currentResidenceYears, 99)
    : null;
  const residenceMonthsVal = changes?.currentResidenceMonths
    ? currentResidenceMonthsValidation('Residence Months', changes?.currentResidenceMonths, 11)
    : null;
  const mailingCityVal = changes?.mailingAddressData?.city
    ? mailingCityValidation('Mailing City', changes?.mailingAddressData?.city)
    : null;
  const mailingPostalCodeVal = changes?.mailingAddressData?.postalCode
    ? mailingPostalCodeValidation('Mailing Postal Code', changes?.mailingAddressData?.postalCode, 5)
    : null;
  const previousCityVal = changes?.previousResidenceAddressData?.city
    ? previousCityValidation('Previous City', changes?.previousResidenceAddressData?.city)
    : null;
  const previousPostalCodeVal = changes?.previousResidenceAddressData?.postalCode
    ? previousPostalCodeValidation('Previous Postal Code', changes?.previousResidenceAddressData?.postalCode, 5)
    : null;

  if (permanantCityVal) addressDetailsErrors.permanantCity = permanantCityVal;
  if (permanantPostalCodeVal) addressDetailsErrors.permanantPostalCode = permanantPostalCodeVal;
  if (residenceYearsVal) addressDetailsErrors.residenceYears = residenceYearsVal;
  if (residenceMonthsVal) addressDetailsErrors.residenceMonths = residenceMonthsVal;
  if (mailingCityVal) addressDetailsErrors.mailingCity = mailingCityVal;
  if (mailingPostalCodeVal) addressDetailsErrors.mailingPostalCode = mailingPostalCodeVal;
  if (previousCityVal) addressDetailsErrors.previousCity = previousCityVal;
  if (previousPostalCodeVal) addressDetailsErrors.previousPostalCode = previousPostalCodeVal;

  return Object.keys(addressDetailsErrors).length > 0 ? addressDetailsErrors : 1;
  //================== Contact Details Erros Object - End ==================//
};

export const permanantCityValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkCity = validateTextWithCharactersSpacesAndNumbers(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkCity) result.push(checkCity);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

const permanantPostalCodeValidation = (fieldName, value, length) => {
  const result: any[] = [];
  const checkPostlCode = validatePositiveNumber(fieldName, value);
  const checkLength = validatelength(fieldName, value, length);
  if (checkPostlCode) result.push(checkPostlCode);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};
export const mailingCityValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkCity = validateTextWithCharactersSpacesAndNumbers(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkCity) result.push(checkCity);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

const mailingPostalCodeValidation = (fieldName, value, length) => {
  const result: any[] = [];
  const checkPostlCode = validatePositiveNumber(fieldName, value);
  const checkLength = validatelength(fieldName, value, length);
  if (checkPostlCode) result.push(checkPostlCode);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};
export const previousCityValidation = (fieldName, value) => {
  const result: any[] = [];
  const checkCity = validateTextWithCharactersSpacesAndNumbers(fieldName, value);
  const checkLength = validatelength(fieldName, value);
  if (checkCity) result.push(checkCity);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

const previousPostalCodeValidation = (fieldName, value, length) => {
  const result: any[] = [];
  const checkPostlCode = validatePositiveNumber(fieldName, value);
  const checkLength = validatelength(fieldName, value, length);
  if (checkPostlCode) result.push(checkPostlCode);
  if (checkLength) result.push(checkLength);
  return result.length > 0 ? result : null;
};

export const currentResidenceYearsValidation = (fieldName, value, maxLength) => {
  const result: any[] = [];
  const checkPositiveNumber = validatePositiveNumber(fieldName, value);
  const checkMaxValue = validatemaximumAmount(fieldName, value, maxLength);
  if (checkPositiveNumber) result.push(checkPositiveNumber);
  if (checkMaxValue) result.push(checkMaxValue);
  return result.length > 0 ? result : null;
};

export const currentResidenceMonthsValidation = (fieldName, value, maxValue) => {
  const result: any[] = [];
  const checkPositiveNumber = validatePositiveNumber(fieldName, value);
  const checkMaxValue = validatemaximumAmount(fieldName, value, maxValue);
  if (checkPositiveNumber) result.push(checkPositiveNumber);
  if (checkMaxValue) result.push(checkMaxValue);
  return result.length > 0 ? result : null;
};
