import { Downgraded, useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { creditData, renderingDetails } from "../../../../../configs/mainStore";
import { saveUIJobDataHandler } from "../../../../../services/apiCalls";
import { closeBtn } from "../../../../../styles";
import { responseSuccessCode } from "../../../../../utility/other";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { uiComponentMapping } from "./UIJobComponentMapping";

const WorkflowUIJobHandler = (params) => {
  const dataCollection = useState({});
  const syncing = useState(false);
  const validationError = useState("");
  const renderingDivDetails = useState(renderingDetails);
  const component = params?.jobResData?.componentId;
  const actionTypeMap = {
    component3: {
      save: "Assign",
    },
  };

  const validateComponentData = () => {
    if (component === "component3") {
      const payload: any = dataCollection?.get();
      if (!payload || !payload.newAssignee) {
        validationError.set("New Assignee can not be empty.");
        return false;
      }
    }

    return true;
  };

  const saveUIJobData = async () => {
    if (!validateComponentData()) {
      return;
    }

    syncing.set(true);
    const res: any = await saveUIJobDataHandler({
      componentId: params?.jobResData?.componentId,
      uiData: dataCollection?.get(),
      ...params?.wfData,
    });
    syncing.set(false);

    if (res?.status == responseSuccessCode) {
      addToaster({
        status: "success",
        title: res?.data,
        message: "Action Updated",
      });

      // after successfully workflow change the application is navigated to the application list
      renderingDivDetails.set({
        renderId: "manage-applications",
        renderParams: {},
      });
    } else {
      addToaster({
        status: "error",
        title: "Error !",
        message: res?.data,
      });
    }
    closeGlobalModal();
  };
  const closeUIJobData = () => {
    closeGlobalModal();
  };

  const creditFileData: any = useState(creditData);
  const creditDataResponse: any = creditFileData.attach(Downgraded).get();

  return (
    <Box>
      {/* Child Data Component Render Here */}
      {params?.jobResData?.componentId ? (
        React.createElement(uiComponentMapping[component], {
          dataCollection: dataCollection,
          wfData: params?.wfData,
          creditData: creditDataResponse,
          nextWorkflowStepId: params?.jobResData?.nextWorkflowStepId,
        })
      ) : (
        <></>
      )}

      {validationError.get() && (
        <Typography color={"error"} variant={"caption"} fontWeight={400}>
          {" "}
          {validationError.get()}{" "}
        </Typography>
      )}

      <Stack direction="row" spacing={2} justifyContent="space-between" pt={2}>
        <>
          <Box> </Box>
          {syncing.get() && <CircularProgressComponent size={24} />}
        </>
        <Stack direction={"row"}>
          <ButtonComponent
            title="Close"
            startIcon={<CloseIcon />}
            variant="contained"
            onClick={closeUIJobData}
            loadingbtn={true}
            style={closeBtn}
          />
          <ButtonComponent
            startIcon={<DoneIcon />}
            title={actionTypeMap[component]?.save ?? "Save"}
            variant="contained"
            onClick={saveUIJobData}
            loadingbtn={true}
            color="info"
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default WorkflowUIJobHandler;
