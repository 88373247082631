import { Grid } from "@mui/material";
import { SinglePageBreak } from "../../SecurityDocuments/CommonPageBreaks";
import CommonSignatureBlock from "../../SecurityDocuments/CommonSignatureBlock";

function Signatures({ data, branch, typeCaseClass }) {
  const personalData = data?.formData?.personalData;

  const renderNicDetails = (type = "", item: any = {}) => {
    let nic = "";
    if (type == "") {
      return null;
    }

    if (type == "PA") {
      if (personalData.newNic != null && personalData.newNic != "") {
        nic = `${personalData.newNic}`;
      } else if (personalData.oldNic != null && personalData.oldNic != "") {
        nic = `${personalData.oldNic}`;
      }
    } else if (type == "JB") {
      if (item?.personalData?.newNic != null && item?.personalData?.newNic != "") {
        nic = `${item?.personalData?.newNic}`;
      } else if (item?.personalData?.oldNic != null && item?.personalData?.oldNic != "") {
        nic = `${item?.personalData?.oldNic}`;
      } else {
        nic = "";
      }
    } else if (type == "GR") {
      if (item?.personalData?.newNic != null && item?.personalData?.newNic != "") {
        nic = `${item?.personalData?.newNic}`;
      } else if (item?.personalData?.oldNic != null && item?.personalData?.oldNic != "") {
        nic = `${item?.personalData?.oldNic}`;
      } else {
        nic = "";
      }
    }

    return nic;
  };

  return (
    <Grid container columns={12} p={1}>
      <Grid item xs={12} pb={2}>
        <div>
          Yours faithfully,
          <br /> For and on behalf of,
          <br /> PRADESHIYA SANWARDHANA BANK
        </div>
        <div className="ol-manager-signature-placeholder">..............................................</div>
        <div>
          Manager <span className={`security-doc-type-variable ${typeCaseClass}`}>{branch}</span> Branch
        </div>
      </Grid>
      <SinglePageBreak />

      <Grid item xs={12} pt={4} style={{ borderTop: "1px dashed #000000" }}>
        The Manager,
        <br />
        PRADESHIYA SANWARDHANA BANK,
        <br />
        <span className={`security-doc-type-variable ${typeCaseClass}`}>{branch}</span> Branch,
        <br />
        <br />
        I/ we acknowledge receipt of the original of this letter and confirmed our acceptance of the facility offered as stated therein.
      </Grid>
      <Grid item xs={12} mt={2}>
        <Grid container xs={12}>
          <CommonSignatureBlock creditFileData={data} typeCaseClass={typeCaseClass || ""} renderNicDetails={renderNicDetails} isThisDayAvailable={false} isDateAvailable={true} isSignAtAvailable={true} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Signatures;
