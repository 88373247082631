import LoadingButton from '@mui/lab/LoadingButton';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import { FC } from 'react';
import { ButtonPropsTypes } from '../../../configs/interfaces';

//-- Button Selector Function --
export const ButtonComponent: FC<ButtonPropsTypes> = ({
  id,
  loadingbtn,
  iconbtn,
  edge,
  disableRipple,
  disableFocusRipple,
  style,
  children,
  classes,
  onClick,
  color,
  disabled,
  endIcon,
  fullWidth,
  href,
  size,
  startIcon,
  variant,
  title,
  autoFocus,
  loading,
  sx,
  className,
}) => {
  if (iconbtn == true)
    return (
      <IconBtn
        id={`icon-btn-${id}`}
        color={color}
        classes={classes}
        disabled={disabled}
        disableRipple={disableRipple}
        disableFocusRipple={disableFocusRipple}
        size={size}
        variant={""}
        edge={edge}
        sx={sx}
        autoFocus={autoFocus}
        onClick={onClick}
        className={className}
        style={style}
      >
        {children}
      </IconBtn >
    ); // WHEN REQUIRE ICON BUTTON
  if (loadingbtn == true)
    // WHEN REQUIRE LOADING BUTTON
    return (
      <LoadingBtn
        id={`loading-btn-${id}`}
        loading={loading}
        style={style}
        onClick={onClick}
        color={color}
        disabled={disabled}
        endIcon={endIcon}
        fullWidth={fullWidth}
        href={href}
        size={size}
        startIcon={startIcon}
        variant={variant}
        title={title}
        autoFocus={autoFocus}
        sx={sx}
        loadingPosition="start"
      ></LoadingBtn>
    );

  // WHEN REQUIRE BASIC BUTTON ( Default option )
  return (
    <BasicBtn
      id={`basic-btn-${id}`}
      style={style}
      onClick={onClick}
      color={color}
      disabled={disabled}
      endIcon={endIcon}
      fullWidth={fullWidth}
      href={href}
      size={size}
      startIcon={startIcon}
      variant={variant}
      title={title}
      autoFocus={autoFocus}
      sx={sx}
    />
  );
};

//BASIC BUTTON
const BasicBtn: FC<ButtonPropsTypes> = ({
  style,
  onClick,
  color,
  disabled,
  endIcon,
  fullWidth,
  href,
  size,
  startIcon,
  variant,
  title,
  autoFocus,
  className,
  sx,
}) => {
  return (
    <>
      <Button
        style={style}
        onClick={onClick}
        color={color}
        disabled={disabled}
        endIcon={endIcon}
        fullWidth={fullWidth}
        href={href}
        size={size}
        startIcon={startIcon}
        variant={variant}
        autoFocus={autoFocus}
        sx={sx}
        className={className}
      >
        {title}
      </Button>
    </>
  );
};

//LOADING BUTTON
const LoadingBtn: FC<ButtonPropsTypes> = ({
  style,
  onClick,
  color,
  disabled,
  endIcon,
  fullWidth,
  href,
  size,
  startIcon,
  variant,
  autoFocus,
  loading,
  className,
  sx,
  title,
}) => {
  return (
    <>
      {/* <LoadingButton
        style={style}
        onClick={onClick}
        color={color}
        disabled={disabled}
        endIcon={endIcon}
        fullWidth={fullWidth}
        href={href}
        size={size}
        startIcon={startIcon}
        variant={variant}
        autoFocus={autoFocus}
        loading={loading}
        className={className}
        sx={sx}
      >
        hloo
      </LoadingButton> */}
      <LoadingButton
        style={style}
        // style={{ minWidth: '100px' }}
        onClick={onClick}
        className={className}
        startIcon={startIcon}
        variant={variant}
        disabled={disabled}
        autoFocus={autoFocus}
        endIcon={endIcon}
        loading={loading}
        loadingPosition="start"
        color={color}
      >
        {title}
      </LoadingButton>
    </>
  );
};

//ICON BUTTON
const IconBtn: FC<ButtonPropsTypes> = ({
  classes,
  color,
  disabled,
  disableFocusRipple,
  disableRipple,
  edge,
  size,
  onClick,
  sx,
  autoFocus,
  children,
  className,
  style,
}) => {
  return (
    <IconButton
      color={color}
      classes={classes}
      disabled={disabled}
      disableRipple={disableRipple}
      disableFocusRipple={disableFocusRipple}
      size={size}
      edge={edge}
      sx={sx}
      className={className}
      autoFocus={autoFocus}
      onClick={onClick}
      style={style}
    >
      {children}
    </IconButton>
  );
};
