import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { LetterofSetOffJBContent } from "./LetterofSetOffJBContent";

function LetterofSetOffJB(props) {
  const jointBorrowData: any[] = props?.data?.formData?.jointBorrowerData || [];

  return (
    <div>
      {jointBorrowData.length > 0 &&
        jointBorrowData.map((jb, index) => {
          const { initialsInFull, primaryLastName } = jb?.personalData;

          return (
            <>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index + 1}a-content`} id={`panel${index + 1}a-header`}>
                  <Typography>
                    Joint Borrower {index + 1} - {`${initialsInFull} ${primaryLastName}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <LetterofSetOffJBContent item={jb} data={props?.data} disableAccordingToTab={props?.disableAccordingToTab} isLoggedInUserNotTheAssignee={props?.isLoggedInUserNotTheAssignee} setDocumentDataUpdated={props?.setDocumentDataUpdated} currentApplicationWfData={props?.currentApplicationWfData} applicationId={props?.applicationId} />
                </AccordionDetails>
              </Accordion>
            </>
          );
        })}
    </div>
  );
}

export default LetterofSetOffJB;
