import { Stack } from '@mui/material';
import { FC } from 'react';
import appStrings from '../../../configs/constants/appStrings';
import {
  continueButtonStyle,
  loadingButtonStyle,
  resetButtonStyle
} from '../../../configs/constants/styleValues';
import { NavigationButtonsPropsTypes } from '../../../configs/interfaces';
import { StyledButton } from '../../InputComponents/ButtonComponent/StyledButton';

export const NavigationButtons: FC<NavigationButtonsPropsTypes> = ({
  onBack,
  onNext,
  noBack,
  noNext,
  loading,
  disabled = false,
}) => {
  //if noBack prop is passed from parent then back button will not render
  //if noNext prop is passed from parent then next button will not render
  //By defualt both buttons are rendering

  return (
    <Stack direction="row" spacing={2} justifyContent="end">
      {!noBack && ( // when no back button required
        <StyledButton styles={resetButtonStyle} title={appStrings?.common?.back} onClick={onBack} />
      )}
      {!noNext && ( // when no next button required
        <StyledButton
          styles={loading ? loadingButtonStyle : continueButtonStyle}
          title={appStrings?.common?.next}
          onClick={onNext}
          loading={loading}
          disabled={disabled}
        />
      )}
    </Stack>
  );
};
