import { Downgraded, useState } from "@hookstate/core";
import { Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { NumericFormat } from "react-number-format";
import { continueButtonStyle, loadingButtonStyle } from "../../../../../../../../configs/constants/styleValues";
import { creditData, masterDataDetails, userDetails } from "../../../../../../../../configs/mainStore";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import { StyledButton } from "../../../../../../../InputComponents/ButtonComponent/StyledButton";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";

export const AddLoanRequirementForm = ({ changeRequirementData, checkPrivilegeStatus, loanRequirementList }) => {
  const { userId }: any = userDetails.attach(Downgraded).get();

  const [description, setDescription] = React.useState("");
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [errorMessages, setErrorMessages] = React.useState<any>({});

  const creditDetailsState: any = useState(creditData);
  const { amount, formData }: any = creditDetailsState.attach(Downgraded).get();
  const [estimation, setEstimation] = React.useState<string>("");
  const masterData: any = useState(masterDataDetails);

  const loanPurpose = masterData?.LOAN_PURPOSE?.get()?.find((t) => t.id == formData?.creditData?.loanPurpose);

  useEffect(() => {
    getEstimationData();
  }, [loanRequirementList]);

  const getEstimationData = async () => {
    if (loanRequirementList instanceof Array) {
      const loanRequirementData = JSON.parse(JSON.stringify(loanRequirementList[0]));
      setEstimation(loanRequirementData?.estimationValue ?? "");
      setDescription(loanRequirementData?.description);
    } else {
      const { description, estimationValue, isDisabled } = loanRequirementList;
      setEstimation(estimationValue || "");
      setDescription(description || "");
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const theObjectToBePass = {
      isDisabled: true,
      estimationValue: estimation,
      description: description,
    };

    let errorArray = Object.values(errorMessages).filter((key) => key !== "");

    if (errorArray.length > 0) {
      setIsLoading(false);
      return;
    }

    await changeRequirementData(theObjectToBePass, userId);
    setIsLoading(false);
  };

  useMemo(() => {
    if (estimation === "") {
      setErrorMessages((prev) => ({
        ...prev,
        estimation: "Estimation Must Be Included...!",
      }));
      return;
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        estimation: "",
      }));
    }

    let disbursedAmount = parseFloat(amount);
    let estimationAmount = parseFloat(estimation);

    if (estimationAmount < disbursedAmount) {
      setErrorMessages((prev) => ({
        ...prev,
        estimation: "Estimation cannot be lesser than the loan amount...!",
      }));
      return;
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        estimation: "",
      }));
    }

    if (description === "") {
      setErrorMessages((prev) => ({
        ...prev,
        description: "Comment Must Be Included...!",
      }));
      return;
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        description: "",
      }));
    }

    setErrorMessages({});
  }, [estimation, description, amount]);

  const conterollerOfFunds = () => {
    const bankContributionAmount = parseFloat(amount);
    const totalAmount = parseFloat(estimation);
    const defaulPercentage = 100;

    if (errorMessages.estimation) return [];

    const borrowerContribution = () => {
      const borrowerContributionAmount = (totalAmount - bankContributionAmount) * defaulPercentage;

      const borrowerContributionPercentage = borrowerContributionAmount / totalAmount;

      let amount = Math.round(borrowerContributionAmount) / 100;

      return {
        amount: OnDemandValueFormatter.thousandSeparator(amount.toFixed(2)),
        percentage: Math.round(borrowerContributionPercentage * defaulPercentage) / 100,
      };
    };

    const bankContribution = () => {
      const bankContributionPercentage = (bankContributionAmount / totalAmount) * defaulPercentage;
      let amount = Math.round(bankContributionAmount * 100) / 100;

      return {
        amount: OnDemandValueFormatter.thousandSeparator(amount.toFixed(2)),
        percentage: Math.round(bankContributionPercentage * 100) / 100,
      };
    };

    const total = () => {
      return {
        amount: OnDemandValueFormatter.thousandSeparator(totalAmount.toFixed(2)),
        percentage: defaulPercentage,
      };
    };

    return [
      {
        id: "borrowerContribution",
        title: "Borrower Contribution",
        ...borrowerContribution(),
      },
      {
        id: "bankContribution",
        title: "Bank Contribution",
        ...bankContribution(),
      },
      {
        id: "total",
        title: "Total",
        ...total(),
      },
    ];
  };

  return (
    <Paper>
      <Stack flex={1} p={2}>
        <Stack flex={1} direction={"column"}>
          <Stack direction={"row"} spacing={1}>
            <Typography variant="subtitle2" color={"gray"} width={"150pt"}>
              Loan Purpose
            </Typography>
            <Typography variant="subtitle2" style={{ border: "solid 1px lightGrey", borderRadius: "3pt" }} p={0.75} px={2}>
              {loanPurpose?.name ?? "N/A"}
            </Typography>
          </Stack>

          <Stack direction={"row"} spacing={1} alignItems={"flex-start"} mt={4}>
            <Typography variant="subtitle2" color={"gray"} width={"150pt"}>
              Estimation
            </Typography>
            <Stack width={"25%"}>
              <NumericFormat
                customInput={TextBoxComponent}
                label={""}
                thousandSeparator={true}
                value={formatCurrency(estimation)}
                decimalScale={2}
                onValueChange={(e: any) => {
                  const { value } = e;
                  setEstimation(value);
                }}
                disabled={checkPrivilegeStatus}
                error={errorMessages.estimation}
              />
              {errorMessages.estimation && (
                <Typography variant="caption" color={"red"}>
                  {errorMessages.estimation}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>

        {conterollerOfFunds() && conterollerOfFunds().length > 0 && (
          <Stack flex={1} direction={"row"} alignItems={"flex-start"} mt={4} spacing={1}>
            <Typography variant="subtitle2" color={"gray"} width={"150pt"}>
              Contribution of the Funds
            </Typography>

            <Stack spacing={1} style={{ border: "solid 1px lightGrey", borderRadius: "3pt" }} width={"50%"} pb={2}>
              <Grid container columns={12} spacing={2}>
                <Grid item xs={12}>
                  <Grid container columns={12}>
                    <Grid item xs={5}>
                      <Typography variant="subtitle2" color={"gray"}></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2" color={"gray"}>
                        Amount
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="subtitle2" color={"gray"}>
                        Perecentage
                      </Typography>
                    </Grid>
                  </Grid>

                  {conterollerOfFunds().map((arrayItems: any) => (
                    <Grid container columns={12} key={arrayItems.id} spacing={2} mt={1}>
                      <Grid item xs={5} key={arrayItems.id}>
                        <Typography variant="subtitle2" color={"gray"}>
                          {arrayItems?.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} key={arrayItems.id}>
                        <Typography variant="subtitle2" color={"gray"}>
                          {arrayItems.amount}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} key={arrayItems.id}>
                        <Typography variant="subtitle2" color={"gray"}>
                          {arrayItems?.percentage} %
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        )}
        <Stack direction={"row"} spacing={1} alignItems={"flex-start"} mt={4} mb={2}>
          <Typography variant="subtitle2" color={"gray"} width={"150pt"}>
            Comment
          </Typography>
          <Stack width={"50%"}>
            <TextBoxComponent
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              multiline={true}
              minRows={5}
              maxRows={10}
              value={description}
              disabled={checkPrivilegeStatus}
              error={errorMessages.description}
            />
            {errorMessages.description && (
              <Typography variant="caption" color={"red"}>
                {errorMessages.description}
              </Typography>
            )}
          </Stack>
        </Stack>

        <Divider></Divider>
        <Stack direction={"row"} justifyContent={"flex-end"} mt={2}>
          <StyledButton styles={isLoading ? loadingButtonStyle : continueButtonStyle} title={"Save"} onClick={onSubmit} loading={isLoading} disabled={checkPrivilegeStatus} />
        </Stack>
      </Stack>
    </Paper>
  );
};
