import { Downgraded, useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Stack } from "@mui/material";
import React, { createRef, useEffect } from "react";
import { userDetails, workflowMasterData } from "../../../../../../configs/mainStore";
import {
    createReferalSubworkflowReferral,
    getReferalSubworkflow
} from "../../../../../../services/applicationApis";
import { getUsersByGroupId } from "../../../../../../services/userPrivilegeService";
import { closeBtn, select_basic } from "../../../../../../styles";
import AppConfig from "../../../../../../utility/AppConfig";
import AutoCompleteProxyComponent from "../../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import ModalInputComponent from "../../../../../InputComponents/ModalInputComponent/ModalInputComponent";
import { TextBoxComponent } from "../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../../GlobalToast";

const InsuranceDepartmentModal = (params) => {
    const insuranceDepartmentGroupId = AppConfig.config.insuranceDepartmentGroupId;

    const initialData = { title: "", note: "", user: "" };
    const showErrors = useState(false);
    const { wf_system_users }: any = useState(workflowMasterData);
    const referState: any = useState(initialData);
    const loginUser = useState(userDetails);

    const docUploadRef: any = createRef();

    const [isLoading, setIsLoading] = React.useState<any>(false);
    const [dropdownUserList, setDropdownUserList] = React.useState<any>([]);
    const [subWorkflowData, setSubWorkflowData] = React.useState<any>([]);

    const [refresh, setRefresh] = React.useState(false);
    const [fileList, setFileList] = React.useState<any>([
        {
            ref: docUploadRef,
            isMandatory: true,
            allowedFormats: "application/pdf",
            maxFileSize: 4000000,
            documentKey: "",
        },
    ]);

    const referGroup: any = insuranceDepartmentGroupId;

    const saveRefer = async () => {
        setIsLoading(true);
        const errorState = validateSave();
        if (errorState) {
            showErrors.set(true);
        } else {
            const referralFlowData = subWorkflowData.filter((obj) => obj?.groupId === referGroup);

            const _referralFlowData = referralFlowData[0];

            const data = {
                applicationId: params?.applicationId,
                productID: _referralFlowData?.productID,
                stageId: _referralFlowData?.wfData?.stageId,
                createdBy: loginUser?.userId?.get(),
                groupId: referGroup,
                ...referralFlowData,
                ...referState.get(),
            };

            const referralSubFlowCreate = await createReferalSubworkflowReferral(data);

            if (referralSubFlowCreate?.status === 1) {
                addToaster({
                    status: "success",
                    title: "Sccuess",
                    message: "Referral adding success",
                });
            } else {
                addToaster({
                    status: "error",
                    title: "Error",
                    message: referralSubFlowCreate?.data?.message || "Referral adding error",
                });
            }
        }

        if (params?.callBackOnAction && params?.callBackOnAction instanceof Function) {
            params?.callBackOnAction(true);
        }

        closeGlobalModal();
        setIsLoading(false);

        return true;
    };

    const closeRefer = () => {
        closeGlobalModal();
    };

    const validateSave = () => {
        return referState.note.get() === "";
    };

    const resetError = () => {
        if (showErrors.get()) {
            showErrors.set(false);
        }
    };

    useEffect(() => {
        (async function () {
            const getSubWorkflowData = await getReferalSubworkflow(params?.catalogKey);

            const referTypes: any = [];

            if (getSubWorkflowData?.length) {
                for (const item in getSubWorkflowData) {
                    const subFlowData = getSubWorkflowData[item];

                    referTypes.push({ id: subFlowData?.groupId, name: subFlowData?.groupName });
                }

                setSubWorkflowData(getSubWorkflowData ?? []);
            }

            let usersListData = await getUsersByGroupId(insuranceDepartmentGroupId);

            const correspondingValues = Object.keys(usersListData).map((key) => {
                const id = parseInt(key);

                const object = wf_system_users?.find((obj) => {
                    const objId = obj?.get().id;
                    return objId === id;
                }) || null;

                if (object) {
                    const _obj = object.attach(Downgraded).get();
                    return {
                        name: _obj?.fullName,
                        id: _obj?.id,
                    };
                }

                return null;
            });

            const filteredValues = correspondingValues.filter(value => value !== null);


            setDropdownUserList(filteredValues);
        })();
    }, []);

    const onClickAddBtn = () => {
        let tempFileList = fileList;
        tempFileList.push({
            //TODO LEGAL DOC GET FROM DB
            ref: docUploadRef,
            isMandatory: false,
            allowedFormats: "application/pdf",
            maxFileSize: 4000000,
            documentKey: "",
        });

        setFileList(tempFileList);
        setRefresh(!refresh);
    };

    return (
        <Box>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <div className="basic-font font-size-18 basic-font-color-bold">Create Insurance Policy</div>
            </Stack>

            <Grid container spacing={2} mt={1} className="modal-text-field">
                <Grid container item xs={12}>
                    <ModalInputComponent
                        title="Title"
                        component={
                            <TextBoxComponent
                                value={referState?.title?.get()}
                                onChange={(e) => {
                                    resetError();
                                    referState.title.set(e.target.value);
                                }}
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 100 }}
                            />
                        }
                        isMandatory={true}
                        showErrors={showErrors.get() && referState.title.get() === ""}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <ModalInputComponent
                        title="Note"
                        component={
                            <TextBoxComponent
                                value={referState?.note?.get()}
                                onChange={(e) => {
                                    resetError();
                                    referState.note.set(e.target.value);
                                }}
                                variant="outlined"
                                size="small"
                                multiline={true}
                                minRows={4}
                                maxRows={15}
                                inputProps={{ maxLength: 4000 }}
                            />
                        }
                        isMandatory={true}
                        showErrors={showErrors.get() && referState.note.get() === ""}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <ModalInputComponent
                        title="User"
                        component={
                            <AutoCompleteProxyComponent
                                sx={select_basic}
                                label="User"
                                value={referState?.user?.get()}
                                options={dropdownUserList}
                                onChange={(e) => {
                                    resetError();
                                    referState.user.set(e ? e.id : null);
                                }}
                                size="small"
                            />
                        }
                        isMandatory={false}
                        showErrors={showErrors.get() && !referState.user.get()}
                    />
                </Grid>
            </Grid>

            <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
                <ButtonComponent
                    title="Close"
                    startIcon={<CloseIcon />}
                    variant="contained"
                    onClick={closeRefer}
                    loadingbtn={true}
                    style={closeBtn}
                />
                <ButtonComponent
                    startIcon={<DoneIcon />}
                    title="Save"
                    variant="contained"
                    onClick={saveRefer}
                    disabled={isLoading}
                    loadingbtn={true}
                    color="info"
                />
            </Stack>
        </Box>
    );
};

export default InsuranceDepartmentModal;
