export const primaryBtn = {
  background: "#027bd1",
  color: "white",
  fontWeight: "bold",
  fontFamily: "arial",
  width: "100px",
};

export const hiddenBtn = {
  fontWeight: "bold",
  fontFamily: "arial",
  width: "100px",
};

export const borderdIconButton = { border: "1px solid grey" };

export const select_small = { minWidth: "150px" };
export const select_basic = { minWidth: "200px" };
export const select_basic_ins = { minWidth: "350px", maxWidth: "350px" };
export const longSelect = { minWidth: "240px" };
export const longTextInput = { minWidth: "600px" };

export const textBox_color = { backgroundColor: "#D3D3D3", fontWeight: "bold" };

export const longTextBox = { minWidth: "300px" };

export const devCollapsed = {
  height: "60px",
  overflow: "hidden",
};

export const devUnCollapsed = {
  height: "auto",
  overflow: "hidden",
};

export const loadingText = {
  fontFamily: "arial",
  letterSpacing: "1px",
};

export const templatePanelShow = {
  borderRadius: "8px",
  padding: "10px",
  height: "auto",
  overflow: "hidden",
  background: "#fff",
  margin: "15px",
  "box-shadow": " 0px 10px 10px #747A880D",
};

export const templatePanelHide = {
  borderRadius: "8px",
  padding: "10px",
  height: "50px",
  overflow: "hidden",
  background: "#fff",
  margin: "15px",
  "box-shadow": " 0px 10px 10px #747A880D",
};

export const panelExpandIconBox = {
  marginLeft: "auto",
};
export const NavigationButtons = {
  marginLeft: "auto",
};

// *************************** workflow styles **************** /

export const WorkflowCustomEdge = {
  background: "transparent",
  width: "220px",
  height: "220px",
  // fontSize: "12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "150px",
};

export const WorkflowCustomEdgeIcons = {
  // color: "#7c7c7c",
  // width: "14px",
  // height: "14px",
  // background: "#eee",
  // border: "1px solid rgb(85 85 85)",
  cursor: "pointer",
  // borderRadius: "50%",
  // fontSize: "11px",
  // lineHeight: "1",
  margin: "1px",
  padding: "2px",
};

export const WorkflowEdgeLabel = {
  fontSize: "14px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // background: "#eee",
  // color: "#000",
  // border: "1px solid #fff",
  // borderRadius: "10%",
  padding: "0.4em 0.6em",
  // margin: "0.2em",
  fontWeight: "bold",
};

export const WorkflowCustomNode = {
  border: "1px solid #3c84bb",
  padding: "8px",
  borderRadius: "5px",
  // color: "#222",
  backgroundColor: "#eef3f7",
  cursor: "grab",
  fontSize: "12px",
  margin: "5px",
};

export const WorkflowCustomNodeDotInput = {
  // background: "green",
  width: "8px",
  height: "8px",
  backgroundColor: "#4386be",
};

export const WorkflowCustomNodeDotOutput = {
  // background: "green",
  width: "8px",
  height: "8px",
  backgroundColor: "#4386be",
};

export const WorkflowCustomNodeContainer = {
  // height: "70px",
  width: "250px",
};

export const WorkflowCustomNodeTitle = {
  fontSize: "14px",
  fontWeight: "bold",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  // background: "#d3d2e5",
  borderRadius: "4px",
};

export const WorkflowCustomNodeIcons = {
  color: "#4386bebf",
  padding: "0 2px",
  fontSize: "1.5rem",
  cursor: "pointer",
};

export const ModalBasic = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 720,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  borderRadius: "4px",
  p: 3,
  overflowY: "auto",
  maxHeight: "88vh",
};

export const ModalStageView = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1024,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "4px",
  p: 3,
  overflowY: "auto",
  maxHeight: "88vh",
};

export const modalActionButtonContainer = {
  display: "flex",
  alignItems: "center",
  // padding: "8px",
  justifyContent: "flex-end",
  flex: "0 0 auto",
  marginTop: "1rem",
  gap: "1rem",
};

export const modalActionButton = {
  // fontWeight: "bold",
  marginLeft: "0.5rem",
  backgroundColor: "#4386be",
};

export const ModalTitle = {
  fontWeight: "bold",
};

export const ModalDirectionTitle = {
  fontSize: "1rem",
};

export const ModalBody = {
  padding: "1rem 0 0 0",
};

export const ModalInputLabel = {
  fontSize: "14px",
};

export const ModalInputTuple = {
  padding: "0 1.5rem",
};

export const viewModalLabel = {
  background: "#eeeeee",
  borderRadius: "5%",
  fontWeight: "bold",
  padding: "5px 20px",
};

export const viewModalActionLabel = {
  // background: "#b1b1b7",
  // borderRadius: "5%",
  fontWeight: "bold",
  lineHeight: "0.7em",
  fontSize: "0.8em",
  // padding: "5px 20px",
};

export const permissionChip = {
  margin: " 0 5px",
  padding: " 0 3px",
};

export const WorkflowItemIconStyle = {
  justifyContent: "center",
  alignItems: "center",
  background: "#f3f6f9",
  borderRadius: "5px",
};

export const VerticalAlignItem = {
  margin: "0",
  top: "50%",
  fontSize: "14px",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
};

export const EnableChip = {
  margin: "5px",
  padding: "4px 8px",
  background: "#C9F7F5",
  borderRadius: "5px",
  color: "#1BC5BD",
};

export const DisableChip = {
  margin: "5px",
  padding: "4px 8px",
  background: "#FFF4DE",
  borderRadius: "5px",
  color: "#FFA800",
};

export const WorkflowOverviewActionIcons = {
  margin: "0 4px",
  background: "#f5f5f5",
  padding: "2px 4px",
  borderRadius: "5px",
  cursor: "pointer",
  color: "#b4b4b4",
};

export const manageApplicationFormIcons = {
  margin: "0 4px",
  padding: "2px 4px",
  cursor: "pointer",
  // color: "#b4b4b4",
};

export const ProductMappingHeadingText = {
  fontSize: "16px",
  padding: "0.25rem 1rem 0.25rem 1rem",
  fontWeight: "bold",
  background: "#efefef",
};

export const ProductMappingHeadingOption = {
  fontSize: "13px",
  padding: "0.1rem 0.5rem 0.5rem 0.5rem",
};

export const WorkflowProductSelectText = {
  fontSize: "14px",
  padding: "0 1rem",
};
export const WorkflowProductSelectIcon = {
  fontSize: "20px",
  padding: "0 0.5rem",
  color: "green",
  fontWeight: "bold",
};

export const productTypesPaper = {
  // backgroundColor : "#efefef"
  // padding: "0 0.5rem",
  // margin : '0.5rem 0'
};
export const productTypesSummaryPaper = {
  backgroundColor: "#efefef",
  // border: "1px solid #cfcfcf",
  padding: "0.7rem 0.2rem",
  // margin: "0.05rem 0.1rem",
};
export const productTypesSummaryPaperFont = {
  padding: "0 5px",
  fontSize: "16px",
  fontWeight: "bold",
};
export const productTypesSummaryPaperFontSelected = {
  fontSize: "13px",
  fontWeight: "500",
};
export const productListItemsRow = {
  border: "1px solid #cfcfcf",
  padding: "0.3rem 0.2rem",
  margin: "0.05rem 0.1rem",
  background: "#efefef",
  cursor: "pointer",
};
export const productListItemsRowSelect = {
  border: "1px solid #cfcfcf",
  padding: "0.3rem 0.2rem",
  margin: "0.05rem 0.1rem",
  background: "#e1e0e0",
  cursor: "pointer",
};
export const listTableContainer = {
  padding: "0.2rem !important",
  maxHeight: "26vh",
  width: "100%",
  height: "100%",
  overflowY: "scroll",
};

export const descriptionContainer = {
  maxHeight: "26vh",
  width: "100%",
  height: "100%",
};

export const SchemeDescriptionContainer = {
  maxHeight: "200px",
  width: "100%",
  height: "100%",
};

export const loginButtonStyles = {
  width: "100%",
  height: "48px",
  backgroundColor: "#454C66 !important",
  marginLeft: "inherit",
  color: "white",
};

export const assignButton = {
  // margin: "0.15rem 0",
  // lineHeight: "1.75",
  // padding: "3px 15px",
  width: "100%",
  height: "100%",
  fontWeight: "bold",
  background: "#b4b4b4",
};

export const MappedProductHeading = {
  fontSize: "16px",
  padding: " 0.75rem 1rem",
  fontWeight: "bold",
  background: "#efefef",
};

export const MappedWorkflowText = {
  fontSize: "14px",
  padding: "0.5rem",
  // fontWeight: "bold",
};

export const MappedWorkflowChipProduct = {
  padding: "0.4rem",
  margin: "0.4rem",
  background: "#6aa84f",
  color: "#fff",
  fontWeight: "bold",
};
export const MappedWorkflowChipApplicant = {
  padding: "0.4rem",
  margin: "0.4rem",
  background: "#a64d79",
  color: "#fff",
  fontWeight: "bold",
};

export const MappedWorkflowHeading = {
  fontSize: "14px",
  padding: "0.5rem",
};

export const MappedWorkflowRowContainer = {
  margin: "0.25rem 0rem",
  padding: "0.5rem",
  background: "#efefef",
};

export const iconCircle = {
  width: "16px",
  height: "18px",
  color: "#1976d2",
  background: "rgba(67, 134, 190, 0.1)",
  borderRadius: "30px",
  padding: "9px 10px",
  // opacity: "0.7",
};

export const workflowDrawActionButton = {
  backgroundColor: "#4386be",
  padding: "8px 20px",
  justifyContent: "flex-start",
  color: "white",
  margin: "5px !important",
  "&:hover": {
    background: "#4386be",
    opacity: "90%",
  },
};

// *************************** workflow styles finished **************** /

export const partialViwerCard = { border: "1px solid #dbdbdb" };

export const modelStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
};

export const inputGroup = {
  border: "0.5px solid #CED4DA;",
  borderRadius: "8px",
};

// *************************** Calculator Screen styles  **************** /

export const repaymentTypeBtnStyles = (selected, isLeftBtn, isRightBtn) => {
  return {
    width: "170px",
    height: "42px",
    background: selected ? "#4386BE" : null,
    border: selected ? null : " 0.5px solid #bfbdb8",
    color: selected ? " #FFFFFF" : "#4386BE",
    opacity: 1,
    textAlign: "center",
    fontFamily: "Poppins, Medium ",
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: "0px",
    borderRadius: isLeftBtn
      ? "6px 0px 0px 6px"
      : isRightBtn
        ? "0px 6px 6px 0px"
        : null,
    "&:hover": {
      // height: "38px",
      fontFamily: "Poppins !important",
      // fontSize: " 16px",
      textAlign: "center",
      letterSpacing: "0px",
      color: "#ffffff",
      opacity: 1,
      background: " #5ba0d9 0% 0% no-repeat padding-box",
      // borderRadius: "6px",
      border: "none",
    },
  };
};

export const loanAmountSlider = {
  color: "#747A88",
  height: "1px",
  "& .MuiSlider-track": {
    border: "none",
  },
};

// *************************** Calculator Screen styles finished **************** /

// *************************** Loan Type Detector Screen styles  **************** /

export const loanTypeSelectButton = (
  selected,
  startBtn = false,
  endBtn = false
) => {
  return {
    width: 197.25,
    height: 48,
    background: selected
      ? "#4386BE 0% 0% no-repeat padding-box"
      : "#FFFFFF 0% 0% no-repeat padding-box",
    border: "0.5px solid #4386BE",
    borderRadius: startBtn ? "6px 0px 0px 6px" : endBtn ? "0px 6px 6px 0px" : 0,
    opacity: 1,
    textAlign: "center",
    font: "normal normal 600 14px/19px Poppins",
    letterSpacing: "0px",
    color: selected ? "#FFFFFF" : "#4386BE",
    "&:hover": {
      width: 197.25,
      height: 48,
      background: "#5ba0d9",
      border: "0.5px solid #4386BE",
      borderRadius: startBtn
        ? "6px 0px 0px 6px"
        : endBtn
          ? "0px 6px 6px 0px"
          : 0,
      opacity: 1,
      textAlign: "center",
      font: "normal normal 600 14px/19px Poppins",
      letterSpacing: "0px",
      color: "#FFFFFF",
    },
  };
};

// *************************** Loan Type Detector Screen styles finished  **************** /

// *************************** Manage Applications Screen styles  **************** /

export const filterButton = {
  textAlign: "left",
  letterSpacing: "0px",
  color: "#FFFFFF",
  width: "120px",
  height: "55px",
  background: "#747A88 0% 0% no-repeat padding-box",
  borderRadius: "6px",
  opacity: 1,
};
// *************************** Manage Applications Screen styles  **************** /

// *************************** Online Application Screen styles  **************** /

export const IndividualButton = (selected) => ({
  width: 150,
  height: 48,
  background: selected ? "#4386BE" : null,
  border: selected ? null : " 0.5px solid #bfbdb8",
  borderRadius: "6px 0px 0px 6px",
  opacity: 1,
  textAlign: "center",
  fontFamily: "Poppins, Medium ",
  fontSize: "14px",
  letterSpacing: "0px",
  color: selected ? "#FFFFFF" : "#4386BE",
  "&:hover": {
    paddingLeft: "2%",
    paddingRight: "2%",
    // height: "38px",
    fontFamily: "Poppins !important",
    // fontSize: " 16px",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#ffffff",
    opacity: 1,
    background: " #5ba0d9 0% 0% no-repeat padding-box",
    // borderRadius: "6px",
    border: "none",
  },
});

export const corporateButton = (selected) => ({
  width: 150,
  height: 48,
  border: selected ? null : " 0.5px solid #bfbdb8",
  background: selected ? "#4386BE" : null,
  borderRadius: "0px 6px 6px 0px",
  opacity: 1,
  textAlign: "center",
  fontFamily: "Poppins, Medium ",
  fontSize: "14px",
  letterSpacing: "0px",
  color: selected ? "#FFFFFF" : "#4386BE",
  "&:hover": {
    width: 150,
    height: 48,
    border: selected ? null : " 0.5px solid #bfbdb8",
    background: "#5ba0d9",
    borderRadius: "0px 6px 6px 0px",
    opacity: 1,
    textAlign: "center",
    fontFamily: "Poppins, Medium ",
    fontSize: "14px",
    letterSpacing: "0px",
    color: "#FFFFFF",
  },
});

export const onlineAppSearchButton = {
  width: "150px",
  height: "48px",
  background: "#4386be",
  opacity: 1,
  textAlign: "center",
  fontFamily: "Poppins, Medium ",
  fontSize: "14px",
  letterSpacing: "0px",
  color: "#ffffff",
  marginLeft: "5px",
  "&:hover": {
    paddingLeft: "2%",
    paddingRight: "2%",
    height: "48px",
    fontFamily: "Poppins !important",
    // fontSize: " 16px",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#ffffff",
    opacity: 1,
    background: " #5ba0d9 0% 0% no-repeat padding-box",
    borderRadius: "6px",
    border: "none",
  },
};

export const onlineAppClearButton = {
  width: "150px",
  height: "48px",
  opacity: 1,
  textAlign: "center",
  fontFamily: "Poppins, Medium ",
  fontSize: "14px",
  letterSpacing: "0px",
  color: "#ababab",
  marginLeft: "5px",
  border: "1px solid #ababab",
};

export const MajorActionButton = {
  background: "#454C66",
  width: "220px",
  textAlign: "end",
  fontSize: "16px",
  letterSpacing: "0px",
  color: "#FFFFFF",
  opacity: "1",
  "&:hover": {
    background: "#454C66",
    opacity: "90%",
  },
};

export const normalActionButton = {
  background: "#4386be",
  opacity: 1,
  textAlign: "center",
  fontFamily: "Poppins, Medium ",
  fontSize: "14px",
  color: "#FFFFFF",
  letterSpacing: "0px",
  "&:hover": {
    background: "#4386be",
    opacity: "75%",
  },
};

export const selectButtonBackgroundColor = {
  backgroundColor: "#4386be",
  "&:hover": {
    background: "#4386be",
    opacity: "75%",
  },
};

export const iconPendingCircle = {
  width: "16px",
  height: "18px",
  color: "#fbc700",
  background: "#fbc7003d",
  borderRadius: "30px",
  padding: "9px 10px",
};

export const iconApprovedCircle = {
  width: "16px",
  height: "18px",
  color: "#008c01",
  background: "#008c013d",
  borderRadius: "30px",
  padding: "9px 10px",
};

export const iconRejectedCircle = {
  width: "16px",
  height: "18px",
  color: "#ff0000",
  background: "#ff00003d",
  borderRadius: "30px",
  padding: "9px 10px",
};

export const createButton = {
  background: "#2196f3",
  opacity: 1,
  textAlign: "center",
  fontFamily: "Poppins, Medium ",
  color: "#FFFFFF",
  padding: "5px 20px",
  letterSpacing: "0px",
  "&:hover": {
    background: "#1976d2",
    opacity: "75%",
  },
};

export const closeBtn = {
  backgroundColor: "#80808000",
  color: "gray",
  border: "none",
  boxShadow: "none",
};

export const rejectBtn = { backgroundColor: "#f90000" };

export const doneBtn = { backgroundColor: "#00d502" };

export const assetsDataTableHeaderCell = {
  color: "#747a88",
  fontSize: 14,
  opacity: 0.7,
  textAlign: "left",
};
export const inputHeaderCellStyle = { padding: 2 };
export const inputCellStyle = { borderBottom: "none", paddingRight: 1 };
export const inputCellStyleInAssetsGross = { borderBottom: "none" };
export const inputCellStyleInAssetsMarketValue = { borderBottom: "none" };
export const descriptionCellStyle = { margin: 0, paddingTop: 0 };

export const cursorPointer = { cursor: "pointer" };

export const inputBoxStyles = {
  display: "flex",
  justifyContent: "spaceBetween",
  alignItems: "left",
  marginTop: 10,
  marginRight: 10,
};

export const multipleSelectStyles = {
  padding: "0px !important",
};

export const summaryAppraisalTableHeaders = {
  backgroundColor: "#BFBFBF",
  fontWeight: "bold",
  fontFamily: "Poppins",
  border: "2px solid #000",
  fontSize: "16px"
};

export const summaryAppraisalSalesPurchaseHeaders = {
  fontWeight: "bold",
  fontFamily: "Poppins",
  border: "2px solid #000",
  fontSize: "16px"
};

export const summaryAppraisalData = {
  fontFamily: "Poppins",
  border: "2px solid #000",
  fontSize: "12px"
};

export const balanceSheetProfitLossData = {
  fontFamily: "Poppins",
  border: "2px solid #000",
  fontSize: "16px"
}

export const summaryAppraisalImpFigures = {
  fontWeight: "bold",
  backgroundColor: "#D9D9D9",
  fontFamily: "Poppins",
  border: "2px solid #000",
  fontSize: "14px"
};