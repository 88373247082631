import CloseIcon from "@mui/icons-material/Close";
import { Stack, Typography } from "@mui/material";
import React from "react";
import Avatar from "react-avatar";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import ScoreCardRow from "./ScoreCardRow";

const ScoreCardLayout = (params) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dataObject = params?.item || {};
  const templateName = params?.templateName || {};

  const calculateTotalScore = (obj) => {
    let totalScore = 0;
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key) && !isNaN(parseFloat(obj[key].score))) {
        totalScore += parseFloat(obj[key].score);
      }
    }
    return totalScore;
  };

  for (const groupKey in dataObject) {
    if (Object.hasOwnProperty.call(dataObject, groupKey)) {
      const group = dataObject[groupKey];
      const totalScore = calculateTotalScore(group);
      group["totalScore"] = totalScore;
    }
  }

  return (
    <>
      {isLoading ? (
        <CircularProgressComponent
          size={30}
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 100,
          }}
        />
      ) : (
        <>
          <Stack direction={"row"} justifyContent={"space-between"} flex={2}>
            <Stack flex={1}>
              <Typography variant="subtitle1" color={"gray"} sx={{ p: 2 }}>
                {templateName.name || ""}
              </Typography>
            </Stack>

            <Stack direction={"row"} spacing={{ md: 10 }} flex={1} justifyContent={"flex-end"}>
              <Stack className=" align-center">
                <div className={`basic-font basic-font-color opacity-7 font-size-12`}>{"Total Score"}</div>
                <div className={`basic-font font-size-28`} style={{ color: templateName.grade?.color }}>
                  {templateName.value}
                </div>
              </Stack>
              <Stack className=" align-center">
                <div className={`basic-font basic-font-color opacity-7 font-size-12`}>{"Grade"}</div>
                <Avatar
                  round={true}
                  value={templateName.grade?.value}
                  size={"40px"}
                  color={templateName.grade?.color}
                />
              </Stack>
            </Stack>
          </Stack>

          {Object.keys(dataObject).map((key) => {
            const value = dataObject[key];
            return (
              <Stack>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  style={{ maxWidth: "100%", backgroundColor: "#e6f0fa" }}
                  mt={3}
                  p={2}
                >
                  <Stack>
                    <div className="basic-font basic-font-color-bold font-size-16 align-left">{key}</div>
                  </Stack>
                  <Stack>
                    <div className="basic-font basic-font-color-bold font-size-16 align-left">{value.totalScore}</div>
                  </Stack>
                </Stack>
                {Object.keys(value).map((innerKey) => {
                  return (
                    innerKey !== "totalScore" && ( // Check if innerKey is not 'totalScore'
                      <ScoreCardRow key={innerKey} item={innerKey} value={value[innerKey]} />
                    )
                  );
                })}
                {/* </Grid> */}
              </Stack>
            );
          })}
        </>
      )}

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={params.handleClose}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </>
  );
};

export default ScoreCardLayout;
