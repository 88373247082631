import { Downgraded, useHookstate } from "@hookstate/core";
import { InputAdornment, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { CORE_BANK_KEYS } from "../../../../configs/constants/contants";
import { privilegeKeys } from "../../../../configs/constants/privilegeKeys";
import { creditData, userDetails } from "../../../../configs/mainStore";
import { getCoreBankData, setCoreBankData } from "../../../../services/bankServiceInvorker";
import { formatCurrency } from "../../../../services/currencyFormater";
import { getIsDisbursed } from "../../../../utility/helpers/getIsDisbursed";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { addToaster } from "../../GlobalToast";
import InnerSectionHeaderComponent from "./InnerSectionHeaderComponent";

const CompulsorySavings = ({ data, applicationId, innerComponentData }) => {
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  const [compulsorySavings, setComplusorySavings]: any = useState("");
  const updating: any = useHookstate(false);
  const creditFileData: any = creditData.attach(Downgraded).get();
  const disbursed = creditFileData.disbursed;

  useEffect(() => {
    (async function () {
      const selectedAccountObj = await getCoreBankData(applicationId, CORE_BANK_KEYS.INVESTMENT_AMOUNT);
      if (selectedAccountObj) {
        setComplusorySavings(selectedAccountObj);
      }
    })();
  }, []);

  const handleSave = async () => {
    if (compulsorySavings !== "") {
      updating.set(true);

      const res = await setCoreBankData(applicationId, CORE_BANK_KEYS.INVESTMENT_AMOUNT, compulsorySavings);

      if (res?.message) {
        addToaster({
          status: "success",
          title: "Success",
          message: res?.message,
        });
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: "Not Updated",
        });
      }
    } else {
      addToaster({
        status: "warning",
        title: "Empty",
        message: "Amount Required",
      });
    }
    updating.set(false);
  };

  const onChangeAccount = (value) => {
    setComplusorySavings(value);
  };

  return (
    <Stack className="mainSectionWrapper" flex={1} style={{ overflowX: "hidden", overflowY: "hidden" }}>
      <InnerSectionHeaderComponent innerComponentData={innerComponentData} applicationId={applicationId} />
      <Stack alignItems="center" padding={3} className="data-box" direction="row" spacing={3}>
        <Typography variant="subtitle1"> Amount </Typography>
        <Stack direction="row" flex={1} spacing={2}>
          <NumericFormat
            customInput={TextBoxComponent}
            variant="outlined"
            thousandSeparator={true}
            value={formatCurrency(compulsorySavings ?? "N/A")}
            decimalScale={2}
            allowLeadingZeros={false}
            allowNegative={false}
            required={true}
            fullWidth={false}
            onValueChange={(e: any) => {
              const { value } = e;
              onChangeAccount(value);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">LKR</InputAdornment>,
            }}
          />
          <ButtonComponent
            title={"Save"}
            variant="contained"
            onClick={handleSave}
            style={{
              maxHeight: "40px",
            }}
            disabled={getIsDisbursed(privilegeKeys.COMPULSORY_AMOUNT_SAVE, disbursed) || isLoggedInUserNotTheAssignee}
          />
        </Stack>
        <Stack flex={2}></Stack>
      </Stack>
    </Stack>
  );
};
export default CompulsorySavings;
