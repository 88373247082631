import { Box, Typography } from "@mui/material";
import { standardDateFormatter } from "los-util";
import { useTranslation } from "react-i18next";
import { PatternFormat } from "react-number-format";
import { MASTER_DATA_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import { VARIANT, disSelectButtonStyles, nextBtnStyles } from "../../../../../../../../configs/constants/styleValues";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter, OnDemandValueValidator } from "../../../../../../../../services/onDemandValidators";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import { StyledButton } from "../../../../../../../InputComponents/ButtonComponent/StyledButton";
import { SelectComponent } from "../../../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { FormLabelComponent } from "../../../../../../../InputHelperComponents/FormLabelComponent/FormLabelComponent";
import { FieldSectionWrapper } from "../../../../../../FieldSectionsWrapper.tsx/FieldSectionWrapper";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import { DuplicateKeyReceivedActivateState, InsuranceAvailableActivateState, WarrantyAvailableSection } from "../../SecurityTypeSubComponents/SecurityTypeSubComponent";

export const Vehicle = (props) => {
  const { t: translate } = useTranslation();
  const masterDataStore = MasterDataProvider.provideMasterData();

  const valuationOfficers: any[] = [
    { id: 1, name: "Officer1" },
    { id: 1, name: "Officer2" },
  ];

  const masterDataStoreState = {
    vhFuelType: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.FUEL_TYPE),
    vhManufacturerCode: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.MANUFACTURER_CODE),
    vhThirdPartyDelaerCode: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.THIRD_PARTY_DEALER_CODE),
    vhTaxType: masterDataStore?.getMasterData(MASTER_DATA_KEYS?.TAX_TYPE),
    vhUseAsset: masterDataStore?.getMasterData(MASTER_DATA_KEYS.USE_OF_ASSET),
    vhNameValuationOfficer: valuationOfficers,
    vhVehicleCatergory: masterDataStore.getMasterData(MASTER_DATA_KEYS.VEHICLE_CATEGORY),
  };

  const {
    vhValueOfSecurity,
    vhSecuredValue,
    vhVehicleCatergory,
    vhVehicleMake,
    vhVehicleModel, //0,
    vhEngineNo, //0
    vhChassisNo, //0
    vhMarketValue, //0
    vhForceSaleValue, //0
    vhCReleasedDate,
    vhFuelType,
    vhDescription,
    vhIntendedUse, //0
    vhRegitrationNumber, //0
    vhYearOfRegistration,
    vhValuerName,
    vhValuerRegNumber,
    vhValuerDate,
    vhManufacturerName,
    vhManufacturerCode, //0
    vhYearOfManufacture,
    vhCountryOfOrigin,
    vhYearOfVehiclePurchase,
    vhSupplierOrDealerName,
    vhSupplierAddress,
    vhThirdPartyDelaerCode,
    vhInvoiceNumber,
    vhInvoiceDate,
    vhInvoiceOrPurchaseValue,
    vhTaxType, //0
    vhTaxNumber, //0
    vhTaxAmount, //0
    vhInsuranceValue,
    vhAssetClass,
    vhUseAsset,
    vhColour,
    vhNameValuationOfficer,
    vhAddressValuationOfficer,
    vhContactNumberValuationOfficer,
    vhContactNumberSupplier,
    vhQuotationNumber,
    vhDateQuatation,
    vhValueQuatation,
    vhKeptAtAddress,
  } = props.fields || {};

  const VehicleCondition = ({ }) => {
    return (
      <InputGrid visibility={1} variant={VARIANT?.extraLongGrid}>
        <Box mb={2}>
          <FormLabelComponent>Vehicle Condition</FormLabelComponent>
        </Box>
        <Box display="flex">
          <Box mr={1} key={1}>
            <StyledButton
              styles={props?.doc?.actionBrandNewRecondition.get() ? nextBtnStyles : disSelectButtonStyles}
              title={"Brand New"}
              onClick={() => {
                props?.doc?.actionBrandNewRecondition.set(true);
                props?.doc?.actionRecondition.set(false);
                props?.doc?.actionSecondHand.set(false);
              }}
            />
          </Box>
          <Box mr={1} key={1}>
            <StyledButton
              styles={props?.doc?.actionRecondition.get() ? nextBtnStyles : disSelectButtonStyles}
              title={"Recondition"}
              onClick={() => {
                props?.doc?.actionRecondition.set(true);
                props?.doc?.actionBrandNewRecondition.set(false);
                props?.doc?.actionSecondHand.set(false);
              }}
            />
          </Box>
          <Box mr={1} key={1}>
            <StyledButton
              styles={props?.doc?.actionSecondHand.get() ? nextBtnStyles : disSelectButtonStyles}
              title={"Second Hand"}
              onClick={() => {
                props?.doc?.actionRecondition.set(false);
                props?.doc?.actionBrandNewRecondition.set(false);
                props?.doc?.actionSecondHand.set(true);
              }}
            />
          </Box>
        </Box>
      </InputGrid>
    );
  };

  return (
    <Box mt={2} className="securityWrapper">
      <FieldSectionWrapper>
        {props?.isNotNeedComponentTitle ? (
          <></>
        ) : (
          <InputFieldsStack>
            <Typography variant="subtitle1" pl={2.5}>
              Vehicle
              {props.index > 0 ? "(" + parseInt(props.index) + ")" : ""}
            </Typography>
          </InputFieldsStack>
        )}
        <InputFieldsStack>
          <InputGrid visibility={vhValueOfSecurity.visible}>
            <TextBoxComponent
              value={formatCurrency(props?.doc?.vhValueOfSecurity.get())}
              onChange={(e) => {
                props?.doc?.vhValueOfSecurity.set(e.target.value);
              }}
              label={translate("Value of the Security")}
              onInput={OnDemandValueFormatter.numeric}
              required={vhValueOfSecurity.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhSecuredValue.visible}>
            <TextBoxComponent
              value={formatCurrency(props?.doc?.vhSecuredValue.get())}
              onChange={(e) => props?.doc?.vhSecuredValue.set(e.target.value)}
              label={translate("Secured Value")}
              onInput={OnDemandValueFormatter.numeric}
              required={vhSecuredValue.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhDescription.visible}>
            <TextBoxComponent
              value={props?.doc?.vhDescription.get()}
              onChange={(e) => props?.doc?.vhDescription.set(e.target.value)}
              label={translate("Description")}
              onInput={OnDemandValueFormatter.alphaNumericSpaces}
              required={vhDescription.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhInsuranceValue.visible}>
            <TextBoxComponent value={formatCurrency(props?.doc?.vhInsuranceValue.get())} onChange={(e) => props?.doc?.vhInsuranceValue.set(e.target.value)} label={translate("Insuarance Value")} required={vhInsuranceValue.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.numeric} />
          </InputGrid>
          <InputGrid visibility={vhForceSaleValue.visible}>
            <TextBoxComponent
              value={formatCurrency(props?.doc?.vhForceSaleValue.get())}
              onChange={(e) => props?.doc?.vhForceSaleValue.set(e.target.value)}
              label={translate("Force Sale Value")}
              onInput={OnDemandValueFormatter.numeric}
              required={vhForceSaleValue.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
        </InputFieldsStack>

        <InputFieldsStack>
          <VehicleCondition />
        </InputFieldsStack>

        <InputFieldsStack>
          <InputGrid visibility={vhVehicleCatergory.visible}>
            <SelectComponent label={translate("Vehicle Category")} value={props?.doc?.vhVehicleCatergory.get()} values={masterDataStoreState.vhVehicleCatergory} required onChange={(e) => props?.doc?.vhVehicleCatergory.set(e.target.value)} defaultPlaceholder={true} />
          </InputGrid>
          <InputGrid visibility={vhVehicleMake.visible}>
            <TextBoxComponent
              value={props?.doc?.vhVehicleMake.get()}
              onChange={(e) => props?.doc?.vhVehicleMake.set(e.target.value)}
              label={translate("Vehicle Make")}
              onInput={OnDemandValueFormatter.alphaNumericSpaces}
              required={vhVehicleMake.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhVehicleModel.visible}>
            <TextBoxComponent
              value={props?.doc?.vhVehicleModel.get()}
              onChange={(e) => props?.doc?.vhVehicleModel.set(e.target.value)}
              label={translate("Vehicle Model")}
              onInput={OnDemandValueFormatter.alphaNumericSpaces}
              required={vhVehicleModel.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhEngineNo.visible}>
            <TextBoxComponent
              value={props?.doc?.vhEngineNo.get()}
              onChange={(e) => props?.doc?.vhEngineNo.set(e.target.value)}
              label={translate("Engine No")}
              onInput={OnDemandValueFormatter.alphaNumeric}
              required={vhEngineNo.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhChassisNo.visible}>
            <TextBoxComponent
              value={props?.doc?.vhChassisNo.get()}
              onChange={(e) => props?.doc?.vhChassisNo.set(e.target.value)}
              label={translate("Chassis No")}
              onInput={OnDemandValueFormatter.alphaNumeric}
              required={vhChassisNo.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhMarketValue.visible}>
            <TextBoxComponent
              value={formatCurrency(props?.doc?.vhMarketValue.get())}
              onChange={(e) => props?.doc?.vhMarketValue.set(e.target.value)}
              label={translate("Market Value")}
              onInput={OnDemandValueFormatter.numeric}
              required={vhMarketValue.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhCReleasedDate.visible}>
            <ValidateDatePicker
              value={formattedDate(props?.doc?.vhCReleasedDate.get())}
              onChange={(date) => {
                const preparedDate = standardDateFormatter(date);
                if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                  props?.doc?.vhCReleasedDate.set(preparedDate);
                }
              }}
              label={`${translate("CR Released Date")} (DD/MM/YYYY)`}
              enableFuture={true}
              disablePast={false}
              required={vhCReleasedDate.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhFuelType.visible}>
            <SelectComponent label={translate("Fuel Type")} value={props?.doc?.vhFuelType.get()} values={masterDataStoreState.vhFuelType} required onChange={(e) => props?.doc?.vhFuelType.set(e.target.value)} defaultPlaceholder={true} />
          </InputGrid>
          <InputGrid visibility={vhIntendedUse.visible}>
            <TextBoxComponent
              value={props?.doc?.vhIntendedUse.get()}
              onChange={(e) => props?.doc?.vhIntendedUse.set(e.target.value)}
              label={translate("Intended Use")}
              onInput={OnDemandValueFormatter.alphaNumericSpaces}
              required={vhIntendedUse.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhRegitrationNumber.visible}>
            <TextBoxComponent
              value={props?.doc?.vhRegitrationNumber.get()}
              onChange={(e) => props?.doc?.vhRegitrationNumber.set(e.target.value)}
              label={translate("Registration Number")}
              onInput={OnDemandValueFormatter.alphaNumeric}
              required={vhRegitrationNumber.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhKeptAtAddress.visible}>
            <TextBoxComponent
              value={props?.doc?.vhKeptAtAddress.get()}
              onChange={(e) => props?.doc?.vhKeptAtAddress.set(e.target.value)}
              label={translate("Kept at address")}
              onInput={OnDemandValueFormatter.any}
              required={vhKeptAtAddress.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhYearOfRegistration.visible}>
            <TextBoxComponent
              value={props?.doc?.vhYearOfRegistration.get()}
              onChange={(e) => props?.doc?.vhYearOfRegistration.set(e.target.value)}
              label={translate("Year of Registration")}
              onInput={OnDemandValueFormatter.yearFormat}
              required={vhYearOfRegistration.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhAssetClass.visible}>
            <TextBoxComponent value={props?.doc?.vhAssetClass.get()} onChange={(e) => props?.doc?.vhAssetClass.set(e.target.value)} label={translate("Asset Class")} required={vhAssetClass.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.alphaNumericSpaces} />
          </InputGrid>
          <InputGrid visibility={vhUseAsset.visible}>
            <SelectComponent label={translate("Use of the Asset")} value={props?.doc?.vhUseAsset.get()} values={masterDataStoreState.vhUseAsset} required={vhUseAsset.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.vhUseAsset.set(e.target.value)} defaultPlaceholder={true} />
          </InputGrid>
          <InputGrid visibility={vhColour.visible}>
            <TextBoxComponent value={props?.doc?.vhColour.get()} onChange={(e) => props?.doc?.vhColour.set(e.target.value)} label={translate("Colour")} required={vhColour.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.alphaNumericSpaces} />
          </InputGrid>
          <InputGrid visibility={vhValuerName.visible}>
            <TextBoxComponent
              value={props?.doc?.vhValuerName.get()}
              onChange={(e) => props?.doc?.vhValuerName.set(e.target.value)}
              label={translate("Valuer Name")}
              onInput={OnDemandValueFormatter.alphaNumericSpaces}
              required={vhValuerName.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhValuerRegNumber.visible}>
            <TextBoxComponent
              value={props?.doc?.vhValuerRegNumber.get()}
              onChange={(e) => props?.doc?.vhValuerRegNumber.set(e.target.value)}
              label={translate("Valuer Reg Number")}
              onInput={OnDemandValueFormatter.alphaNumeric}
              required={vhValuerRegNumber.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhValuerDate.visible}>
            <ValidateDatePicker
              value={formattedDate(props?.doc?.vhValuerDate.get())}
              onChange={(date) => {
                const preparedDate = standardDateFormatter(date);
                if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                  props?.doc?.vhValuerDate.set(preparedDate);
                }
              }}
              label={`${translate("The valuation date")} (DD/MM/YYYY)`}
              enableFuture={true}
              disablePast={false}
              required={vhValuerDate.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhNameValuationOfficer.visible}>
            {/* <SelectComponent
              label={translate("Valuation Officer")}
              value={props?.doc?.vhNameValuationOfficer.get()}
              values={masterDataStoreState.vhNameValuationOfficer}
              required={vhNameValuationOfficer.mandatory === 1 ? true : false}
              onChange={(e) =>
                props?.doc?.vhNameValuationOfficer.set(e.target.value)
              }
              defaultPlaceholder={true}
            /> */}
            <TextBoxComponent label={translate("Name of the Valuation Officer")} value={props?.doc?.vhNameValuationOfficer.get()} required={vhNameValuationOfficer.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.vhNameValuationOfficer.set(e.target.value)} onInput={OnDemandValueFormatter.alphabeticalSpaces} />
          </InputGrid>
          <InputGrid visibility={vhAddressValuationOfficer.visible}>
            <TextBoxComponent value={props?.doc?.vhAddressValuationOfficer.get()} onChange={(e) => props?.doc?.vhAddressValuationOfficer.set(e.target.value)} label={translate("Address of the Valuation Officer")} onInput={OnDemandValueFormatter.any} required={vhAddressValuationOfficer.mandatory === 1 ? true : false} />
          </InputGrid>
          <InputGrid visibility={vhContactNumberValuationOfficer.visible}>
            {/* <TextBoxComponent
              value={props?.doc?.vhContactNumberValuationOfficer.get()}
              onChange={(e) =>
                props?.doc?.vhContactNumberValuationOfficer.set(e.target.value)
              }
              label={translate("Contact Number of Valuation Officer")}
              onInput={OnDemandValueFormatter.alphaNumeric}
              required={
                vhContactNumberValuationOfficer.mandatory === 1 ? true : false
              }
            /> */}

            <PatternFormat
              key={""}
              format="(###) ### ####"
              defaultValue={props?.doc?.vhContactNumberValuationOfficer.get()}
              value={props?.doc?.vhContactNumberValuationOfficer.get()}
              label={translate("Contact Number of Valuation Officer")}
              fullWidth={true}
              allowEmptyFormatting={false}
              mask="_"
              customInput={TextBoxComponent}
              error={props?.doc?.vhContactNumberValuationOfficer.get() === 1 ? OnDemandValueValidator.phoneLandNumber(props?.doc?.vhContactNumberValuationOfficer.get()) : OnDemandValueValidator.phoneNumber(props?.doc?.vhContactNumberValuationOfficer.get())}
              required={vhContactNumberValuationOfficer.mandatory === 1 ? true : false}
              onValueChange={(values, _) => {
                props?.doc?.vhContactNumberValuationOfficer.set(values.value);
              }}
              isAllowed={(values) => {
                if (values.value && values.value.toString().length > 10) {
                  return false;
                }
                return true;
              }}
            />
          </InputGrid>
          <InputGrid visibility={vhManufacturerName.visible}>
            <TextBoxComponent
              value={props?.doc?.vhManufacturerName.get()}
              onChange={(e) => props?.doc?.vhManufacturerName.set(e.target.value)}
              label={translate("Manufacturer  Name")}
              onInput={OnDemandValueFormatter.alphabeticalSpaces}
              required={vhManufacturerName.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhManufacturerCode.visible}>
            {/* <SelectComponent
              label={translate("Manufacturer Code")}
              value={props?.doc?.vhManufacturerCode.get()}
              values={masterDataStoreState.vhManufacturerCode}
              required={vhManufacturerCode.mandatory === 1 ? true : false}
              onChange={(e) =>
                props?.doc?.vhManufacturerCode.set(e.target.value)
              }
              defaultPlaceholder={true}
            /> */}
            <TextBoxComponent label={translate("Manufacturer Code")} value={props?.doc?.vhManufacturerCode.get()} values={masterDataStoreState.vhManufacturerCode} required={vhManufacturerCode.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.vhManufacturerCode.set(e.target.value)} />
          </InputGrid>
          <InputGrid visibility={vhYearOfManufacture.visible}>
            <TextBoxComponent
              value={props?.doc?.vhYearOfManufacture.get()}
              onChange={(e) => props?.doc?.vhYearOfManufacture.set(e.target.value)}
              label={translate("Year of Manufacture")}
              onInput={OnDemandValueFormatter.yearFormat}
              required={vhYearOfManufacture.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhCountryOfOrigin.visible}>
            <TextBoxComponent
              value={props?.doc?.vhCountryOfOrigin.get()}
              onChange={(e) => props?.doc?.vhCountryOfOrigin.set(e.target.value)}
              label={translate("Country Of Origin")}
              onInput={OnDemandValueFormatter.alphaNumericSpaces}
              required={vhCountryOfOrigin.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhYearOfVehiclePurchase.visible}>
            <TextBoxComponent
              value={props?.doc?.vhYearOfVehiclePurchase.get()}
              onChange={(e) => props?.doc?.vhYearOfVehiclePurchase.set(e.target.value)}
              label={translate("Year Of Vehicle Purchase")}
              onInput={OnDemandValueFormatter.yearFormat}
              required={vhYearOfVehiclePurchase.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhSupplierOrDealerName.visible}>
            <TextBoxComponent
              value={props?.doc?.vhSupplierOrDealerName.get()}
              onChange={(e) => props?.doc?.vhSupplierOrDealerName.set(e.target.value)}
              label={translate("Supplier/Dealer Name")}
              onInput={OnDemandValueFormatter.alphabeticalSpaces}
              required={vhSupplierOrDealerName.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhSupplierAddress.visible}>
            <TextBoxComponent
              value={props?.doc?.vhSupplierAddress.get()}
              onChange={(e) => props?.doc?.vhSupplierAddress.set(e.target.value)}
              label={translate("Supplier Address")}
              onInput={OnDemandValueFormatter.any}
              required={vhSupplierAddress.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhThirdPartyDelaerCode.visible}>
            {/* <SelectComponent
              label={translate("Third Party Delaer Code")}
              value={props?.doc?.vhThirdPartyDelaerCode.get()}
              values={masterDataStoreState.vhThirdPartyDelaerCode}
              required={vhThirdPartyDelaerCode.mandatory === 1 ? true : false}
              onChange={(e) =>
                props?.doc?.vhThirdPartyDelaerCode.set(e.target.value)
              }
              defaultPlaceholder={true}
            /> */}
            <TextBoxComponent label={translate("Third Party Delaer Code")} value={props?.doc?.vhThirdPartyDelaerCode.get()} required={vhThirdPartyDelaerCode.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.vhThirdPartyDelaerCode.set(e.target.value)} />
          </InputGrid>
          <InputGrid visibility={vhContactNumberSupplier.visible}>
            {/* <TextBoxComponent
              value={props?.doc?.vhContactNumberSupplier.get()}
              onChange={(e) =>
                props?.doc?.vhContactNumberSupplier.set(e.target.value)
              }
              label={translate("Contact Number of Supplier")}
              onInput={OnDemandValueFormatter.alphaNumeric}
              required={vhContactNumberSupplier.mandatory === 1 ? true : false}
            /> */}

            <PatternFormat
              key={""}
              format="(###) ### ####"
              defaultValue={props?.doc?.vhContactNumberSupplier.get()}
              value={props?.doc?.vhContactNumberSupplier.get()}
              label={translate("Contact Number of Supplier")}
              fullWidth={true}
              allowEmptyFormatting={false}
              mask="_"
              customInput={TextBoxComponent}
              error={props?.doc?.vhContactNumberSupplier.get() === 1 ? OnDemandValueValidator.phoneLandNumber(props?.doc?.vhContactNumberSupplier.get()) : OnDemandValueValidator.phoneNumber(props?.doc?.vhContactNumberSupplier.get())}
              required={vhContactNumberSupplier.mandatory === 1 ? true : false}
              onValueChange={(values, _) => {
                props?.doc?.vhContactNumberSupplier.set(values.value);
              }}
              isAllowed={(values) => {
                if (values.value && values.value.toString().length > 10) {
                  return false;
                }
                return true;
              }}
            />
          </InputGrid>
          <InputGrid visibility={vhInvoiceNumber.visible}>
            <TextBoxComponent
              value={props?.doc?.vhInvoiceNumber.get()}
              onChange={(e) => props?.doc?.vhInvoiceNumber.set(e.target.value)}
              label={translate("Invoice Number")}
              onInput={OnDemandValueFormatter.alphaNumeric}
              required={vhInvoiceNumber.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhInvoiceDate.visible}>
            <ValidateDatePicker
              value={formattedDate(props?.doc?.vhInvoiceDate.get())}
              onChange={(date) => {
                const preparedDate = standardDateFormatter(date);
                if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                  props?.doc?.vhInvoiceDate.set(preparedDate);
                }
              }}
              label={`${translate("Invoice Date")} (DD/MM/YYYY)`}
              enableFuture={true}
              disablePast={false}
              required={vhInvoiceDate.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhInvoiceOrPurchaseValue.visible}>
            <TextBoxComponent
              value={formatCurrency(props?.doc?.vhInvoiceOrPurchaseValue.get())}
              onChange={(e) => props?.doc?.vhInvoiceOrPurchaseValue.set(e.target.value)}
              label={translate("Invoice / Purchase value")}
              onInput={OnDemandValueFormatter.numeric}
              required={vhInvoiceOrPurchaseValue.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhTaxType.visible}>
            {/* <SelectComponent
              label={translate("Tax Type")}
              value={props?.doc?.vhTaxType.get()}
              values={masterDataStoreState.vhTaxType}
              required={vhTaxType.mandatory === 1 ? true : false}
              onChange={(e) => props?.doc?.vhTaxType.set(e.target.value)}
              defaultPlaceholder={true}
            /> */}

            <TextBoxComponent label={translate("Tax Type")} value={props?.doc?.vhTaxType.get()} required={vhTaxType.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.vhTaxType.set(e.target.value)} />
          </InputGrid>
          <InputGrid visibility={vhTaxNumber.visible}>
            <TextBoxComponent
              value={props?.doc?.vhTaxNumber.get()}
              onChange={(e) => props?.doc?.vhTaxNumber.set(e.target.value)}
              label={translate("Tax Number")}
              onInput={OnDemandValueFormatter.alphaNumeric}
              required={vhTaxNumber.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
          <InputGrid visibility={vhTaxAmount.visible}>
            <TextBoxComponent
              value={formatCurrency(props?.doc?.vhTaxAmount.get())}
              onChange={(e) => props?.doc?.vhTaxAmount.set(e.target.value)}
              label={translate("Tax Amount")}
              onInput={OnDemandValueFormatter.numeric}
              required={vhTaxAmount.mandatory === 1 ? true : false}
            // disabled={getIsDisbursed(null, disbursed)}
            />
          </InputGrid>
        </InputFieldsStack>

        <DuplicateKeyReceivedActivateState props={props} />
        <WarrantyAvailableSection props={props} />
        <InsuranceAvailableActivateState props={props} />
        <InputFieldsStack>
          {/* <InputGrid visibility={vhDateCommencementInsurance.visible}>
            <ValidateDatePicker
              value={formattedDate(
                props?.doc?.vhDateCommencementInsurance.get()
              )}
              onChange={(date) => {
                const preparedDate = standardDateFormatter(date);
                if (
                  date &&
                  preparedDate !== null &&
                  preparedDate !== "Invalid Date"
                ) {
                  props?.doc?.vhDateCommencementInsurance.set(preparedDate);
                }
              }}
              label={translate("Date of Commencement of Insurance")}
              enableFuture={true}
              disablePast={false}
              required={
                vhDateCommencementInsurance.mandatory === 1 ? true : false
              }
            />
          </InputGrid> */}
          <InputGrid visibility={vhQuotationNumber.visible}>
            <TextBoxComponent value={props?.doc?.vhQuotationNumber.get()} onChange={(e) => props?.doc?.vhQuotationNumber.set(e.target.value)} label={translate("Quotation Number")} onInput={OnDemandValueFormatter.alphaNumeric} required={vhQuotationNumber.mandatory === 1 ? true : false} />
          </InputGrid>
          <InputGrid visibility={vhDateQuatation.visible}>
            <ValidateDatePicker
              value={formattedDate(props?.doc?.vhDateQuatation.get())}
              onChange={(date) => {
                const preparedDate = standardDateFormatter(date);
                if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                  props?.doc?.vhDateQuatation.set(preparedDate);
                }
              }}
              label={`${translate("Date of Quatation")} (DD/MM/YYYY)`}
              enableFuture={true}
              disablePast={false}
              required={vhDateQuatation.mandatory === 1 ? true : false}
            />
          </InputGrid>
          <InputGrid visibility={vhValueQuatation.visible}>
            <TextBoxComponent value={formatCurrency(props?.doc?.vhValueQuatation.get())} onChange={(e) => props?.doc?.vhValueQuatation.set(e.target.value)} label={translate("Value of the Quatation")} onInput={OnDemandValueFormatter.alphaNumeric} required={vhValueQuatation.mandatory === 1 ? true : false} />
          </InputGrid>
        </InputFieldsStack>
      </FieldSectionWrapper>
    </Box>
  );
};
