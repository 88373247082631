import { Grid } from '@mui/material';
import ApplicationHeader from '../../../../Application/ApplicationHeader';
import DetailItem from '../../../../DetailItem';

const RisksConditionsAndGeneralFacts = ({ data, applicationId, isMainContactDetails = true, mainTabData = { isAppraisal: false }, isRawVersion = false, printMode = false }) => {
    const risks: any = data?.formData?.risksConditionsAndGeneralFactsData?.risks;
    const conditions: any = data?.formData?.risksConditionsAndGeneralFactsData?.conditions;
    const recommendations: any = data?.formData?.risksConditionsAndGeneralFactsData?.factsAndRecommendations;

    return (
        <div className="mainSectionWrapper full-width full-height">
            {isMainContactDetails && <ApplicationHeader
                formData={data?.formData}
                requestId={data?.requestId}
                applicationId={applicationId}
                title={'Risks, Conditions and General Facts'}
                isAppraisal={mainTabData?.isAppraisal}
                isRawVersion={isRawVersion}
            />}
            <Grid container p={1} className="inner-component-height">
                <Grid container item p={1} style={{ backgroundColor: '#e6f0fa' }} className="border-radius-5 sub-header-height">
                    <div className="basic-font basic-font-color-bold font-size-16 align-left">
                        Risks, Conditions and General Facts
                    </div>
                    <Grid container pt={1}>
                        <Grid container p={1} className="data-box">
                            <Grid container item mt={1} >
                                {risks && <Grid item xs={12}>
                                    <DetailItem
                                        title="Analysis of Need"
                                        value={risks ? risks : '-'}
                                        valueFontClass="font-size-14"
                                        titleFontClass="font-size-12"
                                    />
                                </Grid>}
                            </Grid>
                            <Grid container item mt={1} >
                                {conditions && <Grid item xs={12}>
                                    <DetailItem
                                        title="Conditions"
                                        value={conditions ? conditions : '-'}
                                        valueFontClass="font-size-14"
                                        titleFontClass="font-size-12"
                                    />
                                </Grid>}
                            </Grid>
                            <Grid container item mt={1} >
                                {recommendations && <Grid item xs={12}>
                                    <DetailItem
                                        title="General facts and Recommendation"
                                        value={recommendations ? recommendations : '-'}
                                        valueFontClass="font-size-14"
                                        titleFontClass="font-size-12"
                                    />
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    )
}

export default RisksConditionsAndGeneralFacts