import ARMAllocation from "../../components/OtherComponents/CreditFile/Components/ARMAllocation/ARMAllocation";
import AgreementCondition from "../../components/OtherComponents/CreditFile/Components/AgreementCondition/AgreementCondition";
import Application from "../../components/OtherComponents/CreditFile/Components/Application";
import AddressInformation from "../../components/OtherComponents/CreditFile/Components/Application/AddressInformation";
import AssetsDetails from "../../components/OtherComponents/CreditFile/Components/Application/AssetsDetails";
import BankDetails from "../../components/OtherComponents/CreditFile/Components/Application/BankDetails";
import BusinessDetails from "../../components/OtherComponents/CreditFile/Components/Application/BusinessDetails";
import ContactDetails from "../../components/OtherComponents/CreditFile/Components/Application/ContactDetails";
import CreditDetails from "../../components/OtherComponents/CreditFile/Components/Application/CreditDetails";
import EducationalDetails from "../../components/OtherComponents/CreditFile/Components/Application/EducationalDetails";
import EmploymentDetails from "../../components/OtherComponents/CreditFile/Components/Application/EmploymentDetails";
import ExpensesDetails from "../../components/OtherComponents/CreditFile/Components/Application/ExpensesDetails";
import IdentificationDetails from "../../components/OtherComponents/CreditFile/Components/Application/IdentificationDetails";
import IncomeDetails from "../../components/OtherComponents/CreditFile/Components/Application/IncomeDetails";
import InquiryOfObligations from "../../components/OtherComponents/CreditFile/Components/Application/InquiryOfObligations";
import JointBorrower from "../../components/OtherComponents/CreditFile/Components/Application/JointBorrower";
import LifeInsuranceDetails from "../../components/OtherComponents/CreditFile/Components/Application/LifeInsuranceDetails";
import SecurityDetails from "../../components/OtherComponents/CreditFile/Components/Application/SecurityDetails";
import TaxDetails from "../../components/OtherComponents/CreditFile/Components/Application/TaxDetails";
import ApplicationDocumentUpload from "../../components/OtherComponents/CreditFile/Components/ApplicationDocumentUpload";
import ApplicationHistoryRevamp from "../../components/OtherComponents/CreditFile/Components/ApplicationHistory/ApplicationHistoryRevamp";
import RecommendedApprovedBy from "../../components/OtherComponents/CreditFile/Components/Appraisal/Retail/Components/RecommendedApprovedBy/RecommendedApprovedBy";
import SalaryDeduction from "../../components/OtherComponents/CreditFile/Components/Appraisal/Retail/Components/SalaryDeduction/SalaryDeduction";
import BorrowersBasicDocument from "../../components/OtherComponents/CreditFile/Components/BorrowersBasicDocument";
import LoanRelatedCIF from "../../components/OtherComponents/CreditFile/Components/CIF/LoanRelatedCIF";
import CMSHistory from "../../components/OtherComponents/CreditFile/Components/CMSHistory/CMSHistory";
import { BankServiceCharges } from "../../components/OtherComponents/CreditFile/Components/Charges/BankServiceCharges";
import ServiceCharges from "../../components/OtherComponents/CreditFile/Components/Charges/ServiceCharges";
import { DataVerificationCheckList } from "../../components/OtherComponents/CreditFile/Components/Checklist/DataVerificationCheckList/DataVerificationCheckList";
import { DocumentChecklist } from "../../components/OtherComponents/CreditFile/Components/Checklist/DocumentChecklist/DocumentChecklist";
import { PostDisbursementChecklist } from "../../components/OtherComponents/CreditFile/Components/Checklist/PostDisbursementChecklist/PostDisbursementChecklist";
import { PostDisbursementsChecklist } from "../../components/OtherComponents/CreditFile/Components/Checklist/PostDisbursementsChecklist/PostDisbursementsChecklist";
import { PredisbursementChecklist } from "../../components/OtherComponents/CreditFile/Components/Checklist/PredisbursementChecklist/PredisbursementChecklist";
import { SecurityDocumentChecklist } from "../../components/OtherComponents/CreditFile/Components/Checklist/SecurityDocumentChecklist/SecurityDocumentChecklist";
import TaskChecklist from "../../components/OtherComponents/CreditFile/Components/Checklist/TaskChecklist/TaskChecklist";
import GuarantorDetails from "../../components/OtherComponents/CreditFile/Components/Collateral/GuarantorDetails";
import CompulsorySavings from "../../components/OtherComponents/CreditFile/Components/CompulsorySavings";
import ApplicationCondition from "../../components/OtherComponents/CreditFile/Components/Condition/ApplicationCondition";
import CribRecodes from "../../components/OtherComponents/CreditFile/Components/Crib/CribRecodes";
import { ActiveDecisions } from "../../components/OtherComponents/CreditFile/Components/Decision/ActiveDecisions";
import DisbursementAPIStatus from "../../components/OtherComponents/CreditFile/Components/DisbursementAPIStatus/DisbursementAPIStatus";
import DisbursementDocuments from "../../components/OtherComponents/CreditFile/Components/DisbursementDocuments/DisbursementDocuments";
import DocumentsOtherDocuments from "../../components/OtherComponents/CreditFile/Components/DocumentsOtherDocuments";
import InspectionDocumentUpload from "../../components/OtherComponents/CreditFile/Components/InspectionDocumentUpload/InspectionDocumentUpload";
import InsurancePolicyDocumentsList from "../../components/OtherComponents/CreditFile/Components/InsurancePolicy/InsurancePolicyDocumentsList";
import ReviewInsurancePolicyDocumentsList from "../../components/OtherComponents/CreditFile/Components/InsurancePolicy/ReviewInsurancePolicyDocumentsList";
import InvestmentAccount from "../../components/OtherComponents/CreditFile/Components/InvestmentAccount/InvestmentAccount";
import ApplicationLegal from "../../components/OtherComponents/CreditFile/Components/Legal/ApplicationLegal";
import LegalDocumentsList from "../../components/OtherComponents/CreditFile/Components/Legal/LegalDocumentsList";
import AssociatedLoans from "../../components/OtherComponents/CreditFile/Components/LoanAssociations/AssociatedLoans";
import MarketingChannelCode from "../../components/OtherComponents/CreditFile/Components/MarketingChannelCode/MarketingChannelCode";
import ApplicationNote from "../../components/OtherComponents/CreditFile/Components/Note/ApplicationNote";
import OfferLatterLayoutRevamp from "../../components/OtherComponents/CreditFile/Components/OfferLatterLayoutRevamp";
import PostConditionDocuments from "../../components/OtherComponents/CreditFile/Components/PostConditionDocuments";
import PostDisbursementDocuments from "../../components/OtherComponents/CreditFile/Components/PostDisbursementDocuments";
import Predisbursement from "../../components/OtherComponents/CreditFile/Components/Predisbursement/Predisbursement";
import ProcessTimeline from "../../components/OtherComponents/CreditFile/Components/ProcessTimeline/ProcessTimeline";
import ProductGuarantorDocuments from "../../components/OtherComponents/CreditFile/Components/ProductGuarantorDocuments";
import JointBorrowerDocument from "../../components/OtherComponents/CreditFile/Components/ProductJointBorrowerDocument";
import ApplicationRefer from "../../components/OtherComponents/CreditFile/Components/Refer/ApplicationRefer";
import CreditLeadPriorApproval from "../../components/OtherComponents/CreditFile/Components/Refer/CreditLeadPriorApproval";
import CreditAudit from "../../components/OtherComponents/CreditFile/Components/Review/CreditAudit";
import FundReleaseApproval from "../../components/OtherComponents/CreditFile/Components/Review/FundReleaseApproval";
import ApplicationRisk from "../../components/OtherComponents/CreditFile/Components/Risk/ApplicationRisk";
import RiskDocumentsList from "../../components/OtherComponents/CreditFile/Components/Risk/RiskDocumentsList";
import ReportsPreviousBorrowings from "../../components/OtherComponents/CreditFile/Components/SCV/ReportsPreviousBorrowings";
import ScoreCardLayout from "../../components/OtherComponents/CreditFile/Components/ScoreCard/ScoreCardLayout";
import SecurityDocuments from "../../components/OtherComponents/CreditFile/Components/SecurityDocuments";
import SecurityDocumentUpload from "../../components/OtherComponents/CreditFile/Components/SecurityDocuments/DocumentUpload/SecurityDocumentUpload";
import SummaryLayout from "../../components/OtherComponents/CreditFile/Components/SummaryLayout";
import ApplicationTasks from "../../components/OtherComponents/CreditFile/Components/Tasks/ApplicationTasks";
import DisbursementCheckList from "../../components/OtherComponents/CreditFile/Components/Tasks/DisbursementCheckList";
import FollowUpChecklist from "../../components/OtherComponents/CreditFile/Components/Tasks/FollowUpChecklist";
import TimelineLog from "../../components/OtherComponents/CreditFile/Components/TimelineLog/TimelineLog";
import WorkflowHistory from "../../components/OtherComponents/CreditFile/Components/WorkflowHistory/WorkflowHistory";
// import { LoanProtectionInsurance } from "../../components/OtherComponents/ApplicationForm/Sections/SecurityDetails/SecurityData/PaperDocs/LoanProtectInsuran/LoanProtectInsuran";
import { CreditFdAgainstLoans } from "../../components/OtherComponents/CreditFile/Components/Collateral/FdAgainstLoans";
import { CreditImmovableMachineryEquipement } from "../../components/OtherComponents/CreditFile/Components/Collateral/ImmovableMachineryEquipement";
import { CreditLifeInsurance } from "../../components/OtherComponents/CreditFile/Components/Collateral/LifeInsurance";
import { CreditLoanProtectionInsurance } from "../../components/OtherComponents/CreditFile/Components/Collateral/LoanProtectionInsurance";
import { CreditMovableMachineryEquipement } from "../../components/OtherComponents/CreditFile/Components/Collateral/MovableMachineryEquipement";
import { CreditPledge } from "../../components/OtherComponents/CreditFile/Components/Collateral/Pledge";
import { CreditProperty } from "../../components/OtherComponents/CreditFile/Components/Collateral/Property";
import { CreditShares } from "../../components/OtherComponents/CreditFile/Components/Collateral/Shares";
import { CreditStockAsGold } from "../../components/OtherComponents/CreditFile/Components/Collateral/StockAsGold";
import { CreditTresuryBond } from "../../components/OtherComponents/CreditFile/Components/Collateral/TresuryBond";
import { CreditVehicle } from "../../components/OtherComponents/CreditFile/Components/Collateral/Vehicle";
import { CreditWarehouseReceipt } from "../../components/OtherComponents/CreditFile/Components/Collateral/WarehouseReceipt";
import { inspection_tab } from "../../components/OtherComponents/CreditFile/Components/CreditInspection/Helpers/inspectionTabOrderRestructuredFn";
import BusinessInspection from "../../components/OtherComponents/CreditFile/Components/CreditInspection/InspectionFormParking/BusinessInspections/BusinessInspection";
import CollateralInspections from "../../components/OtherComponents/CreditFile/Components/CreditInspection/InspectionFormParking/CollateralInspections/CollateralInspections";
import InspectionDetails from "../../components/OtherComponents/CreditFile/Components/CreditInspection/InspectionFormParking/InspectionDetails/InspectionDetails";
import InspectionParticipation from "../../components/OtherComponents/CreditFile/Components/CreditInspection/InspectionFormParking/InspectionParticipation/InspectionParticipation";
import JBInspectionsContainer from "../../components/OtherComponents/CreditFile/Components/CreditInspection/InspectionFormParking/JBInspections/JBInpectionContainer";
import OtherQuestions from "../../components/OtherComponents/CreditFile/Components/CreditInspection/InspectionFormParking/OtherQuestions/OtherQuestions";
import PrimaryApplicantInspections from "../../components/OtherComponents/CreditFile/Components/CreditInspection/InspectionFormParking/PrimaryApplicantInspections/PrimaryApplicantInspections";

import AutomaticSettlementOfCreditCardBills from "../../components/OtherComponents/CreditFile/Components/Application/AutomaticSettlementOfCreditCardBills";
import GroupSelection from "../../components/OtherComponents/CreditFile/Components/Application/GroupSelection/GroupSelection";
import RelativeNotLivingWithYou from "../../components/OtherComponents/CreditFile/Components/Application/RelativeNotLivingWithYou";
import SupplimentaryCard from "../../components/OtherComponents/CreditFile/Components/Application/SupplimentaryCard/SupplimentaryCard";
import AuditedFinancialsDetails from "../../components/OtherComponents/CreditFile/Components/Appraisal/Micro/Components/AuditedFinancialsDetails/AuditedFinancialsDetails";
import DirectOtherBankLiabilityAnalysis from "../../components/OtherComponents/CreditFile/Components/Appraisal/Micro/Components/DirectOtherBankLiabilityAnalysis/DirectOtherBankLiabilityAnalysis";
import FinancialPositionOfTheCompanyContainer from "../../components/OtherComponents/CreditFile/Components/Appraisal/Micro/Components/FinancialPositionOfTheCompany/FinancialPositionOfTheCompanyContainer";
import MarketAnalysis from "../../components/OtherComponents/CreditFile/Components/Appraisal/Micro/Components/MarketAnalysis/MarketAnalysis";
import {
  microAppraisalInnerTabs,
  miroAppraisalComponents,
  retailconstAppraisalInnerTabs,
} from "../../components/OtherComponents/CreditFile/Components/Appraisal/Micro/Configs/RenderingConfig/RenderingConfig";
import AbilityToPayLoan from "../../components/OtherComponents/CreditFile/Components/Appraisal/Retail/Components/AbilityToPayLoan/AbilityToPayLoan";
import AnalysisLoanApplicantWrapper from "../../components/OtherComponents/CreditFile/Components/Appraisal/Retail/Components/AnalysisOfLoanRequirement/AnalysisLoanApplicantWrapper";
import AnalysisOfLoanRequirement from "../../components/OtherComponents/CreditFile/Components/Appraisal/Retail/Components/AnalysisOfLoanRequirement/AnalysisOfLoanRequirement";
import PreviousBorrowing from "../../components/OtherComponents/CreditFile/Components/Appraisal/Retail/Components/PreviousBorrowing/PreviousBorrowing";
import RisksConditionsAndGeneralFacts from "../../components/OtherComponents/CreditFile/Components/Appraisal/Retail/Components/RisksConditionsAndGeneralFacts/RisksConditionsAndGeneralFacts";
import SCVDetails from "../../components/OtherComponents/CreditFile/Components/Appraisal/Retail/Components/SCVDetails/SCVDetails";
import ApplicationReviewAudit from "../../components/OtherComponents/CreditFile/Components/Audit/ApplicationReviewAudit";
import AuditDocumentList from "../../components/OtherComponents/CreditFile/Components/Audit/AuditDocumentList";
import ApplicationCauHubDepartment from "../../components/OtherComponents/CreditFile/Components/CAUHubDepartment/ApplicationCauHubDepartment";
import CAUDocumentsList from "../../components/OtherComponents/CreditFile/Components/CAUHubDepartment/CAUDocumentsList";
import CollateralDocuments from "../../components/OtherComponents/CreditFile/Components/Collateral/collateral-documents/CollateralDocuments";
import ScoreCardQuestionsWrapper from "../../components/OtherComponents/CreditFile/Components/CreditInspection/InspectionFormParking/ScoreCardQuestions/ScoreCardQuestions";
import SustainableEnvironmentalAndSafeguard from "../../components/OtherComponents/CreditFile/Components/CreditInspection/InspectionFormParking/SustainableEnvironmentalAndSafeguard/SustainableEnvironmentalAndSafeguard";
import GeneralDocuments from "../../components/OtherComponents/CreditFile/Components/GeneralDocuments";
import InspectionScoreCard from "../../components/OtherComponents/CreditFile/Components/InspectionScoreCard/Scorecard";
import ApplicationInsurancePolicy from "../../components/OtherComponents/CreditFile/Components/InsurancePolicy/SubFlowComponent/ApplicationInsurancePolicy";
import RecoveryAccountLayoutV2 from "../../components/OtherComponents/CreditFile/Components/UserCreditComponents/BankServices/RecoveryAccountLayoutV2";
import ApplicationsFormHistory from "../../components/OtherComponents/MenuBarComponent/ApplicationFormHistory";
import { getCopy } from "../../utility/util";
/** Basic Tabs (Main and Inner) */
export const navigationList = [
  "application",
  "inspection_tab",
  "appraisal",
  "decision_main",
  "history",
  "associations",
  "review",
  "refer_section",
  "task_section",
  "check_list",
  "note_section",
  "documents_section",
  "scv_section",
  "summary_section",
  "communication_section",
  "collateral",
];

/** Basic Tab configurations  */
export const navigationConfig = {
  application: {
    id: "application",
    title: "Application",
    type: "clickableAndScrollable",
  },
  appraisal: {
    id: "appraisal",
    title: "Appraisal",
    type: "clickableAndScrollable",
    isAppraisal: true,
  },
  decision_main: { id: "decision_main", title: "Decision", type: "clickable" },
  history: { id: "history", title: "History", type: "clickable" },
  review: { id: "review", title: "Review", type: "clickable" },
  refer_section: { id: "refer_section", title: "Refer", type: "clickable" },
  task_section: { id: "task_section", title: "Tasks", type: "clickable" },
  note_section: { id: "note_section", title: "Notes", type: "clickable" },
  documents_section: {
    id: "documents_section",
    title: "Documents",
    type: "clickable",
  },
  account_creation: {
    id: "account_creation",
    title: "Account Creation",
    type: "clickable",
  },
  summary_section: {
    id: "summary_section",
    title: "Summary",
    type: "clickable",
  },
  scv_section: { id: "scv_section", title: "Bank Services", type: "clickable" },
  check_list: { id: "check_list", title: "Checklist", type: "clickable" },
  collateral: { id: "collateral", title: "Collateral", type: "clickable" },
  inspection_tab: {
    id: "inspection_tab",
    title: "Inspection",
    type: "clickableAndScrollable",
  },
  associations: {
    id: "associations",
    title: "Associations",
    type: "clickableAndScrollable",
  },
};

// export const innerNavigationList = {
//   application: ['primary-applicant-details', 'credit-details', 'contact-details'],
//   appraisal: ['section_1'],
//   decision_main: ['crib', 'decision', 'score_card'],
//   history: ['application_history', 'workflow_status', 'appraisal_time'],
//   review: ['fund_release_approval', 'credit_audit'],
//   refer: ['refer'],
//   task: ['tasks', 'follow_up_check_list'],
//   documents: ['documents'],
//   account_creation: ['disbursement_details', 'account_details', 'disbursement_history'],
// };

export const innerNavigationListCopy = {
  application: [
    "primary-applicant-details",
    "credit-details",
    "address-details",
    "contact-details",
    "education-details",
    "employment-details",
    "business-details",
    "income-details",
    "expenses-details",
    "inquiry-of-obligations",
    "life-insurance-details",
    "bank-details",
    "assets-details",
    "tax-details",
    "joint-borrower-details",
    "security-details",
    "supplementary-card",
    "details-of-relative-not-living-with-you",
    "automatic-settlment-of-credit-card-bills"
  ],
  appraisal: [...retailconstAppraisalInnerTabs, ...microAppraisalInnerTabs],
  decision_main: ["crib", "score_card", "activeDecisions", "inspection_score_card"],
  history: ["application_history", "workflow_history", "cms_history", "timeline_log", "timeline_process", "application_form_history"],
  associations: ["assoc_loans"],
  collateral: [
    "guarantor_details",
    "loan_protection_insurance",
    "life_insurance",
    "fd_against_loans",
    "warehouse_receipt",
    "tresury_bond",
    "shares",
    "pledge",
    "stock_as_gold",
    "movable_machinery_and_equipement",
    "vehicle",
    "property",
    "immovable_machinery_and_equipement",
  ],
  review: [
    "fund_release_approval",
    "credit_audit",
    "condition",
    "agreement_condition",
    "pre_disbursement",
    "review_insurance_department",
    "review_audit",
  ],
  refer_section: ["refers", "credit_lead_prior_approval", "review_risk", "review_legal", "review_insurance_department", "review_cau"],
  task_section: ["tasks"],
  check_list: [
    "follow_up_check_list",
    "document_check_list",
    "disbursement_check_list",
    "predisbursement_check_list",
    "data_verification_checklist",
    "security_document_check_list",
    "post_disbursement_checklist",
  ],
  note_section: ["notes"],
  documents_section: [
    "borrowers_basic_documents",
    "guarantor_documents",
    "joint_borrower_documents",
    "other_documents",
    "post_condition_documents",
    "security_documents",
    "security_document_upload",
    "insruance_policy_document",
    "inspection_document_upload",
    "post_disbursements_documents",
    "disbursements_documents",
    "audit_documents",
    "collateral_documents",
    "supplementary_documents",
    "non_related_documents",
    "business_documents",
    "income_documents"
  ],
  // account_creation: ['disbursement_details', 'account_details', 'disbursement_history'],
  summary_section: ["summary"],
  scv_section: [
    "loan_related_cif",
    "recovery_account",
    "disbursed_api_status",
    "arm_allocation",
    "service_charges",
    "investment_account",
    "marketing_channel",
    "group_selection",
  ],
  inspection_tab: inspection_tab,
};

export const innerSecurityNavigationList = ["promissiory_note"];

export const innerSecurityNavigationConfig = {
  "sec-promissiory-note": {
    id: "sec-promissiory-note",
    title: "Promissory Note",
    componentId: "PromissoryNote",
  },
  "sec-loan-agreement": {
    id: "sec-loan-agreement",
    title: "Loan Agreement",
    componentId: "LoanAgreement",
  },
  "sec-loan-receipt": {
    id: "sec-loan-receipt",
    title: "Loan Receipt",
    componentId: "LoanReceipt",
  },
  "declaration": {
    id: "declaration",
    title: "Declaration",
    componentId: "CardHolderAndBankOfficerDeclaration",
  },
  "sec-guarantee-bond": {
    id: "sec-guarantee-bond",
    title: "Guarantee Bond",
    componentId: "GuaranteeBond",
  },
  "sec-letter-set-off-pa": {
    id: "sec-letter-set-off-pa",
    title: "Letter of Set-off PA",
    componentId: "LetterofSetOffPA",
  },
  "sec-letter-set-off-jb": {
    id: "sec-letter-set-off-jb",
    title: "Letter of Set-off JB",
    componentId: "LetterofSetOffJB",
  },
  "sec-standing-instructions": {
    id: "sec-standing-instructions",
    title: "Standing Instructions",
    componentId: "StandingInstructions",
  },
  "offer-letter": {
    id: "offer-letter",
    title: "Offer Letter",
    componentId: "OfferLatterLayoutRevamp",
    order: 7,
  },
  "signature-verification": {
    id: "signature-verification",
    title: "Signature Verification",
    componentId: "SignatureVerification",
  },
};

const defaultInnerNavigationConfig = {
  "primary-applicant-details": {
    id: "primary-applicant-details",
    title: "Personal Details",
    componentId: "IdentificationDetails",
  },
  "credit-details": {
    id: "credit-details",
    title: "Credit Details",
    componentId: "CreditDetails",
  },
  "contact-details": {
    id: "contact-details",
    title: "Contact Details",
    componentId: "ContactDetails",
  },

  "supplementary-card": {
    id: "supplementary-card",
    title: "Supplementary Card",
    componentId: "SupplimentaryCard",
  },
  "e-statements": {
    id: "e-statements",
    title: "Supplementary Card",
    componentId: "SupplimentaryCard",
  },
  "details-of-relative-not-living-with-you": {
    id: "details-of-relative-not-living-with-you",
    title: "Details of Relative Not Living With You",
    componentId: "RelativeNotLivingWithYou",
  },
  "automatic-settlment-of-credit-card-bills": {
    id: "automatic-settlment-of-credit-card-bills",
    title: "Automatic Settlement of Credit Card Bills",
    componentId: "AutomaticSettlementOfCreditCardBills",
  },
  "address-details": {
    id: "address-details",
    title: "Address Details",
    componentId: "AddressInformation",
  },
  "education-details": {
    id: "education-details",
    title: "Education Details",
    componentId: "EducationalDetails",
  },
  "income-details": {
    id: "income-details",
    title: "Income Details",
    componentId: "IncomeDetails",
  },
  "expenses-details": {
    id: "expenses-details",
    title: "Expense Details",
    componentId: "ExpensesDetails",
  },
  "tax-details": {
    id: "tax-details",
    title: "Tax Details",
    componentId: "TaxDetails",
  },
  "life-insurance-details": {
    id: "life-insurance-details",
    title: "Life Insurance Details",
    componentId: "LifeInsuranceDetails",
  },
  "security-details": {
    id: "security-details",
    title: "Security Details",
    componentId: "SecurityDetails",
  },
  "employment-details": {
    id: "employment-details",
    title: "Employment Details",
    componentId: "EmploymentDetails",
  },
  "business-details": {
    id: "business-details",
    title: "Business Details",
    componentId: "BusinessDetails",
  },
  "assets-details": {
    id: "assets-details",
    title: "Assets Details",
    componentId: "AssetsDetails",
  },
  "bank-details": {
    id: "bank-details",
    title: "Bank Details",
    componentId: "BankDetails",
  },
  "guarantor-details": {
    id: "guarantor-details",
    title: "Guarantor Details",
    componentId: "GuarantorData",
  },
  "joint-borrower-details": {
    id: "joint-borrower-details",
    title: "Joint Borrower Details",
    componentId: "JointBorrower",
  },
  "analysis-of-the-loan-requirement": {
    id: "analysis-of-the-loan-requirement",
    title: "Analysis of the Loan",
    componentId: "AnalysisOfLoanRequirement",
  },
  "risk-conditions-general-facts": {
    id: "risk-conditions-general-facts",
    title: "Risks, Conditions and General Facts",
    componentId: "RisksConditionsAndGeneralFacts",
  },
  "inquiry-of-obligations": {
    id: "inquiry-of-obligations",
    title: "Inquiry of Obligations",
    componentId: "InquiryOfObligations",
  },
  "reports-previous-borrowings": {
    id: "reports-previous-borrowings",
    title: "Reports on Previous Borrowings",
    componentId: "PreviousBorrowing",
  },

  "primary_applicant_inspections": {
    id: "primary_applicant_inspections",
    title: "Borrower 1 Inspections",
    componentId: "PrimaryApplicantInspections",
  },

  inspection_sustainable_env_and_safeguard: {
    id: "inspection_sustainable_env_and_safeguard",
    title: "Sustainable Environmental Safeguard",
    componentId: "SustainableEnvironmentalAndSafeguard",
  },

  jb_inspections: {
    id: "jb_inspections",
    title: "Borrower 2 Inspections",
    componentId: "JBInspections",
  },
  "business_inspection": {
    id: "business_inspection",
    title: "Business Details",
    componentId: "BusinessInspection",
  },

  "inspection_participation": {
    id: "inspection_participation",
    title: "Inspection Participation",
    componentId: "InspectionParticipation",
  },

  "inspection_details": {
    id: "inspection_details",
    title: "Inspection Summary",
    componentId: "InspectionDetails",
  },
  "collateral_inspections": {
    id: "collateral_inspections",
    title: "Collateral Inspections Details",
    componentId: "CollateralInspections",
  },
  "scorecard_questions": {
    id: "scorecard_questions",
    title: "Scorecard Questions",
    componentId: "ScoreCardQuestions",
  },

  inspection_other_questions: {
    id: "inspection_other_questions",
    title: "Other Questions",
    componentId: "OtherQuestions",
  },

  liability_details: {
    id: "liability_details",
    title: "Liability Details",
    componentId: "Application",
  },
  crib: { id: "crib", title: "CRIB", componentId: "CribRecodes" },
  activeDecisions: {
    id: "activeDecisions",
    title: "Active Decisions",
    componentId: "ActiveDecisions",
  },
  decision: { id: "decision", title: "Decision", componentId: "componentId" },
  score_card: {
    id: "score_card",
    title: "Score Card",
    componentId: "ScoreCardLayout",
  },
  inspection_score_card: {
    id: "inspection_score_card",
    title: "Inspection Score Card",
    componentId: "InspectionScoreCard",
  },
  recovery_account: {
    id: "recovery_account",
    title: "Recovery Account",
    componentId: "RecoveryAccountLayout",
  },
  application_history: {
    id: "application_history",
    title: "Application History",
    componentId: "ApplicationHistory",
  },
  workflow_history: {
    id: "workflow_history",
    title: "Workflow History",
    componentId: "WorkflowHistory",
  },
  timeline_log: {
    id: "timeline_log",
    title: "Timeline Log",
    componentId: "TimelineLog",
  },
  timeline_process: {
    id: "timeline_process",
    title: "Process Timeline",
    componentId: "ProcessTimeline",
  },
  cms_history: {
    id: "cms_history",
    title: "CMS History",
    componentId: "CMSHistory",
  },
  application_form_history: {
    id: "application_form_history",
    title: "Application Submissions",
    componentId: "ApplicationsFormHistory",
  },
  assoc_loans: {
    id: "assoc_loans",
    title: "Associated Loans",
    componentId: "AssociatedLoans",
  },
  workflow_status: {
    id: "workflow_status",
    title: "Workflow Status",
    componentId: "componentId",
  },
  // 18: { id: 18, title: "Collateral Review", componentId: 'componentId' },
  fund_release_approval: {
    id: "fund_release_approval",
    title: "Fund Release Approval",
    componentId: "FundReleaseApproval",
    headerActionButton: true,
    headerActionButtonTitle: "Add Fund Approval",
    modalBodyKey: "fund-release-approval",
  },
  credit_audit: {
    id: "credit_audit",
    title: "Credit Audit",
    componentId: "CreditAudit",
    headerActionButton: true,
    headerActionButtonTitle: "Add Audit Data",
    modalBodyKey: "credit-audit",
  },
  tasks: {
    id: "tasks",
    title: "Tasks",
    componentId: "ApplicationTasks",
    headerActionButton: true,
    headerActionButtonTitle: "Create Task",
    modalBodyKey: "task-modal",
  },
  follow_up_check_list: {
    id: "follow_up_check_list",
    title: "Follow Up Checklist",
    componentId: "FollowUpChecklist",
    headerActionButton: true,
    headerActionButtonTitle: "Create Follow Up",
    modalBodyKey: "follow-up-checklist",
  },
  disbursement_check_list: {
    id: "disbursement_check_list",
    title: "Disbursement Checklist",
    componentId: "DisbursementCheckList",
    headerActionButton: true,
    headerActionButtonTitle: "Create Checklist",
    modalBodyKey: "disbursement-checklist",
  },
  account_details: {
    id: "account_details",
    title: "Account Details",
    componentId: "componentId",
  },
  disbursement_history: {
    id: "disbursement_history",
    title: "Disbursement History",
    componentId: "componentId",
  },
  disbursement_details: {
    id: "disbursement_details",
    title: "Disbursement Details",
    componentId: "componentId",
  },
  section_1: {
    id: "section_1",
    title: "Section 1",
    componentId: "WorkflowHistory",
  },
  refers: {
    id: "refers",
    title: "Refer",
    componentId: "ApplicationRefer",
    headerActionButton: true,
    headerActionButtonTitle: "Create Referral",
    modalBodyKey: "referral-modal",
  },
  credit_lead_prior_approval: {
    id: "credit_lead_prior_approval",
    title: "Prior Approval",
    componentId: "CreditLeadPriorApproval",
    headerActionButton: false,
    headerActionButtonTitle: "",
    modalBodyKey: "",
  },
  documents: {
    id: "documents",
    title: "Documents",
    componentId: "ProductDocumentUpload",
  },
  borrowers_basic_documents: {
    id: "borrowers_basic_documents",
    title: "Applicant Basic Documents",
    componentId: "ProductBorrowersBasicDocument",
    documentTypeId: 1,
    applicantType: 1,
    participantType: 1,
    order: 1,
  },
  joint_borrower_documents: {
    id: "joint_borrower_documents",
    title: "Joint Borrower Documents",
    componentId: "ProductJointBorrowerDocument",
    documentTypeId: 1,
    applicantType: 1,
    participantType: 2,
    order: 2,
  },
  other_documents: {
    id: "other_documents",
    title: "Other Documents",
    componentId: "DocumentsOtherDocuments",
    documentTypeId: 2,
    participantType: 6,
    applicantType: 1,
    order: 4,
  },
  post_condition_documents: {
    id: "post_condition_documents",
    title: "Post Condition Documents",
    componentId: "PostConditionDocuments",
    documentTypeId: 3,
    participantType: 4,
    applicantType: 1,
    order: 5,
  },
  security_documents: {
    id: "security_documents",
    title: "Security Documents Formats",
    componentId: "SecurityDocuments",
    documentTypeId: 4,
    applicantType: 1,
    order: 9,
  },
  post_disbursements_documents: {
    id: "post_disbursements_documents",
    title: "Post Disbursement Documents",
    componentId: "PostDisbursementDocuments",
    documentTypeId: 7,
    applicantType: 1,
    order: 10,
    participantType: 7,
  },
  disbursements_documents: {
    id: "disbursements_documents",
    title: "Disbursement Documents",
    componentId: "DisbursementDocuments",
    documentTypeId: 8,
    applicantType: 1,
    order: 10,
  },
  audit_documents: {
    id: "audit_documents",
    title: "Audit Documents",
    componentId: "AuditDocumentList",
    documentTypeId: 9,
    applicantType: 1,
    order: 10,
  },
  collateral_documents: {
    id: "collateral_documents",
    title: "Collateral Documents",
    componentId: "CollateralDocuments",
    documentTypeIds: [10, 11], // 12 Paper Doc removed - refer JIRA -  2654
    applicantType: 1,
    participantType: 10,
    order: 11,
  },
  summary: {
    id: "summary",
    title: "Summary",
    componentId: "SummaryLayout",
  },
  guarantor_documents: {
    id: "guarantor_documents",
    title: "Guarantor Documents",
    componentId: "ProductGuarantorDocuments",
    documentTypeId: 3,
    applicantType: 1,
    participantType: 3,
    order: 3,
  },
  supplementary_documents: {
    id: "supplementary_documents",
    title: "Supplementary Documents",
    componentId: "GeneralDocuments",
    documentTypeId: 13,
    participantType: 13,
    applicantType: 1,
    order: 12,
  },
  non_related_documents: {
    id: "non_related_documents",
    title: "Non-Related Documents",
    componentId: "GeneralDocuments",
    documentTypeId: 14,
    participantType: 14,
    applicantType: 1,
    order: 13,
  },
  business_documents: {
    id: "business_documents",
    title: "Business Documents",
    componentId: "GeneralDocuments",
    documentTypeId: 15,
    participantType: 15,
    applicantType: 1,
    order: 14,
  },
  income_documents: {
    id: "income_documents",
    title: "Income Documents",
    componentId: "GeneralDocuments",
    documentTypeId: 16,
    participantType: 16,
    applicantType: 1,
    order: 15,
  },
  notes: {
    id: "notes",
    title: "Application Notes",
    componentId: "ApplicationNote",
    headerActionButton: true,
    headerActionButtonTitle: "Create Note",
    modalBodyKey: "note-modal",
  },
  condition: {
    id: "condition",
    title: "Offer Letter Conditions",
    componentId: "ApplicationCondition",
    headerActionButton: true,
    headerActionButtonTitle: "Create Offer Letter Conditions",
    modalBodyKey: "condition-modal",
  },
  agreement_condition: {
    id: "agreement_condition",
    title: "Agreement Special Conditions",
    componentId: "AgreementCondition",
    headerActionButton: true,
    headerActionButtonTitle: "Add New Conditions",
    modalBodyKey: "agreement-condition-modal",
  },
  review_risk: {
    id: "review_risk",
    title: "Risk",
    componentId: "ApplicationReviewRisk",
    headerActionButton: true,
    headerActionButtonTitle: "Create Risk",
    modalBodyKey: "risk-modal",
    order: 6,
  },
  review_audit: {
    id: "review_audit",
    title: "Audit",
    componentId: "ApplicationReviewAudit",
    headerActionButton: true,
    headerActionButtonTitle: "Create Audit",
    modalBodyKey: "audit-modal",
    order: 7,
  },
  documents_section: {
    id: "documents_section",
    title: "Documents",
    componentId: "VerticalDocumentContainer",
  },
  review_legal: {
    id: "review_legal",
    title: "Legal",
    componentId: "ApplicationLegal",
    headerActionButton: true,
    headerActionButtonTitle: "Create Legal",
    modalBodyKey: "legal-modal",
  },
  review_cau: {
    id: "review_cau",
    title: "CAU Hub Department",
    componentId: "ApplicationCauHubDepartment",
    headerActionButton: true,
    headerActionButtonTitle: "Create CAU Hub Department",
    modalBodyKey: "cau-hub-dep-modal",
  },
  pre_disbursement: {
    id: "pre_disbursement",
    title: "Approving Conditions",
    componentId: "predisbursement",
    headerActionButton: true,
    headerActionButtonTitle: "Add Conditions",
    modalBodyKey: "pre-disbursement-modal",
  },
  review_insurance_department: {
    id: 'review_insurance_department',
    title: 'Insurance Policy',
    componentId: 'Reviewinsurancedepartment',
    headerActionButton: true,
    headerActionButtonTitle: 'Add Insurance Policy',
    modalBodyKey: 'insuranse-dep-referral-modal',
  },
  analysis_loan_applicant: {
    id: "analysis_loan_applicant",
    title: "Analysis of the Loan Applicant",
    // componentId: "AnalysisLoanApplicant",
    componentId: "AnalysisLoanApplicantWrapper",
  },
  market_analysis: {
    id: "market_analysis",
    title: "Market Analysis",
    componentId: "MarketAnalysis",
  },
  analysis_loan_requirement: {
    id: "analysis_loan_requirement",
    title: "Analysis of the Loan Requirement",
    componentId: "AnalysisOfLoanRequirement",
  },
  direct_other_bank_liability_analysis: {
    id: "direct_other_bank_liability_analysis",
    title: "Direct Other Bank Liability Analysis",
    componentId: "DirectOtherBankLiabilityAnalysis",
  },
  salary_deduction: {
    id: "salary_deduction",
    title: "Salary Deduction",
    componentId: "SalaryDeduction",
  },
  ability_to_repay: {
    id: "ability_to_repay",
    title: "Ability to Repay the Proposed Loan",
    componentId: "AbilityToPayLoan",
  },
  financial_position_of_company: {
    id: "financial_position_of_company",
    title: "Financial Position of the Company",
    componentId: "FinancialPositionOfTheCompany",
  },
  scv_details: {
    id: "scv_details",
    title: "SCV Details",
    componentId: "SCVDetails",
  },
  ratio_analysis: {
    id: "ratio_analysis",
    title: "Ratio Analysis",
    componentId: "RatioAnalysis",
  },
  recommended_and_approved_by: {
    id: "recommended_and_approved_by",
    title: "Recommended By / Approved By",
    componentId: "RecommendedApprovedBy",
  },
  reports_on_previous_rdb_burrowing: {
    id: "reports_on_previous_rdb_burrowing",
    title: "Reports on Previous RDB Borrowing",
    componentId: "ReportOnBurrowing",
  },
  loan_related_cif: {
    id: "loan_related_cif",
    title: "Loan Related CIF",
    componentId: "LoanRelatedCIF",
    // headerActionButton: true,
    // headerActionButtonTitle: 'Refresh',
  },
  service_charges: {
    id: "service_charges",
    title: "Service Charges",
    componentId: "BankServiceCharges",
  },
  marketing_channel: {
    id: "marketing_channel",
    title: "Marketing Channel Code",
    componentId: "MarketingChannelCode",
  },
  group_selection: {
    id: "group_selection",
    title: "Group Selection",
    componentId: "GroupSelection",
  },
  disbursed_api_status: {
    id: "disbursed_api_status",
    title: "Disbursement API Status",
    componentId: "DisbursementAPIStatus",
    // headerActionButton: true,
    // headerActionButtonTitle: 'Refresh',
  },
  arm_allocation: {
    id: "arm_allocation",
    title: "ARM Allocation",
    componentId: "ARMAllocation",
  },
  investment_account: {
    id: "investment_account",
    title: "Investment Account",
    componentId: "InvestmentAccount",
  },
  task_check_list: {
    id: "task_check_list",
    title: "Task Checklist",
    componentId: "TaskChecklist",
    headerActionButton: true,
    headerActionButtonTitle: "Create Task Checklist",
    modalBodyKey: "task-check-list-modal",
  },
  document_check_list: {
    id: "document_check_list",
    title: "Document Checklist",
    componentId: "DocumentChecklist",
  },
  predisbursement_check_list: {
    id: "predisbursement_check_list",
    title: "Pre Disbursement Checklist",
    componentId: "PredisbursementChecklist",
  },
  data_verification_checklist: {
    id: "data_verification_checklist",
    title: "Data Verification Checklist",
    componentId: "DataVerificationCheckList",
  },
  security_document_check_list: {
    id: "security_document_check_list",
    title: "Security Document Checklist",
    componentId: "SecurityDocumentChecklist",
  },
  post_disbursement_checklist: {
    id: "post_disbursement_checklist",
    title: "Post Disbursement Checklist",
    componentId: "PostDisbursementsChecklist",
  },
  loan_protection_insurance: {
    id: "loan_protection_insurance",
    title: "Loan Protection Insurance",
    componentId: "LoanProtectionInsurance",
  },
  life_insurance: {
    id: "life_insurance",
    title: "Life Insurance",
    componentId: "LifeInsurance",
  },
  fd_against_loans: {
    id: "fd_against_loans",
    title: "FD Against Loans",
    componentId: "FdAgainstLoans",
  },
  warehouse_receipt: {
    id: "warehouse_receipt",
    title: "Warehouse Receipt",
    componentId: "WarehouseReceipt",
  },
  tresury_bond: {
    id: "tresury_bond",
    title: "Tresury Bond",
    componentId: "TresuryBond",
  },
  shares: {
    id: "shares",
    title: "Shares",
    componentId: "Shares",
  },
  pledge: {
    id: "pledge",
    title: "Pledge",
    componentId: "Pledge",
  },
  stock_as_gold: {
    id: "stock_as_gold",
    title: "Stock As Gold",
    componentId: "StockAsGold",
  },
  movable_machinery_and_equipement: {
    id: "movable_machinery_and_equipement",
    title: "Movable Machinery & Equipment",
    componentId: "MovableMachineryEquipement",
  },
  immovable_machinery_and_equipement: {
    id: "immovable_machinery_and_equipement",
    title: "Immovable Machinery & Equipment",
    componentId: "ImmovableMachineryEquipement",
  },

  vehicle: {
    id: "vehicle",
    title: "Vehicle",
    componentId: "Vehicle",
  },
  property: {
    id: "property",
    title: "Property",
    componentId: "Property",
    headerActionButton: true,
    headerActionButtonTitle: "Save",
  },
  guarantor_details: {
    id: "guarantor_details",
    title: "Guarantor Details",
    componentId: "GuarantorDetails",
  },
  risk_documents: {
    id: "risk_documents",
    title: "Risk Documents",
    componentId: "RiskDocumentsList",
    order: 6,
  },
  legal_documents: {
    id: "legal_documents",
    title: "Legal Documents",
    componentId: "LegalDocumentsList",
    order: 10,
  },
  cau_hub_dep_documents: {
    id: "cau_hub_dep_documents",
    title: "CAU Hub Documents",
    componentId: "CAUDocumentsList",
    order: 11,
  },
  insruance_policy_document: {
    id: "insruance_policy_document",
    title: "Insurance Policy Documents",
    componentId: "InsurancePolicyDocumentsList",
    order: 9,
  },
  security_document_upload: {
    id: "security_document_upload",
    title: "Security Documents",
    componentId: "SecurityDocumentUpload",
    documentTypeId: 4,
    participantType: 5,
    applicantType: 1,
  },
  inspection_document_upload: {
    id: "inspection_document_upload",
    title: "Inspection Documents",
    componentId: "InspectionDocumentUpload",
    documentTypeId: 9,
    applicantType: 1,
  },
  micro_ability_to_repay: {
    id: "micro_ability_to_repay",
    title: "Ability To Repay the Proposed Loan",
    componentId: "AbilityToPayLoanAndProposedLoanAmount",
  },
  micro_analysis_loan_applicant: {
    id: "micro_analysis_loan_applicant",
    title: "Add Analysis Loan Applicant",
    componentId: "AddAnalysisLoanApplicantForm",
  },
  micro_analysis_loan_requirement: {
    id: "micro_analysis_loan_requirement",
    title: "Analysis of the Loan Requirement",
    componentId: "AnalysisLoanRequirement",
  },
  micro_marketAnalysis: {
    id: "micro_marketAnalysis",
    title: "Market Analysis",
    componentId: "MarketAnalysis",
  },
  micro_financialPositionOfTheCompany: {
    id: "micro_financialPositionOfTheCompany",
    title: "Financial Position Of The Company",
    componentId: "FinancialPositionOfTheCompanyContainer",
  },
  micro_direct_other_bank_liability_analysis: {
    id: "micro_direct_other_bank_liability_analysis",
    title: "Direct Other Bank Liability Analysis",
    componentId: "DirectOtherBankLiabilityAnalysis",
  },

  micro_auditedFinancialsDetails: {
    id: "micro_auditedFinancialsDetails",
    title: "Audited Financials Details",
    componentId: "AuditedFinancialsDetails",
  }
}
export const innerNavigationConfig = getCopy(defaultInnerNavigationConfig);
export const resetInnerNavigationConfig = getCopy(defaultInnerNavigationConfig);
export const applicantTypes = {
  1: "primaryApplicant",
  2: "joinBorrower",
  3: "guarantor",
  4: "spouse",
};

/** Inner tab component mapping */
export const componentMapping = {
  ...miroAppraisalComponents,
  ApplicationHistory: ApplicationHistoryRevamp,
  WorkflowHistory: WorkflowHistory,
  CMSHistory: CMSHistory,
  ApplicationsFormHistory: ApplicationsFormHistory,
  AssociatedLoans: AssociatedLoans,
  Application: Application,
  IdentificationDetails: IdentificationDetails,
  ApplicationTasks: ApplicationTasks,
  FundReleaseApproval: FundReleaseApproval,
  CreditAudit: CreditAudit,
  FollowUpChecklist: FollowUpChecklist,
  DisbursementCheckList: DisbursementCheckList,
  ApplicationRefer: ApplicationRefer,
  CreditLeadPriorApproval: CreditLeadPriorApproval,
  CribRecodes: CribRecodes,
  ActiveDecisions: ActiveDecisions,
  ProductDocumentUpload: ApplicationDocumentUpload,
  ProductBorrowersBasicDocument: BorrowersBasicDocument,
  DocumentsOtherDocuments: DocumentsOtherDocuments,
  PostConditionDocuments: PostConditionDocuments,
  SecurityDocuments: SecurityDocuments,
  PostDisbursementDocuments: PostDisbursementDocuments,
  DisbursementDocuments: DisbursementDocuments,
  AuditDocumentList: AuditDocumentList,
  CollateralDocuments: CollateralDocuments,
  ContactDetails: ContactDetails,
  AddressInformation: AddressInformation,
  EducationalDetails: EducationalDetails,
  IncomeDetails: IncomeDetails,
  ExpensesDetails: ExpensesDetails,
  CreditDetails: CreditDetails,
  BankDetails: BankDetails,
  EmploymentDetails: EmploymentDetails,
  BusinessDetails: BusinessDetails,
  AssetsDetails: AssetsDetails,
  SummaryLayout: SummaryLayout,
  ScoreCardLayout: ScoreCardLayout,
  InspectionScoreCard: InspectionScoreCard,
  TaxDetails: TaxDetails,
  InquiryOfObligations: InquiryOfObligations,
  PreviousBorrowing: PreviousBorrowing,
  AnalysisOfLoanRequirement: AnalysisOfLoanRequirement,
  RisksConditionsAndGeneralFacts: RisksConditionsAndGeneralFacts,
  JointBorrower: JointBorrower,
  ApplicationNote: ApplicationNote,
  ApplicationCondition: ApplicationCondition,
  AgreementCondition: AgreementCondition,
  ApplicationLegal: ApplicationLegal,
  ApplicationReviewRisk: ApplicationRisk,
  LifeInsuranceDetails: LifeInsuranceDetails,
  SecurityDetails: SecurityDetails,
  predisbursement: Predisbursement,
  OfferLatterLayout: OfferLatterLayoutRevamp,
  AbilityToPayLoan: AbilityToPayLoan,
  FinancialPositionOfTheCompany: FinancialPositionOfTheCompanyContainer,
  SCVDetails: SCVDetails,
  SalaryDeduction: SalaryDeduction,
  RecommendedApprovedBy: RecommendedApprovedBy,
  // AnalysisLoanApplicant: AnalysisLoanApplicant,
  AnalysisLoanApplicantWrapper: AnalysisLoanApplicantWrapper,
  RecoveryAccountLayout: RecoveryAccountLayoutV2,
  CompulsorySavings: CompulsorySavings,
  LoanRelatedCIF: LoanRelatedCIF,
  ReportOnBurrowing: ReportsPreviousBorrowings,
  DisbursementAPIStatus: DisbursementAPIStatus,
  TaskChecklist: TaskChecklist,
  DocumentChecklist: DocumentChecklist,
  SecurityDocumentChecklist: SecurityDocumentChecklist,
  PredisbursementChecklist: PredisbursementChecklist,
  PostDisbursementsChecklist: PostDisbursementsChecklist,
  DataVerificationCheckList: DataVerificationCheckList,
  PostDisbursementChecklist: PostDisbursementChecklist,
  ARMAllocation: ARMAllocation,
  InvestmentAccount: InvestmentAccount,
  BankServiceCharges: BankServiceCharges,
  MarketingChannelCode: MarketingChannelCode,
  GroupSelection: GroupSelection,
  LoanProtectionInsurance: CreditLoanProtectionInsurance,
  LifeInsurance: CreditLifeInsurance,
  FdAgainstLoans: CreditFdAgainstLoans,
  WarehouseReceipt: CreditWarehouseReceipt,
  TresuryBond: CreditTresuryBond,
  Shares: CreditShares,
  Pledge: CreditPledge,
  StockAsGold: CreditStockAsGold,
  MovableMachineryEquipement: CreditMovableMachineryEquipement,
  ImmovableMachineryEquipement: CreditImmovableMachineryEquipement,
  Vehicle: CreditVehicle,
  Property: CreditProperty,
  GuarantorDetails: GuarantorDetails,
  RiskDocumentsList: RiskDocumentsList,
  LegalDocumentsList: LegalDocumentsList,
  CAUDocumentsList: CAUDocumentsList,
  ProductGuarantorDocuments: ProductGuarantorDocuments,
  ProductJointBorrowerDocument: JointBorrowerDocument,
  SecurityDocumentUpload: SecurityDocumentUpload,
  InspectionDocumentUpload: InspectionDocumentUpload,
  InsurancePolicyDocumentsList: InsurancePolicyDocumentsList,
  Reviewinsurancedepartment: ReviewInsurancePolicyDocumentsList,
  TimelineLog: TimelineLog,
  ProcessTimeline: ProcessTimeline,
  ServiceCharges: ServiceCharges,
  PrimaryApplicantInspections: PrimaryApplicantInspections,
  JBInspections: JBInspectionsContainer,
  InspectionParticipation: InspectionParticipation,
  InspectionDetails: InspectionDetails,
  CollateralInspections: CollateralInspections,
  ScoreCardQuestions: ScoreCardQuestionsWrapper,
  BusinessInspection: BusinessInspection,
  OtherQuestions: OtherQuestions,
  ApplicationReviewAudit: ApplicationReviewAudit,
  DirectOtherBankLiabilityAnalysis,
  MarketAnalysis,
  SustainableEnvironmentalAndSafeguard,
  AuditedFinancialsDetails,
  ApplicationInsurancePolicy,
  ApplicationCauHubDepartment,
  GeneralDocuments,
  SupplimentaryCard,
  RelativeNotLivingWithYou,
  AutomaticSettlementOfCreditCardBills,
};
