import { Downgraded, useHookstate } from "@hookstate/core";
import { Box, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { branchDetails, masterDataDetails } from "../../../../configs/mainStore";
import { getApplicationLeadData } from "../../../../services/applicationApis";
import { formatCurrency } from "../../../../services/currencyFormater";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../GlobalModal/GlobalModal";

const PriorApprovalReferenceModal = (props) => {
  const [personalData, setPersonalData]: any = React.useState({});
  const [creditData, setCreditData]: any = React.useState({});
  const [contactData, setContactData]: any = React.useState({});
  const masterData: any = useHookstate(masterDataDetails);
  const { branchMap } = branchDetails.attach(Downgraded).get();

  useEffect(() => {
    getLeadData();
  }, [props.data]);

  const getLeadData = async () => {
    const res = await getApplicationLeadData(props?.data);
    const data = res?.data;
    const listVieWDetails = data?.request?.APPLICANT_FORM_DATA && JSON.parse(data?.request["APPLICANT_FORM_DATA"]);
    const personalData = listVieWDetails?.personalData;
    const creditData = listVieWDetails?.creditData;
    const contactData = listVieWDetails?.contactData;

    setPersonalData(personalData);
    setCreditData(creditData);
    setContactData(contactData);
  };

  const title: any = masterData?.TITLE?.get()?.find((t) => t.id === personalData?.primaryTitle);

  const district: any = masterData?.DISTRICT?.get()?.find((t) => t.id === personalData?.district);

  const branchId = personalData?.branch;

  const branch: any = branchMap[branchId]?.name;

  const SectionInfoItem = ({ index, title, value }) => {
    return (
      <Stack flex={1} direction={"column"} justifyContent={"flex-start"} alignItems={"center"}>
        <Stack style={{ width: "100%", textAlign: "left" }}>
          <Typography variant="subtitle1">
            {index}. {title}:
          </Typography>
        </Stack>
        <Stack style={{ width: "100%", textAlign: "left" }}>
          <Typography variant="body1" fontWeight={300}>
            {value}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const BorrowerCreditDetails = () => {
    const product: any = props.productMasterData?.productTypesMap[creditData?.type].get();
    const sector: any = props.productMasterData?.productSectorsMap[creditData?.sector]?.get();
    const scheme: any = props.productMasterData?.productSchemesMap[creditData?.scheme]?.get();
    const applicant: any = props?.productMasterData?.productApplicantsMap[creditData?.applicantType]?.get();
    const loanPurpose = masterData?.LOAN_PURPOSE?.get()?.find((t) => t.id === creditData?.loanPurpose);
    const repaymentType = masterData?.REPAYMENT_TYPE?.get()?.find((t) => t.id === creditData?.repaymentType);
    const loanFrequency = masterData?.LOAN_FREQUENCY?.get()?.find((t) => t.id === creditData?.loanFrequency);
    const fundSource = masterData?.FUND_SOURCE?.get()?.find((t) => t.id == creditData?.fundSource);

    const gap = "10px";
    return (
      <Stack>
        <Stack flex={1} direction="row" padding={gap}>
          <SectionInfoItem index={1} title={"Product"} value={product?.TYPE_NAME.toString() ?? "N/A"}></SectionInfoItem>
          <SectionInfoItem index={2} title={"Sector"} value={sector?.TYPE_NAME.toString() ?? "N/A"}></SectionInfoItem>
          <SectionInfoItem index={3} title={"Scheme"} value={scheme?.TYPE_NAME.toString() ?? "N/A"}></SectionInfoItem>
        </Stack>
        <Stack flex={1} direction="row" padding={gap}>
          <SectionInfoItem index={4} title={"Applicant Type"} value={applicant?.TYPE_NAME ? applicant?.TYPE_NAME : "N/A"}></SectionInfoItem>
          <SectionInfoItem index={5} title={"Loan Amount"} value={formatCurrency(creditData?.loanAmount) ?? "N/A"}></SectionInfoItem>
          <SectionInfoItem index={6} title={"Loan Purpose"} value={loanPurpose?.name ? loanPurpose?.name : "N/A"}></SectionInfoItem>
        </Stack>
        <Stack flex={1} direction="row" padding={gap}>
          <SectionInfoItem index={7} title={"Loan Cycle"} value={creditData?.loanCycles ?? "N/A"}></SectionInfoItem>
          <SectionInfoItem index={8} title={"Repayment Type"} value={repaymentType?.name ? repaymentType?.name : "N/A"}></SectionInfoItem>
          <SectionInfoItem index={9} title={"Loan Frequency"} value={loanFrequency?.name ? loanFrequency?.name : "N/A"}></SectionInfoItem>
        </Stack>
        <Stack flex={1} direction="row" padding={gap}>
          <SectionInfoItem index={10} title={"Interest Rate"} value={creditData?.interestRate ?? "N/A"}></SectionInfoItem>
          <SectionInfoItem index={11} title={"Loan Tenor"} value={creditData?.loanTerms ?? "N/A"}></SectionInfoItem>
          <SectionInfoItem index={12} title={"Fund Source"} value={fundSource?.name ? fundSource?.name : "N/A"}></SectionInfoItem>
        </Stack>
        <Stack flex={1} direction="row" padding={gap}>
          <SectionInfoItem index={13} title={"Grace Period"} value={creditData?.gracePeriod ? creditData.gracePeriod : "N/A"}></SectionInfoItem>
        </Stack>
      </Stack>
    );
  };
  const BorrowerBasicDetails = () => {
    const gap = "10px";
    return (
      <Stack>
        <Stack flex={1} direction="row" padding={gap}>
          <SectionInfoItem index={1} title={"Title"} value={title?.name ? title?.name : "N/A"}></SectionInfoItem>
          <SectionInfoItem index={2} title={"Initials in Full"} value={personalData?.initialsInFull ? personalData?.initialsInFull : "N/A"}></SectionInfoItem>
          <SectionInfoItem index={3} title={"Last Name"} value={personalData?.primaryLastName ? personalData?.primaryLastName : "N/A"}></SectionInfoItem>
        </Stack>
        <Stack flex={1} direction="row" padding={gap}>
          <SectionInfoItem index={4} title={"Used Name"} value={personalData?.usedName ? personalData?.usedName : "N/A"}></SectionInfoItem>
          <SectionInfoItem index={5} title={"Initials"} value={personalData?.initials ? personalData?.initials : "N/A"}></SectionInfoItem>
          <SectionInfoItem index={6} title={"Other Names"} value={personalData?.primaryOtherName ? personalData?.primaryOtherName : "N/A"}></SectionInfoItem>
        </Stack>
        <Stack flex={1} direction="row" padding={gap}>
          <SectionInfoItem index={7} title={"New NIC No"} value={personalData?.newNic ? personalData?.newNic : "N/A"}></SectionInfoItem>
          <SectionInfoItem index={8} title={"Old NIC No"} value={personalData?.oldNic ? personalData?.oldNic : "N/A"}></SectionInfoItem>
          <SectionInfoItem index={9} title={"Primary Contact No"} value={contactData?.primaryContact ? contactData?.primaryContact : "N/A"}></SectionInfoItem>
        </Stack>
        <Stack flex={1} direction="row" padding={gap}>
          <SectionInfoItem index={10} title={"Primary Email Address"} value={contactData?.primaryEmail ? contactData?.primaryEmail : "N/A"}></SectionInfoItem>
          <SectionInfoItem index={11} title={"Nearest RDB District"} value={district?.name ? district?.name : "N/A"}></SectionInfoItem>
          <SectionInfoItem index={12} title={"Nearest RDB Branch"} value={branch ? branch : "N/A"}></SectionInfoItem>
        </Stack>
      </Stack>
    );
  };

  return (
    <Box pb={2} padding={"5px"}>
      <Paper style={{ boxShadow: "none" }} elevation={0}>
        <Stack mt={3} mb={1} direction={"row"} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa", padding: "10px" }}>
          <Typography color={"gray"}>Borrower Basic Details</Typography>
        </Stack>

        <BorrowerBasicDetails></BorrowerBasicDetails>

        <Stack mt={4} direction={"row"} className="border-radius-5 basic-font basic-font-color-bold font-size-16 align-left" style={{ backgroundColor: "#e6f0fa", padding: "10px" }}>
          <Typography color={"gray"}>Credit Details</Typography>
        </Stack>

        <BorrowerCreditDetails></BorrowerCreditDetails>
      </Paper>
      <ButtonComponent
        title="Close"
        onClick={() => {
          closeGlobalModal();
        }}
        sx={{ marginTop: "15px" }}
        variant={"outlined"}
      />
    </Box>
  );
};

export default PriorApprovalReferenceModal;
