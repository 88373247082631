import { Grid } from "@mui/material";
import React from "react";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../../GlobalToast";
import BuildOtherParticipantItems from "./BuildOtherParticipantItems";

const ShareholderAssignModal = ({ data, callBackOnAction }) => {
  const [selectedPreShareholderList, setSelectedPreShareholderList]: any = React.useState({
    partnerships: [],
    individuals: [],
    directors: [],
  });

  const assignDispatcher = (selectedData = {}) => {
    if (selectedPreShareholderList.partnerships.length < 1 && selectedPreShareholderList.individuals.length < 1 && selectedPreShareholderList.directors.length < 1) {
      addToaster({
        status: "error",
        title: "Empty",
        message: "You haven't selected anything, please select before assigning!",
      });
    } else {
      callBackOnAction(selectedPreShareholderList);
      closeGlobalModal();
    }
  };

  const callBackOnShareholderSelect = (selectedData = {}) => {
    setSelectedPreShareholderList(selectedData);
  };

  return (
    <Grid container columns={12}>
      <BuildOtherParticipantItems title={"Partnerships"} options={data?.partnerships || []} typePrefix={"partnershipD_"} selectedPreShareholderList={selectedPreShareholderList} callBackOnShareholderSelect={callBackOnShareholderSelect} />
      <BuildOtherParticipantItems title={"Individuals"} options={data?.individuals || []} typePrefix={"individualD_"} selectedPreShareholderList={selectedPreShareholderList} callBackOnShareholderSelect={callBackOnShareholderSelect} />
      <BuildOtherParticipantItems title={"Directors"} options={data?.directors || []} typePrefix={"directorD_"} selectedPreShareholderList={selectedPreShareholderList} callBackOnShareholderSelect={callBackOnShareholderSelect} />
      <Grid item xs={12} textAlign={"right"}>
        <ButtonComponent
          title={"Assign"}
          variant="contained"
          onClick={() => {
            assignDispatcher();
          }}
          disabled={false}
          loadingbtn={false}
        />
      </Grid>
    </Grid>
  );
};

export default ShareholderAssignModal;
