import { useState } from '@hookstate/core';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Grid, Stack } from '@mui/material';
import React from 'react';
import { ERROR_CODES } from '../../../../../configs/constants/errorCode';
import { userDetails } from '../../../../../configs/mainStore';
import { applicationTimelineApi, updateRefer } from '../../../../../services/creditFileApiCall';
import { closeBtn, createButton } from '../../../../../styles';
import { responseSuccessCode } from '../../../../../utility/other';
import { referAction, referStatus } from '../../../../../utility/util';
import { ButtonComponent } from '../../../../InputComponents/ButtonComponent/ButtonComponent';
import ModalInputComponent from '../../../../InputComponents/ModalInputComponent/ModalInputComponent';
import { TextBoxComponent } from '../../../../InputComponents/TextBoxComponent/TextBoxComponent';
import { closeGlobalModal } from '../../../GlobalModal/GlobalModal';
import { addToaster } from '../../../GlobalToast';

const ReferActionModal = (params) => {
  const [savingData, setSavingData] = React.useState<any>(false);
  const loginUser = useState(userDetails);

  const initialData = {
    note: params?.note,
    actionNote: '',
    referId: params?.referId,
    opinionNote: params?.data?.oppinionNote,
  };

  const referState: any = useState(initialData);
  const showErrors = useState(false);

  const updateReferHandle = async (status) => {
    setSavingData(true);
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      const action = Object.keys(referAction)?.find((act) => referAction[act]?.key == status);
      const referStat = Object.keys(referStatus)?.find((sts) => referStatus[sts]?.key == 'complete');

      const data = {
        applicationId: params?.applicationId,
        referId: referState.referId.get(),
        action: action,
        referStatus: referStat,
        actionNote: referState.actionNote.get(),
        opinionNote: referState?.opinionNote?.get(),
        assignedBy: params?.data?.assignee,
        createdBy: params?.data?.createdBy,
      };

      const res: any = await updateRefer(data);

      if (res?.status == responseSuccessCode) {
        addToaster({
          status: 'success',
          title: 'Success',
          message: res?.data?.msg,
        });

        const referenceId = params?.data?.referId;
        const referenceType = params?.data?.referType;

        const appTimelineParams = {
          referenceId,
          applicationId: params?.applicationId,
          referenceType,
          actionedBy: loginUser?.userId?.get(),
          actionNote: referState?.actionNote?.get() || '',
          endProcess: true,
          actionType: 'OPINION_APPROVED',
        };

        const applicationTimelineApiResponse = await applicationTimelineApi(appTimelineParams);

        if (applicationTimelineApiResponse?.status === ERROR_CODES.success) {
          addToaster({
            status: 'success',
            title: 'Success',
            message: applicationTimelineApiResponse?.data?.message,
          });
        } else {
          addToaster({
            status: 'error',
            title: 'Error',
            message: 'Timeline Update Failed',
          });
        }

        // calling the modal callback passing necessary arguments
        if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
          params.callBackOnAction(res?.data?.data);
        }
      } else {
        addToaster({
          status: 'error',
          title: 'Error',
          message: res?.data?.msg,
        });
      }

      closeGlobalModal();
    }
    setSavingData(false);
  };

  const closeRefer = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return referState.actionNote.get() === '';
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Action Referral</div>
      </Stack>

      <Grid container spacing={1} mt={2} rowSpacing={2} className="modal-text-field">
        <ModalInputComponent
          title="Referral Id"
          component={
            <div className="align-left font-size-14 basic-font basic-font-color-bold">{referState?.referId?.get()}</div>
          }
          isMandatory={false}
          showErrors={false}
        />
        <ModalInputComponent
          title="Referral Note"
          component={
            <div className="align-left font-size-14 basic-font basic-font-color-bold">{referState?.note?.get()}</div>
          }
          isMandatory={false}
          showErrors={false}
        />
        <ModalInputComponent
          title="Opinion Note"
          component={
            <div className="align-left font-size-14 basic-font basic-font-color-bold">
              {referState?.opinionNote?.get()}
            </div>
          }
          isMandatory={false}
          showErrors={false}
        />
        <ModalInputComponent
          title="Action Note"
          component={
            <TextBoxComponent
              value={referState?.actionNote?.get()}
              onChange={(e) => {
                resetError();
                referState.actionNote.set(e.target.value);
              }}
              variant="outlined"
              size="small"
              multiline={true}
              minRows={4}
            />
          }
          isMandatory={true}
          showErrors={showErrors.get() && referState.actionNote.get() === ''}
        />
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeRefer}
          loadingbtn={true}
          style={closeBtn}
        />
        {/* <ButtonComponent
          startIcon={<NotInterestedIcon />}
          title="Reject"
          variant="contained"
          onClick={() => updateReferHandle('reject')}
          loadingbtn={true}
          style={rejectBtn}
        /> */}
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Acknowledged"
          variant="contained"
          onClick={() => updateReferHandle('approve')}
          loadingbtn={true}
          style={createButton}
          disabled={savingData}
        />
      </Stack>
    </Box>
  );
};

export default ReferActionModal;
