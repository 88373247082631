import { Grid } from "@mui/material";
import { SinglePageBreak } from "../../SecurityDocuments/CommonPageBreaks";

function SpecialConditions({ data, recoveryAccount, offerLetterConditionsList, typeCaseClass }) {

  const renderSpecialConditions = (condition) => {
    let description: any = "";
    let formattedDescription: any = "";
    let placeholders: any = {};
    let keys: any[] = [];

    description = condition.description;
    placeholders = JSON.parse(condition.palceholders);
    keys = Object.keys(placeholders);

    keys.forEach((key) => {
      if (description.includes(key)) {
        let value = placeholders[key].value != null ? placeholders[key].value : "N/A";
        description = description.replaceAll(key, value);
      }
    });

    formattedDescription = description;

    return formattedDescription;
  };

  return (
    <>
      {offerLetterConditionsList.length > 0 && (
        <>
          <SinglePageBreak />
          <Grid container columns={12} pl={1} pr={1}>
            <Grid item xs={12}>
              <h4 style={{ textDecoration: "underline" }}>SPECIAL CONDITIONS</h4>
            </Grid>
            <Grid item xs={12} className="offer-letter-condition-container">
              <ol className="offer-letter-condition-list">
                {offerLetterConditionsList.length > 0 &&
                  offerLetterConditionsList.map((condition) => {
                    return <li className={`security-doc-type-variable ${typeCaseClass}`}>{condition.palceholders != null ? renderSpecialConditions(condition) : condition.description}</li>;
                  })}
              </ol>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default SpecialConditions;
