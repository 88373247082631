import moment, { Moment } from "moment";

const shortDateFormat = "YYYY-DD-MM h:mm a";
const dateFormat = "MMMM Do YYYY";
const dateFormatWithTime = "MMMM Do YYYY, h:mm:ss a";
const timeFormatWithTime = "h:mm:ss a";
const standardDateFormat = "DD/MM/YYYY";
const standardDateFormatForValidation = "MM/DD/YYYY";
const defaultCurrency = "LKR";
const dateAndMonth = "YYYY MMMM";
const yearOnly = "YYYY";
const dbDateFormatter = "DD/MMM/YYYY";
const yearMonthDateFormat = "YYYY/MM/DD";
const monthDateYearFormat = "MM/DD/YYYY";
const dateMonthYearFormat = "DD MMM, YYYY";

export const dateFormatWithDate = (date) => {
  return moment(date).format(dateFormat);
};

export const dateformatToLocalString = (date: Moment) => {
  let formattedDate = date.toLocaleString();
  return formattedDate && formattedDate !== 'Invalid date' ? formattedDate : '';
}

export const dateFormatMMDDYYYY = (date) => {
  return moment(date).format(monthDateYearFormat);
};

export const dateFormatWithTimeChange = (date) => {
  return moment(date).format(dateFormatWithTime);
};

export const timeFormatWithTimeChange = (time) => {
  return moment(time).format(timeFormatWithTime);
};

export const timeFormatWithGmtZero = (time) => {
  return moment.utc(time).format(timeFormatWithTime);
};

export const dateTimeFormatWithGmtZero = (time) => {
  return moment.utc(time).format(dateFormatWithTime);
};

export const figureFormat = (figure) => {
  var val = figure ? figure.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
  return `${defaultCurrency} ${val}`;
};

export const periodsToMonths = (years, months) => {
  const y = !isNaN(years) ? parseInt(years) : 0;
  const m = !isNaN(months) ? parseInt(months) : 0;
  return y * 12 + m;
};

export const shortDateFormatWithDate = (date) => {
  return moment(date).format(shortDateFormat);
};

export const standardDateFormatter = (date) => {
  return moment(date).format(standardDateFormat);
};

export const yearAndMonthFormatter = (date) => {
  return moment(date).format(dateAndMonth);
};

export const yearFormatter = (date) => {
  return moment(date).format(yearOnly);
};

export const dbDateFormatterFn = (date) => {
  return moment(date).format(dbDateFormatter);
};

export const formattedDate = (date) => {
  return date ? moment(date, standardDateFormat).toDate() : null;
};

export const formattedDateForValidation = (date) => {
  return moment(date, standardDateFormatForValidation).toDate();
};

export const dateMonthYearFormatWithGmtZero = (time) => {
  return moment.utc(time).format(dateMonthYearFormat);
};

export const dateToTimeFormatWithGmtZero = (date) => {
  return moment.utc(date).format(timeFormatWithTime);
};

export const dateGmtZeroConvertionState = (date) => {
  const zeroGmtDate = moment(date).add(330, "minutes");

  let fullDateWithTime = moment.utc(zeroGmtDate).format(dateFormatWithTime);
  let fullTime = moment.utc(zeroGmtDate).format(timeFormatWithTime);
  let normalDate = moment.utc(zeroGmtDate).format(dateFormat);

  return {
    fullDateWithTime,
    fullTime,
    normalDate,
  };
};

export const calculateAge = (date) => {
  var years = moment().diff(date, "years");
  var months = moment().diff(date, "months");
  return [years, months % 12];
};

export const calculateProcessingTime = (date) => {
  var nowDate = moment()["_d"];
  var formattedNowDate = moment(nowDate).format(yearMonthDateFormat);
  var formattedCreatedDate = moment(date).format(yearMonthDateFormat);
  const diff = moment(formattedNowDate).diff(formattedCreatedDate);
  const diffDuration = moment.duration(diff);
  return `${diffDuration.months()}: Months ${diffDuration.days()}: Days ${diffDuration.hours()}: Hours`;
};

export const currentEmployedDuration = (date) => {
  if (date) {
    var years = moment().diff(date, "years");
    var months = moment().diff(date, "months");

    var x = [years, months % 12];
    return `${x[0]} years ${x[1]} months`;
  } else {
    return "N/A";
  }
};

export const previousEmployedDuration = (startDate, endDate) => {
  if (startDate && endDate) {
    var years = moment(endDate).diff(startDate, "years");
    var months = moment(endDate).diff(startDate, "months");
    var x = [years, months % 12];
    return `${x[0]} years ${x[1]} months`;
  } else {
    return "N/A";
  }
};

export const currentEmployedStartDate = (date) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let year = moment(date, "MM/DD/YYYY").year();
  let month = moment(date, "MM/DD/YYYY").month();
  return `${monthNames[month]} ${year} - Present`;
};

export const previousEmployedStartDate = (startDate, endDate) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let startDateyear = moment(startDate, "MM/DD/YYYY").year();
  let startDatemonth = moment(startDate, "MM/DD/YYYY").month();
  let endDateyear = moment(endDate, "MM/DD/YYYY").year();
  let endDatemonth = moment(endDate, "MM/DD/YYYY").month();
  return `${monthNames[startDatemonth]} ${startDateyear} - ${monthNames[endDatemonth]} ${endDateyear}`;
};

export const capitalize = (str = "") => {
  if (!str) return "N/A";
  const exceptions = ["crib", "cif", "arm", "api"];
  const replacements = {
    activedecisions: "Active Decisions",
    score_card: "Score Cards",
    predisbursement: "Approving Conditions",
    condition: "Special Conditions",
    notes: "Notes",
    statu: "Status",
    security_documents: "Security Document Formats",
    pre_disbursement: "Approving Conditions",
    summary: "Credit Summary",
    service_charges: "Charges",
    refers: "Refer",
    post_condition_documents: "Post Condition Documents",
    recovery_account: "Recovery Account Selection",
    data_verification_checklist: "Mandatory Data Verification Checklist",
    cms_history: "CMS History",
    assoc_loans: "Associated Loans",
    borrowers_basic_documents: "Applicant Basic Documents",
  };

  if (replacements[str]) {
    return replacements[str];
  }

  return (
    str
      .toLowerCase()
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => {
        if (replacements[word]) {
          return replacements[word];
        } else if (exceptions.includes(word)) {
          return word.toUpperCase();
        } else {
          word = word.charAt(0).toUpperCase() + word.slice(1);
          return word;
        }
      })
      .join(" ") + (str.includes("Disbursed API Status") ? "s" : "")
  );
};

export const previousEmployDateCompare = (startDate, endDate) => {
  let prevEmpStartDate = moment(startDate).format(yearMonthDateFormat);
  let prevEmpEndDate = moment(endDate).format(yearMonthDateFormat);
  prevEmpStartDate = prevEmpStartDate.replace(/\//g, "-");
  prevEmpEndDate = prevEmpEndDate.replace(/\//g, "-");
  let isAfter = moment(prevEmpEndDate).isAfter(prevEmpStartDate);
  return isAfter;
};

export const documentDate = () => {
  return moment().format(standardDateFormat);
};

export const getCurrentEmployedMonths = (date) => {
  if (date) {
    var months = moment().diff(date, "months");
    return months;
  } else {
    return 0;
  }
};

export const getPrevEmployedMonths = (startDate, endDate) => {
  if (startDate && endDate) {
    var months = moment(endDate).diff(startDate, "months");
    return months;
  } else {
    return 0;
  }
};

export function NicDobFinder(nicNumber) {
  if (nicNumber.length === 10 || nicNumber.length === 9) {
    nicNumber = "19" + nicNumber;
  }
  const birthYear = parseInt(nicNumber.toString().substr(0, 4));
  const birthMonthDate = parseInt(nicNumber.toString().substr(4, 3));
  if (birthYear % 4 !== 0) {
    return new Date(birthYear, 0, birthMonthDate - 1);
  } else {
    return new Date(birthYear, 0, birthMonthDate);
  }
}

export const scoreValueFormat = (score) => {
  if (score == null || score === "") return;
  return parseFloat(score).toFixed(2);
};