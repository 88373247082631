import { Downgraded, useState } from "@hookstate/core";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Box, Grid } from "@mui/material";
import React, { FC, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { securityDocumentPermissions, securityDocumentTextCase } from "../../../../../../configs/constants/contants";
import {
  branchDetails,
  creditData,
  letterDocumentStructure,
  userDetails
} from "../../../../../../configs/mainStore";
import {
  disableAccess
} from "../../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../../../InputComponents/SelectComponent/SelectComponent";
import { addToaster } from "../../../../GlobalToast";
import SecurityDoceumentsFileDocumentsViewSection from "../../SecurityDocumentsFileDocumentsViewSection";
import CommonSecurityDocHeader from "../CommonSecurityDocHeader";
import { StandingInstructionsBody } from "./StandingInstructionsComponents/StandingInstructionsBody";
import { StandingInstructionsHeader } from "./StandingInstructionsComponents/StandingInstructionsHeader";

interface StandingInstructionsPropType {
  data?: any;
  applicationId?: any;
  letterDocumentData?: any;
  existingDataArr?: any;
  disableAccordingToTab?: any;
  isLoggedInUserNotTheAssignee?: any;
  setDocumentDataUpdated?: any;
  currentApplicationWfData?: any;
}

const printValidator = {
  standingInstructionsBody: () => [],
};

export const StandingInstructions: FC<StandingInstructionsPropType> = ({
  data,
  applicationId,
  existingDataArr,
  setDocumentDataUpdated,
}) => {
  const creditFileData: any = creditData.attach(Downgraded).get();
  const componentRef: any = useRef();

  const letterDocumentStructureData: any = useState(letterDocumentStructure);
  const existingLetterDocumentData = letterDocumentStructureData.attach(Downgraded).get();

  const [standingInstructionsDataArr, setStandingInstructionsDataArr] = React.useState<any>({});
  const userData: any = userDetails.attach(Downgraded).get();
  const usedBranchId = creditFileData["originationBranch"] || 1;
  const documentId = securityDocumentPermissions.standingInstruction || "standing_instruction";
  const [coreBankData, setCodeBankData] = React.useState({});
  const [typeCase, setTypeCase] = React.useState(1);
  const [typeCaseClass, settypeCaseClass] = React.useState();

  const noPermissionSave = disableAccess({
    documentComponentId: documentId,
    privilegeKeyforSec: "SEC_DOC_EDIT_SAVE_CHANGES",
  });

  const noPermissionPrint = disableAccess({
    documentComponentId: documentId,
    privilegeKeyforSec: "SEC_DOC_PRINT_SECURITY_DOC",
  });

  useEffect(() => {
    prepareDependencies();
  }, []);

  useEffect(() => {
    changeTypeCase();
  }, [typeCase]);

  const handlePrint = () => {
    let documentErrors: any[] = [];
    let validateStatus = true;

    for (const key of Object.keys(printValidator)) {
      try {
        const errors = printValidator[key]();
        if (errors.length > 0) {
          errors.forEach((error) => {
            documentErrors.push(error.message);
          });
          validateStatus = false;
        }
      } catch (error) {
        continue;
      }
    }

    if (documentErrors.length > 0) {
      let formattedMessage = ["Failed to proceed with empty placeholders.\n\n"];

      for (const item of documentErrors) {
        formattedMessage.push(`\n\n👉 ${item}`);
      }

      addToaster({
        status: "error",
        title: "Error",
        message: formattedMessage,
      });
      validateStatus = false;
    }

    return validateStatus;
  };

  const proceedPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const prepareDependencies = async () => {
    const {
      INVESTMENT_ACCOUNT: investmentAccount = "",
      INVESTMENT_AMOUNT: investmentAmount = "",
      REC_ACC: recoveryAccount = ""
    } = data.coreData || {};

    setCodeBankData({ recoveryAccount, investmentAmount, investmentAccount, branch: branchDetails.branchMap[usedBranchId].name.get() });

    if (existingDataArr) {
      let standingInstructionsDataObj = existingDataArr.find((obj) => obj?.LETTER_TYPE === "standingInstructions");
      setStandingInstructionsDataArr(standingInstructionsDataObj);
    }
  };

  const changeTypeCase = () => {
    settypeCaseClass(securityDocumentTextCase[typeCase || 1].typeCaseClass);
  };

  const getPageMargins = () => {
    return `@page { margin: ${"7mm"} ${"10mm"}  ${"15mm"} ${"15mm"} !important; }`;
  };

  return (
    <Grid container className="inner-component-full-height basic-font">
      <style>{getPageMargins()}</style>
      <div className="full-width full-height">
        <SecurityDoceumentsFileDocumentsViewSection
          data={data}
          applicationId={applicationId}
          isSecurityDocument={true}
          securityDocumentKey={"standing-instruction"}
        />
        <Grid container pt={2} pr={2} pl={2} pb={1} ref={componentRef}>
          <CommonSecurityDocHeader documentName={"Standing Order"} textCase={"lower"} />
          <StandingInstructionsHeader data={creditFileData} typeCaseClass={typeCaseClass} />
          <StandingInstructionsBody
            data={creditFileData}
            coreBankData={coreBankData}
            typeCaseClass={typeCaseClass}
          />
        </Grid>
        <Box m={1}>
          <Grid container columns={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Grid item xs={4} pr={1}>
              <SelectComponent
                label={"Select Typecase"}
                value={typeCase}
                values={Object.values(securityDocumentTextCase)}
                onChange={(e) => setTypeCase(e.target.value)}
                required={false}
                defaultPlaceholder={true}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonComponent
                startIcon={<LocalPrintshopIcon />}
                title={"Print PDF"}
                variant="contained"
                onClick={() => {
                  handlePrint() && proceedPrint();
                }}
                style={{ maxHeight: "40px", marginTop: "auto" }}
                disabled={noPermissionPrint}
                loadingbtn={true}
                loading={false}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </Grid>
  );
};
