import { Downgraded, useState } from "@hookstate/core";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from "@mui/icons-material/Edit";
import { Divider, InputAdornment, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { formatCurrency } from "../../../../../services/currencyFormater";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

// Typo
import Typography from "@mui/material/Typography";
import { NumericFormat } from "react-number-format";
import { appSetting, userDetails } from "../../../../../configs/mainStore";
import { getApplicationProductCatalog, getCalOtherCharges } from "../../../../../services/salesSupportCalculatorService";
import { deleteApplicationCharges, getApplicationCharges, setApplicationCharges } from "../../../../../services/trailCalculatorService";
import { Access, userName } from "../../../../../utility/helpers/creditFileUtility";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal, openGlobalModal } from "../../../GlobalModal/GlobalModal";
import { Toast, toast } from "../../../GlobalToast";

const ServiceCharges = ({ data, applicationId, renderId }) => {
  const appSettings: any = useState(appSetting);
  const allChargesList: any = useState({});
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  const [isLoading, setIsLoading] = React.useState(true);
  const [updating, setUpdating] = React.useState(false);

  const [newChargesList, setNewChargesList]: any = React.useState([]);
  const chargesMap: any = useState({
    activeCharges: [],
    inactiveCharges: [],
    productChargesMap: {}
  });

  const catalog = data.currentProductCatalogKey ? data.currentProductCatalogKey : "";

  useEffect(() => {
    loadDetails();
  }, [renderId]);

  const loadDetails = async () => {
    setIsLoading(true);

    let [applicationCharges, chargesList = [], appCharges] = await Promise.all([getApplicationProductCatalog(catalog), getCalOtherCharges(appSettings.get().language), getApplicationCharges(applicationId)]);
    let transformedChargesList = (applicationCharges || []).reduce((acc, item) => {
      let { charges } = item;
      try {
        charges = JSON.parse(charges);
      } catch (error) { } finally {
        acc = [...acc, ...charges];
      }
      return acc;
    }, []);

    let data = chargesList.filter(item => transformedChargesList.includes(item.typeId));

    let { otherChargesList = [] } = appCharges || { data: {} };

    let activeCharges: any = otherChargesList.filter((obj) => obj.publish === 1);
    let inactiveCharges: any = otherChargesList.filter((obj) => obj.publish !== 1);

    const productChargesMap = (data || []).reduce((obj, charge) => {
      obj[charge.typeId] = charge;
      return obj;
    }, {});

    activeCharges = activeCharges.map((obj) => {
      return { ...obj, ...(productChargesMap?.[obj.chargeType] || {}) };
    });
    inactiveCharges = inactiveCharges.map((obj) => {
      return { ...obj, ...(productChargesMap?.[obj.chargeType] || {}) };
    });

    inactiveCharges.sort((a, b) => (a.desc > b.desc ? 1 : b.desc > a.desc ? -1 : 0));
    activeCharges.sort((a, b) => (a.desc > b.desc ? 1 : b.desc > a.desc ? -1 : 0));

    chargesMap.set({
      activeCharges,
      inactiveCharges,
      productChargesMap
    });
    setNewChargesList(activeCharges);

    setIsLoading(false);
  };

  const handleChargesUpdate = async () => {
    const applicationCharges = {
      applicationId: applicationId,
      otherFees: {},
      updatedBy: userId,
      updatedAt: (new Date()).toUTCString()
    };

    newChargesList.forEach(element => {
      delete element.edit;
    });
    setNewChargesList(newChargesList);

    const invalidCharges = newChargesList.filter(charge => !charge.amount);
    if (invalidCharges.length > 0) {
      Toast.error("Please enter the amount");
      return;
    };

    newChargesList.forEach(charge => {
      applicationCharges.otherFees[charge.typeId] = charge.amount;
    });

    try {
      setUpdating(true);
      await setApplicationCharges(applicationCharges);
      toast({
        status: "success",
        message: "Charges updated",
      });
    } catch (error) {
      toast({
        status: "error",
        message: "Failed to update",
      });
    } finally {
      setUpdating(false);
    }
  };

  const handleDeleteCharge = async (charge, index) => {
    closeGlobalModal();

    if (!charge.typeId) {
      Toast.error("Missing required data");
    } else {
      try {
        await deleteApplicationCharges(applicationId, charge.typeId);
        delete newChargesList[index];
        setNewChargesList(newChargesList.filter(item => item));
        Toast.success(`Charge type "${charge.desc || charge.name}" deleted successfully`);
      } catch (error) {
        Toast.error(`Failed to delete charge "${charge.desc || charge.name}"`);
      }
    }
  };

  const onClickedAction = () => {
    openGlobalModal({
      modalSize: "sm",
      title: "",
      bodyId: "charges-modal",
      close: false,
      modalParams: {
        chargesMap: chargesMap,
        allChargesList: allChargesList,
        addNewCharges: (items) => {
          const charges = newChargesList;
          for (const charge of items) {
            charges.push({ ...charge, amount: 0.0, typeId: charge.typeId, chargeType: charge.typeId, publish: 1, edit: true });
          }
          setIsLoading(true);
          const timeFlag = setTimeout(() => {
            setNewChargesList(charges);
            chargesMap.activeCharges.set(charges);
            setIsLoading(false);
            clearTimeout(timeFlag);
          }, 500);
        },
        chargesList: Object.values(chargesMap.productChargesMap.get() || {}) || [],
      },
    });
  };

  const deleteConfirm = (object, index) => {
    if (object.typeId === 0) {
      delete newChargesList[index];
      setNewChargesList(newChargesList);
      return;
    }
    openGlobalModal({
      modalSize: "sm",
      title: "Are you sure ?",
      bodyId: "condition-delete-confirm",
      close: false,
      modalParams: {
        data: { ...object, desc: object.desc || object.name },
        callBackOnAction: () => {
          handleDeleteCharge(object, index);
        },
      },
    });
  };

  if (isLoading) {
    return <Stack height={200} alignItems={"center"} justifyContent={"center"}><CircularProgressComponent size={30} /></Stack>;
  }

  const BuildActiveCharges = ({ charges }) => {
    const [total, setTotal] = React.useState(0.0);

    const calculateTotal = (chargeList: any[] = []) =>
      chargeList.reduce((sum, charge) => sum + parseFloat(charge.amount || "0.0"), 0.0);

    useEffect(() => {
      setTotal(calculateTotal(charges));
    }, [charges]);

    const calculateCharges = useCallback((chargeList = []) => {
      setTotal(calculateTotal(chargeList));
    }, []);

    const displayChargeDate = (data) => {
      if (data?.updatedAt) {
        return (new Date(data?.updatedAt)).toLocaleString();
      }
      return "N/A";
    }

    return <Stack>
      <Divider style={{ marginBottom: 10 }}></Divider>

      <Stack direction={"row"} justifyContent={"space-between"} py={2} px={1}>
        <Typography variant="h6">Total: {formatCurrency(total, 2)}</Typography>
        <ButtonComponent title={"Add Charge"} variant="contained" onClick={() => onClickedAction()} disabled={!Access.privilege()} />
      </Stack>

      <Divider style={{ marginBottom: 20 }}></Divider>

      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              {["Charge Type", "Updated By", "Updated At", "Amount"].map((i, index) => (
                <TableCell align={index === 3 ? "center" : "left"} key={index}> <Typography variant="body1">
                  {i}
                </Typography></TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {newChargesList.map((data, index) => {
              return <TableRow key={index}>
                <TableCell>{data.desc || data.name}</TableCell>
                <TableCell>{!data.updatedBy ? "N/A" : userName(data.updatedBy)}</TableCell>
                <TableCell>{displayChargeDate(data)}</TableCell>
                {data.edit && <TableCell align="right" width={250}>
                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <NumericFormat
                      customInput={TextBoxComponent}
                      variant="outlined"
                      label={"Charge Amount"}
                      thousandSeparator={true}
                      className={"input-right"}
                      value={data.amount}
                      required={true}
                      decimalScale={2}
                      allowNegative={false}
                      onKeyDown={(evt) => {
                        const { key } = evt;
                        if (key == "Escape") {
                          data.edit = false;
                          data.amount = data.draftAmount;
                          delete data.draftAmount;
                          calculateCharges(newChargesList);
                          setNewChargesList([...newChargesList]);
                        }
                        if (key == "Enter") {
                          data.edit = false;
                          delete data.draftAmount;
                          newChargesList[index] = data;
                          calculateCharges(newChargesList);
                          setNewChargesList([...newChargesList]);
                        }
                      }}
                      onValueChange={(e: any) => {
                        const { value } = e;
                        data.amount = parseFloat(value);
                        data.updatedBy = data.updatedBy || userId;
                        data.createdDate = data.createdDate || (new Date()).toLocaleString();
                        data.updatedAt = (new Date()).toLocaleString();
                        calculateCharges(newChargesList);
                      }}
                      style={{
                        textAlign: "right"
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">LKR</InputAdornment>,
                      }}
                      isAllowed={(values) => {
                        const { floatValue } = values;
                        if (floatValue && parseInt(floatValue.toString()) > data.maxAmount) {
                          return false;
                        } else {
                          return true;
                        }
                      }}

                      disabled={isLoggedInUserNotTheAssignee}
                    />
                    <CancelIcon style={{ cursor: "pointer", color: "gray" }} fontSize="small" onClick={() => {
                      data.edit = false;
                      data.amount = data.draftAmount;
                      delete data.draftAmount;
                      calculateCharges(newChargesList);
                      setNewChargesList([...newChargesList]);
                    }} />
                    <CheckIcon style={{ cursor: "pointer", color: "gray" }} fontSize="small" onClick={() => {
                      data.edit = false;
                      delete data.draftAmount;
                      newChargesList[index] = data;
                      calculateCharges(newChargesList);
                      setNewChargesList([...newChargesList]);
                    }} />
                  </Stack>
                </TableCell>}
                {!data.edit && <TableCell width={200} align="right" style={{ paddingRight: "10pt" }}><Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack direction={"row"} flex={1} justifyContent={"flex-end"} mr={6}>
                    <Typography>{formatCurrency(data?.amount, 2)}</Typography>
                  </Stack>
                  <Stack direction={"row"} color={"gray"} spacing={2} display={Access.privilege() ? 'flex' : 'none'}>
                    <EditIcon style={{ cursor: "pointer" }} fontSize="small" onClick={() => {
                      data.edit = true;
                      data.draftAmount = data.amount;
                      newChargesList[index] = data;
                      setNewChargesList([...newChargesList]);
                    }} />
                    <DeleteIcon style={{ cursor: "pointer" }} fontSize="small" onClick={() => deleteConfirm(data, index)} />
                  </Stack>
                </Stack></TableCell>}
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack flex={1} direction={"row"} justifyContent="space-between" px={1} mt={4}>
        {updating ? <CircularProgressComponent size={24} /> : <ButtonComponent title="Submit" variant="contained" onClick={handleChargesUpdate} color="info" disabled={!Access.privilege()} />}
        <Stack direction={"row"} mr={7} display={Access.privilege() ? 'flex' : 'none'}>
          {total > 0.0 && <Typography px={2} border={"solid lightGray 1px"} py={1} borderRadius={"5pt"}>{formatCurrency(total, 2)} {"  LKR"}</Typography>}
        </Stack>
      </Stack>
    </Stack>;
  }

  const BuildInActiveCharges = () => {
    const inactiveCharges = chargesMap?.inactiveCharges?.get() || [];

    return <Stack>
      <Divider></Divider>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              {["Charge Type", "Updated By", "Updated At", "Amount"].map((i, index) => (
                <TableCell align={index === 3 ? "center" : "left"} key={index}> <Typography variant="body1">
                  {i}
                </Typography></TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {inactiveCharges.map((data, index) => (
              <TableRow key={index}>
                <TableCell>{data.desc || data.name}</TableCell>
                <TableCell>{userName(data.updatedBy || userId)}</TableCell>
                <TableCell>{data.createdDate}</TableCell>
                <TableCell width={200} align="right" style={{ paddingRight: "10pt" }}><Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack direction={"row"} flex={1} justifyContent={"flex-end"} mr={6}>
                    <Typography>{formatCurrency(data?.amount, 2)}</Typography>
                  </Stack>
                  <Stack direction={"row"} color={"gray"} spacing={2}>
                  </Stack>
                </Stack></TableCell>
              </TableRow>
            ))}
            {inactiveCharges.length === 0 &&
              <TableRow>
                <TableCell colSpan={4} align="center"><Typography>No inactive charges found.</Typography></TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>;
  }

  if (renderId === "active") {
    return <BuildActiveCharges charges={newChargesList}></BuildActiveCharges>;
  } else {
    return <BuildInActiveCharges></BuildInActiveCharges>;
  }
};

export default ServiceCharges;
