import { Downgraded, useState } from '@hookstate/core';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { Box, Grid, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { creditData, userDetails, workflowMasterData } from '../../../../../../configs/mainStore';
import {
  dataVerificationCheckListItemStatusChange,
  getDataVerificationChecklistList,
  getProductDataVerificationChecklist,
} from '../../../../../../services/creditFileApiCall';
import { getIsDisbursed } from '../../../../../../utility/helpers/getIsDisbursed';
import { ButtonComponent } from '../../../../../InputComponents/ButtonComponent/ButtonComponent';
import { CheckBoxComponent } from '../../../../../InputComponents/CheckBoxComponent/CheckBoxComponent';
import { ErrorMessageComponent } from '../../../../ErrorMessageComponent';
import { addToaster } from '../../../../GlobalToast';
import { CircularProgressComponent } from '../../../../ProgressComponent/ProgressComponent';
import { IconRowComponent } from '../../IconRowComponent';
import InnerSectionHeaderComponent from '../../InnerSectionHeaderComponent';

export const DataVerificationCheckList = ({
  data,
  applicationId,
  innerComponentData,
  productTypeId,
  productSectorId,
  productSchemeId,
  tabsToEdit
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [doneChecklistList, setDoneCheckList] = React.useState<any>();
  const [preChecklistList, setPreCheckList] = React.useState<any>([]);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [isChanged, setIsChanged] = React.useState(false);

  const creditFileData: any = useState(creditData);
  const disbursed = creditFileData.disbursed.get();

  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const data = await getProductDataVerificationChecklist(productTypeId, productSectorId, productSchemeId);
      const preList = await getDataVerificationChecklistList(applicationId);

      if (data) {
        setDoneCheckList(data);
      } else {
        setErrorMessage('Something went Wrong!');
      }

      if (preList) {
        setPreCheckList(preList);
      }

      setIsLoading(false);
    })();
  }, [applicationId, isChanged]);

  const statusUpdate = async (itemId, isChecked, setIsChecked, itemObj, setFullName, setDate, wf_system_users) => {
    setIsChecked(!isChecked);
    let completedBy = userId || '';
    const result = await dataVerificationCheckListItemStatusChange(
      applicationId,
      itemId,
      isChecked,
      itemObj,
      completedBy
    );

    if (result?.message) {
      const user = wf_system_users?.get()?.find((usr) => usr?.id == completedBy);
      const dateCompleted = new Date();

      setFullName(user?.fullName);
      setDate(`${dateCompleted?.toLocaleString()}`);

      addToaster({
        status: 'success',
        title: 'Updated',
        message: 'Check List Item Updated',
      });
    } else if (result?.error) {
      addToaster({
        status: 'warning',
        title: 'Not Updated',
        message: 'Update Error',
      });
    } else {
      addToaster({
        status: 'error',
        title: 'Error',
        message: 'Something went Wrong!',
      });
    }
  };

  const checkPrivilegeStatus = () => {
    let isDisabled = true
    if (tabsToEdit.includes(innerComponentData?.id)) {
      isDisabled = true
    } else {
      isDisabled = getIsDisbursed(null, disbursed) || isLoggedInUserNotTheAssignee
    }
    return isDisabled
  }

  return (
    <div className="full-width full-height">
      <InnerSectionHeaderComponent
        innerComponentData={innerComponentData}
        applicationId={applicationId}
        currentApplicationWfData={{}}
        callBackOnAction={() => {
          setIsChanged(!isChanged);
        }}
        disabled={checkPrivilegeStatus()}
      />
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          {isLoading ? (
            <CircularProgressComponent
              size={30}
              sx={{
                marginTop: '20px',
                marginBottom: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
                zIndex: 100,
              }}
            />
          ) : errorMessage ? (
            <ErrorMessageComponent headMessage={'Error!'} errorMessage={errorMessage} />
          ) : doneChecklistList?.length > 0 ? (
            <>
              {doneChecklistList?.map((item, i) => {
                return (
                  <TaskChecklistRow
                    itemObj={item}
                    statusUpdate={statusUpdate}
                    key={i}
                    itemDisabled={checkPrivilegeStatus()}
                    preChecklistList={preChecklistList}
                  />
                );
              })}
            </>
          ) : (
            <ErrorMessageComponent headMessage={'Data Not Available'} errorMessage={''} />
          )}
        </Stack>
      </Grid>
    </div>
  );
};

const TaskChecklistRow = ({ itemObj, statusUpdate, preChecklistList, itemDisabled }) => {
  const [isChecked, setIsChecked] = React.useState<any>(false);
  const [fullName, setFullName] = React.useState<any>('');
  const [date, setDate] = React.useState<any>('');

  const { wf_system_users }: any = useState(workflowMasterData);

  const isExpand = useState(false);

  const onExpand = () => {
    isExpand.set(!isExpand.get());
  };

  useEffect(() => {
    let returnedStatus = compareWithPreArr(preChecklistList);
    if (returnedStatus) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }

    let dataArr = compareDataArr(preChecklistList);

    const user = wf_system_users?.get()?.find((usr) => usr?.id == dataArr?.COMPLETED_BY);
    const dateCompleted = dataArr?.COMPLETED_DATE ? new Date(dataArr?.COMPLETED_DATE) : '';

    setFullName(user?.fullName);
    setDate(`${dateCompleted?.toLocaleString()}`);
  }, []);

  const compareWithPreArr = (arr) => {
    if (arr?.length > 0) {
      let filteredArr = arr?.filter((el) => {
        return el?.MAPPING_ID == itemObj?.ID;
      });
      return filteredArr[0]?.STATUS;
    }
  };

  const compareDataArr = (arr) => {
    if (arr?.length > 0) {
      let filteredArr = arr?.filter((obj?: any, i?: any) => {
        if (obj?.MAPPING_ID === itemObj?.ID) {
          return {
            fullName: obj?.COMPLETED_BY,
            date: obj?.COMPLETED_DATE,
          };
        }
      });
      return filteredArr[0];
    }
  };

  return (
    <Box>
      <Grid
        className="row_list_item"
        style={{
          boxShadow: `0px 0px 10px ${isChecked ? '#39a21a' : '#ff1b1b'}3d`,
        }}
        container
        direction="column"
        p={2}
      >
        <Grid container direction="row">
          <Grid container style={{ textAlign: 'left' }} alignItems={'center'}>
            <CheckBoxComponent
              checked={isChecked}
              disabled={itemDisabled}
              onChange={() => {
                statusUpdate(itemObj?.id, isChecked, setIsChecked, itemObj, setFullName, setDate, wf_system_users);
              }}
            />
            <Grid flex={1}>
              <IconRowComponent subTxt={''} mainTxt={itemObj?.DESCRIPTION} color={isChecked ? '#39a21a' : '#ff1b1b'} />
            </Grid>

            <Stack direction="row" spacing={2} alignItems={'center'}>
              <IconRowComponent
                Icon={isChecked ? DoneIcon : CloseIcon}
                subTxt={''}
                mainTxt={isChecked ? 'Done' : 'Pending'}
                color={isChecked ? '#39a21a' : '#ff1b1b'}
              />
              {fullName && date && (
                <Stack direction="row" spacing={2} alignItems={'center'}>
                  <Grid item xs={1} className="align-center">
                    <ButtonComponent
                      iconbtn
                      onClick={onExpand}
                    >
                      {
                        isExpand.get() ? (
                          <KeyboardDoubleArrowUpIcon className="basic-font-color" />
                        ) : (
                          <KeyboardDoubleArrowDownIcon className="basic-font-color" />
                        )
                      }
                    </ButtonComponent>
                  </Grid>
                </Stack>
              )}
            </Stack>
          </Grid>
          {isExpand.get() && (
            <Grid container className="full-width row_list_item" columns={24} mt={1}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <div className={`basic-font basic-font-color opacity-7 font-size-14`}>Actioned By</div>
                  <div className={`basic-font basic-font-color font-size-14`}>{fullName}</div>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <div className={`basic-font basic-font-color opacity-7 font-size-14`}>Actioned Date</div>
                  <div className={`basic-font basic-font-color font-size-14`}>{date} </div>
                </Stack>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
