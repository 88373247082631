import { Box, InputAdornment } from "@mui/material";
import { standardDateFormatter } from "los-util";
import { NumericFormat } from "react-number-format";
import { VARIANT } from "../../../../../../../../configs/constants/styleValues";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { FormLabelComponent } from "../../../../../../../InputHelperComponents/FormLabelComponent/FormLabelComponent";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";

const PreInspectionDetails = ({ props }) => {
  const { pledgeNameOfTheBankOfficer, pledgeDateOfInspection, pledgePrePricePerUnit, pledgePreQuantityOfUnitsTested, pledgePreTotalValueChecked, pledgePreStockQualityDetails } = props.fields || {};

  return (
    <>
      <InputFieldsStack>
        <InputGrid visibility={1} variant={VARIANT?.longGrid}>
          <Box mb={1} mt={1}>
            <FormLabelComponent>Pre Inspection Details</FormLabelComponent>
          </Box>
        </InputGrid>
      </InputFieldsStack>

      <InputFieldsStack>
        <InputGrid visibility={pledgeNameOfTheBankOfficer.visible}>
          <TextBoxComponent value={props?.doc?.pledgeNameOfTheBankOfficer.get()} onChange={(e) => props?.doc?.pledgeNameOfTheBankOfficer.set(e.target.value)} label={"Name of the Bank officer"} required={pledgeNameOfTheBankOfficer.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.alphaNumericSpaces} />
        </InputGrid>

        <InputGrid visibility={pledgeDateOfInspection.visible}>
          <ValidateDatePicker
            value={formattedDate(props?.doc?.pledgeDateOfInspection.get())}
            onChange={(date) => {
              const preparedDate = standardDateFormatter(date);
              if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                props?.doc?.pledgeDateOfInspection.set(preparedDate);
              }
            }}
            label={"Date of the Inspection (DD/MM/YYYY)"}
            enableFuture={false}
            disablePast={false}
            required={pledgeDateOfInspection.mandatory === 1 ? true : false}
          />
        </InputGrid>
        <InputGrid visibility={pledgePrePricePerUnit.visible}>
          <NumericFormat
            customInput={TextBoxComponent}
            variant="outlined"
            label={"Price Per Unit"}
            thousandSeparator={true}
            value={formatCurrency(props?.doc?.pledgePrePricePerUnit.get())}
            required={pledgePrePricePerUnit?.mandatory === 1 ? true : false}
            decimalScale={2}
            onValueChange={(e: any) => {
              const { value } = e;
              props?.doc?.pledgePrePricePerUnit.set(value);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">LKR</InputAdornment>,
            }}
          />
        </InputGrid>
        <InputGrid visibility={pledgePreQuantityOfUnitsTested.visible}>
          <NumericFormat
            customInput={TextBoxComponent}
            variant="outlined"
            label={"Quantity of units tested"}
            thousandSeparator={true}
            value={formatCurrency(props?.doc?.pledgePreQuantityOfUnitsTested.get())}
            required={pledgePreQuantityOfUnitsTested?.mandatory === 1 ? true : false}
            decimalScale={2}
            onValueChange={(e: any) => {
              const { value } = e;
              props?.doc?.pledgePreQuantityOfUnitsTested.set(value);
            }}
          />
        </InputGrid>

        <InputGrid visibility={pledgePreTotalValueChecked.visible}>
          <NumericFormat
            customInput={TextBoxComponent}
            variant="outlined"
            label={"Total value checked"}
            thousandSeparator={true}
            value={formatCurrency(props?.doc?.pledgePreTotalValueChecked.get())}
            required={pledgePreTotalValueChecked?.mandatory === 1 ? true : false}
            decimalScale={2}
            onValueChange={(e: any) => {
              const { value } = e;
              props?.doc?.pledgePreTotalValueChecked.set(value);
            }}
          />
        </InputGrid>

        <InputGrid visibility={pledgePreStockQualityDetails.visible}>
          <TextBoxComponent value={props?.doc?.pledgePreStockQualityDetails.get()} onChange={(e) => props?.doc?.pledgePreStockQualityDetails.set(e.target.value)} label={"Stock Quality Details"} required={pledgePreStockQualityDetails?.mandatory === 1 ? true : false} onInput={OnDemandValueFormatter.alphaNumericSpaces} />
        </InputGrid>
      </InputFieldsStack>
    </>
  );
};

export default PreInspectionDetails;
