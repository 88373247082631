import { Downgraded, useState } from "@hookstate/core";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { formStructureSet } from "../../../../../configs/mainStore";
import { stockAsGoldDefault } from "../../../../../configs/stores/securityDataSubSection/securityDataSubSections.module";
import { saveAppFormDataJson } from "../../../../../services/apiCalls";
import { resetInputItems } from "../../../../../services/multipleInputDelete";
import { disableAccess } from "../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { StockAsGold } from "../../../ApplicationForm/Sections/SecurityDetails/SecurityData/Movable/StockAsGold/StockAsGold";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { collateralApplicationFormGetter, getMandatoryFields, handleMandatory } from "./collateral-apis/CollateralApplicationFormGetter";
import { collateralSectionDeletion } from "./collateral-utils/Utility";
import HeaderCollateral from "./helpers/CollateralHeaderComponent";
import { collateralValidationErrorArray } from "./validations/validations";

export const CreditStockAsGold = ({ data, applicationId, mainTabData = { isAppraisal: false }, isMainContactDetails = true, isRawVersion, apiCallBack, innerComponentData, tabsToEdit }) => {
  const [fullObj, setFullObj]: any = React.useState({});
  const [loading, setLoading]: any = React.useState(false);
  const [fields, setFields]: any = React.useState({});
  const [fetching, setFetching] = React.useState<boolean>(false);

  const requestId = data?.requestId;
  const existingData = useState<Array<any>>([]);

  let itemIndex = 0;

  const exisitingObject = data?.formData?.securityData?.movable?.stockAsGold;

  const appFormSet = useState(formStructureSet);
  const checkPrivilegeStatus = disableAccess({ isTabInEditString: tabsToEdit.includes(innerComponentData?.id) });

  const handleGetApplicationData = async () => {
    await getApplicationForm();
  };

  useEffect(() => {
    setFetching(true);
    handleGetApplicationData()
      .then(() => {
        setExistingData();
      })
      .then(() => {
        setFullObj(JSON.parse(JSON.stringify(data?.formData?.securityData?.movable ?? {})));
      });
    setFetching(false);
  }, []);

  const getApplicationForm = async () => {
    const key = "stockAsGold";
    const response = await collateralApplicationFormGetter(key, appFormSet);
    if (response) {
      setFields(response);
    } else setFields({});
  };

  const setExistingData = () => {
    if (exisitingObject) {
      existingData.set(JSON.parse(JSON.stringify(exisitingObject)));
    }
  };

  const handleFieldsAdding = () => {
    let stockAsGold = {
      itemIndex: 0,
      entryId: 0,
      ...JSON.parse(JSON.stringify(stockAsGoldDefault)),
    };

    try {
      if (existingData.get()?.length > 0) {
        stockAsGold["itemIndex"] = existingData.get()?.length;
        stockAsGold["entryId"] = existingData.get()?.length;
      }
      const tempArray = existingData.get() ? [...existingData.get(), stockAsGold] : [stockAsGold];
      existingData.set(JSON.parse(JSON.stringify(tempArray)));
    } catch (error) { }
  };

  const STOCK_AS_GOLD_MANDATORY = [...getMandatoryFields(fields)];

  const handleSave = async () => {
    setLoading(true);
    const mandatoryRes = handleMandatory(existingData.attach(Downgraded).get(), STOCK_AS_GOLD_MANDATORY);
    const finalArr: any = [];
    delete fullObj.stockAsGold;

    for (let index = 0; index < existingData.get().length; index++) {
      const element = existingData[index].get();

      if (element?.itemIndex) {
        delete element.indexId;
      }

      if (element?.entryId) {
        delete element.entryId;
      }

      let obj = {
        type: "movable_vehicle",
        itemIndex: index,
        entryId: index,
        ...element,
      };
      finalArr[finalArr.length] = obj;
    }

    let finalObj = {
      movable: {
        stockAsGold: finalArr,
        ...fullObj,
      },
    };

    let errorArray = collateralValidationErrorArray("movable", "stockAsGold", finalObj);

    const lastValidation: Array<any> = [...errorArray, ...mandatoryRes];

    if (lastValidation?.length > 0) {
      const errorMessage: string = lastValidation.join("\n");
      addToaster({
        status: "error",
        title: "Mandatory Validation Error",
        message: `${errorMessage}`,
      });
      setLoading(false);
    } else {
      let data = JSON.parse(JSON.stringify(finalObj));

      let res = await saveAppFormDataJson(requestId, "securityData", data);

      if (res?.status === 1) {
        apiCallBack();
        setLoading(false);
        addToaster({
          status: "success",
          title: "Updated",
          message: "Security Details Updated",
        });
      } else if (res?.error) {
        setLoading(false);
        addToaster({
          status: "warning",
          title: "Not Updated",
          message: "Update Error",
        });
      } else {
        setLoading(false);
        addToaster({
          status: "error",
          title: "Error",
          message: "Something went Wrong!",
        });
      }
    }
  };

  return (
    <>
      {fetching ? (
        <Grid
          container
          alignItems="center"
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
            position: "relative",
          }}
        >
          <CircularProgressComponent size={30} sx={{ left: "50%", position: "absolute", zIndex: 100 }} />
        </Grid>
      ) : (
        <>
          <div className="full-width full-height" style={{ position: "relative" }}>
            {checkPrivilegeStatus ? <Stack style={{ width: "100%", height: "100vh", position: "absolute", left: 0, top: 0, backgroundColor: "255, 255, 255, 0.1", zIndex: 1000 }}></Stack> : <></>}
            {isMainContactDetails && <HeaderCollateral loading={loading} handleFieldsAdding={handleFieldsAdding} requestDtlData={existingData} collateralApplicationForm={fields} handleSave={handleSave} headerTitle={"Stock as Gold"} />}

            {/* Security Form */}
            <Grid container className="inner-component-height">
              <Stack spacing={1} m={1} className="full-width">
                {existingData.get()?.length > 0 ? (
                  existingData?.map((doc, index) => {
                    if (!doc.get()) return <></>;
                    const elementKeys = Object.keys(doc);
                    if (!elementKeys.includes("removedItem")) {
                      itemIndex = itemIndex + 1;
                      return (
                        <Box display="flex" alignItems="center" mb={1} key={`StockAsGold_${itemIndex * itemIndex}`}>
                          <Box style={{ width: "100%" }}>
                            <StockAsGold doc={doc} fields={fields} index={itemIndex} isNotNeedComponentTitle={true} />
                          </Box>
                          <Box>
                            <Box pb={3}>
                              <Tooltip title={"Reset"}>
                                <Box>
                                  <ButtonComponent
                                    iconbtn={true}
                                    onClick={() => {
                                      resetInputItems(doc, "movable", "stockAsGold");
                                    }}
                                    style={{ border: "1px solid gray" }}
                                    size="small"
                                  >
                                    <RestartAltIcon />
                                  </ButtonComponent>
                                </Box>
                              </Tooltip>
                            </Box>
                            <Box pt={3}>
                              <Tooltip title={"Delete"}>
                                <Box>
                                  <ButtonComponent iconbtn onClick={() => {
                                    collateralSectionDeletion({ applicationId, collateralDataState: existingData, itemIndex: index, collateralKeyString: "movable:stockAsGold:Movable Stock As Gold" })
                                  }}>
                                    <DeleteIcon />
                                  </ButtonComponent>
                                </Box>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Box>
                      );
                    }
                  })
                ) : (
                  <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
                )}
              </Stack>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};
