import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Grid } from "@mui/material";
import { defaultOptions } from "../../../../../utility/util";
import { IconRowConditionComponent } from "../IconRowConditionComponent";

const PredisbursementItemRow = (params) => {
  const { data, applicationId, setNewDataArray, creditFileData, actionDelete, disableAccordingTo, trialCalcResult, trialCalcParams, recommendedInterestRate, regularPaymentFactor } = params;

  const color = defaultOptions?.color;


  return (
    <Grid
      container
      alignItems="center"
      className="row_list_item"
      p={2}
      key={data.sequence}
      style={{
        backgroundColor: `${color}3d`,
      }}
    >
      <Grid container className="full-width" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <IconRowConditionComponent Icon={RadioButtonCheckedIcon} subTxt={data?.TITLE} mainTxt={data?.DESCRIPTION} color={color} data={data} applicationId={applicationId} setNewDataArray={setNewDataArray} creditFileData={creditFileData} actionDelete={actionDelete} disableAccordingTo={disableAccordingTo} trialCalcResult={trialCalcResult} trialCalcParams={trialCalcParams} conditionType={"approvingCondition"} recommendedInterestRate={recommendedInterestRate}
            regularPaymentFactor={regularPaymentFactor} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PredisbursementItemRow;
