import { useState } from "@hookstate/core";
import { Button, Grid } from "@mui/material";
import { priorComments } from "../../../../configs/mainStore";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../GlobalModal/GlobalModal";

export default function PriorActionModal({ handleReject, handleApproved, documentId, applicationId, applicationRefId, confirmUpload, fileList, setFileList }) {
  const comments = useState(priorComments);

  const props = {
    documentId,
    applicationRefId,
    applicationId,
    confirmUpload,
    fileList,
    setFileList
  }

  return (
    <Grid container p={1} xs={12} md={12} rowGap={3}>
      <Grid item xs={12} md={12}>
        <TextBoxComponent label={"Comments"} value={comments.get()} fullWidth={true} onChange={(e) => comments.set(e.target.value)} multiline rows={5} />
      </Grid>
      <Grid container item>
        <Grid container columns={12}>
          <Grid item xs={2}>
            <Button variant="outlined" onClick={() => closeGlobalModal()}>
              Close
            </Button>
          </Grid>
          <Grid item xs={10}>
            <Grid container justifyContent={"flex-end"}>
              <Grid item>
                <Button variant="contained" color="error" onClick={handleReject}>
                  Reject
                </Button>
              </Grid>
              <Grid item ml={2}>
                <Button variant="contained" color="success" onClick={handleApproved}>
                  Approve
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
