import { useState } from "@hookstate/core";
import { Box, Grid, Stack } from "@mui/material";
import { appSetting } from "../../../configs/mainStore";
import { getUserDetailsById } from "../../../services/userService";
import { dateFormatWithDate, dateFormatWithTimeChange } from "../../../utility/fomatters";
import { ButtonComponent } from "../../InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../GlobalModal/GlobalModal";
import { DocumentPreviewHistoryRow } from "../ProductDocumentUploadComponent/DocumentPreviewRow";
import { ScreenTitle } from "../SectionTitle/SectionTitle";
import { MainSectionsWrapper } from "./MainSectionsWrapper";

const ViewDisbursementDocumentUploadHistory = (params) => {
    const appSettings: any = useState(appSetting);

    const closeCondition = async () => {
        closeGlobalModal();
    };

    return (
        <Box>
            <Stack>
                <ScreenTitle title={params.docName} description={"Document History"} />
            </Stack>
            {params?.historyData?.map((item, index) => {
                return (
                    <MainSectionsWrapper
                        title={item?.archivedDate && dateFormatWithTimeChange(item?.archivedDate)}
                        subTitle="Click to get file details"
                        keyCode={index}
                        noBtn={true}
                        icon={false}
                        status={item?.status === 1 ? "success" : item?.status === 2 ? "error" : "info"}
                        file={item}
                        isDocument={true}
                    >
                        <Grid container className="align-center row_list_item" mt={2} columns={24}>
                            <DocumentPreviewHistoryRow
                                subTxtOne="Uploaded By"
                                mainTxtOne={
                                    item?.uploadedBy &&
                                    getUserDetailsById(item?.uploadedBy)[appSettings.get().language]?.name
                                }
                                subTxtTwo="Uploaded Date"
                                mainTxtTwo={item?.uploadedDate ? dateFormatWithDate(item?.uploadedDate) : "--"}
                            />
                            <DocumentPreviewHistoryRow
                                subTxtOne="Verified By"
                                mainTxtOne={
                                    item?.verifiedBy
                                        ? getUserDetailsById(item?.verifiedBy)[appSettings.get().language]?.name
                                        : "--"
                                }
                                subTxtTwo="Verified Date"
                                mainTxtTwo={item?.verifiedDate ? dateFormatWithDate(item?.verifiedDate) : "--"}
                            />
                            <DocumentPreviewHistoryRow
                                subTxtOne="Archive By"
                                mainTxtOne={
                                    item?.archivedBy &&
                                    getUserDetailsById(item?.archivedBy)[appSettings.get().language]?.name
                                }
                                subTxtTwo="Archive Date"
                                mainTxtTwo={item?.archivedDate ? dateFormatWithDate(item?.archivedDate) : "--"}
                            />
                        </Grid>
                    </MainSectionsWrapper>
                );
            })}
            <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
                <ButtonComponent
                    title="Close"
                    variant="contained"
                    onClick={closeCondition}
                    loadingbtn={true}
                    color="info"
                />
            </Stack>
        </Box>
    );
};

export default ViewDisbursementDocumentUploadHistory;