import { Grid, Stack, Typography } from "@mui/material";
import DetailItem from "../DetailItem";

const AutomaticSettlementOfCreditCardBills = ({ data }) => {
    let autoSettleCCBillsState: any = data?.formData?.autoSettleCCBillsState;
    return (
        <Stack>
            <Stack p={1} px={2} style={{ backgroundColor: "#e6f0fa" }}>
                <Typography variant="subtitle1" color={"gray"}> Automatic Settlement of Credit Card Bills </Typography>
            </Stack>
            <Grid container className="data-box" spacing={1} padding={1}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <DetailItem title="Account Number" value={autoSettleCCBillsState?.accountNumber ? autoSettleCCBillsState?.accountNumber : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={3}>
                            <DetailItem title="Settlement Amount" value={autoSettleCCBillsState?.settlementAmount ? `${autoSettleCCBillsState?.settlementAmount}%` : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Stack>
    )
}

export default AutomaticSettlementOfCreditCardBills