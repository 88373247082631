import { Downgraded } from "@hookstate/core";
import AppConfig from "./AppConfig";

interface ExtraInfo {
  // Define the properties of the extraInfo object here if there are any
  // For now, we assume it can be an empty object
  [key: string]: any;
}
interface ConfigJsonOption {
  id: number;
  name: string;
  parentKey: string | null;
  extraInfo: ExtraInfo;
}
interface JsonConfig {
  options: Record<string, ConfigJsonOption>;
  parentKeys: string[];
}

type TypeToAssess = 'TYPE' | 'SECTOR' | 'SCHEME' | 'TYPE-SECTOR' | 'SECTOR-SCHEME' | 'TYPE-SCHEME' | 'TYPE-SECTOR-SCHEME';

export default class MasterDataProvider {
  private static instance: any = null;
  static dataObject: any = {};

  private constructor() { }

  public static provideMasterData() {
    if (!MasterDataProvider.instance)
      MasterDataProvider.instance = new MasterDataProvider();
    return MasterDataProvider.instance;
  }

  public static setMasterData(data) {
    this.dataObject = (data && data) || {};
  }

  getMasterData(type: any) {
    if (type === null || type === "") return [];
    return (
      (MasterDataProvider?.dataObject[type]?.length > 0 &&
        MasterDataProvider?.dataObject[type]) ||
      []
    );
  }

  getDependencyMasterData(
    type: any,
    dependency: any,
    autoCompleteDropdown = false
  ) {
    if (
      type === null ||
      type === "" ||
      dependency === null ||
      dependency === ""
    )
      return [];

    const data =
      (MasterDataProvider?.dataObject?.[type]?.length > 0 &&
        MasterDataProvider?.dataObject?.[type]) ||
      [];

    if (data?.length === 0) return data;

    let extractData = [];

    if (autoCompleteDropdown) {
      extractData =
        JSON.parse(JSON.stringify(data.value))?.filter(
          (item) => item?.["pcode"] === dependency
        ) || [];
    } else {
      extractData =
        data?.filter((item) => item?.["pcode"]?.get() === dependency) || [];
    }

    return extractData;
  }

  getMasterDataDescription(type: any, value: any) {
    return Object.keys(MasterDataProvider?.dataObject[type])?.length > 0 &&
      value !== null &&
      value !== "" &&
      Object.keys(MasterDataProvider?.dataObject[type]).length > 0
      ? MasterDataProvider?.dataObject[type]
        ?.filter((item) => {
          return item.id?.get() == value;
        })?.[0]
        ?.["name"]?.get()
      : "";
  }

  filterMasterDataByProduct(type: string, productCatalog: string, typeToAssess?: TypeToAssess) {

    // Retrieve master data values and downgrade the state if necessary
    let masterDataValues: { id: string | number; name: string }[] = this.getMasterData(type)?.attach(Downgraded).get() || [];
    // If there are no master data values, return early
    if (!masterDataValues.length) return masterDataValues;

    // Default to 'TYPE' if typeToAssess is not provided
    typeToAssess = typeToAssess ? typeToAssess : 'TYPE';

    try {
      // Retrieve the exclude map based on the product type
      const excludeMap = AppConfig.config.masterDataConfigExcludeMap || undefined;

      // If exclude map is not available, return the original master data values
      if (!excludeMap) return masterDataValues;

      // Convert the typeToAssess to lowercase for consistent key access
      let typeToAssessKey: string = typeToAssess.toLowerCase();
      let excludeMapdata: Record<string, Record<string, string[]>> | undefined = excludeMap?.[typeToAssessKey] || undefined;

      // If there's no data for the typeToAssessKey, return the original master data values
      if (!excludeMapdata) return masterDataValues;

      // Parse the productCatalog into a structured object (type, sector, scheme)
      const catalog: { type: number | string; sector: number | string; scheme: number | string } =
        (([type, sector, scheme]) => ({ type, sector, scheme }))(productCatalog.split('-'));

      // Generate a compound key by mapping and joining valid keys from the catalog
      typeToAssessKey = typeToAssessKey.split('-')
        .reduce((typeToAssessKeys: string[], currKey: string) =>
          catalog?.[currKey] ? [...typeToAssessKeys, catalog[currKey]] : typeToAssessKeys,
          []).join('-');

      // Extract the relevant configuration data from the exclude map data
      let configData: string[] = excludeMapdata?.[typeToAssessKey]?.[type].map(curr => curr.toString()) || [];

      // If there's no config data or it's empty, return the original master data values
      if (!configData.length) return masterDataValues;

      // Filter out the master data values based on the config data
      return Array.isArray(configData) ?
        masterDataValues.filter(({ id }) => !configData.includes(id.toString())) : masterDataValues;

    } catch (error) {
      // In case of any error, return the original master data values
      return masterDataValues;
    }
  }

  // getConfigMasterData(type: string): ConfigJsonOption[] {
  //   return dropDownsConfigs[type] ? Object.values(dropDownsConfigs[type].options) : [];
  // }

  // getConfigMasterDataDescription(type: string, value: string | number): string {
  //   return dropDownsConfigs[type].options?.[value].name || "";
  // }

  // getConfigDependencyMasterData(type: string, parentType: string, parentValue: string): ConfigJsonOption[] {

  //   let dropDownData: JsonConfig = dropDownsConfigs[type] ? dropDownsConfigs[type] : {};

  //   if (!Object.keys(dropDownData).length) return [];

  //   let dropDown: ConfigJsonOption[] = Object.values(dropDownData.options);

  //   return dropDownData.parentKeys.includes(parentType) ? dropDown.filter(({ parentKey }) => parentKey == parentValue) || [] : dropDown;
  // }
}

