import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import { Divider, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { appFormRequestId } from "../../../../configs/mainStore";
import { saveAppFormDataJson } from "../../../../services/apiCalls";
import { ButtonComponent } from "../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../GlobalModal/GlobalModal";

const ModalApplicationFormDetails = (props) => {
    let [raw, setRaw] = React.useState("");
    const [updating, setUpdating] = React.useState(false);

    const value = props.data ? props.data.get() : null;

    let requestId = appFormRequestId.get();

    useEffect(() => {
        if (value) {
            setRaw(JSON.stringify(value, null, 2));
        }
    }, [value]);

    if (!value) {
        return <></>;
    }

    const save = async () => {
        try {
            const data = JSON.parse(raw);
            setUpdating(true);
            const response = await saveAppFormDataJson(requestId, props.type, data);
            if (response.status === 1) {
                closeGlobalModal();
            }
        } catch (error) {

        } finally {
            setUpdating(false);
        }
    };

    return (
        <Stack padding={1} direction={'column'}>
            <Typography variant="subtitle2"> Advanced Edit </Typography>
            <Divider></Divider>
            <Stack alignItems={'center'} direction={'column'} spacing={3} mt={4}>
                <TextBoxComponent fullWidth={true} multiline={true} rows={10} value={raw} onChange={(e) => setRaw(e.target.value)}></TextBoxComponent>
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="flex-end" pt={4}>
                <ButtonComponent
                    startIcon={<UpdateIcon />}
                    title="Update"
                    variant="contained"
                    onClick={() => { save(); }}
                    loadingbtn={true}
                    loading={updating}
                    color="success"
                />
                <ButtonComponent
                    startIcon={<CloseIcon />}
                    title="Close"
                    variant="contained"
                    onClick={() => { closeGlobalModal(); }}
                    loadingbtn={false}
                    disabled={updating}
                    color="info"
                />
            </Stack>
        </Stack>
    )
}

export default ModalApplicationFormDetails;