import { Stack, Tooltip } from "@mui/material";
import React from "react";
import { CheckBoxComponent } from "../../../../InputComponents/CheckBoxComponent/CheckBoxComponent";

export const CommonPageBreaksAll = ({ isPageBreak, pageBreakHandler }) => {
  return (
    <div className="data-box" style={{ marginTop: "0 !important" }}>
      <CheckBoxComponent checked={isPageBreak} onChange={(e) => pageBreakHandler(e.target.checked)} required={false} />
      <span>Page Breaks</span>
    </div>
  );
};

export const SinglePageBreak = () => {
  const [enablePageBreak, setEnablePageBreak] = React.useState(false);

  return (
    <>
      <Tooltip title="Content beyond the line will start in a new page when printing start">
        <Stack flex={1} width={"100"} direction="row" className="break-point-marker">
          <Stack flex={1} style={{ width: "100%", minHeight: "2px", borderBottom: "1px dashed grey" }}></Stack>
          <Stack>
            <CheckBoxComponent checked={enablePageBreak} onChange={(e) => setEnablePageBreak(e.target.checked)} required={false} />
          </Stack>
        </Stack>
      </Tooltip>
      {enablePageBreak && <div style={{ pageBreakAfter: "always" }}></div>}
    </>
  );
};
