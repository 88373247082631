import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { useState } from "@hookstate/core";
import PreviewIcon from "@mui/icons-material/Preview";
import { workflowMasterData } from "../../../../../configs/mainStore";
import { dateFormatWithTimeChange } from "../../../../../utility/fomatters";
import { ChipsComponent } from "../../../../InputComponents/ChipsComponent/ChipsComponent";
import { openGlobalModal } from "../../../GlobalModal/GlobalModal";

const HistoryItemRow = ({ data, groupId, referData, currentUser }) => {
  const [historyNote, setHistoryNote] = React.useState<any>("");
  const [expand, setExpand] = React.useState<any>(false);
  const [showExpandButton, setShowExpandButton] = React.useState<any>(false);

  const { wf_jobs, wf_stages, wf_system_users, wf_api_jobs, wf_groups }: any =
    useState(workflowMasterData);

  const stage = wf_stages?.get()?.find((stg) => stg?.id == data?.stage);
  let note = data?.note;

  const apiJobs = wf_api_jobs?.get();

  const attachements = data ?? [];

  try {
    let _note = JSON.parse(data?.note);
    const _apiJob = apiJobs.find((job) => job?.id == _note.apiJobId || _note);
    note = _apiJob?.name || _note.apiJobId || _note;
  } catch (error) {
    note = data?.note;
  }

  const createdDate = data.timeStamp ? dateFormatWithTimeChange(data.timeStamp) : "";
  const createdBy = wf_system_users?.get()?.find((usr) => usr?.id == data?.actionBy);

  const _limitedNote = data.note.length <= 100 ? data.note : data.note.substr(0, 100) + "...";

  let assignees: any = [];

  const assignedGroups = data?.assignedgroups ? data?.assignedgroups.split(",") : "";
  const assignedUsers = data?.assignedusers;

  if (assignedUsers) {
    const getAssignedUserData = wf_system_users?.get()?.find((usr) => usr?.id == Number(assignedUsers));

    assignees.push(getAssignedUserData?.fullName);
  } else {
    if (assignedGroups.length > 0) {
      for (const item of assignedGroups) {
        const assigneeData = wf_groups?.get()?.find((group) => group?.id === Number(item));
        assignees.push(assigneeData?.name);
      }
    }
  }

  useEffect(() => {
    setHistoryNote(_limitedNote);

    if (data.note?.length > 100) {
      setShowExpandButton(!showExpandButton);
    }
  }, [_limitedNote, data.note]);

  const expandHistoryNote = (expand) => {
    setHistoryNote(expand ? _limitedNote : data.note);
    setExpand(!expand);
  };

  return (
    <Grid
      container
      alignItems="center"
      className="data-box"
      p={2}
      key={data.sequence}
      style={{ backgroundColor: "white" }}
    >
      <Grid container className="full-width" alignItems="center" spacing={2}>
        <Stack flex="1">
          <Stack direction={"row"} flex="1" alignItems={"center"}>
            <Stack p={2}>
              <Typography
                style={{
                  border: "solid 2px gray",
                  width: "14pt",
                  borderRadius: "16px",
                  color: "gray",
                  padding: "2px",
                  fontSize: "10pt",
                  textAlign: "center",
                }}
              >
                {data.sequence}
              </Typography>
            </Stack>
            <Stack flex={3} direction={"row"} padding={2} justifyContent={"space-between"} alignItems={"center"}>
              {[
                { key: "Actioned By", value: createdBy?.fullName },
                { key: "Actioned Date", value: createdDate },
                { key: "Stage", value: stage?.name },
                { key: "Assignee", value: assignees.join(", ") },
              ].map((item) => (
                <Stack>
                  <Typography variant="caption" color={"grey"}>
                    {item.key}
                  </Typography>
                  <Typography variant="subtitle1" color={"grey"}>
                    {item.value}
                  </Typography>
                </Stack>
              ))}
              {attachements?.attachements?.length > 0 && (
                <Stack>
                  <Typography variant="caption" color={"grey"}>
                    Attachement(s)
                  </Typography>
                  <Typography variant="subtitle1" color={"grey"}>
                    {attachements?.attachements?.map((item) => (
                      <PreviewIcon
                        onClick={() => {
                          openGlobalModal({
                            modalSize: "md",
                            title: `Attachement Details`,
                            bodyId: "refer-detach",
                            close: true,
                            modalParams: {
                              item: attachements,
                              groupId: groupId,
                              referData,
                              currentUser
                            },
                          });
                        }}
                        style={{
                          cursor: "pointer",
                          color: "#1976d2",
                        }}
                      />
                    ))}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Divider />
          <Stack padding={2}>
            <Typography variant="subtitle1" color={"grey"}>
              <Stack>
                <Box sx={{ whiteSpace: "pre-wrap" }}>{historyNote}</Box>
              </Stack>
              {showExpandButton && (
                <Stack direction={"row-reverse"}>
                  <ChipsComponent
                    size={"large"}
                    label={<Typography variant="caption">{expand ? "Collapse" : "Expand"}</Typography>}
                    onClick={() => expandHistoryNote(expand)}
                  ></ChipsComponent>
                </Stack>
              )}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default HistoryItemRow;
