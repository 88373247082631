import * as securityDefaultData from "../configs/stores/securityDataSubSection/securityDataSubSections.module";

export const deleteMultipleInputItem = (item) => {
  const pureItem = JSON.parse(JSON.stringify(item.value));
  pureItem["removedItem"] = true;

  if (pureItem["removedItem"]) {
    item.set(pureItem);
  } else {
    console.error("no removed item ");
  }
};

export const resetInputItems = (objectToBeReset, objectKey, subObjectKey) => {
  const securityDefaultDataState = {
    fdAgainstLoans: {
      ...JSON.parse(JSON.stringify(securityDefaultData.fdAgainstLoansDefault)),
    },
    loanProtection: {
      ...JSON.parse(JSON.stringify(securityDefaultData.loanProtectInsurance)),
    },
    shares: {
      ...JSON.parse(JSON.stringify(securityDefaultData.fdAgainstLoansDefault)),
    },
    treasuryBond: {
      ...JSON.parse(JSON.stringify(securityDefaultData.treasuryBondDefault)),
    },
    warehouseReceipt: {
      ...JSON.parse(JSON.stringify(securityDefaultData.warehouseReceiptDefault)),
    },
    lifeInsurance: {
      ...JSON.parse(JSON.stringify(securityDefaultData.lifeInsuranceDefault)),
    },
    machineryEquipment: {
      ...JSON.parse(JSON.stringify(securityDefaultData.machineryEquipmentDefault)),
    },
    pledge: { ...JSON.parse(JSON.stringify(securityDefaultData.pledgeDefault)) },
    vehicle: { ...JSON.parse(JSON.stringify(securityDefaultData.vehicleDefault)) },
    stockAsGold: {
      ...JSON.parse(JSON.stringify(securityDefaultData.stockAsGoldDefault)),
    },
    property: { ...JSON.parse(JSON.stringify(securityDefaultData.propertyDefault)) },
    immovableMachineryEquipement: {
      ...JSON.parse(JSON.stringify(securityDefaultData.immovableMachineryEquipementDefault)),
    },
  };
  let defaultObject;
  defaultObject = securityDefaultDataState[subObjectKey];

  objectToBeReset.set(JSON.parse(JSON.stringify(defaultObject)));
};

export const getSectionFields = (key, props) => {
  const filteredObj = props?.content?.find((el) => el.key === key);
  return JSON.parse(JSON.stringify(filteredObj?.fields));
};
