import CommentIcon from "@mui/icons-material/Comment";
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryIcon from "@mui/icons-material/History";
import { Box, Chip, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { deleteAbilityProposedLoanComment } from "../../../../../../../../services/creditFileApiCall";
import { userName } from "../../../../../../../../utility/helpers/creditFileUtility";
import { ButtonComponent } from "../../../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { ErrorMessageComponent } from "../../../../../../ErrorMessageComponent";
import { getExistingComments } from "../../Api/AppraisalApi";
import ModalWrapper from "../Modals/ModalWrapper/ModalWrapper";

const historyComponentTypes = {
  0: "addCommentTab",
  1: "showHistoryTab",
};

const Comments = ({ setOpenModal, openModal, handleSubmitData, userId, getResponseData, applicationId, isLoggedInUserNotTheAssignee }) => {
  const [openHistoryComponentType, setOpenHistoryComponentType] = React.useState<string>(historyComponentTypes[0]);
  const [addedCommentList, setAddedCommentList] = React.useState<Array<any>>([]);
  const [addAcomment, setAddComment] = React.useState("");
  const { t: translate } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      if (openModal) {
        const existingComments = await getExistingComments(applicationId);
        setAddedCommentList(existingComments);
      }
    };

    fetchData();

    return () => {
      setOpenHistoryComponentType(historyComponentTypes[0]);
    };
  }, [openModal]);

  const handleSubmit = () => {
    if (openHistoryComponentType === historyComponentTypes[1]) return;

    const LoggedUserName = userName(userId);
    const allComments = [
      ...addedCommentList,
      {
        comment: addAcomment,
        createdAt: new Date(),
        user: LoggedUserName,
      },
    ];
    setAddedCommentList(allComments);

    handleSubmitData({
      comment: addAcomment,
    });

    setAddComment("");
  };

  const handleDeleteFn = async (id) => {
    let { status } = await deleteAbilityProposedLoanComment({ id, applicationId });
    if (status === 1) {
      setAddedCommentList(addedCommentList.filter((detail) => detail.id !== id));

    }

  };

  return (
    <Stack direction={"row"} justifyContent={"flex-end"}>
      <Box p={2}>
        <ButtonComponent style={{ padding: "8px" }} startIcon={<CommentIcon />} title="Add Comments" variant="contained" onClick={() => setOpenModal(true)} loadingbtn={true} color="info" disabled={isLoggedInUserNotTheAssignee} />
      </Box>
      <ModalWrapper openModal={openModal} setOpenModal={setOpenModal} dialogTitle={"COMMENTS"} maxWidth={"xl"} handleSubmit={handleSubmit}>
        <Stack width={"100%"}>
          <Stack direction={"row-reverse"}>
            <ButtonComponent startIcon={openHistoryComponentType === historyComponentTypes[0] ? <HistoryIcon /> : <CommentIcon />} title={openHistoryComponentType === historyComponentTypes[0] ? "Show Comments History" : "Add More Comments"} variant="outlined" onClick={() => setOpenHistoryComponentType(openHistoryComponentType === historyComponentTypes[0] ? historyComponentTypes[1] : historyComponentTypes[0])} loadingbtn={true} color="primary" />
          </Stack>
          {openHistoryComponentType === historyComponentTypes[1] ? (
            <Stack p={1} py={3} color={"GrayText"}>
              {addedCommentList.length > 0 ? (
                <Stack gap={2} style={{ maxHeight: "250px", padding: "8px" }}>
                  {addedCommentList
                    .sort((first, second) => second.createdAt - first.createdAt)
                    .map((detail) => (
                      <Stack direction={"row"} style={{ border: "1px solid gray", padding: "8px" }} key={detail.createdAt}>
                        <Stack flex={11}>
                          <Stack>
                            <Typography variant="body1"  >{detail.comment}</Typography>
                          </Stack>
                          <Stack direction={"row"} justifyContent={"flex-start"} pt={3}>
                            <Typography px={1} variant="caption">
                              Created by: {userName(detail.user)}
                            </Typography>
                            <Typography px={1} variant="caption">
                              Created at:   {new Date(detail.createdAt).toLocaleString()}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"flex-start"} flex={1}>
                          <Box>
                            <Chip
                              style={{ border: "none" }}
                              // label="Delete"
                              onDelete={() => handleDeleteFn(detail.id)}
                              deleteIcon={<DeleteIcon />}
                              variant="outlined"
                            />
                          </Box>
                        </Stack>
                      </Stack>
                    ))}
                </Stack>
              ) : (
                <ErrorMessageComponent headMessage={translate("errorMsg.noData")} errorMessage={"There is no any comments here...!"} />
              )}
            </Stack>
          ) : (
            <Stack p={1} py={3} color={"GrayText"}>
              <TextField id="outlined-multiline-flexible" value={addAcomment} multiline placeholder={"Add your comments here..."} maxRows={5} rows={5} onChange={(e) => setAddComment(e.target.value)} />
            </Stack>
          )}
        </Stack>
      </ModalWrapper>
    </Stack>
  );
};

export default Comments;
