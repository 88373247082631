import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import FeaturedVideoOutlinedIcon from "@mui/icons-material/FeaturedVideoOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import SchemaOutlinedIcon from "@mui/icons-material/SchemaOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { useEffect } from "react";

export const useNodeId = () => {
  let id;

  useEffect(() => {
    id = 0;
  }, []);

  return `wf_node_${id++}`;
};

export const parseINT = (val) => {
  return !isNaN(val) ? parseInt(val) : val;
};

export const WorkflowStructuralChangeConfig = {
  stageNodeAdd: false,
  jobNodeAdd: false,
  conditionNodeAdd: false,
  stageNodeDelete: true,
  jobNodeDelete: false,
  conditionNodeDelete: false,
  stageNodeUpdate: true,
  jobNodeUpdate: false,
  conditionNodeUpdate: false,
  nodePermissionChange: false,

  edgeAdd: false,
  edgeDelete: false,
  edgeUpdateToNewAction: false,
  edgeUpdatePermissions: false,
};

export const nodeColors = {
  customStartNode: {
    stage: { color: "#e9600c", icon: RadioButtonCheckedOutlinedIcon },
  },
  customEndNode: { stage: { color: "#18d54c", icon: BeenhereOutlinedIcon } },
  customDefaultNode: {
    stage: {
      color: "#e21ecf",
      icon: FeaturedVideoOutlinedIcon,
    },
    job: {
      color: "#712ccc",
      icon: WorkHistoryOutlinedIcon,
    },
    condition: {
      color: "#a4354a",
      icon: SchemaOutlinedIcon,
    },
    workflow: {
      color: "#c4354a",
      icon: WorkspacePremiumIcon,
    },
  },
};

// export const edgeColors = {
//   1: { color: '#ff9090' },
//   2: { color: '#ffb55f' },
//   3: { color: '#6cd3ff' },
//   5: { color: '#1cd596' },
//   default: { color: '#e9e9e9' },
//   revert: { color: '#ff3333' },
// };

export const edgeColors = {
  reject: { color: "#ff9090" },
  withdraw: { color: "#ffb55f" },
  approve: { color: "#6cd3ff" },
  forward: { color: "#1cd596" },
  next: { color: "#acd596" },
  default: { color: "#e9e9e9" },
  revert: { color: "#ff3333" },
  forward_review: { color: "#dcd596" },
};

export const permissionsBoxColors = {
  hierarchies: { color: "#3c84bb" },
  groups: { color: "#3c84bb" },
  users: { color: "#3c84bb" },
};

export const isEmail = (key) => {
  return key === "email";
};

export const isSMS = (key) => {
  return key === "sms";
};

export const isUI = (key) => {
  return key === "ui";
};

export const isIR = (key) => {
  return key === "ir";
};

export const isAPI = (key) => {
  return key === "api";
};

export const isStage = (key) => {
  return key === "email";
};

export const isJob = (key) => {
  return key === "email";
};

export const isCondition = (key) => {
  return key === "sms";
};

export const fundReleaseStatusColors = {
  1: { color: "#1cd596" },
  2: { color: "#ff9090" },
  3: { color: "#ff9090" },
  4: { color: "#ffb55f" },
  5: { color: "#ffb55f" },
  default: { color: "#e9e9e9" },
};

export const documentStatusColors = {
  0: { color: "#d5c81c" },
  1: { color: "#00ff4c" },
  2: { color: "#ff9090" },
  default: { color: "#a2a2a2" },
};

export const cribStatusColors = {
  1: { color: "#1cd596" },
  2: { color: "#9dd857" },
  3: { color: "#ffb55f" },
  4: { color: "#ff9090" },
  default: { color: "#e9e9e9" },
};

export const fundReleaseStatusChipColors = {
  1: { color: "success" },
  2: { color: "error" },
  3: { color: "error" },
  4: { color: "warning" },
  5: { color: "warning" },
  default: { color: "default" },
};

export const cmsHistroyStylings = (bgColor) => {
  return { backgroundColor: bgColor, color: "#FFFFFF", marginRight: "5px" };
};
