import { useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Stack } from "@mui/material";
import React, { createRef, useEffect } from "react";
import { ERROR_CODES } from "../../../../../../configs/constants/errorCode";
import { userDetails, workflowMasterData } from "../../../../../../configs/mainStore";
import { getUserGroupsForReports } from "../../../../../../services/applicationApis";
import {
  applicationTimelineApi,
  createCauHeadAssign,
  uploadCauReferenceFiles
} from "../../../../../../services/creditFileApiCall";
import { getUsersByGroupId } from "../../../../../../services/userPrivilegeService";
import { closeBtn } from "../../../../../../styles";
import AppConfig from "../../../../../../utility/AppConfig";
import { referStatus } from "../../../../../../utility/util";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import ModalInputComponent from "../../../../../InputComponents/ModalInputComponent/ModalInputComponent";
import { RadioButtonComponent } from "../../../../../InputComponents/RadioButtonComponent/RadioButtonComponent";
import { SelectComponent } from "../../../../../InputComponents/SelectComponent/SelectComponent";
import { TextBoxComponent } from "../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { FileUploadComponent } from "../../../../DropzoneComponent/FileUploadComponent";
import { closeGlobalModal } from "../../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../../GlobalToast";
import { CircularProgressComponent } from "../../../../ProgressComponent/ProgressComponent";

const ReferModal = (params) => {
  const initialData = { note: "", user: "" };
  const showErrors = useState(false);
  const { wf_system_users, wf_groups }: any = useState(workflowMasterData);
  const referState: any = useState(initialData);
  const loginUser = useState(userDetails);

  const docUploadRef: any = createRef();

  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [isDropDownUserListLoading, setDropdownUserListLoading] = React.useState<any>(false);
  const [isDropDownGroupListLoading, setDropdownGroupListLoading] = React.useState<any>(false);
  const [dropdownUserList, setDropdownUserList] = React.useState<any>([]);
  const [referGroupsList, setReferGroupsList] = React.useState<any>([]);
  const [referGroup, setReferGroup] = React.useState<any>([]);

  const [refresh, setRefresh] = React.useState(false);
  const [fileList, setFileList] = React.useState<any>([
    {
      //TODO LEGAL DOC GET FROM DB
      ref: docUploadRef,
      isMandatory: true,
      allowedFormats: "application/pdf",
      maxFileSize: 4000000,
      documentKey: "",
    },
  ]);

  const [assignToLegalRiskOpinion, setAssignToLegalRiskOpinion] = React.useState<any>(null);

  const radioButtonGroup = [
    {
      id: 1,
      name: "Yes",
      label: "Yes",
      value: true,
    },
    {
      id: 0,
      name: "No",
      label: "No",
      value: false,
    },
  ];

  const saveRefer = async () => {
    setIsLoading(true);
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      const promises: any = [];

      fileList.map((item: any) => {
        promises.push(item.ref.current?.uploadSingleImg());
      });

      const referStat = Object.keys(referStatus)?.find((sts) => referStatus[sts]?.key == "active");
      const data = {
        applicationId: params?.applicationId,
        createdBy: loginUser?.userId?.get(),
        workflowId: params?.currentWorkflow,
        stageId: params?.currentWorkflowStage,
        referStatus: referStat,
        referType: referGroup,
        cauOfficer: true,
        referId: params?.referId,
        assignToAdminOpinion: assignToLegalRiskOpinion,
        assignee:
          assignToLegalRiskOpinion === false ? params?.data?.createdBy : referState.user.get(),
        ...referState.get(),
      };

      const res = await createCauHeadAssign(data);
      if (res?.status?.code === 1) {
        addToaster({
          status: "success",
          title: "Success",
          message: res?.msg,
        });
        const referenceId = params?.data?.referId;
        const appTimelineParams = {
          referenceId,
          applicationId: params?.applicationId,
          actionedBy: loginUser?.userId?.get(),
          assignee:
            assignToLegalRiskOpinion === false ? params?.data?.createdBy : referState.user.get(),
          actionNote: referState?.note?.get() || "",
          actionType: "ASSIGN_TO_CAU_HEAD",
        };
        const applicationTimelineApiResponse = await applicationTimelineApi(appTimelineParams);
        if (applicationTimelineApiResponse?.status === ERROR_CODES.success) {
          addToaster({
            status: "success",
            title: "Success",
            message: applicationTimelineApiResponse?.data?.message,
          });
        } else {
          addToaster({
            status: "error",
            title: "Error",
            message: "Timeline Update Failed",
          });
        }
        // calling the modal callback passing necessary arguments
        if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
          params.callBackOnAction(res?.data);
        }
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: res?.data?.msg,
        });
      }
      closeGlobalModal();
    }
    setIsLoading(false);
  };

  const closeRefer = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    if (assignToLegalRiskOpinion === true) {
      return referState.note.get() === "" || !referState.user.get();
    }
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  const getReferGroupId = async (groupID) => {
    setDropdownUserListLoading(true);
    let usersListData = await getUsersByGroupId(groupID);

    const correspondingValues = Object.keys(usersListData).map((key) => {
      const id = parseInt(key);
      const object = wf_system_users?.find((obj) => obj?.get().id === id);
      return {
        name: object?.fullName,
        id: object?.id,
      };
    });

    setDropdownUserList(correspondingValues);
    setDropdownUserListLoading(false);
  };

  useEffect(() => {
    (async () => {
      setDropdownGroupListLoading(true);
      const referalIDList = await getUserGroupsForReports();
      let tempUserGroupList: any = [];
      wf_groups?.get()?.map((item: any) => {
        if (
          item?.id === referalIDList?.legalGroupId ||
          item?.id === referalIDList?.riskGroupId ||
          (params?.hideInsDepSelect !== true && item?.id === referalIDList?.insDepGroupId)
        ) {
          tempUserGroupList.push({ name: item?.name, id: item?.id });
        }
      });

      setReferGroupsList(tempUserGroupList);
      setDropdownGroupListLoading(false);
    })();
  }, []);

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Create Referral</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Note"
            component={
              <TextBoxComponent
                value={referState?.note?.get()}
                onChange={(e) => {
                  resetError();
                  referState.note.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                minRows={4}
                inputProps={{ maxLength: 100 }}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && referState.note.get() === ""}
          />
        </Grid>
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Require Risk/Legal Opinion"
            component={radioButtonGroup.map((item) => {
              return (
                <>
                  <RadioButtonComponent
                    checked={assignToLegalRiskOpinion === item?.value}
                    value={item?.value}
                    name={item?.name}
                    onChange={() => setAssignToLegalRiskOpinion(item?.value)} // onChange={() => selectedFrom.set(element)}
                  />
                  <span className={"user-preference-label"}>{item?.label}</span>
                </>
              );
            })}
            isMandatory={true}
            showErrors={showErrors.get() && referState.note.get() === ""}
          />
        </Grid>
        {assignToLegalRiskOpinion &&
          (isDropDownGroupListLoading ? (
            <CircularProgressComponent size={20} />
          ) : (
            <Grid container item xs={12}>
              <ModalInputComponent
                title="Refer Type"
                component={
                  <SelectComponent
                    onChange={(e) => {
                      resetError();
                      setReferGroup(e.target.value);
                      getReferGroupId(e.target.value);
                    }}
                    values={referGroupsList}
                    // value={referState?.user?.get()}
                    size="small"
                    label={"Select Refer Type"}
                  />
                }
                isMandatory={true}
                showErrors={showErrors.get() && !referState.user.get()}
              />
            </Grid>
          ))}

        {assignToLegalRiskOpinion &&
          (isDropDownUserListLoading ? (
            <CircularProgressComponent size={20} />
          ) : (
            dropdownUserList?.length > 0 && (
              <Grid container item xs={12}>
                <ModalInputComponent
                  title="User"
                  component={
                    <SelectComponent
                      onChange={(e) => {
                        resetError();
                        referState.user.set(e.target.value);
                      }}
                      values={dropdownUserList}
                      value={referState?.user?.get()}
                      size="small"
                      label={"Select User"}
                    />
                  }
                  isMandatory={true}
                  showErrors={showErrors.get() && !referState.user.get()}
                />
              </Grid>
            )
          ))}
        {fileList.map((item, i) => {
          const { cau_document_id: documentId } = AppConfig.config;
          return (
            <FileUploadComponent
              fileDetails={item}
              uploadDataObject={{
                applicationId: params?.applicationId,
                referenceId: params?.referId,
                docIndex: i,
                documentId,
                createdBy: loginUser?.userId?.get(),
              }}
              isPreviewHave={false}
              fileUploadApi={uploadCauReferenceFiles}
            />
          );
        })}
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeRefer}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={saveRefer}
          disabled={isLoading}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default ReferModal;
