import { Box, Grid, Stack } from "@mui/material";
import { FC } from "react";
import Avatar from "react-avatar";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";

interface InspectionSectionWrapperProps {
  cardDetails: any;
  viewScoreCard: any;
}

export const ScoreCardMainSectionWrapper: FC<InspectionSectionWrapperProps> = ({
  cardDetails,
  viewScoreCard,
}: InspectionSectionWrapperProps) => {
  return (
    <Grid className={"inspection_template__panel--hide"} padding={"20px"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack flex={1}>
          <Box className="mainSection__heading--bold">{cardDetails.name}</Box>

          <Box className="mainSection__description">
            <small>{cardDetails.templateKey}</small>
          </Box>
        </Stack>
        <Stack direction={"row"} spacing={{ md: 10 }} flex={1}>
          <Stack className=" align-center">
            <div className={`basic-font basic-font-color opacity-7 font-size-12`}>{"Total Score"}</div>
            <div className={`basic-font font-size-28`} style={{ color: cardDetails.grade.color }}>
              {cardDetails.value}
            </div>
          </Stack>
          <Stack className=" align-center">
            <div className={`basic-font basic-font-color opacity-7 font-size-12`}>{"Grade"}</div>
            <Avatar round={true} value={cardDetails.grade.value} size={"40px"} color={cardDetails.grade.color} />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
          <ButtonComponent title="View" variant="contained" onClick={viewScoreCard} color="info" />
        </Stack>
      </Stack>
    </Grid>
  );
};
