import React, { createRef, FC, useEffect } from "react";

import { Grid } from "@mui/material";


import { Downgraded, useState } from "@hookstate/core";
import { appSetting } from "../../../configs/mainStore";
import { documentUploadData } from "../../../configs/stores/document-upload-store/documentUploadStore";
import { getInspectionDocuments } from "../../../services/documentApis";
import { InspectionDocumentComponent } from "../DropzoneComponent/InspectionDocumentComponent ";
import { ErrorMessageComponent } from "../ErrorMessageComponent";
import { InspectionUploadSectionWrapper } from "../MainSectionsWrapper/InspectionUploadSectionWrapper";
import { SubSectionsWrapper } from "../SubSectionsWrapper/SubSectionsWrapper";

interface InspectionDocumentUploadSubSectionProps {
  applicationId?: any;
  participantId?: any;
  documentSectionName?: any;
  documentComponentId?: any;
  onSyncSuccess?: any;
  onSync?: any;
  sectionLoading?: Boolean;
  disableAccordingToTab?: any;
  isLoggedInUserNotTheAssignee?: any;
  documentTypeId?: any;
  personalData?: any,
  creditFileData?: any,
}

export const InspectionDocumentUploadSubSection: FC<InspectionDocumentUploadSubSectionProps> = ({
  participantId,
  applicationId,
  documentSectionName,
  documentTypeId,
  documentComponentId,
  personalData,
  creditFileData,
  onSyncSuccess,
  onSync,
  sectionLoading,
  disableAccordingToTab = false,
  isLoggedInUserNotTheAssignee,
}: any) => {
  const docUploadRef: any = createRef();
  const [sectionMeta, setSectionMeta] = React.useState<any>([]);
  const [isUploading, setIsUploading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("")
  const [documentTypes, setDocumentTypes] = React.useState<any>([])
  const [uploadedDocumentTypes, setuploadedDocumentTypes] = React.useState<any>([])
  const { documentDetailsList }: any = documentUploadData.attach(Downgraded).get();
  const appSettings: any = useState(appSetting);

  const reloadComponent = async (element?: any) => {
  }

  useEffect(() => {
    (async () => {
      let data = {
        applicationId: applicationId
      }
      let resFileData = await getInspectionDocuments(data)

      if (resFileData && resFileData?.length > 0) {
        setuploadedDocumentTypes(resFileData)

        const reducedData = resFileData?.reduce((acc, obj) => {
          const key = `${obj["document_catalogue"]}_${obj["participant_id"]}`;
          (acc[key] = acc[key] || []).push(obj);
          return acc;
        }, {});

        setDocumentTypes(reducedData)
      } else {
        setErrorMessage("Empty Document List")
      }

    })()
  }, [documentTypeId])

  const submitBtnClick = async () => {
    const normalDocUploadResult = await docUploadRef?.current?.normalDocumentUploadFunc();

    if (normalDocUploadResult === true) {
      reloadComponent(true);
    }
  };

  return (
    <>
      {errorMessage?.length === 0 ? (
        <>
          {
            Object.keys(documentTypes)?.map((obj, i) => {
              const [docId, individualId]: any = obj.split("_");

              const foundData = personalData.find(item => item.individualId == individualId);

              const documentName = documentDetailsList[docId][appSettings.get().language]?.name;
              let title = foundData?.initialsInFull
                ? `${documentName} - ${foundData?.initialsInFull}`
                : documentName

              return <InspectionUploadSectionWrapper
                title={title}
                keyCode={1}
                noBack={true}
                loading={isUploading}
                onNext={() => {
                  submitBtnClick();
                }}
                numOfUploadedFiles={documentTypes[obj]?.length}
                subTxt={
                  sectionMeta?.desc
                    ? `Upload ${sectionMeta?.desc} Documents`
                    : `Upload ${documentSectionName}`
                }
                documentComponentId={documentComponentId}
                personalData={personalData}
                creditFileData={creditFileData}
                onSyncSuccess={onSyncSuccess}
                onSync={onSync}
                sectionLoading={sectionLoading}
                disableAccordingToTab={disableAccordingToTab}
                documentTypes={documentTypes}>
                <SubSectionsWrapper title="">
                  <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                      <Grid container rowSpacing={2}>
                        {documentTypes[obj].map((item: any, i) => {
                          return <InspectionDocumentComponent key={i} fileDetails={item} onNext={documentTypes[obj]} />;
                        })}
                      </Grid>
                    </Grid>
                  </Grid>

                </SubSectionsWrapper>
              </InspectionUploadSectionWrapper>
            })
          }
        </>
      ) : <ErrorMessageComponent headMessage={"Empty"} errorMessage={errorMessage} />}
    </>
  );
};
