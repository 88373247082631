import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Divider, Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import React, { Fragment } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const TabValidationModal = (params: any = {}) => {
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState<string | false>('');

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAccordionExpand = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Box
      sx={{
        ...style,
        width: 800,
        maxHeight: 500,
        overflow: 'hidden',
        overflowY: 'scroll',
      }}
    >
      <h2 id="child-modal-title" style={{ fontFamily: 'Poppins' }}>
        Credit File Validations
      </h2>
      {/* <p id="child-modal-description">

        </p> */}
      <Grid container>
        {params.validationWarnings.length > 0 ? (
          <Grid item xs m={1} sx={{ fontFamily: 'Poppins' }}>
            <Box p={0.5} m={1} className="basic-font-color">
              You have Warnings
            </Box>
            {params.validationWarnings?.map((item, key) => {
              return (
                <Accordion key={key} expanded={params.expanded === 'panel1'} onChange={handleAccordionExpand('panel1')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography className={'warning-credit'}>{item?.mainTab}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {item?.tabMessage?.map((i, key) => {
                      return (
                        <Typography key={key} className="basic-font-color">
                          {i.message}
                        </Typography>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Grid>
        ) : (
          <Fragment />
        )}
        {params.validationErrors.length > 0 ? (
          <>
            <Divider orientation="vertical" flexItem />
            <Grid item xs m={1} sx={{ fontFamily: 'Poppins' }}>
              <Box p={0.5} m={1} className="basic-font-color">
                You have Errors
              </Box>
              {params.validationErrors?.map((item, key): any => {
                return (
                  <Accordion key={key} expanded={expanded === 'panel1'} onChange={handleAccordionExpand('panel1')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={'warning-credit'}>{item?.mainTab}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {item?.tabMessage?.map((i, key) => {
                        return (
                          <Typography key={key} className="basic-font-color">
                            {i.message}
                          </Typography>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
          </>
        ) : (
          <Fragment />
        )}
      </Grid>
      <Button sx={{ float: 'right' }} onClick={handleClose}>
        OK
      </Button>
    </Box>
  );
};
