import { Box, Grid, Stack, Typography } from "@mui/material";
import { standardDateFormatter } from "los-util";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { MASTER_DATA_KEYS } from "../../../../../../../../configs/constants/masterDataKeys";
import { VARIANT } from "../../../../../../../../configs/constants/styleValues";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandLengthValidator, OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import MasterDataProvider from "../../../../../../../../utility/MasterDataProvider";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import AutoCompleteProxyComponent from "../../../../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";
import { AutoInstallmentRecoveryRequiredSection, LoanTransactionModeSection } from "./FDAgainstLoansSub";

export const FdAgainstLoans = (props) => {
  const { t: translate } = useTranslation();

  const {
    fdValueOfSecurity,
    fdSecuredValue,
    fdDescription,
    fdLoanDetailsAccountNumber,
    fdCustomerNumber,
    fdLoanDetailsAccountOpenedOn,
    fdPurposeCode,
    fdFixedDepositAccountNumber,
    fdContractNumber,
    fdDepositDate,
    fdCurrency,
    fdPrincipalAmount,
    fdInterestAccured,
    fdMaturityDate,
    fdDepositInterestRateCbs,
    fdDepositAmount,
    fdMargin,
    fdAmountAvaibleForLoan,
    fdTotalFdAmount,
    fdTotalOfMaximumGrantingLadAmount,
    fdSactionedRefNumber,
    fdSanctionedAmount,
    fdSactionedBy,
    fdExpiryDate,
    fdDepositInterestRateFd,
    fdWeightedAverageDepositRate,
    fdIncrementalInterestAboveTheDepositRate,
    fdEffectiveInterestRate,
    fdRemarksInterestDetails,
    fdCreditAmount,
    fdSourceOfFundType,
    fdTransactionCurrency,
    fdTransactionAmount,
    fdSavingAccountAvaibleBalanace,
    fdGLAccessCode,
    fdRemarksSettlementsDetails,
    fdLoanAccountNumber,
    fdEffectiveDate,
    fdProductCode,
    fdAutRProductCode,
    fdAccountCurrency,
    fdLoanAutoRecoveryAccountOpenedOn,
    fdTenorFixedDeposit,
  } = props.fields || {};

  const componentName: string = `FD Against Loans
  ${props.index > 0 ? "(" + (parseInt(props.index) + 1) + ")" : ""}`;

  const validateInputItemsLength = (value: string | number, maxVal: number): boolean => {
    value = value.toString();
    return value.length <= maxVal;
  };

  const loadPurposes = () => {
    const masterDataStore = MasterDataProvider.provideMasterData();
    let list = masterDataStore?.getMasterData(MASTER_DATA_KEYS?.LOAN_PURPOSE);
    list = JSON.parse(JSON.stringify(list.value));

    list = list.filter((obj) => {
      return obj?.id !== 26;
    });
    return list;
  };

  return (
    <div className="full-width full-height">
      <Grid container>
        <Stack spacing={1} m={1} className="full-width">
          <Box mt={2} className="securityWrapper" p={2}>
            <Fragment>
              {props?.isNotNeedComponentTitle ? (
                <></>
              ) : (
                <InputFieldsStack>
                  <Typography variant="subtitle1" pl={2.5}>
                    {componentName}
                  </Typography>
                </InputFieldsStack>
              )}
              <InputFieldsStack>
                <InputGrid visibility={fdValueOfSecurity?.visible}>
                  <TextBoxComponent
                    value={formatCurrency(props?.doc?.fdValueOfSecurity?.get())}
                    onChange={(e) => {
                      props?.doc?.fdValueOfSecurity?.set(e.target.value);
                    }}
                    label={translate("Value of the Security")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={fdValueOfSecurity?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={fdSecuredValue?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.fdSecuredValue?.get())} onChange={(e) => props?.doc?.fdSecuredValue?.set(e.target.value)} label={translate("Secured Value")} onInput={OnDemandValueFormatter.numeric} required={fdSecuredValue?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={fdDescription?.visible}>
                  <TextBoxComponent value={props?.doc?.fdDescription?.get()} onChange={(e) => props?.doc?.fdDescription?.set(e.target.value)} label={translate("Description")} onInput={OnDemandValueFormatter.alphaNumericSpaces} required={fdDescription?.mandatory === 1 ? true : false} />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={fdLoanDetailsAccountNumber?.visible}>
                  <TextBoxComponent
                    value={props?.doc?.fdLoanDetailsAccountNumber?.get()}
                    onChange={(e) => {
                      if (validateInputItemsLength(e.target.value, 12)) {
                        props?.doc?.fdLoanDetailsAccountNumber?.set(e.target.value);
                      }
                    }}
                    label={translate("Loan Account Number")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={fdLoanDetailsAccountNumber?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={fdCustomerNumber?.visible}>
                  <TextBoxComponent value={props?.doc?.fdCustomerNumber?.get()} onChange={(e) => props?.doc?.fdCustomerNumber?.set(e.target.value)} label={translate("Customer  Number")} onInput={OnDemandLengthValidator.phoneNumber} required={fdCustomerNumber?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={fdProductCode?.visible}>
                  <TextBoxComponent
                    value={props?.doc?.fdProductCode?.get()}
                    onChange={(e) => {
                      if (validateInputItemsLength(e.target.value, 3)) {
                        props?.doc?.fdProductCode?.set(e.target.value);
                      }
                    }}
                    label={translate("Loan Product Code")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={fdProductCode?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={fdLoanDetailsAccountOpenedOn?.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.fdLoanDetailsAccountOpenedOn?.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.fdLoanDetailsAccountOpenedOn?.set(preparedDate);
                      }
                    }}
                    label={translate("Account Opened On (DD/MM/YYYY)")}
                    enableFuture={true}
                    disablePast={false}
                    required={fdLoanDetailsAccountOpenedOn?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={fdPurposeCode?.visible}>

                  <AutoCompleteProxyComponent
                    label={translate("Purpose Code")}
                    options={loadPurposes()}
                    fullWidth={true}
                    onChange={(e) => {
                      if (e) {
                        return props?.doc?.fdPurposeCode?.set(e.id);
                      } else {
                        props?.doc?.fdPurposeCode?.set(null);
                      }
                    }}
                    required={fdPurposeCode?.mandatory === 1 ? true : false}
                    value={props?.doc?.fdPurposeCode?.get()}
                    cls="select_basic"
                  />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={fdFixedDepositAccountNumber?.visible}>
                  <TextBoxComponent
                    value={props?.doc?.fdFixedDepositAccountNumber?.get()}
                    onChange={(e) => {
                      if (validateInputItemsLength(e.target.value, 12)) {
                        props?.doc?.fdFixedDepositAccountNumber?.set(e.target.value);
                      }
                    }}
                    label={translate("Fixed Deposit Account Number")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={fdFixedDepositAccountNumber?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={fdContractNumber?.visible}>
                  <TextBoxComponent value={props?.doc?.fdContractNumber?.get()} onChange={(e) => props?.doc?.fdContractNumber?.set(e.target.value)} label={translate("Contract Number")} onInput={OnDemandValueFormatter.alphaNumericSpaces} required={fdContractNumber?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={fdDepositDate?.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.fdDepositDate?.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.fdDepositDate?.set(preparedDate);
                      }
                    }}
                    label={translate("Deposit Date (DD/MM/YYYY)")}
                    enableFuture={true}
                    disablePast={false}
                    required={fdDepositDate?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={fdCurrency?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.fdCurrency?.get())} onChange={(e) => props?.doc?.fdCurrency?.set(e.target.value)} label={translate("Currency")} onInput={OnDemandValueFormatter.numeric} required={fdCurrency?.mandatory === 1 ? true : false} />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={fdPrincipalAmount?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.fdPrincipalAmount?.get())} onChange={(e) => props?.doc?.fdPrincipalAmount?.set(e.target.value)} label={translate("Principal Amount")} onInput={OnDemandValueFormatter.numeric} required={fdPrincipalAmount?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={fdInterestAccured?.visible}>
                  <TextBoxComponent value={props?.doc?.fdInterestAccured?.get()} onChange={(e) => props?.doc?.fdInterestAccured?.set(e.target.value)} label={translate("Interest Accured")} onInput={OnDemandValueFormatter.alphaNumericSpaces} required={fdInterestAccured?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={fdMaturityDate?.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.fdMaturityDate?.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.fdMaturityDate?.set(preparedDate);
                      }
                    }}
                    label={translate("Maturity Date (DD/MM/YYYY)")}
                    enableFuture={true}
                    disablePast={false}
                    required={fdMaturityDate?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={fdDepositInterestRateCbs?.visible}>
                  <TextBoxComponent value={props?.doc?.fdDepositInterestRateCbs?.get()} onChange={(e) => props?.doc?.fdDepositInterestRateCbs?.set(e.target.value)} label={translate("Deposit Interest Rate")} onInput={OnDemandValueFormatter.numeric} required={fdDepositInterestRateCbs?.mandatory === 1 ? true : false} />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={fdDepositAmount?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.fdDepositAmount?.get())} onChange={(e) => props?.doc?.fdDepositAmount?.set(e.target.value)} label={translate("Deposit Amount")} onInput={OnDemandValueFormatter.numeric} required={fdDepositAmount?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={fdMargin?.visible}>
                  <TextBoxComponent value={props?.doc?.fdMargin?.get()} onChange={(e) => props?.doc?.fdMargin?.set(e.target.value)} label={translate("Margin")} onInput={OnDemandValueFormatter.alphaNumericSpaces} required={fdMargin?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={fdAmountAvaibleForLoan?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.fdAmountAvaibleForLoan?.get())} onChange={(e) => props?.doc?.fdAmountAvaibleForLoan?.set(e.target.value)} label={translate("Amount Avaible For Loan")} onInput={OnDemandValueFormatter.numeric} required={fdAmountAvaibleForLoan?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={fdTenorFixedDeposit?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.fdTenorFixedDeposit?.get())} onChange={(e) => props?.doc?.fdTenorFixedDeposit?.set(e.target.value)} label={translate("Tenor of the Fixed Deposit")} onInput={OnDemandValueFormatter.numeric} required={fdTenorFixedDeposit?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={fdTotalFdAmount?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.fdTotalFdAmount?.get())} onChange={(e) => props?.doc?.fdTotalFdAmount?.set(e.target.value)} label={translate("Total FD Amount")} onInput={OnDemandValueFormatter.numeric} required={fdTotalFdAmount?.mandatory === 1 ? true : false} />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={fdTotalOfMaximumGrantingLadAmount?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.fdTotalOfMaximumGrantingLadAmount?.get())} onChange={(e) => props?.doc?.fdTotalOfMaximumGrantingLadAmount?.set(e.target.value)} label={translate("Total Of  Maximum Granting  LAD Amount")} onInput={OnDemandValueFormatter.numeric} required={fdTotalOfMaximumGrantingLadAmount?.mandatory === 1 ? true : false} />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={fdSactionedRefNumber?.visible}>
                  <TextBoxComponent value={props?.doc?.fdSactionedRefNumber?.get()} onChange={(e) => props?.doc?.fdSactionedRefNumber?.set(e.target.value)} label={translate("Sactioned Ref Number")} onInput={OnDemandValueFormatter.numeric} required={fdSactionedRefNumber?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={fdSanctionedAmount?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.fdSanctionedAmount?.get())} onChange={(e) => props?.doc?.fdSanctionedAmount?.set(e.target.value)} label={translate("Sanctioned Amount")} onInput={OnDemandValueFormatter.numeric} required={fdSanctionedAmount?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={fdSactionedBy?.visible}>
                  <TextBoxComponent label={translate("Sactioned By")} value={props?.doc?.fdSactionedBy?.get()} required={fdSactionedBy?.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.fdSactionedBy?.set(e.target.value)} />
                </InputGrid>
                <InputGrid visibility={fdExpiryDate?.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.fdExpiryDate?.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.fdExpiryDate?.set(preparedDate);
                      }
                    }}
                    label={translate("Expiry Date (DD/MM/YYYY)")}
                    enableFuture={true}
                    disablePast={false}
                    required={fdExpiryDate?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={fdDepositInterestRateFd?.visible}>
                  <TextBoxComponent value={props?.doc?.fdDepositInterestRateFd?.get()} onChange={(e) => props?.doc?.fdDepositInterestRateFd?.set(e.target.value)} label={translate("Deposit Interest Rate")} onInput={OnDemandValueFormatter.numeric} required={fdDepositInterestRateFd?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={fdWeightedAverageDepositRate?.visible}>
                  <TextBoxComponent value={props?.doc?.fdWeightedAverageDepositRate?.get()} onChange={(e) => props?.doc?.fdWeightedAverageDepositRate?.set(e.target.value)} label={translate("Weighted Average Deposit Rate")} onInput={OnDemandValueFormatter.numeric} required={fdWeightedAverageDepositRate?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={fdIncrementalInterestAboveTheDepositRate?.visible}>
                  <TextBoxComponent value={props?.doc?.fdIncrementalInterestAboveTheDepositRate?.get()} onChange={(e) => props?.doc?.fdIncrementalInterestAboveTheDepositRate?.set(e.target.value)} label={translate("Incremental Interest Above The Deposit Rate")} onInput={OnDemandValueFormatter.numeric} required={fdIncrementalInterestAboveTheDepositRate?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={fdEffectiveInterestRate?.visible}>
                  <TextBoxComponent
                    value={props?.doc?.fdEffectiveInterestRate?.get()}
                    onChange={(e) => props?.doc?.fdEffectiveInterestRate?.set(e.target.value)}
                    label={translate("Effective Interest Rate")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={fdEffectiveInterestRate?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={fdRemarksInterestDetails?.visible} variant={VARIANT?.extraLongGrid}>
                  <TextBoxComponent
                    multiline
                    rows={5}
                    value={props?.doc?.fdRemarksInterestDetails?.get()}
                    onChange={(e) => props?.doc?.fdRemarksInterestDetails?.set(e.target.value)}
                    label={translate("Remarks")}
                    onInput={OnDemandValueFormatter.alphaNumericSpaces}
                    required={fdRemarksInterestDetails?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={fdCreditAmount?.visible}>
                  <NumericFormat
                    customInput={TextBoxComponent}
                    variant="outlined"
                    label={translate("Credit Amount")}
                    thousandSeparator={true}
                    value={formatCurrency(props?.doc?.fdCreditAmount?.get())}
                    required={fdCreditAmount?.mandatory === 1 ? true : false}
                    decimalScale={2}
                    onValueChange={(e: any) => {
                      const { value } = e;
                      props?.doc?.fdCreditAmount?.set(value);
                    }}
                  />
                </InputGrid>
                <InputGrid visibility={fdSourceOfFundType?.visible}>
                  <TextBoxComponent label={translate("Source Of Fund Type")} value={props?.doc?.fdSourceOfFundType?.get()} required={fdSourceOfFundType?.mandatory === 1 ? true : false} onChange={(e) => props?.doc?.fdSourceOfFundType?.set(e.target.value)} />
                </InputGrid>
                <InputGrid visibility={fdTransactionCurrency?.visible}>

                  <NumericFormat
                    customInput={TextBoxComponent}
                    variant="outlined"
                    label={translate("Transaction Currency")}
                    thousandSeparator={true}
                    value={formatCurrency(props?.doc?.fdTransactionCurrency?.get())}
                    required={fdTransactionCurrency?.mandatory === 1 ? true : false}
                    decimalScale={2}
                    onValueChange={(e: any) => {
                      const { value } = e;
                      props?.doc?.fdTransactionCurrency?.set(value);
                    }}
                  />
                </InputGrid>
                <InputGrid visibility={fdTransactionAmount?.visible}>

                  <NumericFormat
                    customInput={TextBoxComponent}
                    variant="outlined"
                    label={translate("Transaction Amount")}
                    thousandSeparator={true}
                    value={formatCurrency(props?.doc?.fdTransactionAmount?.get())}
                    required={fdTransactionAmount?.mandatory === 1 ? true : false}
                    decimalScale={2}
                    onValueChange={(e: any) => {
                      const { value } = e;
                      props?.doc?.fdTransactionAmount?.set(value);
                    }}
                  />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <LoanTransactionModeSection props={props} />
                <InputGrid visibility={fdSavingAccountAvaibleBalanace?.visible}>
                  <NumericFormat
                    customInput={TextBoxComponent}
                    variant="outlined"
                    label={translate("Saving Account Avaible Balanace")}
                    thousandSeparator={true}
                    value={formatCurrency(props?.doc?.fdSavingAccountAvaibleBalanace?.get())}
                    required={fdSavingAccountAvaibleBalanace?.mandatory === 1 ? true : false}
                    decimalScale={2}
                    onValueChange={(e: any) => {
                      const { value } = e;
                      props?.doc?.fdSavingAccountAvaibleBalanace?.set(value);
                    }}
                  />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={fdGLAccessCode?.visible}>
                  <TextBoxComponent value={props?.doc?.fdGLAccessCode?.get()} onChange={(e) => props?.doc?.fdGLAccessCode?.set(e.target.value)} label={translate("GL Access Code")} onInput={OnDemandValueFormatter.numeric} required={fdGLAccessCode?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={fdRemarksSettlementsDetails?.visible} variant={VARIANT?.extraLongGrid}>
                  <TextBoxComponent multiline rows={5} value={props?.doc?.fdRemarksSettlementsDetails?.get()} onChange={(e) => props?.doc?.fdRemarksSettlementsDetails?.set(e.target.value)} label={translate("Remarks")} onInput={OnDemandValueFormatter.alphaNumericSpaces} required={fdRemarksSettlementsDetails?.mandatory === 1 ? true : false} />
                </InputGrid>
              </InputFieldsStack>

              <InputFieldsStack>
                <InputGrid visibility={fdLoanAccountNumber?.visible}>
                  <TextBoxComponent value={props?.doc?.fdLoanAccountNumber?.get()} onChange={(e) => props?.doc?.fdLoanAccountNumber?.set(e.target.value)} label={translate("Loan Account Number")} onInput={OnDemandValueFormatter.numeric} required={fdLoanAccountNumber?.mandatory === 1 ? true : false} />
                </InputGrid>
                <InputGrid visibility={fdEffectiveDate?.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.fdEffectiveDate?.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.fdEffectiveDate?.set(preparedDate);
                      }
                    }}
                    label={translate("Effective Date (DD/MM/YYYY)")}
                    enableFuture={true}
                    disablePast={false}
                    required={fdEffectiveDate?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={fdAutRProductCode?.visible}>
                  <TextBoxComponent
                    value={props?.doc?.fdAutRProductCode?.get()}
                    onChange={(e) => {
                      if (validateInputItemsLength(e.target.value, 3)) {
                        props?.doc?.fdAutRProductCode?.set(e.target.value);
                      }
                    }}
                    label={translate("Product Code")}
                    onInput={OnDemandValueFormatter.numeric}
                    required={fdAutRProductCode?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
                <InputGrid visibility={fdAccountCurrency?.visible}>
                  <TextBoxComponent value={formatCurrency(props?.doc?.fdAccountCurrency?.get())} onChange={(e) => props?.doc?.fdAccountCurrency?.set(e.target.value)} label={translate("Account Currency")} onInput={OnDemandValueFormatter.numeric} required={fdAccountCurrency?.mandatory === 1 ? true : false} />
                </InputGrid>
              </InputFieldsStack>
              <InputFieldsStack>
                <InputGrid visibility={fdLoanAutoRecoveryAccountOpenedOn?.visible}>
                  <ValidateDatePicker
                    value={formattedDate(props?.doc?.fdLoanAutoRecoveryAccountOpenedOn?.get())}
                    onChange={(date) => {
                      const preparedDate = standardDateFormatter(date);
                      if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                        props?.doc?.fdLoanAutoRecoveryAccountOpenedOn?.set(preparedDate);
                      }
                    }}
                    label={translate("Account Opened On (DD/MM/YYYY)")}
                    enableFuture={true}
                    disablePast={false}
                    required={fdLoanAutoRecoveryAccountOpenedOn?.mandatory === 1 ? true : false}
                  />
                </InputGrid>
              </InputFieldsStack>
              <AutoInstallmentRecoveryRequiredSection props={props} />
            </Fragment>
          </Box>
        </Stack>
      </Grid>
    </div>
  );
};
