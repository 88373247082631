import { getCopy } from "../../utility/util";
import appStrings from "../constants/appStrings";
import { AgricultureDetailsState, EStatement } from "../interfaces";
import {
  fdAgainstLoansDefault,
  immovableMachineryEquipementDefault,
  lifeInsuranceDefault,
  loanProtectInsurance,
  machineryEquipmentDefault,
  pledgeDefault,
  propertyDefault,
  sharesDefault,
  stockAsGoldDefault,
  treasuryBondDefault,
  vehicleDefault,
  warehouseReceiptDefault,
} from "./securityDataSubSection/securityDataSubSections.module";
//common state data
export const addressTypes = {
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  addressLine4: "",
  city: "",
  propertyName: "",
  flatNumber: "",
  houseNumber: "",
  streetName: "",
  cityLine1: "",
  cityLine2: "",
  streetType: "",
  district: "",
  province: "",
  postalCode: "",
  dsDivision: "",
  gnDivision: "",
};

export const mailingAddressTypes = {
  mailAddressLine1: "",
  mailAddressLine2: "",
  mailAddressLine3: "",
  mailAddressLine4: "",
  mailCity: "",
  mailPropertyName: "",
  mailFlatNumber: "",
  mailHouseNumber: "",
  mailStreetName: "",
  mailCityLine1: "",
  mailCityLine2: "",
  mailStreetType: "",
  mailDistrict: "",
  mailProvince: "",
  mailPostalCode: "",
  mailDsDivision: "",
  mailGnDivision: "",
};
export const currentAddressTypes = {
  currentAddressLine1: "",
  currentAddressLine2: "",
  currentAddressLine3: "",
  currentAddressLine4: "",
  currentCity: "",
  currentPropertyName: "",
  currentFlatNumber: "",
  currentHouseNumber: "",
  currentStreetName: "",
  currentCityLine1: "",
  currentCityLine2: "",
  currentStreetType: "",
  currentDistrict: "",
  currentProvince: "",
  currentPostalCode: "",
  currentDsDivision: "",
  currentGnDivision: "",
};

export const prevAddressTypes = {
  previousAddressLine1: "",
  previousAddressLine2: "",
  previousAddressLine3: "",
  previousAddressLine4: "",
  previousCity: "",
  previousPropertyName: "",
  previousFlatNumber: "",
  previousHouseNumber: "",
  previousStreetName: "",
  previousCityLine1: "",
  previousCityLine2: "",
  previousStreetType: "",
  previousDistrict: "",
  previousProvince: "",
  previousPostalCode: "",
  previousDsDivision: "",
  previousGnDivision: "",
};

export const empAddressTypes = {
  empPropertyName: "",
  empFlatNumber: "",
  empHouseNumber: "",
  empStreetName: "",
  empCityLine1: "",
  empCityLine2: "",
  empStreetType: "",
  empDistrict: "",
  empProvince: "",
  empPostalCode: "",
  empGnDivision: "",
};

export const grtAddressTypes = {
  grtPropertyName: "",
  grtFlatNumber: "",
  grtHouseNumber: "",
  grtStreetName: "",
  grtCityLine1: "",
  grtCityLine2: "",
  grtStreetType: "",
  grtDistrict: "",
  grtProvince: "",
  grtPostalCode: "",
  grtGnDivision: "",
};

//---------------------------------------------------------------------------------

const isPartialFrom = false;

const appState = {};

/**
 * App form main section state ------------------------------------------------------------
 */

// const sectionExpandState = 1;

const sectionExpandState = {
  participant: "primary",
  section: 1,
  key: "personalData",
};

export const detailsOfApplicantCloseToRdbState = {
  rdRName: "",
  rdRRelationShip: "",
  rdREmployeeNo: "",
  rdRBranch: "",
};

export const personalDataState = {
  individualId: "0",
  primaryTitle: "",
  primaryFirstName: "",
  primaryMiddleName: "",
  primaryLastName: "",
  oldNic: "",
  newNic: "",
  civilState: "",
  gender: "",
  dorinDoraAccount: "",
  dob: null,
  years: null,
  months: null,
  taxPayer: 0,
  individualTaxNo: "",
  addGuarantor: null,
  addJointBorrower: 0,
  oldNicExp: null,
  newNicExp: null,
  initialsInFull: "",
  usedName: "",
  initials: "",
  primaryOtherName: "",
  race: "",
  sriLankanCitizenship: null,
  sriLankanCitizenshipStatus: "",
  spouseFullName: "",
  spouseContactNo: "",
  spouseIncome: "",
  spouseOfficeNo: "",
  spouseMobile: "",
  spouseOldNic: "",
  spouseNewNic: "",
  empStatus: "",
  fullTimeOrPartTime: "",
  occupation: "",
  publicEmpLoanActCovered: null,
  legalWarrantAgainstCustomer: null,
  otherDetailSpecification: "",
  preferredCommunicationLanguage: "",
  district: "",
  cashDeposited: 0,
  branch: "",
  specify: "",
  relationshipToMainApplicant: "",
  relationshipToMainApplicantCode: "",
  guaranteedAmountOfLoan: "",
  idTypes: [{ idType: "", idDetails: "", idExp: null }],
  otherNames: [
    {
      languageId: "",
      firstName: "",
      middleName: "",
      lastName: "",
    },
  ],
  isApplicantCloseToRdb: 0,
  detailsOfApplicantCloseToRdb: [
    JSON.parse(JSON.stringify(detailsOfApplicantCloseToRdbState)),
  ],
  sectionErrorStatus: "pending",
};

const personalDetailState = JSON.parse(JSON.stringify(personalDataState));

export const creditDataState = {
  applicantTypes: null,
  productTypes: null,
  sectorTypes: "",
  schemeTypes: "",
  recordId: "",
  loanAmount: "",
  formattedLoanAmount: "",
  approvedLoanAmount: null,
  loanPayYear: "",
  loanPayMonths: "",
  repaymentType: 1,
  interestRate: "",
  loanPurpose: null,
  loanTerms: "",
  loanCycles: "",
  loanFrequency: 3,
  fundSource: "",
  gracePeriod: 0,
  sectionErrorStatus: "success",
  type: "",
  sector: "",
  scheme: "",
  loanRequirement: "",
  applicantType: "",
};

const creditDetailsState = JSON.parse(JSON.stringify(creditDataState));

const singleCustomerViewDetailsState = {
  dorinDoraAccount: "",
  sectionErrorStatus: "pending",
};

export const singleCustomerViewDetails = JSON.parse(
  JSON.stringify(singleCustomerViewDetailsState)
);

const defaultEStatements: EStatement = {
  email: "",
  typeOfStatement: "",
  optional: "",
  sectionErrorStatus: "pending",
};

export const resetDefaultEStatementsState = JSON.parse(
  JSON.stringify(defaultEStatements)
);
export const defaultEStatementsState = JSON.parse(
  JSON.stringify(defaultEStatements)
);

export const contactDataState = {
  primaryContact: "",
  primarySms: "",
  bankSmsNewsAlerts: "",
  relationName: "",
  relationLandNumber: "",
  relationship: "",
  contactTypes: [{ contactType: "", contactNumber: "" }],
  primaryEmail: "",
  socialContactTypes: [{ type: "", url: "" }],
  emailTypes: [{ emailType: "", emailAddress: "" }],
  bankEmailNewsAlerts: "",
  sectionErrorStatus: "pending",
};

const contactDetailState = JSON.parse(JSON.stringify(contactDataState));

const additionalDetailState = {
  employed: true,
  salary: "",
};

export const addressDataState = {
  isMailingAddressSame: null,
  isCurrentAddressSame: null,
  permanentAddress: { ...addressTypes },
  mailingAddressData: { ...mailingAddressTypes },
  currentAddressData: { ...currentAddressTypes },
  residentialState: "",
  otherResidentialState: "",
  currentResidenceYears: "",
  currentResidenceMonths: "",
  currentResidenceTimePeriod: null,
  previousResidenceAddressData: { ...prevAddressTypes },
  sectionErrorStatus: "pending",
};

const addressDetailsState = JSON.parse(JSON.stringify(addressDataState));

export const educationAwardState = {
  awardType: "",
  awardName: "",
  awardYear: "",
  awardDuration: "",
  awardDurationYears: "",
  awardDurationMonths: "",
  instituteName: "",
  nvqLevel: "",
};

export const educationDataState = {
  primaryEducationGrade: "",
  awardTypes: [JSON.parse(JSON.stringify(educationAwardState))],
  sectionErrorStatus: "pending",
};

const educationDetailsState = JSON.parse(JSON.stringify(educationDataState));

const employmentDetails = {
  employmentType: "",
  employmentStatus: "",
  natureOfEmployment: "",
  endDate: null,
  startDate: null,
  permenantEmployment: null,
  pensionableEmployment: null,
  permanentDate: null,
  pensionableDate: null,
  industryType: null,
  employmentTimePeriod: null,
  noOfYearsAtEmp: "",
  noOfMonthsAtEmp: "",
  noOfYearsToPension: "",
  noOfMonthsToPension: "",
  employmentTimePeriodToPension: null,
  occupation: "",
  designation: "",
  employerName: "",
  companyName: "",
  corporateId: "0",
  fullTimeOrPartTime: "",
};

const selfEmployedDetailsState = {
  typeOfBusiness: "",
  businessSector: "",
  businessSubSector: "",
  businessRegNo: "",
  brDate: null,
  noOfEmployees: "",
  taxPayment: "",
  taxId: "",
  noOfYearsInOperation: null,
  pastBusinessExperience: null,
  nameOfTheBusiness: "",
  website: "",
  corporateId: "0",
};

export const currentEmployeeState = {
  isSectionCollapsed: false,
  employmentCategory: "",
  currentEmployment: null,
  ...employmentDetails,
  ...empAddressTypes,
  contactTypes: [
    {
      empContactType: "",
      empContactNumber: "",
      empContactName: "",
      empEmailType: "",
      empEmailAddress: "",
    },
  ],
  address: {
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    addressLine4: "",
    city: "",
    province: "",
    district: "",
    dsDivision: "",
    gnDivision: "",
    postalCode: "",
  },
};

export const employeeDataState = {
  experience: { totalYearsOfExperience: null, totalMonthsOfExperience: null },
  currentEmployment: [JSON.parse(JSON.stringify(currentEmployeeState))],
  previousEmployment: [JSON.parse(JSON.stringify(currentEmployeeState))],
  sectionErrorStatus: "pending",
};

const employedDetailState = JSON.parse(JSON.stringify(employeeDataState));
const employedDetailState1 = JSON.parse(JSON.stringify(employeeDataState));

export const taxDetailsObject = {
  taxFileNumber: "",
  office: "",
  indexNumber: "",
  yearOfAssessment: null,
  statutoryIncome: "",
  estimatedIncome: "",
  valueSubjectsToTax: "",
  valuePayable: "",
  taxPaid: "",
};

const defaultCurrentBusiness = {
  isSectionCollapsed: false,
  ...employmentDetails,
  contactTypes: [
    {
      empContactType: "",
      empContactNumber: "",
      empContactName: "",
      empEmailAddress: "",
    },
  ],
  emailTypes: [{ empEmailType: "", empEmailAddress: "" }],
  typeOfBusiness: "",
  businessSector: "",
  businessSubSector: "",
  businessRegNo: "",
  brDate: null,
  noOfEmployees: "",
  noOfFemaleEmployees: "",
  noOfMaleEmployees: "",
  taxPayment: "",
  taxId: "",
  NoOfYearsInOperation: "",
  pastBusinessExperience: null,
  noOfYearsInExperience: null,
  noOfMonthsInExperience: null,
  NoYearsOperation: "",
  nameOfTheBusiness: "",
  website: "",
  vulnerabilityClimaticAndEnvironmentalFactors: "",
  currentYearSales: "",
  previousYearSales: "",
  businessIsRunWithA: true,
  leaseAgreementUnder: "",
  propertyIsUnder: "",
  isLeaseAgreementUnderSelected: false,
  isPropertyIsUnderSelected: false,
  otherName: "",
  relationshipWithtThePrimaryApplicant: "",
  agreementPeriod: "",
  description: "",
  city: "",
  isShareholderAvailable: false,
  assets: [{ assetType: "", assetValue: "" }],
  partnershipDetails: [
    {
      partnershipD_name: "",
      partnershipD_oldNic: "",
      partnershipD_newNic: "",
      partnershipD_relationshipWithtThePrimaryApplicant: "",
      partnershipD_addressLine1: "",
      partnershipD_addressLine2: "",
      partnershipD_addressLine3: "",
      partnershipD_addressLine4: "",
      partnershipD_city: "",
      partnershipD_province: "",
      partnershipD_distric: "",
      partnershipD_postalCode: "",
    },
  ],
  individualDetails: [
    {
      individualD_name: "",
      individualD_oldNic: "",
      individualD_newNic: "",
      individualD_relationshipWithtThePrimaryApplicant: "",
      individualD_addressLine1: "",
      individualD_addressLine2: "",
      individualD_addressLine3: "",
      individualD_addressLine4: "",
      individualD_city: "",
      individualD_province: "",
      individualD_distric: "",
      individualD_postalCode: "",
    },
  ],
  directorDetails: [
    {
      directorD_name: "",
      directorD_oldNic: "",
      directorD_newNic: "",
      directorD_relationshipWithtThePrimaryApplicant: "",
      directorD_addressLine1: "",
      directorD_addressLine2: "",
      directorD_addressLine3: "",
      directorD_addressLine4: "",
      directorD_city: "",
      directorD_province: "",
      directorD_distric: "",
      directorD_postalCode: "",
    },
  ],
  shareholderDetails: [
    {
      shareholderD_name: "",
      shareholderD_oldNic: "",
      shareholderD_newNic: "",
      shareholderD_relationshipWithtThePrimaryApplicant: "",
      shareholderD_percentageOfShares: "",
      shareholderD_addressLine1: "",
      shareholderD_addressLine2: "",
      shareholderD_addressLine3: "",
      shareholderD_addressLine4: "",
      shareholderD_city: "",
      shareholderD_province: "",
      shareholderD_distric: "",
      shareholderD_postalCode: "",
    },
  ],
  address: {
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    addressLine4: "",
    city: "",
    province: "",
    district: "",
    dsDivision: "",
    gnDivision: "",
    postalCode: "",
  },
  taxData: {
    taxIdentificationNumber: "",
    taxDetails: [JSON.parse(JSON.stringify(taxDetailsObject))],
  },
  experience: { totalYearsOfExperience: null, totalMonthsOfExperience: null },
  isCertificationHave: null,
  natureOfBusiness: "",
};

export const defaultBusineeDetails = getCopy(defaultCurrentBusiness);

export const currentBusinessState = getCopy(defaultCurrentBusiness);

export const businessDataState = {
  currentBusiness: [JSON.parse(JSON.stringify(currentBusinessState))],
  // previousEmployment: [JSON.parse(JSON.stringify(currentBusinessState))],
  sectionErrorStatus: "pending",
};

const businessDetailState = JSON.parse(JSON.stringify(businessDataState));

export const personnelIncomeDataState = {
  incomeHolder: "",
  salaryAssignment: "",
  incomeSector: "",
  incomeSubSector: "",
  sourceOfIncome: "",
  salarySlipBasedOn: null,
  incomeFrequency: "",
  frequencyForMonth: "",
  frequencyForYear: "",
  basicSalary: "",
  averageVariableAllowance: "",
  fixedAllowance: "",
  lastAverageDeduction: "",
  salarySlipBasedOnYears: "",
  salarySlipBasedOnMonths: "",
  lastThreeAverageDeduction: "",
  markAsMainIncome: 0,
};

const personnelIncomeDetailsState = [
  JSON.parse(JSON.stringify(personnelIncomeDataState)),
];

export const businessIncomeDataState = {
  sourceOfIncomeBusiness: "",
  incomeFrequencyBusiness: "",
  incomeFrequencyForMonthBusiness: "",
  incomeFrequencyForYearBusiness: "",
  incomeBusiness: "",
};
export const businessExpenseDataState = {
  expenseTypeBusiness: "",
  expenseFrequencyBusiness: "",
  expenseFrequencyForMonthBusiness: "",
  expenseFrequencyForYearBusiness: "",
  expenseBusiness: "",
};

const businessIncomeDetailsState = JSON.parse(
  JSON.stringify(businessIncomeDataState)
);
const businessExpenseDetailsState = JSON.parse(
  JSON.stringify(businessExpenseDataState)
);

export const cultivationIncomeDataState = {
  incomeTypeCultivation: "",
  incomeFrequencyCultivation: "",
  seasonalIncome: "",
};
export const cultivationExpenseDataState = {
  expenseTypeCultivation: "",
  expenseFrequencyCultivation: "",
  seasonalExpense: "",
};

const cultivationIncomeDetailsState = JSON.parse(
  JSON.stringify(cultivationIncomeDataState)
);
const cultivationExpenseDetailsState = JSON.parse(
  JSON.stringify(cultivationExpenseDataState)
);

export const incomeComparedDataState = JSON.parse(
  JSON.stringify({
    personnelIncome: personnelIncomeDetailsState,
    businessIncome: {
      income: [businessIncomeDetailsState],
      expense: [businessExpenseDetailsState],
    },
    cultivationIncome: {
      income: [cultivationIncomeDetailsState],
      expense: [cultivationExpenseDetailsState],
    },
  })
);

export const incomeState = {
  income: [],
  expense: [],
};

export const incomeDataState = JSON.parse(
  JSON.stringify({
    personnelIncome: [],
    businessIncome: JSON.parse(JSON.stringify(incomeState)),
    cultivationIncome: JSON.parse(JSON.stringify(incomeState)),
    selectedCategories: [],
    sectionErrorStatus: "pending",
  })
);

const incomeDetailsState = JSON.parse(JSON.stringify(incomeDataState));

export const expDefData = {
  expenseType: "",
  expenseFrequency: "",
  frequencyForMonth: "",
  frequencyForAnnual: "",
  expenseAmount: "",
};

export const expenseDataState = JSON.parse(
  JSON.stringify({
    numberOfDepends: "",
    presenceOfRegulatedFinSystem: "",
    expenses: [expDefData],
    sectionErrorStatus: "pending",
  })
);

const expenseDetailsState = JSON.parse(JSON.stringify(expenseDataState));

export const lifeInsuranceDataState = {
  insuranceCompany: "",
  insuredAmount: "",
  monthlyPremium: "",
  beneficiary: "",
  sectionErrorStatus: "pending",
};

const lifeInsuranceDetailsState = [
  JSON.parse(JSON.stringify(lifeInsuranceDataState)),
];

export const BankDataState = {
  bankName: "",
  branch: "",
  accountType: "",
  accountOwnership: "",
  accountName: "",
  accountNo: "",
  balance: "",
  accountOpenDate: null,
  maximumBalance: "",
  asAtDate: null,
  balanceAsAtDate: "",
  averageDepositLastSixMonths: "",
  averageWithdrawalLastSixMonths: "",
  relationshipWithRDB: "",
  sectionErrorStatus: "pending",
};

const bankDetailsState = [JSON.parse(JSON.stringify(BankDataState))];

export const assetsDataState = {
  addAssets: "",
  assetsCategory: "",
  assetType: "",
  ownership: "",
  extentOrRegNoOrOther: "",
  assetGrossValue: "",
  assetMarketValue: "",
  mortgageValue: "",
  assetDescription: "",
  sectionErrorStatus: "pending",
};

const assetsDetailsState = [JSON.parse(JSON.stringify(assetsDataState))];

export const individualTaxState = {
  taxIdentificationNumber: "",
  sectionErrorStatus: "pending",
  taxDetails: [JSON.parse(JSON.stringify(taxDetailsObject))],
};

const taxDetailsState = JSON.parse(JSON.stringify(individualTaxState));

export const analysisOfLoanRequirementDataObject = {
  analysisOfNeed: "",
};

const analysisOfLoanRequirementDataState = JSON.parse(
  JSON.stringify(analysisOfLoanRequirementDataObject)
);

export const risksConditionsAndGeneralFactsObject = {
  risks: "",
  conditions: "",
  factsAndRecommendations: "",
};

const risksConditionsAndGeneralFactsState = JSON.parse(
  JSON.stringify(risksConditionsAndGeneralFactsObject)
);

export const inquiryOfObligationsObject = {
  loanLease: [],
  creditCard: [],
  pawning: [],
  overdraft: [],
  totalLiabilityAmount: "",
  sectionErrorStatus: "pending",
  liablityCategory: "",
};

const inquiryOfObligationsState = JSON.parse(
  JSON.stringify(inquiryOfObligationsObject)
);

export const loanLeaseDataObject = {
  liabilityType: "",
  bank: "",
  amount: "",
  grantedDate: null,
  obligationType: "",
  debtee: "",
  installmentFrequency: "",
  installmentAmount: "",
  installmentLeft: "",
  currentOutstanding: "",
  security: "",
  securityType: "",
  valuation: "",
  moreInfo: "",
  statusOfLoan: "",
  moreInfoOnRepaymentLoan: "",
};

const loanLeaseState = JSON.parse(JSON.stringify(loanLeaseDataObject));

export const creditCardObject = {
  bank: "",
  typeOfCard: "",
  limit: "",
  currentOutstanding: "",
};

const creditCardState = JSON.parse(JSON.stringify(creditCardObject));

export const pawningObject = {
  bank: "",
  originalAmount: "",
  outstanding: "",
  annualInterest: "",
  matureDate: null,
};

const pawningState = JSON.parse(JSON.stringify(pawningObject));

export const overdraftObject = {
  bank: "",
  limitOfOverdraft: "",
  outstanding: "",
  expiryDate: null,
  securityCategory: "",
};

const overdarftState = JSON.parse(JSON.stringify(overdraftObject));

export const inquiryOfObligationsComparedObject = JSON.parse(
  JSON.stringify({
    loanLease: [loanLeaseState],
    creditCard: [creditCardState],
    pawning: [pawningState],
    overdraft: [overdarftState],
    obligations: [
      {
        bankName: "",
        branch: "",
        obligationType: "",
        debtee: "",
        loanAmount: "",
        grantedDate: null,
        balance: "",
        installment: "",
      },
    ],
    chequeReturn: [
      {
        year: "",
        numberOfChecksReturns: "",
        valueOfReturnedCheques: "",
      },
    ],
    totalLiabilityAmount: "",
    sectionErrorStatus: "pending",
  })
);

export const previousBorrowingsStateObject = {
  bank: "",
  branch: "",
  loanScheme: "",
  loanAmount: "",
  loanPurpose: "",
  grantedDate: null,
  settledDate: null,
  securityAvailability: "",
  securityType: "",
  currentArrears: "",
  moreInfoAbtRepayment: "",
};

const previousBorrowingsState = [
  JSON.parse(JSON.stringify(previousBorrowingsStateObject)),
];

export const securityDataState = JSON.parse(
  JSON.stringify({
    securityType: "",
    securitySubType: "",
    guarantors: [],
    paperDocs: {
      loanProtection: [],
      lifeInsurance: [],
      fdAgainstLoans: [],
      warehouseReceipt: [],
      treasuryBond: [],
      shares: [],
    },
    movable: {
      pledge: [],
      stockAsGold: [],
      machineryEquipment: [],
      vehicle: [],
    },
    immovable: {
      property: [],
      immovableMachineryEquipement: [],
    },
    sectionErrorStatus: "pending",
  })
);

const securityDetailState: any = JSON.parse(JSON.stringify(securityDataState));
/**
 * Ending App form main section state ------------------------------------------------------------
 */

const appFormRequestId = null;
//----------------------------------------------------------------------------------------

const masterDataState = {};

const appFormBuilderSavingState = false;

const latestApplicationDataState = {};

const lastEditedSectionState = appStrings?.mainSectionKeys?.personalData;

const otherParticipantState = {
  isSectionCollapsed: true,
  sectionErrorStatus: "pending",
  [appStrings?.mainSectionKeys?.personalData]: JSON.parse(
    JSON.stringify(personalDataState)
  ),
  [appStrings?.mainSectionKeys?.contactData]: JSON.parse(
    JSON.stringify(contactDataState)
  ),
  [appStrings?.mainSectionKeys?.addressData]: JSON.parse(
    JSON.stringify(addressDataState)
  ),
  [appStrings?.mainSectionKeys?.educationData]: JSON.parse(
    JSON.stringify(educationDataState)
  ),
  [appStrings?.mainSectionKeys?.employedData]: JSON.parse(
    JSON.stringify(employedDetailState)
  ),
  [appStrings?.mainSectionKeys?.businessData]: JSON.parse(
    JSON.stringify(businessDetailState)
  ),
  [appStrings?.mainSectionKeys?.incomeData]: JSON.parse(
    JSON.stringify(incomeDataState)
  ),
  [appStrings?.mainSectionKeys?.expenseData]: JSON.parse(
    JSON.stringify(expenseDataState)
  ),
  [appStrings?.mainSectionKeys?.bankData]: JSON.parse(
    JSON.stringify([BankDataState])
  ),
  [appStrings?.mainSectionKeys?.assetsData]: JSON.parse(
    JSON.stringify([assetsDataState])
  ),
  [appStrings?.mainSectionKeys?.lifeInsuranceData]: JSON.parse(
    JSON.stringify([lifeInsuranceDataState])
  ),
  [appStrings?.mainSectionKeys?.taxDetailsData]: JSON.parse(
    JSON.stringify(individualTaxState)
  ),
  [appStrings?.mainSectionKeys?.inquiryOfObligationsData]: JSON.parse(
    JSON.stringify(inquiryOfObligationsObject)
  ),
};
const defaultSupplementaryCard = {
  isSectionCollapsed: true,
  sectionErrorStatus: "pending",
  [appStrings?.mainSectionKeys?.personalData]: JSON.parse(
    JSON.stringify(personalDataState)
  ),
  [appStrings?.mainSectionKeys?.addressData]: JSON.parse(
    JSON.stringify(addressDataState)
  ),
};

export const otherParticipantCompareState = {
  isSectionCollapsed: true,
  [appStrings?.mainSectionKeys?.personalData]: JSON.parse(
    JSON.stringify(personalDataState)
  ),
  // [appStrings?.mainSectionKeys?.creditData]: JSON.parse(JSON.stringify(creditDataState)),
  [appStrings?.mainSectionKeys?.contactData]: JSON.parse(
    JSON.stringify(contactDataState)
  ),
  [appStrings?.mainSectionKeys?.addressData]: JSON.parse(
    JSON.stringify(addressDataState)
  ),
  [appStrings?.mainSectionKeys?.educationData]: JSON.parse(
    JSON.stringify(educationDataState)
  ),
  [appStrings?.mainSectionKeys?.employedData]: JSON.parse(
    JSON.stringify(employedDetailState)
  ),
  [appStrings?.mainSectionKeys?.businessData]: JSON.parse(
    JSON.stringify(businessDetailState)
  ),
  [appStrings?.mainSectionKeys?.incomeData]: JSON.parse(
    JSON.stringify(incomeComparedDataState)
  ),
  [appStrings?.mainSectionKeys?.expenseData]: JSON.parse(
    JSON.stringify(expenseDataState)
  ),
  [appStrings?.mainSectionKeys?.bankData]: JSON.parse(
    JSON.stringify([BankDataState])
  ),
  [appStrings?.mainSectionKeys?.assetsData]: JSON.parse(
    JSON.stringify([assetsDataState])
  ),
  [appStrings?.mainSectionKeys?.lifeInsuranceData]: JSON.parse(
    JSON.stringify([lifeInsuranceDataState])
  ),
  [appStrings?.mainSectionKeys?.taxDetailsData]: JSON.parse(
    JSON.stringify(individualTaxState)
  ),
  [appStrings?.mainSectionKeys?.inquiryOfObligationsData]: JSON.parse(
    JSON.stringify(inquiryOfObligationsComparedObject)
  ),
};

const guarantorDetailsState = [
  JSON.parse(JSON.stringify(otherParticipantState)),
];

const jointBorrowerDetailsState = [
  JSON.parse(JSON.stringify(otherParticipantState)),
];

export const resetSupplementaryCardState = [
  JSON.parse(JSON.stringify(defaultSupplementaryCard)),
];
export const defaultSupplementaryCardState = [
  JSON.parse(JSON.stringify(defaultSupplementaryCard)),
];

const defaultNonRelatedReference = {
  primaryTitle: "",
  initialsInFull: "",
  primaryLastName: "",
  initials: "",
  gender: "",
  dob: "",
  age: "",
  contactNo: "",
  sectionErrorStatus: "pending",
}

export const defaultNonRelatedReferenceState = getCopy(defaultNonRelatedReference);

export const resetDefaultNonRelatedReferenceState = getCopy(defaultNonRelatedReference);

export const nonRelatedReferenceState = getCopy(defaultNonRelatedReference);

const defaultAutoSettleCCBills = {
  accountNumber: "",
  settlementAmount: "",
  sectionErrorStatus: "pending",
}

export const resetAutoSettleCCBillsState = getCopy(defaultAutoSettleCCBills);
export const defaultautoSettleCCBillsState = getCopy(defaultAutoSettleCCBills);

export const paperDocsDefaultState = {
  insuranceCompanyCode: "",
  issuingAuthority: "",
  insuranceAgreementPolicy: "",
  registrationDate: null,
  policyNumber: "",
  documentNumber: "",
  noOfDocuments: "",
  faceValue: "",
  dueDate: null,
  description: "",
};

export const securityDataStateStructure = JSON.parse(
  JSON.stringify({
    securityType: "",
    securitySubType: "",
    guarantors: [JSON.parse(JSON.stringify(otherParticipantCompareState))],
    // paperDocs: [JSON.parse(JSON.stringify(paperDocsDefaultState))],
    paperDocs: {
      loanProtection: [JSON.parse(JSON.stringify(loanProtectInsurance))],
      lifeInsurance: [JSON.parse(JSON.stringify(lifeInsuranceDefault))],
      shares: [JSON.parse(JSON.stringify(sharesDefault))],
      treasuryBond: [JSON.parse(JSON.stringify(treasuryBondDefault))],
      warehouseReceipt: [JSON.parse(JSON.stringify(warehouseReceiptDefault))],
      fdAgainstLoans: [JSON.parse(JSON.stringify(fdAgainstLoansDefault))],
    },
    movable: {
      vehicle: [JSON.parse(JSON.stringify(vehicleDefault))],
      pledge: [JSON.parse(JSON.stringify(pledgeDefault))],
      stockAsGold: [JSON.parse(JSON.stringify(stockAsGoldDefault))],
      machineryEquipment: [
        JSON.parse(JSON.stringify(machineryEquipmentDefault)),
      ],
    },
    immovable: {
      property: [JSON.parse(JSON.stringify(propertyDefault))],
      immovableMachineryEquipement: [
        JSON.parse(JSON.stringify(immovableMachineryEquipementDefault)),
      ],
    },
  })
);
export const mandatoryValidationMsgStructure = JSON.parse(
  JSON.stringify({
    "primary-applicant-details": [],
    "credit-details": [],
    "eduction-details": [],
    "employment-details": [],
    "contact-details": [],
    "address-details": [],
    "education-details": [],
    "income-details": [],
    "expenses-details": [],
    "bank-details": [],
    "assets-details": [],
    // 'security-details': [],
    // 'joint-borrower-details': [],
    "tax-details": [],
    "analysis-of-the-loan-requirement": [],
    "risk-conditions-general-facts": [],
    "inquiry-of-obligations": [],
    "reports-previous-borrowings": [],
    "business-details": [],
    "life-insurance-details": [],
  })
);

const mandatoryValidationErrorMessages = JSON.parse(
  JSON.stringify({
    ...mandatoryValidationMsgStructure,
    "security-details": [
      {
        "paper-doc": [],
        guarantor: [],
      },
    ],
    "joint-borrower-details": [],
    "security-details-parent": [],
    "joint-borrower-details-parent": [],
  })
);

const currentExpandSection = {
  participant: null,
  section: 1,
  key: "primary-applicant-details",
};

const agricultureDetails = {
  primaryApplicantData: [],
  jointBorrowerData: [],
  guarantorData: [],
};

export const defaultAgricultureDetails = JSON.parse(
  JSON.stringify(agricultureDetails)
);
const agricultureDetailsState: AgricultureDetailsState = JSON.parse(
  JSON.stringify(agricultureDetails)
);

export {
  additionalDetailState,
  addressDetailsState,
  agricultureDetailsState,
  analysisOfLoanRequirementDataState,
  appFormBuilderSavingState,
  appFormRequestId,
  appState,
  assetsDetailsState,
  bankDetailsState,
  businessDetailState,
  businessExpenseDetailsState,
  businessIncomeDetailsState,
  contactDetailState,
  creditCardState,
  creditDetailsState,
  cultivationExpenseDetailsState,
  cultivationIncomeDetailsState,
  currentExpandSection,
  educationDetailsState,
  employedDetailState,
  employedDetailState1,
  expenseDetailsState,
  guarantorDetailsState,
  incomeDetailsState,
  inquiryOfObligationsState,
  isPartialFrom,
  jointBorrowerDetailsState,
  lastEditedSectionState,
  latestApplicationDataState,
  lifeInsuranceDetailsState,
  loanLeaseState,
  mandatoryValidationErrorMessages,
  masterDataState,
  otherParticipantState,
  overdarftState,
  pawningState,
  personalDetailState,
  personnelIncomeDetailsState,
  previousBorrowingsState,
  risksConditionsAndGeneralFactsState,
  sectionExpandState,
  securityDetailState,
  selfEmployedDetailsState,
  taxDetailsState
};

