import { Box, Grid } from "@mui/material";

// Typo
import Typography from "@mui/material/Typography";
import { ChipsComponent } from "../../../../InputComponents/ChipsComponent/ChipsComponent";
const GridContentItem = ({ title, value, alignType = "right" }) => {
  return (
    <>
      <Grid item xs={12} mb={0.5}>
        <Typography variant="body2">
          <Box sx={{ fontWeight: "light" }}>{title}</Box>
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: alignType }} pr={1}>
        <Typography variant="body2">{value ? value : "N/A"}</Typography>
      </Grid>
    </>
  );
};

export const LoanRequirementGridContentItem = ({ title, value, alignType = "right" }) => {
  return (
    <>
      <Grid item xs={6}>
        <Typography>
          <Box sx={{ fontWeight: "light" }}>{title}</Box>
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: alignType }} p={1}>
        <Typography variant="h6">{value}</Typography>
      </Grid>
    </>
  );
};

export const TaskGridContentItem = ({ title, value, alignType = "right" }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="caption">{title}</Typography> : <Typography variant="caption">{value}</Typography>
      </Grid>
    </>
  );
};

export const IncomeGridContentItem = ({ title, value, alignType = "right" }) => {
  return (
    <>
      <Grid item xs={6}>
        <Typography>
          <Box sx={{ fontWeight: "light" }}>{title}</Box>
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: alignType }} p={1}>
        <Typography variant="body1">{value}</Typography>
      </Grid>
    </>
  );
};

export const GridLightTextTitle = ({ title, alignType = "left" }) => {
  return (
    <>
      <Typography variant="body2">
        <Box sx={{ fontWeight: "light" }}>{title}</Box>
      </Typography>
    </>
  );
};

export const GridLightTextTitleForAbility = ({ title, alignType = "left" }) => {
  return (
    <>
      <Typography variant="body2">
        <Box sx={{ fontWeight: "light" }}>{title}</Box>
      </Typography>
    </>
  );
};

export const SectionContentTitle = ({ title }) => {
  return (
    <>
      <Typography variant="h6">{title}</Typography>
    </>
  );
};

export const SectionContentType = ({ type }) => {
  return <>{type && <ChipsComponent label={<Typography variant="caption"> {type} </Typography>} size="medium" />}</>;
};

export const CreditHeaderPersonalDataInline = ({ title, value }) => {
  return (
    <>
      <Typography variant="body2" mr={0.5}>
        <Box sx={{ fontWeight: "light" }}>{title}: </Box>
      </Typography>
      <Typography variant="body2" gutterBottom>
        {value}
      </Typography>
    </>
  );
};

export const GridLightTextTitleInSalaryDeduction = ({ title, alignType = "left" }) => {
  return (
    <>
      <Typography variant="body2" mt={2}>
        <Box sx={{ fontWeight: "light" }}>{title}</Box>
      </Typography>
    </>
  );
};

export default GridContentItem;
