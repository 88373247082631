import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import { creditComponentTitleMapping } from "../../../../../configs/constants/contants";

export const SummaryAccordion = ({
  handleAccordionExpand,
  componentId,
  componentMapping,
  otherParams,
  expanded,
}) => {
  return (
    <Accordion
      key={componentId}
      expanded={expanded == componentId}
      onChange={handleAccordionExpand(componentId)}
      sx={{ marginBottom: 2 }}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: "#e6f0fa", height: '10px' }}
      >
        <Grid item xs>
          <div className="basic-font basic-font-color-bold font-size-16 padding-m align-left">
            {creditComponentTitleMapping[componentId]}
          </div>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {componentId &&
          componentMapping[componentId] &&
          React.createElement(componentMapping[componentId], otherParams)}
      </AccordionDetails>
    </Accordion>
  );
};
