import CloseIcon from '@mui/icons-material/Close';
import { Divider, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { ButtonComponent } from "../../components/InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../components/OtherComponents/GlobalModal/GlobalModal";
import Branches from "../UserConfigs/Components/Branches";
import Extra from "../UserConfigs/Components/Extra";
import PrivilegeGroups from "../UserConfigs/Components/PrivilegeGroups";
import UserRegisterLayout from "./UserRegisterLayout";

export default (props) => {
    const [screenMode, setScreenMode]: any = useState(0);
    const [userId, setUserId]: any = useState(0);
    const existingUserId = (props?.userDetail?.USER_ID || props?.userDetail?.userId);

    const [screens]: any = useState({
        0: () => <RegisterUser />,
        1: () => <AssignUserGroup />,
        2: () => <PermittedUserBranch />,
        3: () => <UserDLA />
    });
    const subtitles = {
        0: existingUserId ? `Update user (${existingUserId})` : "Create new user",
        1: existingUserId ? `Assign group(s) to user (${existingUserId})` : "Assign registered user to group",
        2: existingUserId ? `Assign permitted branche(s) to user (${existingUserId})` : "Assign permitted branches",
        3: existingUserId ? `Assign user DLA limits to user (${existingUserId})` : "Assign user DLA limits"
    };

    const RegisterUser = () => {
        const updateUser = (_user) => {
            for (const [key, value] of Object.entries(_user || {})) {
                props.userDetail[key] = value;
            }
            const userId = _user?.USER_ID || _user?.userId;
            if (userId) {
                setUserId(userId);
                setScreenMode(1);
            }
        };

        return <UserRegisterLayout userDetail={props.userDetail} callBackOnAction={updateUser} nOption={true}></UserRegisterLayout>;
    }

    const AssignUserGroup = () => {
        const userId = props?.userDetail?.USER_ID || props?.userDetail?.userId;
        const assignGroups = () => { return true; };

        return <PrivilegeGroups user={userId} willAssignGroups={assignGroups} />;
    }

    const PermittedUserBranch = () => {
        const userId = props?.userDetail?.USER_ID || props?.userDetail?.userId;
        const assignBranches = () => { return true; };

        return <Branches user={userId} willAssignBranches={assignBranches} />;
    }

    const UserDLA = () => {
        const userId = props?.userDetail?.USER_ID || props?.userDetail?.userId;

        return <Extra user={userId} />;
    }


    return <Stack>
        <Stack direction={'row'}>
            <Stack flex={1}>
                <Typography>User Registration Wizard</Typography>
                <Typography variant="caption" color={'gray'}>{subtitles[screenMode]} </Typography>
            </Stack>
            <Stack p={1} onClick={() =>
                closeGlobalModal()} >
                <CloseIcon />
            </Stack>
        </Stack>
        <Divider sx={{ marginTop: "10px" }} />
        <Stack height={'calc(100vh - 300px)'} sx={{ overflowY: 'auto' }}>
            {screens[screenMode]()}
        </Stack>
        <Divider />
        <Stack mt={2}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                {screenMode === 0 ? <Stack></Stack> : <ButtonComponent title="Back"
                    variant="outlined"
                    onClick={() => {
                        let nextScreen = screenMode - 1;
                        if (nextScreen === -1) {
                            nextScreen = 0;
                        } else {
                            setScreenMode(nextScreen);
                        }
                    }}
                    disabled={false} />}
                {screenMode === (Object.keys(screens).length - 1) ? <ButtonComponent title="Done"
                    variant="contained"
                    onClick={() => {
                        closeGlobalModal();
                    }}></ButtonComponent> : <ButtonComponent title="Next"
                        variant="contained"
                        onClick={() => {
                            let nextScreen = screenMode + 1;
                            if (Object.keys(screens).length === nextScreen) {
                                nextScreen = Object.keys(screens).pop();
                            } else {
                                setScreenMode(nextScreen);
                            }
                        }}
                        disabled={!userId}></ButtonComponent>}
            </Stack>
        </Stack>
    </Stack>;
}