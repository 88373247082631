import { Downgraded, useHookstate } from '@hookstate/core';
import { Box, Button, Stack, Typography } from "@mui/material";
import { deleteAllJointBorrowersFromDTL } from '../../../../../services/applicationApis';
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";

const DeleteJointBorrowerModal = ({ jointBorrowerState, requestId, callBackOnAction, supplementaryCard = false }) => {
    let addedJbs = jointBorrowerState.attach(Downgraded).get();
    let id = supplementaryCard ? "supplementaryCard" : "jointBorrowerData";
    let title = supplementaryCard ? "Supplementary Card" : "Joint Borrower";
    let obj = { type: id, requestId };
    let isLoading = useHookstate(false);

    const handleDelete = async () => {
        isLoading.set(true);
        try {
            jointBorrowerState.set([]);
            await deleteAllJointBorrowersFromDTL(obj);
        } catch (error) {

        } finally {
            isLoading.set(false);
            closeGlobalModal();
            callBackOnAction(true)
        }
    }

    return <Stack width={"100%"}>
        <Stack direction={'row'} pt={2} pr={2}>
            <Stack flex={1}>
                <Typography variant='h6'>Data Loss Alert</Typography>
            </Stack>
        </Stack>
        <Stack spacing={2} py={2} px={1}>
            <Box>
                <Typography variant='subtitle1'>
                    By Clicking on <span style={{ fontWeight: "bold" }}>"Proceed"</span> all the data entered for the following {title}(s) will be removed.
                </Typography>
                {
                    addedJbs.map((currJb, index) => {
                        let countNo: string = index + 1 < 10 ? `( 0${index + 1} )` : `( ${index + 1} )`;
                        let { personalData: { oldNic, newNic, initialsInFull } } = currJb
                        let nic = newNic ? newNic : oldNic ? oldNic : "";
                        let itemString: string = `${title} ${countNo}: ${initialsInFull}`;

                        if (nic) {
                            itemString = `${itemString} - (${nic})`;
                        }
                        return <Typography variant='subtitle2' px={1} pt={1}>{itemString}</Typography>
                    })
                }
                <Typography variant='subtitle1' pt={2}>
                    To keep the data click on <span style={{ fontWeight: "bold" }}>"Cancel"</span>
                </Typography>
            </Box>

        </Stack>

        <Stack direction={"row"} spacing={2} justifyContent={"flex-end"} pt={3}>
            <Button disabled={isLoading.get()} variant='outlined' onClick={() => {
                closeGlobalModal();
                callBackOnAction(false)
            }}> Cancel</Button>
            <Button disabled={isLoading.get()} variant='contained' onClick={() => handleDelete()}>Proceed</Button>
        </Stack>



    </Stack>


}

export default DeleteJointBorrowerModal;