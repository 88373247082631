import { useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { userDetails } from "../../../../../configs/mainStore";
import { closeBtn } from "../../../../../styles";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";

const PredisbursementCustomModal = (params) => {
  const initialData = { type: "", title: "", description: "" };
  const showErrors = useState(false);
  const predisbursementState: any = useState(initialData);
  const loginUser = useState(userDetails);
  const { productType, sector, scheme } = params;

  const addCondition = async () => {
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      let productCatelog = "";
      if (productType != "" && sector != "" && scheme != "") {
        productCatelog = `${productType}-${sector}-${scheme}`;
      }

      const conditonObj = {
        COND_DESCRIPTION: predisbursementState?.description.get(),
        COND_KEY: "",
        CREATED_BY: loginUser?.userId?.get(),
        DESCRIPTION: predisbursementState?.description.get(),
        F_DESC: predisbursementState?.description.get(),
        ID: 0,
        PLACEHOLDERS: "{}",
        PRODUCT_CATALOG: productCatelog,
        PRODUCT_TYPE_ID: productType,
        STATUS: "",
        MAPPING_ID: new Date().getTime(),
        TITLE: predisbursementState?.title?.get(),
      };
      const dataArr: any[] = [];
      dataArr.push(conditonObj);

      let obj = {
        predisbursementList: dataArr,
        applicationId: params?.applicationId,
      };

      if (params.addItem) {
        params.addItem(obj);
      }

      closeGlobalModal();
    }
  };

  const closeCondition = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return predisbursementState.title.get() === "" || predisbursementState.description.get() === "";
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Create Approving Condition</div>
      </Stack>
      <Grid container spacing={2} mt={4} className="modal-text-field" px={2}>
        <Stack width={"100%"}>
          <Typography variant="subtitle2" color={"gray"}>
            Condition Title <span className="error">*</span>
          </Typography>
          <TextBoxComponent
            value={predisbursementState?.title?.get()}
            onChange={(e) => {
              resetError();
              predisbursementState.title.set(e.target.value);
            }}
            variant="outlined"
            size="small"
            multiline={true}
            minRows={1}
          />
        </Stack>

        <Stack width={"100%"} mt={2}>
          <Typography variant="subtitle2" color={"gray"}>
            Condition Description <span className="error">*</span>
          </Typography>
          <TextBoxComponent
            value={predisbursementState?.description.get()}
            onChange={(e) => {
              resetError();
              predisbursementState.description.set(e.target.value);
            }}
            variant="outlined"
            size="small"
            multiline={true}
            minRows={4}
          />
        </Stack>
      </Grid>
      {showErrors.get() && (
        <Typography variant="caption" className="error">
          Title and Description required to proceed
        </Typography>
      )}

      <Stack direction="row" spacing={2} justifyContent="end" pt={3} mt={2} pr={2} pb={1}>
        <ButtonComponent title="Close" startIcon={<CloseIcon />} variant="contained" onClick={closeCondition} loadingbtn={true} style={closeBtn} />
        <ButtonComponent startIcon={<DoneIcon />} title="Add" variant="contained" onClick={addCondition} loadingbtn={true} color="info" />
      </Stack>
    </Box>
  );
};

export default PredisbursementCustomModal;
