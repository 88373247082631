import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

function Body({ data, typeCaseClass }) {
  const personalData = data?.formData?.personalData;
  const jointBorrowerData = data?.formData?.jointBorrowerData || [];
  const guarantorData = data?.formData?.securityData?.guarantors || [];

  const renderNicDetails = (type = "", item: any = {}) => {
    let nic = "";
    if (type == "") {
      return null;
    }

    if (type == "PA") {
      if (personalData.newNic != null && personalData.newNic != "") {
        nic = `${personalData.newNic}`;
      } else if (personalData.oldNic != null && personalData.oldNic != "") {
        nic = `${personalData.oldNic}`;
      }
    } else if (type == "JB") {
      if (item?.personalData?.newNic != null && item?.personalData?.newNic != "") {
        nic = `${item?.personalData?.newNic}`;
      } else if (item?.personalData?.oldNic != null && item?.personalData?.oldNic != "") {
        nic = `${item?.personalData?.oldNic}`;
      } else {
        nic = "";
      }
    } else if (type == "GR") {
      if (item?.personalData?.newNic != null && item?.personalData?.newNic != "") {
        nic = `${item?.personalData?.newNic}`;
      } else if (item?.personalData?.oldNic != null && item?.personalData?.oldNic != "") {
        nic = `${item?.personalData?.oldNic}`;
      } else {
        nic = "";
      }
    }

    return nic;
  };

  return (
    <Grid container xs={24}>
      <Grid item xs={24}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow sx={{ border: 1 }}>
                <TableCell sx={{ border: 1, borderColor: " #000000" }} colSpan={2} width={"50%"}>
                  <Grid mb={1.25}>Full Name of the Customer</Grid>
                </TableCell>
                <TableCell sx={{ border: 1, borderColor: " #000000" }} width={"30%"}>
                  <Grid mb={1.25}>NIC No</Grid>
                </TableCell>
                <TableCell sx={{ border: 1, borderColor: " #000000" }} width={"20%"}>
                  <Grid mb={1.25}>Specimen Signature</Grid>
                </TableCell>
              </TableRow>
              <TableRow sx={{ border: 1 }}>
                <TableCell sx={{ border: 1, borderColor: " #000000" }} width={"5%"}>
                  <Grid mb={1.25}>1</Grid>
                </TableCell>
                <TableCell sx={{ border: 1, borderColor: " #000000" }} width={"45%"}>
                  <Grid mb={1.25} className={`security-doc-type-variable ${typeCaseClass}`}>
                    {`${personalData?.initialsInFull} ${personalData?.primaryLastName}`}
                  </Grid>
                </TableCell>
                <TableCell sx={{ border: 1, borderColor: " #000000" }} width={"30%"}>
                  <Grid mb={1.25} className={`security-doc-type-variable ${typeCaseClass}`}>
                    {renderNicDetails("PA")}
                  </Grid>
                </TableCell>
                <TableCell sx={{ border: 1, borderColor: " #000000" }} width={"20%"}>
                  <Grid mb={1.25}></Grid>
                </TableCell>
              </TableRow>

              {jointBorrowerData.length > 0 &&
                jointBorrowerData?.map((item, i) => {
                  return (
                    <>
                      <TableRow sx={{ border: 1 }}>
                        <TableCell sx={{ border: 1, borderColor: " #000000" }}>
                          <Grid mb={1.25}>{i + 2}</Grid>
                        </TableCell>
                        <TableCell sx={{ border: 1, borderColor: " #000000" }}>
                          <Grid mb={1.25} className={`security-doc-type-variable ${typeCaseClass}`}>
                            {`${item?.personalData?.initialsInFull + " " + item?.personalData?.primaryLastName ?? ""}`}
                          </Grid>
                        </TableCell>
                        <TableCell sx={{ border: 1, borderColor: " #000000" }}>
                          <Grid mb={1.25} className={`security-doc-type-variable ${typeCaseClass}`}>
                            {renderNicDetails("JB", item)}
                          </Grid>
                        </TableCell>
                        <TableCell sx={{ border: 1, borderColor: " #000000" }}>
                          <Grid mb={1.25}></Grid>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {guarantorData.length > 0 && (
        <Grid item xs={24} mt={3}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableBody>
                <TableRow sx={{ border: 1 }}>
                  <TableCell sx={{ border: 1, borderColor: " #000000" }} colSpan={2} width={"50%"}>
                    <Grid mb={1.25}>Full Name of the Guarantor</Grid>
                  </TableCell>
                  <TableCell sx={{ border: 1, borderColor: " #000000" }} width={"30%"}>
                    <Grid mb={1.25}>NIC No</Grid>
                  </TableCell>
                  <TableCell sx={{ border: 1, borderColor: " #000000" }} width={"20%"}>
                    <Grid mb={1.25}>Specimen Signature</Grid>
                  </TableCell>
                </TableRow>

                {guarantorData.length > 0 &&
                  guarantorData?.map((item, i) => {
                    return (
                      <>
                        <TableRow sx={{ border: 1 }}>
                          <TableCell sx={{ border: 1, borderColor: " #000000" }} width={"5%"}>
                            <Grid mb={1.25}>{i + 1}</Grid>
                          </TableCell>
                          <TableCell sx={{ border: 1, borderColor: " #000000" }} width={"45%"}>
                            <Grid mb={1.25} className={`security-doc-type-variable ${typeCaseClass}`}>{`${item?.personalData?.initialsInFull + " " + item?.personalData?.primaryLastName ?? ""}`}</Grid>
                          </TableCell>
                          <TableCell sx={{ border: 1, borderColor: " #000000" }} width={"30%"}>
                            <Grid mb={1.25} className={`security-doc-type-variable ${typeCaseClass}`}>
                              {renderNicDetails("GR", item)}
                            </Grid>
                          </TableCell>
                          <TableCell sx={{ border: 1, borderColor: " #000000" }} width={"20%"}>
                            <Grid mb={1.25}></Grid>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}

      <Grid item xs={24} mt={3} mb={1}>
        We confirm that the signature/s of the above Customer/s was/were checked and verified with the Signature Card/s.
        <br />
        <br />
        {guarantorData.length > 0 && <>Further, we confirm that the signature/s of the above Guarantor/s was/were checked and verified with the Signature Card/s and/or Guarantor Form/s.</>}
      </Grid>
    </Grid>
  );
}

export default Body;
