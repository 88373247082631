import { educationAwardState } from "../../../configs/stores/applicationForm";
import { messageMappings } from "./messageMapping";
import { handleArray, validateWithStructure } from "./structureValidation";

export const educationDetails = (structure, state, isGuarantor = false, isJointBorrower = false, participantState, isSecuritySectionNext?) => {
  let finalKeyArr = [];
  let errorArray = validateWithStructure(structure, state);
  // console.log("=== errorArray - educationData ===", errorArray);

  if (errorArray?.length > 0) {
    finalKeyArr = errorArray;
  }

  let errArrInArrays = handleArray(structure, state?.awardTypes, [educationAwardState]);

  if (errArrInArrays?.length > 0) {
    finalKeyArr = errorArray.concat(errArrInArrays);
  }

  // console.log("=== structure - educationData ===", structure);
  // console.log("ERROR ARRAY - educationData => ", finalKeyArr);

  const preparedErrorMessages = messageMappings(finalKeyArr);
  return preparedErrorMessages;
};
