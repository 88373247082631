import { Downgraded, useState } from "@hookstate/core";
import CallIcon from "@mui/icons-material/Call";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { nextBtnStyles } from "../../../../../configs/constants/styleValues";
import { creditData, userDetails } from "../../../../../configs/mainStore";
import { downloadCmsContracts, downloadCollectionHistories } from "../../../../../services/collectionHistories";
import { select_basic } from "../../../../../styles";
import { dateFormatWithDate, timeFormatWithGmtZero } from "../../../../../utility/fomatters";
import { StyledButton } from "../../../../InputComponents/ButtonComponent/StyledButton";
import { ChipsComponent } from "../../../../InputComponents/ChipsComponent/ChipsComponent";
import { SelectComponent } from "../../../../InputComponents/SelectComponent/SelectComponent";
import { addToaster } from "../../../GlobalToast";
import { InputGrid } from "../../../InputGrid/InputGrid";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { cmsHistroyStylings } from "../../../WorkflowComponents/utils";
import { IconRowComponent } from "../IconRowComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";
import { ErrorMessageComponent } from "../UserCreditComponents/ErrorMessageComponent";

const CMSHistory = ({ data, innerComponentData, applicationId }) => {
  const [cmsHistoryItems, setCmsHistoryItems]: any = React.useState([]);
  const [nicDetails, setNicDetails]: any = React.useState([]);
  const [contractDetails, setContractDetails]: any = React.useState([]);
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  const [isLoading, setIsLoading]: any = React.useState(false);
  const [isLoadingContracts, setIsLoadingContracts]: any = React.useState(false);

  const [customerNIC, setCustomerNIC] = React.useState("");
  const [loanMasterId, setLoanMasterId] = React.useState("");
  const [isButtonClicked, setIsButtonClicked] = React.useState(false);
  const creditFileData: any = useState(creditData);

  useEffect(() => {
    extractNICDetails();
  }, []);

  useEffect(() => {
    if (customerNIC) {
      downloadCustomerContracts({ nic: customerNIC });
    }
  }, [customerNIC]);

  const extractNICDetails = () => {
    let nics: any = [];

    // extract primary applicant nic details
    const oldNIC = creditFileData.formData?.personalData?.oldNic?.get();
    if (oldNIC) {
      nics.push({
        id: oldNIC,
        name: `${oldNIC} (Primary Applicant)`,
      });
    }

    const newNIC = creditFileData.formData?.personalData?.newNic?.get();
    if (newNIC) {
      nics.push({
        id: newNIC,
        name: `${newNIC} (Primary Applicant)`,
      });
    }

    // extract JB nic details
    let index = 0;
    for (const jb of creditFileData.formData.jointBorrowerData?.get() || []) {
      index++;
      const newNIC = jb.personalData?.newNic;
      const oldNIC = jb.personalData?.oldNic;

      if (newNIC) {
        nics.push({
          id: newNIC,
          name: `${newNIC} (Joint Borrower ${index})`,
        });
      }
      if (oldNIC) {
        nics.push({
          id: oldNIC,
          name: `${oldNIC} (Joint Borrower ${index})`,
        });
      }
    }

    // extract GRT nic details
    index = 0;
    for (const guarantor of creditFileData.formData.securityData?.guarantors?.get() || []) {
      index++;
      const newNIC = guarantor?.personalData?.newNic;
      const oldNIC = guarantor?.personalData?.oldNic;

      if (newNIC) {
        nics.push({
          id: newNIC,
          name: `${newNIC} (Gurantor ${index})`,
        });
      }
      if (oldNIC) {
        nics.push({
          id: oldNIC,
          name: `${oldNIC} (Gurantor ${index})`,
        });
      }
    }

    setNicDetails(nics);
  };

  const fetchCollectionHistories = async (data) => {
    try {
      setIsLoading(true);

      const result = await downloadCollectionHistories(data);

      if (result !== null) {
        setCmsHistoryItems(result);
      } else {
        setCmsHistoryItems([]);
      }
    } catch (error: any) {
      addToaster({
        status: "error",
        title: "Error",
        message: "An error occured while fetching data",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const downloadCustomerContracts = async (data) => {
    try {
      setIsLoadingContracts(true);
      let result = await downloadCmsContracts(data);

      if (result) {
        result = result.map((obj) => {
          return { id: obj.id, name: `${obj.contractNo} (${obj.productName})` };
        });
        setContractDetails(result);
      }
    } catch (error: any) {
      addToaster({
        status: "error",
        title: "Error",
        message: "Failed to fetch customer contracts",
      });
    } finally {
      setIsLoadingContracts(false);
    }
  };

  const RenderNicDetails = () => {
    const handleSelectChange = (e) => {
      const selectedValue = e.target.value;
      setCustomerNIC(selectedValue);
    };

    const handleContractChange = (e) => {
      const selectedValue = e.target.value;
      setLoanMasterId(selectedValue);
    };

    return (
      <>
        <InputGrid visibility={1}>
          <SelectComponent sx={select_basic} label="Select NIC: " value={customerNIC} values={nicDetails} onChange={handleSelectChange} defaultPlaceholder={true} />
        </InputGrid>

        <InputGrid visibility={1} style={{ paddingLeft: "10px" }}>
          <SelectComponent endAdornment={isLoadingContracts ? <CircularProgressComponent size={25} /> : <></>} sx={select_basic} label="Select Contracts: " value={loanMasterId} values={contractDetails} onChange={handleContractChange} defaultPlaceholder={true} disabled={customerNIC === "" ? true : false} />
        </InputGrid>

        <Stack direction="row" pl={1}>
          <StyledButton
            styles={nextBtnStyles}
            onClick={() => {
              setIsButtonClicked(true);
              fetchCollectionHistories({ loanMasterId });
            }}
            title="Loan History"
          // disabled={savingState?.get()}
          />
        </Stack>
      </>
    );
  };

  return (
    <>
      <Grid container>
        <Stack className="full-width full-height">
          <InnerSectionHeaderComponent innerComponentData={innerComponentData} applicationId={applicationId} currentApplicationWfData={{}} disabled={isLoggedInUserNotTheAssignee}>
            <RenderNicDetails />
          </InnerSectionHeaderComponent>
        </Stack>
      </Grid>

      {isButtonClicked && (
        <Grid container className="full-width border-radius-5" alignItems="center" p={1} style={{ background: "#f8f9fa", maxHeight: "95%", overflow: "auto" }}>
          <Stack className="full-width full-height">
            {isLoading ? (
              <Stack direction={"row"} justifyContent={"center"} height={100} alignItems={"center"}>
                <CircularProgressComponent size={25} />
              </Stack>
            ) : (
              <CmsHistoryItemsProvider cmsHistoryItems={cmsHistoryItems} />
            )}
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default CMSHistory;

const CmsHistoryItemsProvider = ({ cmsHistoryItems }) => {
  if (cmsHistoryItems && cmsHistoryItems.length > 0) {
    return cmsHistoryItems.map((index: any, key) => {
      return (
        <Box m={1} mt={2} p={1} key={key}>
          <Stack direction={"column"}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack direction={"row"}>
                <IconRowComponent Icon={CallIcon} subTxt={""} mainTxt={""} color={"#7bacd7"} designation={""} />
                <Typography variant="subtitle1" sx={{ color: "#659be0" }}>
                  {index["title"]?.toUpperCase()}
                </Typography>
              </Stack>
              <Stack direction={"row"}>
                <ChipsComponent label={<Typography variant="caption">{index.type}</Typography>} size="medium" sx={cmsHistroyStylings("#659be0")} />

                <ChipsComponent label={<Typography variant="caption"> {index.parentalStatus} </Typography>} size="medium" sx={cmsHistroyStylings("#3fc7d4")} />
              </Stack>
            </Stack>
            <Stack direction={"row"}>
              <Typography variant="caption" sx={{ color: "#999999" }}>
                {`By : ${index["actionedBy"]}     
          ${dateFormatWithDate(index["dateAndTime"])}   
          ${timeFormatWithGmtZero(index["dateAndTime"])}`}
              </Typography>
            </Stack>
            <Stack direction={"row"}>
              <Typography variant="caption" sx={{ color: "#999999" }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: index["description"],
                  }}
                />
              </Typography>
            </Stack>
          </Stack>
          <Divider />
        </Box>
      );
    });
  }

  return <ErrorMessageComponent headMessage={""} errorMessage={"No Data Available"} />;
};
