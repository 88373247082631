import { useState } from "@hookstate/core";
import { Grid, Stack, Typography } from "@mui/material";
import { globalImageDocumentsState, masterDataDetails } from "../../../../../configs/mainStore";
import { fetchDocumentsWithPath } from "../../../../../utility/helpers/creditFileUtility";
import DetailItem from "../DetailItem";

const RelativeNotLivingWithYou = ({ data }) => {
    let personalData: any = data?.formData?.personalData;
    const masterData: any = useState(masterDataDetails);
    const title: any = masterData?.TITLE?.get()?.find((t) => t.id == personalData?.primaryTitle);
    const gender: any = masterData?.GENDER?.get()?.find((t) => t.id == personalData?.gender);

    return (
        <Stack>
            <Stack p={1} px={2} style={{ backgroundColor: "#e6f0fa" }}>
                <Typography variant="subtitle1" color={"gray"}>Details Of Relative Not Living With You</Typography>
            </Stack>
            <Grid container className="data-box" spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={1} rowGap={3}>
                        <Grid item xs={3}>
                            <DetailItem title="Title" value={title?.name ? title?.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={3}>
                            <DetailItem title="Initials in Full" value={personalData?.initialsInFull ? personalData?.initialsInFull : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={5}>
                            <DetailItem title="Last Name" value={personalData?.primaryLastName ? personalData?.primaryLastName : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={3}>
                            <DetailItem title="Used Name" value={personalData?.usedName ? personalData?.usedName : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={3}>
                            <DetailItem title="Initials" value={personalData?.initials ? personalData?.initials : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={3}>
                            <DetailItem title="Other Names" value={personalData?.primaryOtherName ? personalData?.primaryOtherName : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        {personalData?.oldNic && (
                            <Grid item xs={3} onClick={async () => {
                                const docs = await fetchDocumentsWithPath("NIC,NIC_BACK,NIC_FRONT");
                                globalImageDocumentsState.set(docs || "");
                            }} >
                                <DetailItem title="Old NIC" value={personalData.oldNic ? personalData?.oldNic : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                        )}
                        {personalData?.oldNicExp && (
                            <Grid item xs={3}>
                                <DetailItem title="Issued Date (DD/MM/YYYY)" value={personalData?.oldNicExp ? personalData.oldNicExp : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                        )}
                        {personalData?.newNic && (
                            <Grid item xs={3} onClick={async () => {
                                const docs = await fetchDocumentsWithPath("NIC,NIC_BACK,NIC_FRONT");
                                globalImageDocumentsState.set(docs || "");
                            }}>
                                <DetailItem title="New NIC" value={personalData.newNic ? personalData?.newNic : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                        )}
                        {personalData?.newNicExp && (
                            <Grid item xs={3}>
                                <DetailItem title="Issued Date (DD/MM/YYYY)" value={personalData?.newNicExp ? personalData.newNicExp : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                            </Grid>
                        )}
                        <Grid item xs={3}>
                            <DetailItem title="Gender" value={gender?.name ? gender?.name : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={3}>
                            <DetailItem title="Date of Birth (DD/MM/YYYY)" value={personalData?.dob ? personalData?.dob : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                        <Grid item xs={3}>
                            <DetailItem title="Age" value={personalData?.dob ? `${personalData?.years} Years and ${personalData?.months} Months` : "-"} valueFontClass="font-size-14" titleFontClass="font-size-12" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Stack>
    )
}

export default RelativeNotLivingWithYou