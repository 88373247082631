import moment from "moment";
import { MASTER_DATA_KEYS } from "../../../../../../../../../../configs/constants/masterDataKeys";
import MasterDataProvider from "../../../../../../../../../../utility/MasterDataProvider";
import { generateUniqueId, getCopy, inspectionDeductionsTypes } from "../../../../../../../../../../utility/util";
import { comprehensivePandL } from "../../../../Stores/MicroAppraisalDefaultData";
import { inspectionExpensesMap } from "../../../../Utilities/Util";

export const comprehensiveSetStateFromTheBusinessData = ({ state, busineeData }) => {
    const { revenuesDefault, reductionsDefault, expensesDefauls, depreciationDefault } = getCopy(comprehensivePandL);
    const { businessIncome, businessExpenses, deductionsCostsOfGoods: businessDeductionsCostsOfGoods, totaleBusinessAssertOtherDetails }:
        { businessIncome: Record<string, any[]>; businessExpenses: Record<string, any[]>; deductionsCostsOfGoods: Record<string, any[]>; totaleBusinessAssertOtherDetails: Record<string, any[]>; } = busineeData;

    const sanitizedIncomeDetails: Record<string, Record<number, number>> = getSanitizedBusinessIncome(businessIncome);
    const incomeObject = runIncomePreInitializer(sanitizedIncomeDetails);

    const sanitizedDeductionsCostsOfGoods: Record<string, Record<number, number>> = getSanitizedDeductionsCostsOfGoods(businessDeductionsCostsOfGoods);
    const deductionsCostsOfGoodsObject = runIncomePreInitializer(sanitizedDeductionsCostsOfGoods);

    const sanitizedExpenses: Record<string, Record<number, number>> = getSanitizedExpenses(businessExpenses);
    const sanitizedBusinessExpenses = runExpensesPreInitializer(sanitizedExpenses)

    const sanitizedDepreciation: Record<string, Record<number, number>> = getSanitizedDepreciation(totaleBusinessAssertOtherDetails)
    const depreciation = runIncomePreInitializer(sanitizedDepreciation);

    state.data.set(prev => ({
        ...prev,
        revenues: incomeObject.length ? incomeObject : revenuesDefault,
        reductions: deductionsCostsOfGoodsObject.length ? deductionsCostsOfGoodsObject : reductionsDefault,
        depreciation: depreciation.length ? depreciation : depreciationDefault,
        expenses: { ...prev?.expenses, ...sanitizedBusinessExpenses }
    }))
}

const getSanitizedDeductionsCostsOfGoods = (deductionsCostsOfGoods: Record<string, any[]>) => {
    return Object.entries(deductionsCostsOfGoods).reduce((itemObject, [key, value]: [string, any[]], index) => {
        if (!Array.isArray(value)) return itemObject;
        value.forEach((item) => {
            let deductionsCOGType: string | undefined = item.deductionsCOGType || undefined;
            if (deductionsCOGType && !item?.["removedItem"]) {
                let desc: string | undefined = inspectionDeductionsTypes.find(({ id }) => Number(id) === Number(deductionsCOGType))?.name;
                itemObject = !desc ? itemObject : {
                    ...itemObject,
                    [desc]: {
                        ...(itemObject?.[desc] || {}),
                        [key]: Number(item?.annualDeductionsCOG || 0) + Number(itemObject?.[desc]?.[key] || 0)
                    }
                }
            }
        });
        return itemObject
    }, {})
}

const getSanitizedBusinessIncome = (businessIncome: Record<string, any[]>) => {
    return Object.entries(businessIncome).reduce((itemObject, [key, value]: [string, any[]], index) => {
        if (!Array.isArray(value)) return itemObject;
        value.forEach((item) => {
            if (item.sourceOfIncome && !item?.["removedItem"]) {
                itemObject = {
                    ...itemObject,
                    [item.sourceOfIncome]: {
                        ...(itemObject?.[item.sourceOfIncome] || {}),
                        [key]: Number(item?.annualBusinessIncome || 0) + Number(itemObject?.[item.sourceOfIncome]?.[key] || 0)
                    }
                }
            }
        });
        return itemObject
    }, {})
}

const getSanitizedExpenses = (expenses: Record<string, any[]>) => {

    return Object.entries(expenses).reduce((itemObject, [key, value]: [string, any[]], index) => {
        if (!Array.isArray(value)) return itemObject;
        value.forEach((item) => {
            let expensesType: string | undefined = item.expenseType || undefined;
            if (expensesType && !item?.["removedItem"]) {
                let desc: string | undefined = expensesType;
                itemObject = !desc ? itemObject : {
                    ...itemObject,
                    [desc]: {
                        ...(itemObject?.[desc] || {}),
                        [key]: Number(item?.annualExpense || 0) + Number(itemObject?.[desc]?.[key] || 0)
                    }
                }
            }
        });
        return itemObject
    }, {})
}

const getSanitizedDepreciation = (asset: Record<string, any[]>): Record<string, Record<number, number>> => {
    return Object.entries(asset).reduce((itemObject, [key, curr]: [string, any[]], index) => {
        if (!Array.isArray(curr)) return itemObject;

        curr.forEach((item) => {
            let desc = "Depreciation";
            let yearKey: number | null = Number(key) || null;

            itemObject = !desc || !yearKey ? itemObject : {
                ...itemObject,
                [desc]: {
                    ...(itemObject?.[desc] || {}),
                    [yearKey]: Number(itemObject?.[desc]?.[yearKey] || 0) + Number(item?.["depreciationExpense"] || 0)
                }
            }
        })
        return itemObject;
    }, {})

}

const runIncomePreInitializer = (itemData: Record<string, Record<number, number>>) => {
    let afterYear = Number(moment().year()) + 1
    let yearAfterYear = afterYear + 1;

    return Object.entries(itemData).reduce((dataItemArray: any[], [key, value]: [string, Record<number, number>], index) => {
        return [...dataItemArray, { description: key, [yearAfterYear]: Number(value?.[afterYear] || 0), ...value, dataId: generateUniqueId() }]
    }, []);
}

const runExpensesPreInitializer = (itemData: Record<string, Record<number, number>>) => {
    const masterDataStore = MasterDataProvider.provideMasterData();
    let afterYear = Number(moment().year()) + 1
    let yearAfterYear = afterYear + 1;

    return Object.entries(itemData).reduce((dataItemObj, [key, value]: [string, Record<number, number>], index) => {
        for (const [sectionKey, dropDownMap] of Object.entries(inspectionExpensesMap)) {

            if (dropDownMap.includes(Number(key) || 0)) {
                let description = masterDataStore.getMasterDataDescription(MASTER_DATA_KEYS?.BUSINESS_EXPENSES, key);
                dataItemObj = {
                    ...dataItemObj,
                    [sectionKey]: [...(dataItemObj?.[sectionKey] || []), { description, [yearAfterYear]: Number(value?.[afterYear] || 0), ...value, dataId: generateUniqueId(), }]
                }
            }
        }
        return dataItemObj;
    }, []);



}
