import { Paper, Stack } from "@mui/material";
import React from "react";
import { CircularProgressComponent } from "../../../../../../../ProgressComponent/ProgressComponent";
import { checkReturnDetailConfigs } from "../../../Configs/DirectOtherBankLiabilityAnalysis/DirectOtherBankLiabilityAnalysisConfigs";
import TableBodyWrapper from "../../../Helpers/TableContainer/TableBody/TableBodyWrapper";
import { TableHeaderWrapperCustom } from "../../../Helpers/TableContainer/TableHeader/TableHeaderWrapper";
import TableWrapper from "../../../Helpers/TableContainer/TableWrapper/TableWrapper";
import ChequeReturnsTableBuildRow from "./BuildRowTableComponents/ChequeReturnsTableBuildRow";

const ChequeReturnDetails = ({ chequeReturnData, callBackOnDelete }) => {
  let titleArray: any[] = [];
  let titleStringArr: any[] = [];

  if (checkReturnDetailConfigs.length > 0) {
    checkReturnDetailConfigs.forEach((obj) => {
      titleStringArr.push(obj?.title);
    });
  }
  titleArray = titleStringArr;

  if (titleArray.length === 0) {
    return <></>;
  }

  return (
    <Stack component={Paper} elevation={0} width={"100%"} height={"100%"}>
      <React.Suspense fallback={<CircularProgressComponent sx={{ left: "50%", top: "50%" }} />}>
        <TableWrapper>
          <TableHeaderWrapperCustom headerArray={titleArray} />
          <TableBodyWrapper>
            <ChequeReturnsTableBuildRow data={chequeReturnData} callBackOnDelete={callBackOnDelete()} />
          </TableBodyWrapper>
        </TableWrapper>
      </React.Suspense>
    </Stack>
  );
};

export default ChequeReturnDetails;
