import { useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Stack } from "@mui/material";
import React, { createRef, useEffect } from "react";
import { userDetails, workflowMasterData } from "../../../../../configs/mainStore";
import { applicationTimelineApi, saveRiskData, uploadRiskFile } from "../../../../../services/creditFileApiCall";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { TextBoxComponent } from "../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../GlobalToast";

import { RISK_DOCUMENT_ID } from "../../../../../configs/constants/contants";
import { ERROR_CODES } from "../../../../../configs/constants/errorCode";
import { getUsersByGroupId } from "../../../../../services/userPrivilegeService";
import { closeBtn } from "../../../../../styles";
import { responseSuccessCode } from "../../../../../utility/other";
import AutoCompleteProxyComponent from "../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import ModalInputComponent from "../../../../InputComponents/ModalInputComponent/ModalInputComponent";
import { CMTextInput } from "../../../CommunicationService/CMTextInput";
import { FileUploadComponent } from "../../../DropzoneComponent/FileUploadComponent";

const ReferralRiskModal = (params) => {
  const initialData = { title: "", description: "" };
  const showErrors = useState(false);
  const riskState: any = useState(initialData);
  const loginUser = useState(userDetails);
  const { wf_system_users }: any = useState(workflowMasterData);

  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [dropdownUserList, setDropdownUserList] = React.useState<any>([]);

  const docUploadRef: any = createRef();

  useEffect(() => {
    (async function () {
      let tempUserList: any = [];
      let usersListData = await getUsersByGroupId(params?.referType);

      tempUserList = Object.keys(usersListData).map((key) => {
        const id = parseInt(key);
        const object = wf_system_users?.find((obj) => obj?.get().id === id);
        return {
          name: object?.get().fullName,
          id: object?.get().id,
        };
      });

      setDropdownUserList(tempUserList);
    })();
  }, []);

  const saveRisk = async () => {
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      setIsLoading(true);

      const primaryDocUploadResult = await docUploadRef?.current?.uploadSingleImg();

      if (primaryDocUploadResult?.message === "Empty Data") {
        const formData = {
          title: riskState?.title?.get(),
          description: riskState?.description.get(),
          createdBy: loginUser?.userId?.get(),
          applicationId: params?.applicationId,
          workflowId: params?.currentWorkflow,
          stageId: params?.currentWorkflowStage,
          referralId: params?.referId,
          approvalFrom: riskState?.approvalFrom?.get(),
        };

        const result = await saveRiskData(formData);

        if (result?.status == responseSuccessCode) {
          addToaster({
            status: "success",
            title: "Success",
            message: result?.data?.msg,
          });

          // calling the modal callback passing necessary arguments
          if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
            params.callBackOnAction(result?.data?.data);
          }
        } else {
          addToaster({
            status: "error",
            title: "Error",
            message: result?.data?.msg,
          });
        }
      } else {
        if (primaryDocUploadResult?.status) {
          if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
            params.callBackOnAction(primaryDocUploadResult?.data?.data);
          }
        } else {
          addToaster({
            status: "error",
            title: "Error",
            message: primaryDocUploadResult?.message,
          });
        }
      }

      const referenceId = params?.referId;
      const referenceType = params?.referType;

      const appTimelineParams = {
        referenceId,
        applicationId: params?.applicationId,
        referenceType,
        actionedBy: loginUser?.userId?.get(),
        assignee: riskState?.approvalFrom?.get(),
        actionNote: riskState?.description?.get() || "",
        actionType: "ASSIGN_TO_RISK_USER",
      };

      const applicationTimelineApiResponse = await applicationTimelineApi(appTimelineParams);

      if (applicationTimelineApiResponse?.status === ERROR_CODES.success) {
        addToaster({
          status: "success",
          title: "Success",
          message: applicationTimelineApiResponse?.data?.message,
        });
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: "Timeline Update Failed",
        });
      }

      closeGlobalModal();
      setIsLoading(false);
    }
  };

  const closeRisk = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return riskState.title.get() === "" || riskState.description.get() === "";
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  const extractValue = (value) => {
    if (value === "null") {
      return null;
    }
    if (value === "undefined") {
      return null;
    }
    if (value === "") {
      return null;
    }
    if (!value) {
      return null;
    }
    return value;
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Create Risk</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Risk Title"
            component={
              <TextBoxComponent
                value={riskState?.title?.get()}
                onChange={(e) => {
                  resetError();
                  riskState.title.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                minRows={1}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && riskState.risk.get() === ""}
          />
        </Grid>
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Risk Description"
            component={
              <TextBoxComponent
                value={riskState?.description.get()}
                onChange={(e) => {
                  resetError();
                  riskState.description.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                minRows={12}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && riskState.risk.get() === ""}
          />
        </Grid>
        {params?.enableDropdown === true && (
          <Grid container item xs={12}>
            <ModalInputComponent
              title="User"
              component={
                <AutoCompleteProxyComponent
                  label={"Select User"}
                  value={extractValue(riskState?.approvalFrom?.get())}
                  options={dropdownUserList}
                  onChange={(e) => riskState?.approvalFrom?.set(e ? e.id : null)}
                />
              }
              isMandatory={true}
              showErrors={showErrors.get() && !riskState.user.get()}
            />
          </Grid>
        )}
        <Grid item xs={12} md={12} className="">
          <CMTextInput
            desc={"Upload Risk File"}
            component={
              <FileUploadComponent
                fileDetails={{
                  //TODO RISK DOC GET FROM DB
                  ref: docUploadRef,
                  isMandatory: false,
                  allowedFormats: "application/pdf",
                  maxFileSize: 4000000,
                  documentKey: "",
                }}
                uploadDataObject={{
                  title: riskState?.title?.get(),
                  description: riskState?.description.get(),
                  createdBy: loginUser?.userId?.get(),
                  applicationId: params?.applicationId,
                  workflowId: params?.currentWorkflow,
                  stageId: params?.currentWorkflowStage,
                  referralId: params?.referId,
                  approvalFrom: riskState?.approvalFrom?.get(),
                  documentId: RISK_DOCUMENT_ID,
                }}
                isPreviewHave={false}
                fileUploadApi={uploadRiskFile}
              />
            }
          // error={errorMessage?.cribRecordKey !== '' ? true : false}
          />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeRisk}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title={params?.legalriskModal === true ? "Send Oppinion" : "send"}
          variant="contained"
          onClick={saveRisk}
          loadingbtn={true}
          loading={isLoading}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default ReferralRiskModal;
