import { Downgraded, useHookstate, useState } from "@hookstate/core";
import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { excludeTypesFromSpecialConditions } from "../../../../../configs/constants/conditionListExcludes";
import { CORE_BANK_KEYS } from "../../../../../configs/constants/contants";
import { continueButtonStyle, loadingButtonStyle } from "../../../../../configs/constants/styleValues";
import { creditData, productDetails, userDetails } from "../../../../../configs/mainStore";
import { getCoreBankData } from "../../../../../services/bankServiceInvorker";
import { createOfferLetterConditions, getConditionList, getTrailCalData } from "../../../../../services/creditFileApiCall";
import { getIsDisbursed } from "../../../../../utility/helpers/getIsDisbursed";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { StyledButton } from "../../../../InputComponents/ButtonComponent/StyledButton";
import { ErrorMessageComponent } from "../../../ErrorMessageComponent";
import { openGlobalModal } from "../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import InnerSectionHeaderComponent from "../InnerSectionHeaderComponent";
import OfferLetterConditionItemRow from "./OfferLetterConditionItemRow";

const ApplicationCondition = ({ data, applicationId, currentApplicationWfData, innerComponentData, tabsToEdit }) => {
  const [conditionList, setConditionList]: any = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [recommendedInterestRate, setRecomendInterestRate] = React.useState(0);
  const [regularPaymentFactor, setRegularPaymentFactor] = React.useState(0);
  const [trialCalcParams, setTrialCalcParams]: any = React.useState({});
  const [trialCalcResult, setTrialCalResult]: any[] = React.useState([]);

  const creditFileData: any = creditData.attach(Downgraded).get();
  const disbursed = creditFileData?.disbursed;
  const { userId }: any = userDetails.attach(Downgraded).get();
  const isLoggedInUserNotTheAssignee = userId !== data?.currentAssignee;
  const loginUser = useState(userDetails);
  const { productType, sector, scheme } = currentApplicationWfData;
  const productCatelog = `${productType}-${sector}-${scheme}`;

  const productMasterData: any = useHookstate(productDetails);
  const productCatalogKeyData = productMasterData?.productCatalogData?.allProductCatalogsWithKeys?.get();

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      if (applicationId) {
        const standingOrderAmount = await getCoreBankData(applicationId, CORE_BANK_KEYS.INVESTMENT_AMOUNT);
        const selectedAccountObj = await getCoreBankData(applicationId, CORE_BANK_KEYS.REC_ACC);

        if (standingOrderAmount) {
          creditFileData.formData.creditData.standingOrderAmount = standingOrderAmount || "0";
        }

        creditFileData.formData.creditData.recoveryAccountNumber = selectedAccountObj || "";

        const getFormattedPrevAddedConditions: any[] = [];

        await getAddedList();

        if (getFormattedPrevAddedConditions.length > 0) {
          const ids = getFormattedPrevAddedConditions.map((obj) => obj.MAPPING_ID);
          sessionStorage.addedOfferLetterConditions = JSON.stringify(ids);
        } else {
          sessionStorage.addedOfferLetterConditions = null;
        }

        const trialInvokedData = await getTrailCalData(applicationId);

        if (trialInvokedData) {
          if (trialInvokedData?.trailCalResult != null && trialInvokedData?.trailCalResult != undefined) {
            setTrialCalResult(trialInvokedData?.trailCalResult);
          }

          if (trialInvokedData?.trailCalParams != null && trialInvokedData?.trailCalParams != undefined) {
            const loanInterestRate = trialInvokedData?.trailCalParams?.LoanInterestRate || trialInvokedData?.trailCalParams?.loanInterestRate;
            setTrialCalcParams(trialInvokedData?.trailCalParams);
            setRecomendInterestRate(loanInterestRate);

          }
        }

        const productData = productCatalogKeyData[productCatelog];

        if (productData) {
          setRegularPaymentFactor(productData?.REGULAR_PAYMENT_FACTOR)
        }
      }
      setIsLoading(false);
    })();

    return () => {
      sessionStorage.addedOfferLetterConditions = null;
    };
  }, []);

  const getAddedList = async () => {
    const excludeString = excludeTypesFromSpecialConditions.map((item) => JSON.stringify(item)).join(",");
    const previouslyAddedOfferLetterConditionData = await getConditionList(applicationId, excludeString);

    previouslyAddedOfferLetterConditionData.forEach((element) => {
      const conditonObj = {
        COND_TYPE: "",
        COND_DESCRIPTION: element?.description,
        COND_KEY: "",
        CREATED_BY: loginUser?.userId?.get(),
        DESCRIPTION: element?.description,
        F_DESC: element?.description,
        ID: element?.id,
        PLACEHOLDERS: "{}",
        PRODUCT_CATALOG: productCatelog || "",
        PRODUCT_TYPE_ID: productType || "",
        STATUS: "",
        MAPPING_ID: element?.mappingId,
        TITLE: element?.title,
      };
    });
    setConditionList(previouslyAddedOfferLetterConditionData);
  };

  const callBackOnAction = (newConditions) => {
    const formattedItems = newConditions.map((item) => {
      const _item = item;
      _item.MAPPING_ID = _item.ID || _item.id;
      _item.ID = 0;
      return _item;
    });

    const items = [...conditionList, ...formattedItems];

    let addedItems: any[] = items.map((obj) => obj.MAPPING_ID || obj.mappingId);
    sessionStorage.addedOfferLetterConditions = JSON.stringify(addedItems);

    setConditionList(items);
  };

  const setNewDataArray = (updatedConditions) => {
    setConditionList([...conditionList, ...[updatedConditions]]);
  };

  const hasFilledManualEntries = (rowsToAdd) => {
    let nonEmptyPlaceholders: string[] = [];

    for (const item of rowsToAdd) {
      const json = (item as any).PLACEHOLDERS;
      try {
        const placeholder = JSON.parse(json);
        const keys = Object.keys(placeholder);
        for (const key of keys) {
          const placeholderNode = placeholder[key];
          if (placeholderNode.type === "manual") {
            if (!placeholderNode.value) {
              nonEmptyPlaceholders.push(key);
            }
          }
        }
      } catch (error) {
        continue;
      }
    }

    return nonEmptyPlaceholders;
  };

  const handleSave = async () => {
    setIsLoading(true);
    const newItemsAvailable = newItems();
    if (newItemsAvailable) {
      const rowsToAdd = conditionList.filter((obj) => obj.ID === 0);

      const nonEmptyEntries = hasFilledManualEntries(rowsToAdd);
      if (nonEmptyEntries.length > 0) {
        let formattedMessage = ["Failed to proceed with empty placeholders.\n\n"];

        for (const item of nonEmptyEntries) {
          formattedMessage.push(`\n\n👉 ${item}`.replaceAll("__", "").replaceAll("_", " ").toUpperCase());
        }

        addToaster({
          status: "error",
          title: "Error",
          message: formattedMessage,
        });
        setIsLoading(false);
        return;
      }

      if (rowsToAdd.length === 0) {
        setIsFetching(false);
        return;
      }

      let obj = {
        conditions: rowsToAdd,
        applicationId: applicationId,
        type: "special_offer_condition",
      };
      setIsFetching(true);

      const res: any = await createOfferLetterConditions(obj);

      if (res?.msg) {
        setIsFetching(false);
        await getAddedList();
        // createApplicationsHistory(applicationId, `New Offer Letter condition/s`, null, currentChange, "/products/conditions");

        addToaster({
          status: "success",
          title: "Updated",
          message: "Check List Item Updated",
        });
      } else {
        setIsFetching(false);
        addToaster({
          status: "warning",
          title: "Error",
          message: res?.error,
        });
      }
    } else {
      setIsFetching(false);
      addToaster({
        status: "error",
        title: "Error",
        message: "Something went Wrong!",
      });
    }
    setIsLoading(false);
  };

  const onClickCreate = (modalBodyKey) => {
    let addedItems: any[] = conditionList.map((obj) => obj.mappingId || obj.MAPPING_ID);
    sessionStorage.addedOfferLetterConditions = JSON.stringify(addedItems);

    openGlobalModal({
      modalSize: "lg",
      title: "",
      bodyId: modalBodyKey,
      close: false,
      modalParams: {
        applicationId: applicationId,
        callBackOnAction: callBackOnAction,
        ...currentApplicationWfData,
      },
    });
  };

  const addCustomItem = (data) => {
    if (data.conditions) {
      setConditionList([...conditionList, ...data.conditions]);
    }
  };

  const openCustomConditions = (modalBodyKey) => {
    openGlobalModal({
      modalSize: "lg",
      title: "",
      bodyId: modalBodyKey,
      close: false,
      modalParams: {
        addItem: addCustomItem,
        applicationId: applicationId,
        callBackOnAction: callBackOnAction,
        ...currentApplicationWfData,
      },
    });
  };

  const actionDelete = (response) => {
    if (response != null && response !== "") {
      let deletedObj = response?.data || response[0];

      const id = deletedObj?.id || deletedObj?.ID;

      let noneDeletedConditions = conditionList.filter((obj) => {
        let conditionId = obj.id || obj.ID;
        return conditionId !== id;
      });

      const formattedNoneDeletedConditions: any[] = [];

      if (noneDeletedConditions.length > 0) {
        noneDeletedConditions.forEach((element) => {
          const conditonObj = {
            COND_TYPE: "",
            COND_DESCRIPTION: element?.description,
            COND_KEY: "",
            CREATED_BY: loginUser?.userId?.get(),
            DESCRIPTION: element?.description,
            F_DESC: element?.description,
            ID: element?.id,
            PLACEHOLDERS: "{}",
            PRODUCT_CATALOG: productCatelog || "",
            PRODUCT_TYPE_ID: productType || "",
            STATUS: "",
            MAPPING_ID: new Date().getTime(),
            TITLE: element?.title,
          };
          formattedNoneDeletedConditions.push(conditonObj);
        });
      }

      setConditionList(noneDeletedConditions);
      handleDelete(formattedNoneDeletedConditions);
    }
  };
  const handleInstantDelete = (index) => {
    const updateList = conditionList.filter((obj, i) => i !== index);
    setConditionList(updateList);
  };

  const handleDelete = async (noneDeletedConditions: any = []) => {
    try {
      setIsFetching(false);

      let obj = {
        conditions: noneDeletedConditions,
        applicationId: applicationId,
        type: "special_offer_condition",
        action: "D",
      };
      setIsFetching(true);

      const res: any = await createOfferLetterConditions(obj);

      if (res?.msg) {
        setIsFetching(false);
        // createApplicationsHistory(applicationId, 'Offer letter deleted', null, currentChange, "/los/Offer letter deleted");

        addToaster({
          status: "success",
          title: "Deleted",
          message: "Condition Item Deleted!",
        });
      } else {
        setIsFetching(false);
        addToaster({
          status: "warning",
          title: "Error",
          message: res?.error,
        });
      }
    } catch (error) {
      setIsFetching(false);
      addToaster({
        status: "error",
        title: "Error",
        message: "Something went Wrong!",
      });
    }
  };

  const newItems = () => {
    if (!conditionList || conditionList.length === 0) {
      return false;
    }
    return conditionList.filter((obj) => obj.ID === 0).length > 0;
  };

  const checkPrivilegeStatus = () => {
    let isDisabled = true;
    if (tabsToEdit.includes(innerComponentData?.id)) {
      isDisabled = true;
    } else {
      isDisabled = getIsDisbursed(null, disbursed) || isLoggedInUserNotTheAssignee;
    }
    return isDisabled;
  };

  return (
    <div className="full-width full-height">
      <InnerSectionHeaderComponent innerComponentData={innerComponentData} applicationId={applicationId} currentApplicationWfData={currentApplicationWfData} callBackOnAction={callBackOnAction} modalSize="lg" disabled={checkPrivilegeStatus()}>
        <ButtonComponent title={"Pre-Defined Conditions"} variant="contained" onClick={() => onClickCreate("offer-letter-predefine-condition-modal")} disabled={checkPrivilegeStatus()} />
        <Box ml={0.5} mr={0.5}></Box>
        <ButtonComponent title={"Custom Conditions"} variant="contained" onClick={() => openCustomConditions("condition-modal")} disabled={checkPrivilegeStatus()} />
        <Box ml={0.5} mr={0.5}></Box>
        {newItems() ? <StyledButton styles={isFetching ? loadingButtonStyle : continueButtonStyle} title="Save" onClick={handleSave} loading={isFetching} disabled={isFetching ? true : false || checkPrivilegeStatus()} /> : <></>}
      </InnerSectionHeaderComponent>
      <Grid container className="inner-component-height">
        <Stack spacing={1} m={1} className="full-width">
          {isLoading ? (
            <CircularProgressComponent
              size={30}
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                zIndex: 100,
              }}
            />
          ) : conditionList && conditionList.length > 0 ? (
            conditionList?.map((data: any, index) => <OfferLetterConditionItemRow index={index} data={data} disbursed={disbursed} applicationId={applicationId} currentApplicationWfData={currentApplicationWfData} innerComponentData={innerComponentData} setNewDataArray={setNewDataArray} creditFileData={creditFileData} actionDelete={actionDelete} disableAccordingTo={checkPrivilegeStatus()} trialCalcResult={trialCalcResult} trialCalcParams={trialCalcParams} handleInstantDelete={handleInstantDelete} recommendedInterestRate={recommendedInterestRate} regularPaymentFactor={regularPaymentFactor} />)
          ) : (
            <ErrorMessageComponent headMessage={"No data available"} errorMessage={""} />
          )}
        </Stack>
      </Grid>
    </div>
  );
};

export default ApplicationCondition;
