import { Grid } from '@mui/material';
import { INPUT_STACK_VALUES } from '../../../configs/constants/styleValues';

export const InputFieldsStack = ({
  children,
  spacing = INPUT_STACK_VALUES.spacing,
  marginTop = 0,
  marginBottom = 0,
}) => {
  return (
    <Grid
      container
      // justifyContent="center"
      // alignContent={alignContent}
      // direction="row"
      spacing={spacing}
      // m={InputStackValues.margin}
      mt={marginTop}
      mb={marginBottom}
      alignItems="center" // +
    >
      {children}
    </Grid>
  );
};
