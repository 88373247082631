import { Box } from "@mui/material";
import { standardDateFormatter } from "los-util";
import { NumericFormat } from "react-number-format";
import { VARIANT } from "../../../../../../../../configs/constants/styleValues";
import { formatCurrency } from "../../../../../../../../services/currencyFormater";
import { OnDemandValueFormatter } from "../../../../../../../../services/onDemandValidators";
import { formattedDate } from "../../../../../../../../utility/fomatters";
import { TextBoxComponent } from "../../../../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import ValidateDatePicker from "../../../../../../../InputComponents/ValidatedDatePickerComponent/ValidateDatePickerComponent";
import { FormLabelComponent } from "../../../../../../../InputHelperComponents/FormLabelComponent/FormLabelComponent";
import { InputFieldsStack } from "../../../../../../InputFieldsStack/InputFieldsStack";
import { InputGrid } from "../../../../../../InputGrid/InputGrid";

const PledgeAgreement = ({ props }) => {
  const { pledgeValueOfStock, pledgeAgreementNumber, pledgeDatePreparePledgeAgreement, pledgeNameLawyerPreparedPledgeAgreement } = props.fields || {};
  return (
    <>
      <InputFieldsStack>
        <InputGrid visibility={1} variant={VARIANT?.longGrid}>
          <Box mb={1} mt={1}>
            <FormLabelComponent>Pledge Agreement</FormLabelComponent>
          </Box>
        </InputGrid>
      </InputFieldsStack>
      <InputFieldsStack>
        <InputGrid visibility={pledgeValueOfStock.visible}>
          <NumericFormat
            customInput={TextBoxComponent}
            variant="outlined"
            label={"Value of Stock"}
            thousandSeparator={true}
            value={formatCurrency(props?.doc?.pledgeValueOfStock.get())}
            required={pledgeValueOfStock?.mandatory === 1 ? true : false}
            decimalScale={2}
            onValueChange={(e: any) => {
              const { value } = e;
              props?.doc?.pledgeValueOfStock.set(value);
            }}
          />
        </InputGrid>
        <InputGrid visibility={pledgeAgreementNumber.visible}>
          <NumericFormat
            customInput={TextBoxComponent}
            variant="outlined"
            label={"Pledge Agreement Number"}
            thousandSeparator={true}
            value={props?.doc?.pledgeAgreementNumber.get()}
            required={pledgeAgreementNumber?.mandatory === 1 ? true : false}
            decimalScale={2}
            onValueChange={(e: any) => {
              const { value } = e;
              props?.doc?.pledgeAgreementNumber.set(value);
            }}
          />
        </InputGrid>

        <InputGrid visibility={pledgeDatePreparePledgeAgreement.visible}>
          <ValidateDatePicker
            value={formattedDate(props?.doc?.pledgeDatePreparePledgeAgreement.get())}
            onChange={(date) => {
              const preparedDate = standardDateFormatter(date);
              if (date && preparedDate !== null && preparedDate !== "Invalid Date") {
                props?.doc?.pledgeDatePreparePledgeAgreement.set(preparedDate);
              }
            }}
            label={"Date of the Prepared Pledge Agreement (DD/MM/YYYY)"}
            enableFuture={false}
            disablePast={false}
            required={pledgeDatePreparePledgeAgreement.mandatory === 1 ? true : false}
          />
        </InputGrid>

        <InputGrid visibility={pledgeNameLawyerPreparedPledgeAgreement.visible}>
          <TextBoxComponent value={props?.doc?.pledgeNameLawyerPreparedPledgeAgreement.get()} onChange={(e) => props?.doc?.pledgeNameLawyerPreparedPledgeAgreement.set(e.target.value)} label={"Name of the lawyer prepared the Pledge Agreement"} required={pledgeNameLawyerPreparedPledgeAgreement.visible ? true : false} onInput={OnDemandValueFormatter.alphaNumericSpaces} />
        </InputGrid>
      </InputFieldsStack>
    </>
  );
};

export default PledgeAgreement;
