import { DesktopDatePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import { FC } from "react";
import { DatePickerPropsTypes } from "../../../configs/interfaces";
import { LocalizationProviderComponent } from "../../InputHelperComponents/LocalizationProviderComponent/LocalizationProviderComponent";

export const DatePickerComponent: FC<DatePickerPropsTypes> = ({
  onChange,
  renderInput,
  acceptRegex,
  allowSameDateSelection,
  cancelText,
  className,
  clearable,
  clearText,
  components,
  componentsProps,
  defaultCalendarMonth,
  desktopModeMediaQuery,
  DialogProps,
  disableCloseOnSelect,
  disabled,
  disableHighlightToday,
  disableMaskedInput,
  disableOpenPicker,
  getOpenDialogAriaText,
  getViewSwitchingButtonText,
  InputAdornmentProps,
  inputFormat,
  inputRef,
  leftArrowButtonText,
  loading,
  mask,
  maxDate,
  minDate,
  onClose,
  okText,
  onAccept,
  onError,
  onMonthChange,
  onOpen,
  onViewChange,
  onYearChange,
  open,
  OpenPickerButtonProps,
  openTo,
  orientation,
  PaperProps,
  PopperProps,
  readOnly,
  reduceAnimations,
  renderDay,
  renderLoading,
  rifmFormatter,
  rightArrowButtonText,
  shouldDisableDate,
  shouldDisableYear,
  showDaysOutsideCurrentMonth,
  showTodayButton,
  showToolbar,
  todayText,
  ToolbarComponent,
  toolbarFormat,
  toolbarPlaceholder,
  toolbarTitle,
  TransitionComponent,
  value,
  views,
  label,
  disableFuture,
  enableFuture = false,
  disablePast = false,
  required,
}) => {
  const option = "enhanced";

  const pickerEnhanced = (params) => {
    return <TextField {...params} size="small" type="date" disabled={false} onInput={formatWhiteListChars} />;
  };

  const pickerGeneric = (params) => {
    return (
      <TextField
        id="date"
        label={label}
        type="date"
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  };

  const formatWhiteListChars = (e) => {
    const input = e.target.value;
    const temp = input.replace(/[^0-9/]+/gi, "/");
    const currentLength = temp.length;

    if (currentLength > 10) {
      e.target.value = temp.slice(0, 10);
    } else if (currentLength === 2 || currentLength === 5) {
      if (temp.charAt(temp.length - 1) === "/") {
        e.target.value = temp.substring(0, temp.length - 1);
      } else {
        e.target.value = temp + "/";
      }
    } else if (currentLength === 3 && temp.charAt(2) === "/") {
      e.target.value = temp.substring(0, 2) + temp.substring(3);
    } else if (currentLength === 6 && temp.charAt(5) === "/") {
      e.target.value = temp.substring(0, 5) + temp.substring(6);
    }
  };

  const extractDateValue = (date) => {
    try {
      if (date === "" || date === "Invalid Date") {
        return null;
      }

      if (date.toString() === "Invalid Date") {
        return null;
      }

      return date;
    } catch (error) {
      return null;
    }
  };

  return (
    <LocalizationProviderComponent>
      <DesktopDatePicker
        disableFuture={enableFuture ? false : true}
        disablePast={disablePast ? true : false}
        inputFormat="dd/MM/yyyy"
        value={extractDateValue(value)}
        onChange={onChange}
        renderInput={option === "enhanced" ? pickerEnhanced : pickerGeneric}
        acceptRegex={acceptRegex}
        allowSameDateSelection={allowSameDateSelection}
        className={className}
        clearable={clearable}
        clearText={clearText}
        components={components}
        componentsProps={componentsProps}
        defaultCalendarMonth={defaultCalendarMonth}
        disableCloseOnSelect={disableCloseOnSelect}
        disabled={disabled}
        disableHighlightToday={disableHighlightToday}
        disableMaskedInput={disableMaskedInput}
        disableOpenPicker={disableOpenPicker}
        getOpenDialogAriaText={getOpenDialogAriaText}
        getViewSwitchingButtonText={getViewSwitchingButtonText}
        InputAdornmentProps={InputAdornmentProps}
        inputRef={inputRef}
        leftArrowButtonText={leftArrowButtonText}
        loading={loading}
        mask={mask}
        maxDate={maxDate}
        minDate={minDate}
        onClose={onClose}
        onAccept={onAccept}
        onError={onError}
        onMonthChange={onMonthChange}
        onOpen={onOpen}
        onViewChange={onViewChange}
        onYearChange={onYearChange}
        open={open}
        OpenPickerButtonProps={OpenPickerButtonProps}
        openTo={openTo}
        orientation={orientation}
        PaperProps={PaperProps}
        PopperProps={PopperProps}
        readOnly={readOnly}
        reduceAnimations={reduceAnimations}
        renderDay={renderDay}
        renderLoading={renderLoading}
        rifmFormatter={rifmFormatter}
        rightArrowButtonText={rightArrowButtonText}
        shouldDisableDate={shouldDisableDate}
        shouldDisableYear={shouldDisableYear}
        showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
        showToolbar={showToolbar}
        ToolbarComponent={ToolbarComponent}
        toolbarFormat={toolbarFormat}
        toolbarPlaceholder={toolbarPlaceholder}
        toolbarTitle={toolbarTitle}
        TransitionComponent={TransitionComponent}
        views={views}
        label={label + `${required ? " *" : ""}`}
      />
    </LocalizationProviderComponent>
  );
};
