export class GlobalLogger {
    static log(fileName: string, ...rest): void {
        if (process.env.REACT_APP_ENVIRONMENT) {
            console.log(`<FileName>===>${fileName}`, rest);
        }
    }
    static alert(fileName: string, ...rest): void {
        if (process.env.REACT_APP_ENVIRONMENT) {
            alert(`<FileName>===>${fileName}: <value>===> ${rest}`);
        }
    }
}