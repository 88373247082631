import { useState } from "@hookstate/core";
import React from "react";
import ErrorBoundary from "../../../../../../../ErrorHandleComponent/ErrorBoundary";
import { addToaster } from "../../../../../../GlobalToast";
import FormBuilder from "../../../FormBuilder";
import { inspectionMessageMappingsFromTitle } from "../../../FormBuilder/Tools/Helpers/Validations/Helpers/MessageMapping";
import { inspectionValidationMandtoryHandler } from "../../../FormBuilder/Tools/Helpers/Validations/InspectionValidationHandler";
import { ApplicationFormKey, SectionKey } from "../../../FormBuilder/Tools/Helpers/inspectionKeyEnums";
import { inspetionIdState } from "../../../FormBuilder/Tools/Helpers/inspectionStores/dataStore";
import { formKeys } from "../Helpers/configs";

const InspectionPledge = ({ title, data, handleSubmit, index, inspectionPropertyDetails, currentId, applicationId, otherParams }) => {
  const inspetionId = useState<any>(inspetionIdState);
  const [formDetails, setFormDetails] = React.useState({ ...data, applicationId, inspetionId: inspetionId.get(), });

  const onDownload = () => { };

  const onSubmit = () => {
    let errorArray: Array<any> = inspectionValidationMandtoryHandler(ApplicationFormKey.pledge, formDetails, SectionKey.collateralInspection);

    if (errorArray && errorArray.length > 0) {
      const preparedErrorMessagges = inspectionMessageMappingsFromTitle(errorArray).join("\n");
      return addToaster({
        status: "error",
        title: "Mandatory Validation Error",
        message: `Pledge \n ${preparedErrorMessagges}`,
      });
    }

    handleSubmit(formDetails, index);
  };

  const onChange = (metaData, value) => {
    setFormDetails(value)
  };

  return (
    <ErrorBoundary>
      <FormBuilder applicationDataObject={formDetails} onChange={onChange} formId={formKeys[currentId]} title={title} onSubmit={onSubmit} onDownload={onDownload} otherParams={otherParams} />
    </ErrorBoundary>
  );
};

export default InspectionPledge;
