'use client'

import { useEffect, useState } from "react";
import { Toast, addToaster } from "../components/OtherComponents/GlobalToast";
import { userDetails } from "../configs/mainStore";
import { saveAppFormDataJson, submitApplication } from "./apiCalls";
import { applicationFormSectionDetailsDelete, updateApplicationJson } from "./applicationApis";

const nic = ({ newNic = "", oldNic = "" }) => [oldNic, newNic].filter(obj => obj).join("/");
const validApplicant = ({ personalData }) => personalData?.individualId?.toString() && personalData?.individualId?.toString() != "0" && personalData?.dob && nic(personalData).replaceAll("/", "").length > 0;

const useApplication = (api: any, applicationId: number, refresh) => {
    const [isLoading, setIsLoading] = useState(true);
    const [application, setApplication]: any = useState({});
    const [error, setError]: any = useState({});

    const syncApplication = async (data, index, submitting) => {
        const { requestId = 0 } = data || {};

        index.set(-1);
        const alertPayload = {
            status: "success",
            title: "Success",
            message: "Application data sync successfull.",
        };

        try {
            submitting.set(true);
            await saveAppFormDataJson(requestId, "", {});
            await deleteInvalidApplicants(index, submitting);
        } catch (error) {
            alertPayload.status = "error";
            alertPayload.title = "Error";
            alertPayload.message = "Failed to sync application data.";
        } finally {
            submitting.set(false);
            addToaster(alertPayload);
        }
    };

    const saveEditedContent = async (saveContent) => {
        const content = JSON.parse(saveContent);

        try {
            setIsLoading(true);
            const { updateApplicationJson: { lastRowid: updateRef = null } = {}, updateApplicationJsonHistory: { lastRowid: historyRef = null } = {} } = await updateApplicationJson(applicationId, content);

            if (updateRef && historyRef) {
                Toast.success("Application data sync successfull. \n ");
                await fetchApplication();
            }
        } catch (error) {
            Toast.error("Failed to sync application.");
        } finally {
            setIsLoading(false);
        }
    }

    const fetchApplication = async () => {
        try {
            if (!applicationId) {
                return;
            }
            setIsLoading(true);
            const data = await api(applicationId);
            if (data) {
                setApplication(data);
            } else {
                throw new Error("Application data not found");
            }
        } catch (error: any) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const resubmitApplication = async (data, index, submitting) => {
        index.set(-1);
        const alertPayload = {
            status: "success",
            title: "Success",
            message: "Application data sync successfull.",
        };

        try {
            submitting.set(true);
            const { type, sector, scheme, applicantType } = data.formData.creditData;
            const { requestId = 0 } = data || {};
            const userId = userDetails.userId.get();

            if (requestId === 0) {
                return;
            }

            const { data: _data, status } = await submitApplication(requestId, "", {}, type, sector, scheme, applicantType, userId, "U");
            _data && status;
        } catch (error) {
            alertPayload.status = "error";
            alertPayload.title = "Error";
            alertPayload.message = "Failed to sync application data.";
        } finally {
            submitting.set(false);
            addToaster(alertPayload);
        }
    }

    const deleteInvalidApplicants = async (index, submitting) => {
        let guarantors = application?.APPLICANT_FORM_DATA?.securityData?.guarantors ?? [];
        try {
            for (const guarantor of guarantors) {
                index.set(index.get() + 1);
                if (validApplicant(guarantor)) {
                    continue;
                }

                submitting.set(true);
                const data = await applicationFormSectionDetailsDelete({ applicationId, sectionTravers: `securityData.guarantors[${index.get()}]` });
                if (data) {
                    delete guarantors[index.get()];
                }
                fetchApplication();
                break;
            }
        } catch (error) {
        } finally {
            submitting.set(false);
        }
    };

    useEffect(() => {
        fetchApplication();
    }, [applicationId, refresh]);

    return { isLoading, error, application, saveEditedContent, syncApplication, resubmitApplication, deleteInvalidApplicants };
}

export default useApplication;