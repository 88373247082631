import { useState } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Stack } from "@mui/material";
import { userDetails } from "../../../../../configs/mainStore";
import { saveNoteData } from "../../../../../services/creditFileApiCall";
import { closeBtn } from "../../../../../styles";
import { responseSuccessCode } from "../../../../../utility/other";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import ModalInputComponent from "../../../../InputComponents/ModalInputComponent/ModalInputComponent";
import { TextBoxComponent } from "../../../../InputComponents/TextBoxComponent/TextBoxComponent";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../GlobalToast";

const NoteModal = (params) => {
  const initialData = { noteTitle: "", noteDescription: "" };
  const showErrors = useState(false);
  const noteState: any = useState(initialData);
  const loginUser = useState(userDetails);
  const desCharacterLimit = "4000";

  const saveNote = async () => {
    const errorState = validateSave();

    if (errorState) {
      showErrors.set(true);
      addToaster({
        status: "error",
        title: "Error",
        message: "Please fill required fields before saving",
      });
    } else {
      if (noteState.noteTitle?.get()?.length > 30) {
        addToaster({
          status: "error",
          title: "Error",
          message: "Note title should be 30 letters or lower",
        });
      }

      const originalNote = noteState.noteDescription?.get();
      const removeLineBreaks = originalNote.replace(/[\r\n]/g, ""); // Remove counting Line Breaks, because line breaks are also count as letter / character

      if (removeLineBreaks?.length > 2000) {
        addToaster({
          status: "error",
          title: "Error",
          message: "Note description should be 2000 letters or lower ",
        });

        return;
      }
      const data = {
        applicationId: params?.applicationId,
        createdBy: loginUser?.userId?.get(),
        workflowId: params?.currentWorkflow,
        stageId: params?.currentWorkflowStage,
        ...noteState.get(),
      };
      const res = await saveNoteData(data);

      if (res?.status == responseSuccessCode) {
        addToaster({
          status: "success",
          title: "Success",
          message: res?.data?.msg,
        });

        // calling the modal callback passing necessary arguments
        if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
          params.callBackOnAction(res?.data?.data);
        }
      } else {
        addToaster({
          status: "error",
          title: "Error",
          message: res?.data?.msg,
        });
      }

      closeGlobalModal();
    }
  };

  const closeNote = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return noteState.noteTitle.get() === "" || noteState.noteDescription.get() === "";
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Create Note</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Note Title"
            component={
              <TextBoxComponent
                value={noteState?.noteTitle?.get()}
                onChange={(e) => {
                  resetError();
                  noteState.noteTitle.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                minRows={1}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && noteState.note.get() === ""}
          />
        </Grid>
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Note Description"
            component={
              <TextBoxComponent
                value={noteState?.noteDescription.get()}
                onChange={(e) => {
                  resetError();
                  noteState.noteDescription.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                minRows={4}
                inputProps={{
                  maxlength: desCharacterLimit,
                }}
              // helperText={`${ noteState.noteDescription.get().length}/${characterLimit}`}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && noteState.note.get() === ""}
          />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent title="Close" startIcon={<CloseIcon />} variant="contained" onClick={closeNote} loadingbtn={true} style={closeBtn} />
        <ButtonComponent startIcon={<DoneIcon />} title="Save" variant="contained" onClick={saveNote} loadingbtn={true} color="info" />
      </Stack>
    </Box>
  );
};

export default NoteModal;
