import { Downgraded, useState } from "@hookstate/core";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Box, Grid } from "@mui/material";
import React, { FC, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { securityDocumentPermissions, securityDocumentTextCase } from "../../../../../../configs/constants/contants";
import { branchDetails, creditData, letterDocumentStructure, userDetails } from "../../../../../../configs/mainStore";
import { saveLetterDocumentsData, updatePromissoryAmount } from "../../../../../../services/creditFileApiCall";
import { formatCurrency } from "../../../../../../services/currencyFormater";
import { disableAccess } from "../../../../../../utility/helpers/ApplicationMandatoryValidations/isSecurityDocUploadApprovedPermitted";
import { ButtonComponent } from "../../../../../InputComponents/ButtonComponent/ButtonComponent";
import { SelectComponent } from "../../../../../InputComponents/SelectComponent/SelectComponent";
import { addToaster } from "../../../../GlobalToast";
import SecurityDoceumentsFileDocumentsViewSection from "../../SecurityDocumentsFileDocumentsViewSection";
import CommonSecurityDocHeader from "../CommonSecurityDocHeader";
import { PromissioryNoteBody } from "./PromissoryNoteComponents/PromissioryNoteBody";

interface PromissoryNotePropTypes {
  data?: any;
  applicationId?: Object;
  letterDocumentData?: any;
  existingDataArr?: any;
  disableAccordingToTab?: any; // to be Removed
  isLoggedInUserNotTheAssignee?: any; // to be removed
  setDocumentDataUpdated?: any;
  currentApplicationWfData?: any;
}

const printValidator = {
  promissoryNoteBody: () => [],
};

export const PromissoryNote: FC<PromissoryNotePropTypes> = ({ data, applicationId, letterDocumentData, existingDataArr, disableAccordingToTab, isLoggedInUserNotTheAssignee = false, setDocumentDataUpdated, currentApplicationWfData }) => {
  const creditFileData: any = creditData.attach(Downgraded).get();
  const componentRef: any = useRef();
  const userData: any = userDetails.attach(Downgraded).get();
  const usedBranchId = creditFileData["originationBranch"] || 1;
  const nearestBranch = branchDetails.branchMap[usedBranchId].name.get();
  const creditDataObj: any = creditFileData?.formData?.creditData;
  const letterDocumentStructureData: any = useState(letterDocumentStructure);
  const existingLetterDocumentData = letterDocumentStructureData.attach(Downgraded).get();
  const [promissioryNoteDataArr, setPromissioryNoteDataArr] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [loanAmount, setLoanAmount] = React.useState<any>();
  const [promissaryInterestRate, setPromissaryInterestRate] = React.useState(0);
  const requestedAmount = creditDataObj?.loanAmount ? formatCurrency(creditDataObj?.loanAmount, 2) : "-";
  const [trialCalcResults, setTrialCalcResults]: any = React.useState();
  const documentId = securityDocumentPermissions.promissoryNote;
  const [typeCase, setTypeCase] = React.useState(1);
  const [typeCaseClass, settypeCaseClass] = React.useState();
  const [promissoryAmount, setPromissaryAmout]: any = React.useState("");

  const noPermissionPrint = disableAccess({
    documentComponentId: documentId,
    privilegeKeyforSec: "SEC_DOC_PRINT_SECURITY_DOC",
  });

  useEffect(() => {
    const { trialData: trailObjectData } = data || {};
    setTrialCalcResults(trailObjectData);
    (async function () {
      if (applicationId) {
        const params = {
          applicationId: applicationId,
        };

        const promissoryUpdateRes = await updatePromissoryAmount(params);

        if (promissoryUpdateRes.status === 1) {
          setIsLoading(true);
          setPromissaryAmout("N/A");
          setIsLoading(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    let loanAmountData = trialCalcResults?.trailCalParams?.LoanAmount || creditDataObj?.loanAmount;
    let trialInterestRate = trialCalcResults?.trailCalParams?.LoanInterestRate ?? trialCalcResults?.trailCalParams?.loanInterestRate;
    setLoanAmount(loanAmountData);
    setPromissaryInterestRate(trialInterestRate ?? 0);
  }, [trialCalcResults]);

  useEffect(() => {
    if (existingDataArr) {
      let promissioryNoteDataObj = existingDataArr?.find((obj) => obj?.LETTER_TYPE === "promissioryNote");
      promissioryNoteDataObj && setPromissioryNoteDataArr(promissioryNoteDataObj);
    }
  });

  useEffect(() => {
    changeTypeCase();
  }, [typeCase]);

  const saveDocumentData = async () => {
    let letterBodyObj = {};

    setIsLoading(true);

    const updatedData = existingLetterDocumentData?.promissoryNoteObj;
    const letterBodyData = promissioryNoteDataArr?.LETTER_BODY;
    if (letterBodyData) {
      const letterBodyDataObj = JSON.parse(letterBodyData);

      letterBodyObj = {
        ...letterBodyDataObj,
        ...updatedData,
      };
    } else {
      letterBodyObj = {
        ...updatedData,
      };
    }

    const data = {
      APPLICATION_ID: applicationId,
      WORKFLOW_ID: "",
      LETTER_BODY: JSON.stringify(letterBodyObj),
      STATUS: 1,
      CREATED_BY: userData?.userId,
      UPDATED_BY: userData?.userId,
      LETTER_TYPE: "promissioryNote",
    };

    const res = await saveLetterDocumentsData(data);
    if (res?.status === 1) {
      setDocumentDataUpdated((prev) => !prev);
    }

    setIsLoading(false);

    if (res?.status === 1) {
      addToaster({
        status: "success",
        title: "Success",
        message: "Letter Document Data Added",
      });
    } else {
      addToaster({
        status: "error",
        title: "Error",
        message: "Letter Document Data Adding Falied",
      });
    }
  };

  const handlePrint = () => {
    let documentErrors: any[] = [];
    let validateStatus = true;

    for (const key of Object.keys(printValidator)) {
      try {
        const errors = printValidator[key]();
        if (errors.length > 0) {
          errors.forEach((error) => {
            documentErrors.push(error.message);
          });
          validateStatus = false;
        }
      } catch (error) {
        continue;
      }
    }

    if (documentErrors.length > 0) {
      let formattedMessage = ["Failed to proceed with empty placeholders.\n\n"];

      for (const item of documentErrors) {
        formattedMessage.push(`\n\n👉 ${item}`);
      }

      addToaster({
        status: "error",
        title: "Error",
        message: formattedMessage,
      });
      validateStatus = false;
    }

    return validateStatus;
  };

  const proceedPrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
    @media print {
      #Header, #Footer {
        display: none !important;
      }
    }
  `,
  });

  const changeTypeCase = () => {
    settypeCaseClass(securityDocumentTextCase[typeCase || 1].typeCaseClass);
  };

  const getPageMargins = () => {
    return `@page { margin: ${"7mm"} ${"10mm"}  ${"15mm"} ${"15mm"} !important; }`;
  };

  return (
    <>
      {" "}
      <Grid container className={`inner-component-full-height basic-font`}>
        <style>{getPageMargins()}</style>
        <div className="full-width full-height">
          <SecurityDoceumentsFileDocumentsViewSection data={data} applicationId={applicationId} isSecurityDocument={true} securityDocumentKey={"promissory-note"} />
          <Grid container pt={2} pr={2} pl={2} pb={1} ref={componentRef}>
            <CommonSecurityDocHeader documentName={"Promissory Note"} textCase={"lower"} />
            <PromissioryNoteBody data={creditFileData} requestedAmount={requestedAmount} branch={nearestBranch} applicationId={applicationId} letterDocumentData={letterDocumentData} promissioryNoteDataArr={promissioryNoteDataArr} loanAmount={loanAmount} promissaryInterestRate={promissaryInterestRate} disableAccordingToTab={disableAccordingToTab} validator={printValidator} isLoggedInUserNotTheAssignee={isLoggedInUserNotTheAssignee} typeCaseClass={typeCaseClass} promissoryAmount={promissoryAmount} />
          </Grid>
          <Box m={1}>
            <Grid container columns={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Grid item xs={4} pr={1}>
                <SelectComponent label={"Select Typecase"} value={typeCase} values={Object.values(securityDocumentTextCase)} onChange={(e) => setTypeCase(e.target.value)} required={false} defaultPlaceholder={true} />
              </Grid>
              <Grid item xs={4}>
                <ButtonComponent
                  startIcon={<LocalPrintshopIcon />}
                  title={"Print PDF"}
                  variant="contained"
                  loadingPosition="start"
                  onClick={() => {
                    handlePrint() && proceedPrint();
                  }}
                  style={{ maxHeight: "40px", marginTop: "auto" }}
                  disabled={noPermissionPrint}
                  loadingbtn={true}
                  loading={false}
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      </Grid>
    </>
  );
};
