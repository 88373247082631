import { useState } from '@hookstate/core';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Grid, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { userGroupList } from '../../../../../../configs/constants/contants';
import { userDetails, workflowMasterData } from '../../../../../../configs/mainStore';
import {
  createCauHeadAssign,
  getGroupownersByGroupId
} from '../../../../../../services/creditFileApiCall';
import { closeBtn } from '../../../../../../styles';
import { userName } from '../../../../../../utility/helpers/creditFileUtility';
import { responseSuccessCode } from '../../../../../../utility/other';
import { referStatus } from '../../../../../../utility/util';
import { ButtonComponent } from '../../../../../InputComponents/ButtonComponent/ButtonComponent';
import ModalInputComponent from '../../../../../InputComponents/ModalInputComponent/ModalInputComponent';
import { SelectComponent } from '../../../../../InputComponents/SelectComponent/SelectComponent';
import { TextBoxComponent } from '../../../../../InputComponents/TextBoxComponent/TextBoxComponent';
import { closeGlobalModal } from '../../../../GlobalModal/GlobalModal';
import { addToaster } from '../../../../GlobalToast';

const OfficerHeadReferHeadModal = (params) => {
  const initialData = { note: '', user: '' };
  const showErrors = useState(false);
  const { wf_groups }: any = useState(workflowMasterData);
  const referState: any = useState(initialData);
  const loginUser = useState(userDetails);
  const userGroupId = 39;

  const [referGroup, setReferGroup] = React.useState<any>([]);
  const [headOfficerList, setHeadOfficerList] = React.useState<any>(false);
  const [headOfficerValue, setHeadOfficerValue] = React.useState<any>(null);

  useEffect(() => {
    setReferGroup(userGroupId);
    if (userGroupId === 39) {
      (async function () {
        let tempUserList = {};
        // Get Head of Credit Admin Group ID
        const res = await getGroupownersByGroupId(143);

        const uniqueRes = new Set(res);

        if (res) {
          tempUserList = Array.from(uniqueRes.values()).map((obj) => {
            return {
              name: userName(obj),
              id: obj,
            };
          });

          setHeadOfficerList(tempUserList);
        }
      })();
    }
  }, []);

  const saveRefer = async () => {
    const errorState = validateSave();
    if (errorState) {
      showErrors.set(true);
    } else {
      const referStat = Object.keys(referStatus)?.find((sts) => referStatus[sts]?.key == 'active');
      const data = {
        applicationId: params?.applicationId,
        createdBy: loginUser?.userId?.get(),
        workflowId: params?.currentWorkflow,
        stageId: params?.currentWorkflowStage,
        referStatus: referStat,
        referType: referGroup,
        cauOfficer: true,
        referId: params?.referId,
        ...referState.get(),
      };

      const res = await createCauHeadAssign(data);

      // console.log('res =>', res);

      if (res?.status == responseSuccessCode) {
        addToaster({
          status: 'success',
          title: 'Success',
          message: res?.data?.msg,
        });

        // calling the modal callback passing necessary arguments
        if (params.callBackOnAction && params.callBackOnAction instanceof Function) {
          params.callBackOnAction(res?.data?.data);
        }
      } else {
        addToaster({
          status: 'error',
          title: 'Error',
          message: res?.data?.msg,
        });
      }

      closeGlobalModal();
    }
  };

  const closeRefer = () => {
    closeGlobalModal();
  };

  const validateSave = () => {
    return referState.note.get() === '' || !referState.user.get();
  };

  const resetError = () => {
    if (showErrors.get()) {
      showErrors.set(false);
    }
  };

  useEffect(() => {
    let tempUserGroupList: any = [];
    wf_groups?.get()?.map((item: any) => {
      if (
        item?.id === userGroupList.Legal.id ||
        item?.id === userGroupList.Risk.id ||
        item?.id === userGroupList.InsuranceDepartment.id
      ) {
        tempUserGroupList.push({ name: item?.name, id: item?.id });
      }
    });
  }, []);

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <div className="basic-font font-size-18 basic-font-color-bold">Create Referral</div>
      </Stack>

      <Grid container spacing={2} mt={1} className="modal-text-field">
        <Grid container item xs={12}>
          <ModalInputComponent
            title="Note"
            component={
              <TextBoxComponent
                value={referState?.note?.get()}
                onChange={(e) => {
                  resetError();
                  referState.note.set(e.target.value);
                }}
                variant="outlined"
                size="small"
                multiline={true}
                minRows={4}
                inputProps={{ maxLength: 100 }}
              />
            }
            isMandatory={true}
            showErrors={showErrors.get() && referState.note.get() === ''}
          />
        </Grid>
        {headOfficerList && (
          <Grid container item xs={12}>
            <ModalInputComponent
              title="Head of Credit Admin"
              component={
                <SelectComponent
                  onChange={(e) => {
                    resetError();
                    setHeadOfficerValue(e.target.value);
                    referState.user.set(e.target.value);
                  }}
                  values={headOfficerList}
                  value={headOfficerValue}
                  size="small"
                  label={'Select Head of Credit Admin'}
                />
              }
              isMandatory={true}
              showErrors={showErrors.get() && !referState.user.get()}
            />
          </Grid>
        )}
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <ButtonComponent
          title="Close"
          startIcon={<CloseIcon />}
          variant="contained"
          onClick={closeRefer}
          loadingbtn={true}
          style={closeBtn}
        />
        <ButtonComponent
          startIcon={<DoneIcon />}
          title="Save"
          variant="contained"
          onClick={saveRefer}
          loadingbtn={true}
          color="info"
        />
      </Stack>
    </Box>
  );
};

export default OfficerHeadReferHeadModal;
