import { Downgraded } from "@hookstate/core";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { userDetails } from "../../../../../configs/mainStore";
import { updateReferralChangeAssignee } from "../../../../../services/configurationApis";
import { getUsersByGroupId } from "../../../../../services/userPrivilegeService";
import AutoCompleteProxyComponent from "../../../../InputComponents/AutoCompleteComponent/AutoCompleteProxyComponent";
import { ButtonComponent } from "../../../../InputComponents/ButtonComponent/ButtonComponent";
import { closeGlobalModal } from "../../../GlobalModal/GlobalModal";
import { addToaster } from "../../../GlobalToast";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";

function ReferralChangeAssignee(params) {
    const { groupId, applicationId, id } = params;
    const [isLoading, setIsLoading] = React.useState(false);
    const [usersList, setUsersList] = React.useState({});
    const [selectedUser, setSelectedUsser] = React.useState(null);
    const { userId: loggedUserId }: any = userDetails.attach(Downgraded).get();


    useEffect(() => {
        (async function () {
            setIsLoading(true);

            let usersListData = await getUsersByGroupId(
                groupId
            );

            const userList: any = []

            if (usersListData) {
                if (Number(params?.assignee) === loggedUserId) {
                    userList.push({
                        name: "Unassign",
                        id: "",
                    });
                } else {
                    for (let [key] of Object.entries(usersListData)) {
                        const userId = parseInt(key.toString());

                        if (userId === loggedUserId) {
                            userList.push({
                                name: "Me",
                                id: userId,
                            });
                        }
                    }
                }

                setUsersList(userList);
            }

            setIsLoading(false);
        })();
    }, [params]);

    const saveCondition = async () => {
        setIsLoading(true);
        const data = {
            id: id,
            applicationId: applicationId,
            assignee: selectedUser,
        };

        const res = await updateReferralChangeAssignee(data);

        setIsLoading(false);

        if (res?.status === 1) {
            addToaster({
                status: "success",
                title: "Reffer Assignment",
                message: res?.data?.data?.message || "Current Assignment has been changed",
            });
            params.callBackOnAction(true);
            closeGlobalModal();
        } else {
            addToaster({
                status: "error",
                title: "Current Assignee ",
                message: res?.data?.data?.message || "Update Failed",
            });
        }
    };

    const closeCondition = () => {
        closeGlobalModal();
    };

    return (
        <Box>
            <Stack direction="row" justifyContent={"space-between"}>
                <Stack direction={"column"}>
                    <div className="basic-font font-size-18 basic-font-color-bold">
                        Change Assignee
                    </div>
                    <Typography variant="caption" color={"gray"}>
                        Change the assignee manually
                    </Typography>
                </Stack>
            </Stack>

            <Stack direction="column" mt={2}>
                <Typography variant="caption" color={"gray"}>
                    Application Reference(s)
                </Typography>
            </Stack>

            <Divider></Divider>

            <Stack mt={2} mb={2}>
                <Typography variant="subtitle1" fontWeight={100} mb={1}>
                    Users from group{" "}
                </Typography>

                {isLoading ? (
                    <CircularProgressComponent size={30} />
                ) : (
                    <AutoCompleteProxyComponent
                        label={"Select Assignee"}
                        value={selectedUser}
                        options={usersList}
                        fullWidth={true}
                        cls="select_basic"
                        sx={{ width: 300 }}
                        onChange={(e) => {
                            setSelectedUsser(e?.id || null);
                        }}
                    />
                )}
            </Stack>

            <Divider></Divider>

            <Stack direction="row" spacing={2} justifyContent="end" pt={1} mt={1}>
                <ButtonComponent
                    title="Close"
                    startIcon={<CloseIcon />}
                    variant="outlined"
                    onClick={closeCondition}
                />
                <ButtonComponent
                    startIcon={<DoneIcon />}
                    title="Assign"
                    variant="contained"
                    onClick={saveCondition}
                    color="info"
                />
            </Stack>
        </Box>
    );
}

export default ReferralChangeAssignee;
