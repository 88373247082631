import { Downgraded, useHookstate, useState } from '@hookstate/core';
import { standardDateFormatter } from 'los-util';
import moment from 'moment';
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import appStrings from '../../configs/constants/appStrings';
import { langDictionary } from '../../configs/languageDictionaryEn';
import { lastEditedSection, renderingDetails, selectedApplicationReferenceToEditState } from '../../configs/mainStore';
import { incomeState } from '../../configs/stores/applicationForm';
import { getPendingForm } from '../../services/apiCalls';
import { applicationFormSectionDetailsDelete } from '../../services/applicationApis';
import { applicantTypeStructure, getApplicationFormStructure } from '../../services/applicationFormApis';
import { setStateFromTheApplicationFormData } from '../../services/setStateFromTheApplicationFormData';
import AppConfig from '../../utility/AppConfig';
import { useApplicationFormStore, useClearFormSubcribtions } from '../../utility/CustomHooks/useApplicationFormStates';
import { useSaveSectionChanges } from '../../utility/CustomHooks/useSaveSectionChanges';
import { getTheFieldKeyIfNeeded } from '../../utility/helpers/ApplicationMandatoryValidations/structureValidation';
import { validateMandatoryHandler } from '../../utility/helpers/validateMandatoryHandler';
import { getCopy, isJSON } from '../../utility/util';
import { toast } from '../OtherComponents/GlobalToast';
import { getBackToNormalStepper, updateSectionErrorState } from './ApplicationFormUtils';

export const ApplicationFormContext: any = createContext(undefined);
export class ModeType {
    static initializing = 'INITIALIZING';
    static processing = 'PROCESSING';
    static done = 'DONE';
    static error = 'ERROR';
}
export type SelectedSteps = Record<number, string[]>;
export type ErrorState = Record<string, string[] | Record<string, string[]>>;
export type StepWiseStructure = Record<string, any[]>;
export type CurrentParticipantType = 'securityData' | 'jointBorrowerData' | 'personalData';
export type OtherParticipantDataHandler = { currentParticipantId: number; currentParticipantType: CurrentParticipantType };
export type participantSectionKey = 'securityData' | 'jointBorrowerData' | 'supplementaryCard' | null;

export const ApplicationFormManageProvider = ({ children }) => {
    let worker = new Worker(new URL('../OtherComponents/ApplicationForm/Woker/fn-executor-worker.ts', import.meta.url));
    const validation = useHookstate<ErrorState>({});
    const issues: any = useHookstate({ warnings: [], errors: [] });
    const currentCatalog = useHookstate("1-1-21");
    const currentPage = useHookstate(0);
    const application = useHookstate<any>({});
    const applicationForm = useHookstate({});
    const [launchState, setLaunchState] = React.useState<ModeType>(ModeType.initializing);
    const renderingDivDetails: any = useState(renderingDetails);
    const formStore = useApplicationFormStore();
    const { clear } = useClearFormSubcribtions();
    const navigationsSectionSteps = useHookstate<SelectedSteps>({});
    const previousSection = useState(lastEditedSection);
    const expanded: any = useState({ participant: null, section: 1, key: "primary-applicant-details" });
    const loaders: Record<string, any> = useHookstate({});
    const otherParticipantDataState = useHookstate<OtherParticipantDataHandler>({ currentParticipantId: 0, currentParticipantType: 'personalData' });
    const cifDataStore = useHookstate({});
    const selectedApplicationReferenceId = useHookstate(selectedApplicationReferenceToEditState);
    const appFormNotVisibleMandatoryKeys = useHookstate<Record<string, string[]> | undefined>(undefined as Record<string, string[]> | undefined);

    const runWorker = ({ appFormCopy, sectionKey = undefined }) => {
        const getDataKeys = ({ appForm }) => {
            try {
                return appForm.reduce((fieldKeys: string[], section: any) => {
                    if (section?.content && Array.isArray(section?.content) && section?.content.length) {
                        section?.content.forEach((subSection) => {
                            fieldKeys = [...fieldKeys, ...getDataKeys({ appForm: [subSection] })];
                        });
                    }

                    if (section?.fields && Object.keys(section?.fields).length) {
                        Object.keys(section?.fields).forEach((fieldKey) => {
                            if (
                                (section?.fields[fieldKey]?.visible === 0 ||
                                    section?.fields[fieldKey]?.fieldConfig?.previous?.visible === 0 ||
                                    section?.fields[fieldKey]?.fieldConfig?.current?.visible === 0
                                ) &&
                                (section?.fields[fieldKey]?.mandatory === 1 ||
                                    section?.fields[fieldKey]?.fieldConfig?.previous?.mandatory === 0 ||
                                    section?.fields[fieldKey]?.fieldConfig?.current?.mandatory === 0
                                )
                            ) {
                                fieldKeys.push(fieldKey);
                            }
                        });
                    }

                    return fieldKeys;
                }, [])
            } catch (error) {
                console.error(sectionKey, error);
                return [];
            }

        }
        let payLoad = {};

        if (sectionKey) {
            payLoad[sectionKey as string] = getDataKeys({ appForm: Array.isArray(appFormCopy) ? appFormCopy : [appFormCopy] });
        } else {
            Object.entries(appFormCopy).forEach(([key, value]) => {
                payLoad[key] = getDataKeys({ appForm: value });
            });
        }

        return payLoad;
    }

    const navigationMeta = {
        navSteps: {
            get navigationSteps() { return navigationsSectionSteps.attach(Downgraded).get(); },

            setNavigationSteps(newSteps: SelectedSteps) {
                navigationsSectionSteps.set(Object.keys(newSteps).reduce((stepObj, step, index) => {
                    return { ...stepObj, [index]: newSteps[step] }
                }, {}));
            },
        },

        stepInTo: (key) => {
            const { formsContent } = application.attach(Downgraded).get();

            if (formsContent?.[key]) {
                const { stepIndex } = formsContent?.[key];
                currentPage.set(stepIndex);

                const sectionIndex = navigationMeta.navSteps.navigationSteps?.[stepIndex].indexOf(key);
                expanded.set({ participant: null, section: sectionIndex + 1, key });
                navigationMeta.navActions.positionTop(key);
            }
        },
        navActions: {
            taxDetailsStatusDeligations: (status) => {
                let { navigationSteps: selectedSteps, setNavigationSteps } = navigationMeta.navSteps;
                const { steps } = application.attach(Downgraded).get();
                const { taxInitializer } = preInitializingSteps();
                const sectionKey = "tax-details";

                const response = taxInitializer(selectedSteps, status);

                selectedSteps = response['selectedSteps'];

                if (response['status']) {
                    selectedSteps = getBackToNormalStepper(steps, sectionKey, selectedSteps)
                }

                setNavigationSteps(selectedSteps);
            },

            positionTop: (elem, preElem = null) => {
                const timeout = setTimeout(() => {
                    const element = document?.getElementById(elem);
                    const elementPre = preElem ? document?.getElementById(preElem) : null;
                    element?.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" });
                    if (element) {
                        window.scroll({
                            top: element.offsetTop - (elementPre?.offsetHeight || 0) + 40,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }
                    clearTimeout(timeout);
                }, 250);
            }
        }
    }

    const prepareFormLayout = async (formData) => {
        let endResult = {};
        try {
            const { creditData: { type, sector, scheme, applicantType } } = formData

            if (!type || !sector || !scheme || !applicantType) {
                new Error('Required Data Not Founded!');
            }

            const [structure] = await Promise.all([getApplicationFormStructure(type, sector, scheme, applicantType)]);

            const bindCreditBasedMetaDataResult = bindCreditBasedMetaData(formData);
            const bindNavigationMetaDataResult = bindNavigationMetaData(structure);

            endResult = {
                ...bindCreditBasedMetaDataResult, ...bindNavigationMetaDataResult, formStructure: structure,
                currentCatalog: [type, sector, scheme].join("-")
            }

            return endResult;

        } catch (error) {
            // new Error('prepareFormLayout Error!');
            issues.errors.set((prev) => [...prev, 'prepareFormLayout Error!']);
            return endResult;
        }
    }

    const bindNavigationMetaData = (structure) => {
        let { formsContent = {}, steps } = structure[applicantTypeStructure[1]].reduce(({ steps, formsContent }, currItem) => {

            let currStepIndex: number | string = currItem.stepIndex;

            // eslint-disable-next-line eqeqeq
            if (currStepIndex || currStepIndex == 0) {
                let previousSteps: string[] = steps?.[currStepIndex] || [];

                steps = {
                    ...steps,
                    [currStepIndex]: [...previousSteps, currItem.key]
                }

                formsContent = {
                    ...formsContent,
                    [currItem.key]: currItem
                }

                return { steps, formsContent }
            }
            return { steps, formsContent }
        }, {});

        let initialSteps: SelectedSteps = { ...steps };

        const { run } = preInitializingSteps();
        steps = run(steps);

        navigationMeta.navSteps.setNavigationSteps(steps);
        return { formsContent, steps: initialSteps }
    }

    const bindCreditBasedMetaData = (formData) => {
        const catalog = AppConfig.config.athamaruCatalog;
        const athamaru = catalog === currentCatalog.get();

        return { isAthamaru: athamaru, athamaruCatalog: catalog }
    }

    const loadApplicationForm = async () => {
        let currLaunchState = '';

        try {
            let { requestId = null, applicationId = null } = renderingDivDetails.renderParams.attach(Downgraded).get();

            if (!requestId && !applicationId) {
                currLaunchState = ModeType.error;
                new Error('Required Data Not Founded!');
            }

            let { status, data } = await getPendingForm(requestId, applicationId);

            if (!status || !Object.keys(data).length) {
                currLaunchState = ModeType.error;
                // new Error('No data found from the Requets');
                issues.errors.set((prev) => [...prev, 'No data found from the Requets']);
            };

            setLaunchState(ModeType.processing);

            let { APPLICANT_FORM_DATA: applicationFormData, APPLICATION_DATA } = data
            let { APPLICATION_ID = null, REQUEST_ID = null, STATUS: applicationCurrStatus = null, REVERTED: reverted = 0, CURRENT_WORKFLOW_STAGE: currentWfStage = 0 } = APPLICATION_DATA;

            if (isJSON(applicationFormData)) {
                applicationFormData = JSON.parse(applicationFormData)
            } else {
                issues.errors.set((prev) => [...prev, 'Application form data Not found.!']);
            }

            data.APPLICANT_FORM_DATA = applicationFormData;

            applicationForm.set(applicationFormData);

            setStateFromTheApplicationFormData({ data: applicationFormData, ...formStore });

            const prepareFormLayoutRes = await prepareFormLayout(applicationFormData);

            application.set({ ...data, ...prepareFormLayoutRes, applicationId: APPLICATION_ID || applicationId, requestId: (requestId || REQUEST_ID), applicationCurrStatus, reverted, currentWfStage });

            selectedApplicationReferenceId.set(APPLICATION_ID)

            if (!issues.errors.get().length) {
                currLaunchState = ModeType.done;
            }
            return true;

        } catch (error) {
            currLaunchState = ModeType.error;
        } finally {
            setLaunchState(currLaunchState);
        }

        return currLaunchState;
    }

    const onNext = useCallback(async (key = null) => {
        if (!key) {
            let { navigationSteps } = navigationMeta.navSteps
            if ((Object.keys(navigationSteps).length - 1) !== Number(currentPage.get())) {
                currentPage.set(prev => Number(prev) + 1);
            } else {
                renderingDivDetails.renderId.set("manage-applications")
            }
        } else {
            currentPage.set(key);
        }
    }, []);

    const preInitializingSteps = () => {
        const { taxPayer = 0 } = applicationForm?.["personalData"].get();

        const taxInitializer = (selectedSteps: SelectedSteps, status = null,) => {
            status = status !== null ? status : taxPayer;
            const sectionKey = "tax-details";

            if (!status) {
                selectedSteps = Object.values(selectedSteps).reduce((stepObj, item: string[], index: number) => {
                    let array: string[] = item;

                    if (array.includes(sectionKey)) {
                        array = array.filter((key) => key !== sectionKey);
                        return { ...stepObj, [index]: array }
                    }
                    return { ...stepObj, [index]: item }
                }, {} as SelectedSteps) || {};
            }

            return { selectedSteps, status };;
        }

        const run = (selectedSteps: SelectedSteps) => {
            let res = taxInitializer(selectedSteps);
            selectedSteps = res["selectedSteps"];

            return selectedSteps;
        }

        return { taxInitializer, run }
    }

    const useFormStructureForStep = () => {
        let { formStructure = [] } = application.attach(Downgraded).get();
        formStructure = formStructure?.[applicantTypeStructure[1]] || [];

        let structure = useMemo(() => {
            return Object.entries(navigationsSectionSteps.get()).reduce((stepWiseStructure: any, [key, structureKeys], index: number) => {
                let array = formStructure.filter((item) => structureKeys.includes(item.key));
                let prevItems = stepWiseStructure?.key || [];
                let item = { [key]: [...prevItems, ...array] }

                if (Number(key) === Object.keys(navigationsSectionSteps.get()).length - 1) {
                    item = { [key]: formStructure }
                }

                return { ...stepWiseStructure, ...item };
            }, {} as StepWiseStructure);
        }, [navigationsSectionSteps.get(), application?.["formStructure"]?.get()])

        return { structure: structure[currentPage.get()] };
    }

    const useHandleFormOtherEvents = (sectionIndex, sectionKey, participantSectionKey: participantSectionKey, otherParticipantState, sectionContent) => {
        const sectionName = appStrings.mainSectionValidationsKeys?.[sectionKey];
        let isGurantor = sectionName === 'securityData';
        let isJointBorrower = (sectionName === 'jointBorrowerData') || (sectionName === 'supplementaryCard');
        let { formStructure } = application.attach(Downgraded).get();
        let otherParticipantStructure = isGurantor ? formStructure[applicantTypeStructure[2]] : isJointBorrower ? formStructure[applicantTypeStructure[3]] : {};
        const { saveChanges } = useSaveSectionChanges();
        const navigationSteps = navigationsSectionSteps[currentPage.get()].get();
        let nextSection = navigationSteps[sectionIndex] ? navigationSteps[sectionIndex] : sectionKey;
        const { navActions: { positionTop } } = navigationMeta;

        useEffect(() => {
            if (!appFormNotVisibleMandatoryKeys?.[sectionName]?.get()) {
                worker.postMessage({ fn: runWorker.toString(), args: { appFormCopy: sectionContent, sectionKey: sectionName } });

                worker.onmessage = function (e) {
                    try {
                        const { data: { status, data } } = e;

                        if (status === 1 && data && Object.keys(data).length) {
                            setTimeout(() => {
                                appFormNotVisibleMandatoryKeys.set(prev => ({ ...prev, ...data }));
                            }, 2000);
                        }
                        else {
                            throw new Error('Worker Error!');
                        }

                    } catch (error) {
                        console.error(error);
                    }
                };
            }

        }, [sectionContent, sectionName]);

        const sectionStateDelete = (path: string, itemIndex: number | null, callBackOnAction) => {
            let formState = participantSectionKey ? otherParticipantState : formStore;
            let sectionState = formState?.[sectionName];
            let defaultItem = (path === 'businessIncome' || path === 'cultivationIncome') ? getCopy(incomeState) : [];
            let overridePath: string = ""

            if (path) {
                path.split(".").forEach((p) => {
                    overridePath = Array.isArray(sectionState) ? `${overridePath}[${p}]` : `${overridePath}.${p}`;
                    sectionState = p ? sectionState[p] : sectionState;
                })
                path = overridePath;
            }

            path = path ? `${sectionName}${path}` : sectionName;
            let { currentParticipantId } = otherParticipantDataState.attach(Downgraded).get();
            path = participantSectionKey ? `${participantSectionKey == 'securityData' ? 'securityData.guarantors' : participantSectionKey}[${currentParticipantId}].${path}` : `${path}`;

            if (itemIndex !== null) {
                path = `${path}[${itemIndex}]`;

                sectionState.set(prev => prev.reduce((sanitizedItem = [], currItem, index) => {

                    currItem = { ...currItem, sectionErrorStatus: "pending" };

                    if (itemIndex !== index) return [...sanitizedItem, currItem];

                    return sanitizedItem;
                }, []));
            } else {
                sectionState.set(defaultItem);
            }

            if (callBackOnAction) {
                callBackOnAction(sectionState.get());
            }

            return path;
        }

        const triggerSectionValidations = () => {
            let isGuarantor = (participantSectionKey === 'securityData');
            let isJointBorrower = (participantSectionKey === 'jointBorrowerData') || (participantSectionKey === 'supplementaryCard');
            let props = { formStore, structure: sectionContent, section: sectionKey, participantState: otherParticipantState, participantStructure: otherParticipantStructure, isGuarantor, isJointBorrower, previousData: applicationForm }
            let sectionErrorResult: any = validateMandatoryHandler(props);
            let sectionError: any = Array.isArray(sectionErrorResult) ? { [sectionName]: [...sectionErrorResult] } : { ...sectionErrorResult };
            let currentSectionState = participantSectionKey ? otherParticipantState : formStore;
            let customErrors = customInputFieldValidationMap(currentSectionState?.[sectionName], sectionName);

            if (customErrors.length) {
                sectionError = { [sectionName]: [...sectionError[sectionName], ...customErrors] };
            }

            let status = sectionError[sectionName].length > 0 ? "error" : "success";

            if (participantSectionKey) {
                let getPrevsValidations = validation?.[appStrings.participant?.[participantSectionKey]]?.get() || [];
                const currentItemIndex = otherParticipantDataState.currentParticipantId.get();
                let prevsItems = getPrevsValidations[currentItemIndex] || {};

                getPrevsValidations[currentItemIndex] = { ...prevsItems, ...sectionError }

                sectionError = { [appStrings.participant?.[participantSectionKey]]: getPrevsValidations };
            }


            updateSectionErrorState(currentSectionState?.[sectionName], status)

            return { ...sectionError };
        }

        const onExpand = (): number => {
            loaders?.[sectionName].set((prev) => ({ ...prev, onExpand: true }));
            try {
                expanded.set({ participant: participantSectionKey, section: sectionIndex, key: sectionKey });
                previousSection.set(participantSectionKey ? participantSectionKey : sectionName)

                positionTop(sectionKey, nextSection);

                return 1
            } catch (error) {
                return -1;
            } finally {
                loaders?.[sectionName].set((prev) => ({ ...prev, onExpand: false }));
            }
        }

        const onNext = async ({ noTriggerValidations = false }): Promise<void> => {
            loaders?.[sectionName].set((prev) => ({ ...prev, onNext: true }));
            try {
                if (!noTriggerValidations) {
                    let validationResponse = triggerSectionValidations();
                    validation.set((prev) => ({ ...prev, ...validationResponse }));
                }

                await saveChanges(participantSectionKey ? participantSectionKey : sectionName, "next", "I");

                expanded.set({ participant: participantSectionKey, section: sectionIndex + 1, key: sectionKey });
                previousSection.set(participantSectionKey ? participantSectionKey : sectionName);
                positionTop(sectionKey, nextSection);
            } catch (error) {
                throw new Error(`Somethig went Wrong...!==> ${error}`)
            } finally {
                loaders?.[sectionName].set((prev) => ({ ...prev, onNext: false }));
            }
        }

        const getSectionErrors = (): string[] => [];


        const handleJointBorrowerAction = async (value: number) => {
            loaders?.[sectionName].set((prev) => ({ ...prev, onNext: true }));

            try {
                let prevStatus: number = formStore?.personalData?.addJointBorrower.attach(Downgraded).get();
                formStore?.personalData?.addJointBorrower.set(prev => prev === 1 ? 0 : 1);

                if (prevStatus !== value) {
                    await saveChanges('personalData', "next", "I");
                }

            } catch (error) { }
            finally {
                loaders?.[sectionName].set((prev) => ({ ...prev, onNext: false }));
            }
        }

        const useSelecteCif = () => {

            const triggerState = (cifData) => {
                if (typeof cifData === "object" && Object.keys(cifData).length) {
                    cifDataStore.set({ [participantSectionKey ? participantSectionKey : 'personalData']: cifData });

                    let { personalData, addressData } = participantSectionKey ? otherParticipantState : formStore;

                    runSet(cifData, { personalData, addressData })
                }
            }

            const runSet = (cifData, { personalData, addressData }) => {
                let { CIF: selectedCif, address1: addressLine1, address2: addressLine2, address3: addressLine3, address4: addressLine4, dateOfBirth, initialInFull: initialsInFull, lastName: primaryLastName, sex } = cifData;
                let dataOfBirth = moment(dateOfBirth, "DD/MM/YYYY");
                let ageCal = moment().diff(dataOfBirth, "months");
                let years = Math.floor(Number(ageCal) / 12);
                let months = Math.floor(Number(ageCal) % 12);
                let gender = sex.toUpperCase() === 'F' ? 2 : 1;
                let initials: string = initialsInFull.split(" ").reduce((intial: string, name: string) => name ? intial += ` ${name.charAt(0)}` : intial, "");
                initials = initials.trim();

                personalData.set(prev => ({ ...prev, initialsInFull, primaryLastName, years, months, dob: standardDateFormatter(dataOfBirth), gender, selectedCif, initials, sectionErrorStatus: "pending" }));
                addressData.set(prev => ({ ...prev, permanentAddress: { ...prev.permanentAddress, addressLine1, addressLine2, addressLine3, addressLine4 }, sectionErrorStatus: "pending" }))
            }

            return { triggerState }
        }

        const applicationSubSectionDelete = async ({ path = "", itemIndex = null, callBackOnAction }: { path: string; itemIndex: number | null, callBackOnAction?: Function }) => {
            loaders?.[sectionName].set((prev) => ({ ...prev, onNext: true }));
            let toastObj = { status: "", message: "" };
            let { applicationId } = application.attach(Downgraded).get();

            try {
                path = sectionStateDelete(path, itemIndex, callBackOnAction);

                await applicationFormSectionDetailsDelete({ applicationId, sectionTravers: `${path}` });

                toastObj.status = "success";
                toastObj.message = `${langDictionary.applicationForm.titles?.[sectionName]} deleted successfully!`;
            } catch (error) {
                toastObj.status = "error";
                toastObj.message = `Error on ${langDictionary.applicationForm.titles?.[sectionName]} deletion!`;
            } finally {

                loaders?.[sectionName].set((prev) => ({ ...prev, onNext: false }));
                toast(toastObj);
            }
        }

        return { onNext, getSectionErrors, onExpand, expanded, saveChanges, handleJointBorrowerAction, useSelecteCif, applicationSubSectionDelete }
    }

    const otherParticipantHandlder = ({ currentParticipantId, currentParticipantType }) => {
        otherParticipantDataState.set({ currentParticipantId, currentParticipantType })
    }

    const cleanUp = () => {
        clear();
        worker.terminate();
        otherParticipantHandlder({ currentParticipantId: 0, currentParticipantType: 'personalData' });
    }

    const customInputFieldValidationMap = (state, sectionName) => {
        let errorArray = [] as string[];

        try {
            const { config: { underWrittingPolicyConfigs } } = AppConfig;

            if (!underWrittingPolicyConfigs || !Object.keys(underWrittingPolicyConfigs).length) {
                throw new Error('UnderWritting Policy Configs Not Found!');
            }

            const { currentCatalog } = application.attach(Downgraded).get()

            if (!underWrittingPolicyConfigs[currentCatalog]) {
                throw new Error('Current Catalog Not Found!');
            }

            const { fieldLevelMandatory } = underWrittingPolicyConfigs[currentCatalog] || [];

            if (!fieldLevelMandatory || !fieldLevelMandatory.length) {
                throw new Error('Field Level Mandatory Not Found!');
            }

            for (const fieldKey of fieldLevelMandatory) {

                if (Array.isArray(state?.[fieldKey].get()) || Object.keys(state?.[fieldKey].get()).length) {
                    continue;
                }

                let field = state?.[fieldKey].get() || undefined;

                if (field) {
                    let key = getTheFieldKeyIfNeeded(field, fieldKey);
                    errorArray = key ? [...errorArray, key] : errorArray;
                }
            }

        } catch (error) {
            return []
        }

        return errorArray;

    }


    return (
        <ApplicationFormContext.Provider value={
            {
                navigation: { ...navigationMeta },
                validation,
                currentPage,
                loadApplicationForm,
                launchState,
                applicationForm,
                clear: cleanUp,
                applicationParams: application.attach(Downgraded).get(),
                onNext,
                navigationsSectionSteps,
                useFormStructureForStep,
                lastEditedSection: previousSection,
                useHandleFormOtherEvents,
                loaders,
                expanded,
                updateSectionErrorState,
                otherParticipantHandlder,
                otherParticipantDataState,
                formStore
            }
        }>

            {children}
        </ApplicationFormContext.Provider >
    );
}

export const useApplicationFormContext = () => {
    const context: any = useContext(ApplicationFormContext);

    if (!context) {
        throw new Error('useApplicationFormContext must be used within a ApplicationFormManageProvider');
    }

    return context;
};