import { useState } from "@hookstate/core";
import { Grid, Stack } from "@mui/material";
import React, { FC, useEffect } from "react";
import { branchDetails, letterDocumentStructure, masterDataDetails } from "../../../../../../../configs/mainStore";
import { formatCurrency } from "../../../../../../../services/currencyFormater";
import { numberToWords } from "../../../../../../../utility/util";
import { SinglePageBreak } from "../../CommonPageBreaks";
import Security from "./Security";

interface LoanAgreementConditionsPropType {
  data?: Object;
  applicationId?: any;
  branch?: any;
  installmentData?: any;
  letterDocumentData?: any;
  applicableRate?: any;
  loanAgreementDataArr?: any;
  disburseLoanAmount?: any;
  repaymentDate?: any;
  typeCaseClass?: any;
  contentBreakPoints?: any;
  contentBreakHandler?: any;
}

export const LoanAgreementConditions: FC<LoanAgreementConditionsPropType> = ({ installmentData, data, branch, applicationId, applicableRate, letterDocumentData, loanAgreementDataArr, disburseLoanAmount, repaymentDate, typeCaseClass, contentBreakPoints, contentBreakHandler }) => {
  const letterDocumentStructureData: any = useState(letterDocumentStructure);
  const payload: any = data;
  const masterData: any = masterDataDetails;
  const creditData = payload?.formData?.creditData;
  const usedBranchId = payload.originationBranch;
  const originationBranch = branchDetails.branchMap[usedBranchId].name.get();
  const loanPurpose = masterData?.LOAN_PURPOSE?.get()?.find((t) => t.id == creditData?.loanPurpose);
  const guarantorData: any = payload?.formData?.securityData?.guarantors || [];
  const [selectedAccount, setSelectedAccount] = React.useState("");
  const [disburseLoanValue, setDisburseLoanValue]: any = React.useState(0.0);
  const [loanAmountInWords, setLoanAmountInWords]: any = React.useState("");
  const [regularPaymentFactor, setRegularPaymentFactor]: any = React.useState(0.0);
  const [annualInterestRate, setAnnualInterestRate]: any = React.useState(0.0);

  useEffect(() => {
    loanCreditDetails();
    getCoreBankDataDetails();
  }, []);

  useEffect(() => {
    loanCreditDetails();
  }, [disburseLoanAmount, installmentData, repaymentDate, applicableRate]);

  let loanAgreementObj = {};

  const getCoreBankDataDetails = async () => {
    const {
      REC_ACC: recoveryAccount = ""
    } = (data as any).coreData || {};

    setSelectedAccount(recoveryAccount);
  };

  const callBackOnAction = (value, param) => {
    loanAgreementObj = {
      ...letterDocumentData?.loanAgreementObj,
      [param]: value,
    };
    letterDocumentStructureData?.["loanAgreementObj"].set(loanAgreementObj);

  };

  const loanCreditDetails = () => {
    let disburseLoanAmt: any = disburseLoanAmount || payload.formData.creditData.loanAmount || 0.0;
    setDisburseLoanValue(disburseLoanAmt);

    let disburseLoanValueWords = numberToWords(disburseLoanAmt);
    setLoanAmountInWords(disburseLoanValueWords);

    let loanInterestRateValue = applicableRate || parseFloat(creditData.interestRate) || 0.0;

    let regularPayFactor = creditData.regularPaymentFactor != null && creditData.regularPaymentFactor != "" ? parseFloat(creditData.regularPaymentFactor) : 0.0;
    setRegularPaymentFactor(regularPayFactor);

    if (regularPayFactor >= 1) {
      let annualInterestRate = parseFloat(loanInterestRateValue + regularPayFactor);
      setAnnualInterestRate(annualInterestRate);
    } else {
      let annualInterestRate = parseFloat(loanInterestRateValue);
      setAnnualInterestRate(annualInterestRate);
    }
  };

  const hasGuarantors = () => {
    if (!guarantorData) {
      return false;
    }
    if (!(guarantorData instanceof Array)) {
      return false;
    }
    if (guarantorData.length === 0) {
      return false;
    }
    return guarantorData.length > 0;
  };

  return (
    <>
      <Grid container mt={5} style={{ textAlign: "justify" }}>
        <Stack>
          <Grid item mb={hasGuarantors() && creditData.scheme != 21 ? 0 : 1}>
            I/ We apply for accommodation by way of a loan of a sum of <span className={`security-doc-type-variable ${typeCaseClass}`}>{loanAmountInWords}</span> <span className={`${typeCaseClass}`}></span> (Rs. {formatCurrency(disburseLoanValue, 2)})
          </Grid>
          <SinglePageBreak />
          {hasGuarantors() && (
            <Grid item mt={3}>
              On the security of
            </Grid>
          )}
          <Grid item>{<Security data={data} disburseLoanAmount={disburseLoanAmount} typeCaseClass={typeCaseClass} />}</Grid>
          <Grid mb={1}>
            This loan is required for the purpose of <span className={`security-doc-type-variable ${typeCaseClass}`}>{loanPurpose?.name ? loanPurpose.name : "-"} </span>
            <SinglePageBreak />
          </Grid>
          <Stack mb={1}>and I/We agree to strictly abide by the following terms and conditions :</Stack>
          <ol className={"loan-agreement-condition-list"}>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Stack mb={1}>Loan repayable on demand, by either me / us, without previous notice to the Bank or at any time as required by the Bank</Stack>
            </li>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Grid mb={1}>
                The proceeds of the above loan to be credited to my/our savings account number{" "}
                <span className={`security-doc-type-variable ${typeCaseClass}`}>
                  {selectedAccount ?? ""} {""}
                </span>
                at 933, Wedamulla, Kelaniya of Pradeshiya Sanwardhana Bank (hereinafter referred to as “the Bank”) <span className={`security-doc-type-variable ${typeCaseClass}`}>{originationBranch} </span>
                {""}Branch.
              </Grid>
            </li>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Stack mb={1}>The loan to be exclusively utilised for the purpose stated above</Stack>
            </li>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Grid mb={1}>
                Interest to be payable by me/us at the rate of {annualInterestRate} per centum per annum, or such other higher rate as may from time to time be fixed or charged by the Bank. Provided that if the instalments in repayment of principal and the monthly interest on the loan are made punctually on the due dates I/We shall be entitled to a rebate of {regularPaymentFactor} per centum per annum.
              </Grid>
            </li>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Grid mb={1}>Subject to Clause 1 above, I/we propose to repay the loan by installment of .................................. per month, the first installment to be paid on the .................................. day after the date on which the loan is granted and the succeeding installments to be paid on the same day in every subsequent month</Grid>
            </li>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Grid mb={1}>
                I/ We hereby irrevocably authorise the Bank without notice to me/ us to debit my/ our savings account <span className={`security-doc-type-variable ${typeCaseClass}`}>{selectedAccount}</span> at the Bank or any of the savings accounts maintained by me/us at the Bank at the sole discretion of the Bank with the installments in repayment on the due dates as agreed above together with the amount of the interest and taxes payable and any other charges/commissions.
              </Grid>
            </li>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Stack mb={1}>In the event any property /properties is/ are being mortgaged as security for the above facility, I/We hereby authorise the Bank to any carry out at the Bank’s sole discretion valuation/s of such property/ properties at my/our cost and I/we further agree that if there is a fall in value of any such property/properties to provide additional security when required by the Bank.</Stack>
            </li>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Stack mb={1}>In any case where any other loan/s or credit facility/facilities has/have been or is/are granted to me/us by the Bank and if default is made in the payment of principal or interest or taxes due upon any such loan/s or credit facility/facilities, the repayment of the above loan shall be deemed to be in default even though in fact default may not have been made.</Stack>
            </li>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Stack mb={1}>All information regarding my/our trade, business or occupation and financial condition will be furnished to the Bank as and when required by the Bank.</Stack>
            </li>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Stack mb={1}>Any material change in my/our trade, business or occupation shall be forthwith notified by me/us to the Bank.</Stack>
            </li>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Stack mb={1}>Particulars of any litigation which may tend to affect my/our financial capacity and in which I/We may have involved shall be disclosed by me/us to the Bank whenever such eventuality occurs.</Stack>
            </li>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Stack mb={1}>The Bank is hereby irrevocably authorised by me/us without notice to me/us to combine the account of the above loan with all or any of my/our other accounts whether savings or deposit (term) and to transfer and appropriate all monies lying to my/our credit with the Bank in any of its offices. Such unfettered right and irrevocable authority hereby given by me/us to the Bank shall be exercised by the Bank even to the extent of refusing payment of withdrawals or other like documents drawn by me/us on any office of the Bank on or before the date of exercising of such right or authority or even to the extent of closing any account/s in my/our name/s in any office of the Bank and I/We hereby undertake to indemnify the Bank against all claims or demands that may be made on the Bank consequent on such action of the Bank.</Stack>
            </li>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Stack mb={1}>The production in any court of law or tribunal or body or statutory officer of any statement extract writing or other document showing the monies owing by me/us as a result of the Bank granting the above loan and made out of the books or documents of the Bank and signed and certified by the Manager or Accountant of the Bank shall be deemed to be conclusive proof of the amounts of my/our liability to the Bank without any further documents or vouchers being produced by the Bank.</Stack>
            </li>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Stack mb={1}>If this documents is signed by or on behalf of two or more persons whether as partners of a firm or otherwise such persons shall be jointly and severally liable to the Bank for the amount of the above loan and interest, taxes and other charges.</Stack>
            </li>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Stack mb={1}>The monies due by me/us to the Bank in respect of the above loan and interest, taxes and other charges shall be recoverable from me/us notwithstanding the Prescription Ordinance ( Cap. 68) and I/we shall not plead the Prescription Ordinance as a bar to the Bank suing me/us for the recovery of the said monies.</Stack>
            </li>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Stack mb={1}>All representations and statements made above and elsewhere and otherwise to the Bank or any of its officers by me/us or my/our agents, employees or officers in writing or otherwise on my/our behalf or purporting to be on my/our behalf are hereby warranted true and correct and intended to be acted upon by the Bank and shall from the basis of the contract resulting from the grant to me/us of the above loan.</Stack>
            </li>
            <li style={{ marginBottom: "16px", textAlign: "justify" }}>
              <Stack mb={1}>Any monies paid or lying to the credit of any of my/our account/s in any branch of the Bank shall at the discretion of the Bank be applied first to recover the interest due to the Bank and thereafter to recover the installments of the principal sum granted.</Stack>
            </li>
            <li style={{ textAlign: "justify" }}>
              <Stack mb={1}>The Bank may waive any of the above terms or conditions either unconditionally or on terms. Every such waiver by the Bank shall be without prejudice to the rights of the Bank hereunder which shall always remain exercisable as if such waiver had not been made and whenever the Bank thinks fit.</Stack>
            </li>
          </ol>
        </Stack>
      </Grid>
    </>
  );
};
