import { Downgraded, useHookstate } from "@hookstate/core";
import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { creditData } from "../../../../../configs/mainStore";
import { underwritingPolicyValidations } from "../../../../../services/creditFileApiCall";
import { MainSectionsWrapper } from "../../../MainSectionsWrapper/MainSectionsWrapper";
import { CircularProgressComponent } from "../../../ProgressComponent/ProgressComponent";
import { ValidationResultContainer, ValidationResults } from "./FraudCheck";

export const UnderwritingPolicyCheck = () => {
  const creditFileData: any = useHookstate(creditData);
  const usersData = creditFileData?.attach(Downgraded)?.get();

  const [policyState, setPolicyStatus] = useState([]);
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const applicationId = usersData.applicationId[0];

    validate(applicationId);
  }, []);

  const validate = async (applicationId) => {
    setIsLoading(true);
    const response = await underwritingPolicyValidations(applicationId);

    if (response) {
      setPolicyStatus(response);
    }

    const isError = response?.every((result) => result?.code == 1);
    setStatus(isError);
    setIsLoading(false);
  };

  if (isLoading) {
    return <Stack
      alignItems="center"
      justifyContent={"center"}
      height={"100px"}
    >
      <Stack width={"300px"} direction={"row"} spacing={2}>
        <CircularProgressComponent size={24} />
        <Typography color={"gray"}>Underwriting Policy Check...</Typography>
      </Stack>
    </Stack>;
  }

  if (!policyState?.length) {
    return <Stack></Stack>;
  }

  return <MainSectionsWrapper
    showSubText={false}
    showStatus={false}
    title="Underwriting Policy Check"
    keyCode={undefined}
    noBtn={true}
    decisionState={status}
  >
    <ValidationResultContainer results={policyState} containerWidth={6}>
      <ValidationResults result={policyState} />
    </ValidationResultContainer>
  </MainSectionsWrapper>;
};
