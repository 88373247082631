import { Downgraded, State } from "@hookstate/core";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import { loanSummaryHints } from "../../../../configs/constants/contants";
import { branchDetails } from "../../../../configs/mainStore";
import { formatCurrency } from "../../../../services/currencyFormater";
import AppConfig from "../../../../utility/AppConfig";
import { standardDateFormatter } from "../../../../utility/fomatters";
import ProductDataProvider from "../../../../utility/productDataProvider";
import { branchCodeMap } from "../../../../utility/util";
import { InfoToolTip } from "../../Info";

export const BuildHeader = ({ label }) => {
  return (
    <Grid container p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16" style={{ backgroundColor: "#e6f0fa" }}>
      <Typography>{label}</Typography>
    </Grid>
  );
};

export const SummaryHeaderExpander = ({ label, currentKey, expandedKey }: { label: string; currentKey: string; expandedKey: State<Record<string, boolean>> }) => {
  return <Stack key={currentKey} p={1} className="border-radius-5 basic-font basic-font-color-bold font-size-16" style={{ backgroundColor: "#e6f0fa" }} direction={"row"} alignContent={"center"}>
    <Stack flex={11}>
      <Typography>{label}</Typography>
    </Stack>
  </Stack>

}

export const BuildRow = ({ data }) => {
  const value = data.value;
  return (
    <Grid container direction={"row"} p={1}>
      <Grid item sm={5} py={!data.key ? 1.5 : 0}>
        <Typography variant="subtitle2">{data.key}</Typography>
      </Grid>
      <Grid item sm={7} container direction={"row"} justifyContent="flex-start" alignItems="baseline" pl={1.5}>
        <InfoToolTip data={{ ...data.tooltip }}>
          <Typography variant="subtitle2">{value === "NaN" ? "" : value}</Typography>
        </InfoToolTip>
      </Grid>
    </Grid>
  );
};

export class Controller {
  allData: any = {};
  formData: any = {};
  creditData: any = {};
  personalData: any = {};
  addressData: any = {};
  masterData: any = {};
  trailCalResult: any[] = [];
  trailCalParams: any = {};
  applicationId: any = "";
  securityData: any = {};

  typedData = (masterType, key) => {
    try {
      return this.masterData[masterType]?.get()?.find((t) => t?.id === key);
    } catch (error) {
      return this.masterData[masterType]?.find((t) => t?.id === key);
    }
  };

  validateSecurityChilds = (data) => {
    let activeChilds: any = []
    activeChilds = data.filter(obj => obj?.removedItem == null)
    return activeChilds;
  }

  generateSecurityString = (data) => {
    const collateralDataObj = AppConfig.config.collateral || {};
    let security: any = "";
    security = Object.keys(data)
      .map((categoryId) => {
        const category = collateralDataObj[categoryId];
        if (category) {
          const categoryName = category.name;
          const childNames = data[categoryId].childs
            .map((childId) => {
              const child = category.childs.find(
                (child) => child.id === childId
              );
              return child ? child.name : null;
            })
            .filter(Boolean)
            .join(", ");
          return childNames.length > 0
            ? `${categoryName}: ${childNames}`
            : categoryName;
        } else {
          return null;
        }
      })
      .filter(Boolean)
      .join(", ");
    return security;
  }

  constructor(data, masterData, trailCalResult, trailCalParams, applicationId) {
    this.allData = data;
    this.formData = data?.formData;
    this.creditData = this.formData?.creditData;
    this.personalData = this.formData?.personalData;
    this.addressData = this.formData?.addressData;
    this.trailCalResult = trailCalResult;
    this.trailCalParams = trailCalParams;
    this.masterData = masterData.get();
    this.applicationId = applicationId;
    this.securityData = this.formData?.securityData;
  }

  get loanSummary() {
    const _branchInfo: any = branchDetails.attach(Downgraded).get();

    let branch = this.allData?.originationBranch;
    let branchCode = "N/A";
    let submittedDate = "N/A";

    const branchMap = _branchInfo.branchMap[branch];

    try {
      branch = branchMap?.name;
      branchCode = branchCodeMap[branch];
    } catch (error) {
      branch = this.personalData?.branch;
      branchCode = "N/A";
    }

    try {
      if (this.allData.applicationLastUpdatedDate) {
        let applicationLastUpdatedDate = standardDateFormatter(this.allData.applicationLastUpdatedDate);
        submittedDate = applicationLastUpdatedDate;
      } else {
        submittedDate = "N/A";
      }
    } catch (error) {
      submittedDate = "N/A";
    }

    return [
      {
        key: `Branch`,
        value: branch,
        tooltip: loanSummaryHints?.branch ?? "",
      },
      {
        key: "Branch Code",
        value: branchCode,
        tooltip: loanSummaryHints?.branchCode ?? "",
      },
      {
        key: "Submitted Date",
        value: submittedDate,
        tooltip: loanSummaryHints?.submittedDate ?? "",
      },
    ];
  }

  get userSummary() {
    const name = [this.personalData.initialsInFull || "", this.personalData.primaryLastName || ""].join(" ");
    let address = "";
    try {
      let permanentAddress = this.addressData.permanentAddress;
      address = [permanentAddress?.addressLine1, permanentAddress?.addressLine2, permanentAddress?.addressLine3, permanentAddress?.addressLine4, permanentAddress?.city].join(" ");
    } catch (error) {
      address = "N/A";
    }

    return [
      {
        key: `Name`,
        value: name,
        tooltip: loanSummaryHints?.name ?? "",
      },
      {
        key: "Address",
        value: address,
        tooltip: loanSummaryHints?.address ?? "",
      },
      { key: " ", value: "" },
    ];
  }



  get creditSummary() {
    let reqLoanAmount = "N/A";
    let disbursementLoanAmount = "N/A";
    let loanScheme = "N/A";
    let loanPurpose = "N/A";
    let security = "N/A";
    let repaymentType = "N/A";
    let interestRate = "N/A";
    let maxMonthInstallement = "N/A";
    let maxLoanAmount = "N/A";
    let loanTenor = "N/A";
    let frequency = "N/A";
    let installement = "N/A";
    let maximumMonthInterest: any = "N/A";

    // Below security type get code to be refactored later
    try {
      const creditSecurity: any = {};
      const filteredCreditSecurity: any = {};
      if (this.securityData != null && this.securityData != "") {
        // Paperdocs
        if (this.securityData.paperDocs != null) {
          creditSecurity[1] = {
            childs: [],
          };
          if (this.securityData.paperDocs.loanProtection != null && this.securityData.paperDocs.loanProtection.length > 0) {
            let activeChilds: any = []
            activeChilds = this.validateSecurityChilds(this.securityData.paperDocs.loanProtection)
            if (activeChilds.length > 0) {
              creditSecurity[1].childs.push(1);
            }
          }
          if (this.securityData.paperDocs.lifeInsurance != null && this.securityData.paperDocs.lifeInsurance.length > 0) {
            let activeChilds: any = []
            activeChilds = this.validateSecurityChilds(this.securityData.paperDocs.lifeInsurance)
            if (activeChilds.length > 0) {
              creditSecurity[1].childs.push(2);
            }
          }
          if (this.securityData.paperDocs.fdAgainstLoans != null && this.securityData.paperDocs.fdAgainstLoans.length > 0) {
            let activeChilds: any = []
            activeChilds = this.validateSecurityChilds(this.securityData.paperDocs.fdAgainstLoans)
            if (activeChilds.length > 0) {
              creditSecurity[1].childs.push(3);
            }
          }
          if (this.securityData.paperDocs.warehouseReceipt != null && this.securityData.paperDocs.warehouseReceipt.length > 0) {
            let activeChilds: any = []
            activeChilds = this.validateSecurityChilds(this.securityData.paperDocs.warehouseReceipt)
            if (activeChilds.length > 0) {
              creditSecurity[1].childs.push(4);
            }
          }
          if (this.securityData.paperDocs.treasuryBond != null && this.securityData.paperDocs.treasuryBond.length > 0) {
            let activeChilds: any = []
            activeChilds = this.validateSecurityChilds(this.securityData.paperDocs.treasuryBond)
            if (activeChilds.length > 0) {
              creditSecurity[1].childs.push(5);
            }
          }
          if (this.securityData.paperDocs.shares != null && this.securityData.paperDocs.shares.length > 0) {
            let activeChilds: any = []
            activeChilds = this.validateSecurityChilds(this.securityData.paperDocs.treasuryBond)
            if (activeChilds.length > 0) {
              creditSecurity[1].childs.push(6);
            }
          }
        }
        // Guarantor
        if (this.securityData.guarantors != null) {
          creditSecurity[2] = {
            childs: [],
          };
        }
        // Movable
        if (this.securityData.movable != null) {
          creditSecurity[3] = {
            childs: [],
          };
          if (this.securityData.movable.pledge != null && this.securityData.movable.pledge.length > 0) {
            let activeChilds: any = []
            activeChilds = this.validateSecurityChilds(this.securityData.movable.pledge)
            if (activeChilds.length > 0) {
              creditSecurity[3].childs.push(1);
            }
          }
          if (this.securityData.movable.stockAsGold != null && this.securityData.movable.stockAsGold.length > 0) {
            let activeChilds: any = []
            activeChilds = this.validateSecurityChilds(this.securityData.movable.stockAsGold)
            if (activeChilds.length > 0) {
              creditSecurity[3].childs.push(2);
            }
          }
          if (this.securityData.movable.machineryEquipment != null && this.securityData.movable.machineryEquipment.length > 0) {
            let activeChilds: any = []
            activeChilds = this.validateSecurityChilds(this.securityData.movable.machineryEquipment)
            if (activeChilds.length > 0) {
              creditSecurity[3].childs.push(3);
            }
          }
          if (this.securityData.movable.vehicle != null && this.securityData.movable.vehicle.length > 0) {
            let activeChilds: any = []
            activeChilds = this.validateSecurityChilds(this.securityData.movable.vehicle)
            if (activeChilds.length > 0) {
              creditSecurity[3].childs.push(4);
            }
          }
        }
        // Immovable
        if (this.securityData.immovable != null) {
          creditSecurity[4] = {
            childs: [],
          };
          if (this.securityData.immovable.property != null && this.securityData.immovable.property.length > 0) {
            let activeChilds: any = []
            activeChilds = this.validateSecurityChilds(this.securityData.immovable.property)
            if (activeChilds.length > 0) {
              creditSecurity[4].childs.push(1);
            }
          }
          if (
            this.securityData.immovable.immovableMachineryEquipement != null && this.securityData.immovable.immovableMachineryEquipement.length > 0
          ) {
            let activeChilds: any = []
            activeChilds = this.validateSecurityChilds(this.securityData.immovable.immovableMachineryEquipement)
            if (activeChilds.length > 0) {
              creditSecurity[4].childs.push(2);
            }
          }
        }
      }

      if (Object.keys(creditSecurity).length > 0) {
        Object.keys(creditSecurity).forEach(securityMainKey => {
          if (securityMainKey != "2") {
            if (creditSecurity[securityMainKey].childs.length > 0) {
              filteredCreditSecurity[securityMainKey] = {};
              filteredCreditSecurity[securityMainKey]['childs'] = creditSecurity[securityMainKey].childs
            }
          } else {
            filteredCreditSecurity[securityMainKey] = {};
            filteredCreditSecurity[securityMainKey]['childs'] = [];
          }

        })
      }

      if (Object.keys(filteredCreditSecurity).length > 0) {
        security = this.generateSecurityString(filteredCreditSecurity);
      } else {
        security = "N/A";
      }
    } catch (error) {
      security = "N/A";
    }

    try {
      let repaymentTypeObj = this.masterData?.REPAYMENT_TYPE.find((t) => t.id == this.creditData?.repaymentType);
      repaymentType = repaymentTypeObj?.name;
    } catch (error) {
      repaymentType = "N/A";
    }

    try {
      disbursementLoanAmount = this.trailCalParams.LoanAmount ?? this.trailCalParams.loanAmount ?? this.creditData.loanAmount;
      reqLoanAmount = this.creditData.loanAmount;
      interestRate = this.trailCalParams.LoanInterestRate ?? this.trailCalParams.loanInterestRate ?? this.trailCalParams.applicableRate ?? this.creditData.interestRate ?? 0.0;
      loanPurpose = this.typedData("LOAN_PURPOSE", this.creditData.loanPurpose)?.name;
      loanScheme = new ProductDataProvider(this.creditData).getSchemeName()
    } catch (error) { }

    try {
      if (this.trailCalResult.length > 0) {
        maxMonthInstallement = this.trailCalResult[1].termlyInstallment;
        maximumMonthInterest = parseFloat(this.trailCalResult[1].interestRecovery);
      } else {
        maxMonthInstallement = "N/A";
        maximumMonthInterest = "N/A";
      }

      maxLoanAmount = this.creditData.maxLoanAmount;
    } catch (error) { }

    try {
      installement = this.creditData.installement;
      frequency = this.typedData("LOAN_FREQUENCY", this.creditData.loanFrequency)?.name;
      loanTenor = this.trailCalParams.LoanTenure ?? this.creditData.loanTerms ?? 0;
    } catch (error) { }

    return [
      {
        key: "Requested Loan Amount",
        value: formatCurrency(reqLoanAmount),
        tooltip: loanSummaryHints?.reqLoanAmount ?? "",
      },
      {
        key: "Recommended Loan Amount",
        value: formatCurrency(reqLoanAmount),
        tooltip: loanSummaryHints?.recommendedLoanAmount ?? "",
      },

      {
        key: "Approved Loan Amount",
        value: formatCurrency(disbursementLoanAmount),
        tooltip: loanSummaryHints?.approvedLoanAmount ?? "",
      },
      {
        key: "Loan Scheme",
        value: loanScheme,
        tooltip: loanSummaryHints?.loanScheme ?? "",
      },

      {
        key: "Proposed Rate",
        value: `${interestRate}% p.a`,
        tooltip: loanSummaryHints?.proposedRate ?? "",
      },
      {
        key: "Loan Purpose",
        value: loanPurpose,
        tooltip: loanSummaryHints?.loanPurpose ?? "",
      },
      {
        key: "Security",
        value: security,
        tooltip: loanSummaryHints?.security ?? "",
      },
      {
        key: "Repayment Type",
        value: repaymentType,
        tooltip: loanSummaryHints?.repaymentType ?? "",
      },

      {
        key: "Maximum Month Installment",
        value: maxMonthInstallement,
        tooltip: loanSummaryHints?.maximumMonthInstallment ?? "",
      },
      {
        key: "Maximum Month Interest",
        value: maximumMonthInterest,
        tooltip: loanSummaryHints?.maximumMonthInterest ?? "",
      },

      {
        key: "Loan Tenor",
        value: loanTenor,
        tooltip: loanSummaryHints?.loanTenor ?? "",
      },
      {
        key: "Frequency",
        value: frequency,
        tooltip: loanSummaryHints?.frequency ?? "",
      },
    ];
  }
}
